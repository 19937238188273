import React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateAnnualReportCellValue,
  updateAnnualReportField,
} from 'redux/actions/AnnualReportView/actions';
import CurrencyField, {
  CurrencyFieldProps,
} from 'components/UI/Inputs/CurrencyField';
import StringInput from './StringInput';
import styled from '@emotion/styled';

interface NumberFieldProps {
  className?: string;
  id: string;
  value: number | undefined;
}

const NumberInput = styled(StringInput)`
  font-family: Roboto;
  text-align: right;
`;

const NumberField = ({
  id,
  value,
  className,
  ...props
}: NumberFieldProps & CurrencyFieldProps) => {
  const dispatch = useDispatch();
  const onChange = useCallback(
    (newValue: number | undefined) => {
      if (newValue !== value)
        if (id.split('.').length > 3) {
          dispatch(updateAnnualReportCellValue(id, newValue));
        } else {
          dispatch(updateAnnualReportField(id, newValue));
        }
    },
    [dispatch, value, id]
  );

  return (
    <CurrencyField
      className={className}
      {...props}
      value={value}
      onValueChange={onChange}
      Input={NumberInput}
    />
  );
};

export default NumberField;
