import React from 'react';
import { createPeriods } from 'utils';
import GroupStatus from './GroupStatus';
import AccountStatus from './AccountStatus';
import VatStatus from './VatStatus';
import { AccountGroupAttribute, getGroupStatus } from 'utils/accountGrouper';
import styled from '@emotion/styled';
import { WithTheme } from '@material-ui/core/styles';
import { orange } from 'theme/colors';
import { Tooltip } from '@material-ui/core';
import { useIntl } from 'react-intl';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
`;

const LampContainer = styled.div<WithTheme>`
  border-radius: ${props => props.theme.spacing(1 / 4)}px
    ${props => props.theme.spacing(1 / 4)}px 0 0;
  width: 4%;
  overflow: hidden;
`;

const AccountStatusWrapper = styled.div<WithTheme>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: ${props => props.theme.spacing(1 / 4)}px;
  row-gap: ${props => props.theme.spacing(1 / 4)}px;
  flex-flow: row wrap;
  justify-content: center;
`;

const ClosingMonthLabel = styled.div<WithTheme>`
  text-align: center;
  height: 1.25rem;
  line-height: 1.25rem;
  color: ${props => props.theme.palette.primary.contrastText};
  background: ${props => props.theme.palette.primary.main};
  margin-bottom: ${props => props.theme.spacing(1 / 4)}px;
`;

const CurrentDate = styled.div<WithTheme>`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  border-top-left-radius: ${props => props.theme.shape.borderRadius};
  border-top-right-radius: ${props => props.theme.shape.borderRadius};
  background: ${orange};
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: ${props => props.theme.spacing(1 / 4)}px;
`;

/* 
    Differentiate from other accounts, this is ATM always gray 'not started' since
    since there is no way to modify it in the UI
*/
const VAT = 'vat';

// Order matters!
const availablelamps: Array<AccountGroupAttribute | string> = [
  'a15xx',
  'a1630',
  'a19xx',
  'a24xx',
  // VAT,
  'moms',
  'rest',
];
const todayDateObj = new Date();
const today =
  `${todayDateObj.getFullYear()}` +
  `${todayDateObj.getMonth() + 1}`.padStart(2, '0');
const todaysMonth = todayDateObj.getMonth() + 1;

const LampRow = ({ expanded, lamps = {}, financialYears, closingMonth }) => {
  const intl = useIntl();
  const periods = createPeriods(financialYears);

  return (
    <Wrapper>
      {periods.map(period => (
        <LampContainer key={period.date}>
          {today === period.date && (
            <Tooltip
              title={intl.formatMessage({ id: 'month.current' })}
              placement="top"
            >
              <CurrentDate>
                {intl.formatMessage({ id: `month.${todaysMonth}` })}
              </CurrentDate>
            </Tooltip>
          )}

          {period.date.slice(-2) === `${closingMonth}`.padStart(2, '0') && (
            <ClosingMonthLabel>Bokslut</ClosingMonthLabel>
          )}

          {!expanded ? (
            <GroupStatus
              status={
                (lamps[period.date] && getGroupStatus(lamps[period.date])) ||
                'unvisited'
              }
            />
          ) : (
            <AccountStatusWrapper>
              {availablelamps.map((accountGroup, index) => {
                if (accountGroup === VAT) {
                  return <VatStatus key={`${accountGroup}${index}`} />;
                }
                return (
                  <AccountStatus
                    status={
                      (lamps[period.date] &&
                        lamps[period.date][accountGroup]) ||
                      'unvisited'
                    }
                    key={`${accountGroup}${index}`}
                  />
                );
              })}
            </AccountStatusWrapper>
          )}
        </LampContainer>
      ))}
    </Wrapper>
  );
};

export default LampRow;
