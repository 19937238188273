import {
  TaxCalculationConfig,
  TaxCalculationRow,
  TransactionRow,
} from './types';
import { createYears } from './calculateAccrualFunds';
import { getStoredTaxConfig } from 'components/Api/Client/tax';
import {
  account,
  floor,
  id,
  max,
  min,
  multiply,
  or,
  round,
  sum,
} from 'utils/References/helpers';
import arrayToRecord from 'utils/arrayToRecord';

const accountReference = (account: string): TaxCalculationRow => ({
  id: `account${account}`,
  label: account,
  reference: `account(${account})`,
});

type NumberProperties<T> = {
  [K in keyof T]: T[K] extends number ? K : never;
}[keyof T];
type ConfigName = NumberProperties<TaxCalculationConfig>;

// helpers for config
const config = (name: ConfigName) => `config(${name})`;

// helper for adjustment rows
const adjustmentRow = (
  id: string,
  from: string,
  to: string,
  reverse?: boolean
): TransactionRow[] => [
  {
    id: `${id}.from`,
    labelId: id,
    account: from,
    reference: reverse ? `multiply(-1,id(${id}))` : `id(${id})`,
  },
  {
    id: `${id}.to`,
    labelId: id,
    account: to,
    reference: reverse ? `id(${id})` : `multiply(-1,id(${id}))`,
  },
];

export const companyTaxPerYear = {
  '2013': 0.22,
  '2014': 0.22,
  '2015': 0.22,
  '2016': 0.22,
  '2017': 0.22,
  '2018': 0.22,
  '2019': 0.214,
  '2020': 0.214,
  '2021': 0.206,
  '2022': 0.206,
  '2023': 0.206,
};

export const configBase: TaxCalculationConfig = {
  // Ännu ej bokförd särskild löneskatt enligt beräkning nedan
  notBookedParticualSalaryTax: {
    id: 'notYetBookedParticualSalaryTax',
    reference: id('particularSalaryTaxToBook'),
  },
  // Årets resultat för konto 3000-8799
  resultBeforeFrom3000to8799: {
    id: 'resultBeforeFrom3000to8799',
    reference: multiply(
      sum(account('3000:8799'), id('particularSalaryTaxToBook')),
      -1
    ),
  },
  // Bokslutsplanering
  yearEndPlanning: {
    rows: [
      // Redan bokförda lämnade koncernbidrag
      {
        id: 'bookedSentGroupContributions',
        reference: multiply(
          -1,
          or(account('8830:8839'), max(account('8800:8899:koncernbidrag'), 0))
        ),
      },
      // Redan bokförda mottagna koncernbidrag
      {
        id: 'bookedReceivedGroupContributions',
        reference: multiply(
          -1,
          or(account('8820:8829'), min(account('8800:8899:koncernbidrag'), 0))
        ),
      },
      // Valda lämnade koncernbidrag
      {
        id: 'chosenSentGroupContributions',
        value: 0,
      },
      // Valda mottagna koncernbidrag
      {
        id: 'chosenReceivedGroupContributions',
        value: 0,
      },
      // Lämnade gottgörelser
      {
        id: 'sentCompensation',
        reference: multiply(-1, account('8840:8849')),
      },
      // Förändring av överavskrivningar
      {
        id: 'changeOfOverdepreciation',
        reference: multiply(-1, account('8850:8859')),
      },
      // Vald ökning av överavskrivning
      {
        id: 'chosenIncreaseOfOverdepreciation',
        value: 0,
      },
      // Vald minskning av överavskrivning
      {
        id: 'chosenDecreaseOfOverdepreciation',
        value: 0,
      },
      // Förändring av ersättningsfond
      {
        id: 'changeOfReplacementFund',
        reference: multiply(-1, account('8860:8869')),
      },
      // Vald övrig bokslutsjustering 1
      {
        id: 'chosenOtherAdjustment1',
        value: 0,
      },
      // Vald övrig bokslutsjustering 2
      {
        id: 'chosenOtherAdjustment2',
        value: 0,
      },
      // Redan bokförda periodiseringsfonder
      {
        id: 'bookedAccrualFund',
        reference: multiply(-1, account('8810:8819')),
      },
      // Tvingande återföring av p-fond
      {
        id: 'forcedReversalOfAccrualFund', // Agoy-excel D23
        reference: id('accrualFund-6'),
      },
      // Max möjlig avsättning till p-fond, Max möjlig ytterligare avsättning till p-fond
      {
        // This is just a placeholder for the very special line which will
        // format the label with a value calculated after both Skatteberäkning and Periodiseringsfonder
        id: 'maxPossibleDepositionToAccrualFundLabel', // // Agoy-excel D24
        value: 0,
      },
      // Vald återföring av periodiseringsfond
      {
        id: 'chosenReversalOfAccrualFund', // Agoy-excel D25
        value: 0,
      },
      // Vald avsättning till periodiseringsfond
      {
        id: 'chosenDepositionToAccrualFund', // Agoy-excel D26
        value: 0,
      },
    ],
    sum: {
      id: 'yearEndPlanningSum',
      hidden: true,
    },
  },
  yearEndPlanningResultBeforeTaxes: {
    id: 'resultAfterYearEndPlanningBeforeTaxes',
    reference: sum(id('yearEndPlanningSum'), id('resultBeforeFrom3000to8799')),
  },
  // Resultat INNAN skatt
  resultBeforeTaxes: {
    id: 'resultBeforeTaxes',
    reference: multiply(-1, account('3000:8799')),
    hidden: true,
  },
  // Skatteberäkning
  taxCalculation: {
    rows: [
      {
        id: 'increaseOfCompanyTaxFromAccrualFunds',
        reference: id('increaseOfCompanyTax'),
        hiddenWhenEmpty: true,
      },
      {
        // Icke skattepliktiga intäkter
        id: 'nonTaxableIncomes',
        reference: id('nonTaxableIncomesSum'),
      },
      {
        // Ej avdragsgilla kostander
        id: 'nonDeductibleExpenses',
        reference: id('nonDeductibleExpensesSum'),
      },
      {
        // Schablonintäkt på periodiseringsfonder (0,51%)
        id: 'templateIncomeAccrualFund',
        reference: or(
          multiply(
            -1,
            account('2101:2149,yearIb'),
            config('templateTax'),
            'yearPercentage()'
          ),
          0
        ),
      },
      {
        // Underskottsavdrag
        id: 'deficitDeduction',
        label: 'Underskottsavdrag',
        value: 0,
      },
    ],
    sum: { id: 'taxCalculationSum', hidden: true },
  },
  taxCalculationMoreRows: [
    // Årets skattemässiga resultat
    {
      id: 'yearsTaxableResult',
      reference: sum(
        id('taxCalculationSum'),
        or(id('resultAfterYearEndPlanningBeforeTaxes'), id('resultBeforeTaxes'))
      ),
    },
    // Redan bokförd bolagskatt
    {
      id: 'bookedCompanyTax',
      reference: or(account('8901:8910'), 0),
    },
    // Bolagsskatt,
    // Rules:
    //  * if the taxable result is less than 200, then no tax.
    //  * The taxable result is rounded down to nearest 10
    //  * The decimals are remove from the calculated tax
    //
    // Check out the tests in resolveReference for more.
    {
      id: 'companyTax',
      reference: max(
        0,
        multiply(
          // Trick to get 0 if yearsTaxableResult is less than 200 and 1 otherwise, to multiply the tax with, like an if-statement.
          min(max(0, round(sum(id('yearsTaxableResult'), -199.5))), 1),
          floor(
            multiply(
              // multiplying with 0.1, flooring and multiply by 10 is
              // rounding down to nearest factor of 10.
              multiply(10, floor(multiply(0.1, id('yearsTaxableResult')))),
              'config(companyTax)'
            )
          )
        )
      ),
    },
    // Justering av bokförd , skatt som ska bokföras
    // Skatt som ska bokföras
    {
      id: 'taxToBook',
      labelId: 'taxToBook',
      reference: sum(id('companyTax'), multiply(-1, id('bookedCompanyTax'))),
    },
    // Redan bokfört resutat
    {
      id: 'alreadyBookedResult',
      reference: or(account('8990:8999'), 0),
    },
    // Resultat
    {
      id: 'result',
      reference: sum(
        or(
          id('resultAfterYearEndPlanningBeforeTaxes'),
          id('resultBeforeTaxes')
        ),
        multiply(-1, id('companyTax'))
      ),
    },
    // Resultat att bokföra
    {
      id: 'resultToBook',
      labelId: 'resultToBook',
      reference: sum(id('result'), multiply(-1, id('alreadyBookedResult'))),
    },
  ],
  nonTaxableIncomes: {
    rows: [accountReference('8314')],
    sum: { id: 'nonTaxableIncomesSum' },
  },
  nonDeductibleExpenses: {
    rows: [
      accountReference('6072'),
      accountReference('6982'),
      accountReference('6992'),
      accountReference('7622'),
      accountReference('7632'),
      accountReference('8010'),
      accountReference('8012'),
      accountReference('8020'),
      accountReference('8022'),
      accountReference('8110'),
      accountReference('8423'),
    ],
    sum: { id: 'nonDeductibleExpensesSum' },
  },
  particularSalaryTax: {
    rows: [
      accountReference('7410'),
      accountReference('7411'),
      accountReference('7412'),
    ],
    sum: { id: 'particularSalaryTaxSum' },
  },
  alreadyBookedParticularSalaryTax: {
    id: 'alreadyBookedParticularSalaryTax',
    reference: or(account('7530:7549'), 0),
  },
  particularSalaryTaxToBook: {
    id: 'particularSalaryTaxToBook',
  },
  accrualFunds: { rows: [], sum: { id: 'accrualFundsSum' } },
  accrualFundsRows: [
    // Tvingande återföring av p-fond
    {
      id: 'accrualFundsForcedReversalOfAccrualFund', // (Agoy-excel 'p-fond'!P14)
      reference: or(id('accrualFund-6'), 0),
    },
    // Total avsättning till p-fond vid årets början
    {
      id: 'totalAccrualFunds', // (Agoy-excel 'p-fond'!P15)
      reference: account('2110:2150,yearIb'),
    },
    {
      id: 'templateIncome', // (Agoy-excel 'p-fond'!P16)
      reference: multiply(id('accrualFundsSum'), config('templateTax')),
    },
    {
      id: 'accrualFundsCompanyTax', // (Agoy-excel 'p-fond'!P17)
      reference: multiply(id('templateIncome'), config('companyTax')),
    },
    // Max möjlig avsättning till p-fond, Max möjlig ytterligare avsättning till p-fond
    {
      // This is just a placeholder for the very special line which will
      // format the label with a value calculated after both Skatteberäkning and Periodiseringsfonder
      id: 'maxPossibleDepositionToAccrualFundLabel',
      value: 0,
    },
    {
      // (AgoyExcel U12)
      id: 'maxPossibleDepositionToAccrualFundU12', // if bookedAccrualFund > 0
      reference: multiply(
        round(
          sum(
            id('yearsTaxableResult'),
            multiply(-1, or(id('bookedAccrualFund'), 0)),
            multiply(-1, id('chosenDepositionToAccrualFund'))
          )
        ),
        0.25
      ),
      hidden: true,
    },
    {
      // (AgoyExcel U14)
      id: 'maxPossibleDepositionToAccrualFund', // if bookedAccrualFund > 0
      reference: round(
        multiply(-1, id('maxPossibleDepositionToAccrualFundU12'))
      ),

      hidden: true,
    },
    {
      // (AgoyExcel U16)
      id: 'U16',
      reference: round(or(id('bookedAccrualFund'), 0)),

      hidden: true,
    },
    {
      // (Excel U22)
      id: 'maxPossibleFurtherDepositionToAccrualFund',
      reference: sum(
        multiply(
          -1,
          sum(
            multiply(
              sum(
                id('yearsTaxableResult'),
                multiply(-1, or(id('bookedAccrualFund'), 0)),
                multiply(-1, id('chosenDepositionToAccrualFund'))
              ),
              0.25
            ),
            or(id('bookedAccrualFund'), 0)
          )
        ),
        multiply(-1, id('chosenDepositionToAccrualFund'))
      ),
      hidden: true,
    },
    {
      // (Excel U20) Ytterligare möjlig avsättning till p-fonde efter bokfört
      id: 'furtherPossibleDepositionToAccrualFund',
      reference: or(
        round(
          min(
            0,
            multiply(
              sum(id('maxPossibleDepositionToAccrualFundU12'), id('U16')),
              -1
            )
          )
        ),
        0
      ),
      hidden: true,
    },
    {
      // (Excel U22) Max möjlig ytterligare avsättning till p-fond efter bokfört o eget val
      id: 'furtherPossibleDepositionToAccrualFundWithChosen',
      reference: or(
        round(
          sum(
            id('furtherPossibleDepositionToAccrualFund'),
            multiply(-1, id('chosenDepositionToAccrualFund'))
          )
        ),
        0
      ),
      hidden: true,
    },
    {
      id: 'chosenAndBookedDepositToAccrualFund',
      reference: sum(
        or(id('bookedAccrualFund'), 0),
        id('chosenDepositionToAccrualFund')
      ),
    },
    // Max möjlig återföring av p-fond exlusive tvingande återföring
    {
      id: 'maxPossibleReversalOfAccrualFundNotForced',
      reference: sum(
        id('accrualFundsForcedReversalOfAccrualFund'),
        id('totalAccrualFunds')
      ),
    },
    // Vald återföring av periodiseringsfond för aktuellt bokslut inklusive tvingande återföring (väljs i skatteberäkningen)
    {
      id: 'chosenAndReversalOfAccrualFundIncludingForced',
      reference: sum(
        id('accrualFundsForcedReversalOfAccrualFund'),
        id('chosenReversalOfAccrualFund')
      ),
    },
    // Uppräkning av företagsskatt
    {
      id: 'increaseOfCompanyTax',
      value: 0,
      hidden: true,
    },
  ],
  adjustments: {
    rows: [
      ...adjustmentRow('taxToBook', '8910', '2510'),
      ...adjustmentRow('resultToBook', '8990', '2099'),
      ...adjustmentRow('particularSalaryTaxToBook', '7530', '2514'),
      ...adjustmentRow('chosenDepositionToAccrualFund', '8810', '2129', true),
      ...adjustmentRow('chosenReversalOfAccrualFund', '8810', '2119', true),
      ...adjustmentRow('chosenSentGroupContributions', '8830', '2360', true),
      ...adjustmentRow(
        'chosenReceivedGroupContributions',
        '8820',
        '1310',
        true
      ),
      ...adjustmentRow(
        'chosenIncreaseOfOverdepreciation',
        '8850',
        '2150',
        true
      ),
      ...adjustmentRow(
        'chosenDecreaseOfOverdepreciation',
        '8850',
        '2150',
        true
      ),
    ],
    sum: {
      id: 'adjustmentsSum',
      hidden: true,
      account: '',
    },
  },
  salaryTax: 0.2426,
  companyTax: 0.22,
  templateTax: 0.0051,
};

export const yearEndPlanningConfig = configBase.yearEndPlanning;
export const taxCalculationTableConfig = configBase.taxCalculation;
export const adjustmentsConfig = configBase.adjustments;

const mergeRow = (
  configRow: TaxCalculationRow,
  storedRow: TaxCalculationRow | undefined
): TaxCalculationRow => {
  if (!storedRow) {
    return configRow;
  }
  // The part that can be changed by a user is the label, value and deleted
  if (
    configRow.label !== storedRow.label ||
    configRow.value !== storedRow.label ||
    storedRow.deleted
  ) {
    return {
      ...configRow,
      label: storedRow.label,
      value: storedRow.value,
      deleted: storedRow.deleted,
    };
  }
  return configRow;
};

export const mergeRows = (
  config: TaxCalculationRow[],
  stored: TaxCalculationRow[]
): TaxCalculationRow[] => {
  // Refresh the stored config with possible updates
  const storedById = arrayToRecord(stored, row => row.id);
  return [
    ...config.map(configRow => mergeRow(configRow, storedById[configRow.id])),
    ...stored.filter(row => row.id.startsWith('@')),
  ];
};

export const getTaxConfig = async (
  clientId: string,
  year: string,
  periods: string[]
): Promise<TaxCalculationConfig> => {
  const storedTaxConfig = await getStoredTaxConfig(clientId, year);
  const newTaxConfig = {
    ...configBase,
    accrualFunds: {
      ...configBase.accrualFunds,
      rows: createYears(periods),
    },
    companyTax: companyTaxPerYear[year],
  };
  if (storedTaxConfig) {
    let config: TaxCalculationConfig = {
      ...newTaxConfig,
      ...storedTaxConfig,
    };
    config = {
      ...config,
      yearEndPlanning: {
        ...config.yearEndPlanning,
        rows: mergeRows(
          configBase.yearEndPlanning.rows,
          storedTaxConfig.yearEndPlanning.rows
        ),
      },
      taxCalculation: {
        ...config.taxCalculation,
        rows: mergeRows(
          configBase.taxCalculation.rows,
          storedTaxConfig.taxCalculation.rows
        ),
      },
    };
    if (config.accrualFunds.rows.some(row => !row.label)) {
      // When agoy-app creates a config, the label can be missing
      config = {
        ...config,
        accrualFunds: {
          ...config.accrualFunds,
          rows: config.accrualFunds.rows.map((row, index) =>
            row.label
              ? row
              : { ...row, label: newTaxConfig.accrualFunds.rows[index].label }
          ),
        },
      };
    }
    return config;
  }
  return newTaxConfig;
};
