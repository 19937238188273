const arrayToRecord = <T, U extends string | number | symbol>(
  arr: T[] | undefined,
  key: (item: T) => U
): Record<U, T> => {
  if (!arr) {
    return {} as Record<U, T>;
  }

  return arr.reduce((result, item: T) => {
    result[key(item)] = item;
    return result;
  }, {} as Record<U, T>);
};

export default arrayToRecord;
