import React, { memo } from 'react';
import { IconButton, TableCell, TableRow, WithTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  AnnualReportTableColumn,
  AnnualReportTableRow,
} from 'types/AnnualReport/table';
import { addAnnualReportRow } from 'redux/actions/AnnualReportView/actions';
import styled from '@emotion/styled';
import DeleteIcon from '@material-ui/icons/Delete';
import Cell from '../Cell';
import { AddRowProps } from './HierarchicalTable';

export interface HierarchicalTableRowProps {
  className?: string;
  classes: Record<string, string>;
  baseId: string;
  row: AnnualReportTableRow;
  columns: AnnualReportTableColumn[];
  level: number;
  rowComponents: React.ComponentType<HierarchicalTableRowProps>[];
  AddRow?: React.ComponentType<AddRowProps>;
  editing?: boolean;
  editingOverride?: (editing: boolean, columnId: string) => boolean;
  onDelete?: () => void;
  print?: boolean;
}

const DeleteCell = styled(TableCell)<WithTheme>`
  &.MuiTableCell-body {
    padding: 0;
  }
  .MuiIconButton-root {
    padding: ${props => props.theme.spacing(0.5)}px;
  }
`;

const classes = {};

const HierarchicalTableRow = memo(
  React.forwardRef<HTMLTableRowElement, HierarchicalTableRowProps>(
    (
      {
        className,
        row,
        columns,
        baseId,
        level,
        rowComponents,
        editing,
        editingOverride,
        onDelete,
        AddRow,
        print,
      },
      fwdRef
    ) => {
      const dispatch = useDispatch();

      const SubRowComponent = rowComponents[level];

      return (
        <>
          {row.cells && (
            <TableRow ref={fwdRef} key={row.id} className={className}>
              {columns.map(col => {
                const cell = row.cells?.[col.id];
                const key = `${row.id}.${col.id}`;
                const cellId = `${baseId}.${key}`;
                if (!cell) {
                  return <TableCell key={key} />;
                }
                // AGOY-886 do not render table cells that belong to column hidden
                if (col.id.includes('hidden')) {
                  return null;
                }
                return (
                  <TableCell key={key}>
                    <Cell
                      cell={cell}
                      id={cellId}
                      editing={
                        editingOverride
                          ? editingOverride(editing || false, col.id)
                          : editing
                      }
                      print={print}
                    />
                  </TableCell>
                );
              })}
              <DeleteCell>
                {!print && editing && onDelete && (
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </DeleteCell>
            </TableRow>
          )}
          {SubRowComponent &&
            row.rows?.map(subRow => (
              <SubRowComponent
                classes={classes}
                key={`${baseId}.${row.id}.${subRow.id}`}
                row={subRow}
                baseId={`${baseId}.${row.id}`}
                columns={columns}
                level={level + 1}
                rowComponents={rowComponents}
                editing={editing}
                AddRow={AddRow}
                print={print}
              />
            ))}
          {!print && SubRowComponent && AddRow && row.newRowTemplate && (
            <AddRow
              level={level}
              onClick={cellParameters =>
                dispatch(
                  addAnnualReportRow(
                    `${baseId}.${row.id}`,
                    undefined,
                    cellParameters
                  )
                )
              }
              parent={row}
              columns={columns}
              parentId={`${baseId}.${row.id}`}
            />
          )}
        </>
      );
    }
  )
);

export const RowLevel1 = styled(HierarchicalTableRow)<
  { print?: boolean } & WithTheme
>`
  .MuiTableCell-body {
    font-size: ${props =>
      props.print
        ? props.theme.typography.fontSize
        : props.theme.typography.fontSize * 1.25}px;
    font-weight: 700;
  }
  .MuiTableCell-body:first-of-type {
    padding-left: 0;
  }
`;

export const RowLevel2 = styled(HierarchicalTableRow)<
  { heading?: boolean; print?: boolean } & WithTheme
>`
  .MuiTableCell-body {
    font-size: ${props =>
      props.print
        ? props.theme.typography.fontSize
        : props.theme.typography.fontSize * 1.125}px;
    font-weight: 700;
  }
  .MuiTableCell-body:first-of-type {
    padding-left: ${props => (props.print ? 0 : props.theme.spacing(2))}px;
    color: ${props =>
      props.heading
        ? props.theme.palette.text.primary
        : props.theme.palette.grey[600]};
  }
`;

export const RowLevel3 = styled(HierarchicalTableRow)<WithTheme>`
  .MuiTableCell-body:first-of-type {
    font-weight: 700;
    padding-left: ${props => props.theme.spacing(4)}px;
    color: black;
  }
`;

export default HierarchicalTableRow;
