import { merge, isEqual } from 'lodash';

import {
  startedSavingUserData,
  savingUserDataSuccess,
  savingUserDataFailed,
} from 'redux/actions';
import { RootState } from 'redux/types';
import { InputData } from 'types/AccountingView/types';
import {
  PATCH_USER_INPUT_DATA,
  PATCH_USER_INPUT_ROUTINE_DATA,
  SET_NUM_IMAGES,
  INCREASE_IMAGES,
  DECREASE_IMAGES,
} from '../actionsTypes';
import { getClient } from './apolloMiddlewareClient';
import { ADD_USER_INPUT, ADD_USER_INPUT_ROUTINE } from 'Graphql/Mutations';
import { GET_USER_INPUT, GET_ROUTINES } from 'Graphql/Queries';
import { createInputData, createInputRoutineData } from 'utils/UserInputUtils';
import debounceByKey from 'utils/debounceByKey';

const saveInputKey = (
  dispatch,
  customerId,
  accountingYear,
  account,
  period,
  userInput
) => {
  return `${customerId}_${accountingYear}_${account}_${period}`;
};

const saveInputRoutineKey = (dispatch, customerId, account, routine) => {
  return `${customerId}__${account}`;
};

const saveInput = async (
  dispatch,
  customerId,
  accountingYear,
  account,
  period,
  userInput
) => {
  const [year, month] = period.split('-');

  dispatch(startedSavingUserData());
  getClient()
    .mutate({
      mutation: ADD_USER_INPUT,
      variables: {
        accountingYear,
        year,
        month,
        customerId,
        account,
        inputData: JSON.stringify(userInput),
      },
      update: store => {
        const {
          //@ts-ignore
          getUserInputData,
        } = store.readQuery({
          query: GET_USER_INPUT,
          variables: {
            customerId,
            accountingYear,
          },
        });
        const existingInputData = JSON.parse(getUserInputData.inputData);
        const newInputData = {
          [`${accountingYear}-${year}-${month}`]: {
            [`account${account}`]: userInput,
          },
        };
        const mergedData = merge({}, existingInputData, newInputData);
        getUserInputData.inputData = JSON.stringify(mergedData);
        store.writeQuery({
          query: GET_USER_INPUT,
          variables: {
            customerId,
            accountingYear,
          },
          data: {
            getUserInputData: getUserInputData,
          },
        });
      },
    })
    .then(() => {
      dispatch(savingUserDataSuccess());
    })
    .catch(() => {
      dispatch(savingUserDataFailed());
    });
};

const saveInputRoutine = async (
  dispatch,
  customerId,
  account,
  routineInput
) => {
  dispatch(startedSavingUserData());
  getClient()
    .mutate({
      mutation: ADD_USER_INPUT_ROUTINE,
      variables: {
        customerId,
        account,
        routine: JSON.stringify(routineInput),
      },
      update: store => {
        const {
          //@ts-ignore
          getRoutinesData,
        } = store.readQuery({
          query: GET_ROUTINES,
          variables: {
            customerId,
          },
        });

        const existingRoutinesData = JSON.parse(getRoutinesData.routines);

        const newRoutineData = {
          [`account${account}`]: routineInput,
        };
        const mergedData = merge({}, existingRoutinesData, newRoutineData);
        getRoutinesData.routines = JSON.stringify(mergedData);

        store.writeQuery({
          query: GET_ROUTINES,
          variables: {
            customerId,
          },
          data: {
            getRoutinesData: getRoutinesData,
          },
        });
      },
    })
    .then(() => {
      dispatch(savingUserDataSuccess());
    })
    .catch(err => {
      dispatch(savingUserDataFailed());
    });
};

const save = debounceByKey(saveInputKey, saveInput, 1000);

const saveRoutine = debounceByKey(saveInputRoutineKey, saveInputRoutine, 1000);

const getInputData = (
  accountingView,
  accountNumber,
  formattedPeriod
): InputData => {
  const accountUserInput = accountingView.userInput[`account${accountNumber}`];
  return (
    accountUserInput &&
    accountUserInput[formattedPeriod] &&
    createInputData(accountUserInput[formattedPeriod])
  );
};

const saveUserInputData = ({
  getState,
  dispatch,
}: {
  getState: () => RootState;
  dispatch: () => any;
}) => next => action => {
  const prevState = getState();
  const result = next(action);
  const newState = getState();

  if (
    action.type === PATCH_USER_INPUT_DATA ||
    action.type === SET_NUM_IMAGES ||
    action.type === INCREASE_IMAGES ||
    action.type === DECREASE_IMAGES
  ) {
    const { accountNumber, formattedPeriod } = action;

    const { accountingView: prevAccountingView } = prevState;
    const {
      accountingView: newAccountingView,
      customerView: newCustomerView,
    } = newState;

    const previousInputData = getInputData(
      prevAccountingView,
      accountNumber,
      formattedPeriod
    );

    const inputData = getInputData(
      newAccountingView,
      accountNumber,
      formattedPeriod
    );

    if (!isEqual(previousInputData, inputData)) {
      save(
        dispatch,
        newCustomerView.currentCustomer,
        newCustomerView.currentYear,
        accountNumber,
        formattedPeriod,
        inputData
      );
    }
  }

  if (action.type === PATCH_USER_INPUT_ROUTINE_DATA) {
    const { accountNumber } = action;
    const { accountingView, customerView } = getState();

    saveRoutine(
      dispatch,
      customerView.currentCustomer,
      accountNumber,
      createInputRoutineData(accountingView.routines[`account${accountNumber}`])
    );
  }

  return result;
};

export default saveUserInputData;
