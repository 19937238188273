import {
  RESET_CUSTOMER,
  SET_ACCOUNT_LIST_DRAWER_EXPANDED,
  SET_ACCOUNT_LIST_DRAWER_MODE,
  ACCOUNT_LIST_DRAWER_ADD_ACCOUNT,
  ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT,
  ACCOUNT_LIST_DRAWER_CLEAR_SELECTION,
  ACCOUNT_LIST_DRAWER_SET_ACCOUNTS,
} from '../../actionsTypes';
import AccountListDrawerState from './types';
import { AccountListDrawerActions, GlobalActions } from 'redux/actions';

const initialAccountListDrawerState: AccountListDrawerState = {
  expanded: false,
  mode: 'viewing',
  selectedAccounts: [],
  selectionOwner: '',
};

export default (
  state: AccountListDrawerState = initialAccountListDrawerState,
  action: AccountListDrawerActions | GlobalActions
): AccountListDrawerState => {
  switch (action.type) {
    case RESET_CUSTOMER:
      return { ...initialAccountListDrawerState };
    case SET_ACCOUNT_LIST_DRAWER_EXPANDED:
      return {
        ...state,
        expanded: action.expanded,
      };
    case SET_ACCOUNT_LIST_DRAWER_MODE:
      return {
        ...state,
        mode: action.mode,
        selectionOwner:
          action.mode === 'selecting-accounts' ? action.selectionOwner : '',
      };
    case ACCOUNT_LIST_DRAWER_ADD_ACCOUNT:
      return {
        ...state,
        selectedAccounts: [
          ...new Set([...state.selectedAccounts]).add(action.account),
        ],
      };
    case ACCOUNT_LIST_DRAWER_SET_ACCOUNTS:
      return {
        ...state,
        selectedAccounts: action.accounts,
      };
    case ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT:
      return {
        ...state,
        selectedAccounts: state.selectedAccounts.filter(
          account => account !== action.account
        ),
      };
    case ACCOUNT_LIST_DRAWER_CLEAR_SELECTION:
      return {
        ...state,
        selectedAccounts: [],
      };
    default:
      return state;
  }
};
