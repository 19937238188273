import arrayBufferToBuffer from './ArrayBuffer';
import accountGrouper from './accountGrouper';
import isInputCorrect from './isInputCorrect';
import createPeriods from './createPeriods';
import useDebounce from './useDebounce';
import formatFinancialYear from './formatFinancialYear';
import theme from 'theme';
import { accountStatus } from './accountGrouper';
export * from './Formatting';
export * from './numbers';

const between = (begin, middle, end) => middle >= begin && middle <= end;

export const totValue = (rowData, key) => {
  const filteredRowData = rowData.filter(val => val[key]);
  return filteredRowData.length > 0
    ? filteredRowData[filteredRowData.length - 1][key]
    : 0;
};

/**
 * Joakims mysterious specification cleaner
 */
export const cleanSpecification = (specification: Customer.Specification[]) => {
  const cleaned: Customer.Specification[] = [];

  const isEmpty = (row: Customer.Specification) => {
    return row.description === '' && row.amount === '0' && row.account === '';
  };

  const fill = (obj: Customer.Specification, key: string) => {
    if (obj[key] === '' || obj[key] === 0) {
      obj[key] = key === 'amount' ? 0 : ' ';
    }
    return obj;
  };

  specification.forEach(row => {
    if (!isEmpty(row)) {
      Object.keys(row).forEach(prop => {
        fill(row, prop);
      });
      cleaned.push(row);
    }
  });

  return cleaned;
};

export const getPeriodHeaderStatusColor = (status: accountStatus): string => {
  const colors = {
    done: theme.palette.success.main,
    unvisited: theme.palette.grey[400],
    wrong: theme.palette.error.main,
  };
  return colors[status];
};

export const getStatusColor = (status: accountStatus): string => {
  const colors = {
    doesNotExist: theme.palette.accountingView.cell.incomingBalance,
    done: theme.palette.success.main,
    unvisited: theme.palette.grey[400],
    wrong: theme.palette.overviewView.accountStatusWrong,
  };
  return colors[status];
};

export {
  arrayBufferToBuffer,
  between,
  accountGrouper,
  isInputCorrect,
  createPeriods,
  useDebounce,
  formatFinancialYear,
};
