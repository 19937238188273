import {
  AnnualReport,
  AnnualReportPart,
  AnnualReportSection,
  AnnualReportSectionArray,
  AnnualReportTable,
  AnnualReportTableRow,
  Cell,
} from 'types/AnnualReport/types';
import { ReferenceError, ResolvedReference } from 'utils/References/types';

type Refs = Record<string, ResolvedReference>;

export const updateCell = (cell: Cell, references: Refs): Cell => {
  if (cell.type === 'ref') {
    const value =
      cell.reference in references
        ? references[cell.reference]
        : ReferenceError.NotResolved;
    if (cell.value !== value) {
      return { ...cell, value };
    }
  } else if (cell.type === 'refs') {
    let updated = false;
    const newValues = cell.references.map((ref, index) => {
      const value =
        ref in references ? references[ref] : ReferenceError.NotResolved;
      if (cell.values[index] !== value) {
        updated = true;
      }
      return value;
    });
    if (newValues.length !== cell.values.length || updated) {
      return { ...cell, values: newValues };
    }
  } else if (cell.type === 'msg') {
    if (cell.parameterReferences) {
      let updated = false;
      const newParameterValues = Object.entries(
        cell.parameterReferences
      ).reduce((result, [param, ref]) => {
        const newRef =
          ref in references ? references[ref] : ReferenceError.NotResolved;
        if (param in result) {
          if (result[param] !== newRef) {
            updated = true;
            return {
              ...result,
              [param]: newRef,
            };
          }
          return result;
        } else {
          updated = true;
          return {
            ...result,
            [param]: newRef,
          };
        }
      }, cell.parameterValues || {});
      if (updated) {
        return { ...cell, parameterValues: newParameterValues };
      }
    }
  }

  return cell;
};

const updateRows = (
  rows: AnnualReportTableRow[],
  references: Refs
): AnnualReportTableRow[] => {
  let updated = false;
  const newRows = rows.map(row => {
    const newRow = updateRow(row, references);
    if (newRow !== row) {
      updated = true;
    }
    return newRow;
  });

  return updated ? newRows : rows;
};

const updateRow = (
  row: AnnualReportTableRow,
  references: Refs
): AnnualReportTableRow => {
  let newRow = row;
  if (newRow.rows) {
    const newRows = updateRows(newRow.rows, references);
    if (newRows !== newRow.rows) {
      newRow = { ...newRow, rows: newRows };
    }
  }
  if (newRow.cells) {
    const newCells = Object.keys(newRow.cells).reduce((cells, column) => {
      const cell = cells[column];
      const newCell = updateCell(cell, references);
      if (newCell !== cell) {
        return { ...cells, [column]: newCell };
      }
      return cells;
    }, newRow.cells);

    if (newCells !== newRow.cells) {
      newRow = { ...newRow, cells: newCells };
    }
  }

  return newRow;
};

const updateTable = (
  table: AnnualReportTable,
  references: Refs
): AnnualReportTable => {
  if (!table.rows) {
    console.error('Missing rows', table);
  }
  const newRows = updateRows(table.rows, references);
  if (newRows !== table.rows) {
    return { ...table, rows: newRows };
  }
  return table;
};

const updateSection = <T extends AnnualReportSection>(
  section: T,
  references: Refs
): T => {
  return Object.keys(section).reduce((newSection, key) => {
    const value = newSection[key];
    if (value) {
      if (value.rows) {
        const newTable = updateTable(value, references);
        if (newTable !== value) {
          return { ...newSection, [key]: newTable };
        }
      } else if (value.type) {
        const newField = updateCell(value, references);
        if (newField !== value) {
          return { ...newSection, [key]: newField };
        }
      }
    }
    return newSection;
  }, section);
};

const updateSections = <T extends AnnualReportSection>(
  section: AnnualReportSectionArray<T>,
  references: Refs
): AnnualReportSectionArray<T> => {
  let updated = false;
  const newSections = section.sections.map(section => {
    if (section) {
      const newSection = updateSection(section, references);
      if (newSection !== section) {
        updated = true;
        return newSection;
      }
    }
    return section;
  });
  return updated ? { ...section, sections: newSections } : section;
};

const updatePart = (
  part: AnnualReportPart,
  references: Refs
): AnnualReportPart => {
  return Object.keys(part).reduce((newPart, key) => {
    const value = newPart[key];
    if (value) {
      if (value.sections) {
        const newSection = updateSections(value, references);
        if (newSection !== value) {
          return { ...newPart, [key]: newSection };
        }
      } else {
        const newSection = updateSection(value, references);
        if (newSection !== value) {
          return { ...newPart, [key]: newSection };
        }
      }
    }
    return newPart;
  }, part);
};

const updateReportValues = (
  report: AnnualReport,
  references: Record<string, ResolvedReference>
): AnnualReport => {
  return Object.keys(report).reduce((newReport, key) => {
    const value = newReport[key];
    if (value) {
      const newPart = updatePart(value, references);
      if (newPart !== value) {
        return { ...newReport, [key]: newPart };
      }
    }
    return newReport;
  }, report);
};

export default updateReportValues;
