import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import config from '../../../config';

const Wrapper = styled.div`
  height: 90vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled(Typography)`
  font-size: 2rem;
  margin-bottom: 16px;
`;

const Version = () => {
  const [restApiVersion, setRestApiVersion] = useState({
    version: '',
    commitHash: '',
  });

  useEffect(() => {
    const fetchRestApiVersion = async () => {
      const response = await fetch(`${config.appEndpoint}/version`);
      const { commitHash, version } = await response.json();
      setRestApiVersion({ commitHash, version });
    };

    fetchRestApiVersion();
  }, []);

  return (
    <Wrapper>
      <Text>
        Webapp version:{'    '}
        <b>{config.appVersion}</b>
      </Text>
      <Text>
        Webapp commit hash:{'    '}
        <b>{config.appCommitId}</b>
      </Text>
      <Text>
        Rest API version:{'    '}
        <b>{restApiVersion.version}</b>
      </Text>
      <Text>
        Rest API commit hash:{'    '}
        <b>{restApiVersion.commitHash}</b>
      </Text>

      <Text>
        Environment:{'    '}
        <b>{config.runningEnvironment}</b>
      </Text>
    </Wrapper>
  );
};

export default Version;
