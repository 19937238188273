import React, { useRef, useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import {
  LoginBox,
  LinkWrapper,
  TextField,
  UnderlineLink,
  ActionsWrapper,
} from './SharedComponents';
import { useIntl } from 'react-intl';
import { CTAButton } from 'components/UI/Buttons';
import { useForm } from 'react-hook-form';
import { userSignUp } from 'utils/AwsAsync';
import FormError from 'components/UI/Inputs/FormError';
import { SimplifiedTextfield } from 'components/UI';

interface CreateContentProps {
  backToLogin: () => void;
  OpenConfirm: () => void;
}

const CreateContent = ({ backToLogin, OpenConfirm }: CreateContentProps) => {
  const { register, errors, handleSubmit, watch } = useForm();
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const intl = useIntl();

  const password = useRef(null);
  password.current = watch('password', '');

  const handleFormSubmit = async data => {
    const { username, password, given_name, family_name } = data;
    try {
      setLoading(true);
      await userSignUp({ username, password, given_name, family_name });
      OpenConfirm();
    } catch (error) {
      setLoading(false);
      setSubmitError(
        error.name === 'UsernameExistsException'
          ? intl.formatMessage({ id: 'register.exists' })
          : error.name === 'UserLambdaValidationException' &&
            error.message.endsWith('Domain not approved.')
          ? intl.formatMessage({ id: 'register.illegalDomain' })
          : error.name === 'InvalidParameterException' &&
            error.message.endsWith(
              'must have length greater than or equal to 6'
            )
          ? intl.formatMessage({ id: 'register.passwordTooShort' })
          : intl.formatMessage({ id: 'error' })
      );
      console.error(error);
    }
  };

  return (
    <LoginBox onSubmit={handleSubmit(handleFormSubmit)}>
      <Typography variant="h2">
        {intl.formatMessage({ id: 'create.account' })}
      </Typography>
      <Typography>
        {intl.formatMessage({ id: 'create.account.text' })}
      </Typography>

      <SimplifiedTextfield
        labelPlacement="top"
        placeholder={intl.formatMessage({ id: 'editprofile.firstName' })}
        name="given_name"
        variant="outlined"
        fullWidth
        editing={true}
        inputRef={register({
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
        })}
      />

      <FormError>{errors.given_name?.message}</FormError>

      <SimplifiedTextfield
        labelPlacement="top"
        placeholder={intl.formatMessage({ id: 'editprofile.lastName' })}
        name="family_name"
        variant="outlined"
        fullWidth
        editing={true}
        inputRef={register({
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
        })}
      />

      <FormError>{errors.family_name?.message}</FormError>

      <TextField
        variant="outlined"
        size="small"
        inputRef={register({
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
        })}
        name="username"
        placeholder={intl.formatMessage({ id: 'email.placeholder' })}
        type="email"
      />

      <FormError>{errors.username?.message}</FormError>

      <TextField
        variant="outlined"
        size="small"
        inputRef={register({
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
        })}
        name="password"
        placeholder={intl.formatMessage({ id: 'password' })}
        type="password"
      />

      <TextField
        variant="outlined"
        size="small"
        inputRef={register({
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
          validate: value =>
            value === password.current ||
            intl.formatMessage({ id: 'form.passwordNoMatch' }),
        })}
        name="extraPassword"
        placeholder={intl.formatMessage({ id: 'password.repeat' })}
        type="password"
      />

      <FormError>{errors.extraPassword?.message}</FormError>

      {submitError && <FormError>{submitError}</FormError>}

      <LinkWrapper>
        <UnderlineLink onClick={OpenConfirm}>
          {intl.formatMessage({ id: 'login.writeConformation' })}
        </UnderlineLink>
      </LinkWrapper>

      <ActionsWrapper>
        <Button onClick={backToLogin}>
          {intl.formatMessage({ id: 'forgot.back' })}
        </Button>
        <CTAButton type="submit" loading={loading}>
          {intl.formatMessage({ id: 'register.register' })}
        </CTAButton>
      </ActionsWrapper>
    </LoginBox>
  );
};

export default CreateContent;
