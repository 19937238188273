import {
  SET_ACCOUNT_LIST_DRAWER_EXPANDED,
  SET_ACCOUNT_LIST_DRAWER_MODE,
  ACCOUNT_LIST_DRAWER_ADD_ACCOUNT,
  ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT,
  ACCOUNT_LIST_DRAWER_CLEAR_SELECTION,
  ACCOUNT_LIST_DRAWER_SET_ACCOUNTS,
} from '../../actionsTypes';

import { AccountListDrawerMode } from '../../reducers/AccountListDrawer/types';

interface SetAccountListDrawerExpanded {
  type: typeof SET_ACCOUNT_LIST_DRAWER_EXPANDED;
  expanded: boolean;
}

interface SetAccountListDrawerMode {
  type: typeof SET_ACCOUNT_LIST_DRAWER_MODE;
  mode: AccountListDrawerMode;
  selectionOwner: string;
}

interface AccountListDrawerAddAccount {
  type: typeof ACCOUNT_LIST_DRAWER_ADD_ACCOUNT;
  account: string;
}

interface AccountListDrawerSetAccounts {
  type: typeof ACCOUNT_LIST_DRAWER_SET_ACCOUNTS;
  accounts: string[];
}

interface AccountListDrawerRemoveAccount {
  type: typeof ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT;
  account: string;
}

interface AccountListDrawerClearSelection {
  type: typeof ACCOUNT_LIST_DRAWER_CLEAR_SELECTION;
}

export const setAccountListDrawerExpanded = (
  expanded
): SetAccountListDrawerExpanded => ({
  type: SET_ACCOUNT_LIST_DRAWER_EXPANDED,
  expanded,
});

export const setAccountListDrawerMode = (
  mode: AccountListDrawerMode,
  selectionOwner?: string
): SetAccountListDrawerMode => ({
  type: SET_ACCOUNT_LIST_DRAWER_MODE,
  mode,
  selectionOwner:
    mode === 'selecting-accounts' && selectionOwner !== undefined
      ? selectionOwner
      : '',
});

export const addSelectedAccount = (account): AccountListDrawerAddAccount => ({
  type: ACCOUNT_LIST_DRAWER_ADD_ACCOUNT,
  account,
});

export const setSelectedAccounts = (
  accounts
): AccountListDrawerSetAccounts => ({
  type: ACCOUNT_LIST_DRAWER_SET_ACCOUNTS,
  accounts,
});

export const removeSelectedAccount = (
  account
): AccountListDrawerRemoveAccount => ({
  type: ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT,
  account,
});

export const clearAccountListDrawerSelection = (): AccountListDrawerClearSelection => ({
  type: ACCOUNT_LIST_DRAWER_CLEAR_SELECTION,
});

export type AccountListDrawerActions =
  | SetAccountListDrawerExpanded
  | SetAccountListDrawerMode
  | AccountListDrawerAddAccount
  | AccountListDrawerSetAccounts
  | AccountListDrawerRemoveAccount
  | AccountListDrawerClearSelection;
