// helpers for config
const fn = (operator: string) => (...args: (string | number)[]): string =>
  `${operator}(${args.join(',')})`;

export const multiply = fn('multiply');
export const div = fn('div');
export const sum = fn('sum');
export const or = fn('or');
export const max = fn('max');
export const min = fn('min');
export const id = (id: string) => `id(${id})`;
export const account = (name: string, modifier?: 'ib' | 'ub' | 'change') =>
  `account(${name}${modifier ? `,${modifier}` : ''})`;
export const round = (arg: string) => `round(${arg})`;
export const floor = (arg: string) => `round(sum(-0.5,${arg}))`;
