import React from 'react';
import { WithTheme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { AnnualReportTable } from 'types/AnnualReport/types';

import styled from '@emotion/styled';
import { flattenRows, includeRowInPrint } from '../../utils';
import Cell from 'components/Views/Customer/AnnualReport/UI/Cell';

interface Note1TableProps {
  table: AnnualReportTable;
  tableId: string;
  editing?: boolean;
  groupRowsInterval?: number;
  print?: boolean;
}

const GridTable = styled.div<WithTheme>`
  display: grid;
  grid-template-columns: auto 180px;
  grid-gap: ${props => props.theme.spacing(1) / 2}px;
`;

const GridTableHead = styled.div`
  display: contents;

  > *:first-of-type {
    justify-self: start;
  }
`;

const GridTableHeadCell = styled.div<WithTheme>`
  align-self: center;
  justify-self: end;
  color: ${props => props.theme.palette.grey[700]};
`;

const GridTableRow = styled.div<{ firstItemInGroup: boolean } & WithTheme>`
  display: contents;
  > * {
    padding: ${props => props.theme.spacing(1) / 2}px;
  }

  ${props =>
    props.firstItemInGroup
      ? `> * {
        margin-top: ${props.theme.spacing(4)}px;
      }`
      : ''}

  /* Zebra grid */
  &:nth-of-type(even) {
    > * {
      background-color: ${props => props.theme.palette.grey[100]};
    }
  }
  /* First column justify content left */
  > *:nth-first-of-type(1) {
    justify-content: flex-start;
  }

  /* Last column justify content right */
  > *:nth-last-of-type(1) {
    justify-content: flex-end;
  }
`;

const RowCell = styled.div<{ print: boolean } & WithTheme>`
  ${props => (props.print ? `padding: ${props.theme.spacing(0, 1)};` : '')}
  display: flex;
  min-height: 45px;
  align-items: center;
  font-size: inherit;
  line-height: 1.5rem;
`;

const Note1Table = ({
  table,
  tableId,
  editing = false,
  groupRowsInterval,
  print = false,
}: Note1TableProps) => {
  const { formatMessage } = useIntl();

  const renderRows = flattenRows(table.rows).filter(
    row => !print || includeRowInPrint(row)
  );

  return (
    <GridTable>
      <GridTableHead>
        {table.columns.map(col => (
          <GridTableHeadCell key={col.id}>
            {col.label ||
              formatMessage({
                id: `annualReport.${tableId}.${col.id}`,
                defaultMessage: col.id,
              })}
          </GridTableHeadCell>
        ))}
      </GridTableHead>

      {renderRows.map((row, index) => {
        let firstItemInGroup = false;
        if (groupRowsInterval) {
          firstItemInGroup = index % groupRowsInterval === 0 && index !== 0;
        }
        return (
          <GridTableRow key={row.id} firstItemInGroup={firstItemInGroup}>
            {row.cells && (
              <React.Fragment>
                {table.columns.map(col => {
                  const cell = row.cells?.[col.id];
                  const key = `${row.id}.${col.id}`;
                  if (!cell) {
                    return <div key={key} />;
                  }
                  return (
                    <RowCell key={key} print={print}>
                      <Cell
                        cell={cell}
                        id={`${tableId}.${key}`}
                        editing={editing}
                        print={false}
                      />
                    </RowCell>
                  );
                })}
              </React.Fragment>
            )}
          </GridTableRow>
        );
      })}
    </GridTable>
  );
};

export default Note1Table;
