import isInputCorrect from './isInputCorrect';
import { AccountInformation } from './SieParser';

export type accountStatus = 'done' | 'wrong' | 'unvisited' | 'doesNotExist';

export type AccountGroupAttribute =
  | 'a15xx'
  | 'a19xx'
  | 'a24xx'
  | 'a1630'
  | 'rest'
  | 'moms';

export interface AccountGroup {
  a15xx?: accountStatus;
  a19xx?: accountStatus;
  a24xx?: accountStatus;
  a1630?: accountStatus;
  rest?: accountStatus;
  moms?: accountStatus;
}

type GroupedAccountsType = Partial<AccountGroup>;

export type accountGrouperFunction = (
  period: string,
  sieData: { sieBlob: AccountInformation[] },
  userInput: object
) => GroupedAccountsType;

export const accountGrouper: accountGrouperFunction = (
  period,
  sieData,
  userInput
) => {
  const accountGroups: AccountGroup = {};
  let account;
  let accountGroup;
  let monthData;
  let ub;
  const formattedPeriod = `${period.slice(0, 4)}-${period.slice(4, 6)}`;

  // For every account in the sie file
  for (let i = 0; i < sieData.sieBlob.length; i++) {
    account = sieData.sieBlob[i].account;
    accountGroup = getAccountGroup(account);

    // TODO: There seems to be a problem in the sie-parser. This is a workaround
    // for when a row is corrupt and the account name is empty
    if (sieData.sieBlob[i].accountName!.trim() === '') continue;

    // If the account group has already been set to wrong in a previous
    // iteration, keep that. "Wrong" has top prio
    if (accountGroups[accountGroup] === 'wrong') continue;

    // If the account has been modified by the user in the period
    if (
      userInput[`account${account}`] &&
      userInput[`account${account}`][formattedPeriod]
    ) {
      monthData = sieData.sieBlob[i].periods.find(
        monthData => monthData.period === period
      );

      // Some accounts will not have data for every month
      if (monthData === undefined) {
        ub = sieData.sieBlob[i].yearIbs[0]?.saldo;
        if (sieData.sieBlob[i].periods.length !== 0) {
          // Get the lastest period that exists before the missing period
          let periodAsInt = parseInt(period);
          // Remove UBs that are older than the current period
          let periodsUntilNow = sieData.sieBlob[i].periods.filter(
            rowInPeriod => parseInt(rowInPeriod.period) <= periodAsInt
          );

          // Now the UB of the period closest in time to now
          if (periodsUntilNow.length !== 0) {
            ub = periodsUntilNow.reduce((prev, curr) => {
              let currPeriod = parseInt(curr.period);
              let prevPeriod = parseInt(prev.period);
              return Math.abs(currPeriod - periodAsInt) <
                Math.abs(prevPeriod - periodAsInt)
                ? curr
                : prev;
            }).ub;
          }
        }
        // Account numbers >= 3000, result accounts
        if (ub === undefined) ub = 0;
      } else {
        ub = monthData.ub;
      }
      accountGroups[accountGroup] = isInputCorrect(
        userInput[`account${account}`][formattedPeriod].saldo,
        ub
      )
        ? 'done'
        : 'wrong';
    } else {
      // If the current account has not been edited by the user, but other
      // accounts belonging to the same group are marked as "done", the overall
      // status of the group should be "wrong". If the user has not touched any
      // account in the group, the status should be unvisited
      if (accountGroups[accountGroup] === 'done') {
        accountGroups[accountGroup] = 'wrong';
      } else {
        accountGroups[accountGroup] = 'unvisited';
      }
    }
  }

  const status: Partial<AccountGroup> = {
    a15xx: 'doesNotExist',
    a19xx: 'doesNotExist',
    a24xx: 'doesNotExist',
    a1630: 'doesNotExist',
    rest: 'doesNotExist',
    moms: 'doesNotExist',
    ...accountGroups,
  };

  return status;
};

type getGroupStatusType = (group: AccountGroup) => accountStatus;

export const getGroupStatus: getGroupStatusType = group => {
  const existingStatuses = Object.values(group);

  if (existingStatuses.includes('wrong')) {
    return 'wrong';
  }

  if (existingStatuses.includes('unvisited')) {
    return existingStatuses.includes('done') ? 'wrong' : 'unvisited';
  }

  if (
    !existingStatuses.includes('unvisited') &&
    !existingStatuses.includes('wrong') &&
    !existingStatuses.includes('done')
  ) {
    return 'unvisited';
  } else {
    return 'done';
  }
};

const getAccountGroup = account => {
  const accountString = `${account}`;

  if (accountString.startsWith('15')) {
    return 'a15xx';
  } else if (accountString.startsWith('19')) {
    return 'a19xx';
  } else if (accountString.startsWith('24')) {
    return 'a24xx';
  } else if (account === '1630') {
    return 'a1630';
  } else if (account === '1650' || account === '2650') {
    return 'moms';
  } else {
    return 'rest';
  }
};

export default accountGrouper;
