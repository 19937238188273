import React, { createContext, useState } from 'react';

interface PrintContext {
  hideMainContent: boolean;
  setHideMainContent: (hidden: boolean) => void;
}

const PrintContext = createContext<PrintContext>({
  hideMainContent: false,
  setHideMainContent: _ => {},
});

export const PrintContextProvider = ({ children }) => {
  const [hideMainContent, setHideMainContent] = useState<boolean>(false);

  return (
    <PrintContext.Provider
      value={{ hideMainContent, setHideMainContent }}
    >
      {children}
    </PrintContext.Provider>
  );
};

export default PrintContext;
