import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Box, Typography, ButtonProps } from '@material-ui/core';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import {
  setAccountListDrawerExpanded,
  setAccountListDrawerMode,
  setSelectedAccounts,
} from 'redux/actions';

type AccountSelectorProps = {
  rowId: string;
  account?: string;
  onClick?: (ev) => void;
  onAccountSelected?: (
    selectedAccount: string | undefined,
    selectedAccountName: string | undefined,
    close?: () => void
  ) => void;
};

const SelectAccountButton = styled(Button)``;

const AccountSelector = React.forwardRef(
  (
    {
      rowId,
      account,
      onClick,
      onAccountSelected,
      children,
      ...buttonProps
    }: AccountSelectorProps & ButtonProps,
    ref
  ) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const selectionOwner = useSelector(
      state => state.accountListDrawer.selectionOwner
    );
    const selectedAccount = useSelector(
      state => state.accountListDrawer.selectedAccounts[0]
    );

    const accounts = useSelector(
      state => state.accountingView.sieData?.accounts
    );

    const [active, setActive] = useState(false);

    const handleClose = useCallback(() => {
      setActive(false);
      dispatch(setAccountListDrawerExpanded(false));
      dispatch(setAccountListDrawerMode('viewing'));
    }, [dispatch]);

    useEffect(() => {
      if (selectedAccount && onAccountSelected && account !== selectedAccount) {
        if (rowId === selectionOwner) {
          const accountName =
            selectedAccount && accounts?.[selectedAccount]?.accountName;
          onAccountSelected(selectedAccount, accountName, handleClose);
        }
      }
    }, [
      selectedAccount,
      onAccountSelected,
      rowId,
      selectionOwner,
      account,
      handleClose,
      accounts,
    ]);

    const handleClick = useCallback(
      ev => {
        dispatch(setSelectedAccounts(account ? [account] : []));
        dispatch(setAccountListDrawerExpanded(true));
        dispatch(setAccountListDrawerMode('selecting-accounts', rowId));
        setActive(true);
        if (onClick) onClick(ev);
      },
      [onClick, dispatch, account, rowId]
    );

    useEffect(() => {
      if (active && selectionOwner !== rowId) {
        setActive(false);
        if (onAccountSelected) {
          const accountName =
            selectedAccount && accounts?.[selectedAccount]?.accountName;
          onAccountSelected(selectedAccount, accountName);
        }
      }
    }, [
      selectionOwner,
      rowId,
      active,
      onAccountSelected,
      selectedAccount,
      accounts,
    ]);

    return (
      <Box>
        <SelectAccountButton
          onClick={handleClick}
          variant="contained"
          color="primary"
          disableElevation
          {...buttonProps}
          buttonRef={ref}
        >
          <Typography variant="button" noWrap>
            {children
              ? children
              : account
              ? intl.formatMessage({ id: 'accountSelector.changeAccount' })
              : intl.formatMessage({ id: 'accountSelector.selectAccount' })}
          </Typography>
        </SelectAccountButton>
      </Box>
    );
  }
);

export default AccountSelector;
