import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addAnnualReportRow } from 'redux/actions/AnnualReportView/actions';
import { NoteTwoTableSection } from 'types/AnnualReport/parts/notes';
import { numberValue, stringValue } from 'utils/AnnualReport/cell';
import Comment from '../Comment';
import FinancialYearsHeader from '../FinancialYearsHeader';
import Header from '../Header';
import { Note } from '../Note';
import SixColumnTable from '../SixColumnTable';
import ThreeColumnTable from '../ThreeColumnTable';

type SixColumnThreeColumnNoteProps = {
  noteId: string;
  print?: boolean;
} & NoteTwoTableSection;

const SixColumnThreeColumnNote = ({
  noteId,
  active,
  number,
  table,
  secondTable,
  comment,
  print = false,
}: SixColumnThreeColumnNoteProps) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);

  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header
        noteId={noteId}
        noteNumber={numberValue(number)}
        editing={editing}
        onEditToggle={editing => {
          setEditing(!editing);
        }}
        print={print}
      />

      <FinancialYearsHeader />

      <SixColumnTable
        table={table}
        tableId={`notes.${noteId}.table`}
        editing={editing}
        print={print}
      />

      <ThreeColumnTable
        table={secondTable}
        refTable={table}
        tableId={`notes.${noteId}.secondTable`}
        editing={editing}
        print={print}
        onAddRow={() => {
          dispatch(addAnnualReportRow(`notes.${noteId}.secondTable`));
        }}
      />

      {/* Comment */}
      <Comment
        id={`notes.${noteId}.comment`}
        editing={editing}
        value={stringValue(comment) || ''}
        print={print}
      />
    </Note>
  );
};

export default SixColumnThreeColumnNote;
