import { AccountInformation } from 'utils/SieParser';

export enum ReferenceError {
  MissingAccount = 'missingAccount',
  UnknownReferenceType = 'unknownReferenceType',
  ExpandNotSupported = 'expandNotSupported',
  TooManyValuesReturned = 'tooManyValuesReturned',
  MissingId = 'missingId',
  DivisionByZero = 'DivisionByZero',
  NotResolved = 'notResolved',
}

export interface ResolveReferenceContext {
  resolveById: (id: string, context: this) => ResolvedReference;
  resolveConfig: (name: string, context: this) => ResolvedReference;
  expandIds?: (ids: string, context: this) => string[];
  input: ResolveReferenceInput;
}

export interface ResolveReferenceInput {
  accounts: Record<string, AccountInformation>;
  period: string | null;
  periods: string[];
}

export type ResolvedReference = ReferenceError | number | undefined;
