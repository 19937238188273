import React from 'react';
import { useSelector } from 'redux/reducers';
import styled from '@emotion/styled';
import { Typography, WithTheme } from '@material-ui/core';
import BalanceSheetTable from './BalanceSheetTable';
import { useIntl } from 'react-intl';

const Content = styled.div<WithTheme>`
  min-width: 800px;
  width: min-content;
`;

const OwnPage = styled.div<WithTheme>`
  margin-bottom: ${props => props.theme.spacing(4)}px;
  page-break-after: always;
`;

const BalanceSheetPreview = () => {
  const { formatMessage } = useIntl();
  const balanceSheet = useSelector(
    state => state.annualReport.report?.balanceSheet
  );

  if (!balanceSheet) {
    return null;
  }

  const assets = balanceSheet.section.assets;
  const equityAndLiabilities = balanceSheet.section.equityAndLiabilities;

  const title = formatMessage({ id: 'annualReport.menu.balanceSheet' });

  return (
    <Content>
      <OwnPage>
        <Typography variant="h1">{title}</Typography>
        <BalanceSheetTable
          table={assets}
          tableId="balanceSheet.section.assets"
          showAccounts={false}
          print={true}
        />
      </OwnPage>
      <Typography variant="h1">{title}</Typography>
      <BalanceSheetTable
        table={equityAndLiabilities}
        tableId="balanceSheet.section.equityAndLiabilities"
        showAccounts={false}
        print={true}
      />
    </Content>
  );
};

export default BalanceSheetPreview;
