import gql from 'graphql-tag';

export const PUT_DOCUMENT = gql`
  mutation putDocument(
    $customerId: String!
    $year: String!
    $name: String!
    $contentType: String!
    $category: String!
  ) {
    putDocument(
      customerId: $customerId
      year: $year
      name: $name
      contentType: $contentType
      category: $category
    ) {
      name
      category
      url
      uploadUrl
      contentType
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument(
    $customerId: String!
    $year: String!
    $category: String!
    $name: String!
  ) {
    deleteDocument(
      customerId: $customerId
      year: $year
      category: $category
      name: $name
    )
  }
`;
