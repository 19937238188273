import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  AnnualReportTableColumn,
  AnnualReportTableRow,
} from 'types/AnnualReport/table';
import Cell from '../../UI/Cell';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import {
  addAnnualReportRow,
  deleteAnnualReportRow,
} from 'redux/actions/AnnualReportView/actions';
import DeleteButton from 'components/UI/DeleteButton';

const AddRow = styled(({ columns, ...props }) => <div {...props} />)`
  grid-column: 1 / span ${props => props.columns};
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

interface GridRowProps {
  baseId: string;
  row: AnnualReportTableRow;
  columns: AnnualReportTableColumn[];
  editing: boolean;
  print: boolean;
}

const GridRow = ({ baseId, row, columns, editing, print }: GridRowProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const rowId = `${baseId}.${row.id}`;
  const handleAddRow = () => {
    dispatch(addAnnualReportRow(rowId));
  };
  const handleDelete = () => {
    dispatch(deleteAnnualReportRow(rowId));
  };
  return (
    <>
      {columns.map((col, index) => {
        const cell = row.cells?.[col.id];
        if (!cell) {
          return <span key={col.id} />;
        }
        return (
          <Content key={col.id}>
            <Typography>
              <Cell
                cell={cell}
                id={`${rowId}.${col.id}`}
                editing={editing}
                print={print}
              />
            </Typography>
            {editing && index === columns.length - 1 && (
              <DeleteButton onClick={handleDelete} />
            )}
          </Content>
        );
      })}
      {row.rows?.map(subRow => (
        <GridRow
          key={subRow.id}
          baseId={rowId}
          row={subRow}
          columns={columns}
          editing={editing}
          print={print}
        />
      ))}
      {editing && row.newRowTemplate && (
        <AddRow columns={columns.length}>
          <Button startIcon={<AddIcon />} onClick={handleAddRow}>
            {formatMessage({ id: 'add.row' })}
          </Button>
        </AddRow>
      )}
    </>
  );
};

export default GridRow;
