import {
  TaxCalculationConfig,
  TaxCalculationInput,
  TaxView,
  TaxTable,
  TaxCalculationContext,
  TaxCalculation,
  TaxCalculationRow,
  PossibleDepositionToAccrualFund,
} from './types';
import { calculateTaxTable, calculateRow } from './calculateTaxTable';
import { shallowCompare } from 'utils/shallowCompare';
import { resolveParticularSalaryTax } from './particularSalaryTax';
import { resolveYearEndPlanning } from './yearEndPlanning';
import { isLastPeriod } from './context';
import { calculateAccrualFunds } from './calculateAccrualFunds';
import { resolveReference } from '../References/resolveReference';
import { calculateAdjustments } from './calculateAdjustments';

const resolveNonDeductibleExpenses = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): TaxTable => {
  const taxTable = calculateTaxTable(
    taxView?.nonDeductibleExpenses || context.config.nonDeductibleExpenses,
    context
  );
  if (!taxView?.nonDeductibleExpenses) {
    return taxTable;
  }
  return shallowCompare(taxView?.nonDeductibleExpenses, taxTable)
    ? taxView.nonDeductibleExpenses
    : taxTable;
};

const resolveNonTaxableIncomes = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): TaxTable => {
  const taxTable: TaxTable = calculateTaxTable(
    taxView?.nonTaxableIncomes || context.config.nonTaxableIncomes,
    context
  );

  if (!taxView?.nonTaxableIncomes) {
    return taxTable;
  }

  return shallowCompare(taxView?.nonTaxableIncomes, taxTable)
    ? taxView.nonTaxableIncomes
    : taxTable;
};

const createDefaultTaxCalculation = (
  config: TaxCalculationConfig
): TaxCalculation => ({
  ...config.taxCalculation,
  moreRows: config.taxCalculationMoreRows,
  warningLowAmount: false,
});

const adjustLabel = (
  row: TaxCalculationRow,
  resultIsAlreadyBooked: boolean
): TaxCalculationRow => {
  const newRow = {
    ...row,
    labelId: resultIsAlreadyBooked ? `${row.id}Adjustment` : row.id,
  };

  return row.labelId === newRow.labelId ? row : newRow;
};

const resolveTaxCalculationMoreRows = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): TaxCalculationRow[] => {
  const initialMoreRows =
    taxView?.taxCalculation?.moreRows || context.config.taxCalculationMoreRows;

  // Need to calculate 'alreadyBookedResult' in advance to know which
  // label to use for 'taxToBook' and 'resultToBook'
  const alreadyBookedResult = initialMoreRows.find(
    row => row.id === 'alreadyBookedResult'
  );
  let resultIsAlreadyBooked = false;
  if (alreadyBookedResult) {
    const value = calculateRow(alreadyBookedResult, context).value;
    resultIsAlreadyBooked = value !== undefined && value > 0;
  }

  const moreRows = initialMoreRows.map(row =>
    calculateRow(
      row.id === 'taxToBook' || row.id === 'resultToBook'
        ? adjustLabel(row, resultIsAlreadyBooked)
        : row,
      context
    )
  );

  if (!taxView?.taxCalculation?.moreRows) {
    return moreRows;
  }
  return shallowCompare(taxView?.taxCalculation?.moreRows, moreRows)
    ? taxView?.taxCalculation?.moreRows
    : moreRows;
};

const resolveMaxPossibleDepositionToAccrualFund = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): PossibleDepositionToAccrualFund => {
  const bookedAccrualFund = resolveReference('id(bookedAccrualFund)', context);
  const furtherDeposition =
    typeof bookedAccrualFund === 'number' && bookedAccrualFund > 0;
  const ref = furtherDeposition
    ? resolveReference('id(maxPossibleFurtherDepositionToAccrualFund)', context)
    : resolveReference('id(maxPossibleDepositionToAccrualFund)', context);

  const result: PossibleDepositionToAccrualFund = {
    value: typeof ref === 'number' ? ref : 0,
    furtherDeposition,
  };

  if (!taxView?.maxPossibleDepositionToAccrualFund) {
    return result;
  }
  return shallowCompare(taxView.maxPossibleDepositionToAccrualFund, result)
    ? taxView.maxPossibleDepositionToAccrualFund
    : result;
};

const checkLowAmount = (context: TaxCalculationContext): boolean => {
  const resultToBook = resolveReference('id(resultToBook)', context);
  if (typeof resultToBook === 'number' && Math.abs(resultToBook) <= 5) {
    const particularSalaryTaxToBook = resolveReference(
      'id(particularSalaryTaxToBook)',
      context
    );

    return (
      typeof particularSalaryTaxToBook === 'number' &&
      particularSalaryTaxToBook > 0
    );
  }
  return false;
};

const resolveTaxCalculation = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): TaxCalculation => {
  const taxTable = calculateTaxTable(
    taxView?.taxCalculation || createDefaultTaxCalculation(context.config),
    context
  );
  const moreRows = resolveTaxCalculationMoreRows(context, taxView);

  const warningLowAmount = checkLowAmount(context);
  const taxCalculation: TaxCalculation = {
    ...taxTable,
    moreRows,
    warningLowAmount,
  };

  if (!taxView?.taxCalculation) {
    return taxCalculation;
  }
  return shallowCompare(taxView?.taxCalculation, taxCalculation)
    ? taxView?.taxCalculation
    : taxCalculation;
};

export const taxCalculation = (
  config: TaxCalculationConfig,
  input: TaxCalculationInput,
  taxView: TaxView | null
): TaxView => {
  const context: TaxCalculationContext = {
    resolveById: (id, context) => {
      const row = context.rowsById[id];
      if (row) {
        if (row.value !== undefined) {
          return row.value;
        }
        if (row.reference) {
          return resolveReference(row.reference, context);
        }
      }
      return undefined;
    },
    resolveConfig: (name, context) => {
      const value = context.config[name];
      return typeof value === 'number' ? value : undefined;
    },
    config,
    rowsById: taxView?.rowsById || {},
    input,
  };

  const resultBeforeTaxes = calculateRow(
    taxView?.resultBeforeTaxes || config.resultBeforeTaxes,
    context,
    isLastPeriod(context)
  );

  const nonTaxableIncomes = resolveNonTaxableIncomes(context, taxView);
  const nonDeductibleExpenses = resolveNonDeductibleExpenses(context, taxView);
  const particularSalaryTax = isLastPeriod(context)
    ? resolveParticularSalaryTax(context, taxView)
    : null;

  const notBookedParticualSalaryTax = calculateRow(
    taxView?.notBookedParticualSalaryTax || config.notBookedParticualSalaryTax,
    context,
    !isLastPeriod(context)
  );

  const resultBeforeFrom3000to8799 = calculateRow(
    taxView?.resultBeforeFrom3000to8799 || config.resultBeforeFrom3000to8799,
    context,
    !isLastPeriod(context)
  );

  const yearEndPlanning = resolveYearEndPlanning(context, taxView);
  const accrualFundsBeforeTaxCalculation = isLastPeriod(context)
    ? calculateAccrualFunds(context, taxView?.accrualFunds || null)
    : null;

  const taxCalculation = resolveTaxCalculation(context, taxView);

  const accrualFunds = isLastPeriod(context)
    ? calculateAccrualFunds(context, accrualFundsBeforeTaxCalculation)
    : null;

  const maxPossibleDepositionToAccrualFund = isLastPeriod(context)
    ? resolveMaxPossibleDepositionToAccrualFund(context, taxView)
    : null;

  const adjustments = calculateAdjustments(context, taxView);

  const result: TaxView = {
    notBookedParticualSalaryTax,
    resultBeforeFrom3000to8799,
    yearEndPlanning,
    resultBeforeTaxes,
    taxCalculation,
    nonTaxableIncomes,
    nonDeductibleExpenses,
    particularSalaryTax,
    accrualFunds,
    maxPossibleDepositionToAccrualFund,
    adjustments,
    rowsById: taxView?.rowsById
      ? shallowCompare(context.rowsById, taxView.rowsById)
        ? taxView.rowsById
        : context.rowsById
      : context.rowsById,
  };

  if (taxView === null) {
    return result;
  }
  return shallowCompare(result, taxView) ? taxView : result;
};
