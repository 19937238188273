import React from 'react';
import styled from '@emotion/styled';
import { Typography, TypographyProps } from '@material-ui/core';

interface HeadingProps {
  id?: string;
  title: string | React.ReactElement;
  variant?: TypographyProps['variant'];
  controls?: React.ReactElement | undefined;
}

const HeadingWithControls = styled(Typography)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  min-width: 800px;
`;

const Controls = styled.div`
  flex-shrink: 0;
`;

const Heading = ({ title, variant = 'h2', controls, id }: HeadingProps) => {
  if (controls) {
    return (
      <HeadingWithControls
        variant={variant}
        color={variant === 'h2' ? 'textSecondary' : undefined}
        id={id}
      >
        <span>{title}</span>
        <Controls>{controls}</Controls>
      </HeadingWithControls>
    );
  }
  return (
    <Typography
      variant={variant}
      color={variant === 'h2' ? 'textSecondary' : undefined}
      id={id}
    >
      {title}
    </Typography>
  );
};

export default Heading;
