import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar as MuiToolbar,
  IconButton,
  WithTheme,
  Box,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import styled from '@emotion/styled';
import { ReactComponent as BetaLogo } from 'assets/AgoyLogoBETA.svg';
import {
  CustomerSubMenu,
  CustomersOverviewSubMenu,
  UserMenu,
  HelpMenu,
} from './parts';

const LogoWrapper = styled.div<WithTheme>`
  margin-top: ${props => props.theme.spacing(1)}px;
`;

// The 75px in margin-left compensate for half of the Agoy logo's width
const LogoLink = styled(Link)`
  position: absolute;
  margin-left: calc(50% - 75px);
`;

const AgoyLogo = styled(BetaLogo)`
  height: 30px;
`;

const Toolbar = styled(MuiToolbar)`
  justify-content: space-between;
`;

const Root = styled.div<WithTheme>`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${props => props.theme.zIndex.appBar};
  @media print {
    position: inherit;
    display: none;
  }

  .MuiToolbar-root {
    max-width: 100vw;
    position: sticky;
    left: 0;
  }
`;

const TopBar = styled(AppBar)<WithTheme>`
  button {
    color: ${props => props.theme.palette.common.white};
  }
`;

const Header = () => {
  const location = useLocation();

  if (location.pathname === '/login') {
    return null;
  }
  return (
    <Root>
      <TopBar position="static">
        <Toolbar>
          <Link to="/">
            <IconButton>
              <HomeIcon />
            </IconButton>
          </Link>
          <LogoLink to="/">
            <LogoWrapper aria-label="redirect to customers view">
              <AgoyLogo />
            </LogoWrapper>
          </LogoLink>

          <Box display="flex" alignItems="center">
            <HelpMenu />
            <UserMenu />
          </Box>
        </Toolbar>
      </TopBar>

      {location.pathname.startsWith('/customer') && <CustomerSubMenu />}
      {location.pathname === '/' && <CustomersOverviewSubMenu />}
    </Root>
  );
};

export default Header;
