import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { appHeaderHeight } from './measurements';
import {
  primaryMain,
  secondaryMain,
  info,
  error,
  warning,
  success,
  textPrimary,
  textSecondary,
  cellIncomingBalance,
  cellTotalChange,
  cellOutgoingBalance,
  cellOk,
  cellWarning,
  accountStatusWrong,
  white,
} from './colors';

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: ['Cabin', 'Roboto'].join(','),
    subtitle1: {
      fontSize: '0.8750rem',
    },
    h1: {
      fontSize: '2rem',
      marginTop: '2.5rem',
      marginBottom: '2.5rem',
    },
    h2: {
      fontSize: '1.75rem',
      textAlign: 'left',
      marginTop: '2.5rem',
      marginBottom: '2.5rem',
    },
    h3: {
      fontSize: '1.125rem',
      textAlign: 'left',
      marginTop: '1.25rem',
      marginBottom: '1.25rem',
    },
  },
  zIndex: {
    appHeader: 100,
    appBar: 1202,
    drawer: 1600,
    leftMenu: 40,
    taxLegend: 100,
    accountingView: {
      header: 5,
      rowHeader: 1,
      periodRow: 3,
      summaryRow: 12,
      summaryRowStickyCell: 10,
      speedDial: 20,
    },
  },
  palette: {
    type: 'light',
    background: {
      default: white,
    },
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: secondaryMain,
      contrastText: white,
    },
    info: {
      main: info,
    },
    error: {
      main: error,
    },
    warning: {
      main: warning,
    },
    success: {
      main: success,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    accountingView: {
      cell: {
        incomingBalance: cellIncomingBalance,
        totalChange: cellTotalChange,
        outgoingBalance: cellOutgoingBalance,
        ok: cellOk,
        warning: cellWarning,
      },
    },
    overviewView: {
      accountStatusWrong: accountStatusWrong,
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        height: appHeaderHeight,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
      },
    },
    MuiCircularProgress: {
      root: {},
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '16px',
      },
    },
    MuiDialog: {
      root: {
        '@media print': {
          position: 'static !important',
        },
      },
      paper: {
        '@media print': {
          maxWidth: 'unset !important',
          width: '100%',
        },
      },
    },
    MuiFab: {
      root: {
        '@media print': {
          display: 'none',
        },
      },
    },
    MuiSelect: {
      outlined: {
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
      },
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
      },
    },
  },
};

const theme = createMuiTheme(themeOptions);

export default theme;
