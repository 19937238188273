import React from 'react';
import { useIntl } from 'react-intl';
import { TextSection as TextSectionType } from 'types/AnnualReport/parts/managementReport';
import { stringValue } from 'utils/AnnualReport/cell';
import Heading from '../../../UI/Heading';
import ResetContentButton from '../../../UI/ResetContent/ResetContentButton';
import Section from '../Section';
import MultilineField from '../UI/MultilineField';

const TextSection = ({
  fullId,
  id,
  print,
  reportPart,
}: {
  fullId: string;
  id: string;
  print: boolean;
  reportPart: TextSectionType;
}) => {
  const intl = useIntl();

  const title = intl.formatMessage({
    id: `annualReport.menu.${fullId}`,
  });

  const value = stringValue(reportPart.text);
  const show = !print || value;
  if (!show) {
    return null;
  }

  return (
    <Section>
      <Heading
        id={id}
        title={title}
        controls={
          print ? undefined : <ResetContentButton id={fullId} what={title} />
        }
      />
      <MultilineField
        id={`${fullId}.text`}
        field={reportPart.text}
        print={print}
      />
    </Section>
  );
};

export default TextSection;
