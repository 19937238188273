import React from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { WithTheme, Container, Box, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { addOrganisationInfo } from 'redux/actions';
import { GET_ORGANISATION_INFO } from 'Graphql/Queries/OrganisationQueries';
import { Information, Invoicing, Payment, Members, Customers } from './Parts';
import { useOrgMembers } from 'components/Api/Client/members';

const Layout = styled(Box)`
  display: flex;
  flex: 1;

  overflow-y: auto;
`;

const OrganisationContent = styled.div<WithTheme>`
  width: 100%;
  background: ${props => props.theme.palette.background.default};
  padding-top: 1rem;
`;

const Organisation = () => {
  const dispatch = useDispatch();

  const { loading } = useQuery(GET_ORGANISATION_INFO, {
    onCompleted: data => {
      const organisationInfo = { ...data.getUserOrganisation };
      organisationInfo.invoices = JSON.parse(organisationInfo.invoices);
      dispatch(addOrganisationInfo(organisationInfo));
    },
  });

  const userEmail = useSelector(state => state.user.email);
  const admins = useSelector(state => state.organisation.admins);
  const adminMember = userEmail && admins ? admins.includes(userEmail) : false;

  const orgMembersQuery = useOrgMembers();

  return (
    <>
      <Layout>
        <OrganisationContent>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Information loadingQuery={loading} />
              </Grid>
              {adminMember && (
                <>
                  <Grid item xs={6}>
                    {loading ? <Skeleton height={'100%'} /> : <Invoicing />}
                  </Grid>
                  <Grid item xs={6}>
                    <Payment />
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <Members orgMembersQuery={orgMembersQuery} />
              </Grid>
              <Grid item xs={6}>
                <Customers orgMembersQuery={orgMembersQuery} />
              </Grid>
            </Grid>
          </Container>
        </OrganisationContent>
      </Layout>
    </>
  );
};

export default Organisation;
