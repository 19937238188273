import { Notes } from 'types/AnnualReport/parts/notes';
import { id, or, sum } from 'utils/References/helpers';
import { label, table, field, value, active, activeTable, ref } from './util';

const generateTwoColumnTableRows = rows => {
  return rows
    .addRow(
      '1',
      label('Ingående anskaffningsvärden'),
      value(undefined),
      value(undefined)
    )
    .addRow('2', label('Inköp'), value(undefined), value(undefined))
    .addRow(
      '3',
      label('Försäljningar/utrangeringar'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '4',
      label('Omklassificeringar'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '5',
      label('Utgående anskaffningsvärden'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '6',
      label('Ingående avskrivningar'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '7',
      label('Återförda avskrivningar på försäljningar/utrustning'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '8',
      label('Omklassificeringar'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '9',
      label('Årets avskrivningar'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '10',
      label('Utgående avskrivningar'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '11',
      label('Ingående nedskrivningar'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '12',
      label('Återförda nedskrivningar på försäljningar/utrustning'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '13',
      label('Återförda nedskrivningar'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '14',
      label('Omklassificeringar'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '15',
      label('Årets nedskrivningar'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '16',
      label('Utgående nedskrivningar'),
      value(undefined),
      value(undefined)
    )
    .addRow('17', label('Redovisat värde'), value(undefined), value(undefined))
    .addRow(
      '18',
      label('Tillgångar anskaffade med offentligt bidrag'),
      value(undefined),
      value(undefined)
    )
    .addRow(
      '19',
      label('Ingår med redovisat anskaffningsvärde'),
      value(undefined),
      value(undefined)
    );
};

const year = (financialYear): string => financialYear.substring(7, 11);

export const notesConfig = (financialYear: string): Notes => ({
  type: 'part',
  note1: {
    active: true,
    number: value(1),
    name: value('Redovisningsprinciper'),
    disclaimer: field(
      'Årsredovisningen är upprättad i enlighet med årsredovisningslagen och bokföringsnämndens allmännaråd (BFNAR2016:10) om årsredovisning i mindre företag.'
    ),
    tangibleFixedAssets: activeTable(
      table(
        'notes.note1.tangibleFixedAssets',
        'appliedDeprecationPeriods',
        'numberOfYears'
      )
    )
      .addRows(rows =>
        rows
          .addRow('1', label('Byggnader och mark'), value(undefined))
          .addRow(
            '2',
            label('Maskiner och andra tekniska anläggningar'),
            value(undefined)
          )
          .addRow(
            '3',
            label('Inventarier, verktyg och installationer'),
            value(undefined)
          )
          .addRow(
            '4',
            label('Förbättringsutgifter på annans fastighet'),
            value(undefined)
          )
          .addRow(
            '5',
            label('Övriga materiella anläggningstillgångar'),
            value(undefined)
          )
          .build()
      )
      .build(),
    receivables: active(
      field(
        'Fordringar har upptagits till de belopp var med de beräknas inflyta.'
      )
    ),
    otherAssetsProvisionsAndLiabilities: active(
      field(
        'Övriga tillgångar, avsättningar och skulder har värderats till anskaffningsvärden om inget annat anges nedan.'
      )
    ),
    revenueRecognition: active(
      field(
        'Inkomsten redovisas till det verkliga värdet av vad som erhållits eller kommer att erhållas. Företaget redovisar därför inkomsten till nominellt värde (fakturabelopp) om ersättningen erhålls i likvida medel direkt vid leverans. Avdrag görs för lämnade rabatter.'
      )
    ),
    saleOfGoods: active(
      field(
        'Försäljning av varor redovisas när företaget till köparen har överfört de väsentliga risker och förmåner som är förknippade med ägandet, normalt när kunden har varorna i sin besittning. Intäkter från försäljning av varor som inte har några betydande serviceförpliktelser redovisas vid leverans.'
      )
    ),
    services: active(
      field(
        'Intäkter från konsulttjänster intäktsredovisas när tjänsterna tillhandahålls.'
      )
    ),
    rents: active(
      field(
        'Hyresintäkter från uthyrning av företagets lokaler redovisas linjärt över leasingperioden.'
      )
    ),
    interestRoyaltyAndDividens: active(
      field(
        'Ränta redovisas med tillämpning av effektiv räntemetoden. Royalty periodiseras i enlighet med den aktuella överenskommelsens ekonomiska innebörd. Utdelning redovisas när aktieägarens rätt att få utdelning bedöms som säker.'
      )
    ),
    intangibleAssetsGeneral: active(
      field(
        'Immateriella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjande period. Nyttjandeperioden omprövas per varje balansdag. Pågående projekt skrivs inte av utan nedskrivningsprövas årligen. Följande nyttjande perioder tillämpas:'
      )
    ),
    intangibleAssetsTable: activeTable(
      table('notes.note1.intangibleAssetsTable', 'empty', 'numberOfYears')
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label(
              'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
            ),
            value(undefined)
          )
          .addRow(
            '2',
            label(
              'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
            ),
            value(undefined)
          )
          .addRow(
            '3',
            label('Hyresrätter och liknande rättigheter'),
            value(undefined)
          )
          .addRow('4', label('Goodwill'), value(undefined))
          .build()
      )
      .build(),
    intangibleAssetsProjectsClosed: active(
      field('Inga projekt var avslutade per bokslutsdatum.')
    ),
    incomeTaxParagraph1: active(
      field(
        'Det förelåg inget skattepliktigt resultat efter att ej avdragsgilla kostnader och ej skattepliktiga intäkter lagts till bolagets negativa redovisningsmässiga resultat.'
      )
    ),
    incomeTaxParagraph2: active(
      field(
        'Uppskjuten skatt är inkomstskatt för skattepliktigt resultat avseende framtida räkenskapsår till följd av tidigare transaktioner eller händelser. Uppskjuten skatt beräknas på temporära skillnader. En temporär skillnad finns när det redovisade värdet på en tillgång eller skuld skiljer sig från det skattemässiga värdet. Temporära skillnader beaktas ej i skillnader hänförliga till investeringar i dotterföretag, filialer, intresseföretag eller joint venture omf öretaget kan styra tidpunkten för återföring av de temporära skillnaderna och det inte är uppenbart att den temporära skillnaden kommer att återföras inom en överskådlig framtid. Skillnader som härrör från den första redovisningen av goodwill eller vid den första redovisningen skuld eller tillgång såvida inte den hänförliga transaktionen är ett rörelseförvärv eller påverkar skatt eller redovisat resultat utgör inte heller temporära. Uppskjuten skattefordran avseende underskottsavdrag eller andra framtida skattemässiga avdrag redovisas i den omfattning det är sannolikt att avdragen kan avräknas mot framtida skattemässiga överskott.'
      )
    ),
    incomeTaxParagraph3: active(
      field(
        'Företaget har inga temporära skillnader för utom de som återspeglas i obeskattade reserver.'
      )
    ),
    incomeTaxParagraph4: active(
      field(
        'Uppskjutna skatteskulder som är hänförlig till obeskattade reserver särredovisas inte, obeskattade reserver redovisas med bruttobeloppet i balansräkningen.'
      )
    ),
    incomeTaxParagraph5: active(
      field(
        'För poster som redovisas i resultaträkningen redovisas där med sammanhängande skatt i resultaträkningen. För poster som redovisas direkt mot eget kapital redovisas även skatten direkt mot eget kapital.'
      )
    ),
    comment: field(''),
  },
  note2: {
    active: false,
    number: value(undefined),
    name: value('Medelantal anställda'),
    avgNumberOfEmployees: field(
      'Medelantalet anställda bygger på av bolaget betalda närvarotimmar relaterade till en normal arbetstid.'
    ),
    avgNumberOfEmployeesTable: table(
      'notes.note2.avgNumberOfEmployeesTable',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Medelantal anställda har varit'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            label('Varav kvinnor'),
            value(undefined),
            value(undefined)
          )
          .addRow('3', label('Varav män'), value(undefined), value(undefined))
          .build()
      )
      .build(),
    comment: field(''),
  },
  note3: {
    active: false,
    number: value(undefined),
    name: value('Resultat från övriga finansiella anläggningstillgångar'),
    table: table(
      'notes.note3.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ränteintäkter och liknande resultatposter som avser'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note4: {
    active: false,
    number: value(undefined),
    name: value('Övriga ränteintäkter och liknande resultatposter'),
    table: table(
      'notes.note4.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ränteintäkter och liknande resultatposter som avser'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note5: {
    active: false,
    number: value(undefined),
    name: value('Räntekostnader och liknande resultatposter'),
    table: table(
      'notes.note5.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ränteintäkter och liknande resultatposter som avser'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note6: {
    active: false,
    number: value(undefined),
    name: value(
      'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
    ),
    table: table(
      `notes.note6.table`,
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows => generateTwoColumnTableRows(rows).build())
      .build(),
    comment: field(''),
  },
  note7: {
    active: false,
    number: value(undefined),
    name: value('Hyresrätter och liknande rättigheter'),
    table: table(
      `notes.note7.table`,
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows => generateTwoColumnTableRows(rows).build())
      .build(),
    comment: field(''),
  },
  note8: {
    active: false,
    number: value(undefined),
    name: value('Goodwill'),
    table: table(
      `notes.note8.table`,
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows => generateTwoColumnTableRows(rows).build())
      .build(),
    comment: field(''),
  },
  note9: {
    active: false,
    number: value(undefined),
    name: value('Förskott avseende immateriella anläggningstillgångar'),
    table: table(
      'notes.note9.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ingående förskott'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '3',
            label('Under året utbetalda förskott'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Redovisat värde'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note10: {
    active: false,
    number: value(undefined),
    name: value('Byggnader och mark'),
    table: table(
      `notes.note10.table`,
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        generateTwoColumnTableRows(rows)
          .addRow(
            '20',
            label('Taxeringsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow('21', label('Mark'), value(undefined), value(undefined))
          .addRow('22', label('Byggnader'), value(undefined), value(undefined))
          .build()
      )
      .build(),
    comment: field(''),
  },
  note11: {
    active: false,
    number: value(undefined),
    name: value('Maskiner och andra tekniska anläggningar'),
    table: table(
      `notes.note11.table`,
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows => generateTwoColumnTableRows(rows).build())
      .build(),
    comment: field(''),
  },
  note12: {
    active: false,
    number: value(undefined),
    name: value('Inventarier, verktyg och installationer'),
    table: table(
      `notes.note12.table`,
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows => generateTwoColumnTableRows(rows).build())
      .build(),
    comment: field(''),
  },
  note13: {
    active: false,
    number: value(undefined),
    name: value('Förbättringsutgifter på annans fastighet'),
    table: table(
      `notes.note13.table`,
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows => generateTwoColumnTableRows(rows).build())
      .build(),
    comment: field(''),
  },
  note14: {
    active: false,
    number: value(undefined),
    name: value('Övriga materiella anläggningstillgångar'),
    table: table(
      `notes.note14.table`,
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows => generateTwoColumnTableRows(rows).build())
      .build(),
    comment: field(''),
  },
  note15: {
    active: false,
    number: value(undefined),
    name: value(
      'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
    ),
    table: table(
      'notes.note15.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ingående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow('2', label('Inköp'), value(undefined), value(undefined))
          .addRow(
            '3',
            label('Försäljningar/utrangeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Utgående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            label('Ingående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            label('Återförda nedskrivningar på försäljningar/utrustning'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '13',
            label('Återförda nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '14',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '15',
            label('Årets nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '16',
            label('Utgående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '17',
            label('Redovisat värde'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '18',
            label('Tillgångar anskaffade med offentligt bidrag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '19',
            label('Ingår med redovisat anskaffningsvärde'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note16: {
    active: false,
    number: value(undefined),
    name: value('Andelar i koncernföretag'),
    table: table(
      'notes.note16.table',
      { id: 'company', label: 'Företag' },
      { id: 'orgNr', label: 'Org. nr' },
      { id: 'location', label: 'Säte' },
      { id: 'equityShare', label: 'Kapital andel' },
      { id: 'carryingAmountYear', label: 'Redovisat värde' },
      { id: 'carryingAmountPreviousYear', label: 'Redovisat värde' }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            value(''),
            value(''),
            value(''),
            value(''),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    secondTable: table(
      'notes.note16.secondTable',
      { id: 'companyName', label: '' },
      { id: 'equity', label: 'Eget kapital' },
      { id: 'result', label: 'Resultat' }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ingående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow('2', label('Inköp'), value(undefined), value(undefined))
          .addRow(
            '3',
            label('Försäljningar/utrangeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Utgående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            label('Ingående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            label('Återförda nedskrivningar på försäljningar/utrustning'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '13',
            label('Återförda nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '14',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '15',
            label('Årets nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '16',
            label('Utgående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '17',
            label('Redovisat värde'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '18',
            label('Tillgångar anskaffade med offentligt bidrag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '19',
            label('Ingår med redovisat anskaffningsvärde'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    comment: field(''),
  },
  note17: {
    active: false,
    number: value(undefined),
    name: value('Fordringar hos koncernföretag'),
    table: table(
      'notes.note17.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ingående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            label('Årets lämnade lån'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '3',
            label('Årets amorteringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Årets valutakursdifferenser'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '6',
            label('Utgående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '7',
            label('Ingående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '8',
            label('Amorteringar, avgående fordringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '9',
            label('Återförda nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '10',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            label('Årets nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            label('Årets valutakursdifferenser'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '13',
            label('Utgående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '14',
            label('Redovisat värde'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note18: {
    active: false,
    number: value(undefined),
    name: value('Andelar i intresseföretag och gemensamt styrda företag'),
    table: table(
      'notes.note18.table',
      { id: 'company', label: 'Företag' },
      { id: 'orgNr', label: 'Org. nr' },
      { id: 'location', label: 'Säte' },
      { id: 'equityShare', label: 'Kapital andel' },
      { id: 'carryingAmountYear', label: 'Redovisat värde' },
      { id: 'carryingAmountPreviousYear', label: 'Redovisat värde' }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            value(''),
            value(''),
            value(''),
            value(''),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    secondTable: table(
      'notes.note18.secondTable',
      { id: 'companyName', label: '' },
      { id: 'equity', label: 'Eget kapital' },
      { id: 'result', label: 'Resultat' }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ingående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow('2', label('Inköp'), value(undefined), value(undefined))
          .addRow(
            '3',
            label('Försäljningar/utrangeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Utgående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            label('Ingående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            label('Återförda nedskrivningar på försäljningar/utrustning'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '13',
            label('Återförda nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '14',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '15',
            label('Årets nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '16',
            label('Utgående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '17',
            label('Redovisat värde'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '18',
            label('Tillgångar anskaffade med offentligt bidrag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '19',
            label('Ingår med redovisat anskaffningsvärde'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    comment: field(''),
  },
  note19: {
    active: false,
    number: value(undefined),
    name: value('Fordringar hos intresseföretag och gemensamt styrda företag'),
    table: table(
      'notes.note19.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ingående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            label('Årets lämnade lån'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '3',
            label('Årets amorteringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Årets valutakursdifferenser'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '6',
            label('Utgående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '7',
            label('Ingående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '8',
            label('Amorteringar, avgående fordringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '9',
            label('Återförda nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '10',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            label('Årets nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            label('Årets valutakursdifferenser'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '13',
            label('Utgående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '14',
            label('Redovisat värde'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note20: {
    active: false,
    number: value(undefined),
    name: value('Ägarintressen i övriga företag'),
    table: table(
      'notes.note20.table',
      { id: 'company', label: 'Företag' },
      { id: 'orgNr', label: 'Org. nr' },
      { id: 'location', label: 'Säte' },
      { id: 'equityShare', label: 'Kapital andel' },
      { id: 'carryingAmountYear', label: 'Redovisat värde' },
      { id: 'carryingAmountPreviousYear', label: 'Redovisat värde' }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            value(''),
            value(''),
            value(''),
            value(''),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    secondTable: table(
      'notes.note20.secondTable',
      { id: 'companyName', label: '' },
      { id: 'equity', label: 'Eget kapital' },
      { id: 'result', label: 'Resultat' }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ingående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow('2', label('Inköp'), value(undefined), value(undefined))
          .addRow(
            '3',
            label('Försäljningar/utrangeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Utgående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            label('Ingående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            label('Återförda nedskrivningar på försäljningar/utrustning'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '13',
            label('Återförda nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '14',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '15',
            label('Årets nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '16',
            label('Utgående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '17',
            label('Redovisat värde'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '18',
            label('Tillgångar anskaffade med offentligt bidrag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '19',
            label('Ingår med redovisat anskaffningsvärde'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    comment: field(''),
  },
  note21: {
    active: false,
    number: value(undefined),
    name: value(
      'Fordringar hos övriga företag som det finns ett ägarintresse i'
    ),
    table: table(
      'notes.note21.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ingående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            label('Årets lämnade lån'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '3',
            label('Årets amorteringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Årets valutakursdifferenser'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '6',
            label('Utgående ackumulerade anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '7',
            label('Ingående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow('8', label('Avgående'), value(undefined), value(undefined))
          .addRow(
            '9',
            label('Återförda nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '10',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            label('Årets nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            label('Årets valutakursdifferenser'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '13',
            label('Utgående ackumulerade nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '14',
            label('Utgående redovisat värde'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note22: {
    active: false,
    number: value(undefined),
    name: value('Andra långfristiga värdepappersinnehav'),
    table: table(
      'notes.note22.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ingående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow('2', label('Inköp'), value(undefined), value(undefined))
          .addRow(
            '3',
            label('Försäljningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Utgående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '6',
            label('Ingående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '7',
            label('Återförda nedskrivningar på försäljningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '8',
            label('Återförda nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '9',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            label('Årets nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            label('Utgående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '13',
            label('Redovisat värde'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note23: {
    active: false,
    number: value(undefined),
    name: value('Lån till delägare eller närstående'),
    table: table(
      'notes.note23.table',
      { id: 'label', label: 'Namn på närstående/anknytning till företaget' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows.addRow('1', value(''), value(''), value('')).build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    secondTable: table(
      'notes.note23.secondTable',
      { id: 'label', label: '' },
      { id: 'year', label: '' },
      { id: 'previousYear', label: '' }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ingående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            label('Årets lämnade lån'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '3',
            label('Årets amorteringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Utgående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '6',
            label('Ingående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '7',
            label('Amorteringar, avgående fordringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '8',
            label('Återförda nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '9',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '10',
            label('Årets nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            label('Utgående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            label('Redovisat värde'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note24: {
    active: false,
    number: value(undefined),
    name: value('Andra långfristiga fordringar'),
    table: table(
      'notes.note24.table',
      { id: 'label', label: '' },
      { id: 'year', label: '' },
      { id: 'previousYear', label: '' }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ingående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            label('Årets lämnade lån'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '3',
            label('Årets amorteringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Utgående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '6',
            label('Ingående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '7',
            label('Amorteringar, avgående fordringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '8',
            label('Återförda nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '9',
            label('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '10',
            label('Årets nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            label('Utgående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            label('Redovisat värde'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note25: {
    active: false,
    number: value(undefined),
    name: value('Pågående arbeten för annans räkning'),
    table: table(
      'notes.note25.table',
      { id: 'label', label: 'Pågående arbeten för annans räkning' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Aktiverade nedlagda utgifter'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            label('Fakturerade delbelopp'),
            value(undefined),
            value(undefined)
          )

          .build()
      )
      .build(),
    sumTable: table(
      'notes.note25.sumTable',
      { id: 'label', label: '' },
      { id: 'year', label: '' },
      { id: 'previousYear', label: '' }
    )
      .addRows(rows =>
        rows
          .addRow(
            'sum',
            label('Summa'),
            ref(or(sum(id('notes.note25.table.*.year')), 0)),
            ref(or(sum(id('notes.note25.table.*.previousYear')), 0))
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note26: {
    active: false,
    number: value(undefined),
    name: value('Uppskrivningsfond'),
    table: table(
      'notes.note26.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow('1', label('Att fördela'), value(undefined), value(undefined))
          .addRow(
            '2',
            label('Belopp vid årets ingång'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '3',
            label('Årets uppskrivning'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Avskrivning på uppskrivet belopp'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Nedskrivning på uppskrivet belopp'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '6',
            label('Försäljning/utrangering'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '7',
            label('Fondemission'),
            value(undefined),
            value(undefined)
          )
          .addRow('8', label('Nyemission'), value(undefined), value(undefined))
          .addRow(
            '9',
            label('Täckning av förlust'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '10',
            label('Belopp vid årets utgång'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note27: {
    active: false,
    number: value(undefined),
    name: value('Långfristiga skulder'),
    table: table(
      'notes.note27.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            'toDistribute',
            label('Att fördela'),
            value(undefined),
            value(undefined)
          )
          .addRow('content')
          .addSubRows(rows =>
            rows
              .addRow(
                '1',
                label('Förfaller mellan 2 och 5 år'),
                value(undefined),
                value(undefined)
              )
              .addRow(
                '2',
                label('Förfaller senare än 5 år'),
                value(undefined),
                value(undefined)
              )
              .build()
          )
          .build()
      )
      .build(),
    sumTable: table(
      'notes.note27.sumTable',
      { id: 'label', label: '' },
      { id: 'year', label: '' },
      { id: 'previousYear', label: '' }
    )
      .addRows(rows =>
        rows
          .addRow(
            'sum',
            label('Summa'),
            ref(or(sum(id('notes.note27.table.content.*.year')), 0)),
            ref(or(sum(id('notes.note27.table.content.*.previousYear')), 0))
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note28: {
    active: false,
    number: value(undefined),
    name: value('Skulder som avser flera poster'),
    general: field(
      'Företagets banklån/skuld om yyy kronor redovisas under följande poster i balansräkningen.'
    ),
    table: table(
      'notes.note28.table',
      { id: 'label', label: 'Långfristiga skulder' },
      { id: 'year', label: '' },
      { id: 'previousYear', label: '' }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Obligationslån'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            label('Övriga skulder till kreditinstitut'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '3',
            label('Skulder till koncernföretag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Skulder till intresseföretag och gemensamt styrda företag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Skulder till övriga företag det finns ägarintresse i'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '6',
            label('Övriga skulder'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    secondTable: table(
      'notes.note28.secondTable',
      { id: 'label', label: 'Kortfristiga skulder' },
      { id: 'year', label: '' },
      { id: 'previousYear', label: '' }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Övriga skulder till kreditinstitut'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            label('Skulder till koncernföretag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '3',
            label('Skulder till intresseföretag och gemensamt styrda företag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Skulder till övriga företag det finns ägarintresse i'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Övriga skulder'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    sumTable: table(
      'notes.note28.sumTable',
      { id: 'label', label: '' },
      { id: 'year', label: '' },
      { id: 'previousYear', label: '' }
    )
      .addRows(rows =>
        rows
          .addRow(
            'sum',
            label('Summa'),
            ref(
              or(
                sum(
                  sum(
                    id('notes.note28.table.*.year'),
                    id('notes.note28.secondTable.*.year')
                  )
                ),
                0
              )
            ),
            ref(
              or(
                sum(
                  sum(
                    id('notes.note28.table.*.previousYear'),
                    id('notes.note28.secondTable.*.previousYear')
                  )
                ),
                0
              )
            )
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note29: {
    active: false,
    number: value(undefined),
    name: value('Långfristiga konvertibla lån'),
    table: table(
      'notes.note29.table',
      { id: 'morgages', label: 'Lån' },
      { id: 'terms', label: 'Tid och villkor' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow('1', value(''), value(''), value(undefined), value(undefined))
          .addRow('2', value(''), value(''), value(undefined), value(undefined))
          .build()
      )
      .newRowTemplate(value(''), value(''), value(undefined), value(undefined))
      .build(),
    sumTable: table(
      'notes.note29.sumTable',
      { id: 'morgages', label: '' },
      { id: 'terms', label: '' },
      { id: 'year', label: '' },
      { id: 'previousYear', label: '' }
    )
      .addRows(rows =>
        rows
          .addRow(
            'sum',
            label('Summa'),
            label(''),
            ref(or(sum(id('notes.note29.table.*.year')), 0)),
            ref(or(sum(id('notes.note29.table.*.previousYear')), 0))
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note30: {
    active: false,
    number: value(undefined),
    name: value('Checkräkningskredit'),
    table: table(
      'notes.note30.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Beviljad checkräkningskredit uppgår till'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note31: {
    active: false,
    number: value(undefined),
    name: value('Byggnadskreditiv'),
    table: table(
      'notes.note31.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Beviljad byggnadskreditiv uppgår till'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note32: {
    active: false,
    number: value(undefined),
    name: value('Kortfristiga konvertibla lån'),
    table: table(
      'notes.note32.table',
      { id: 'morgages', label: 'Lån' },
      { id: 'terms', label: 'Tid och villkor' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow('1', value(''), value(''), value(undefined), value(undefined))
          .addRow('2', value(''), value(''), value(undefined), value(undefined))
          .build()
      )
      .newRowTemplate(value(''), value(''), value(undefined), value(undefined))
      .build(),
    sumTable: table(
      'notes.note32.sumTable',
      { id: 'morgages', label: '' },
      { id: 'terms', label: '' },
      { id: 'year', label: '' },
      { id: 'previousYear', label: '' }
    )
      .addRows(rows =>
        rows
          .addRow(
            'sum',
            label('Summa'),
            label(''),
            ref(or(sum(id('notes.note32.table.*.year')), 0)),
            ref(or(sum(id('notes.note32.table.*.previousYear')), 0))
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note33: {
    active: false,
    number: value(undefined),
    name: value('Ställda säkerheter'),
    table: table(
      'notes.note33.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Fastighetsinteckningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            label('Varav till förmån till koncernföretag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '3',
            label('Varav till förmån till intresseföretag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            label('Varav till förmån för gemensamt styrda företag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            label('Företagsinteckningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '6',
            label('Varav till förmån till koncernföretag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '7',
            label('Varav till förmån till intresseföretag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '8',
            label('Varav till förmån för gemensamt styrda företag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '9',
            label('Tillgångar med äganderättsförbehåll'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '10',
            label('Varav till förmån till koncernföretag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            label('Varav till förmån till intresseföretag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            label('Varav till förmån för gemensamt styrda företag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '13',
            label('Belånade fordringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '14',
            label('Varav till förmån till koncernföretag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '15',
            label('Varav till förmån till intresseföretag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '16',
            label('Varav till förmån för gemensamt styrda företag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '17',
            label('Andra ställda säkerheter'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '18',
            label('Varav till förmån till koncernföretag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '19',
            label('Varav till förmån till intresseföretag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '20',
            label('Varav till förmån för gemensamt styrda företag'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    sumTable: table(
      'notes.note33.sumTable',
      { id: 'label', label: '' },
      { id: 'year', label: '' },
      { id: 'previousYear', label: '' }
    )
      .addRows(rows =>
        rows
          .addRow(
            'sum',
            label('Summa ställda säkerheter'),
            ref(or(sum(id('notes.note33.table.*.year')), 0)),
            ref(or(sum(id('notes.note33.table.*.previousYear')), 0))
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note34: {
    active: false,
    number: value(undefined),
    name: value('Eventual förpliktelser'),
    table: table(
      'notes.note34.table',
      { id: 'label', label: '' },
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
    )
      .addRows(rows =>
        rows.addRow('1', value(''), value(undefined), value(undefined)).build()
      )
      .newRowTemplate(value(''), value(''), value(undefined), value(undefined))
      .build(),
    sumTable: table(
      'notes.note34.sumTable',
      { id: 'label', label: '' },
      { id: 'year', label: '' },
      { id: 'previousYear', label: '' }
    )
      .addRows(rows =>
        rows
          .addRow(
            'sum',
            label('Summa'),
            ref(or(sum(id('notes.note34.table.*.year')), 0)),
            ref(or(sum(id('notes.note34.table.*.previousYear')), 0))
          )
          .build()
      )
      .build(),
    secondTable: table(
      'notes.note34.secondTable',
      { id: 'label', label: '' },
      { id: 'year', label: '' },
      { id: 'previousYear', label: '' }
    )
      .addRows(rows =>
        rows
          .addRow(
            '1',
            label('Ingående anskaffningsvärden'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            label('Årets lämnade lån'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '3',
            label('Årets amorteringar'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note35: {
    active: false,
    number: value(undefined),
    name: value('Väsentliga händelser efter räkenskapsårets slut'),
    inputField: field(''),
    comment: field(''),
  },
  note36: {
    active: false,
    number: value(undefined),
    name: value('Ekonomiska arrangemang som inte redovisas i balansräkningen'),
    inputField: field(''),
    comment: field(''),
  },
  note37: {
    active: false,
    number: value(undefined),
    name: value('Koncernförhållanden'),
    table: table('notes.note37.table', { id: 'label', label: '' })
      .addRows(rows =>
        rows
          .addRowActive(false)
          .addRow(
            '1',
            value(
              'Bolaget är helägt dotterbolag till XXX AB, Org.nr XXXXXXXXX-XXXX, säte XXX'
            )
          )
          .addRowActive(false)
          .addRow(
            '2',
            value(
              'Bolaget är moderbolag, men med stöd av ÅRL 7 kap 2§ upprättas inte någon koncernredovisning.'
            )
          )
          .addRowActive(false)
          .addRow(
            '3',
            value(
              'Bolaget är moderbolag, men med stöd av ÅRL 7 kap 3§ upprättas inte någon koncern redovisning.'
            )
          )
          .addRowActive(false)
          .addRow(
            '4',
            value(
              'Övergripande koncernredovisning upprättas av: Namn, Org.nr, säte'
            )
          )
          .addRowActive(false)
          .addRow(
            '5',
            value('Minsta koncernredovisning upprättas av: Namn, Org.nr, säte')
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note38: {
    active: false,
    number: value(undefined),
    name: value('Definition av nyckeltal'),
    table: table('notes.note38.table', { id: 'label', label: '' })
      .addRows(rows =>
        rows
          .addRowActive(false)
          .addRow(
            '1',
            value(
              'Soliditet: Justera eget kapital i procent av balansomslutning'
            )
          )
          .addRowActive(false)
          .addRow(
            '2',
            value(
              'Avkastning på eget kapital: Resultat efter finansiella poster i procent av genomsnittligt justerat eget kapital'
            )
          )
          .addRowActive(false)
          .addRow(
            '3',
            value(
              'Avkastning på totalt kapital: Rörelseresultat med tillägg för finansiella intäkter, i procent av genomsnittlig balansomslutning'
            )
          )
          .addRowActive(false)
          .addRow(
            '4',
            value(
              'Kassalikviditet: Omsättningstillgångar exklusive varulager i procent av kortfristiga fordringar'
            )
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  note39: {
    active: false,
    number: value(undefined),
    name: value('Bokslutsrapport'),
    table: table('notes.note39.table', { id: 'label', label: '' })
      .addRows(rows =>
        rows
          .addRowActive(false)
          .addRow(
            '1',
            value(
              'För denna årsredovisning har en bokslutsrapport upprättats av (Förnamn Efternamn, Redovisningsbyrån AB), som är auktoriserad redovisningskonsult genom medlemskap i (FAR/SRF)'
            )
          )
          .build()
      )
      .build(),
    comment: field(''),
  },
  custom: {
    sections: [],
    newSectionTemplate: {
      active: false,
      number: { type: 'number', value: 0 },
      name: { type: 'string', value: 'Ny not' },
      typeOne: field('Egen fritext'),
      typeTwo: table(
        'notes.custom.typeTwoTable',
        'label',
        { id: 'year', label: year(financialYear) },
        { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
      )
        .newRowTemplate(value('$label'), value('$year'), value('$previousYear'))
        .build(),
      typeThree: table(
        'notes.custom.typeThreeTable',
        'label',
        'val1',
        'val2 ',
        'val3',
        { id: 'year', label: year(financialYear) },
        { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` }
      )
        .newRowTemplate(
          value('$label'),
          value('$val1'),
          value('$val2'),
          value('$val3'),
          value('$year'),
          value('$previousYear')
        )
        .build(),
    },
  },
});

export const notesReferences = (): Record<string, string> => ({});
