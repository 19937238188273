import gql from 'graphql-tag';

export const GET_ALL_CUSTOMERS = gql`
  query allCustomers {
    allCustomers {
      customers {
        sortKey
        name
        type
        closingPeriod
        closingMonth
        manager
        vatPeriod
        financialYears
        lamps
        address
        contact
        email
        number
        orgNumber
        logo
        status
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query getCustomer($customerId: String!) {
    getCustomer(customerId: $customerId) {
      sortKey
      name
      type
      closingPeriod
      closingMonth
      manager
      vatPeriod
      financialYears
      lamps
      address
      contact
      email
      number
      orgNumber
      logo
      status
    }
  }
`;
