import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Typography, IconButton, WithTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import Logo from 'components/UI/Logo';

const ButtonWrapper = styled.div<WithTheme>`
  margin-top: ${props => props.theme.spacing(1)}px;
  align-self: stretch;
`;

const InfoRowTitle = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 1.5rem;
`;

const MinimizedInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  padding: 1rem;
  align-items: center;

  > * {
    margin: 0 2rem 0 0;
  }
`;

const Grid = styled.div`
  margin-bottom: 1.25rem;
  display: grid;
  grid-template-columns: auto 100px auto auto;
  align-items: center;
  align-self: flex-end;
  grid-column-gap: 1rem;
  grid-auto-rows: minmax(min-content, max-content);
`;

const InfoRow = styled.div<WithTheme>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  padding: 0 ${props => props.theme.spacing(1)}px;
`;

const MaximizedInfo = styled(InfoRow)`
  flex-grow: 1;
`;

const MinimizedCaption = styled(Typography)<WithTheme>`
  margin-right: ${props => props.theme.spacing(1 / 2)}px;
  color: ${props => props.theme.palette.text.secondary};
`;

interface InformationRowProps {
  customer: Customer.CustomerType;
  expandedCallback: () => void;
  openMoreCallback: (boolean) => void;
  expanded: boolean;
}

const InformationRow = ({
  customer,
  expandedCallback,
  expanded,
  openMoreCallback,
}: InformationRowProps) => {
  const { name, manager, closingPeriod, vatPeriod } = customer;

  const minimized = useSelector(
    state => state.customersOverview.cardsMinimized
  );
  const intl = useIntl();

  const closingPeriodTrans = intl.formatMessage({
    id: `closingperiod.${closingPeriod}`,
  });
  const closingPeriodLabel = intl.formatMessage({
    id: 'closingperiod',
  });
  const vatPeriodTrans = intl.formatMessage({
    id: `vatperiod.${vatPeriod}`,
  });
  const responsibleLabel = intl.formatMessage({
    id: 'clientmanager',
  });

  const expandClick = useCallback(
    event => {
      event.stopPropagation();
      expandedCallback();
    },
    [expandedCallback]
  );

  const moreClick = useCallback(
    event => {
      event.stopPropagation();
      openMoreCallback(true);
    },
    [openMoreCallback]
  );

  return (
    <InfoRow>
      {minimized ? (
        <MinimizedInfo>
          <Typography variant="h6">{name}</Typography>

          <InfoRow>
            <MinimizedCaption>{responsibleLabel}</MinimizedCaption>
            <Typography variant="subtitle2">{manager}</Typography>
          </InfoRow>

          <InfoRow>
            <MinimizedCaption>{closingPeriodLabel}</MinimizedCaption>
            <Typography variant="subtitle2">{closingPeriodTrans}</Typography>
          </InfoRow>

          <InfoRow>
            <Typography variant="subtitle2">{vatPeriodTrans}</Typography>
          </InfoRow>
        </MinimizedInfo>
      ) : (
        <MaximizedInfo>
          <InfoRow>
            <Logo src={customer.logo} />
            <InfoRowTitle>
              <Typography variant="h6">{name}</Typography>
              <Typography variant="subtitle2">{manager}</Typography>
            </InfoRowTitle>
          </InfoRow>
          <InfoRow>
            <Grid>
              <Typography color="textSecondary" variant="subtitle2">
                {closingPeriodLabel}
              </Typography>
              <Typography variant="subtitle1">{closingPeriodTrans}</Typography>
              <Typography variant="subtitle1">{vatPeriodTrans}</Typography>
            </Grid>
          </InfoRow>
        </MaximizedInfo>
      )}
      <ButtonWrapper>
        <IconButton onClick={expandClick} color="primary">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </ButtonWrapper>
      <ButtonWrapper>
        <IconButton
          data-cy="open-customer-information-btn"
          onClick={moreClick}
          color="primary"
        >
          <MoreHorizIcon />
        </IconButton>
      </ButtonWrapper>
    </InfoRow>
  );
};

export default InformationRow;
