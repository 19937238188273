import React, { useState } from 'react';
import ResetIcon from '@material-ui/icons/Restore';
import { useDispatch } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import ResetContentDialog from './ResetContentDialog';
import { useIntl } from 'react-intl';
import { resetAnnualReportContent } from 'redux/actions/AnnualReportView/actions';

interface ResetContentButtonProps {
  className?: string;
  id: string;
  what: string;
}

const ResetContentButton = ({
  className,
  id,
  what,
}: ResetContentButtonProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const onConfirm = () => {
    dispatch(resetAnnualReportContent(id));
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={formatMessage({ id: 'annualReport.reset.title' })}>
        <IconButton className={className} onClick={() => setOpen(true)}>
          <ResetIcon />
        </IconButton>
      </Tooltip>
      <ResetContentDialog
        what={what}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default ResetContentButton;
