import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Dialog,
} from '@material-ui/core';

export const FallbackComponent = () => (
  <Dialog open>
    <DialogTitle>Ett fel har uppstått</DialogTitle>
    <DialogContent>
      Vi har loggat felet och kommer att titta på det så snart som möjligt.
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          window.location.reload();
        }}
      >
        Ladda om sidan
      </Button>
    </DialogActions>
  </Dialog>
);
