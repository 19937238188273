import moment from 'moment';

const parseFinancialYear = (financialYear: string) => {
  const [start, end] = financialYear
    .split('-')
    .map(date => moment(date, 'YYYYMMDD'));
  return { start, end };
};

export default parseFinancialYear;
