import styled from '@emotion/styled';
import { Box, TextField, Typography, WithTheme } from '@material-ui/core';
import DeleteButton from 'components/UI/DeleteButton';
import EditField from 'components/UI/Inputs/EditField';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteAnnualReportRow,
  updateAnnualReportCellValue,
} from 'redux/actions/AnnualReportView/actions';
import EditingIconButton from '../../UI/EditingIconButton';
import Heading from '../../UI/Heading';

interface CustomParagraphProps {
  id: string;
  no: number;
  heading: string;
  text: string;
  print: boolean;
}

const StyledDeleteButton = styled(DeleteButton)<WithTheme>`
  margin-right: ${props => props.theme.spacing(2)}px;
`;

const NoteNameField = styled(({ disableUnderline, ...rest }) => (
  <TextField size="small" variant="outlined" {...rest} />
))`
  .MuiInputBase-root {
    font-size: inherit;
    width: 100%;
  }
`;

const CustomParagraph = ({
  id,
  no,
  heading,
  text,
  print,
}: CustomParagraphProps) => {
  const dispatch = useDispatch();
  const handleHeadingChange = text => {
    dispatch(updateAnnualReportCellValue(`${id}.heading`, text));
  };
  const handleTextChange = text => {
    dispatch(updateAnnualReportCellValue(`${id}.text`, text));
  };
  const handleDelete = () => {
    dispatch(deleteAnnualReportRow(id));
  };
  const [editing, setEditing] = useState(false);
  const title = `§${no} ${heading}`;

  return (
    <>
      <Heading
        title={
          print || !editing ? (
            title
          ) : (
            <Box display="flex" width="75%">
              <Typography variant="h2" display="inline" color="textSecondary">
                {`§${no}`}
              </Typography>
              {/* This box adjusts the input field to match h2 styles above */}
              <Box ml=".5rem" mt="1.75rem" fontSize="1.75rem">
                <EditField
                  value={heading}
                  onChange={handleHeadingChange}
                  component={NoteNameField}
                />
              </Box>
            </Box>
          )
        }
        controls={
          print ? (
            undefined
          ) : (
            <>
              {editing && <StyledDeleteButton onClick={handleDelete} />}
              <EditingIconButton
                value={editing}
                onClick={() => setEditing(value => !value)}
              />
            </>
          )
        }
      />
      {print || !editing ? (
        <Typography component="div">{text}</Typography>
      ) : (
        <EditField
          value={text}
          onChange={handleTextChange}
          component={({ disableUnderline, ...props }) => (
            <TextField
              fullWidth
              multiline
              variant="outlined"
              {...props}
              rows={8}
            />
          )}
        />
      )}
    </>
  );
};

export default CustomParagraph;
