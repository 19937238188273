import React, { useEffect, memo, useState, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';

import { useSelector } from 'redux/reducers';
import {
  addCustomer,
  setSieData,
  setAdjustmentData,
  setCurrentYear,
  setUserInputData,
  resetCustomer,
  setCurrentCustomer,
  setRoutinesData,
  setTaxViewConfig,
  addGlobalErrorMessage,
} from 'redux/actions';
import {
  GET_SIE_DATA,
  GET_SIE_DATA_FULL,
  GET_CUSTOMER,
  GET_USER_INPUT,
  GET_ROUTINES,
} from 'Graphql/Queries';
import { LoadingPlaceholder } from 'components/UI';
import { FallbackComponent } from 'components/UI/ErrorBoundary';
import { useGetSieFile } from 'components/Api/Client/sie';
import { arrayBufferToBuffer } from 'utils';
import { readBuffer } from 'utils/sie-reader/sie-reader';
import mapSieInformationToTableData from 'utils/SieParser';
import { getTaxConfig } from 'utils/TaxCalculation/config';
import { parseCustomer } from 'utils/GraphqlParser';
import { mapUserInputData, mapRoutinesData } from './utils';
import LoadingLogo from 'components/UI/LoadingLogo';
import styled from '@emotion/styled';

const CustomerDetails = React.lazy(() => import('./CustomerDetails'));
const AnnualReport = React.lazy(() => import('./AnnualReport'));
const Taxes = React.lazy(() => import('./Taxes'));
const TaxDeclaration = React.lazy(() => import('./TaxDeclaration'));

const LoadingFallback = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export interface CustomerRouteParams {
  customerId: string;
}

/**
 * - Known issues in this component
 *
 * In this component we are struggeling with these 3 bugs in the Apollo client:
 * https://github.com/apollographql/react-apollo/issues/3943
 * https://github.com/apollographql/react-apollo/issues/2177
 * https://github.com/apollographql/react-apollo/issues/3505
 *
 * That is why we for example can not use "onCompleted" in the "useLazyQuery",
 * instead we need to use useEffects and listen of their data response.
 * Basically, all stupid code in this file is due to those bugs...
 */
const Customer = () => {
  const dispatch = useDispatch();
  const {
    path,
    params: { customerId },
  } = useRouteMatch<CustomerRouteParams>();
  const sieData = useSelector(state => state.accountingView.sieData);
  const customer = useSelector(state => state.customers[customerId!]);

  const currentYear = useSelector(state => state.customerView.currentYear);

  const [fetchingSieFile, setFetchingSieFile] = useState(false);

  useEffect(() => {
    // Clear the accounting view state before loading a new one
    dispatch(resetCustomer());
  }, [dispatch]);

  useEffect(() => {
    // When the user enters this view, set the current customer in the state
    dispatch(setCurrentCustomer(customerId));
  }, [customerId, dispatch]);

  const { loading: fetchingCustomer } = useQuery(GET_CUSTOMER, {
    variables: { customerId },
    skip: customer && customer.id !== undefined,
    onCompleted: async data => {
      // IF needed because of Apollo bug (https://github.com/apollographql/react-apollo/issues/3943)
      if (data) {
        const parsedCustomer = await parseCustomer(data.getCustomer);
        if (parsedCustomer !== undefined) {
          dispatch(addCustomer(parsedCustomer));
        }
      }
    },
  });

  const [
    getSieData,
    { data: fetchedSieData, loading: fetchingSie },
  ] = useLazyQuery(GET_SIE_DATA, {
    variables: { customerId, period: currentYear },
  });
  const [
    getSieDataFull,
    { data: fetchedSieDataFull, loading: fetchingSieFull },
  ] = useLazyQuery(GET_SIE_DATA_FULL, {
    variables: { customerId, period: currentYear },
  });

  const getSieFile = useGetSieFile();

  useEffect(() => {
    if (customerId === undefined) return;

    let getSie = fetchedSieData?.getSie || fetchedSieDataFull?.getSie;
    if (currentYear && fetchedSieData?.getSie?.period === currentYear) {
      getSie = fetchedSieData.getSie;
    }
    if (currentYear && fetchedSieDataFull?.getSie?.period === currentYear) {
      getSie = fetchedSieDataFull.getSie;
    }
    if (getSie) {
      if (getSie.periods) {
        dispatch(
          setSieData({
            periods: getSie.periods,
            period: getSie.period,
            sieBlob: JSON.parse(getSie.sieBlob),
            sieUpdated: getSie.sieUpdated,
            customerId: customerId!,
            transactions: [],
          })
        );
        getTaxConfig(customerId, getSie.period, getSie.periods)
          .then(config => dispatch(setTaxViewConfig(config)))
          .catch(() => {
            dispatch(addGlobalErrorMessage('error'));
          });
      }
      if (getSie.adjustmentData) {
        dispatch(setAdjustmentData(getSie.adjustmentData));
      }
    }
  }, [currentYear, customerId, dispatch, fetchedSieData, fetchedSieDataFull]);

  const [
    getUserInput,
    { data: fetchedUserInput, loading: fetchingUserInput },
  ] = useLazyQuery(GET_USER_INPUT, {
    variables: { customerId, accountingYear: currentYear },
  });

  useEffect(() => {
    if (fetchedUserInput === undefined) return;
    let getUserInputData = fetchedUserInput.getUserInputData;
    const parsed = JSON.parse(getUserInputData.inputData);
    const mapped = mapUserInputData(parsed);
    dispatch(setUserInputData(mapped));
  }, [dispatch, fetchedUserInput]);

  const [
    getRoutines,
    { data: fetchedRoutines, loading: fetchingRoutines },
  ] = useLazyQuery(GET_ROUTINES, {
    variables: { customerId },
  });

  useEffect(() => {
    if (fetchedRoutines === undefined) return;
    let getRoutinesData = fetchedRoutines.getRoutinesData;
    const parsed = JSON.parse(getRoutinesData.routines);
    const mapped = mapRoutinesData(parsed);
    dispatch(setRoutinesData(mapped));
  }, [dispatch, fetchedRoutines]);

  useEffect(() => {
    if (currentYear !== undefined && customerId !== undefined) {
      // Siedata and user input data can only be fetched after the current year
      // has been set, or we don't know for which year to fetch for
      if (sieData?.period !== currentYear) {
        setFetchingSieFile(true);
        getSieFile(customerId, currentYear)
          .then(({ data, lastModified }) => {
            if (data) {
              const buffer = arrayBufferToBuffer(data);
              const sieFile = readBuffer(buffer);
              const {
                periods,
                aggregated,
                transactions,
              } = mapSieInformationToTableData(sieFile);

              const trimmedPeriods = periods.periods.map(val =>
                val.substring(0, 6)
              );
              const year = periods.periods[0].substring(0, 4);

              dispatch(
                setSieData({
                  customerId,
                  period: year,
                  periods: trimmedPeriods,
                  sieBlob: aggregated,
                  sieUpdated: lastModified || undefined,
                  transactions,
                })
              );
              getTaxConfig(customerId, year, trimmedPeriods)
                .then(config => dispatch(setTaxViewConfig(config)))
                .catch(() => {
                  dispatch(addGlobalErrorMessage('error'));
                });
              getSieData();
            } else {
              getSieDataFull();
            }
            setFetchingSieFile(false);
          })
          .catch(err => {
            setFetchingSieFile(false);
          });
      }
      getUserInput();

      /* Routines could be fetched separately since it doesn't have a dependency 
        to accounting year but since it's shown in context of user data we do it here
      */
      getRoutines();
    }
  }, [
    currentYear,
    getSieData,
    getSieDataFull,
    getUserInput,
    getRoutines,
    sieData,
    customerId,
    getSieFile,
    dispatch,
  ]);

  const financialYears = customer?.financialYears;
  /**
   * When talking about "which year you are at" its always the start year
   * of a financial period. Example: if we have "20160901-20181231" the "year"
   * should be 2016.
   */
  useEffect(() => {
    // When the customer is loaded, set the current financial year as the latest one
    if (financialYears) {
      const latestYear = financialYears[financialYears.length - 1];
      dispatch(setCurrentYear(latestYear));
    }
  }, [financialYears, dispatch]);

  return (
    <>
      {fetchingUserInput ||
      fetchingRoutines ||
      fetchingSieFile ||
      fetchingSie ||
      fetchingSieFull ||
      fetchingCustomer ? (
        <LoadingPlaceholder />
      ) : (
        <Sentry.ErrorBoundary fallback={FallbackComponent}>
          <Suspense
            fallback={
              <LoadingFallback>
                <LoadingLogo size="medium" />
              </LoadingFallback>
            }
          >
            <Switch>
              <Route path={`${path}/details`} component={CustomerDetails} />
              <Route path={`${path}/year`} component={AnnualReport} />
              <Route path={`${path}/tax`} component={Taxes} />
              <Route
                path={`${path}/taxdeclaration`}
                component={TaxDeclaration}
              />
            </Switch>
          </Suspense>
        </Sentry.ErrorBoundary>
      )}
    </>
  );
};

export default memo(Customer);

Customer.whyDidYouRender = false;
