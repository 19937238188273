export const sanitizeOrgNumber = (orgNumber: string): string => {
  return orgNumber.trim();
};

/**
 * Check if a given number is valid according to Luhn algorithm
 * @param {string} orgNumber can contain `-` chars
 */
export const isValidOrgNumber = (orgNumber: string): boolean => {
  const orgNumberWithoutSlashes = orgNumber.split('-').join('');

  if (orgNumberWithoutSlashes.length !== 10) return false;

  let sum = 0;
  let doubleUpDigit = false;

  /* from the right to left, double every other digit starting with the second to last digit.*/
  for (let i = orgNumberWithoutSlashes.length - 1; i >= 0; i--) {
    const currentDigit = parseInt(orgNumberWithoutSlashes.charAt(i));

    /* double every other digit starting with the second to last digit */
    if (doubleUpDigit) {
      /* doubled number is greater than 9 than subtracted 9 */
      if (currentDigit * 2 > 9) {
        sum += currentDigit * 2 - 9;
      } else {
        sum += currentDigit * 2;
      }
    } else {
      sum += currentDigit;
    }
    doubleUpDigit = !doubleUpDigit;
  }

  /* sum and divide it by 10. If the remainder equals zero, the number is valid.  */
  return sum % 10 === 0 ? true : false;
};

const stripOrgNumber = (orgNumber: string): string =>
  orgNumber.replace(' ', '').replace('-', '');

/**
 * Matches if two organisation numbers are the same
 * @param firstOrgNr
 * @param secondOrgNr
 * @returns boolean
 */
export const isMatchingOrgNumbers = (
  firstOrgNr: string,
  secondOrgNr: string
): boolean => {
  return stripOrgNumber(firstOrgNr) === stripOrgNumber(secondOrgNr);
};
