import React from 'react';
import { SecondaryButton, CTAButton } from 'components/UI';

import styled from '@emotion/styled';
import { WithTheme, Box } from '@material-ui/core';

const SaveContainer = styled.div<WithTheme>`
  padding: ${props => props.theme.spacing(4)}px 0;
`;
const ButtonWrapper = styled.div<WithTheme>`
  display: flex;
`;
const RowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface SaveChangesProps {
  saveChanges: (e: any) => void;
  saveLabel: string;
  savingChanges?: boolean;
  discardLabel: string;
  discardChanges: (e: any) => void;
}

const SaveChanges = ({
  saveChanges,
  saveLabel,
  savingChanges = false,
  discardLabel,
  discardChanges,
}: SaveChangesProps) => (
  <SaveContainer>
    <RowContainer>
      <ButtonWrapper>
        <Box mr={2}>
          <SecondaryButton onClick={discardChanges} color="primary">
            {discardLabel}
          </SecondaryButton>
        </Box>
        <CTAButton
          data-cy="save-btn"
          loading={savingChanges}
          onClick={saveChanges}
          id="onboarding-create-customer"
        >
          {saveLabel}
        </CTAButton>
      </ButtonWrapper>
    </RowContainer>
  </SaveContainer>
);

export default SaveChanges;
