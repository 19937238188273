import React, { useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@material-ui/core';

import { useSelector } from 'redux/reducers';
import FileUpload from 'components/UI/FileUpload';
import useLoadSieFile from 'utils/useLoadSieFile';

const UploadSIEButton = () => {
  const intl = useIntl();

  const currentCustomer = useSelector(
    state => state.customerView.currentCustomer
  );
  const customer = useSelector(state =>
    currentCustomer ? state.customers[currentCustomer] : undefined
  );
  const sieData = useSelector(state => state.accountingView.sieData);

  const [loadSieFile, { loading, error }] = useLoadSieFile(customer);

  const [open, setOpen] = useState(false);

  const handleFileUpload = useCallback(
    (selectorFiles: FileList | null) => {
      const reader = new FileReader();
      try {
        reader.addEventListener('load', () => loadSieFile(reader));
        selectorFiles && reader.readAsArrayBuffer(selectorFiles[0]);
      } catch (error) {
        window.alert(intl.formatMessage({ id: 'sie.error' }));
      }
    },
    [loadSieFile, intl]
  );

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (error) setOpen(true);
  }, [error]);

  const errorBaseId =
    error?.name === 'WrongOrgNr' ? 'sie.orgNr' : 'sie.unknown';

  return (
    <>
      <FileUpload
        showToolTip={sieData === undefined}
        handleChange={handleFileUpload}
        sieUpdated={sieData?.sieUpdated}
        disabled={loading}
      />
      <Divider orientation="vertical" flexItem />
      {open && error && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {intl.formatMessage({ id: `${errorBaseId}.error.title` })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {intl.formatMessage({ id: `${errorBaseId}.error.body` })}
              {error.name === 'WrongOrgNr' && (
                <>
                  <br />
                  <strong>
                    {intl.formatMessage({ id: `${errorBaseId}.error.strong` })}
                  </strong>
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              {intl.formatMessage({ id: 'forgot.back' })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UploadSIEButton;
