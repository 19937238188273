import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, Typography, WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';
import { toggleAnnualReportFieldActive } from 'redux/actions/AnnualReportView/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';

const Section = styled.div<{ print: boolean } & WithTheme>`
  display: flex;
  justify-content: space-between;
  margin: ${props => (props.print ? '0.25rem' : '1rem')} 0;
`;

const ActiveToggle = styled.div`
  width: 160px;
  display: flex;
  align-items: center;
`;

type ToggleSectionProps = {
  print?: boolean;
  id: string;
  section: string; // note1 ex
  children: React.ReactNode;
};

const ToggleSection = ({
  print = false,
  id,
  section,
  children,
}: ToggleSectionProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const active = useSelector(
    state => state.annualReport.report?.notes[section][id].active
  );

  const toggleFieldActive = useCallback(
    id => {
      dispatch(toggleAnnualReportFieldActive('notes', section, id));
    },
    [dispatch, section]
  );

  const toggleCheckboxLabelId = active
    ? 'annualReport.notes.includedSection'
    : 'annualReport.notes.excludedSection';

  if (print && !active) {
    return null;
  }

  return (
    <>
      <Section print={print}>
        <Typography variant="h3">
          {formatMessage({
            id: `annualReport.notes.${section}.${id}`,
          })}
        </Typography>
        {!print && (
          <ActiveToggle>
            <Checkbox checked={active} onChange={() => toggleFieldActive(id)} />
            <Typography>
              {formatMessage({
                id: toggleCheckboxLabelId,
              })}
            </Typography>
          </ActiveToggle>
        )}
      </Section>
      {active && children}
    </>
  );
};

export default ToggleSection;
