import styled from '@emotion/styled';
import { TableRow, WithTheme } from '@material-ui/core';

const AddTableRow = styled(TableRow)<WithTheme & { level: number }>`
  .MuiTableCell-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .MuiTableCell-body:first-of-type {
    padding-left: ${props => props.theme.spacing(2 * props.level)}px;
  }
  .add {
    content: ' ';
    border: 1px dashed transparent;
    height: 36px;
    width: 100%;
    border-radius: ${props => props.theme.shape.borderRadius}px;
  }
  &:hover .add {
    border: 1px dashed ${props => props.theme.palette.primary.main};
  }
`;

export default AddTableRow;
