import { combineReducers } from 'redux';
import accountListDrawer from './AccountListDrawer';
import accountingView from './AccountingView';
import customers from './Customers';
import organisation from './Organisation';
import taxView from './TaxView';
import user from './User';
import customersOverview from './CustomersOverview';
import customerView from './CustomerView';
import messages from './Messages';
import annualReport from './AnnualReportView';
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';

const reducer = combineReducers({
  accountListDrawer,
  user,
  customers,
  organisation,
  accountingView,
  taxView,
  customersOverview,
  customerView,
  messages,
  annualReport,
});

const rootReducer = (state, action) => {
  return reducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default rootReducer;
