import gql from 'graphql-tag';

export const GET_ROUTINES = gql`
  query getRoutinesData($customerId: String!) {
    getRoutinesData(customerId: $customerId) {
      routines
    }
  }
`;

export const GET_USER_INPUT = gql`
  query getUserInputData($customerId: String!, $accountingYear: String!) {
    getUserInputData(customerId: $customerId, accountingYear: $accountingYear) {
      inputData
    }
  }
`;

export const LIST_PERIOD_DOCUMENTS = gql`
  query listPeriodDocuments(
    $clientId: String!
    $account: String!
    $year: String!
    $month: String!
    $accountingYear: String!
  ) {
    listPeriodDocuments(
      clientId: $clientId
      account: $account
      year: $year
      month: $month
      accountingYear: $accountingYear
    ) {
      name
      url
      lastModified
    }
  }
`;
