import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Typography,
  WithTheme,
  Paper,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Description, GetApp } from '@material-ui/icons';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { useSelector } from 'redux/reducers';
import { DeleteButton } from 'components/UI';
import Snackbar from 'components/UI/Snackbar';
import { useDeleteSubscription } from 'components/Api/Organisation/subscription';
import CardTabPanel from '../UI/CardTabPanel';
import { CardTabs } from '../UI/CardTabs';
import { date, formatStripefee } from 'utils/Formatting';
import { setSubscriptionStatus } from 'redux/actions/Organisation';

// max-height used to align long lists with payment section on the right
// content will overflow and scroll shown
const PaidInvoicesList = styled(List)`
  overflow: auto;
  max-height: 183px;
`;

const StripeUpcomingAmount = styled(Typography)`
  font-size: 2.4rem;
  font-weight: 500;
`;

const Text = styled(Typography)<WithTheme>`
  margin-bottom: ${props => props.theme.spacing(3)}px;

  color: ${props => props.theme.palette.text.primary};
  font-weight: 400;
`;

export interface Invoice {
  amount_due: number;
  currency: string;
  invoice_pdf: string;
  next_payment_attempt: number;
  number: string;
  period_start: number;
  period_end: number;
  paid: boolean;
  status_transitions: {
    paid_at: number;
  };
}

/**
 * Calculate the latest day of authorised access to Agoy, for a given organisation.
 * @return {number} date in EPOCH format
 */
export const calculateLatestAccessDate = (
  latestPaidInvoice: Invoice | null,
  upcomingInvoice: Invoice | null
): number => {
  let latestAccessDate = new Date();

  if (latestPaidInvoice) {
    latestAccessDate = new Date(latestPaidInvoice.period_end * 1000);
    latestAccessDate.setMonth(latestAccessDate.getMonth() + 1);
  } else if (upcomingInvoice) {
    latestAccessDate = new Date(upcomingInvoice.period_end * 1000);
  }

  return Math.floor(latestAccessDate.getTime() / 1000);
};

const Invoicing = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);
  const [toast, setToast] = useState({ isOpen: false, message: '' });
  const subscriptionStatus: Organisation.subscriptionStatus = useSelector(
    state => state.organisation.subscriptionStatus
  );
  const nextPayment = useSelector(state => state.organisation.nextPayment);
  const upcomingInvoice = useSelector(
    state => state.organisation.invoices?.upcoming
  );
  const paidInvoices: Invoice[] | null = useSelector(state => {
    const invoices = state.organisation.invoices?.invoices;
    // most recently paid invoice shown first in list
    return invoices?.sort(
      (a, b) => b.status_transitions.paid_at - a.status_transitions.paid_at
    );
  });

  const latestAccessDate = calculateLatestAccessDate(
    paidInvoices ? paidInvoices[0] : null,
    upcomingInvoice
  );

  const [
    deleteSubscription,
    { loading: deleteSubscriptionLoading },
  ] = useDeleteSubscription();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleCloseToast = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast({ ...toast, isOpen: false });
  };

  const cancelSubscription = async () => {
    try {
      await deleteSubscription();
      // If the upcoming invoice was due now or due time has passed, set the status to canceled
      // otherwise cancel_at_period_end
      const status =
        nextPayment! <= Math.floor(Date.now() / 1000)
          ? 'canceled'
          : 'cancel_at_period_end';
      dispatch(setSubscriptionStatus(status));
    } catch (e) {
      setToast({
        isOpen: true,
        message: intl.formatMessage({
          id: 'dashboard.invoicing.subscription.error',
        }),
      });
    }
  };

  return (
    <>
      <Snackbar open={toast.isOpen} onClose={handleCloseToast}>
        <Alert severity="error">{toast.message}</Alert>
      </Snackbar>
      <Paper elevation={4}>
        <CardTabs
          value={value}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab
            label={intl.formatMessage({ id: 'dashboard.invoicing.upcoming' })}
          />
          <Tab label={intl.formatMessage({ id: 'dashboard.invoicing.paid' })} />
          <Tab
            label={intl.formatMessage({
              id: 'dashboard.invoicing.subscription',
            })}
          />
        </CardTabs>
        <CardTabPanel name="upcoming" value={value} index={0}>
          {subscriptionStatus === 'trialing' && (
            <Alert severity="info">
              {intl.formatMessage({ id: 'dashboard.invoicing.trialPeriod' })}
              {date(latestAccessDate)}.
            </Alert>
          )}
          {upcomingInvoice ? (
            <>
              <Typography variant="h2">
                <StripeUpcomingAmount>
                  {formatStripefee(upcomingInvoice?.amount_due)}{' '}
                  {upcomingInvoice?.currency}
                </StripeUpcomingAmount>
              </Typography>

              <Typography color="textSecondary" gutterBottom>
                {intl.formatMessage({
                  id: 'dashboard.invoicing.willBeWithdrawn',
                })}
              </Typography>
              <Typography variant="body2" component="p">
                {date(upcomingInvoice?.next_payment_attempt)}
              </Typography>
            </>
          ) : (
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'dashboard.invoicing.noUpcomingInvoices',
              })}
            </Typography>
          )}
        </CardTabPanel>
        <CardTabPanel name="paid" value={value} index={1}>
          <PaidInvoicesList>
            {paidInvoices?.map((invoice, index) => (
              <React.Fragment key={invoice.number}>
                {index !== 0 && <Divider />}
                <ListItem>
                  <ListItemIcon>
                    <Description />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${date(invoice.period_start)} - ${date(
                      invoice.period_end
                    )}`}
                    secondary={
                      invoice.paid
                        ? `${intl.formatMessage({
                            id: 'dashboard.invoicing.withdrawn',
                          })} ${date(invoice.status_transitions.paid_at)}`
                        : `${intl.formatMessage({
                            id: 'dashboard.invoicing.unpaid',
                          })}`
                    }
                  />
                  <IconButton
                    color="primary"
                    aria-label="download invoice"
                    component="span"
                    onClick={() => {
                      window.open(invoice.invoice_pdf);
                    }}
                  >
                    <GetApp />
                  </IconButton>
                </ListItem>
              </React.Fragment>
            ))}
          </PaidInvoicesList>
        </CardTabPanel>
        <CardTabPanel name="subscription" value={value} index={2}>
          {subscriptionStatus === 'cancel_at_period_end' && (
            <Text>
              {intl.formatMessage({
                id: 'dashboard.invoicing.subscription.toBeCabcelled',
              })}
              {date(latestAccessDate)}
            </Text>
          )}

          {subscriptionStatus === 'canceled' && (
            <Text>
              {intl.formatMessage({
                id: 'dashboard.invoicing.subscription.canceled',
              })}
            </Text>
          )}

          {(subscriptionStatus === 'past_due' ||
            (subscriptionStatus !== 'canceled' &&
              subscriptionStatus !== 'cancel_at_period_end')) && (
            <>
              {subscriptionStatus === 'past_due' && (
                <Text>
                  {intl.formatMessage({
                    id: 'dashboard.invoicing.subscription.past_due',
                  })}
                </Text>
              )}
              {subscriptionStatus !== 'past_due' && (
                <Text>
                  {intl.formatMessage({
                    id: 'dashboard.invoicing.subscription.message',
                  })}
                  {date(latestAccessDate)}
                </Text>
              )}

              <DeleteButton
                variant="contained"
                onClick={cancelSubscription}
                confirmMessage={intl.formatMessage({
                  id: 'dashboard.invoicing.subscription.cancel.confirm',
                })}
                disabled={deleteSubscriptionLoading}
                disableElevation
              >
                {intl.formatMessage({
                  id: 'dashboard.invoicing.subscription.cancel',
                })}
              </DeleteButton>
            </>
          )}
        </CardTabPanel>
      </Paper>
    </>
  );
};

export default Invoicing;
