import { Cell } from 'types/AnnualReport/cell';

export const stringValue = (cell: Cell | undefined) =>
  cell?.type === 'string' ? cell.value : undefined;

export const labelValue = (cell: Cell | undefined) =>
  cell?.type === 'label' ? cell.value : undefined;

export const numberValue = (cell: Cell | undefined) =>
  cell?.type === 'number' ? cell.value : undefined;

export const referenceValue = (cell: Cell | undefined) =>
  cell?.type === 'ref' ? cell.value : undefined;
