import React from 'react';
import styled from '@emotion/styled';
import { WithTheme } from '@material-ui/core';

const Page = styled(({ children, ...props }) =>
  React.Children.count(children) > 0 ? <div {...props}>{children}</div> : null
)<WithTheme>`
  page-break-before: always;
  width: 800px;

  @media screen {
    border-bottom: 1px dashed ${props => props.theme.palette.grey['500']};
    padding: 32px 32px;
    width: 864px;
  }
`;

export default Page;
