import React, { useState, useCallback, useEffect } from 'react';
import { ccyFormat, ccyParse } from 'utils';
import { TextField } from '@material-ui/core';

export interface CurrencyFieldProps<T = any> {
  className?: string;
  displayDecimals?: number;
  editingDecimals?: number;
  onValueChange?: (value: number | undefined) => void;
  value: number | undefined;
  onlyPositive?: boolean;
  onlyNegative?: boolean;
  parser?: (value: string) => number | undefined;
  formatter?: (value: number | undefined, decimals: number) => string;
  InputProps?: T;
  Input?: React.ComponentType<T>;
}

const CurrencyField = React.forwardRef(
  (
    {
      className,
      displayDecimals = 0,
      editingDecimals = 2,
      value,
      onValueChange,
      onlyPositive = false,
      onlyNegative = false,
      Input = TextField,
      InputProps = {},
      formatter = ccyFormat,
      parser = ccyParse,
    }: CurrencyFieldProps,
    ref
  ) => {
    const [text, setText] = useState<string>('');
    const [decimals, setDecimals] = useState<number>(displayDecimals);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
      setText(formatter(value, decimals) || '');
    }, [value, formatter, decimals]);

    useEffect(() => {
      const value = parser(text);
      // Validate
      if (value !== undefined && onlyPositive && value < 0) {
        setHasError(true);
      } else if (value !== undefined && onlyNegative && value > 0) {
        setHasError(true);
      } else {
        setHasError(false);
      }
    }, [text, parser, onlyNegative, onlyPositive]);

    const handleChange = useCallback(
      event => {
        setText(event.target.value);
      },
      [setText]
    );

    const commitValue = useCallback(
      (newValue: string) => {
        if (onValueChange) {
          const parsedText = parser(newValue);

          if (value !== parsedText) {
            const nextValue =
              parsedText === undefined || Number.isNaN(parsedText)
                ? undefined
                : parsedText;
            onValueChange(nextValue);
          }
        }
      },
      [value, parser, onValueChange]
    );

    const handleBlur = useCallback(
      event => {
        if (!hasError) {
          commitValue(event.target.value);
          setDecimals(displayDecimals);
        }
      },
      [setDecimals, displayDecimals, commitValue, hasError]
    );

    const handleFocus = useCallback(
      event => {
        if (!hasError) {
          setDecimals(editingDecimals);
        }
      },
      [setDecimals, editingDecimals, hasError]
    );

    const handleEnter = useCallback(
      event => {
        if (event.key === 'Enter') {
          if (!hasError) {
            commitValue(event.target.value);
          }
        }
      },
      [commitValue, hasError]
    );

    return (
      <Input
        {...InputProps}
        className={className}
        ref={ref}
        onChange={handleChange}
        value={text}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyPress={handleEnter}
        error={hasError}
      />
    );
  }
);

export default CurrencyField;
