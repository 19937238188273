import {
  TaxCalculationRow,
  TaxCalculationContext,
  AccrualFunds,
} from './types';
import { calculateRow, calculateTaxTable } from './calculateTaxTable';
import { shallowCompare } from 'utils/shallowCompare';
import moment from 'moment';
import { updateContext } from './context';

export const createYears = (periods: string[]): TaxCalculationRow[] => {
  const result: TaxCalculationRow[] = [];

  let endDate = moment(periods[0], 'YYYYMM').subtract(1, 'day');
  for (let i = 1; i <= 6; i++) {
    const label = `${endDate
      .clone()
      .subtract(11, 'month')
      .startOf('month')
      .format('YYYY-MM-DD')} – ${endDate.format('YYYY-MM-DD')}`;
    const id = `accrualFund-${i}`;
    const reference = 'or(account(0))';

    result.push({ id, label, reference });
    endDate = endDate.subtract(1, 'year');
  }
  return result.reverse();
};

const calculateRows = (
  context: TaxCalculationContext,
  previousAccrualFunds: AccrualFunds | null
): TaxCalculationRow[] => {
  const moreRows = (
    previousAccrualFunds?.moreRows || context.config.accrualFundsRows
  ).map(row => calculateRow(row, context));

  if (!previousAccrualFunds?.moreRows) {
    return moreRows;
  }

  return shallowCompare(previousAccrualFunds.moreRows, moreRows)
    ? previousAccrualFunds.moreRows
    : moreRows;
};

export const parseYearFromAccrualFundLabel = (label: string) => {
  return label.substring(13, 17);
};

const calculateIncreaseOfCompanyTax = (
  context: TaxCalculationContext,
  accrualFunds: TaxCalculationRow[],
  row: TaxCalculationRow
): TaxCalculationRow => {
  const sumFundsBefore2019 =
    -1 *
    accrualFunds
      .filter(fund => fund.value)
      .filter(
        fund => fund.label && parseYearFromAccrualFundLabel(fund.label) < '2019'
      )
      .map(fund => fund.value || 0)
      .reduce((a, b) => a + b, 0);

  const chosenReversalOfAccrualFund = context.resolveById(
    'chosenReversalOfAccrualFund',
    context
  );

  if (typeof chosenReversalOfAccrualFund !== 'number') {
    return row;
  }

  const increaseOfCompanyTax =
    Math.min(sumFundsBefore2019, chosenReversalOfAccrualFund) * 0.03;

  if (row.value === increaseOfCompanyTax) {
    return row;
  }

  return updateContext(context, { ...row, value: increaseOfCompanyTax });
};

export const calculateAccrualFunds = (
  context: TaxCalculationContext,
  previousAccrualFunds: AccrualFunds | null
): AccrualFunds => {
  const { rows, sum } = calculateTaxTable(
    previousAccrualFunds || context.config.accrualFunds,
    context
  );
  const newMoreRows = calculateRows(context, previousAccrualFunds).map(row =>
    row.id === 'increaseOfCompanyTax'
      ? calculateIncreaseOfCompanyTax(context, rows, row)
      : row
  );
  const moreRows =
    previousAccrualFunds &&
    shallowCompare(newMoreRows, previousAccrualFunds.moreRows)
      ? previousAccrualFunds.moreRows
      : newMoreRows;

  const accrualFunds: AccrualFunds = {
    rows,
    sum,
    moreRows,
  };

  if (!previousAccrualFunds) {
    return accrualFunds;
  }

  return shallowCompare(previousAccrualFunds, accrualFunds)
    ? previousAccrualFunds
    : accrualFunds;
};
