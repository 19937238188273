import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@material-ui/core';

export interface ConfirmationDialogProps {
  text: string;
  onAccept: Function;
  onClose: Function;
}

const ConfirmationDialog = ({
  text,
  onAccept,
  onClose,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={true} onClose={() => onClose()}>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onClose();
              onAccept();
            }}
          >
            OK
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            Avbryt
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
