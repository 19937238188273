import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Select, FormControl, MenuItem } from '@material-ui/core';
import { matchPath } from 'react-router';
import get from 'lodash-es/get';
import config from 'config';

export const ModalHeader = ({ history }) => {
  const intl = useIntl();
  const match = matchPath(history.location.pathname, {
    path: '/customer/:id/:tab',
    strict: false,
  });
  const { id, tab } = get(match, 'params', {});

  // AGOY-995 enable tax and year
  let modals;
  if (
    config.runningEnvironment === 'production' ||
    config.runningEnvironment === 'staging'
  ) {
    modals = ['details', 'tax', 'year'];
  } else {
    modals = ['details', 'tax', 'year', 'taxdeclaration'];
  }

  const [currentTab, setCurrentTab] = useState(tab);

  const tabChanged = event => {
    setCurrentTab(event.target.value);
    history.push(`/customer/${id}/${event.target.value}`);
  };

  return (
    <FormControl>
      <Select disableUnderline value={currentTab} onChange={tabChanged}>
        {modals.map(val => (
          <MenuItem key={val} value={val}>
            {intl.formatMessage({ id: val })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default withRouter(ModalHeader);
