import gql from 'graphql-tag';
export const GET_YEAR_REPORT = gql`
  query getYearReportData($customerId: String!, $year: Int!) {
    getYearReportData(customerId: $customerId, year: $year) {
      settingsAndBaseInformation
      managementReport
      incomeStatement
      balanceSheet
      flowAnalysis
      notes
      ending
    }
  }
`;
