import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import cloneDeep from 'lodash-es/cloneDeep';
import {
  Grid,
  WithTheme,
  Button,
  Typography,
  TextField,
  InputAdornment,
  FormControlLabel,
  Switch,
  FormGroup,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Search } from '@material-ui/icons';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { updateCustomer as updateCustomerAction } from 'redux/actions';
import { UPDATE_CUSTOMER } from 'Graphql/Mutations';
import { GenericModal } from 'components/Modals/GenericModal';
import ActivateFortnoxIntegration from 'components/Modals/ActivateFortnoxIntegration';
import Snackbar from 'components/UI/Snackbar';
import Logo from 'components/UI/Logo';
import { useClientMembers } from 'components/Api/Client/members';
import {
  useAddMemberRole,
  useDeleteMemberRole,
} from 'components/Api/Admin/MemberRoles';
import { DefaultContainer } from '../Parts/Container';
import { UserAccessListItem } from '../UI/UserAccessListItem';
import { AgoyAppQuery } from 'utils/AgoyAppClient/useAgoyAppQuery';

const Container = styled(DefaultContainer)<WithTheme>`
  height: 100%;
  min-width: 80vw;
`;
const GridWrapper = styled(Grid)<WithTheme>`
  padding: ${props => props.theme.spacing(2)}px;
  height: 100%;
`;

const ClientLogo = styled(Logo)<WithTheme>`
  width: 150px;
  height: 150px;
  display: block;
`;

const ClientInfo = styled(Grid)<WithTheme>`
  padding: ${props => props.theme.spacing(2)}px;
`;

const SearchAndFilterSection = styled(Grid)<WithTheme>`
  margin: ${props => props.theme.spacing(2)}px 0;
`;

const MembersSection = styled(Grid)<WithTheme>`
  padding: 0 ${props => props.theme.spacing(1)}px;
`;

// Member helpers
interface Members {
  [memberEmail: string]: Member.MemberType & MemberSecurityAttributes;
}

interface MemberSecurityAttributes {
  isAuthorised?: boolean;
  isAuthorisedEditedValue?: boolean;
  isVisible?: boolean;
}

/**
 * Construct the member list to be used in the popup state.
 * The authorisation of the members over a client is also computed.
 * @param organisationMembers all the members in the organisation
 * @param clientAuthorisedMembers  authorised members for the selected client
 */
export const calculateAuthorisedMembers = (
  orgMembers: Member.MemberType[] | null = [],
  clientAuthorisedMembers: Member.MemberType[] = []
): Members => {
  let authorisedMembers = {};

  if (
    orgMembers === null ||
    !orgMembers.length ||
    !clientAuthorisedMembers.length
  )
    return authorisedMembers;

  const clientAuthorisedMemberEmails = clientAuthorisedMembers.map(
    c => c.email
  );

  orgMembers.forEach(member => {
    const isClientAuthorisedForMember = clientAuthorisedMemberEmails.includes(
      member.email
    );

    authorisedMembers[member.email] = {
      ...member,
      isAuthorised: isClientAuthorisedForMember,
      isAuthorisedEditedValue: isClientAuthorisedForMember,
      isVisible: true,
    };
  });

  return authorisedMembers;
};

export const resetMembers = (members: Members): Members => {
  let resetedMembers = cloneDeep(members);

  Object.keys(resetedMembers).forEach(memberEmail => {
    resetedMembers[memberEmail].isAuthorisedEditedValue =
      resetedMembers[memberEmail].isAuthorised;
    resetedMembers[memberEmail].isVisible = true;
  });

  return resetedMembers;
};

// Filter helpers

interface Filters {
  memberEmail: string;
  authorisedOnly: boolean;
}

const DEFAULT_FILTERS: Filters = { memberEmail: '', authorisedOnly: false };

export const filterMembers = ({
  members,
  filters,
}: {
  members: Members;
  filters: Filters;
}) => {
  const filteredMembers = cloneDeep(members);

  Object.keys(members).forEach(memberEmail => {
    let isVisible = true;

    if (
      filters.memberEmail.length > 0 &&
      !filteredMembers[memberEmail].email
        .toLowerCase()
        .includes(filters.memberEmail.toLowerCase())
    ) {
      isVisible = false;
    }

    if (
      isVisible &&
      filters.authorisedOnly &&
      !filteredMembers[memberEmail].isAuthorisedEditedValue
    ) {
      isVisible = false;
    }

    filteredMembers[memberEmail].isVisible = isVisible;
  });

  return filteredMembers;
};

// Client status helper
const getStatus = currentStatus => {
  if (currentStatus === 'active') return 'inactive';

  // this returns default active status if currentStatus isn't set
  return 'active';
};

interface EditClientProps {
  customerId: string;
  handleClose: () => void;
  orgMembersQuery: AgoyAppQuery<Member.MemberType[], any>;
}

const EditClient = ({
  customerId: clientId,
  handleClose,
  orgMembersQuery,
}: EditClientProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // STATE
  const [filters, setFilters] = useState<Filters>(DEFAULT_FILTERS);
  const [members, setMembers] = useState<Members>({});
  const [toast, setToast] = useState({ isOpen: false, message: '' });

  const admins = useSelector(state => state.organisation.admins);
  const userEmail = useSelector(state => state.user.email);
  const isUserAdmin = userEmail && admins ? admins.includes(userEmail) : false;

  const fullCustomer = useSelector(state => {
    return state.customers?.[`${clientId}`] || {};
  });

  const {
    name: clientName = '',
    status: clientStatus,
    logo: clientLogo = '',
  } = fullCustomer;

  const [openFortnoxIntegration, setOpenFortnoxIntegration] = useState(false);

  // API
  const [addMemberRole, { loading: addMemberRoleLoading }] = useAddMemberRole();
  const [
    deleteMemberRole,
    { loading: removeUserAccessLoading },
  ] = useDeleteMemberRole();
  const [clientMembers] = useClientMembers(clientId);
  const [orgMembers] = orgMembersQuery;

  useEffect(() => {
    const sanitizedClientMembers = clientMembers == null ? [] : clientMembers;
    setMembers(calculateAuthorisedMembers(orgMembers, sanitizedClientMembers));
  }, [orgMembers, clientMembers]);

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: data => {
      dispatch(
        updateCustomerAction({
          ...fullCustomer,
          status: data.updateCustomer.status,
        })
      );
    },
  });

  const onClose = () => {
    handleClose();
    setFilters(DEFAULT_FILTERS);
  };

  const onFilterChange = (filters: Filters) => {
    setFilters(filters);
    setMembers(filterMembers({ members, filters }));
  };

  const onToggleMemberAuthorisation = async (memberEmail: string) => {
    if (!isUserAdmin) {
      setToast({ isOpen: true, message: 'Only admins can edit permissions' });
      return;
    }

    if (admins.includes(members[memberEmail].email)) {
      setToast({
        isOpen: true,
        message: intl.formatMessage({
          id: 'dashboard.members.edit.cannotRemoveAdmin',
        }),
      });
      return;
    }

    let operation: 'addAuthorisation' | 'deleteAuthorisation' =
      'addAuthorisation';

    try {
      if (members[memberEmail].isAuthorisedEditedValue) {
        operation = 'deleteAuthorisation';
        await deleteMemberRole(memberEmail, clientId);
      } else {
        operation = 'addAuthorisation';
        await addMemberRole(memberEmail, clientId);
      }

      setMembers({
        ...members,
        [memberEmail]: {
          ...members[memberEmail],
          isAuthorisedEditedValue: !members[memberEmail]
            .isAuthorisedEditedValue,
        },
      });
    } catch (e) {
      const message = intl.formatMessage({
        id:
          operation === 'addAuthorisation'
            ? 'dashboard.customers.edit.removeAccessFailed'
            : 'dashboard.customers.edit.grantAccessFailed',
      });
      setToast({ isOpen: true, message });
    }
  };

  const handleCloseToast = () => {
    setToast({ ...toast, isOpen: false });
  };

  const showFortnoxIntegration = () => {
    setOpenFortnoxIntegration(true);
  };

  const closeFortnoxIntegration = () => {
    setOpenFortnoxIntegration(false);
  };

  return (
    <GenericModal open={true} handleClose={onClose}>
      <div>
        <Snackbar open={toast.isOpen} onClose={handleCloseToast}>
          <Alert severity="error">{toast.message}</Alert>
        </Snackbar>
      </div>
      <Container>
        <GridWrapper container direction="column" justify="space-between">
          <Grid container item spacing={2}>
            <Grid container>
              <Grid container item xs={true}>
                <Grid item>
                  <ClientLogo src={clientLogo} />
                </Grid>
                <ClientInfo item>
                  <Typography variant="h2">{clientName}</Typography>
                </ClientInfo>
              </Grid>
              <Grid container item xs={true} justify="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={showFortnoxIntegration}
                  >
                    {intl.formatMessage({
                      id: 'dashboard.customers.edit.fortnoxButton',
                    })}
                  </Button>
                  {openFortnoxIntegration && (
                    <ActivateFortnoxIntegration
                      customerId={clientId}
                      close={closeFortnoxIntegration}
                    />
                  )}
                </Grid>
                {isUserAdmin && clientStatus && (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => {
                          const newStatus = getStatus(clientStatus);
                          updateCustomer({
                            variables: {
                              ...fullCustomer,
                              customerId: fullCustomer.sortKey,
                              status: newStatus,
                            },
                          });
                        }}
                        disableElevation
                        color="primary"
                      >
                        {intl.formatMessage({
                          id: `dashboard.customers.edit.clientStatus.${clientStatus}`,
                        })}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container item>
              <SearchAndFilterSection
                container
                item
                spacing={2}
                justify="space-between"
              >
                <Grid item xs={6}>
                  <TextField
                    id="search-auth-clients"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={e =>
                      onFilterChange({
                        ...filters,
                        memberEmail: e.target.value,
                      })
                    }
                    placeholder={intl.formatMessage({
                      id: 'dashboard.members.searchPlaceholder',
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid container justify="flex-end" item xs={6}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={filters.authorisedOnly}
                          onChange={() =>
                            onFilterChange({
                              ...filters,
                              authorisedOnly: !filters.authorisedOnly,
                            })
                          }
                          name="viewAuthorizedMembersToggle"
                        />
                      }
                      label={intl.formatMessage({
                        id: 'dashboard.customers.edit.viewAuthorizedToggle',
                      })}
                    />
                  </FormGroup>
                </Grid>
              </SearchAndFilterSection>
              <MembersSection container item spacing={2}>
                {Object.keys(members)
                  .filter(memberEmail => members[memberEmail].isVisible)
                  .map(memberEmail => (
                    <Grid key={memberEmail} zeroMinWidth item>
                      <UserAccessListItem
                        name={
                          members[memberEmail].given_name &&
                          members[memberEmail].family_name
                            ? `${members[memberEmail].given_name} ${members[memberEmail].family_name}`
                            : undefined
                        }
                        email={memberEmail}
                        authorized={
                          members[memberEmail].isAuthorisedEditedValue
                        }
                        updatingAccess={
                          addMemberRoleLoading || removeUserAccessLoading
                        }
                        accessChangeHandler={() =>
                          onToggleMemberAuthorisation(memberEmail)
                        }
                      />
                    </Grid>
                  ))}
              </MembersSection>
            </Grid>
          </Grid>
        </GridWrapper>
      </Container>
    </GenericModal>
  );
};

export default EditClient;
