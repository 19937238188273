import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import {
  FormControl,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Avatar,
  Button,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { insertUserToOrg, removeInvite } from 'redux/actions';
import {
  INVITE_TO_ORGANISATION,
  DELETE_INVITE,
} from 'Graphql/Mutations/OrganisationMutations';

const SearchField = styled(FormControl)`
  width: 100%;
`;

const InviteMemberButton = styled(Button)`
  min-width: 130px;
`;

const getErrorMessageId = error => {
  const errorType = error.graphQLErrors
    ?.filter(err => err.errorType?.startsWith('Agoy'))
    .map(err => err.errorType)[0];

  switch (errorType) {
    case 'Agoy:UserExists':
      return 'dashboard.members.invite.error.user.exists';
    case 'Agoy:InviteExists':
      return 'dashboard.members.invite.error.invite.exists';
  }
  return 'dashboard.members.invite.error.text';
};

const InviteMember = () => {
  const invited = useSelector(state => state.organisation.invited);

  const dispatch = useDispatch();
  const intl = useIntl();

  const [email, setEmail] = useState<string>('');

  const [
    addUser,
    { loading: addInviteLoading, error: addInviteError },
  ] = useMutation(INVITE_TO_ORGANISATION, {
    onCompleted: () => {
      dispatch(insertUserToOrg(email.toLowerCase()));
      setEmail('');
    },
  });

  const [deleteInvite, { loading: deleteInviteLoading }] = useMutation(
    DELETE_INVITE
  );

  const clickAddUser = () => {
    if (!email) return;
    addUser({
      variables: {
        email: email.toLowerCase(),
      },
    });
  };

  const clickDeleteInvite = async (email: string) => {
    const { errors } = await deleteInvite({
      variables: { email },
    });
    if (!errors) {
      dispatch(removeInvite(email));
    }
  };

  return (
    <>
      <SearchField>
        <TextField
          id="add-members"
          variant="outlined"
          size="small"
          placeholder={intl.formatMessage({ id: 'email.placeholder' })}
          fullWidth
          value={email}
          onChange={e => setEmail(e.target.value)}
          InputProps={{
            endAdornment: (
              <InviteMemberButton
                color="primary"
                size="small"
                onClick={clickAddUser}
              >
                {intl.formatMessage({ id: 'dashboard.members.invite' })}
              </InviteMemberButton>
            ),
          }}
        />
      </SearchField>
      <List>
        {(addInviteLoading || addInviteError) && (
          <>
            {addInviteLoading && (
              <ListItem>
                <Skeleton variant="text" animation="wave" width={'100%'} />
              </ListItem>
            )}
            {addInviteError && (
              <Alert severity="error">
                <AlertTitle>
                  {intl.formatMessage({
                    id: 'dashboard.members.invite.error.title',
                  })}
                </AlertTitle>
                {intl.formatMessage({
                  id: getErrorMessageId(addInviteError),
                })}
              </Alert>
            )}
          </>
        )}

        {invited &&
          invited.map(member => (
            <React.Fragment key={member}>
              <ListItem>
                <ListItemIcon>
                  <Avatar />
                </ListItemIcon>
                <ListItemText>{member}</ListItemText>
                <IconButton
                  onClick={() => clickDeleteInvite(member)}
                  disabled={deleteInviteLoading}
                >
                  <Delete />
                </IconButton>
              </ListItem>
            </React.Fragment>
          ))}
      </List>
    </>
  );
};

export default InviteMember;
