import { CustomNotesSection, Notes } from 'types/AnnualReport/parts/notes';
import { sectionOrdering } from 'types/AnnualReport/types';
import { NOTES_CONTROLLED_BY_ACTIVE_FLAG } from 'utils/AnnualReport/constants';
import { shallowCompare } from 'utils/shallowCompare';
import AnnualReportState from '../types';

const updateNotesNumbering = (state: AnnualReportState) => {
  if (!state.report) {
    return state;
  }

  const { referencedNotes } = state;

  let n = 1;
  const notes = sectionOrdering.notes.reduce((notes, section): Notes => {
    if ('sections' in notes[section]) {
      const sections = notes[section].sections.map(
        (customSection: CustomNotesSection | null, index) => {
          if (customSection === null) {
            return null;
          }

          const active = referencedNotes.includes(`notes.${section}-${index}`);

          const value = active ? n++ : undefined;
          return customSection.number.type !== 'number' ||
            customSection.number.value === value
            ? customSection
            : {
                ...customSection,
                number: { ...customSection.number, value },
                active,
              };
        }
      );
      if (!shallowCompare(sections, notes[section].sections)) {
        return {
          ...notes,
          [section]: {
            ...notes[section],
            sections,
          },
        };
      }
      return notes;
    } else {
      const active = NOTES_CONTROLLED_BY_ACTIVE_FLAG.includes(section)
        ? referencedNotes.includes(`notes.${section}`) || notes[section].active
        : referencedNotes.includes(`notes.${section}`);

      const value = active ? n++ : undefined;

      if (
        notes[section].number.value !== value ||
        notes[section].active !== active
      ) {
        return {
          ...notes,
          [section]: {
            ...notes[section],
            active,
            number: {
              ...notes[section].number,
              value,
            },
          },
        };
      }
      return notes;
    }
  }, state.report.notes);

  if (notes === state.report.notes) {
    return state;
  }

  return {
    ...state,
    report: {
      ...state.report,
      notes,
    },
  };
};

export default updateNotesNumbering;
