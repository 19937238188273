import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  AppBar,
  Toolbar,
  Divider,
  Button,
  Switch,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { useSelector } from 'redux/reducers';
import { toggleLampsExpanded, toggleMinimizedCards } from 'redux/actions';
import { CreateCustomer } from 'components/Modals/Customer';
import Legend from './Legend';

const classes = {
  bottomAppBar: theme => ({
    background: theme.palette.grey[200],
    color: 'inherit',
  }),
  bottomToolbar: theme => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&& > *': {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  }),
  divider: theme => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  }),
};

const CustomersOverviewSubMenu = () => {
  const dispatch = useDispatch();
  const orgName = useSelector(state => state.organisation.name);
  const [customerOpen, setCustomerOpen] = useState(false);
  const intl = useIntl();

  const lampsExpanded = useSelector(
    state => state.customersOverview.lampsExpanded
  );
  const cardsMinimized = useSelector(
    state => state.customersOverview.cardsMinimized
  );
  const customers = useSelector(state => state.customers);
  const [welcome, setWelcome] = useState(true);

  useEffect(() => {
    if (Object.keys(customers).length !== 0) {
      setWelcome(false);
    }
  }, [customers]);

  const toggleLamps = () => dispatch(toggleLampsExpanded());
  const toggleMinimized = () => dispatch(toggleMinimizedCards());
  const openCreateCustomer = () => setCustomerOpen(true);
  const closeCreateCustomer = () => setCustomerOpen(false);

  return (
    <>
      {orgName && customerOpen && (
        <CreateCustomer open={customerOpen} close={closeCreateCustomer} />
      )}
      {!welcome && (
        <AppBar position="static" css={classes.bottomAppBar}>
          <Toolbar css={classes.bottomToolbar}>
            <Button
              startIcon={<AddCircleOutlineIcon />}
              onClick={openCreateCustomer}
              data-cy={'add-client'}
              id="onboarding-first-customer"
            >
              {intl.formatMessage({ id: 'sidemenu.add_customer' })}
            </Button>
            <Divider orientation="vertical" flexItem css={classes.divider} />
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={cardsMinimized}
                  onChange={toggleMinimized}
                />
              }
              label={
                <Typography variant="button">
                  {intl.formatMessage({ id: 'customerlist.minimizedView' })}
                </Typography>
              }
            />
            <Divider orientation="vertical" flexItem css={classes.divider} />
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={lampsExpanded}
                  onChange={toggleLamps}
                  id="onboarding-legend"
                />
              }
              label={
                <Typography variant="button">
                  {intl.formatMessage({ id: 'customerlist.showAllStatuses' })}
                </Typography>
              }
            />

            {lampsExpanded && <Legend />}
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default memo(CustomersOverviewSubMenu);
