import { InputData } from 'types/AccountingView/types';
import { cleanSpecification } from 'utils';

type InputRoutineData = {
  routinesForAccount?: string;
};

export const createInputData = ({
  saldo,
  comment,
  specification,
  numImages = 0,
}: InputData) => {
  const inputData = { visited: true };
  if (saldo) inputData['saldo'] = saldo;
  if (comment) inputData['comment'] = comment;
  if (specification)
    inputData['specification'] = cleanSpecification(specification);
  if (numImages >= 0) {
    inputData['numImages'] = numImages;
  } else {
    inputData['numImages'] = 0;
  }
  return inputData;
};

export const createInputRoutineData = (accountRoutines: {}): InputRoutineData => {
  return accountRoutines;
};
