import {
  AnnualReportPartKey,
  AnnualReportTable,
  TableChange,
} from 'types/AnnualReport/types';
import AnnualReportState from '../../types';
import { replaceByIndex, splitSection } from './util';

const updateTable = (
  state: AnnualReportState,
  partKey: AnnualReportPartKey,
  sectionId: string,
  tableKey: string,
  updater: (
    table: AnnualReportTable,
    change: TableChange
  ) => [AnnualReportTable, TableChange]
): AnnualReportState => {
  if (!state.report) {
    return state;
  }
  const [sectionKey, sectionIndex] = sectionId.includes('-')
    ? splitSection(sectionId)
    : [sectionId];

  const table: AnnualReportTable | undefined =
    sectionIndex !== undefined
      ? state.report[partKey]?.[sectionKey]?.sections?.[sectionIndex]?.[
          tableKey
        ]
      : state.report[partKey]?.[sectionKey]?.[tableKey];

  if (!table) {
    return state;
  }
  const tableChange: TableChange = (sectionIndex !== undefined
    ? state.changes[partKey]?.[sectionKey]?.[sectionIndex]?.[tableKey]
    : state.changes[partKey]?.[sectionKey]?.[tableKey]) || { type: 'update' };

  const [newTable, newTableChange] = updater(table, tableChange);

  if (newTable === table) {
    return state;
  }
  return {
    ...state,
    report: {
      ...state.report,
      [partKey]: {
        ...state.report[partKey],
        [sectionKey]:
          sectionIndex !== undefined
            ? {
                ...state.report[partKey][sectionKey],
                sections: replaceByIndex(
                  state.report[partKey][sectionKey].sections,
                  sectionIndex,
                  {
                    ...state.report[partKey][sectionKey].sections[sectionIndex],
                    [tableKey]: newTable,
                  }
                ),
              }
            : {
                ...state.report[partKey][sectionKey],
                [tableKey]: newTable,
              },
      },
    },
    changes: {
      ...state.changes,
      [partKey]: {
        ...state.changes[partKey],
        [sectionKey]:
          sectionIndex !== undefined
            ? {
                ...state.changes[partKey]?.[sectionKey],
                [sectionIndex]: {
                  ...state.changes[partKey]?.[sectionKey]?.[sectionIndex],
                  [tableKey]: newTableChange,
                },
              }
            : {
                ...state.changes[partKey]?.[sectionKey],
                [tableKey]: newTableChange,
              },
      },
    },
  };
};

export default updateTable;
