import {
  TaxCalculationPart,
  TaxCalculationConfig,
} from 'utils/TaxCalculation/types';
import { agoyAppClient } from 'utils/AgoyAppClient/useAgoyAppClient';
import { getContext } from 'utils/AgoyAppClient/contextHolder';

type StoredTaxConfig = Pick<TaxCalculationConfig, TaxCalculationPart>;

export const storeTaxConfig = async (
  clientId: string,
  year: string,
  taxCalculation: StoredTaxConfig
): Promise<void> => {
  const context = getContext();
  if (!context) {
    throw new Error('context not set yet');
  }
  const client = agoyAppClient<void, StoredTaxConfig, any>(context);

  const response = await client(
    `/api/client/${encodeURIComponent(clientId)}/tax/${year}`,
    'POST',
    taxCalculation
  );

  if (response.ok) {
    return;
  } else {
    return Promise.reject(response.error);
  }
};

export const getStoredTaxConfig = async (
  clientId: string,
  year: string
): Promise<StoredTaxConfig | null> => {
  const context = getContext();
  if (!context) {
    throw new Error('context not set yet');
  }
  const client = agoyAppClient<StoredTaxConfig, void, any>(context);

  const response = await client(
    `/api/client/${encodeURIComponent(clientId)}/tax/${year}`,
    'GET'
  );

  if (response.ok) {
    return response.body as StoredTaxConfig;
  } else {
    if (response.statusCode === 404) {
      return null;
    }
    return Promise.reject(response.error);
  }
};
