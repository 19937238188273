import React, { useState, useCallback, useEffect } from 'react';

interface EditFieldProps {
  className?: string;
  value: string;
  onChange: (label: string) => void;
  component: React.ComponentType<any>;
  disabled?: boolean;
  placeholder?: string;
}

const EditField = ({
  className,
  value,
  onChange,
  disabled = false,
  component: Component,
  placeholder,
}: EditFieldProps) => {
  const [label, setLabel] = useState(value);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setLabel(event.currentTarget.value);
    },
    []
  );

  useEffect(() => {
    setLabel(value);
  }, [value, setLabel]);

  const handleBlur = useCallback(() => {
    if (label !== value) {
      onChange(label);
    }
  }, [onChange, value, label]);

  const handleEnter = useCallback(
    event => {
      if (event.key === 'Enter') {
        if (label !== value) {
          onChange(label);
        }
      }
    },
    [onChange, value, label]
  );

  useEffect(() => {
    setLabel(value);
  }, [value, setLabel]);

  return (
    <Component
      className={className}
      disabled={disabled}
      value={label}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyPress={handleEnter}
      placeholder={placeholder}
    />
  );
};

export default EditField;
