import React, { useRef } from 'react';
import styled from '@emotion/styled';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { WithTheme } from '@material-ui/core';

interface UserImageProps {
  imageUrl?: string;
  handleChange: (file: FileList | null) => void;
  editing: boolean;
  square?: boolean;
}

interface BaseProps {
  imageUrl?: string;
  editing: boolean;
  square?: boolean;
}

const Base = styled.div<WithTheme & BaseProps>`
  height: 8rem;
  width: 8rem;
  border-radius: ${props => (props.square ? 'none' : '50%')};
  margin: 2rem auto;
  background-color: ${props => `${props.theme.palette.grey[400]}`};
  position: relative;
  ${props =>
    props.editing && `border: 2px dashed ${props.theme.palette.primary.main}`};
  overflow: hidden;
  cursor: ${props => (props.editing ? 'pointer' : 'default')};

  input {
    display: none;
  }

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${props =>
    props.imageUrl &&
    `&:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url(${props.imageUrl}) ;
    background-size: cover;
    background-repeat:no-repeat; 
    background-position: center center; 
    opacity: ${props.editing ? '0.4' : '1'}
    }`};
`;

const StyledAvatar = styled(AccountCircleIcon)`
  font-size: 4rem;
`;

export const UserClientImage = ({
  editing,
  imageUrl,
  handleChange,
  square,
}: UserImageProps) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (editing) fileInput.current!.click();
  };

  return (
    <Base
      imageUrl={imageUrl}
      onClick={handleClick}
      editing={editing}
      square={square}
    >
      <input
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={e => handleChange(e.target.files)}
      />
      {editing && <AddAPhotoIcon color="primary" />}
      {!imageUrl && !editing && <StyledAvatar />}
    </Base>
  );
};

export default UserClientImage;
