import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid, Typography, WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import PreviewSection from '../../UI/PreviewSection';
import { referenceValue, stringValue } from 'utils/AnnualReport/cell';
import Paragraph from './Paragraph';
import VotingList from './VotingList';
import { ccyFormat, formatFinancialYear } from 'utils';
import GridRow from './GridRow';
import Fee from './Fee';
import BoardMembersParagraph from './BoardMembersParagraph';
import { LABELS } from '../Signatures/People/BoardMemberTypeSelection';
import AuditorsParagraph from './AuditorsParagraph';
import CustomParagraph from './CustomParagraph';

const ResultTable = styled.div<WithTheme>`
  display: grid;
  width: max-content;
  grid-template-columns: auto auto;
  gap: ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(4)}px;
`;

const AnnualGeneralMeetingPreview = () => {
  const { formatMessage } = useIntl();

  // STATE
  const annualGeneralMeeting = useSelector(
    state => state.annualReport.report?.annualGeneralMeeting
  );
  const currentFinancialYear = useSelector(
    state => state.customerView.currentFinancialYear
  );
  const customer = useSelector(
    state =>
      state.customerView.currentCustomer &&
      state.customers[state.customerView.currentCustomer]
  );
  const ceo = useSelector(state =>
    state.annualReport.report?.signatures.section.people.rows?.find(row =>
      stringValue(row.cells?.role)?.includes(LABELS.CEO)
    )
  );

  let paragraph = 1;
  const chairman = annualGeneralMeeting?.main.votingList.rows.find(
    row =>
      stringValue(row.cells?.role) === 'chairman' ||
      stringValue(row.cells?.role) === 'chairmanAndRecordKeeper'
  );
  const recordKeeper = annualGeneralMeeting?.main.votingList.rows.find(
    row =>
      stringValue(row.cells?.role) === 'recordKeeper' ||
      stringValue(row.cells?.role) === 'chairmanAndRecordKeeper'
  );
  const adjuster = annualGeneralMeeting?.main.votingList.rows.find(
    row => stringValue(row.cells?.role) === 'adjuster'
  );

  if (!annualGeneralMeeting || !customer || !currentFinancialYear) {
    return null;
  }

  const result =
    annualGeneralMeeting.main.result.type === 'ref' &&
    ccyFormat(referenceValue(annualGeneralMeeting.main.result));

  return (
    <PreviewSection id="annualGeneralMeeting">
      <Typography variant="h1">
        {formatMessage({ id: 'annualReport.menu.annualGeneralMeeting' })}
      </Typography>

      {/* Location */}
      <Box mt={1}>
        <Typography>
          {formatMessage({
            id: 'annualReport.annualGeneralMeeting.main.location',
          })}
        </Typography>
        <Typography>
          {stringValue(annualGeneralMeeting.main.location) || ''}
        </Typography>
      </Box>

      {/* Date */}
      {annualGeneralMeeting.main.date.type === 'string' && (
        <Box mt={1}>
          <Typography>
            {formatMessage({
              id: 'annualReport.annualGeneralMeeting.main.date',
            })}
          </Typography>
          <Typography>
            {stringValue(annualGeneralMeeting.main.date) || ''}
          </Typography>
        </Box>
      )}
      <Typography>
        {formatMessage({ id: 'annualReport.annualGeneralMeeting.main.header' })}
        {`${customer.name}, org.nr ${customer.orgNumber}, 
        ${stringValue(annualGeneralMeeting.main.location) || ''},
        ${stringValue(annualGeneralMeeting.main.date) || ''}.`}
      </Typography>

      {/* Voting list */}
      <Paragraph no={paragraph++} id="votingList" />
      <VotingList fullId="annualGeneralMeeting.main.votingList" print={true} />
      <Typography>
        {formatMessage({
          id: 'annualReport.annualGeneralMeeting.main.votingList.text2',
        })}
      </Typography>

      {/* Chairman */}
      <Paragraph
        no={paragraph++}
        id="2"
        name={stringValue(chairman?.cells?.name)}
      />

      {/* Record Keeper */}
      <Paragraph
        no={paragraph++}
        id="3"
        name={stringValue(recordKeeper?.cells?.name)}
        role={stringValue(recordKeeper?.cells?.role)}
      />

      {/* Adjuster */}
      {adjuster && (
        <Paragraph
          no={paragraph++}
          id="4"
          name={stringValue(adjuster?.cells?.name)}
        />
      )}

      {/* Auditor report */}
      <Paragraph no={paragraph++} id="5" />

      {/* Results and balance sheet */}
      <Paragraph
        no={paragraph++}
        id="6"
        year={formatFinancialYear(currentFinancialYear)}
      />

      {/* Result position */}
      <Paragraph no={paragraph++} id="7" />

      {/* Discharge */}
      <Paragraph no={paragraph++} id="8" result={result}>
        {editing => (
          <Box marginY={2}>
            <ResultTable>
              {annualGeneralMeeting.main.resultsDisposition.rows.map(row => (
                <GridRow
                  key={row.id}
                  baseId="annualGeneralMeeting.main.resultsDisposition"
                  row={row}
                  columns={annualGeneralMeeting.main.resultsDisposition.columns}
                  editing={editing}
                  print={true}
                />
              ))}
            </ResultTable>
          </Box>
        )}
      </Paragraph>
      <Typography>
        {formatMessage({
          id: 'annualReport.annualGeneralMeeting.main.8.text2',
        })}
      </Typography>

      {/* Current financial year */}
      <Paragraph
        no={paragraph++}
        id="9"
        ceo={ceo ? 'true' : 'false'}
        year={formatFinancialYear(currentFinancialYear)}
      />

      {/* Fee */}
      <Fee
        fee={stringValue(annualGeneralMeeting.main.fee) || ''}
        print={true}
        no={paragraph++}
      />

      {/* Board members */}
      <BoardMembersParagraph
        no={paragraph++}
        table={annualGeneralMeeting.main.boardMembers}
        print={true}
      />

      {/* Auditors */}
      <AuditorsParagraph
        no={paragraph++}
        auditors={annualGeneralMeeting.main.auditors}
        noAuditor={stringValue(annualGeneralMeeting.main.noAuditor) || ''}
        deputyAuditors={annualGeneralMeeting.main.deputyAuditors}
        print={true}
      />

      {/* Custom paragraphs */}
      {annualGeneralMeeting.main.customParagraphs.rows.map(p => (
        <CustomParagraph
          key={p.id}
          no={paragraph++}
          id={`annualGeneralMeeting.main.customParagraphs.${p.id}`}
          heading={stringValue(p.cells?.heading) || ''}
          text={stringValue(p.cells?.text) || ''}
          print={true}
        />
      ))}

      {/* Termination */}
      <Paragraph no={paragraph++} id="13" />
      <Box marginTop={16}>
        <Grid container>
          <Grid item xs={4}>
            <Typography>{stringValue(chairman?.cells?.name)}</Typography>
            <Typography variant="caption">
              {formatMessage({
                id: 'annualReport.annualGeneralMeeting.role.chairman',
              })}
            </Typography>
          </Grid>
          {chairman !== recordKeeper && (
            <Grid item xs={4}>
              <Typography>{stringValue(recordKeeper?.cells?.name)}</Typography>
              <Typography variant="caption">
                {formatMessage({
                  id: 'annualReport.annualGeneralMeeting.role.recordKeeper',
                })}
              </Typography>
            </Grid>
          )}
          {adjuster && (
            <Grid item xs={4}>
              <Typography>{stringValue(adjuster.cells?.name)}</Typography>
              <Typography variant="caption">
                {formatMessage({
                  id: 'annualReport.annualGeneralMeeting.role.adjuster',
                })}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </PreviewSection>
  );
};

export default AnnualGeneralMeetingPreview;
