import React, { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { WithTheme, Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import lottie from 'lottie-web';

import { ReactComponent as BetaLogo } from 'assets/AgoyLogoBETA.svg';
import splashAnimation from 'assets/SplashScreen2.json';
import facebookIcon from 'assets/facebook.svg';
import instagramIcon from 'assets/instagram.svg';
import linkedinIcon from 'assets/linkedin.svg';

const Wrapper = styled.div`
  height: 100vh;

  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AgoyLogo = styled(BetaLogo)<WithTheme>`
  width: 200px;
  height: 45px;
  margin-top: 40px;
  margin-bottom: ${props => props.theme.spacing(7)}px;
`;

const SplashWrapper = styled.div`
  overflow: hidden;

  svg {
    max-width: 100%;
    height: 400px;
  }
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  align-self: flex-end;
`;

const Image = styled.img`
  height: 40px;
  width: 40px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-top: 60px;
`;

const Text = styled(Typography)<WithTheme>`
  margin: ${props => props.theme.spacing(5)}px;
  text-align: left;
`;

const MobileSplash = () => {
  const { formatMessage } = useIntl();
  const refToDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: refToDiv.current!,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: splashAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMin slice',
      },
    });
    return () => {
      animation.destroy();
    };
  }, []);

  return (
    <Wrapper>
      <AgoyLogo />
      <SplashWrapper ref={refToDiv} />
      <Text variant="h6">
        {formatMessage({ id: 'app.tsx.welcome.mobile.user' })}
      </Text>
      <SocialMediaWrapper>
        <a href="https://www.facebook.com/agoyit/">
          <Image src={facebookIcon} alt="agoy-facebook" />
        </a>
        <a href="https://www.instagram.com/agoy_ab/?igshid=1npotnej5vy48">
          <Image src={instagramIcon} alt="agoy-instagram" />
        </a>
        <a href="https://www.linkedin.com/company/agoyit/">
          <Image src={linkedinIcon} alt="agoy-linkedin" />
        </a>
      </SocialMediaWrapper>
    </Wrapper>
  );
};

export default MobileSplash;
