import React from 'react';
import { WithTheme, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { useSelector } from 'redux/reducers';
import parseFinancialYear from 'utils/parseFinancialYear';

const GridTable = styled.div<WithTheme>`
  display: grid;
  grid-template-columns: auto 130px 130px;
  grid-gap: ${props => props.theme.spacing(1) / 2}px;
`;

const GridTableHead = styled.div`
  display: contents;
`;

const GridTableHeadCell = styled.div<WithTheme>`
  align-self: end;
  justify-self: end;
  color: ${props => props.theme.palette.grey[700]};
`;

const FinancialYearsHeader = () => {
  const { formatMessage } = useIntl();
  const currentFinancialYear = useSelector(
    state => state.customerView.currentFinancialYear
  );

  if (!currentFinancialYear) {
    return null;
  }

  const { start } = parseFinancialYear(currentFinancialYear);
  const currentPeriod = start.format('YYYY');
  const previousPeriod = start.subtract(1, 'month').format('YYYY');

  return (
    <GridTable>
      <GridTableHead>
        <GridTableHeadCell></GridTableHeadCell>
        <GridTableHeadCell>
          <Typography align="right">
            {formatMessage({ id: 'annualReport.notes.tables.year' })}
          </Typography>
          {currentPeriod && (
            <Typography align="right" color="primary">
              {currentPeriod}
            </Typography>
          )}
        </GridTableHeadCell>

        <GridTableHeadCell>
          <Typography align="right">
            {formatMessage({
              id: 'annualReport.notes.tables.previousYear',
            })}
          </Typography>
          {previousPeriod && (
            <Typography align="right" color="primary">
              {previousPeriod}
            </Typography>
          )}
        </GridTableHeadCell>
      </GridTableHead>
    </GridTable>
  );
};

export default FinancialYearsHeader;
