import { useAgoyAppMutate } from 'utils/AgoyAppClient/useAgoyAppMutate';

const createAddMemberRoleRequest = (email: string, file: File): any => {
  // check that the mimetype is an image
  if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
    throw new Error('Incorrect mimetype');
  }

  return {
    uri: encodeURI(`/api/member/${email}/picture/`),
    rawContent: file,
    contentType: file.type,
  };
};

export const useUploadProfilePicture = () => {
  return useAgoyAppMutate(createAddMemberRoleRequest, 'POST');
};
