import { Middleware } from 'redux';
import { RootState } from 'redux/reducers';
import {
  ADD_ANNUAL_REPORT_COLUMN,
  UPDATE_ANNUAL_REPORT_CELL_VALUE,
  UPDATE_ANNUAL_REPORT_FIELD,
  TOGGLE_ANNUAL_REPORT_SECTION_ACTIVE,
  ADD_ANNUAL_REPORT_ROW,
  DELETE_ANNUAL_REPORT_ROW,
  DELETE_ANNUAL_REPORT_COLUMN,
  DELETE_ANNUAL_REPORT_SECTION,
  SET_ANNUAL_REPORT_TYPE,
  ADD_ANNUAL_REPORT_SECTION,
  UPDATE_ANNUAL_REPORT_CELL_REFERENCES,
  TOGGLE_ANNUAL_REPORT_FIELD_ACTIVE,
  TOGGLE_ANNUAL_REPORT_TABLE_ACTIVE,
  TOGGLE_TABLE_ROW_ACTIVE,
  UPDATE_ANNUAL_REPORT_CHANGES,
  UPDATE_ANNUAL_REPORT_COLUMN_LABEL,
} from 'redux/actionsTypes';
import debounceByKey from 'utils/debounceByKey';
import {
  savingUserDataFailed,
  savingUserDataSuccess,
  startedSavingUserData,
} from 'redux/actions';
import { storeAnnualReport } from 'components/Api/Client/annualReport';
import {
  AnnualReportChanges,
  AnnualReportType,
} from 'types/AnnualReport/types';

const triggers = [
  ADD_ANNUAL_REPORT_COLUMN,
  UPDATE_ANNUAL_REPORT_CELL_VALUE,
  UPDATE_ANNUAL_REPORT_CELL_REFERENCES,
  UPDATE_ANNUAL_REPORT_FIELD,
  TOGGLE_ANNUAL_REPORT_SECTION_ACTIVE,
  ADD_ANNUAL_REPORT_ROW,
  DELETE_ANNUAL_REPORT_ROW,
  DELETE_ANNUAL_REPORT_COLUMN,
  SET_ANNUAL_REPORT_TYPE,
  ADD_ANNUAL_REPORT_SECTION,
  DELETE_ANNUAL_REPORT_SECTION,
  TOGGLE_ANNUAL_REPORT_FIELD_ACTIVE,
  TOGGLE_ANNUAL_REPORT_TABLE_ACTIVE,
  TOGGLE_TABLE_ROW_ACTIVE,
  UPDATE_ANNUAL_REPORT_CHANGES,
  UPDATE_ANNUAL_REPORT_COLUMN_LABEL,
];

const saveAnnualReport = async (
  dispatch,
  customerId: string,
  year: string,
  changes: AnnualReportChanges,
  type: AnnualReportType
) => {
  dispatch(startedSavingUserData());
  try {
    await storeAnnualReport(customerId, year, changes, type);
    dispatch(savingUserDataSuccess());
  } catch (e) {
    dispatch(savingUserDataFailed());
  }
};

const key = (
  dispatch,
  customerId: string,
  year: string,
  changes: AnnualReportChanges,
  type: AnnualReportType
): string => customerId;

const save = debounceByKey(key, saveAnnualReport, 2000);

const saveAnnualReportMiddleware: Middleware<
  {},
  RootState
> = store => next => action => {
  const { annualReport: prevState } = store.getState();
  const result = next(action);
  const { annualReport } = store.getState();
  if (
    (prevState.changes !== annualReport.changes ||
      prevState.type !== annualReport.type) &&
    triggers.includes(action.type)
  ) {
    const { currentCustomer, currentYear } = store.getState().customerView;
    if (currentCustomer && currentYear) {
      save(
        store.dispatch,
        currentCustomer,
        currentYear,
        annualReport.changes,
        annualReport.type
      );
    }
  }

  return result;
};

export default saveAnnualReportMiddleware;
