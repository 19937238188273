import AWSAppSyncClient, { AUTH_TYPE, createAppSyncLink } from 'aws-appsync';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import config from '../../config';
import { AuthOptions } from 'aws-appsync-auth-link';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { Credentials } from 'aws-sdk/lib/credentials';

const AWS = require('aws-sdk');

export const createPoolData = () => ({
  UserPoolId: config.userPoolId,
  ClientId: config.clientId,
});

let userPool: AmazonCognitoIdentity.CognitoUserPool;

export const createUserPool = (): AmazonCognitoIdentity.CognitoUserPool => {
  if (!userPool) {
    userPool = new AmazonCognitoIdentity.CognitoUserPool(createPoolData());
  }
  return userPool;
};

export const updateLogins = session => {
  AWS.config.credentials.params.Logins[
    config.userPoolEndpoint
  ] = session.getIdToken().getJwtToken();
};

export const updateCredentials = result => {
  const credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.identityPoolId,
    Logins: {
      [config.userPoolEndpoint]: result.getIdToken().getJwtToken(),
    },
  });
  credentials.clearCachedId();
  AWS.config.update({
    region: config.region,
    credentials,
  });
};

export const getcognitoUser = () => createUserPool().getCurrentUser();

export const isAuthenticated = () => (getcognitoUser() == null ? false : true);

export const getUserName = () => {
  const cognitoUser = getcognitoUser();
  return cognitoUser && cognitoUser['username'];
};

export const refreshAwsCredentials = () => {
  return new Promise((resolve, reject) => {
    AWS.config.credentials &&
      AWS.config.credentials.refresh(error => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      });
  });
};

export const tokenNeedsRefresh = () => {
  return AWS.config.credentials.needsRefresh();
};

export const createIdentityPoolClient = () => {
  const authOptions: AuthOptions = {
    type: AUTH_TYPE.AWS_IAM,
    credentials: async () => {
      return new Promise<Credentials | null>((resolve, reject) => {
        const cognitoUser = getcognitoUser();

        if (cognitoUser != null) {
          cognitoUser.getSession((err, session) => {
            const refresh_token = session.getRefreshToken();

            if (tokenNeedsRefresh()) {
              cognitoUser.refreshSession(refresh_token, (err, session) => {
                if (err) {
                  reject(err);
                } else {
                  AWS.config.credentials.params.Logins[
                    config.userPoolEndpoint
                  ] = session.getIdToken().getJwtToken();
                  AWS.config.credentials.refresh(err => {
                    if (err) {
                      reject(err);
                    } else {
                      resolve(AWS.config.credentials);
                    }
                  });
                }
              });
            } else {
              resolve(AWS.config.credentials);
            }
          });
        }
      }).catch(error => {
        alert('Session could not be renewed. Try reloading the web page.');
        return null;
      });
    },
  };

  const appsyncConfig = {
    disableOffline: true,
    complexObjectsCredentials: () => null,
    url: config.graphqlEndpoint,
    region: config.region,
    auth: authOptions,
  };

  return new AWSAppSyncClient(appsyncConfig, {
    link: createAppSyncLink({
      ...appsyncConfig,
      resultsFetcherLink: ApolloLink.from([
        setContext((request, previousContext) => {
          return new Promise((resolve, reject) => {
            const cognitoUser = getcognitoUser();

            if (cognitoUser != null) {
              cognitoUser.getSession((err, session) => {
                resolve({
                  headers: {
                    ...previousContext.headers,
                    token: session.getIdToken().getJwtToken(),
                  },
                });
              });
            }
          });
        }),
        createHttpLink({
          uri: appsyncConfig.url,
        }),
      ]),
    }),
  });
};

export const logOut = () => {
  var cognitoUser = getcognitoUser();
  if (cognitoUser != null) {
    cognitoUser.signOut();
    return true;
  }
  return false;
};
