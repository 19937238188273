import React from 'react';
import { useSelector } from 'redux/reducers';
import styled from '@emotion/styled';
import { Typography, WithTheme } from '@material-ui/core';
import BalanceSheetTable from '../BalanceSheet/BalanceSheetTable';
import { useIntl } from 'react-intl';

const Content = styled.div<WithTheme>`
  min-width: 800px;
  width: min-content;
`;

const IncomeStatementPreview = () => {
  const { formatMessage } = useIntl();
  const incomeStatement = useSelector(
    state => state.annualReport.report?.incomeStatement
  );

  if (!incomeStatement) {
    return null;
  }

  const title = formatMessage({ id: 'annualReport.menu.incomeStatement' });

  return (
    <Content>
      <Typography variant="h1">{title}</Typography>
      <BalanceSheetTable
        table={incomeStatement.section.table}
        tableId="incomeStatement.section.table"
        showAccounts={false}
        editing={false}
        print={true}
      />
    </Content>
  );
};

export default IncomeStatementPreview;
