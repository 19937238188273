import React from 'react';
import { WithTheme } from '@material-ui/core';
import { AnnualReportTable } from 'types/AnnualReport/types';
import styled from '@emotion/styled';
import Cell from '../../../UI/Cell';
import { flattenRows, includeRowInPrint } from '../utils';

interface SixColumnTableProps {
  table: AnnualReportTable;
  tableId: string;
  editing?: boolean;
  print?: boolean;
}

const GridTable = styled.div<WithTheme>`
  margin: 1rem 0;
  display: grid;
  grid-template-columns: auto 126px 108px 108px 130px 130px;
  grid-gap: ${props => props.theme.spacing(1) / 2}px;
`;

const GridTableHead = styled.div`
  display: contents;
  > *:nth-last-of-type(1),
  > *:nth-last-of-type(2) {
    justify-self: end;
  }
`;

const GridTableHeadCell = styled.div<WithTheme>`
  align-self: center;
  justify-self: start;
  color: ${props => props.theme.palette.grey[700]};
`;

const GridTableRow = styled.div<WithTheme>`
  display: contents;
  > * {
    padding: ${props => props.theme.spacing(1) / 2}px;
  }

  &:nth-of-type(even) {
    > * {
      background-color: ${props => props.theme.palette.grey[100]};
    }
  }

  /* Last two column justify content right */
  > *:nth-last-of-type(1),
  > *:nth-last-of-type(2) {
    justify-content: flex-end;
  }
`;

const RowCell = styled.div<{ print: boolean } & WithTheme>`
  ${props => (props.print ? `padding: ${props.theme.spacing(0, 1)};` : '')}
  display: flex;
  min-height: 45px;
  align-items: center;
  font-size: inherit;
  line-height: 1.5rem;
`;

const SixColumnTable = ({
  table,
  tableId,
  editing = false,
  print = false,
}: SixColumnTableProps) => {
  const renderRows = flattenRows(table.rows).filter(
    row => !print || includeRowInPrint(row)
  );

  return (
    <>
      <GridTable>
        <GridTableHead>
          {table.columns.map(col => (
            <GridTableHeadCell key={col.id}>{col.label}</GridTableHeadCell>
          ))}
        </GridTableHead>

        {renderRows.map(row => (
          <GridTableRow key={row.id}>
            {row.cells && (
              <React.Fragment>
                {table.columns.map(col => {
                  const cell = row.cells?.[col.id];
                  const key = `${row.id}.${col.id}`;
                  if (!cell) {
                    return <div key={key} />;
                  }
                  return (
                    <RowCell key={key} print={print}>
                      <Cell
                        cell={cell}
                        id={`${tableId}.${key}`}
                        editing={editing}
                        print={print}
                      />
                    </RowCell>
                  );
                })}
              </React.Fragment>
            )}
          </GridTableRow>
        ))}
      </GridTable>
    </>
  );
};

export default SixColumnTable;
