import React, { useState } from 'react';
import { LoginBox, ActionsWrapper, TextField } from './SharedComponents';
import { CTAButton } from 'components/UI/Buttons';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';
import { forgetPasswordRequest } from 'utils/AwsAsync';
import { useForm } from 'react-hook-form';
import FormError from 'components/UI/Inputs/FormError';

interface ForgotContentProps {
  backToLogin: () => void;
  goToResetPassword: () => void;
}

const ForgotContent = ({
  backToLogin,
  goToResetPassword,
}: ForgotContentProps) => {
  const { register, errors, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const intl = useIntl();

  const requestNewPassword = async data => {
    const { username } = data;
    setLoading(true);
    try {
      await forgetPasswordRequest({ username });
      goToResetPassword();
    } catch (error) {
      setSubmitError(
        error.name === 'UserNotFoundException'
          ? intl.formatMessage({ id: 'forgot.doesnotexists' })
          : intl.formatMessage({ id: 'error' })
      );
      setLoading(false);
    }
  };

  return (
    <LoginBox onSubmit={handleSubmit(requestNewPassword)}>
      <Typography variant="h5">
        {intl.formatMessage({ id: 'login.forgot' })}
      </Typography>
      <Typography>{intl.formatMessage({ id: 'forgot.text' })}</Typography>

      <TextField
        variant="outlined"
        size="small"
        inputRef={register({
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
        })}
        name="username"
        placeholder={intl.formatMessage({ id: 'email.placeholder' })}
      />

      {submitError && <FormError>{submitError}</FormError>}
      <FormError>{errors.username?.message}</FormError>

      <ActionsWrapper>
        <Button onClick={backToLogin}>
          {intl.formatMessage({ id: 'forgot.back' })}
        </Button>
        <CTAButton type="submit" loading={loading}>
          {intl.formatMessage({ id: 'login.retrieve' })}
        </CTAButton>
      </ActionsWrapper>
    </LoginBox>
  );
};

export default ForgotContent;
