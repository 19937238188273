import moment from 'moment';
import 'moment/locale/sv';

moment.locale('sv');

/**
 * Stripe only returns integers for their prices. The last two digits of the integer
 * are always decimals.
 * @param num The number to format
 */
export const formatStripefee = (num: string | number): string => {
  let stringified = `${num}`;
  // This is probably uneccesary as zero is represented as 000. Im just staying on the safe side
  if (stringified.length > 2) {
    stringified = [stringified.slice(0, 2), '.', stringified.slice(2)].join('');
  }

  return ccyFormat(parseFloat(stringified));
};

export const ccyFormat = (num, fractionDigits = 0): string => {
  if (num === 0) {
    return '0';
  }
  if (!num) {
    return num;
  }
  const value = typeof num === 'number' ? num : parseFloat(num);

  // Using toFixed instead of toLocaleString since it's way much faster
  const temp = value.toFixed(fractionDigits).replace('.', ',');

  let result = value < 0 ? '-' : '';
  // Insert thousand markers without the decimals
  const len = temp.length - (fractionDigits ? fractionDigits + 1 : 0);
  // Start after the minus sign
  const start = value < 0 ? 1 : 0;
  for (let i = start; i < len; i++) {
    if (i > start && (len - i) % 3 === 0) {
      // Insert no-breaking-space as thousand markers
      result += '\u00a0';
    }
    result += temp.charAt(i);
  }
  // Add the decimals and return
  return result + temp.substring(len);
};

export const ccyParse = (
  value: string | number | undefined
): number | undefined => {
  if (typeof value === 'string') {
    return parseFloat(value.replace(/[\s.]+/g, '').replace(',', '.'));
  }
  return value;
};

export const formatPercentage = (
  value: number | undefined,
  decimals: number = 2
) => (value === undefined ? '' : `${ccyFormat(value * 100.0, decimals)}%`);

export const parsePercentage = (value: string): number | undefined => {
  const trimmedValue = value.trim();
  const result = trimmedValue.endsWith('%')
    ? ccyParse(trimmedValue.substring(0, trimmedValue.length - 1))
    : ccyParse(trimmedValue);

  return result !== undefined ? result / 100 : undefined;
};

export const date = dateString => moment.unix(dateString).format('L');

export const parseDateStringToMonth = dateString => {
  const isoFormatted = dateString.slice(0, 4) + '-' + dateString.slice(4, 6);
  return capitalize(moment(isoFormatted).format('MMMM'));
};

export const capitalize = (str: string): string =>
  str.length < 2
    ? str.toUpperCase()
    : `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;

export const parseDateStringToMonthAndYear = dateString => {
  const isoFormatted = dateString.slice(0, 4) + '-' + dateString.slice(4, 6);
  return ` ${capitalize(moment(isoFormatted).format('MMMM'))} ${moment(
    isoFormatted
  ).format('YYYY')}`;
};

export const parseYearReportData = data => {
  const parsed = {};
  for (let key in data) {
    if (key === '__typename') continue;
    // Keys can be null in the DB, create empty objects in those cases
    parsed[key] = JSON.parse(data[key]) || {};
  }
  return parsed;
};
