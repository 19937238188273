import get from 'lodash/get';

import {
  SET_SIE_DATA,
  SET_USER_INPUT_DATA,
  SET_ROUTINES_DATA,
  RESET_CUSTOMER,
  SET_ADJUSTMENT_DATA,
  PATCH_USER_INPUT_DATA,
  PATCH_USER_INPUT_ROUTINE_DATA,
  STARTED_SAVING_USER_DATA,
  SAVING_USER_DATA_FAILED,
  SAVING_USER_DATA_SUCCESS,
  INCREASE_IMAGES,
  DECREASE_IMAGES,
  SET_NUM_IMAGES,
  TOGGLE_SINGLE_PERIOD,
  TOGGLE_PERIOD_CHANGE_VISIBILITY,
  TOGGLE_PERIOD_UB_VISIBILITY,
} from '../../actionsTypes';
import AccountingState from './types';
import { GlobalActions } from 'redux/actions';
import { AccountingActionTypes } from 'redux/actions/AccountingView/types';

export const initialState: AccountingState = {
  userInput: {},
  routines: {},
  adjustmentData: [],
  savingUserDataStatus: 'SAVED',
  periodChangeVisible: true,
  periodUBVisible: true,
  filteredTransactions: [],
};

export default (
  state: AccountingState = initialState,
  action: AccountingActionTypes | GlobalActions | any // TODO: remove `| any`, and type the other actions
): AccountingState => {
  switch (action.type) {
    case STARTED_SAVING_USER_DATA:
      return {
        ...state,
        savingUserDataStatus: 'SAVING',
      };
    case SAVING_USER_DATA_FAILED:
      return {
        ...state,
        savingUserDataStatus: 'FAILED',
      };
    case SAVING_USER_DATA_SUCCESS:
      return {
        ...state,
        savingUserDataStatus: 'SAVED',
      };
    case SET_ADJUSTMENT_DATA:
      return {
        ...state,
        adjustmentData: action.adjustmentData,
      };
    case SET_SIE_DATA:
      if (state.sieData?.sieBlob === action.sieData.sieBlob) {
        return state;
      }
      return {
        ...state,
        sieData: {
          ...action.sieData,
          accounts: action.sieData.sieBlob.reduce((accounts, account) => {
            accounts[account.account] = account;
            return accounts;
          }, {}),
        },
      };
    case SET_USER_INPUT_DATA:
      return {
        ...state,
        userInput: action.userInput,
      };
    case SET_ROUTINES_DATA:
      return {
        ...state,
        routines: action.routines,
      };
    case PATCH_USER_INPUT_DATA:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          [`account${action.accountNumber}`]: {
            ...state.userInput[`account${action.accountNumber}`],
            [action.formattedPeriod]: {
              ...(state.userInput[`account${action.accountNumber}`] &&
                state.userInput[`account${action.accountNumber}`][
                  action.formattedPeriod
                ]),
              ...action.newUserInput,
            },
          },
        },
      };
    case PATCH_USER_INPUT_ROUTINE_DATA:
      return {
        ...state,
        routines: {
          ...state.routines,
          [`account${action.accountNumber}`]: {
            ...state.routines[`account${action.accountNumber}`],
            ...action.newUserInput,
          },
        },
      };
    case INCREASE_IMAGES:
      const numImagesBeforeIncrease =
        state.userInput[`account${action.accountNumber}`][
          action.formattedPeriod
        ]?.numImages;
      const numImagesIncreased = numImagesBeforeIncrease
        ? numImagesBeforeIncrease + 1
        : 1;
      return {
        ...state,
        userInput: {
          ...state.userInput,
          [`account${action.accountNumber}`]: {
            ...state.userInput[`account${action.accountNumber}`],
            [action.formattedPeriod]: {
              ...(state.userInput[`account${action.accountNumber}`] &&
                state.userInput[`account${action.accountNumber}`][
                  action.formattedPeriod
                ]),
              numImages: numImagesIncreased,
            },
          },
        },
      };
    case DECREASE_IMAGES:
      const numImagebeforeDecrease = get(
        state,
        `userInput[account${action.accountNumber}][${action.formattedPeriod}].numImages`,
        0
      );

      const numImagesDecreased =
        numImagebeforeDecrease > 0 ? numImagebeforeDecrease - 1 : 0;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          [`account${action.accountNumber}`]: {
            ...state.userInput[`account${action.accountNumber}`],
            [action.formattedPeriod]: {
              ...(state.userInput[`account${action.accountNumber}`] &&
                state.userInput[`account${action.accountNumber}`][
                  action.formattedPeriod
                ]),
              numImages: numImagesDecreased,
            },
          },
        },
      };
    case SET_NUM_IMAGES:
      if (
        state.userInput?.[`account${action.accountNumber}`]?.[
          action.formattedPeriod
        ]?.numImages !== action.numImages
      ) {
        return {
          ...state,
          userInput: {
            ...state.userInput,
            [`account${action.accountNumber}`]: {
              ...state.userInput[`account${action.accountNumber}`],
              [action.formattedPeriod]: {
                ...(state.userInput[`account${action.accountNumber}`] &&
                  state.userInput[`account${action.accountNumber}`][
                    action.formattedPeriod
                  ]),
                numImages: action.numImages,
              },
            },
          },
        };
      } else {
        return state;
      }
    case RESET_CUSTOMER:
      return {
        ...initialState,
      };
    case TOGGLE_SINGLE_PERIOD:
      return {
        ...state,
        showSinglePeriod:
          action.period === state.showSinglePeriod ? undefined : action.period,
      };
    case TOGGLE_PERIOD_CHANGE_VISIBILITY:
      return {
        ...state,
        periodChangeVisible: !state.periodChangeVisible,
      };
    case TOGGLE_PERIOD_UB_VISIBILITY:
      return {
        ...state,
        periodUBVisible: !state.periodUBVisible,
      };
    default:
      return state;
  }
};
