import {
  SET_ORGANISATION,
  INSERT_USER_TO_ORG,
  ADD_ORGANISATION_INFO,
  REMOVE_INVITE,
  REMOVE_MEMBER,
  SET_SUBSCRIPTION_STATUS,
} from '../../actionsTypes';

const initialOrganisationState: Organisation.OrganiationType = {
  admins: [],
  invited: [],
  members: [],
  name: null,
  organisationId: null,
  nextPayment: 0,
};

export default (
  state: Organisation.OrganiationType = initialOrganisationState,
  action
): Organisation.OrganiationType => {
  switch (action.type) {
    case SET_SUBSCRIPTION_STATUS:
      return {
        ...state,
        subscriptionStatus: action.status,
      };
    case SET_ORGANISATION:
      return {
        ...state,
        ...action.organisation,
      };
    case INSERT_USER_TO_ORG:
      return {
        ...state,
        invited: [...state.invited, action.email],
      };
    case REMOVE_INVITE:
      return {
        ...state,
        invited: state.invited.filter(email => email !== action.email),
      };
    case ADD_ORGANISATION_INFO:
      return {
        ...state,
        ...action.organisationInfo,
      };
    case REMOVE_MEMBER:
      return {
        ...state,
        members: state.members.filter(email => email !== action.email),
      };
    default:
      return state;
  }
};
