import { useState, useEffect } from 'react';
import useAgoyAppClient from './useAgoyAppClient';

interface LoadingState<ErrorType> {
  loading: boolean;
  error: ErrorType | string | null;
  refetch: () => Promise<void>;
}

export type AgoyAppQuery<T, ErrorType = any> = [
  T | null,
  LoadingState<ErrorType>
];

export const useAgoyAppQuery = <T, ErrorType = any>(
  uri
): AgoyAppQuery<T, ErrorType> => {
  const [state, setState] = useState<[T | null, LoadingState<ErrorType>]>([
    null,
    {
      loading: false,
      error: null,
      refetch: async () => {},
    },
  ]);

  const client = useAgoyAppClient<T>();

  useEffect(() => {
    const get = async () => {
      setState(state => [
        state[0],
        { loading: true, error: null, refetch: get },
      ]);
      const result = await client(uri);
      if (result.ok) {
        setState([result.body, { loading: false, error: null, refetch: get }]);
      } else {
        setState([null, { loading: false, error: result.error, refetch: get }]);
      }
    };

    if (uri) get();
  }, [uri, client]);

  return state;
};
