import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Typography, Button } from '@material-ui/core';

import { useSelector } from 'redux/reducers';
import { userLogIn } from 'redux/actions';
import { CTAButton } from 'components/UI/Buttons';
import FormError from 'components/UI/Inputs/FormError';
import {
  LoginBox,
  LinkWrapper,
  TextField,
  UnderlineLink,
  ActionsWrapper,
} from './SharedComponents';

interface LoginContentProps {
  forgotPasswordCallback: () => void;
  createUserCallback: () => void;
}

const LoginContent = ({
  forgotPasswordCallback,
  createUserCallback,
}: LoginContentProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm();
  const authenticating = useSelector(state => state.user.isAuthenticating);
  const [submitError, setSubmitError] = useState('');

  const handleFormSubmit = async data => {
    const { username, password } = data;
    try {
      await dispatch(userLogIn({ username, password }));
    } catch (error) {
      setSubmitError(
        error.name === 'UserNotFoundException' ||
          error.name === 'NotAuthorizedException'
          ? intl.formatMessage({ id: 'login.userOrPassWordWrong' })
          : error.name === 'UserNotConfirmedException'
          ? intl.formatMessage({ id: 'login.userNotConfirmed' })
          : intl.formatMessage({ id: 'error' })
      );
    }
  };

  return (
    <LoginBox onSubmit={handleSubmit(handleFormSubmit)}>
      <Typography variant="h2">
        {intl.formatMessage({ id: 'login.welcome' })}
      </Typography>
      <Typography>{intl.formatMessage({ id: 'login.text' })}</Typography>

      <TextField
        variant="outlined"
        size="small"
        inputRef={register({
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
        })}
        name="username"
        placeholder={intl.formatMessage({ id: 'email.placeholder' })}
        type="email"
        data-cy="login-username-input"
      />

      <FormError>{errors.username?.message}</FormError>

      <TextField
        variant="outlined"
        size="small"
        inputRef={register({
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
        })}
        name="password"
        placeholder={intl.formatMessage({ id: 'password' })}
        type="password"
        data-cy="login-password-input"
      />

      <FormError>{errors.password?.message}</FormError>

      {submitError && (
        <FormError data-cy="login-error">{submitError}</FormError>
      )}

      <LinkWrapper>
        <UnderlineLink onClick={forgotPasswordCallback}>
          {intl.formatMessage({ id: 'login.forgot' })}
        </UnderlineLink>
      </LinkWrapper>

      <ActionsWrapper>
        <Button onClick={createUserCallback}>
          {intl.formatMessage({ id: 'create.user' })}
        </Button>
        <CTAButton
          type="submit"
          loading={authenticating}
          data-cy="login-submit-btn"
        >
          {intl.formatMessage({ id: 'login' })}
        </CTAButton>
      </ActionsWrapper>
    </LoginBox>
  );
};

export default LoginContent;
