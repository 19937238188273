import React, { Suspense, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import {
  Typography,
  List,
  ListItem,
  Paper,
  WithTheme,
  ListItemIcon,
  ListItemText,
  TextField,
  FormControl,
  InputAdornment,
  Grid,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { POPULATE_CUSTOMERS } from 'redux/actionsTypes';
import { GET_ALL_CUSTOMERS } from 'Graphql/Queries';
import { parseCustomers } from 'utils/GraphqlParser';
import Logo from 'components/UI/Logo';
import Container from './Container';
import { AgoyAppQuery } from 'utils/AgoyAppClient/useAgoyAppQuery';

const EditClient = React.lazy(() => import('../Modals/EditClient'));

const CustomerLogo = styled(Logo)<WithTheme>`
  width: 50px;
  height: 50px;
  margin-right: ${props => props.theme.spacing(2)}px;
  display: block;
`;

const SearchField = styled(FormControl)`
  width: 100%;
`;

const ClientStatus = styled(Typography)<WithTheme & { status: string }>`
  color: ${props =>
    props.status === 'active'
      ? props.theme.palette.secondary.main
      : props.theme.palette.error.main};
  text-transform: capitalize;
`;

interface CustomersProps {
  orgMembersQuery: AgoyAppQuery<Member.MemberType[], any>;
}

const Customers = ({ orgMembersQuery }: CustomersProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('');
  const [editCustomerOpen, setEditCustomerOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | null>(null);
  const [customers, setCustomers] = useState<Customer.CustomerType[]>([]);

  const allCustomers = useSelector(state => state.customers);
  const admins = useSelector(state => state.organisation.admins);
  const userEmail = useSelector(state => state.user.email);

  const isUserAdmin = userEmail && admins ? admins.includes(userEmail) : false;

  useEffect(() => {
    // keep local sorted and filtered list of customers displayed in list
    const sortedCustomers = Array.from(
      Object.values(allCustomers)
    ).sort((a, b) => a.name.localeCompare(b.name));
    setCustomers(sortedCustomers);
  }, [allCustomers]);

  const { loading } = useQuery(GET_ALL_CUSTOMERS, {
    onCompleted: async data => {
      data.allCustomers &&
        dispatch({
          type: POPULATE_CUSTOMERS,
          customers: await parseCustomers(data.allCustomers.customers),
        });
    },
  });

  const clickListItem = (customerId: string) => {
    setSelectedCustomerId(customerId);
    setEditCustomerOpen(true);
  };

  const clickCloseEditCustomer = () => {
    setEditCustomerOpen(false);
  };

  const onSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const search: string = event.target.value.trim().toLowerCase();
    setSearchString(search);
    const sortedCustomers = Array.from(
      Object.values(allCustomers)
    ).sort((a, b) => a.name.localeCompare(b.name));
    setCustomers(
      sortedCustomers.filter(customer =>
        customer.name.toLowerCase().includes(search)
      )
    );
  };

  return (
    <>
      <Suspense
        fallback={
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="40px"
            component="div"
          />
        }
      >
        {editCustomerOpen && (
          <EditClient
            customerId={selectedCustomerId}
            handleClose={clickCloseEditCustomer}
            orgMembersQuery={orgMembersQuery}
          />
        )}
      </Suspense>
      <Paper elevation={4}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {intl.formatMessage({ id: 'dashboard.customers.heading' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SearchField>
                <TextField
                  id="search-customer"
                  variant="outlined"
                  size="small"
                  placeholder={intl.formatMessage({
                    id: 'dashboard.customers.search.placeHolder',
                  })}
                  fullWidth
                  onChange={ev => {
                    onSearchInputChange(ev);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </SearchField>
              <Grid item xs={12}>
                {loading ? (
                  <List>
                    <Skeleton
                      variant="rect"
                      animation="wave"
                      width="100%"
                      height="40px"
                      component="li"
                    />
                  </List>
                ) : (
                  <List>
                    {customers.length !== 0 ? (
                      customers
                        .filter(
                          customer =>
                            customer.status === 'active' || isUserAdmin
                        )
                        .map(customer => (
                          <React.Fragment key={customer.id}>
                            <ListItem
                              button
                              onClick={() => {
                                clickListItem(customer.id);
                              }}
                            >
                              <ListItemIcon>
                                <CustomerLogo src={customer.logo} />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography>{customer.name}</Typography>
                                {isUserAdmin ? (
                                  <ClientStatus status={customer.status}>
                                    {intl.formatMessage({
                                      id: `dashboard.customers.status.${customer.status}`,
                                    })}
                                  </ClientStatus>
                                ) : null}
                              </ListItemText>
                            </ListItem>
                          </React.Fragment>
                        ))
                    ) : searchString ? (
                      <ListItem>
                        <ListItemText>
                          {intl.formatMessage({
                            id: 'dashboard.customers.search.noResults',
                          })}
                        </ListItemText>
                      </ListItem>
                    ) : null}
                  </List>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

export default Customers;
