import React, { useRef, useEffect } from 'react';

export interface PropTypes {
  onDrop: (file: File) => void;
  className?: string;
  onEnter?: (event: React.DragEvent) => void;
  onLeave?: (event: React.DragEvent) => void;
  isValidFile: (file: File) => boolean;
  onInvalidFileType: () => void;
  children: React.ReactNode;
}

const FileDropArea = (props: PropTypes) => {
  const inside = useRef(0);

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    inside.current = 0;
    const file = event.dataTransfer.files[0];
    if (file && props.isValidFile(file)) {
      props.onDrop(file);
    } else {
      props.onInvalidFileType();
    }
  };

  const handleLeave = (event: React.DragEvent) => {
    inside.current--;
    if (inside.current === 0 && props.onLeave) {
      props.onLeave(event);
    }
  };

  const handleEnter = (event: React.DragEvent) => {
    inside.current++;
    if (inside.current === 1 && props.onEnter) {
      props.onEnter(event);
    }
  };

  useEffect(() => {
    const handleWindowEvents = (event: Event) => {
      event.stopPropagation();
      event.preventDefault();
    };

    document.addEventListener('drop', handleWindowEvents, false);
    document.addEventListener('dragover', handleWindowEvents, false);
    return () => {
      document.removeEventListener('dragover', handleWindowEvents, false);
      document.removeEventListener('drop', handleWindowEvents, false);
    };
  }, []);

  return (
    <div
      className={props.className}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnter={handleEnter}
      onDragLeave={handleLeave}
    >
      {props.children}
    </div>
  );
};

export default FileDropArea;
