import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { NoteInputFieldSection } from 'types/AnnualReport/parts/notes';
import { numberValue, stringValue } from 'utils/AnnualReport/cell';
import Comment from './Comment';
import FieldSection from './FieldSection';
import Header from './Header';
import { Note } from './Note';

type InputFieldNoteProps = {
  noteId: string;
  print?: boolean;
} & NoteInputFieldSection;

const InputFieldNote = ({
  noteId,
  active,
  number,
  inputField,
  comment,
  print = false,
}: InputFieldNoteProps) => {
  const [editing, setEditing] = useState(false);

  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header
        noteId={noteId}
        noteNumber={numberValue(number)}
        editing={editing}
        onEditToggle={editing => {
          setEditing(!editing);
        }}
        print={print}
      />

      <Box mt={print ? 1 : 3} mb={print ? 1 : 3}>
        <FieldSection
          noteId={noteId}
          id="inputField"
          value={stringValue(inputField) || ''}
          editing={editing}
          multiline
          print={print}
        />
      </Box>

      {/* Comment */}
      <Comment
        id={`notes.${noteId}.comment`}
        editing={editing}
        value={stringValue(comment) || ''}
        print={print}
      />
    </Note>
  );
};

export default InputFieldNote;
