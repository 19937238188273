import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const NotPayedAdmin = ({ subscriptionStatus }) => {
  const intl = useIntl();

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          {intl.formatMessage({ id: 'subscription_expired.admin' })}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          type="submit"
          component={Link}
          to="/organisation"
        >
          {intl.formatMessage({ id: 'subscription_expired.goToSettings' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotPayedAdmin;
