import {
  ResolveReferenceContext,
  ReferenceError,
  ResolvedReference,
} from './types';
import { AccountInformation, SummedSaldo } from 'utils/SieParser';

type Value = number | undefined | ReferenceError;

const getPeriodValue = (
  accountInformation: AccountInformation | undefined,
  period: string,
  modifier: 'ib' | 'ub' | 'change'
): number | undefined => {
  if (!accountInformation) {
    return undefined;
  }
  const periods = accountInformation.periods;
  let foundPeriod: SummedSaldo | undefined;
  for (let i = 0; i < periods.length; i++) {
    if (periods[i].period <= period) {
      foundPeriod = periods[i];
    } else {
      break;
    }
  }

  if (foundPeriod) {
    switch (modifier) {
      case 'ub':
        return foundPeriod.ub;
      case 'ib':
        return foundPeriod.period === period ? foundPeriod.ib : foundPeriod.ub;
      case 'change':
        return foundPeriod.period === period ? foundPeriod.saldo : 0;
    }
  }
  if (accountInformation.account < '3000') {
    switch (modifier) {
      case 'ub':
        return accountInformation.yearIbs?.find(year => year.yearNo === '0')
          ?.saldo;
      case 'ib':
        return accountInformation.yearIbs?.find(year => year.yearNo === '-1')
          ?.saldo;
      case 'change':
        return 0;
    }
  } else {
    return 0;
  }
};

type AccountValueModifier = 'yearIb' | 'ib' | 'ub' | 'change';
const modifiers = ['yearIb', 'ib', 'ub', 'change'];
const isAccountValueModifier = (
  m: string | undefined
): m is AccountValueModifier => (m ? modifiers.includes(m) : false);

const getAccountValue = (
  sieData: Record<string, AccountInformation>,
  period: string | null,
  account: string,
  modifier: AccountValueModifier
): number | undefined => {
  let value: number | undefined;
  const accountInfo = sieData[account];
  if (period) {
    switch (modifier) {
      case 'yearIb':
        value =
          account < '3000'
            ? accountInfo?.yearIbs.find(year => year.yearNo === '0')?.saldo
            : accountInfo?.res.find(year => year.yearNo === '0')?.saldo;
        break;
      default:
        value = getPeriodValue(accountInfo, period, modifier);
        break;
    }
  } else {
    switch (modifier) {
      case 'yearIb':
      case 'ib':
        value =
          account < '3000'
            ? accountInfo?.yearIbs.find(year => year.yearNo === '0')?.saldo
            : accountInfo?.res.find(year => year.yearNo === '-1')?.saldo;
        break;
      case 'ub':
        value =
          account < '3000'
            ? accountInfo?.yearUbs.find(year => year.yearNo === '0')?.saldo
            : accountInfo?.res.find(year => year.yearNo === '0')?.saldo;
        break;
      case 'change':
        const yearIb =
          account < '3000'
            ? accountInfo?.yearIbs.find(year => year.yearNo === '0')?.saldo
            : accountInfo?.res.find(year => year.yearNo === '-1')?.saldo;
        const yearUb =
          account < '3000'
            ? accountInfo?.yearUbs.find(year => year.yearNo === '0')?.saldo
            : accountInfo?.res.find(year => year.yearNo === '0')?.saldo;
        if (yearIb !== undefined && yearUb !== undefined) {
          value = yearUb - yearIb;
        } else {
          value = undefined;
        }
    }
  }
  return value;
};

const resolveIdReference = <Context extends ResolveReferenceContext>(
  args: string | undefined,
  context: Context
): Value | Value[] => {
  if (!args) {
    return undefined;
  }
  if (args.includes('*')) {
    if (!context.expandIds) {
      return ReferenceError.ExpandNotSupported;
    }
    return context
      .expandIds(args, context)
      .map(id => context.resolveById(id, context));
  }
  return context.resolveById(args, context);
};

const resolveConfigReference = (
  name: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (name === undefined) {
    return undefined;
  }
  return context.resolveConfig(name, context);
};

/**
 * Resolves a value from SIE data accounts. Supports summing a range of accounts.
 *
 * "account(2020)" gets the outgoing balance for account 2020.
 * "account(2020,ib)" gets the ingoing balance for account 2020.
 * "account(2000:2099)" sums the outgoing balances for accounts 2000 - 2099 inclusive.
 * "account(1000:1099+2000:2099)" sum account 1000 - 1099 and 2000 - 2099.
 *
 * @param args the argument part of account()
 * @param context
 */
const resolveAccountReference = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  const { input } = context;
  if (!args) {
    return undefined;
  }

  const [accounts, params] = args.split(',');
  const accountNumbers = accounts
    .split('+')
    .map(range => {
      const [firstAccount, lastAccount, name] = range.split(':');

      if (lastAccount === undefined) {
        return input.accounts[firstAccount] ? [firstAccount] : [];
      }
      return Object.keys(input.accounts).filter(account => {
        const isInRange = account >= firstAccount && account <= lastAccount;
        if (isInRange && name) {
          return input.accounts[account].accountName
            .toLowerCase()
            .includes(name);
        }
        return isInRange;
      });
    })
    .reduce((keys, values) => [...new Set([...keys, ...values])]);

  if (accountNumbers.length === 0) {
    return ReferenceError.MissingAccount;
  }

  return accountNumbers
    .map(account =>
      getAccountValue(
        input.accounts,
        input.period,
        account,
        isAccountValueModifier(params) ? params : 'ub'
      )
    )
    .reduce((sum: number, value) => (value ? sum + value : sum), 0);
};

const resolveFromMultipleReferences = (
  reducer: (res: Value, value: Value) => Value,
  initialValue?: number,
  ignoreMissing?: boolean
) => (args: string | undefined, context: ResolveReferenceContext): Value => {
  if (!args) {
    return undefined;
  }

  const references = resolveMultipleReferences(args, context, ignoreMissing);

  if (references.length === 0) {
    return undefined;
  }

  if (initialValue === undefined) {
    return references.reduce(reducer);
  }
  return references.reduce(reducer, initialValue);
};

const resolveMultiplyReference = resolveFromMultipleReferences((res, value) => {
  if (typeof res === 'string') {
    return res;
  }
  if (typeof value === 'string') {
    return value;
  }

  return res === undefined || value === undefined ? undefined : res * value;
}, 1);

const resolveSumReference = resolveFromMultipleReferences((res, value) => {
  if (typeof res === 'string') {
    return res;
  }
  if (typeof value === 'string') {
    return value;
  }
  return res === undefined || value === undefined ? undefined : res + value;
}, 0);

const resolveMaxReference = resolveFromMultipleReferences((res, value) => {
  if (typeof res === 'string') {
    return res;
  }
  if (typeof value === 'string') {
    return value;
  }
  return res === undefined
    ? value
    : value === undefined
    ? res
    : Math.max(res, value);
});

const resolveDivReference = resolveFromMultipleReferences((res, value) => {
  if (value === 0 || value === undefined) {
    return ReferenceError.DivisionByZero;
  }

  if (typeof res === 'string') {
    return res;
  }

  if (typeof value === 'string') {
    return value;
  }

  return res === undefined ? 0 : res / value;
});

const resolveMinReference = resolveFromMultipleReferences((res, value) => {
  if (typeof res === 'string') {
    return res;
  }
  if (typeof value === 'string') {
    return value;
  }
  return res === undefined
    ? value
    : value === undefined
    ? res
    : Math.min(res, value);
});

const resolveOrReference = resolveFromMultipleReferences(
  (res, value) => {
    if (typeof res === 'string') {
      return res;
    }
    if (typeof value === 'string') {
      return value;
    }
    return res === undefined ? value : res;
  },
  undefined,
  true
);

export const resolveMultipleReferences = (
  references: string,
  context: ResolveReferenceContext,
  ignoreMissing: boolean | undefined
): Value[] => {
  let referencesLeft: string | undefined = references;
  let result: Value[] = [];
  while (referencesLeft) {
    let [value, nextLeft] = resolveReferenceInternal(referencesLeft, context);
    if (
      ignoreMissing &&
      (value === ReferenceError.MissingAccount ||
        value === ReferenceError.MissingId)
    ) {
      result.push(undefined);
    } else if (Array.isArray(value)) {
      result = result.concat(value);
    } else {
      result.push(value);
    }
    nextLeft = nextLeft?.trim();
    if (nextLeft) {
      if (nextLeft.charAt(0) === ',') {
        referencesLeft = nextLeft.substring(1).trimLeft();
      } else {
        break;
      }
    } else {
      break;
    }
  }
  return result;
};

const round = (
  args: string | undefined,
  context: ResolveReferenceContext
): Value => {
  if (args === undefined) {
    return undefined;
  }
  const [value] = resolveReferenceInternal(args, context);
  if (typeof value === 'number') {
    if (value < 0) {
      return -Math.round(-value);
    }
    return Math.round(value);
  }
  if (typeof value === 'string' || value === undefined) {
    return value;
  }
  return ReferenceError.TooManyValuesReturned;
};

const extractNumber = (str: string): string => {
  for (let i = 0; i < str.length; i++) {
    if (
      (str.charAt(i) >= '0' && str.charAt(i) <= '9') ||
      str.charAt(i) === '-' ||
      str.charAt(i) === '.'
    ) {
      continue;
    }
    return str.substring(0, i);
  }
  return str;
};

export const parseReference = (
  reference: string
): [string, string | undefined, string | undefined] => {
  let i = 0;
  let typeEnd;
  let depth = 0;
  while (i < reference.length && typeEnd === undefined) {
    if (reference.charAt(i) === '(') {
      typeEnd = i;
      depth++;
    }
    i++;
  }
  if (reference.length > 0) {
    const number = extractNumber(reference);
    if (number) {
      return [number, undefined, reference.substring(number.length)];
    }
  }
  if (typeEnd === undefined) {
    return [reference, undefined, undefined];
  }
  while (i < reference.length && depth > 0) {
    if (reference.charAt(i) === '(') {
      depth++;
    } else if (reference.charAt(i) === ')') {
      depth--;
    }
    i++;
  }
  return [
    reference.substring(0, typeEnd),
    reference.substring(typeEnd + 1, i - 1),
    i < reference.length ? reference.substring(i) : undefined,
  ];
};

/**
 *
 * @param reference
 * @param context
 * @returns [resolvedValue, remaining string after reference]
 */
const resolveReferenceInternal = <Context extends ResolveReferenceContext>(
  reference: string,
  context: Context
): [Value[] | Value, string | undefined] => {
  const [type, args, leftOver] = parseReference(reference);

  if (args !== undefined) {
    switch (type) {
      case 'account':
        return [resolveAccountReference(args, context), leftOver];
      case 'id':
        return [resolveIdReference(args, context), leftOver];
      case 'config':
        return [resolveConfigReference(args, context), leftOver];
      case 'multiply':
        return [resolveMultiplyReference(args, context), leftOver];
      case 'div':
        return [resolveDivReference(args, context), leftOver];
      case 'sum':
        return [resolveSumReference(args, context), leftOver];
      case 'max':
        return [resolveMaxReference(args, context), leftOver];
      case 'min':
        return [resolveMinReference(args, context), leftOver];
      case 'or':
        return [resolveOrReference(args, context), leftOver];
      case 'round':
        return [round(args, context), leftOver];
      case 'yearPercentage':
        if (context.input.period === null) {
          return [1, leftOver];
        }
        return [
          (context.input.periods.indexOf(context.input.period) + 1) /
            context.input.periods.length,
          leftOver,
        ];
      default:
        throw ReferenceError.UnknownReferenceType;
    }
  }
  const numericValue = parseFloat(type);
  if (typeof numericValue === 'number' && !Number.isNaN(numericValue)) {
    return [numericValue, leftOver];
  }
  return [undefined, leftOver];
};

/**
 * resolveReference
 *
 * Functions:
 * - id(rowId), will get the value using another calculated row with id "rowId"
 * - config(configName), will get the value from config by name.
 * - multiply(value1,value2,...), will multiply all values that are defined by value1..n and that
 *      can be other references, like "multiply(account(8910),-1)" to multiply outgoing balance of
 *      account 8910 with the number -1.
 * - sum(value1,value2,...), like multiply but addition.
 * - yearPercentage(), special function to get the percentage of the financial year that the period reflects.
 *      If the financial year is 2019-01-01 - 2019-12-24 and the period is 2019-06, then it will return 50%, (0.5)
 *      since the end of June is halfway through the year.
 * - max(value1,value2,...), returns the maximum value of the resolved value1, value2,...
 *
 * @param reference
 * @param context
 */
export const resolveReference = <Context extends ResolveReferenceContext>(
  reference: string,
  context: Context
): ResolvedReference => {
  try {
    const [value] = resolveReferenceInternal(reference, context);
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return undefined;
      }
      if (value.length > 1) {
        return ReferenceError.TooManyValuesReturned;
      }
      return value[0];
    }
    return value;
  } catch (error) {
    if (typeof error === 'string') {
      return error as ReferenceError;
    } else {
      console.error('resolveReference', error);
    }
  }
};
