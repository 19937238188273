import { AnnualReportTableRow } from 'types/AnnualReport/table';
import groupBy from 'lodash-es/groupBy';

/**
 * Helper for UI table component which flattens rows ONE depth and creates id based on parent id
 * @param rows AnnualReportTable rows
 * @returns AnnualReportTableRow[]
 */
export const flattenRows = (rows: AnnualReportTableRow[]) => {
  const result: AnnualReportTableRow[] = [];
  if (!rows) return result;

  rows.forEach(row => {
    if (row.cells) result.push(row);
    if (row.rows) {
      row.rows.forEach(subRow => {
        result.push({ ...subRow, id: `${row.id}.${subRow.id}` });
      });
    }
  });

  return result;
};

/**
 * Checks if a table row in a Note should be included in printed annual report
 * @param row AnnualReportTableRow to check
 */
export const includeRowInPrint = (row: AnnualReportTableRow) => {
  if (!row.cells) return false;
  const groupedByType = groupBy(row.cells, 'type');

  // always returns row if it contains cell reference
  if (groupedByType['ref']) {
    return groupedByType['ref'].length > 0;
  }

  // only returns row if not all cell values are undefined
  if (groupedByType['number']) {
    return (
      groupedByType['number'].filter(cell => cell.value !== undefined).length >
      0
    );
  }

  return false;
};
