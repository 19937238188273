import { useState, useCallback, useMemo } from 'react';
import useAgoyAppClient, { Method } from './useAgoyAppClient';

interface LoadingState<ErrorType> {
  loading: boolean;
  error: ErrorType | string | null;
}

export type AgoyAppMutate<Params extends any[], T = void, ErrorType = any> = [
  (...args: Params) => Promise<T>,
  LoadingState<ErrorType>
];

interface Request<T> {
  uri: string;
  content?: T;
  rawContent?: ArrayBuffer;
  contentType?: string;
}

/**
 * Important: Make sure createRequest stays the same for every invocation!
 *
 * @param createRequest Method to create uri and content for the request sent to the api.
 * The parameters for the returned function will be the same as for this method.
 *
 * @param method 'HTTP method'
 */
export const useAgoyAppMutate = <
  Params extends any[],
  RequestBody = undefined,
  T = void,
  ErrorType = any
>(
  createRequest: (...args: Params) => Request<RequestBody>,
  method: Method
): AgoyAppMutate<Params, T, ErrorType> => {
  const [{ loading, error }, setState] = useState<LoadingState<ErrorType>>({
    loading: false,
    error: null,
  });
  const client = useAgoyAppClient<T, RequestBody, ErrorType>();

  const caller = useCallback(
    async (...args: Params) => {
      const { uri, content, rawContent, contentType } = createRequest(...args);
      try {
        setState({
          loading: true,
          error: null,
        });
        const result = await client(
          uri,
          method,
          content,
          rawContent,
          contentType
        );
        if (result.ok) {
          setState({ loading: false, error: null });
          return result.body;
        } else {
          setState({ loading: false, error: result.error });
          return Promise.reject(result.error);
        }
      } catch (e) {
        setState({ loading: false, error: null });
        throw e;
      }
    },
    [client, createRequest, method]
  );

  return useMemo(() => [caller, { loading, error }], [caller, loading, error]);
};
