import {
  TOGGLE_LAMPS_EXPANDED,
  TOGGLE_MINIMIZED_CARDS,
} from '../../actionsTypes';
import CustomersOverviewState from './types';

const initialCustomersOverviewState: CustomersOverviewState = {
  lampsExpanded: false,
  cardsMinimized: false,
};

export default (
  state: CustomersOverviewState = initialCustomersOverviewState,
  action
): CustomersOverviewState => {
  switch (action.type) {
    case TOGGLE_LAMPS_EXPANDED:
      return {
        ...state,
        lampsExpanded: !state.lampsExpanded,
      };
    case TOGGLE_MINIMIZED_CARDS:
      return {
        ...state,
        cardsMinimized: !state.cardsMinimized,
      };
    default:
      return state;
  }
};
