import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Container, Button, Grid, WithTheme } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import {
  setUserAttributes,
  insertUserAttributes,
  addGlobalErrorMessage,
} from 'redux/actions';
import { SimplifiedTextfield } from 'components/UI';
import { useUploadProfilePicture } from 'components/Api/Member';
import { CTAButton } from 'components/UI/Buttons';
import FormError from 'components/UI/Inputs/FormError';
import { changeUserPassword } from 'utils/AwsAsync';
import UserClientImage from '../../../utils/UserClientImage';

interface WrapperProps {
  editing: boolean;
}

const ButtonsWrapper = styled.div<WithTheme>`
  display: flex;
  justify-content: flex-end;
  margin: ${props => `${props.theme.spacing(4)}px;`} 0 0 0;
  > * {
    margin: 0 0 0 ${props => `${props.theme.spacing(2)}px;`};
  }
`;

const Wrapper = styled.div<WrapperProps>`
  display: block;
  margin: auto;
`;

const Profile = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const userEmail = useSelector(state => state.user.email || '');
  const given_name = useSelector(state => state.user.given_name);
  const family_name = useSelector(state => state.user.family_name);
  const phone_number = useSelector(state => state.user.phone_number);
  const picture = useSelector(state => state.user.picture);

  const [editing, setEditing] = useState(false);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [newProfilePicture, setNewProfilePicture] = useState<File | null>(null);

  const { register, handleSubmit, errors, watch, reset, formState } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      given_name: given_name,
      family_name: family_name,
      phone_number: phone_number,
      Password: '',
    },
  });

  const { touched } = formState;

  const newGivenName = watch('given_name');
  const newFamilyName = watch('family_name');
  const newPhoneNumber = watch('phone_number');
  const newPassword = watch('Password');

  const [uploadProfilePicture] = useUploadProfilePicture();

  const PasswordError = 'Error setting password';

  const onSubmit = async data => {
    setLoading(true);
    const toChange: any[] = [];

    if (newProfilePicture) {
      try {
        const response = await uploadProfilePicture(
          userEmail,
          newProfilePicture
        );
        //@ts-ignore
        dispatch(insertUserAttributes({ picture: response.url }));
      } catch (error) {
        if (error.message === 'Incorrect mimetype') {
          setSubmitError(intl.formatMessage({ id: 'hidden.invalidFileType' }));
        } else if (error.message === 'File too big') {
          setSubmitError(intl.formatMessage({ id: 'file.tooBig' }));
        }
      }
    }

    if (Object.values(touched).indexOf(true) !== -1) {
      try {
        const setAttributes = await dispatch(
          setUserAttributes({
            given_name: data.given_name,
            family_name: data.family_name,
            phone_number: data.phone_number,
          })
        );
        toChange.push(setAttributes);
      } catch (error) {
        console.error(error);
        switch (error) {
          case error.given_name:
            setSubmitError('Error setting given name');
            break;
          case error.family_name:
            setSubmitError('Error setting family name');
            break;
          case error.phone_number:
            setSubmitError('Error setting phone number');
            break;
          default:
            setSubmitError('Error setting personal information');
        }
      }
    }

    if (data.Password) {
      try {
        const changePassword = await changeUserPassword(
          data.oldPassword,
          data.Password
        );
        toChange.push(changePassword);
      } catch (error) {
        console.error(error);
        setSubmitError(PasswordError);
        dispatch(addGlobalErrorMessage('error.password'));
      }
    }

    await Promise.all(toChange);

    setDone(true);
    reset({
      given_name: newGivenName,
      family_name: newFamilyName,
      phone_number: newPhoneNumber,
      Password: '',
    });

    setLoading(false);
    toggleEdit();
  };

  const toggleEdit = () => {
    if (submitError !== PasswordError) {
      setEditing(!editing);
      setSubmitError('');
    } else {
      setEditing(true);
    }
  };

  const handlePictureChange = (selectorFiles: FileList | null) => {
    if (selectorFiles) {
      setSaveEnabled(true);
      setNewProfilePicture(selectorFiles[0]);
    }
  };

  const handleChange = e => {
    if (e.target.value !== Object.values(touched)) setSaveEnabled(true);
  };

  return (
    <Container maxWidth="sm">
      <UserClientImage
        editing={editing}
        handleChange={handlePictureChange}
        square={false}
        imageUrl={
          newProfilePicture ? URL.createObjectURL(newProfilePicture) : picture
        }
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError !== '' && <FormError>{submitError}</FormError>}

        <Wrapper editing={editing}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SimplifiedTextfield
                labelPlacement="top"
                label={intl.formatMessage({ id: 'editprofile.firstName' })}
                name="given_name"
                variant="outlined"
                fullWidth
                placeholder={given_name}
                value={editing ? undefined : given_name}
                editing={editing}
                onChange={handleChange}
                inputRef={register({
                  required: true,
                })}
              />

              {errors.given_name && (
                <FormError>
                  {intl.formatMessage({ id: 'fieldrequired' })}
                </FormError>
              )}
            </Grid>
            <Grid item xs={6}>
              <SimplifiedTextfield
                labelPlacement="top"
                label={intl.formatMessage({ id: 'editprofile.lastName' })}
                name="family_name"
                variant="outlined"
                fullWidth
                placeholder={family_name}
                value={editing ? undefined : family_name}
                editing={editing}
                onChange={handleChange}
                inputRef={register({
                  required: true,
                })}
              />

              {errors.family_name && (
                <FormError>
                  {intl.formatMessage({ id: 'fieldrequired' })}
                </FormError>
              )}
            </Grid>
          </Grid>
          <SimplifiedTextfield
            label={intl.formatMessage({ id: 'phone.number' })}
            name="phone_number"
            fullWidth
            labelPlacement="top"
            placeholder={phone_number}
            value={editing ? undefined : phone_number}
            editing={editing}
            onChange={handleChange}
            inputRef={register({
              required: false,
              minLength: 12,
              maxLength: 12,
            })}
          />

          {errors.phone_number && (
            <FormError>
              {intl.formatMessage({ id: 'phonenumber.error' })}
            </FormError>
          )}
          <SimplifiedTextfield
            labelPlacement="top"
            value={userEmail}
            label={intl.formatMessage({ id: 'email' })}
            fullWidth
            editing={editing}
            disabled
          />

          {editing && (
            <SimplifiedTextfield
              labelPlacement="top"
              label={intl.formatMessage({ id: 'new.password' })}
              name="Password"
              type="password"
              fullWidth
              editing={editing}
              inputRef={register}
            />
          )}

          {errors.Password && (
            <FormError>{intl.formatMessage({ id: 'fieldrequired' })}</FormError>
          )}

          {editing && newPassword !== undefined && newPassword !== '' && (
            <SimplifiedTextfield
              labelPlacement="top"
              label={intl.formatMessage({ id: 'old.password' })}
              name="oldPassword"
              type="password"
              variant="outlined"
              fullWidth
              editing={editing}
              onChange={handleChange}
              inputRef={register}
            />
          )}
        </Wrapper>
        <ButtonsWrapper>
          {editing ? (
            <>
              <Button onClick={toggleEdit}>
                {intl.formatMessage({ id: 'cancel' })}
              </Button>
              <CTAButton
                type="submit"
                loading={loading}
                startIcon={done && <DoneIcon />}
                disabled={saveEnabled ? false : true}
              >
                {intl.formatMessage({ id: 'save' })}
              </CTAButton>
            </>
          ) : (
            <CTAButton onClick={toggleEdit}>
              {intl.formatMessage({ id: 'edit' })}
            </CTAButton>
          )}
        </ButtonsWrapper>
      </form>
    </Container>
  );
};

export default Profile;
