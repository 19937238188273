import { DeleteAnnualReportColumnAction } from 'redux/actions/AnnualReportView/types';
import {
  AnnualReportTable,
  AnnualReportTableRow,
  isAnnualReportPartKey,
  RowChange,
} from 'types/AnnualReport/types';
import AnnualReportState from '../types';
import updateTable from './helpers/updateTable';
import { addItem } from './helpers/util';

const deleteColumnInRowChanges = (rows: RowChange[], columnId): RowChange[] => {
  return rows.map(row => {
    if (row.type === 'add') {
      let newRow = row;
      if (newRow.row?.cells) {
        const newCells = { ...newRow.row.cells };
        delete newCells[columnId];
        newRow = { ...newRow, row: { ...newRow.row, cells: newCells } };
      }
      return newRow;
    }
    if (row.type === 'update') {
      let newRow = row;
      if (newRow.rows) {
        newRow = {
          ...newRow,
          rows: deleteColumnInRowChanges(newRow.rows, columnId),
        };
      }
      return newRow;
    }
    return row;
  });
};

const deleteAnnualReportColumn = (
  state: AnnualReportState,
  action: DeleteAnnualReportColumnAction
) => {
  const { id } = action;

  const [part, section, table, columnId] = id.split('.');
  if (columnId === undefined) {
    console.error(`Too few id parts in ${id}`);
    return state;
  }

  if (!isAnnualReportPartKey(part)) {
    return state;
  }

  const updateRow = (row: AnnualReportTableRow): AnnualReportTableRow => {
    let newRow = row;
    if (newRow.rows) {
      newRow = { ...newRow, rows: newRow.rows.map(updateRow) };
    }
    if (newRow.cells) {
      const newCells = { ...newRow.cells };
      delete newCells[columnId];
      newRow = {
        ...newRow,
        cells: newCells,
      };
    }
    if (typeof newRow.newRowTemplate === 'object') {
      newRow = {
        ...newRow,
        newRowTemplate: updateRow(newRow.newRowTemplate),
      };
    }
    return newRow;
  };

  return updateTable(state, part, section, table, (table, change) => {
    const existingAddColumnChange = change.columns?.find(
      c => c.type === 'add' && c.id === columnId
    );
    let newTable: AnnualReportTable = {
      ...table,
      columns: table.columns.filter(col => col.id !== columnId),
      rows: table.rows.map(updateRow),
    };
    if (typeof newTable.newRowTemplate === 'object') {
      newTable = {
        ...newTable,
        newRowTemplate: updateRow(newTable.newRowTemplate),
      };
    }
    if (existingAddColumnChange && existingAddColumnChange.type === 'add') {
      const newColumns = change.columns
        ?.filter(col => col.id !== columnId)
        .map(col => {
          // Other add column changes with indexes higher than the one being delete must shift index
          if (col.type === 'add' && col.index > existingAddColumnChange.index) {
            return { ...col, index: col.index - 1 };
          }
          return col;
        });
      if (!newColumns || newColumns.length === 0) {
        let newChange = { ...change };
        delete newChange.columns;
        if (newChange.rows) {
          newChange = {
            ...newChange,
            rows: deleteColumnInRowChanges(newChange.rows, columnId),
          };
        }
        return [newTable, newChange];
      }
      let newRows = change.rows
        ? deleteColumnInRowChanges(change.rows, columnId)
        : undefined;

      let newChange = { ...change, columns: newColumns };
      if (newRows) {
        newChange = { ...newChange, rows: newRows };
      }
      return [newTable, newChange];
    }
    return [
      newTable,
      {
        ...change,
        columns: addItem(
          change.columns?.filter(col => col.id !== columnId),
          { type: 'delete', id: columnId }
        ),
      },
    ];
  });
};

export default deleteAnnualReportColumn;
