import React, { useCallback, useMemo } from 'react';
import { TextField, Typography } from '@material-ui/core';
import EditField from 'components/UI/Inputs/EditField';
import { updateAnnualReportField } from 'redux/actions/AnnualReportView/actions';
import { useDispatch } from 'react-redux';
import { Field } from 'types/AnnualReport/field';
import { stringValue } from 'utils/AnnualReport/cell';
import styled from '@emotion/styled';

const Textarea = ({ disableUnderline, ...props }) => (
  <TextField fullWidth multiline variant="outlined" {...props} rows={10} />
);

const Block = styled(Typography)`
  margin-bottom: 0.875rem;
  max-width: 800px;
`;

interface MultilineFieldProps {
  id: string;
  field: Field;
  print: boolean;
  editing?: boolean;
  header?: React.ReactElement;
}

const MultilineField = ({
  id,
  field,
  print,
  editing = true,
  header,
}: MultilineFieldProps) => {
  const dispatch = useDispatch();
  const onChange = useCallback(
    (newValue: string) => {
      dispatch(updateAnnualReportField(id, newValue));
    },
    [dispatch, id]
  );
  const value = stringValue(field) || '';
  const splittedForPrint = useMemo(
    () => (print ? value.split(/\r?\n\s*\r?\n/g) : []),
    [print, value]
  );

  if (print) {
    return field.active === false || !value ? null : (
      <>
        {header}
        {splittedForPrint.map((part, index) => (
          <Block key={index}>{part}</Block>
        ))}
      </>
    );
  }
  return (
    <>
      {header}
      <EditField
        value={stringValue(field) || ''}
        onChange={onChange}
        component={Textarea}
        disabled={!editing}
      />
    </>
  );
};

export default MultilineField;
