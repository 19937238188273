import {
  AnnualReportTable,
  AnnualReportTableColumn,
  AnnualReportTableRow,
  AnnualReportTableRowGenerator,
  Cell,
  Field,
  FormatMessageCell,
  LabelCell,
  MultiReferenceCell,
  NumberCell,
  ReferenceCell,
  StringCell,
} from 'types/AnnualReport/types';
import { ReferenceError } from 'utils/References/types';

export const active = (field: Field): Field => ({ ...field, active: true });
export const activeTable = (table: TableBuilder): TableBuilder => ({
  ...table,
  active: true,
});

export const field = (value?: string | number | undefined): Field =>
  typeof value === 'string'
    ? { type: 'string', value }
    : { type: 'number', value };

export const label = (value: string): LabelCell => ({ type: 'label', value });
export const ref = (reference: string): ReferenceCell => ({
  type: 'ref',
  reference,
  value: ReferenceError.NotResolved,
});

export const refs = (...references: string[]): MultiReferenceCell => ({
  type: 'refs',
  references,
  values: references.map(ref => ReferenceError.NotResolved),
});

export const value = (
  value: string | number | undefined
): NumberCell | StringCell =>
  typeof value === 'string'
    ? { type: 'string', value }
    : { type: 'number', value };

export const msg = (
  id: string,
  parameterReferences?: Record<string, string>
): FormatMessageCell => ({
  type: 'msg',
  id,
  parameterReferences,
});

export const row = (
  id: string,
  cells: AnnualReportTableRow['cells'],
  rows?: AnnualReportTableRow[],
  active?: boolean
): AnnualReportTableRow => ({
  id,
  active: active === undefined ? true : active,
  cells,
  rows,
});

const createRow = (
  columns: AnnualReportTableColumn[],
  id: string,
  active: boolean,
  cells: (Cell | undefined)[]
): AnnualReportTableRow => ({
  id,
  active,
  cells:
    cells.length === 0
      ? undefined
      : cells.reduce((obj, cell, index) => {
          if (columns[index] && cell !== undefined) {
            obj[columns[index].id] = cell;
          }
          return obj;
        }, {}),
});

interface TableBuilder {
  /**
   * Active toggle functionality on the whole table
   */
  active?: boolean;
  /**
   * Returns an object (your table) containing your rows in one atribute and your columns in another
   * attribute. The columns are derived from the columns that you passed in to the table
   */
  build: () => AnnualReportTable;
  /**
   * Used for building your rows. Pass a function to this funciton that you want to use to actually
   * build your rows. Your function will reviece the "RowsBuilder" as its first argument, check
   * the documentation of that method for understanding how to build rows.
   */
  addRows: (
    rows: (builder: RowsBuilder) => AnnualReportTableRow[]
  ) => TableBuilder;
  newRowTemplate: (...cells: Cell[]) => TableBuilder;
}

/**
 * Class for building a table
 * @param baseId The ID you wa to assign to the table
 * @param columnIds Column values
 */
export const table = (
  baseId: string,
  ...columnIds: (string | AnnualReportTableColumn)[]
): TableBuilder => {
  let tableRows: AnnualReportTableRow[] = [];
  let newRowTemplate: AnnualReportTableRow | undefined;
  let rowActive: boolean = true;

  const columns: AnnualReportTableColumn[] = columnIds.map(id =>
    typeof id === 'string' ? { id } : id
  );

  const tableBuilder = {
    build: () => {
      const table: AnnualReportTable = {
        active: true,
        columns,
        rows: tableRows,
      };
      if (newRowTemplate) {
        table.newRowTemplate = newRowTemplate;
      }
      return table;
    },
    addRows: buildRows => {
      tableRows = buildRows(rows(columns, baseId));
      return tableBuilder;
    },
    newRowTemplate: (...cells) => {
      newRowTemplate = createRow(columns, '', rowActive, cells);
      return tableBuilder;
    },
  };
  return tableBuilder;
};

export interface RowsBuilder {
  /**
   * Returns the array containing the rows
   */
  build: () => AnnualReportTableRow[];
  /**
   * Adds a row to your table. It will create an object representing a row and insert it into
   * the array that makes up the rows of the table.
   */
  addRow: (id: string, ...cells: (Cell | undefined)[]) => RowsBuilder;
  /**
   * Adds rows inside the latest added row
   */
  addSubRows: (
    subRows: (b: RowsBuilder) => AnnualReportTableRow[]
  ) => RowsBuilder;
  /**
   * setSortKey, set the sort key for the latest row.
   */
  setSortKey: (sortKey: number) => RowsBuilder;
  /**
   * Sets a template for new rows in the latest added row
   */
  newRowTemplate: (...cells: (Cell | undefined)[]) => RowsBuilder;
  /**
   * Sets a generator template for new rows in the last added row
   */
  newRowTemplateGenerator: (
    generator: AnnualReportTableRowGenerator
  ) => RowsBuilder;
  newRowTemplateBuilder: (
    subBuilder: (rows: RowsBuilder) => RowsBuilder
  ) => RowsBuilder;
  /**
   * Sets active for added rows
   */
  addRowActive: (active: boolean) => RowsBuilder;

  getBaseId: () => string;
}

/**
 * Class for building table rows. When ran, returns "RowsBuilder". This object contains three
 * methods, "build", "addRow" and "addSubRow".
 */
export const rows = (
  columns: AnnualReportTableColumn[],
  baseId: string
): RowsBuilder => {
  const result: AnnualReportTableRow[] = [];
  let rowActive: boolean = true;

  const builder: RowsBuilder = {
    build: () => result,
    addRow: (id, ...cells) => {
      result.push(createRow(columns, id, rowActive, cells));
      return builder;
    },
    addSubRows: subRows => {
      const lastRow = result[result.length - 1];
      result[result.length - 1] = {
        ...lastRow,
        rows: lastRow.rows
          ? [
              ...lastRow.rows,
              ...subRows(
                rows(columns, `${baseId}.${result[result.length - 1].id}`)
              ),
            ]
          : subRows(rows(columns, `${baseId}.${result[result.length - 1].id}`)),
      };
      return builder;
    },
    setSortKey: sortKey => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        sortKey,
      };
      return builder;
    },
    newRowTemplate: (...cells) => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        newRowTemplate: createRow(columns, '', rowActive, cells),
        rows: result[result.length - 1].rows || [],
      };
      return builder;
    },
    newRowTemplateGenerator: generator => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        newRowTemplate: generator,
        rows: result[result.length - 1].rows || [],
      };
      return builder;
    },
    newRowTemplateBuilder: subBuilder => {
      result[result.length - 1] = {
        ...result[result.length - 1],
        newRowTemplate: subBuilder(
          rows(columns, `${baseId}.${result[result.length - 1].id}`)
        ).build()[0],
        rows: result[result.length - 1].rows || [],
      };
      return builder;
    },
    getBaseId: () => baseId,
    addRowActive: active => {
      rowActive = active;
      return builder;
    },
  };
  return builder;
};

export const parentId = (id: string) => id.substring(0, id.lastIndexOf('.'));
