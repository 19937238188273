import React from 'react';
import { WithTheme, Typography } from '@material-ui/core';
import styled from '@emotion/styled';

const SubTitle = styled(Typography)<WithTheme>`
  color: ${props => props.theme.palette.text.secondary};
  font-weight: 400;
`;

interface ContainerSubTitleProps {
  children?: React.ReactNode;
}

function ContainerSubTitle(props: ContainerSubTitleProps) {
  const { children } = props;

  return <SubTitle variant="subtitle2">{children}</SubTitle>;
}

export default ContainerSubTitle;
