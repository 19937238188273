import {
  TOGGLE_LAMPS_EXPANDED,
  TOGGLE_MINIMIZED_CARDS,
} from '../../actionsTypes';

export const toggleLampsExpanded = () => ({
  type: TOGGLE_LAMPS_EXPANDED,
});

export const toggleMinimizedCards = () => ({
  type: TOGGLE_MINIMIZED_CARDS,
});
