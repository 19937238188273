import React from 'react';
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Button,
  TableBody,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Row from './TableRow';
import { useDispatch } from 'react-redux';
import {
  addAnnualReportRow,
  addAnnualReportTableColumn,
  deleteAnnualReportColumn,
  updateAnnualReportColumnLabel,
} from 'redux/actions/AnnualReportView/actions';
import { useIntl } from 'react-intl';
import {
  AnnualReportPartKey,
  AnnualReportTable,
} from 'types/AnnualReport/types';
import { NumberFormatType } from '../../../UI/Cell';
import styled from '@emotion/styled';
import EditField from 'components/UI/Inputs/EditField';
import StringInput from '../../../UI/StringInput';

const StyledTableContainer = styled(({ print, ...props }) => (
  <TableContainer {...props} component={Paper} />
))`
  overflow-x: visible;
  .MuiTableCell-head {
    text-align: right;
  }
  ${props =>
    props.print
      ? `
  box-shadow: none;
  
  .MuiTableCell-root {
    border: none;
  }
  `
      : ''}
`;

const EditingColumn = styled.div`
  display: flex;
`;

interface YearTableProps {
  className?: string;
  tableId: string;
  table: AnnualReportTable;
  editing?: boolean;
  print?: boolean;
  numberFormat?: (rowId: string) => NumberFormatType;
}

const YearTable = ({
  className,
  tableId,
  table,
  numberFormat,
  editing = false,
  print = false,
}: YearTableProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const addColumn = () => {
    // limit to 5 the number of year columns
    if (table.columns.length > 5) {
      return;
    }

    const yearToAdd =
      parseInt(table.columns[table.columns.length - 1].label || '') - 1;
    const parts = tableId.split('.') as AnnualReportPartKey[];
    dispatch(
      addAnnualReportTableColumn(
        parts[0],
        parts[1],
        parts[2],
        table.columns.length,
        `${yearToAdd}`,
        `number`
      )
    );
  };

  const addRow = () => dispatch(addAnnualReportRow(`${tableId}`));
  const deleteColumn = columnId => dispatch(deleteAnnualReportColumn(columnId));

  if (!table) return null;

  if (print) {
    if (table.rows.every(row => row.active === false)) {
      return null;
    }
  }

  return (
    <StyledTableContainer className={className} print={print}>
      <Table>
        <TableHead>
          <TableRow>
            {!print && <td />}
            {table.columns.map((col, colIndex) => {
              const label =
                col.label !== undefined
                  ? col.label
                  : formatMessage({
                      id: `yearReport.${tableId}.${col.id}`,
                      defaultMessage: col.id,
                    });
              return (
                <TableCell key={col.id}>
                  {!editing && label}

                  {editing && (
                    <EditingColumn>
                      <EditField
                        value={label}
                        onChange={label => {
                          dispatch(
                            updateAnnualReportColumnLabel(
                              `${tableId}.${col.id}`,
                              label
                            )
                          );
                        }}
                        component={StringInput}
                      />
                      {colIndex > 2 && (
                        <IconButton
                          onClick={() => {
                            deleteColumn(
                              `managementReport.multiYearOverview.table.${col.id}`
                            );
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                    </EditingColumn>
                  )}
                </TableCell>
              );
            })}

            {!print && (
              <TableCell>
                <Button onClick={addColumn}>NY KOLUMN</Button>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {table.rows.map(row => (
            <Row
              key={`${tableId}.${row.id}`}
              baseId={tableId}
              row={row}
              columns={table.columns}
              numberFormat={numberFormat ? numberFormat(row.id) : undefined}
              print={print}
              editing={editing}
            />
          ))}

          {editing && (
            <TableRow>
              <TableCell colSpan={table.columns.length + 1}>
                <Button onClick={addRow}>Lägg till rad</Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default YearTable;
