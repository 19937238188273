import React, { useState } from 'react';
import { Note2Section as Note2SectionType } from 'types/AnnualReport/parts/notes';
import { numberValue, stringValue } from 'utils/AnnualReport/cell';
import Comment from '../../UI/Comment';
import Header from '../../UI/Header';
import FieldSection from '../../UI/FieldSection';
import { Note } from '../../UI/Note';
import { FieldSectionTitle } from '../../UI/FieldSectionTitle';
import ThreeColumnTable from '../../UI/ThreeColumnTable';

type Note2Props = { print?: boolean } & Note2SectionType;

const Note2 = ({
  active,
  number,
  avgNumberOfEmployees,
  avgNumberOfEmployeesTable,
  comment,
  print = false,
}: Note2Props) => {
  const [editing, setEditing] = useState(false);

  if (!active) return null;

  return (
    <Note id="note2">
      <Header
        noteId="note2"
        noteNumber={numberValue(number)}
        editing={editing}
        onEditToggle={editing => {
          setEditing(!editing);
        }}
        print={print}
      />

      <FieldSectionTitle noteId="note2" id="avgNumberOfEmployees" />
      <FieldSection
        noteId="note2"
        id="avgNumberOfEmployees"
        value={stringValue(avgNumberOfEmployees) || ''}
        editing={editing}
        multiline
        print={print}
      />

      <ThreeColumnTable
        table={avgNumberOfEmployeesTable}
        tableId="notes.note2.avgNumberOfEmployeesTable"
        editing={editing}
        print={print}
      />

      {/* Comment */}
      <Comment
        id="notes.note2.comment"
        editing={editing}
        value={stringValue(comment) || ''}
        print={print}
      />
    </Note>
  );
};

export default Note2;
