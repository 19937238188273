import React from 'react';
import styled from '@emotion/styled';

import { WithTheme } from '@material-ui/core';

type ShapeElementProps = {
  type?: string;
};

const ShapeElement = styled.div<WithTheme & ShapeElementProps>`
  background: ${props => props.theme.palette.grey[400]};
  height: 8px;
  border-radius: ${props => props.theme.spacing(1 / 4)}px;
  width: ${props => (props.type === 'legend' ? '1rem' : '100%')};
`;

type VatStatusProps = {
  type?: string;
};

const VatStatus = ({ type }: VatStatusProps) => {
  return <ShapeElement type={type} />;
};

export default VatStatus;
