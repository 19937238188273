import isEqual from 'lodash-es/isEqual';

import { TaxCalculationRow, TaxTable, TaxCalculationContext } from './types';
import { resolveReference } from '../References/resolveReference';
import { updateContext } from './context';

export const calculateRow = <RowType extends TaxCalculationRow>(
  rowBefore: RowType,
  context: TaxCalculationContext,
  hidden: boolean | undefined = undefined
): RowType => {
  const row =
    hidden === undefined || rowBefore?.hidden === hidden
      ? rowBefore
      : { ...rowBefore, hidden };

  if (row.reference) {
    const refValue = resolveReference(row.reference, context);
    const value = typeof refValue === 'number' ? refValue : undefined;
    const error = typeof refValue === 'string' ? refValue : undefined;

    if (value === row.value && error === row.error) {
      return updateContext(context, row);
    } else {
      return updateContext(context, { ...row, value, error });
    }
  }
  return updateContext(context, row);
};

const sumRows = <T extends TaxCalculationRow>(rows: T[]): number => {
  const sum = rows
    .filter(row => !row.deleted)
    .reduce((sum, row) => (row.value ? sum + row.value : sum), 0);
  return sum;
};

export const calculateTaxTable = <RowType extends TaxCalculationRow>(
  taxTable: TaxTable<RowType>,
  context: TaxCalculationContext
): TaxTable<RowType> => {
  const rows = taxTable.rows.map(row => calculateRow(row, context));
  const sum: RowType = {
    ...taxTable.sum,
    value: sumRows(rows),
  };

  const nextTaxTable: TaxTable<RowType> = {
    rows,
    sum,
  };
  if (isEqual(rows, taxTable.rows) && isEqual(sum, taxTable.sum)) {
    return taxTable;
  }
  updateContext(context, sum);
  return nextTaxTable;
};
