import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import EditingIconButton from '../../UI/EditingIconButton';
import Heading from '../../UI/Heading';

interface ParagraphProps {
  id: string;
  no: number;
  editable?: boolean;
  hideSubheading?: boolean;
  children?: (editing: boolean) => React.ReactChild;
}

const Paragraph = ({
  id,
  no,
  editable,
  hideSubheading = false,
  children,
  ...params
}: ParagraphProps & any) => {
  const { formatMessage } = useIntl();
  const [editing, setEditing] = useState(false);

  const title = `§${no} ${formatMessage({
    id: `annualReport.annualGeneralMeeting.main.${id}`,
  })}`;

  return (
    <>
      <Heading
        title={title}
        controls={
          <>
            {editable && (
              <EditingIconButton
                value={editing}
                onClick={() => setEditing(value => !value)}
              />
            )}
          </>
        }
      />
      {!hideSubheading && (
        <Typography>
          {formatMessage(
            {
              id: `annualReport.annualGeneralMeeting.main.${id}.text`,
            },
            params
          )}
        </Typography>
      )}
      {children && children(editing)}
    </>
  );
};

export default Paragraph;
