import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

const MinimizedCaption = styled(Typography)`
  color: #666;
`;

const NotFound = () => {
  const intl = useIntl();
  return (
    <MinimizedCaption variant="h3" align="center" gutterBottom>
      {intl.formatMessage({ id: 'not.found' })}
    </MinimizedCaption>
  );
};

export default NotFound;
