import gql from 'graphql-tag';
export const GET_SIE_DATA_FULL = gql`
  query getSie($customerId: String!, $period: String!) {
    getSie(customerId: $customerId, period: $period) {
      sieBlob
      sieUpdated
      period
      periods
      adjustmentData {
        description
        amount
        account
      }
    }
  }
`;

export const GET_SIE_DATA = gql`
  query getSie($customerId: String!, $period: String!) {
    getSie(customerId: $customerId, period: $period) {
      period
      adjustmentData {
        description
        amount
        account
      }
    }
  }
`;
