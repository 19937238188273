import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'redux/reducers';
import { formatFinancialYear } from 'utils';
import { useIntl } from 'react-intl';
import Activities from './Parts/Activities';
import MultiYearOverview from './Parts/MultiYearOverview/MultiYearOverview';
import ProfitProposal from './Parts/ProfitProposal';
import ResultsDisposition from './Parts/ResultsDisposition';
import ChangesInEquity from './Parts/ChangesInEquity';
import { stringValue } from 'utils/AnnualReport/cell';
import { LABELS } from '../Signatures/People/BoardMemberTypeSelection';
import TextSection from './Parts/TextSection';
import Heading from '../../UI/Heading';
import ResetContentButton from '../../UI/ResetContent/ResetContentButton';
import Section from './Section';

const textSections = [
  'viability',
  'ownershipChanges',
  'controlBalanceSheet',
  'futureDevelopment',
  'rAndD',
  'subsidiariesAbroad',
  'sustainabilityInfo',
  'sustainabilityReport',
  'permitActivities',
];

const ManagementReportContent = ({ print }) => {
  const intl = useIntl();
  const customerId = useSelector(state => state.customerView.currentCustomer!);

  const clientName = useSelector(
    state => customerId && state.customers[customerId]?.name
  );
  const currentFinancialYear = useSelector(
    state => state.customerView.currentFinancialYear
  );

  const financialYear = formatFinancialYear(currentFinancialYear);

  const managementReport = useSelector(
    state => state.annualReport.report?.managementReport
  );
  const ceo = useSelector(state =>
    state.annualReport.report?.signatures.section.people.rows?.find(row =>
      stringValue(row.cells?.role)
        ?.split('|')
        .includes(LABELS.CEO)
    )
  );

  if (!managementReport) {
    return null;
  }

  const title = intl.formatMessage({
    id: 'annualReport.menu.managementReport',
  });
  return (
    <>
      <Typography gutterBottom>
        Styrelsen {ceo ? 'och verkställande direktören' : ''} för{' '}
        <b>{clientName}</b> avger följande årsredovisning för räkenskapsåret{' '}
        {financialYear}.
      </Typography>

      <Typography gutterBottom>
        Årsredovisningen är upprättad i svenska kronor (SEK).
      </Typography>

      <Section>
        <Heading
          title={title}
          variant="h1"
          controls={
            print ? (
              undefined
            ) : (
              <ResetContentButton id="managementReport" what={title} />
            )
          }
        />
      </Section>

      {managementReport.activities.active && (
        <Activities
          id="managementReport.activities"
          print={print}
          reportPart={managementReport.activities}
        />
      )}

      {textSections
        .filter(id => managementReport[id].active !== false)
        .map(id => (
          <TextSection
            key={id}
            fullId={`managementReport.${id}`}
            id={id}
            print={print}
            reportPart={managementReport[id]}
          />
        ))}

      {managementReport.multiYearOverview.active && (
        <MultiYearOverview
          id="managementReport.multiYearOverview"
          print={print}
          reportPart={managementReport.multiYearOverview}
        />
      )}

      {managementReport.changesInEquity.active && (
        <ChangesInEquity
          id="managementReport.changesInEquity"
          print={print}
          reportPart={managementReport.changesInEquity}
        />
      )}

      {managementReport.resultsDisposition.active && (
        <ResultsDisposition
          id="managementReport.resultsDisposition"
          print={print}
          reportPart={managementReport.resultsDisposition}
        />
      )}

      {managementReport.profitProposal.active && (
        <ProfitProposal
          id="managementReport.profitProposal"
          print={print}
          reportPart={managementReport.profitProposal}
        />
      )}
    </>
  );
};

export default ManagementReportContent;
