import React from 'react';
import styled from '@emotion/styled';
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  WithTheme,
} from '@material-ui/core';
import AreYouSure from 'components/Icons/AreYouSure';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

const DangerButton = styled(Button)<WithTheme>`
  background-color: ${props => props.theme.palette.error.main};
  color: ${props => props.theme.palette.error.contrastText};
  margin-top: ${props => props.theme.spacing(4)}px;
`;

const StyledDialog = styled(Dialog)<WithTheme>`
  text-align: center;
  .MuiDialog-paper {
    padding: ${props => props.theme.spacing(4)}px;
    width: 390px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

interface ResetContentDialogProps {
  what: string;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

const ResetContentDialog = ({
  what,
  open,
  onClose,
  onConfirm,
}: ResetContentDialogProps) => {
  const { formatMessage } = useIntl();
  return (
    <StyledDialog open={open} onClose={onClose}>
      <CloseButton onClick={onClose}>
        <CloseIcon fontSize="large" />
      </CloseButton>
      <AreYouSure />
      <Typography variant="h3">
        {formatMessage({ id: 'annualReport.reset.title' })}
      </Typography>
      <Typography color="textSecondary">
        <FormattedHTMLMessage id="annualReport.reset.text" values={{ what }} />
      </Typography>
      <DangerButton variant="contained" onClick={onConfirm}>
        {formatMessage({ id: 'annualReport.reset.button' })}
      </DangerButton>
    </StyledDialog>
  );
};

export default ResetContentDialog;
