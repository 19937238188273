import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Cell as CellType } from 'types/AnnualReport/cell';
import { ccyFormat, ccyParse, formatPercentage, parsePercentage } from 'utils';
import { ResolvedReference } from 'utils/References/types';
import NoteSelector from './NoteSelector';
import NumberField from './NumberField';
import StringField from './StringField';

export type NumberFormatType = 'standard' | 'percentage';

interface CellProps {
  className?: string;
  id: string;
  cell: CellType;
  print?: boolean;
  editing?: boolean;
  numberFormatType?: NumberFormatType;
}

const NumberCell = styled.span`
  display: inline-block;
  font-family: Roboto;
  text-align: right;
  width: 100%;
`;

interface MessageCellProps {
  className?: string;
  id: string;
  parameters: Record<string, ResolvedReference> | undefined;
}

const MessageCell = ({ id, parameters, className }: MessageCellProps) => {
  const { formatMessage } = useIntl();

  const value = formatMessage({ id }, parameters);
  return <span className={className}>{value}</span>;
};

const NonReferenceCell = memo(
  ({ className, id, cell, editing, numberFormatType }: CellProps) => {
    const numberFormat =
      numberFormatType === 'percentage' ? formatPercentage : ccyFormat;
    const numberParser =
      numberFormatType === 'percentage' ? parsePercentage : ccyParse;
    const decimals = numberFormatType === 'percentage' ? 2 : 0;

    if (cell.type === 'label') {
      return <span className={className}>{cell.value}</span>;
    }
    if (cell.type === 'number') {
      if (editing) {
        return (
          <NumberField
            className={className}
            id={id}
            value={cell.value}
            formatter={numberFormat}
            parser={numberParser}
            displayDecimals={decimals}
          />
        );
      }
      return (
        <NumberCell className={className}>
          {cell.value === undefined ? '' : numberFormat(cell.value, decimals)}
        </NumberCell>
      );
    }

    if (cell.type === 'string') {
      if (editing) {
        return <StringField className={className} id={id} value={cell.value} />;
      }
      return <span className={className}>{cell.value}</span>;
    }
    return null;
  }
);

interface ReferenceCellProps {
  className?: string;
  id: string;
  editing?: boolean;
  numberFormatType?: NumberFormatType;
  value: ResolvedReference;
}

const ReferenceCell = memo(
  ({ value, className, id, editing, numberFormatType }: ReferenceCellProps) => {
    const { formatMessage } = useIntl();

    const numberFormat =
      numberFormatType === 'percentage' ? formatPercentage : ccyFormat;
    const numberParser =
      numberFormatType === 'percentage' ? parsePercentage : ccyParse;
    const decimals = numberFormatType === 'percentage' ? 2 : 0;

    if (editing) {
      return (
        <NumberField
          className={className}
          id={id}
          value={typeof value === 'string' ? undefined : value}
          formatter={numberFormat}
          parser={numberParser}
          displayDecimals={decimals}
        />
      );
    }
    if (value === undefined) {
      return null;
    }
    if (typeof value === 'string') {
      return (
        <Typography
          color="error"
          className={className}
          style={{ textAlign: 'right' }}
        >
          {formatMessage({
            id: `ref.error.${value}`,
          })}
        </Typography>
      );
    }
    return (
      <NumberCell className={className}>
        {numberFormat(value, decimals)}
      </NumberCell>
    );
  }
);

const Cell = ({
  className,
  id,
  cell,
  print,
  editing,
  numberFormatType = 'standard',
}: CellProps) => {
  if (!cell) {
    return null;
  }

  if (cell.type === 'refs') {
    return (
      <NoteSelector
        className={className}
        cell={cell}
        editing={editing}
        cellId={id}
      />
    );
  }

  if (cell.type === 'ref') {
    const value = cell.value;
    return (
      <ReferenceCell
        value={value}
        className={className}
        id={id}
        editing={editing}
        numberFormatType={numberFormatType}
      />
    );
  } else if (cell.type === 'msg') {
    return <MessageCell id={cell.id} parameters={cell.parameterValues} />;
  } else {
    return (
      <NonReferenceCell
        className={className}
        id={id}
        cell={cell}
        print={print}
        editing={editing}
        numberFormatType={numberFormatType}
      />
    );
  }
};

export default Cell;
