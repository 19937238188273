import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  Button,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

interface RetryDialogProps {
  onRetry: () => void;
  onCancel?: () => void;
  title: string;
  text: string;
}

const RetryDialog = ({ onRetry, onCancel, title, text }: RetryDialogProps) => {
  const [open, setOpen] = useState(true);
  const close = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const retry = () => {
    setOpen(false);
    onRetry();
  };

  const { formatMessage } = useIntl();

  return (
    <Dialog open={open} onAbort={close}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          {formatMessage({ id: 'retrydialog.cancel.label' })}
        </Button>
        <Button color="primary" variant="contained" onClick={retry}>
          {formatMessage({ id: 'retrydialog.retry.label' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RetryDialog;
