import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Select, FormControl, MenuItem, Tooltip } from '@material-ui/core';

import { useSelector } from 'redux/reducers';
import { setCurrentYear } from 'redux/actions';
import { formatFinancialYear } from 'utils';

interface YearSwitcherProps {
  customerId: string;
}

const YearSwitcher = ({ customerId }: YearSwitcherProps) => {
  const financialYears = useSelector(
    state => state.customers[customerId]?.financialYears || []
  );

  const currentYear =
    useSelector(state => state.accountingView.sieData?.period) || 'FAKE_YEAR';

  const currentFinancialPeriod = financialYears.find(period =>
    period.startsWith(currentYear)
  );
  const dispatch = useDispatch();
  const handleChange = useCallback(
    event => {
      dispatch(setCurrentYear(event.target.value));
    },
    [dispatch]
  );

  const options = useMemo(() => {
    return (
      financialYears &&
      financialYears.map((financialYear, index) => (
        <MenuItem
          data-cy={`year-option-${index + 1}`}
          key={financialYear}
          value={financialYear}
        >
          {formatFinancialYear(financialYear)}
        </MenuItem>
      ))
    );
  }, [financialYears]);

  return (
    <>
      <FormControl>
        <Tooltip title="Välj räkenskapsår" placement="left">
          <Select
            inputProps={{
              'data-cy': 'year-switcher',
            }}
            disableUnderline
            value={currentFinancialPeriod || ''}
            onChange={handleChange}
          >
            {options}
          </Select>
        </Tooltip>
      </FormControl>
    </>
  );
};

export default YearSwitcher;
