import {
  SET_CURRENT_YEAR,
  SET_CURRENT_CUSTOMER,
  TOGGLE_NEW_SIE_FILE_UPDATED,
  SET_CURRENT_PERIOD,
  RESET_CUSTOMER,
  INIT_STATE,
  SET_SIE_DATA,
} from '../../actionsTypes';
import CustomerViewState from './types';

const defaultState = { sieFileJustUpdated: false, currentPeriod: null };

export default (
  state: CustomerViewState = defaultState,
  action
): CustomerViewState => {
  switch (action.type) {
    case RESET_CUSTOMER:
      return defaultState;

    case INIT_STATE:
      return action.state.customerView || state;

    case SET_CURRENT_CUSTOMER:
      return {
        ...state,
        currentCustomer: action.currentCustomer,
      };
    case SET_CURRENT_YEAR:
      return {
        ...state,
        currentYear: action.year,
        currentFinancialYear: action.financialYear,
      };
    case SET_SIE_DATA:
      if (state.currentYear === action.sieData.period) return state;

      return {
        ...state,
        currentYear: action.sieData.period,
      };
    default:
      return state;
    case TOGGLE_NEW_SIE_FILE_UPDATED:
      return {
        ...state,
        sieFileJustUpdated: !state.sieFileJustUpdated,
      };
    case SET_CURRENT_PERIOD:
      return {
        ...state,
        currentPeriod: action.period,
      };
  }
};
