import React, { useState } from 'react';
import { LoginBox, TextField, ActionsWrapper } from './SharedComponents';
import { CTAButton } from 'components/UI/Buttons';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { resendConfirmation } from 'utils/AwsAsync';
import FormError from 'components/UI/Inputs/FormError';

interface ResendCodeContentProps {
  backToConfirm: () => void;
}

const ResendCodeContent = ({ backToConfirm }: ResendCodeContentProps) => {
  const intl = useIntl();
  const { register, errors, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const submitConfirmation = async data => {
    try {
      setLoading(true);
      await resendConfirmation(data.username);
      backToConfirm();
    } catch (error) {
      setSubmitError(intl.formatMessage({ id: 'confirm.error' }));
      setLoading(false);
    }
  };

  return (
    <LoginBox onSubmit={handleSubmit(submitConfirmation)}>
      <Typography variant="h5">
        {intl.formatMessage({ id: 'confirm.title' })}
      </Typography>
      <Typography>
        {intl.formatMessage({ id: 'confirm.resendCodeText' })}
      </Typography>

      <TextField
        variant="outlined"
        size="small"
        inputRef={register({
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
        })}
        name="username"
        placeholder={intl.formatMessage({ id: 'email.placeholder' })}
        type="email"
      />

      <FormError>{errors.username?.message}</FormError>

      {submitError && <FormError>{submitError}</FormError>}

      <ActionsWrapper>
        <Button onClick={backToConfirm}>
          {intl.formatMessage({ id: 'forgot.back' })}
        </Button>
        <CTAButton type="submit" loading={loading}>
          {intl.formatMessage({ id: 'confirm.verify' })}
        </CTAButton>
      </ActionsWrapper>
    </LoginBox>
  );
};

export default ResendCodeContent;
