import React from 'react';
import { WithTheme, Checkbox } from '@material-ui/core';
import { AnnualReportTable } from 'types/AnnualReport/types';
import styled from '@emotion/styled';
import Cell from '../../../UI/Cell';
import { useDispatch } from 'react-redux';
import { toggleTableRowActive } from 'redux/actions/AnnualReportView/actions';
import { flattenRows } from '../utils';

interface CheckboxTableProps {
  table: AnnualReportTable;
  tableId: string;
  editing?: boolean;
  print?: boolean;
}

const GridTable = styled.div<WithTheme>`
  display: grid;
  grid-template-columns: 60px auto;
  grid-gap: ${props => props.theme.spacing(1) / 2}px;
`;

const GridTableHead = styled.div`
  display: none;
`;

const GridTableHeadCell = styled.div<WithTheme>`
  color: ${props => props.theme.palette.grey[700]};
`;

const GridTableRow = styled.div<WithTheme>`
  display: contents;
  > * {
    padding: ${props => props.theme.spacing(1) / 2}px;
  }

  &:nth-of-type(even) {
    > * {
      background-color: ${props => props.theme.palette.grey[100]};
    }
  }

  /* Checkbox column */
  > *:nth-first-of-type(1) {
    justify-content: center;
  }

  /* Label column */
  > *:nth-first-of-type(2) {
    justify-content: flex-start;
  }
`;

const RowCell = styled.div`
  display: flex;
  min-height: 45px;
  align-items: center;
  font-size: inherit;
  line-height: 1.5rem;
`;

const ActiveSelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrintRow = styled.div<WithTheme>`
  background: ${props => props.theme.palette.grey[100]};
  padding: ${props => props.theme.spacing(1)}px;
  margin: ${props => props.theme.spacing(2, 0)};
`;

const CheckboxTable = ({
  table,
  tableId,
  editing = false,
  print = false,
}: CheckboxTableProps) => {
  const dispatch = useDispatch();
  const renderRows = flattenRows(table.rows).filter(row =>
    print ? row.active : row
  );

  const toggleRow = row => {
    dispatch(toggleTableRowActive(`${tableId}.${row.id}`));
  };

  if (print) {
    return (
      <>
        {renderRows.map(row => {
          return (
            <PrintRow key={row.id}>
              {row.cells && (
                <React.Fragment>
                  {table.columns.map(col => {
                    const cell = row.cells?.[col.id];
                    const key = `${row.id}.${col.id}`;
                    if (!cell) {
                      return <div key={key} />;
                    }
                    return (
                      <RowCell key={key}>
                        <Cell
                          cell={cell}
                          id={`${tableId}.${key}`}
                          editing={editing && row.id !== 'sum'}
                          print={print}
                        />
                      </RowCell>
                    );
                  })}
                </React.Fragment>
              )}
            </PrintRow>
          );
        })}
      </>
    );
  }

  return (
    <>
      <GridTable>
        <GridTableHead>
          {table.columns.map(col => (
            <GridTableHeadCell key={col.id} />
          ))}
        </GridTableHead>

        {renderRows.map(row => {
          return (
            <GridTableRow key={row.id}>
              <ActiveSelector>
                <Checkbox
                  checked={row.active}
                  disabled={!editing}
                  onChange={() => {
                    toggleRow(row);
                  }}
                />
              </ActiveSelector>
              {row.cells && (
                <React.Fragment>
                  {table.columns.map(col => {
                    const cell = row.cells?.[col.id];
                    const key = `${row.id}.${col.id}`;
                    if (!cell) {
                      return <div key={key} />;
                    }
                    return (
                      <RowCell key={key}>
                        <Cell
                          cell={cell}
                          id={`${tableId}.${key}`}
                          editing={editing && row.id !== 'sum'}
                          print={print}
                        />
                      </RowCell>
                    );
                  })}
                </React.Fragment>
              )}
            </GridTableRow>
          );
        })}
      </GridTable>
    </>
  );
};

export default CheckboxTable;
