import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button, Typography, Box, WithTheme } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import styled from '@emotion/styled';
import { useSelector } from 'redux/reducers';
import { GET_ALL_CUSTOMERS } from 'Graphql/Queries';
import { LoadingPlaceholder } from 'components/UI';
import RetryDialog from 'components/UI/RetryDialog';
import { CreateCustomer } from 'components/Modals/Customer';
import { parseCustomers } from 'utils/GraphqlParser';
import useErrorLogger from 'utils/useErrorLogger';
import CustomerCard from './CustomerCard';
import WelcomeBackground from 'assets/background-welcome-screen.jpg';
import { populateCustomers } from 'redux/actions';

const Wrapper = styled.div<WithTheme>`
  padding: 0 ${props => props.theme.spacing(3)}px;
  height: 100%;
  overflow-y: auto;
`;

const CustomerListWrapper = styled(Box)`
  display: block;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 1rem;
  text-align: center;
`;

const StyledImage = styled.img`
  margin: 6rem 0 2rem 0;
  width: 40%;
`;

const StyledButton = styled(Button)`
  margin: 2rem 0;
`;

// Since refetch's error handling is flawed, retrying is done by unmounting/mounting the view
const CustomersViewWrapper = () => {
  const { formatMessage } = useIntl();
  const errorLogger = useErrorLogger();
  const [error, setError] = useState<any>(null);
  const retry = () => setError(null);
  const onError = error => {
    errorLogger('Error in CustomersView', error);
    setError(error);
  };

  return error ? (
    <RetryDialog
      onRetry={retry}
      text={formatMessage({ id: 'customersview.fetch.error.text' })}
      title={formatMessage({ id: 'customersview.fetch.error.title' })}
    />
  ) : (
    <CustomersView onError={onError} />
  );
};

const CustomersView = ({ onError }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const organisationId = useSelector(
    state => state.user['custom:organisationId']
  );
  const tourFirstLogin = useSelector(state => state.user.firstLogin);

  const [isLoadingCustomers, setIsLoadingCustomers] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<Customer.CustomerType[]>([]);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState<boolean>(
    false
  );

  const customers = useSelector(state => state.customers);

  const [getAllCustomers, { loading: isGraphqlLoading, error }] = useLazyQuery(
    GET_ALL_CUSTOMERS,
    {
      onCompleted: async data => {
        if (!data.allCustomers.customers) return;

        dispatch(
          populateCustomers({
            customers: await parseCustomers(data.allCustomers.customers),
          })
        );
        setIsLoadingCustomers(false);
      },
    }
  );

  useEffect(() => {
    if (organisationId) {
      getAllCustomers();
    }
  }, [organisationId, getAllCustomers]);

  useEffect(() => {
    if (isGraphqlLoading) {
      setIsLoadingCustomers(true);
    }
  }, [isGraphqlLoading]);

  useEffect(() => {
    setCustomerList(
      Object.values(customers)
        .filter(customer => customer.status === 'active')
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [customers]);

  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error, onError]);

  const openCreateCustomerModal = () => setIsCustomerModalOpen(true);
  const closeCreateCustomerModal = () => setIsCustomerModalOpen(false);

  return (
    <Wrapper>
      {isLoadingCustomers ? (
        <LoadingPlaceholder />
      ) : customerList.length === 0 ? (
        <CustomerListWrapper>
          <StyledImage src={WelcomeBackground} alt="Background-image" />
          <Typography component="div">
            <Box fontWeight="fontWeightBold">
              {intl.formatMessage({ id: 'welcome.page' })}
            </Box>
          </Typography>
          <Box>
            <Typography>
              {intl.formatMessage({ id: 'welcome.trial' })}
            </Typography>
          </Box>
          <StyledButton
            startIcon={<AddCircleOutlineIcon />}
            onClick={openCreateCustomerModal}
            color="secondary"
            variant="contained"
            id="onboarding-add-customer"
          >
            {intl.formatMessage({ id: 'add.first.customer' })}
          </StyledButton>
          {isCustomerModalOpen && (
            <CreateCustomer
              open={isCustomerModalOpen}
              close={closeCreateCustomerModal}
            />
          )}
          {/* Inject a div in order to display the onboarding tour#1 when div is found by Usetiful */}
          {tourFirstLogin && <div id="EMPTY_CUSTOMER_TOUR_ID" />}
        </CustomerListWrapper>
      ) : (
        <div id="customer-card-container" data-cy="customer-card-container">
          {customerList &&
            customerList.map(customer => (
              <CustomerCard key={customer.id} customer={customer} />
            ))}
          {/* Inject a div in order to display the onboarding tour#2 when div is found by Usetiful */}
          {tourFirstLogin && <div id="EXISTING_CUSTOMERS_TOUR_ID" />}
        </div>
      )}
    </Wrapper>
  );
};

export default CustomersViewWrapper;
