export const modalIcon = '#48a6ff';

export const orange = '#F8AF61';
export const white = '#FFFFFF';

export const primaryMain = '#00313C';
export const secondaryMain = '#00CE7C';

export const info = '#335E62';
export const error = '#EA5160';
export const warning = '#F8AF61';
export const success = '#00CE7C';

export const textPrimary = '#00313C';
export const textSecondary = '#000000a6';

// Specific variations of colors used in account overview cells
export const cellIncomingBalance = '#E1ECF1';
export const cellTotalChange = '#EDF3F6';
export const cellOutgoingBalance = '#F4F8F9';
export const cellOk = '#DCF9E7';
export const cellWarning = '#F9CACF';

// Specific variations of colors used in overview
export const accountStatusWrong = '#f4a4ac';
