import {
  SET_TAX_VIEW_CONFIG,
  SET_TAX_VIEW_STATE,
  DELETE_TAX_TABLE_ROW,
  UPDATE_TAX_TABLE_ROW_LABEL,
  UPDATE_TAX_TABLE_ROW_VALUE,
  UPDATE_TAX_TABLE_ROW_REFERENCE,
  ADD_EMPTY_TAX_TABLE_ROW,
  UPDATE_TRANSACTION_ROW_ACCOUNT,
  REMOVE_USER_TRANSACTIONS,
  RESET_YEAR_END_PLANNING,
  RESET_TAX_CALCULATION_TABLE,
  RESET_TAX_CALCULATION_ADJUSTMENTS,
  UPDATE_YEAR_END_PLANNING_CHECKSUM,
} from '../../actionsTypes';
import {
  TaxCalculationConfig,
  TaxView,
  TaxCalculationInput,
  TaxCalculationPart,
} from 'utils/TaxCalculation/types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from 'redux/types';
import { Action } from 'redux';
import debounce from 'lodash-es/debounce';
import { storeTaxConfig } from 'components/Api/Client/tax';
import { taxCalculation } from 'utils/TaxCalculation/taxCalculation';
import {
  AddEmptyTaxTableRow,
  DeleteTaxTableRow,
  RemoveUserTransaction,
  SetTaxViewConfig,
  SetTaxViewState,
  UpdateTaxTableRowLabel,
  UpdateTaxTableRowReference,
  UpdateTaxTableRowValue,
  UpdateTransactionRowAccount,
} from './types';

export const updateTaxView = (
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>,
  getState: () => RootState,
  changedByUser: boolean = true
) => {
  const { sieData } = getState().accountingView;
  if (!sieData) {
    dispatch(setTaxViewState(null));
  } else {
    const { config, state } = getState().taxView;
    const { currentPeriod } = getState().customerView;
    const { periods, accounts, sieUpdated } = sieData;

    if (config && currentPeriod && periods && accounts && sieUpdated) {
      const input: TaxCalculationInput = {
        period:
          periods[periods.length - 1] === currentPeriod ? null : currentPeriod,
        periods,
        accounts,
        sieUpdated,
      };
      const taxViewState = taxCalculation(config, input, state);
      if (
        changedByUser &&
        taxViewState.yearEndPlanning !== null &&
        taxViewState.yearEndPlanning.checksum !==
          config.yearEndPlanning.checksum
      ) {
        dispatch(
          updateYearEndPlanningChecksum(taxViewState.yearEndPlanning.checksum)
        );
      } else {
        dispatch(setTaxViewState(taxViewState));
      }
    } else {
      dispatch(setTaxViewState(null));
    }
  }
};

export const setTaxViewConfig = (
  config: TaxCalculationConfig
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(setTaxViewConfigAction(config));

  updateTaxView(dispatch, getState, false);
};

const setTaxViewConfigAction = (
  config: TaxCalculationConfig
): SetTaxViewConfig => ({
  type: SET_TAX_VIEW_CONFIG,
  config,
});

export const setTaxViewState = (state: TaxView | null): SetTaxViewState => ({
  type: SET_TAX_VIEW_STATE,
  state,
});

const storeTaxConfigFromState = debounce((getState: () => RootState) => {
  const { currentCustomer, currentYear } = getState().customerView;
  const { config } = getState().taxView;
  if (currentCustomer && currentYear && config) {
    storeTaxConfig(currentCustomer, `${currentYear}`, config);
  }
}, 2000);

export const addEmptyTaxTableRow = (
  part: TaxCalculationPart,
  rowId: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(addEmptyTaxTableRowAction(part, rowId));

  updateTaxView(dispatch, getState);

  storeTaxConfigFromState(getState);
};

const addEmptyTaxTableRowAction = (
  part: TaxCalculationPart,
  rowId: string
): AddEmptyTaxTableRow => ({
  type: ADD_EMPTY_TAX_TABLE_ROW,
  part,
  rowId,
  lastModified: new Date().toISOString(),
});

export const deleteTaxTableRow = (
  part: TaxCalculationPart,
  rowId: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(deleteTaxTableRowAction(part, rowId));

  updateTaxView(dispatch, getState);

  storeTaxConfigFromState(getState);
};

const deleteTaxTableRowAction = (
  part: TaxCalculationPart,
  rowId: string
): DeleteTaxTableRow => ({
  type: DELETE_TAX_TABLE_ROW,
  part,
  rowId,
  lastModified: new Date().toISOString(),
});

export const updateTaxTableRowLabel = (
  part: TaxCalculationPart,
  rowId: string,
  label: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(updateTaxTableRowLabelAction(part, rowId, label));

  updateTaxView(dispatch, getState);

  storeTaxConfigFromState(getState);
};

const updateTaxTableRowLabelAction = (
  part: TaxCalculationPart,
  rowId: string,
  label: string
): UpdateTaxTableRowLabel => ({
  type: UPDATE_TAX_TABLE_ROW_LABEL,
  part,
  rowId,
  label,
  lastModified: new Date().toISOString(),
});

export const updateTaxTableRowValue = (
  part: TaxCalculationPart,
  rowId: string,
  value: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(updateTaxTableRowValueAction(part, rowId, value));

  updateTaxView(dispatch, getState);

  storeTaxConfigFromState(getState);
};

const updateTaxTableRowValueAction = (
  part: TaxCalculationPart,
  rowId: string,
  value: number
): UpdateTaxTableRowValue => ({
  type: UPDATE_TAX_TABLE_ROW_VALUE,
  part,
  rowId,
  value,
  lastModified: new Date().toISOString(),
});

export const updateTaxTableRowReference = (
  part: TaxCalculationPart,
  rowId: string,
  label: string,
  reference: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(updateTaxTableRowReferenceAction(part, rowId, label, reference));

  updateTaxView(dispatch, getState);

  storeTaxConfigFromState(getState);
};

export const updateTransactionRowAccount = (
  rowId: string,
  account: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(updateTransactionRowAccountAction(rowId, account));

  updateTaxView(dispatch, getState);

  storeTaxConfigFromState(getState);
};

const updateTaxTableRowReferenceAction = (
  part: TaxCalculationPart,
  rowId: string,
  label: string,
  reference: string
): UpdateTaxTableRowReference => ({
  type: UPDATE_TAX_TABLE_ROW_REFERENCE,
  part,
  rowId,
  label,
  reference,
});

const updateTransactionRowAccountAction = (
  rowId: string,
  account: string
): UpdateTransactionRowAccount => ({
  type: UPDATE_TRANSACTION_ROW_ACCOUNT,
  rowId,
  account,
  lastModified: new Date().toISOString(),
});

export const removeUserTransactions = (): RemoveUserTransaction => ({
  type: REMOVE_USER_TRANSACTIONS,
  lastModified: new Date().toISOString(),
});

export const resetYearEndPlanning = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => (dispatch, getState) => {
  dispatch({
    type: RESET_YEAR_END_PLANNING,
  });

  updateTaxView(dispatch, getState);

  storeTaxConfigFromState(getState);
};

export const resetTaxCalculationTable = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => (dispatch, getState) => {
  dispatch({
    type: RESET_TAX_CALCULATION_TABLE,
  });

  updateTaxView(dispatch, getState);

  storeTaxConfigFromState(getState);
};

export const resetAdjustments = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => (dispatch, getState) => {
  dispatch({
    type: RESET_TAX_CALCULATION_ADJUSTMENTS,
  });

  updateTaxView(dispatch, getState);

  storeTaxConfigFromState(getState);
};

export const updateYearEndPlanningChecksum = (
  checksum?: string
): ThunkAction<void, RootState, unknown, Action<string>> => (
  dispatch,
  getState
) => {
  dispatch({
    type: UPDATE_YEAR_END_PLANNING_CHECKSUM,
    checksum: checksum || getState().taxView.state?.yearEndPlanning?.checksum,
  });

  updateTaxView(dispatch, getState, false);

  storeTaxConfigFromState(getState);
};
