import {
  AnnualReportChanges,
  AnnualReportPart,
  AnnualReportPartChanges,
} from 'types/AnnualReport/types';

const removeSection = <T extends AnnualReportPart>(
  changes: AnnualReportPartChanges<T>,
  ids: string[]
): AnnualReportPartChanges<T> => {
  if (ids.length === 1) {
    const newChanges = { ...changes };
    delete newChanges[ids[0]];
    return newChanges;
  } else {
    // Deeper removal not implemented
    console.error('Trying to delete content to deep in changes');
    return changes;
  }
};

const removePart = (
  changes: AnnualReportChanges,
  ids: string[]
): AnnualReportChanges => {
  const [part] = ids;
  if (ids.length === 1) {
    const newChanges = { ...changes };
    delete newChanges[part];
    return newChanges;
  }
  return {
    ...changes,
    [part]: removeSection(changes[part], ids.slice(1)),
  };
};

const removeChangesById = (
  changes: AnnualReportChanges,
  id: string
): AnnualReportChanges => {
  const ids = id.split('.');
  return removePart(changes, ids);
};

export default removeChangesById;
