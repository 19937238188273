import { CashFlowStatement } from 'types/AnnualReport/parts/cashFlowStatement';
import { label, ref, value, table, RowsBuilder, msg, refs } from './util';
import {
  or,
  id,
  sum,
  account,
  multiply,
  min,
  max,
} from 'utils/References/helpers';
import range from 'lodash-es/range';
import { AccountInformation } from 'utils/SieParser';
import { Cell } from 'types/AnnualReport/cell';
import formatFinancialYear from 'utils/formatFinancialYear';

const accountRangeRows = (
  accounts: Record<string, AccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder,
  accountReference?: (accountNumber: string) => string,
  addAdjustment: boolean = true
): RowsBuilder => {
  range(first, last + 1)
    .map(n => n.toString())
    .forEach(n => {
      const accountInformation = accounts[n];
      if (accountInformation) {
        const reference = accountReference ? accountReference(n) : account(n);
        rows.addRow(
          n,
          label(`${n} ${accountInformation.accountName}`),
          undefined,
          ref(
            addAdjustment
              ? sum(
                  multiply(-1, reference),
                  or(id(`${rows.getBaseId()}.${n}.adjustment`), 0)
                )
              : multiply(-1, reference)
          ),
          addAdjustment ? value(0) : undefined
        );
      }
    });
  return rows;
};

const otherAccountsRange = (
  accounts: Record<string, AccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder
): RowsBuilder => {
  range(first, last + 1)
    .map(n => n.toString())
    .forEach(n => {
      const accountInformation = accounts[n];
      if (accountInformation) {
        rows.addRow(
          n,
          label(`${n} ${accountInformation.accountName}`),
          ref(account(n, 'change'))
        );
      }
    });
  return rows;
};

const addRowWithAdjustment = (
  rows: RowsBuilder,
  rowId: string,
  label: Cell,
  reference: string
): RowsBuilder => {
  return rows.addRow(
    rowId,
    label,
    refs(),
    ref(sum(reference, or(id(`${rows.getBaseId()}.${rowId}.adjustment`), 0))),
    value(0)
  );
};

const positiveChange = (accounts: string) =>
  or(multiply(-1, max(account(accounts, 'change'), 0)), 0);
const negativeChange = (accounts: string) =>
  or(multiply(-1, min(account(accounts, 'change'), 0)), 0);

export const cashFlowStatementConfig = (
  accounts: Record<string, AccountInformation>,
  period: string | null,
  financialYear: string
): CashFlowStatement => ({
  type: 'part',
  section: {
    active: false,
    main: table(
      'cashFlowStatement.section.main',
      'label',
      'notes',
      {
        id: 'value',
        label: formatFinancialYear(financialYear)?.replace(/-/g, '\u2011'), // Non-breaking hyphen
      },
      'adjustment'
    )
      .addRows(rows =>
        rows
          .addRow(
            'topDifference',
            label('Differens'),
            undefined,
            ref(id('cashFlowDifference'))
          )
          .addRow(
            'currentOperations',
            msg('cashFlowStatement.currentOperations')
          )
          .addSubRows(rows =>
            rows
              .addRow(
                'beforeChangeWorkingCapital',
                label('Kassaflöde före förändring av rörelsekapital'),
                refs()
              )
              .addSubRows(rows =>
                rows
                  .addRow(
                    'operatingProfit',
                    label('Rörelseresultat'),
                    refs(),
                    ref(id('operatingProfit'))
                  )
                  .addRow(
                    'adjustmentNotInCashFlow',
                    label(
                      'Justeringar för poster som inte ingår i kassaflödet:'
                    ),
                    undefined
                  )
                  .addRow(
                    'activatedWork',
                    label(
                      'Aktiverat arbete för egen räkning (material, omkostnader och personal)'
                    ),
                    refs(),
                    ref(id('sumActivatedWork'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 3800, 3899, rows, n =>
                      account(n, 'change')
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )
                  .addRow(
                    'dividendsAndProfitShares',
                    label('Utdelning och resultatandelar'),
                    refs(),
                    ref(id('sumDividendsAndProfitShares'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 8000, 8299, rows).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )

                  .addRow(
                    'capitalLoss',
                    label('Realisationsförlust'),
                    refs(),
                    ref(id('sumCapitalLoss'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 7970, 7979, rows, n =>
                      multiply(-1, account(n))
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )

                  .addRow(
                    'capitalGain',
                    label('Realisationsvinst'),
                    refs(),
                    ref(id('sumCapitalGain'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 3970, 3979, rows, n =>
                      multiply(-1, account(n))
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )

                  .addRow(
                    'writeDownChange',
                    label('Nedskrivningar (förändring)'),
                    refs(),
                    ref(id('sumWriteDownChange'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 7700, 7799, rows, n =>
                      account(n, 'change')
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )
                  .addRow(
                    'depreciations',
                    label('Avskrivningar'),
                    refs(),
                    ref(id('sumDepreciations'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 7800, 7899, rows, n =>
                      multiply(-1, account(n))
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )
                  .addRow(
                    'receivedInterest',
                    label('Erhållen ränta m.m.'),
                    refs(),
                    ref(id('sumReceivedInterest'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 8300, 8399, rows).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )

                  .addRow(
                    'paidInterest',
                    label('Erlagd ränta'),
                    refs(),
                    ref(id('sumPaidInterest'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 8400, 8499, rows).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )

                  .addRow(
                    'paidIncomeTax',
                    label('Betald inkomstskatt'),
                    refs(),
                    ref(id('sumPaidIncomeTax'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 2500, 2599, rows, n =>
                      account(n, 'change')
                    )
                      .addRow(
                        'yearTax',
                        label('Årets skatt'),
                        undefined,
                        ref(id('yearTax'))
                      )
                      .build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )
                  .build()
              )
              .addRow(
                'sum',
                label(
                  'Summa Kassaflöde från den löpande verksamheten före förändringar av rörelsekapital'
                ),
                refs(),
                ref(id('sumBeforeChangeWorkingCapital'))
              )
              .addRow(
                'changeWorkingCapital',
                label('Kassaflöde från förändringar av rörelsekapital'),
                refs()
              )
              .addSubRows(rows =>
                rows
                  .addRow(
                    'changeStockAndWorkInProgress',
                    label(
                      'Minskning(+)/ökning(-) av varulager/pågående arbete'
                    ),
                    refs(),
                    ref(id('sumChangeStockAndWorkInProgress'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 1400, 1499, rows, n =>
                      account(n, 'change')
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )

                  .addRow(
                    'changeAccountReceivable',
                    label('Minskning(+)/ökning(-) av kundfordringar'),
                    refs(),
                    ref(id('sumChangeAccountReceivable'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 1500, 1599, rows, n =>
                      account(n, 'change')
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )

                  .addRow(
                    'changeReceivables',
                    label('Minskning(+)/ökning(-) av kortfristiga fordringar'),
                    refs(),
                    ref(id('sumChangeReceivables'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 1600, 1869, rows, n =>
                      account(n, 'change')
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )

                  .addRow(
                    'changeTradeCreditors',
                    label('Minskning(-)/ökning(+) av leverantörsskulder'),
                    refs(),
                    ref(id('sumChangeTradeCreditors'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 2400, 2499, rows, n =>
                      account(n, 'change')
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )
                  .addRow(
                    'changeCurrentLiabilities',
                    label('Minskning(-)/ökning(+) av kortfristiga skulder'),
                    refs(),
                    ref(id('sumChangeCurrentLiabilities'))
                  )
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 2600, 2999, rows, n =>
                      account(n, 'change')
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )

                  .build()
              )
              .addRow(
                'sum1',
                label(
                  'Summa Kassaflöde från den löpande verksamheten före förändringar av rörelsekapital'
                ),
                refs(),
                ref(id('sumBeforeChangeWorkingCapital'))
              )
              .addRow(
                'sum2',
                label('Summa kassaflöde från förändringar av rörelsekapital'),
                refs(),
                ref(id('sumChangeWorkingCapital'))
              )
              .build()
          )

          .addRow(
            'sumCurrentOperations',
            label('Summa kassaflöde från den löpande verksamheten'),
            undefined,
            ref(id('sumCurrentOperations'))
          )
          .addRow('empty1', label(''))
          .addRow(
            'investmentActivities',
            msg('cashFlowStatement.investmentActivities')
          )
          .addSubRows(rows =>
            rows
              .addRow('content')
              .addSubRows(rows => {
                addRowWithAdjustment(
                  rows,
                  '1',
                  label(
                    'Förvärv av balanserade utgifter för utvecklingsarbeten och liknande arbeten'
                  ),
                  positiveChange('1010:1015')
                );
                addRowWithAdjustment(
                  rows,
                  '2',
                  label(
                    'Försäljning av balanserade utgifter för utvecklingsarbeten och liknande arbeten'
                  ),
                  negativeChange('1010:1015')
                );
                addRowWithAdjustment(
                  rows,
                  '3',
                  label('Förvärv av koncessioner, patent, licenser m.m.'),
                  positiveChange('1020:1025+1030:1035+1040:1045+1050:1055')
                );
                addRowWithAdjustment(
                  rows,
                  '4',
                  label('Försäljning av koncessioner, patent, licenser m.m.'),
                  negativeChange('1020:1025+1030:1035+1040:1045+1050:1055')
                );
                addRowWithAdjustment(
                  rows,
                  '5',
                  label('Förvärv av hyresrätter och liknande rättigheter'),
                  positiveChange('1060:1065')
                );
                addRowWithAdjustment(
                  rows,
                  '6',
                  label('Försäljning av hyresrätter och liknande rättigheter'),
                  negativeChange('1060:1065')
                );
                addRowWithAdjustment(
                  rows,
                  '7',
                  label('Förvärv av goodwill'),
                  positiveChange('1070:1075')
                );
                addRowWithAdjustment(
                  rows,
                  '8',
                  label('Försäljning av goodwill'),
                  negativeChange('1070:1075')
                );
                addRowWithAdjustment(
                  rows,
                  '9',
                  label('Förvärv av övriga immateriella anläggningstillgångar'),
                  positiveChange('1080:1085+1090:1095')
                );
                addRowWithAdjustment(
                  rows,
                  '10',
                  label(
                    'Försäljning av övriga immateriella anläggningstillgångar'
                  ),
                  negativeChange('1080:1085+1090:1095')
                );
                addRowWithAdjustment(
                  rows,
                  '11',
                  label('Förvärv av byggnader och mark'),
                  positiveChange(
                    '1120:1125+1110:1115+1130:1135+1140:1145+1150:1155+1180:1185'
                  )
                );
                addRowWithAdjustment(
                  rows,
                  '12',
                  label('Försäljning av byggnader och mark'),
                  negativeChange(
                    '1120:1125+1110:1115+1130:1135+1140:1145+1150:1155+1180:1185'
                  )
                );
                addRowWithAdjustment(
                  rows,
                  '13',
                  label('Förvärv av maskiner och andra tekniska anläggningar'),
                  positiveChange('1210:1215')
                );
                addRowWithAdjustment(
                  rows,
                  '14',
                  label(
                    'Försäljning av maskiner o andra tekniska anläggningar'
                  ),
                  negativeChange('1210:1215')
                );
                addRowWithAdjustment(
                  rows,
                  '15',
                  label('Förvärv av inventarier, verktyg och installationer'),
                  positiveChange('1220:1225+1230:1235+1240:1245+1250:1255')
                );
                addRowWithAdjustment(
                  rows,
                  '16',
                  label(
                    'Försäljning av inventarier, verktyg och installationer'
                  ),
                  negativeChange('1250:1255')
                );
                addRowWithAdjustment(
                  rows,
                  '17',
                  label('Förvärv av leasingavtal m.m.'),
                  positiveChange('1260:1265')
                );
                addRowWithAdjustment(
                  rows,
                  '18',
                  label('Försäljning av leasingavtal'),
                  negativeChange('1260:1265')
                );
                addRowWithAdjustment(
                  rows,
                  '19',
                  label('Förvärv av konst m.m.'),
                  positiveChange('1290:1295')
                );
                addRowWithAdjustment(
                  rows,
                  '20',
                  label('Försäljning av konst m.m.'),
                  negativeChange('1290:1295')
                );
                addRowWithAdjustment(
                  rows,
                  '21',
                  label(
                    'Förvärv av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
                  ),
                  positiveChange('1280:1285')
                );
                addRowWithAdjustment(
                  rows,
                  '22',
                  label(
                    'Försäljning av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
                  ),
                  negativeChange('1280:1285')
                );
                addRowWithAdjustment(
                  rows,
                  '23',
                  label('Förvärv av koncernföretag'),
                  positiveChange('1310:1316')
                );
                addRowWithAdjustment(
                  rows,
                  '25',
                  label('Försäljning av andelar i koncernföretag'),
                  negativeChange('1310:1316')
                );
                addRowWithAdjustment(
                  rows,
                  '26',
                  label('Årets lämnade lån till koncernföretag'),
                  positiveChange('1320:1325')
                );
                addRowWithAdjustment(
                  rows,
                  '27',
                  label('Årets amorteringar från koncernföretag'),
                  negativeChange('1320:1325')
                );
                addRowWithAdjustment(
                  rows,
                  '28',
                  label(
                    'Förvärv av andelar i intresseföretag och gemensamt styrda företag'
                  ),
                  positiveChange('1330:1331+1333+1336')
                );
                addRowWithAdjustment(
                  rows,
                  '30',
                  label(
                    'Försäljning av andelar i intresseföretag och gemensamt styrda företag'
                  ),
                  negativeChange('1330:1331+1333+1336')
                );
                addRowWithAdjustment(
                  rows,
                  '31',
                  label(
                    'Årets lämnade lån till intresseföretag och gemensamt styrda företag'
                  ),
                  positiveChange('1340:1341+1343+1346')
                );
                addRowWithAdjustment(
                  rows,
                  '32',
                  label(
                    'Årets amorteringar från intresseföretag och gemensamt styrda företag'
                  ),
                  negativeChange('1340:1341+1343+1346')
                );
                addRowWithAdjustment(
                  rows,
                  '33',
                  label('Förvärv av långfristiga värdepapper'),
                  positiveChange('1350:1357')
                );
                addRowWithAdjustment(
                  rows,
                  '34',
                  label('Försäljning av långfristiga värdepapper'),
                  negativeChange('1350:1357')
                );
                addRowWithAdjustment(
                  rows,
                  '35',
                  label('Ny långfristig utlåning till närstående'),
                  positiveChange('1360:1365')
                );
                addRowWithAdjustment(
                  rows,
                  '36',
                  label('Återbetalning av långfristigt lån från närstående'),
                  negativeChange('1360:1365')
                );
                addRowWithAdjustment(
                  rows,
                  '37',
                  label('Ny långfristig utlåning till utomstående'),
                  positiveChange('1380:1382')
                );
                addRowWithAdjustment(
                  rows,
                  '38',
                  label('Återbetalning av långfristigt lån från utomstående'),
                  negativeChange('1380:1382')
                );
                return rows.build();
              })
              .newRowTemplate(value(''), refs(), value(0))
              .build()
          )
          .addRow(
            'sumInvestmentActivities',
            label('Summa kassaflöde från investeringsverksamheten'),
            undefined,
            ref(id('sumInvestmentActivities'))
          )
          .addRow('empty2', label(''))
          .addRow(
            'financialActivities',
            msg('cashFlowStatement.financialActivities')
          )
          .addSubRows(rows =>
            rows
              .addRow('content')
              .addSubRows(rows => {
                addRowWithAdjustment(
                  rows,
                  '1',
                  label('Aktiekapital'),
                  or(multiply(-1, account('2081', 'change')), 0)
                );
                addRowWithAdjustment(
                  rows,
                  '2',
                  label('Nyemission'),
                  or(multiply(-1, account('2087+2097', 'change')), 0)
                );
                addRowWithAdjustment(
                  rows,
                  '3',
                  label('Erhållna aktieägartillskott'),
                  negativeChange('2093')
                );
                addRowWithAdjustment(
                  rows,
                  '4',
                  label('Återbetalt aktieägartillskott'),
                  positiveChange('2093')
                );
                addRowWithAdjustment(
                  rows,
                  '5',
                  label('Upptagna långfristiga lån'),
                  negativeChange('2350:2399')
                );
                addRowWithAdjustment(
                  rows,
                  '6',
                  label('Amortering långfristiga lån'),
                  positiveChange('2350:2399')
                );
                addRowWithAdjustment(
                  rows,
                  '7',
                  label('Ändring kortfristiga finansiella skulder'),
                  or(multiply(-1, account('2300:2349', 'change')), 0)
                );
                addRowWithAdjustment(
                  rows,
                  '8',
                  label('Utbetald utdelning'),
                  id('payedDividend')
                );
                addRowWithAdjustment(
                  rows,
                  '9',
                  msg('annualReport.cashFlowStatement.groupContributions', {
                    positive: id('positiveGroupContributions'),
                    negative: id('negativeGroupContributions'),
                  }),
                  id('groupContributions')
                );
                return rows.build();
              })
              .newRowTemplate(value(''), refs(), value(0))
              .build()
          )
          .addRow(
            'sumFinancialActivities',
            label('Summa kassaflöde från finansieringsverksamheten'),
            undefined,
            ref(id('sumFinancialActivities'))
          )
          .addRow('empty3', label(''))
          .addRow(
            'totalSum',
            label('Totalt kassaflöde från ovanstående 3 delar'),
            undefined,
            ref(id('sumOperationAndActivities'))
          )
          .addRow('empty4', label(''))
          .addRow(
            'changeLiquidAssets',
            msg('cashFlowStatement.changeLiquidAssets')
          )
          .addSubRows(rows =>
            rows
              .addRow(
                '1',
                msg('cashFlowStatement.changeLiquidAssets.this', {
                  diff: 'id(cashFlowDifference)',
                }),
                undefined,
                ref(
                  sum(
                    id('sumCurrentOperations'),
                    id('sumFinancialActivities'),
                    id('sumInvestmentActivities')
                  )
                )
              )
              .addRow(
                '2',
                label(
                  `Likvida medel vid ${
                    period === null ? 'årets' : 'periodens'
                  } början`
                ),
                refs(),
                ref(id('liquidAssetsStart'))
              )
              .addRow(
                'booked',
                label(
                  'Förändring av likvida medel enligt bokföringen (Kassaflödet)'
                ),
                refs(),
                ref(id('liquidAssetsChange'))
              )
              .addSubRows(rows =>
                rows
                  .addRow('accounts')
                  .addSubRows(rows =>
                    accountRangeRows(accounts, 1870, 1999, rows, n =>
                      multiply(-1, account(n, 'change'))
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0)
                  )
                  .build()
              )
              .addRow(
                '4',
                label('Kursdifferens i likvida medel'),
                refs(),
                value(0)
              )

              .build()
          )
          .addRow(
            '5',
            label(
              `Likvida medel vid ${
                period === null ? 'årets' : 'periodens'
              } slut`
            ),
            refs(),
            ref(id('liquidAssetsEnd'))
          )
          .addRow(
            'difference',
            label('Differens'),
            undefined,
            ref(id('cashFlowDifference'))
          )
          .build()
      )
      .build(),
    otherAccounts: table(
      'cashFlowStatement.section.otherAccounts',
      'label',
      'value'
    )
      .addRows(rows => {
        otherAccountsRange(accounts, 1000, 1009, rows);
        otherAccountsRange(accounts, 1016, 1019, rows);
        otherAccountsRange(accounts, 1026, 1029, rows);
        otherAccountsRange(accounts, 1036, 1039, rows);
        otherAccountsRange(accounts, 1046, 1049, rows);
        otherAccountsRange(accounts, 1056, 1059, rows);
        otherAccountsRange(accounts, 1066, 1069, rows);
        otherAccountsRange(accounts, 1076, 1079, rows);
        otherAccountsRange(accounts, 1086, 1089, rows);
        otherAccountsRange(accounts, 1096, 1099, rows);
        otherAccountsRange(accounts, 1101, 1109, rows);
        otherAccountsRange(accounts, 1116, 1118, rows);
        otherAccountsRange(accounts, 1126, 1128, rows);
        otherAccountsRange(accounts, 1136, 1138, rows);
        otherAccountsRange(accounts, 1146, 1148, rows);
        otherAccountsRange(accounts, 1156, 1179, rows);
        otherAccountsRange(accounts, 1186, 1209, rows);
        otherAccountsRange(accounts, 1216, 1218, rows);
        otherAccountsRange(accounts, 1226, 1228, rows);
        otherAccountsRange(accounts, 1236, 1238, rows);
        otherAccountsRange(accounts, 1246, 1248, rows);
        otherAccountsRange(accounts, 1256, 1258, rows);
        otherAccountsRange(accounts, 1266, 1268, rows);
        otherAccountsRange(accounts, 1270, 1289, rows);
        otherAccountsRange(accounts, 1296, 1309, rows);
        otherAccountsRange(accounts, 1317, 1319, rows);
        otherAccountsRange(accounts, 1326, 1329, rows);
        otherAccountsRange(accounts, 1332, 1332, rows);
        otherAccountsRange(accounts, 1334, 1335, rows);
        otherAccountsRange(accounts, 1337, 1339, rows);
        otherAccountsRange(accounts, 1342, 1342, rows);
        otherAccountsRange(accounts, 1344, 1345, rows);
        otherAccountsRange(accounts, 1347, 1349, rows);
        otherAccountsRange(accounts, 1358, 1359, rows);
        otherAccountsRange(accounts, 1366, 1379, rows);
        otherAccountsRange(accounts, 1383, 1399, rows);
        otherAccountsRange(accounts, 2082, 2086, rows);
        otherAccountsRange(accounts, 2088, 2090, rows);
        otherAccountsRange(accounts, 2092, 2092, rows);
        otherAccountsRange(accounts, 2094, 2096, rows);
        otherAccountsRange(accounts, 2100, 2299, rows);
        return rows.build();
      })
      .build(),
  },
});

const beforeChangeWorkingCapitalId =
  'cashFlowStatement.section.main.currentOperations.beforeChangeWorkingCapital';
const changeWorkingCapitalId =
  'cashFlowStatement.section.main.currentOperations.changeWorkingCapital';
const investmentActivitiesId =
  'cashFlowStatement.section.main.investmentActivities.content';
const financialActivitiesId =
  'cashFlowStatement.section.main.financialActivities.content';
const changeLiquidAssetsId =
  'cashFlowStatement.section.main.changeLiquidAssets';

export const cashFlowStatementReferences = (): Record<string, string> => ({
  operatingProfit: or(multiply(-1, account('3000:7999')), 0),
  sumActivatedWork: or(
    sum(id(`${beforeChangeWorkingCapitalId}.activatedWork.*.value`)),
    0
  ),
  sumDividendsAndProfitShares: or(
    sum(id(`${beforeChangeWorkingCapitalId}.dividendsAndProfitShares.*.value`)),
    0
  ),
  sumCapitalLoss: or(
    sum(id(`${beforeChangeWorkingCapitalId}.capitalLoss.*.value`)),
    0
  ),
  sumCapitalGain: or(
    sum(id(`${beforeChangeWorkingCapitalId}.capitalGain.*.value`)),
    0
  ),
  sumWriteDownChange: or(
    sum(id(`${beforeChangeWorkingCapitalId}.writeDownChange.*.value`)),
    0
  ),
  sumDepreciations: or(
    sum(id(`${beforeChangeWorkingCapitalId}.depreciations.*.value`)),
    0
  ),
  sumReceivedInterest: or(
    sum(id(`${beforeChangeWorkingCapitalId}.receivedInterest.*.value`)),
    0
  ),
  sumPaidInterest: or(
    sum(id(`${beforeChangeWorkingCapitalId}.paidInterest.*.value`)),
    0
  ),
  sumPaidIncomeTax: or(
    sum(id(`${beforeChangeWorkingCapitalId}.paidIncomeTax.*.value`)),
    0
  ),
  yearTax: or(multiply(-1, account('8900:8989')), 0),
  sumBeforeChangeWorkingCapital: sum(
    id('operatingProfit'),
    id('sumActivatedWork'),
    id('sumDividendsAndProfitShares'),
    id('sumCapitalLoss'),
    id('sumCapitalGain'),
    id('sumWriteDownChange'),
    id('sumDepreciations'),
    id('sumReceivedInterest'),
    id('sumPaidInterest'),
    id('sumPaidIncomeTax')
  ),

  sumChangeStockAndWorkInProgress: or(
    sum(id(`${changeWorkingCapitalId}.changeStockAndWorkInProgress.*.value`)),
    0
  ),
  sumChangeAccountReceivable: or(
    sum(id(`${changeWorkingCapitalId}.changeAccountReceivable.*.value`)),
    0
  ),
  sumChangeReceivables: or(
    sum(id(`${changeWorkingCapitalId}.changeReceivables.*.value`)),
    0
  ),
  sumChangeTradeCreditors: or(
    sum(id(`${changeWorkingCapitalId}.changeTradeCreditors.*.value`)),
    0
  ),
  sumChangeCurrentLiabilities: or(
    sum(id(`${changeWorkingCapitalId}.changeCurrentLiabilities.*.value`)),
    0
  ),
  sumChangeWorkingCapital: sum(
    id('sumChangeStockAndWorkInProgress'),
    id('sumChangeAccountReceivable'),
    id('sumChangeReceivables'),
    id('sumChangeTradeCreditors'),
    id('sumChangeCurrentLiabilities')
  ),
  sumCurrentOperations: sum(
    id('sumBeforeChangeWorkingCapital'),
    id('sumChangeWorkingCapital')
  ),

  // Investment activities
  sumInvestmentActivities: sum(id(`${investmentActivitiesId}.*.value`)),
  groupContributions: or(multiply(-1, account('8820:8839')), 0),
  // Trick to be able to format positive and negative group contributions differently
  // The one of 'positiveGroupContributions' and 'negativeGroupContributions' which is 0 indicate
  // which formatting label is used.
  positiveGroupContributions: min(0, id('groupContributions')),
  negativeGroupContributions: max(0, id('groupContributions')),

  payedDividend: sum(
    or(account('2091+2098+2099', 'ib'), 0),
    or(multiply(-1, account('2091+2098')), 0)
  ),
  sentGroupContributions: min(0, id('groupContributions')),
  sumFinancialActivities: sum(id(`${financialActivitiesId}.*.value`)),

  sumOperationAndActivities: sum(
    id('sumCurrentOperations'),
    id('sumInvestmentActivities'),
    id('sumFinancialActivities')
  ),
  liquidAssetsStart: or(account('1870:1999', 'ib'), 0),
  liquidAssetsEnd: or(account('1870:1999'), 0),
  liquidAssetsChange: or(
    sum(id(`${changeLiquidAssetsId}.booked.accounts.*.value`)),
    0
  ),
  cashFlowDifference: sum(
    id('liquidAssetsChange'),
    multiply(-1, id('sumOperationAndActivities'))
  ),
});
