import React from 'react';
import { useIntl } from 'react-intl';
import { TextField, WithTheme } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import styled from '@emotion/styled';

import LoadingLogo from 'components/UI/LoadingLogo';
import { DeleteButton } from 'components/UI';
import StatusMessages from './StatusMessages';

const ActivateContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckIcon = styled(Check)<WithTheme>`
  margin-left: ${props => props.theme.spacing(2)}px;
  color: ${props => props.theme.palette.success.main};
`;

interface Props {
  integrationCode: string;
  getIntegrationStatusError: any;
  activateIntegrationError: any;
  deleteFortnoxIntegrationError: any;
  isLoading: boolean;
  isIntegrationValid: boolean;
  isIntegrationDeleted: boolean;
  handleIntegrationCodeChange: (e) => void;
  handleIntegrationDelete: () => void;
}
const IntegrationCodeContainer = ({
  integrationCode,
  getIntegrationStatusError,
  activateIntegrationError,
  deleteFortnoxIntegrationError,
  isLoading,
  isIntegrationValid,
  isIntegrationDeleted,
  handleIntegrationCodeChange,
  handleIntegrationDelete,
}: Props) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <ActivateContainer>
        <TextField
          variant="outlined"
          value={integrationCode}
          disabled={isLoading || isIntegrationValid}
          onChange={handleIntegrationCodeChange}
        />
        {isIntegrationValid && <CheckIcon fontSize="large" />}
        {isLoading && <LoadingLogo size="medium" />}
      </ActivateContainer>

      {!isLoading && (
        <StatusMessages
          isIntegrationDeleted={isIntegrationDeleted}
          isIntegrationValid={isIntegrationValid}
          activateIntegrationError={activateIntegrationError}
          deleteFortnoxIntegrationError={deleteFortnoxIntegrationError}
          getIntegrationStatusError={getIntegrationStatusError}
        />
      )}

      {!isLoading && isIntegrationValid && (
        <DeleteButton
          variant="contained"
          onClick={handleIntegrationDelete}
          confirmMessage={formatMessage({
            id: 'integration.fortnox.delete.confirmation',
          })}
          disableElevation
        >
          {formatMessage({ id: 'integration.fortnox.delete.button' })}
        </DeleteButton>
      )}
    </>
  );
};

export default IntegrationCodeContainer;
