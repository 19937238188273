import EditField from 'components/UI/Inputs/EditField';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateAnnualReportCellValue,
  updateAnnualReportField,
} from 'redux/actions/AnnualReportView/actions';
import StringInput from './StringInput';

interface StringFieldProps {
  className?: string;
  id: string;
  value: string;
}

const StringField = ({ className, id, value }: StringFieldProps) => {
  const dispatch = useDispatch();
  const onChange = useCallback(
    (value: string) => {
      if (id.split('.').length > 3) {
        dispatch(updateAnnualReportCellValue(id, value));
      } else {
        dispatch(updateAnnualReportField(id, value));
      }
    },
    [dispatch, id]
  );

  return (
    <EditField
      className={className}
      value={value}
      onChange={onChange}
      component={StringInput}
    />
  );
};

export default StringField;
