import { ToggleAnnualReportSectionActive } from 'redux/actions/AnnualReportView/types';
import AnnualReportState from '../types';
import { replaceByIndex, splitSection } from './helpers/util';

const toggleAnnualReportSectionActive = (
  state: AnnualReportState,
  action: ToggleAnnualReportSectionActive
) => {
  const { part, section: sectionId } = action;
  if (!state.report) {
    return state;
  }
  const [section, sectionIndex] = sectionId.includes('-')
    ? splitSection(sectionId)
    : [sectionId];

  if (
    sectionIndex !== undefined
      ? !state.report[part][section].sections[sectionIndex]
      : !state.report[part][section]
  ) {
    return state;
  }

  return {
    ...state,
    report: {
      ...state.report,
      [part]: {
        ...state.report[part],
        [section]:
          sectionIndex !== undefined
            ? {
                ...state.report[part][section],
                sections: replaceByIndex(
                  state.report[part][section].sections,
                  sectionIndex,
                  {
                    ...state.report[part][section].sections[sectionIndex],
                    active: !state.report[part][section].sections[sectionIndex]
                      .active,
                  }
                ),
              }
            : {
                ...state.report[part][section],
                active: !state.report[part][section].active,
              },
      },
    },
    changes: {
      ...state.changes,
      [part]: {
        ...state.changes[part],
        [section]:
          sectionIndex !== undefined
            ? {
                ...state.changes[part]?.[section],
                [sectionIndex]: {
                  ...state.changes[part]?.[section]?.[sectionIndex],
                  active: !state.report[part][section].sections[sectionIndex]
                    .active,
                },
              }
            : {
                ...state.changes[part]?.[section],
                active: !state.report[part][section].active,
              },
      },
    },
  };
};

export default toggleAnnualReportSectionActive;
