import { useAgoyAppMutate } from 'utils/AgoyAppClient/useAgoyAppMutate';
import { useContext, useCallback } from 'react';
import AgoyAppClientContext from 'utils/AgoyAppClient/AgoyAppClientContext';

type FileContent = ArrayBuffer;

type UploadSieFile = (
  clientId: string,
  year: string,
  file: FileContent
) => void;

const createUploadSieFile = (
  clientId: string,
  year: string,
  file: FileContent
) => ({
  uri: `/api/client/${encodeURIComponent(clientId)}/sie/${year}`,
  rawContent: file,
  contentType: 'application/octet-stream',
});

export const useUploadSieFile = () => {
  return useAgoyAppMutate<Parameters<UploadSieFile>, FileContent>(
    createUploadSieFile,
    'PUT'
  );
};

interface SieFileResponse {
  data: ArrayBuffer | null;
  lastModified: string | null;
}

type GetSieFile = (clientId: string, year: string) => Promise<SieFileResponse>;

const createSieFileUri = (clientId, year) =>
  `/api/client/${encodeURIComponent(clientId)}/sie/${encodeURIComponent(year)}`;

export const getSieFile = (
  baseUrl: string,
  headers: () => Promise<{}>
): GetSieFile => async (clientId, year) => {
  const response = await fetch(
    `${baseUrl}${createSieFileUri(clientId, year)}`,
    {
      headers: await headers(),
      cache: 'no-cache',
    }
  );

  if (response.ok) {
    return {
      data: await response.arrayBuffer(),
      lastModified: response.headers.get('Last-Modified'),
    };
  }
  if (response.status === 404) {
    return {
      data: null,
      lastModified: null,
    };
  }
  return Promise.reject(await response.text());
};

export const useGetSieFile = (): GetSieFile => {
  const { baseUrl, headers } = useContext(AgoyAppClientContext);
  return useCallback(getSieFile(baseUrl, headers), [baseUrl, headers]);
};
