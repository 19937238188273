import React from 'react';
import styled from '@emotion/styled';
import AccountStatus from 'components/Views/CustomersView/CustomerCard/AccountStatus';
import { useIntl } from 'react-intl';

const TypographyLegend = styled.div`
  font-size: 0.8em;
`;

const LegendWrapper = styled.div`
  display: flex;
  > * {
    flex-shrink: 0;
  }
`;

const LegendWrapperStatus = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  grid-column-gap: 1em;
`;

const LegendWrapperAccounts = styled.div`
  display: grid;
  margin-left: 1em;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 1.2em;
  justify-items: center;
`;

export const Legend = () => {
  const intl = useIntl();
  return (
    <LegendWrapper>
      <LegendWrapperStatus>
        <AccountStatus status="doesNotExist" type="legend" />
        <TypographyLegend>
          {intl.formatMessage({ id: 'account.status.doesNotExist' })}
        </TypographyLegend>

        <AccountStatus status="done" type="legend" />
        <TypographyLegend>
          {intl.formatMessage({ id: 'account.status.done' })}
        </TypographyLegend>
        <AccountStatus status="wrong" type="legend" />
        <TypographyLegend>
          {intl.formatMessage({ id: 'account.status.wrong' })}
        </TypographyLegend>
        <AccountStatus status="unvisited" type="legend" />
        <TypographyLegend>
          {intl.formatMessage({ id: 'account.status.unvisited' })}
        </TypographyLegend>
      </LegendWrapperStatus>

      <LegendWrapperAccounts>
        <TypographyLegend>
          {intl.formatMessage({ id: 'account.15' })}
        </TypographyLegend>
        <TypographyLegend>1630</TypographyLegend>
        <TypographyLegend>
          {intl.formatMessage({ id: 'account.19' })}
        </TypographyLegend>
        <AccountStatus status="unvisited" type="legend" />
        <AccountStatus status="unvisited" type="legend" />
        <AccountStatus status="unvisited" type="legend" />

        <TypographyLegend>
          {intl.formatMessage({ id: 'account.24' })}
        </TypographyLegend>
        <TypographyLegend>
          {intl.formatMessage({ id: 'account.taxes' })}
        </TypographyLegend>
        <TypographyLegend>
          {intl.formatMessage({ id: 'account.others' })}
        </TypographyLegend>
        <AccountStatus status="unvisited" type="legend" />
        <AccountStatus status="unvisited" type="legend" />
        <AccountStatus status="unvisited" type="legend" />
      </LegendWrapperAccounts>
    </LegendWrapper>
  );
};

export default Legend;
