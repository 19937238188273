import React, { Suspense, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Paper,
  WithTheme,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  FormControl,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import CardTabPanel from '../UI/CardTabPanel';
import { CardTabs } from '../UI/CardTabs';
import { AgoyAppQuery } from 'utils/AgoyAppClient/useAgoyAppQuery';
import get from 'lodash-es/get';

const EditMember = React.lazy(() => import('../Modals/EditMember'));
const InviteMember = React.lazy(() => import('./InviteMember'));

const SearchField = styled(FormControl)`
  width: 100%;
`;

const MemberListType = styled.div<WithTheme>`
  text-align: right;
  color: ${props => props.theme.palette.text.secondary};
`;

const isAdmin = (member, admins) => {
  return admins.includes(member);
};

interface MembersProps {
  orgMembersQuery: AgoyAppQuery<Member.MemberType[], any>;
}

const emptyArray = [];

export const filterMembersByName = (
  members: Member.MemberType[],
  name: string
): Member.MemberType[] => {
  const lowerCaseName = name.toLocaleLowerCase();
  return members.filter(member => {
    // Using get() to avoid 'undefined undefined' if member is empty
    const fullName = `${get(member, 'given_name', '')} ${get(
      member,
      'family_name',
      ''
    )}`;
    return fullName.toLowerCase().includes(lowerCaseName);
  });
};

const Members = ({ orgMembersQuery }: MembersProps) => {
  const [
    orgMembersQueryResult,
    { loading, refetch: refetchOrgMembers },
  ] = orgMembersQuery;
  // Makes sure orgMembers is not null
  const orgMembers = orgMembersQueryResult || emptyArray;
  const admins = useSelector(state => state.organisation.admins);
  const userEmail = useSelector(state => state.user.email);
  const picture = useSelector(state => state.user.picture);

  const adminMember = userEmail && admins ? admins.includes(userEmail) : false;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [
    selectedMember,
    setSelectedMember,
  ] = useState<Member.MemberType | null>(null);
  const [editMemberOpen, setEditMemberOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | null>(null);
  const [members, setMembers] = useState<Member.MemberType[]>([]);

  useEffect(() => {
    // keep local filtered list of members displayed in list
    if (orgMembers) setMembers([...orgMembers]);
  }, [orgMembers]);

  const intl = useIntl();

  const clickListItem = (index: number) => {
    setSelectedMember(members[index]);
    setEditMemberOpen(true);
  };

  const clickCloseEditMember = () => {
    setEditMemberOpen(false);
    setSelectedMember(null);
  };

  const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const onSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const search: string = event.target.value.trim();
    setSearchString(search);
    setMembers(filterMembersByName(orgMembers, search));
  };

  const handleDeleteMember = () => {
    refetchOrgMembers();
  };

  const renderMemberList = () => {
    if (members.length === 0) {
      if (searchString) {
        return (
          <ListItem>
            <ListItemText>
              {intl.formatMessage({ id: 'dashboard.members.noSearchResults' })}
            </ListItemText>
          </ListItem>
        );
      }

      return (
        <Skeleton
          variant="rect"
          animation="wave"
          width="100%"
          height="40px"
          component="li"
        />
      );
    }
    return loading ? (
      <Skeleton
        variant="rect"
        animation="wave"
        width="100%"
        height="40px"
        component="li"
      />
    ) : (
      members.map((member, index) => (
        <React.Fragment key={member.email}>
          <ListItem
            button
            onClick={() => {
              clickListItem(index);
            }}
          >
            <ListItemIcon>
              <Avatar src={picture ? picture : undefined} />
            </ListItemIcon>
            <ListItemText>
              {`${member.given_name} ${member.family_name}`}
            </ListItemText>
            <ListItemText
              primary={
                <MemberListType>
                  {isAdmin(member, admins)
                    ? intl.formatMessage({
                        id: 'dashboard.members.types.admin',
                      })
                    : intl.formatMessage({
                        id: 'dashboard.members.types.accountingContractor',
                      })}
                </MemberListType>
              }
            />
          </ListItem>
        </React.Fragment>
      ))
    );
  };

  return (
    <>
      <Suspense
        fallback={
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="40px"
            component="div"
          />
        }
      >
        {editMemberOpen && selectedMember && (
          <EditMember
            member={selectedMember}
            handleClose={clickCloseEditMember}
            onDeleteMember={handleDeleteMember}
          />
        )}
      </Suspense>

      <Paper elevation={4}>
        <CardTabs
          value={selectedTabIndex}
          onChange={onTabChange}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab
            label={intl.formatMessage({ id: 'dashboard.members.members' })}
          />
          {adminMember && (
            <Tab
              label={intl.formatMessage({ id: 'dashboard.members.invite' })}
            />
          )}
        </CardTabs>
        <CardTabPanel name="members" value={selectedTabIndex} index={0}>
          <SearchField>
            <TextField
              id="search-members"
              variant="outlined"
              size="small"
              placeholder={intl.formatMessage({
                id: 'dashboard.members.searchPlaceholder',
              })}
              fullWidth
              onChange={ev => {
                onSearchInputChange(ev);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </SearchField>
          <List>{renderMemberList()}</List>
        </CardTabPanel>
        <CardTabPanel name="members" value={selectedTabIndex} index={1}>
          {adminMember && (
            <Suspense
              fallback={
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width="100%"
                  height="40px"
                  component="div"
                />
              }
            >
              <InviteMember />
            </Suspense>
          )}
        </CardTabPanel>
      </Paper>
    </>
  );
};

export default Members;
