import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';

const SuccessMessage = styled.span<WithTheme>`
  color: ${props => props.theme.palette.success.main};
`;

const ErrorMessage = styled.span<WithTheme>`
  color: ${props => props.theme.palette.error.main};
`;

interface functionProps {
  deps: any;
  activateIntegrationError: any;
  getIntegrationStatusError: any;
  deleteFortnoxIntegrationError: any;
  isIntegrationDeleted: boolean;
}

export const calculateErrorStatusMessage = ({
  deps: { formatMessage },
  activateIntegrationError,
  getIntegrationStatusError,
  deleteFortnoxIntegrationError,
  isIntegrationDeleted,
}: functionProps) => {
  if (activateIntegrationError) {
    return typeof activateIntegrationError === 'string' ||
      !activateIntegrationError.details
      ? `${formatMessage({ id: 'error' })}. `
      : activateIntegrationError.details.Message ||
          JSON.stringify(activateIntegrationError);
  }

  if (
    getIntegrationStatusError &&
    getIntegrationStatusError.error !== 'Fortnox integration not found'
  ) {
    return formatMessage({ id: 'error.integration.status' });
  }

  if (deleteFortnoxIntegrationError) {
    return formatMessage({ id: 'error.integration.deletion' });
  }

  if (isIntegrationDeleted) {
    return formatMessage({ id: 'integration.fortnox.deleted' });
  }

  return null;
};

interface Props {
  isIntegrationDeleted: boolean;
  isIntegrationValid: boolean;
  activateIntegrationError: any;
  getIntegrationStatusError: any;
  deleteFortnoxIntegrationError: any;
}

const StatusMessages = ({
  isIntegrationValid,
  isIntegrationDeleted,
  activateIntegrationError,
  getIntegrationStatusError,
  deleteFortnoxIntegrationError,
}: Props) => {
  const { formatMessage } = useIntl();

  if (isIntegrationValid) {
    return (
      <Typography>
        <SuccessMessage>
          {formatMessage({
            id: 'integration.fortnox.activate.success',
          })}
        </SuccessMessage>
      </Typography>
    );
  }

  return (
    <Typography>
      <ErrorMessage>
        {calculateErrorStatusMessage({
          deps: { formatMessage },
          activateIntegrationError,
          getIntegrationStatusError,
          deleteFortnoxIntegrationError,
          isIntegrationDeleted,
        })}
      </ErrorMessage>
    </Typography>
  );
};

export default StatusMessages;
