import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from 'config';

export const withStripeProvider = Component => props => {
  const [stripePromise] = useState(() =>
    loadStripe(config.stripeAPIKey, { locale: 'sv' })
  );

  return (
    <Elements stripe={stripePromise}>
      <Component {...props} />
    </Elements>
  );
};

export default withStripeProvider;
