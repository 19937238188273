import {
  RESET_CUSTOMER,
  SET_CURRENT_PERIOD,
  SET_TAX_VIEW_CONFIG,
  SET_TAX_VIEW_STATE,
  DELETE_TAX_TABLE_ROW,
  UPDATE_TAX_TABLE_ROW_LABEL,
  UPDATE_TAX_TABLE_ROW_VALUE,
  UPDATE_TAX_TABLE_ROW_REFERENCE,
  ADD_EMPTY_TAX_TABLE_ROW,
  UPDATE_TRANSACTION_ROW_ACCOUNT,
  REMOVE_USER_TRANSACTIONS,
  RESET_YEAR_END_PLANNING,
  UPDATE_YEAR_END_PLANNING_CHECKSUM,
  RESET_TAX_CALCULATION_TABLE,
  RESET_TAX_CALCULATION_ADJUSTMENTS,
} from '../../actionsTypes';
import TaxViewState from './types';
import { TaxViewActions, GlobalActions } from 'redux/actions';
import {
  TaxCalculationRow,
  TaxCalculationPart,
} from 'utils/TaxCalculation/types';
import {
  yearEndPlanningConfig,
  taxCalculationTableConfig,
  adjustmentsConfig,
} from 'utils/TaxCalculation/config';
import { SetCurrentPeriodAction } from 'redux/actions/CustomerView/types';

const updateTaxTableRow = (
  state: TaxViewState,
  part: TaxCalculationPart,
  lastModified: string,
  mapper?: <T extends TaxCalculationRow>(row: T) => T,
  filter?: <T extends TaxCalculationRow>(row: T) => boolean
): TaxViewState => {
  if (!state.state || !state.config) {
    return state;
  }
  const rows = state.state[part]?.rows;
  if (!rows) {
    return state;
  }

  const mapRows = <T extends TaxCalculationRow>(rows: T[]): T[] => {
    let newRows = rows;
    if (filter) {
      newRows = newRows.filter(filter);
    }
    if (mapper) {
      newRows = newRows.map(mapper);
    }
    return newRows;
  };

  const statePart = state.state[part];
  if (statePart === null) {
    return state;
  }
  const configPart = state.config[part];

  const newStateRows = mapRows(statePart.rows);
  const newConfigRows = mapRows(configPart.rows);
  if (
    newStateRows.length === statePart.rows.length &&
    newConfigRows.length === configPart.rows.length &&
    newStateRows.every((row, index) => row === statePart.rows[index]) &&
    newConfigRows.every((row, index) => row === configPart.rows[index])
  ) {
    // No change
    return state;
  }
  return {
    ...state,
    state: {
      ...state.state,
      [part]: {
        ...state.state[part],
        rows: mapRows(statePart.rows),
      },
    },
    config: {
      ...state.config,
      [part]: {
        ...configPart,
        lastModified,
        rows: mapRows(configPart.rows),
      },
    },
  };
};

const initialTaxViewState: TaxViewState = {
  config: null,
  state: null,
};

export default (
  state: TaxViewState = initialTaxViewState,
  action: TaxViewActions | GlobalActions | SetCurrentPeriodAction
): TaxViewState => {
  switch (action.type) {
    case RESET_CUSTOMER:
      return { ...initialTaxViewState };
    case SET_CURRENT_PERIOD:
      return {
        ...state,
        state: null,
      };
    case SET_TAX_VIEW_CONFIG:
      if (state.config === action.config) {
        return state;
      }
      return {
        ...state,
        config: action.config,
      };
    case SET_TAX_VIEW_STATE:
      if (state.state === action.state) {
        return state;
      }
      return {
        ...state,
        state: action.state,
      };
    case ADD_EMPTY_TAX_TABLE_ROW: {
      if (!state.state || !state.config) {
        return state;
      }
      const { rowId, part } = action;
      const rows = state.state?.[part]?.rows;
      return {
        ...state,
        state: {
          ...state.state,
          [part]: {
            ...state.state[part],
            rows: [...rows, { id: rowId, label: '', value: 0 }],
          },
        },
        config: {
          ...state.config,
          [part]: {
            ...state.config[part],
            rows: [...rows, { id: rowId, label: '', value: 0 }],
          },
        },
      };
    }
    case DELETE_TAX_TABLE_ROW: {
      const { part, rowId, lastModified } = action;
      if (rowId.startsWith('@')) {
        return updateTaxTableRow(
          state,
          part,
          lastModified,
          undefined,
          row => row.id !== rowId
        );
      }
      return updateTaxTableRow(
        state,
        part,
        lastModified,
        row => (row.id === rowId ? { ...row, deleted: true } : row),
        undefined
      );
    }
    case UPDATE_TAX_TABLE_ROW_LABEL: {
      const { part, rowId, label, lastModified } = action;
      return updateTaxTableRow(state, part, lastModified, row =>
        row.id === rowId && row.label !== label ? { ...row, label } : row
      );
    }
    case UPDATE_TAX_TABLE_ROW_VALUE: {
      const { part, rowId, value, lastModified } = action;
      return updateTaxTableRow(state, part, lastModified, row =>
        row.id === rowId && row.value !== value
          ? { ...row, value, reference: undefined }
          : row
      );
    }
    case UPDATE_TAX_TABLE_ROW_REFERENCE: {
      if (!state.state || !state.config) {
        return state;
      }
      const { part, rowId, label, reference } = action;
      const rows = state.state?.[part]?.rows;
      if (!rows) {
        return state;
      }
      const mapRows = <T extends TaxCalculationRow>(rows: T[]): T[] => {
        return rows.map(row =>
          row.id === rowId ? { ...row, label, reference } : row
        );
      };
      return {
        ...state,
        state: {
          ...state.state,
          [part]: {
            ...state.state[part],
            rows: mapRows(rows),
          },
        },
        config: {
          ...state.config,
          [part]: {
            ...state.config[part],
            rows: mapRows(rows),
          },
        },
      };
    }
    case UPDATE_TRANSACTION_ROW_ACCOUNT: {
      const { rowId, account, lastModified } = action;
      return updateTaxTableRow(
        state,
        'adjustments',
        lastModified,
        <TransactionRow>(row) =>
          row.id === rowId && row.account !== account
            ? { ...row, account }
            : row
      );
    }
    case REMOVE_USER_TRANSACTIONS:
      const { lastModified } = action;
      return updateTaxTableRow(
        state,
        'adjustments',
        lastModified,
        undefined,
        row => !row.id.startsWith('@')
      );
    case RESET_YEAR_END_PLANNING:
      if (!state.config || !state.state) {
        return state;
      }
      return {
        ...state,
        config: {
          ...state.config,
          yearEndPlanning: yearEndPlanningConfig,
        },
        state: {
          ...state.state,
          yearEndPlanning: null,
        },
      };
    case RESET_TAX_CALCULATION_TABLE:
      if (!state.config || !state.state) {
        return state;
      }
      return {
        ...state,
        config: {
          ...state.config,
          taxCalculation: taxCalculationTableConfig,
        },
        state: null,
      };
    case RESET_TAX_CALCULATION_ADJUSTMENTS:
      if (!state.config || !state.state) {
        return state;
      }
      return {
        ...state,
        config: {
          ...state.config,
          adjustments: adjustmentsConfig,
        },
        state: null,
      };
    case UPDATE_YEAR_END_PLANNING_CHECKSUM:
      if (!state.config) {
        return state;
      }
      const { checksum } = action;
      if (state.config.yearEndPlanning.checksum === checksum) {
        return state;
      }
      return {
        ...state,
        config: {
          ...state.config,
          yearEndPlanning: {
            ...state.config.yearEndPlanning,
            checksum,
          },
        },
      };
    default:
      return state;
  }
};
