import React, { useState, useEffect, memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '@material-ui/core';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import DocumentPage from 'components/Modals/Customer/DocumentPage';
import { CustomerInformation } from 'components/Modals/Customer';
import InformationRow from './InformationRow';
import LampRow from './LampRow';
import TimeSpans from './TimeSpans';

const CardContainer = styled(Card)`
  margin: 30px 0 8px;
`;

const CustomerTimeSpans = styled(TimeSpans)`
  margin-bottom: 30px;
`;

interface CustomerCardProps {
  customer: Customer.CustomerType;
}

const CustomerCard = ({ customer }: CustomerCardProps) => {
  const { closingMonth, lamps, financialYears, id: customerId } = customer;
  const [moreOpen, setMoreOpen] = useState(false);
  const [documentPageOpen, setDocumentPageOpen] = useState(false);

  const globalExpanded = useSelector(
    state => state.customersOverview.lampsExpanded
  );
  const [expanded, setExpanded] = useState(globalExpanded);
  const history = useHistory();

  const expandedCallback = useCallback(() => {
    setExpanded(currentValue => !currentValue);
  }, []);

  const openMoreCallback = useCallback(open => {
    setMoreOpen(open);
  }, []);

  const visitCustomer = useCallback(() => {
    history.push(`/customer/${customer.id}/details`);
  }, [customer.id, history]);

  useEffect(() => {
    setExpanded(globalExpanded);
  }, [globalExpanded]);

  return (
    <>
      <CardContainer elevation={2} onClick={visitCustomer}>
        <InformationRow
          customer={customer}
          expandedCallback={expandedCallback}
          openMoreCallback={openMoreCallback}
          expanded={expanded}
        />
        <LampRow
          closingMonth={closingMonth}
          expanded={expanded}
          lamps={lamps}
          financialYears={financialYears}
        />
        <CustomerInformation
          handleClose={() => setMoreOpen(false)}
          handleDocumentsOpen={() => {
            setMoreOpen(false);
            setDocumentPageOpen(true);
          }}
          open={moreOpen}
          customer={customer}
        />
      </CardContainer>
      <CustomerTimeSpans financialYears={financialYears} />
      {customer && documentPageOpen && (
        <DocumentPage
          customerId={customerId}
          open={documentPageOpen}
          handleClose={() => setDocumentPageOpen(false)}
        />
      )}
    </>
  );
};

export default memo(CustomerCard);
