import React, { useCallback } from 'react';
import {
  Checkbox,
  TextField as MuiTextField,
  WithTheme,
} from '@material-ui/core';
import isPropValid from '@emotion/is-prop-valid';
import { default as EditFieldBase } from 'components/UI/Inputs/EditField';
import styled from '@emotion/styled';
import {
  toggleAnnualReportFieldActive,
  updateAnnualReportField,
} from 'redux/actions/AnnualReportView/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';

const Section = styled.div<WithTheme>`
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 1rem 0;
`;

const TextFieldBase = props => {
  return <MuiTextField variant="outlined" size="small" {...props} />;
};

const MultiLineMuiTextField = ({ rows, ...rest }) => (
  <TextFieldBase multiline rows={rows} {...rest} />
);

const MultiLineTextField = styled(MultiLineMuiTextField, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  width: 100%;
`;

const ActiveToggle = styled.div`
  align-items: top;
`;

const PrintSection = styled.div<WithTheme>`
  padding: ${props => props.theme.spacing(1)}px;
  margin: ${props => props.theme.spacing(2, 0)};
  background: ${props => props.theme.palette.grey[100]};
`;

type IncomeTaxParagraphProps = {
  id: string;
  editing?: boolean;
  rows?: number;
  print?: boolean;
};

const IncomeTaxParagraph = ({
  id,
  editing,
  rows = 3,
  print = false,
}: IncomeTaxParagraphProps) => {
  const dispatch = useDispatch();
  const value = useSelector(
    state => state.annualReport.report?.notes.note1[id].value
  );
  const active = useSelector(
    state => state.annualReport.report?.notes.note1[id].active
  );

  const toggleFieldActive = useCallback(
    id => {
      dispatch(toggleAnnualReportFieldActive('notes', 'note1', id));
    },
    [dispatch]
  );

  const onFieldChange = value => {
    const fullId = `notes.note1.${id}`;
    dispatch(updateAnnualReportField(fullId, value));
  };

  // Don't render fields that aren't selected
  if (print && !active) {
    return null;
  }

  if (print) {
    return <PrintSection>{value}</PrintSection>;
  }

  return (
    <Section>
      <ActiveToggle>
        <Checkbox checked={active} onChange={() => toggleFieldActive(id)} />
      </ActiveToggle>
      {editing ? (
        <EditFieldBase
          component={props => <MultiLineTextField rows={rows} {...props} />}
          value={value}
          onChange={onFieldChange}
        />
      ) : (
        <MultiLineTextField value={value} rows={rows} disabled />
      )}
    </Section>
  );
};

export default IncomeTaxParagraph;
