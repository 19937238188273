import {
  AnnualReportPartKey,
  AnnualReportTableRow,
  Cell,
} from 'types/AnnualReport/types';
import { CellUpdate, UpdateRowChange } from 'types/AnnualReport/changes';
import AnnualReportState from '../../types';
import updateRow from './updateRow';

const createCellUpdate = (cell: Cell): CellUpdate | undefined => {
  switch (cell.type) {
    case 'ref':
      return { type: 'ref', reference: cell.reference };
    case 'refs':
      return { type: 'refs', references: cell.references };
    case 'string':
      return cell;
    case 'number':
      return cell;
    default:
      return undefined;
  }
};

const updateCell = (
  state: AnnualReportState,
  partKey: AnnualReportPartKey,
  sectionKey: string,
  tableKey: string,
  rowIds: string[],
  columnId: string,
  updater: (cell: Cell) => Cell
): AnnualReportState => {
  return updateRow(
    state,
    partKey,
    sectionKey,
    tableKey,
    rowIds,
    (
      row: AnnualReportTableRow,
      change
    ): [AnnualReportTableRow, UpdateRowChange] => {
      const cell = row.cells?.[columnId];

      if (cell) {
        const newCell = updater(cell);
        if (newCell !== cell) {
          const cellUpdate = createCellUpdate(newCell);
          if (cellUpdate) {
            const newRow = {
              ...row,
              cells: {
                ...row.cells,
                [columnId]: newCell,
              },
            };

            return [
              newRow,
              {
                ...change,
                row: {
                  ...change.row,
                  cells: {
                    ...change.row?.cells,
                    [columnId]: cellUpdate,
                  },
                },
              },
            ];
          }
        }
      }
      return [row, { type: 'update', id: row.id }];
    }
  );
};

export default updateCell;
