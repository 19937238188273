import { ADD_GLOBAL_MESSAGE, REMOVE_GLOBAL_MESSAGE } from '../../actionsTypes';
import { SeverityLevel } from 'redux/reducers/Messages/types';

interface AddGlobalMessage {
  type: typeof ADD_GLOBAL_MESSAGE;
  severity: SeverityLevel;
  textId?: string;
  text?: string;
}

interface RemoveGlobalMessage {
  type: typeof REMOVE_GLOBAL_MESSAGE;
  id: string;
}

export const addGlobalErrorMessage = (
  textId: string | undefined,
  text?: string
): AddGlobalMessage => ({
  type: ADD_GLOBAL_MESSAGE,
  severity: 'error',
  text,
  textId,
});

export const addGlobalMessage = (
  severity: SeverityLevel,
  textId: string | undefined,
  text?: string
): AddGlobalMessage => ({
  type: ADD_GLOBAL_MESSAGE,
  severity,
  text,
  textId,
});

export const removeGlobalMessage = (id: string): RemoveGlobalMessage => ({
  type: REMOVE_GLOBAL_MESSAGE,
  id,
});

export type MessagesActions = AddGlobalMessage | RemoveGlobalMessage;
