import AnnualReportState from 'redux/reducers/AnnualReportView/types';
import { contentDefinition } from 'types/AnnualReport/types';

const excludedParts = [
  'settings',
  'annualGeneralMeeting',
  'confirmationCertificate',
];

const extractPartsToPrint = (state: AnnualReportState): string[] => {
  const { report } = state;
  if (!report) {
    return [];
  }
  return Object.keys(contentDefinition)
    .filter(part => !excludedParts.includes(part))
    .filter(part =>
      Object.keys(contentDefinition[part]).some(
        section => report[part][section].active !== false
      )
    );
};

export default extractPartsToPrint;
