import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Button,
  TableBody,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Row from '../../UI/TableRow';
import { useDispatch } from 'react-redux';
import {
  addAnnualReportTableColumn,
  deleteAnnualReportColumn,
} from 'redux/actions/AnnualReportView/actions';
import { useIntl } from 'react-intl';
import {
  AnnualReportTableRow,
  AnnualReportTable,
} from 'types/AnnualReport/types';
import styled from '@emotion/styled';

// The index of the "add new column" button
const addNewColumnIndex = 4;

interface BigTableProps {
  table: AnnualReportTable;
  editing?: boolean;
  transition: string; // '' | 'toK2' | 'toK3';
  print?: boolean;
}

// After these row ids a "add new row" row will be inserted
const addNewRowIdsDefault = ['content'];

const StyledTableContainer = styled(({ print, ...props }) => (
  <TableContainer {...props} component={Paper} />
))`
  overflow-x: visible;
  .MuiTableCell-head {
    text-align: right;
  }
  ${props =>
    props.print
      ? `
  box-shadow: none;
  
  .MuiTableCell-root {
    border: none;
  }
  `
      : ''}
`;

const BigTable = ({
  table,
  editing = false,
  transition = '',
  print = false,
}: BigTableProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [rows, setRows] = useState<AnnualReportTableRow[]>([]);
  const [addNewRowIds, setAddNewRowIds] = useState(addNewRowIdsDefault);
  const skipColumnIndexes = [addNewColumnIndex];

  // The structure of the table depends on whether transition to k3 is active or not
  useEffect(() => {
    if (transition === 'toK3') {
      // Update on which rows "add new row should appear"
      setAddNewRowIds(['transitionToK3']);
      // Update which rows are visible in the table
      setRows(
        table.rows.filter(
          row => row.id === 'transitionToK3' || row.id === 'main'
        )
      );
    } else if (transition === 'toK2') {
      setAddNewRowIds(['transitionToK2']);
      // Update which rows are visible in the table
      setRows(
        table.rows.filter(
          row => row.id === 'transitionToK2' || row.id === 'main'
        )
      );
    } else {
      setAddNewRowIds(addNewRowIdsDefault);
      setRows(table.rows.filter(row => row.id === 'main'));
    }
  }, [transition, table]);

  const tableId = 'managementReport.changesInEquity.table';

  const addColumn = () => {
    dispatch(
      addAnnualReportTableColumn(
        'managementReport',
        'changesInEquity',
        'table',
        addNewColumnIndex,
        '',
        `number`
      )
    );
  };

  const deleteColumn = columnId => dispatch(deleteAnnualReportColumn(columnId));

  return (
    <StyledTableContainer print={print}>
      <Table>
        <TableHead>
          <TableRow>
            {!print && <td />}

            {table.columns.map((col, index) => (
              <React.Fragment key={`${col.id}${index}`}>
                {index === addNewColumnIndex && !print && (
                  <TableCell>
                    <Button onClick={addColumn}>NY KOLUMN</Button>
                  </TableCell>
                )}

                <TableCell>
                  {col.label !== undefined
                    ? col.label
                    : formatMessage({
                        id: `annualReport.${tableId}.${col.id}`,
                        defaultMessage: col.id,
                      })}
                  {editing && (
                    <IconButton
                      onClick={() => {
                        deleteColumn(
                          `managementReport.changesInEquity.table.${col.id}`
                        );
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </TableCell>
              </React.Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <Row
              key={`${tableId}.${row.id}`}
              addNewRowIds={addNewRowIds}
              baseId={tableId}
              row={row}
              columns={table.columns}
              skipColumns={print ? undefined : skipColumnIndexes}
              editing={editing}
              print={print}
            />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default BigTable;
