import React, { useState } from 'react';
import { LoginBox, TextField, ActionsWrapper } from './SharedComponents';
import { CTAButton } from 'components/UI/Buttons';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';
import { confirmNewPassword } from 'utils/AwsAsync';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { userLogIn } from 'redux/actions/User';
import FormError from 'components/UI/Inputs/FormError';

interface ForgotContentProps {
  backToLogin: () => void;
}

const ForgotContent = ({ backToLogin }: ForgotContentProps) => {
  const { register, errors, handleSubmit } = useForm();
  const [submitError, setSubmitError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  const requestNewPassword = async data => {
    const { username, newPassword } = data;
    try {
      setLoading(true);
      await confirmNewPassword(data);
      dispatch(userLogIn({ username, password: newPassword }));
    } catch (error) {
      setSubmitError(intl.formatMessage({ id: 'error' }));
      setLoading(false);
    }
  };

  return (
    <LoginBox onSubmit={handleSubmit(requestNewPassword)}>
      <Typography variant="h5">
        {intl.formatMessage({ id: 'resetpassword.title' })}
      </Typography>
      <Typography>
        {intl.formatMessage({ id: 'resetpassword.text' })}
      </Typography>

      <TextField
        variant="outlined"
        size="small"
        inputRef={register({
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
        })}
        name="username"
        placeholder={intl.formatMessage({ id: 'email.placeholder' })}
      />
      <FormError>{errors.username?.message}</FormError>

      <TextField
        variant="outlined"
        size="small"
        inputRef={register({
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
        })}
        type="password"
        name="newPassword"
        placeholder={intl.formatMessage({ id: 'resetpassword.newPassword' })}
      />

      <FormError>{errors.newPassword?.message}</FormError>

      <TextField
        variant="outlined"
        size="small"
        inputRef={register({
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
        })}
        name="code"
        placeholder={intl.formatMessage({ id: 'code' })}
      />

      <FormError>{errors.code?.message}</FormError>

      {submitError && <FormError>{submitError}</FormError>}

      <ActionsWrapper>
        <Button onClick={backToLogin}>
          {intl.formatMessage({ id: 'forgot.back' })}
        </Button>
        <CTAButton type="submit" loading={loading}>
          {intl.formatMessage({ id: 'login.retrieve' })}
        </CTAButton>
      </ActionsWrapper>
    </LoginBox>
  );
};

export default ForgotContent;
