import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { Typography, Paper, Grid, Button, TextField } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { addOrganisationInfo } from 'redux/actions';
import { SET_ORG_DETAILS } from 'Graphql/Mutations/OrganisationMutations';
import Snackbar from 'components/UI/Snackbar';
import { CTAButton } from 'components/UI';
import Container from './Container';
import ContainerSubTitle from './ContainerSubTitle';
import { isValidOrgNumber, sanitizeOrgNumber } from 'utils/Client/client-util';

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 2.5rem;
  margin: 1.75rem 0;
`;

interface InformationProps {
  loadingQuery: boolean;
  children?: React.ReactNode;
}

const Information = ({ loadingQuery }: InformationProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const organisationName = useSelector(state => state.organisation.name);
  const address = useSelector(state => state.organisation.address);
  const postalCode = useSelector(state => state.organisation.postalCode);
  const city = useSelector(state => state.organisation.city);
  const orgNumber = useSelector(state => state.organisation.orgNumber);
  const country = useSelector(state => state.organisation.country);
  const admins = useSelector(state => state.organisation.admins);

  const userEmail = useSelector(state => state.user.email);
  const adminMember = userEmail && admins ? admins.includes(userEmail) : false;

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');

  const [changeOrgInfoOpen, setChangeOrgInfoOpen] = useState(false);
  const [newOrgNumber, setNewOrgNumber] = useState('');
  const [newAdress, setNewAdress] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newPostalCode, setNewPostalCode] = useState('');
  const [newCountry, setNewCountry] = useState('');
  const [orgNrError, setOrgNrError] = useState(false);

  useEffect(() => {
    if (address) setNewAdress(address);
    if (city) setNewCity(city);
    if (country) setNewCountry(country);
    if (postalCode) setNewPostalCode(postalCode);
    if (orgNumber) setNewOrgNumber(sanitizeOrgNumber(orgNumber));
  }, [address, postalCode, city, orgNumber, country]);

  const [updateOrgInfo, { loading }] = useMutation(SET_ORG_DETAILS);

  const handleSubmit = async event => {
    event.preventDefault();

    const payload = {
      address: newAdress,
      postalCode: newPostalCode,
      city: newCity,
      orgNumber: isValidOrgNumber(newOrgNumber)
        ? sanitizeOrgNumber(newOrgNumber)
        : orgNumber
        ? orgNumber
        : undefined,
      country: newCountry,
      eek: 12,
    };

    try {
      if (newOrgNumber && isValidOrgNumber(newOrgNumber)) {
        await updateOrgInfo({ variables: payload });
        dispatch(addOrganisationInfo(payload));

        setSubmitSuccess(true);
        setChangeOrgInfoOpen(false);
      } else if (newOrgNumber && !isValidOrgNumber(newOrgNumber)) {
        setSubmitSuccess(false);
        setChangeOrgInfoOpen(true);
      }
    } catch (error) {
      setSubmitErrorMessage(intl.formatMessage({ id: 'error' })); // Generic error message
      setSubmitError(true);
    }
  };

  const orgNrOnBlur = () => {
    if (!isValidOrgNumber(newOrgNumber)) {
      setOrgNrError(true);
    } else {
      setOrgNrError(false);
    }
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSubmitSuccess(false);
    setSubmitError(false);
    setSubmitErrorMessage('');
  };

  return (
    <>
      <Snackbar open={submitError} onClose={handleCloseSnackbar}>
        <Alert severity="error">{submitErrorMessage}</Alert>
      </Snackbar>
      <Snackbar open={submitSuccess} onClose={handleCloseSnackbar}>
        <Alert severity="success">
          {intl.formatMessage({ id: 'orginfo.updated' })}
        </Alert>
      </Snackbar>

      <Paper elevation={4}>
        {!loadingQuery ? (
          <Container>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h5">
                    {intl.formatMessage({
                      id: 'dashboard.information.heading',
                    })}
                  </Typography>
                  <Title variant="h1">{organisationName}</Title>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      item
                      xs={4}
                    >
                      <ContainerSubTitle>
                        {intl.formatMessage({
                          id: 'dashboard.information.orgNumber',
                        })}
                      </ContainerSubTitle>
                    </Grid>
                    <Grid item xs={8}>
                      {changeOrgInfoOpen ? (
                        <TextField
                          id="orginfo-new-orgnr"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          value={sanitizeOrgNumber(newOrgNumber)}
                          onChange={e => {
                            setNewOrgNumber(e.target.value);
                          }}
                          error={orgNrError}
                          helperText={
                            orgNrError &&
                            intl.formatMessage({ id: 'orgNr.error' })
                          }
                          onBlur={orgNrOnBlur}
                        />
                      ) : (
                        <Typography variant="body1">{orgNumber}</Typography>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      item
                      xs={4}
                    >
                      <ContainerSubTitle>
                        {intl.formatMessage({
                          id: 'dashboard.information.adress',
                        })}
                      </ContainerSubTitle>
                    </Grid>
                    <Grid item xs={8}>
                      {changeOrgInfoOpen ? (
                        <TextField
                          id="orginfo-new-adress"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={newAdress}
                          onChange={e => {
                            setNewAdress(e.target.value);
                          }}
                        />
                      ) : (
                        <Typography variant="body1">{address}</Typography>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      item
                      xs={4}
                    >
                      <ContainerSubTitle>
                        {intl.formatMessage({
                          id: 'dashboard.information.city',
                        })}
                      </ContainerSubTitle>
                    </Grid>
                    <Grid item xs={8}>
                      {changeOrgInfoOpen ? (
                        <TextField
                          id="orginfo-new-city"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={newCity}
                          onChange={e => {
                            setNewCity(e.target.value);
                          }}
                        />
                      ) : (
                        <Typography variant="body1">{city}</Typography>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      item
                      xs={4}
                    >
                      <ContainerSubTitle>
                        {intl.formatMessage({
                          id: 'dashboard.information.zipcode',
                        })}
                      </ContainerSubTitle>
                    </Grid>
                    <Grid item xs={8}>
                      {changeOrgInfoOpen ? (
                        <TextField
                          id="orginfo-new-zipcode"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={newPostalCode}
                          onChange={e => {
                            setNewPostalCode(e.target.value);
                          }}
                        />
                      ) : (
                        <Typography variant="body1">{postalCode}</Typography>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      item
                      xs={4}
                    >
                      <ContainerSubTitle>
                        {intl.formatMessage({
                          id: 'dashboard.information.country',
                        })}
                      </ContainerSubTitle>
                    </Grid>
                    <Grid item xs={8}>
                      {changeOrgInfoOpen ? (
                        <TextField
                          id="orginfo-new-country"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={newCountry}
                          onChange={e => {
                            setNewCountry(e.target.value);
                          }}
                        />
                      ) : (
                        <Typography variant="body1">{country}</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {adminMember && (
                  <Grid container item justify="flex-end">
                    {changeOrgInfoOpen ? (
                      <Grid container justify="flex-end" spacing={2}>
                        <Grid item>
                          <Button
                            color="primary"
                            onClick={() => {
                              setChangeOrgInfoOpen(false);
                            }}
                          >
                            Avbryt
                          </Button>
                        </Grid>
                        <Grid item>
                          <CTAButton
                            disableElevation
                            type="submit"
                            loading={loading}
                          >
                            {intl.formatMessage({ id: 'save' })}
                          </CTAButton>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item>
                        <Button
                          color="primary"
                          onClick={() => {
                            setChangeOrgInfoOpen(true);
                          }}
                        >
                          {intl.formatMessage({
                            id: 'dashboard.information.updateInfoButton',
                          })}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </form>
          </Container>
        ) : (
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="200px"
          />
        )}
      </Paper>
    </>
  );
};

export default Information;
