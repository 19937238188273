import React from 'react';
import { WithTheme, Typography, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { AnnualReportTable } from 'types/AnnualReport/types';
import styled from '@emotion/styled';
import { Add } from '@material-ui/icons';
import Cell from '../../../UI/Cell';
import { stringValue } from 'utils/AnnualReport/cell';
import { flattenRows, includeRowInPrint } from '../utils';

interface ThreeColumnTableProps {
  table: AnnualReportTable;
  refTable?: AnnualReportTable;
  tableId: string;
  editing?: boolean;
  print?: boolean;
  groupRowsInterval?: number;
  onAddRow?: () => void;
}

const GridTable = styled.div<WithTheme>`
  display: grid;
  grid-template-columns: auto 130px 130px;
  grid-gap: ${props => props.theme.spacing(1) / 2}px;
`;

const GridTableHead = styled.div`
  display: contents;

  > *:first-of-type {
    justify-self: start;
  }
`;

const GridTableHeadCell = styled.div<WithTheme>`
  align-self: center;
  justify-self: end;
  color: ${props => props.theme.palette.grey[700]};
`;

const GridTableRow = styled.div<{ firstItemInGroup: boolean } & WithTheme>`
  display: contents;
  > * {
    padding: ${props => props.theme.spacing(1) / 2}px;
  }

  ${props =>
    props.firstItemInGroup
      ? `> * {
        margin-top: ${props.theme.spacing(4)}px;
      }`
      : ''}

  /* Zebra grid */
  &:nth-of-type(even) {
    > * {
      background-color: ${props => props.theme.palette.grey[100]};
    }
  }

  /* Last two column justify content right */
  > *:nth-last-of-type(1),
  > *:nth-last-of-type(2) {
    justify-content: flex-end;
  }
`;

const RowCell = styled.div<{ print: boolean } & WithTheme>`
  ${props => (props.print ? `padding: ${props.theme.spacing(0, 1)};` : '')}
  display: flex;
  min-height: 45px;
  align-items: center;
  font-size: inherit;
  line-height: 1.5rem;
`;

const AddRow = styled.div`
  margin: 1rem 0;
`;

const ThreeColumnTable = ({
  table,
  refTable,
  tableId,
  editing = false,
  print = false,
  groupRowsInterval,
  onAddRow,
}: ThreeColumnTableProps) => {
  const { formatMessage } = useIntl();

  const refRows = refTable?.rows[0]; // company table only consists of one row ATM
  const companyName = stringValue(refRows?.cells?.['company']);
  const renderRows = flattenRows(table.rows).filter(
    row => !print || includeRowInPrint(row)
  );

  return (
    <>
      <GridTable>
        <GridTableHead>
          {table.columns.map(col => (
            <GridTableHeadCell key={col.id}>
              {col.id === 'companyName' && companyName}
              {col.id === 'year' && col.label !== '' && (
                <>
                  <Typography align="right">
                    {formatMessage({ id: 'annualReport.notes.tables.year' })}
                  </Typography>
                  <Typography align="right" color="primary">
                    {col.label}
                  </Typography>
                </>
              )}
              {col.id === 'previousYear' && col.label !== '' && (
                <>
                  <Typography align="right">
                    {formatMessage({
                      id: 'annualReport.notes.tables.previousYear',
                    })}
                  </Typography>
                  <Typography align="right" color="primary">
                    {col.label}
                  </Typography>
                </>
              )}
              {col.id !== 'year' && col.id !== 'previousYear' && col.label}
            </GridTableHeadCell>
          ))}
        </GridTableHead>

        {renderRows.map((row, index) => {
          let firstItemInGroup = false;
          if (groupRowsInterval) {
            firstItemInGroup = index % groupRowsInterval === 0 && index !== 0;
          }
          return (
            <GridTableRow key={row.id} firstItemInGroup={firstItemInGroup}>
              {row.cells && (
                <React.Fragment>
                  {table.columns.map(col => {
                    const cell = row.cells?.[col.id];
                    const key = `${row.id}.${col.id}`;
                    if (!cell) {
                      return <div key={key} />;
                    }
                    return (
                      <RowCell key={key} print={print}>
                        <Cell
                          cell={cell}
                          id={`${tableId}.${key}`}
                          editing={editing && row.id !== 'sum'}
                          print={print}
                        />
                      </RowCell>
                    );
                  })}
                </React.Fragment>
              )}
            </GridTableRow>
          );
        })}
      </GridTable>
      {onAddRow && !print && table.newRowTemplate && (
        <AddRow>
          <Button
            startIcon={<Add />}
            onClick={() => {
              onAddRow();
            }}
          >
            {formatMessage({ id: 'add.row' })}
          </Button>
        </AddRow>
      )}
    </>
  );
};

export default ThreeColumnTable;
