import styled from '@emotion/styled';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  WithTheme,
} from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { addAnnualReportRow } from 'redux/actions/AnnualReportView/actions';
import {
  AnnualReportTable,
  AnnualReportTableColumn,
  AnnualReportTableRow,
} from 'types/AnnualReport/table';
import { HierarchicalTableRowProps } from './HierarchicalTableRow';

const StyledTable = styled(({ stickyHeaderOffset, ...props }) => (
  <Table {...props} />
))<WithTheme & { stickyHeaderOffset: number | undefined }>`
  max-width: max-content;
  margin-top: ${props => props.theme.spacing(2)}px;
  position: relative;
  border-collapse: separate;

  .MuiTableCell-root {
    padding: ${props => props.theme.spacing(1)}px;
  }
  .MuiTableCell-root:first-of-type {
    padding-left: 0;
  }
  .MuiTableCell-body {
    border: none;
    padding: ${props => props.theme.spacing(1)}px;
  }
  .MuiTableCell-head {
    color: ${props => props.theme.palette.grey[700]};
  }
  .MuiTableRow-head {
    vertical-align: baseline;
  }
  ${props =>
    props.stickyHeaderOffset !== undefined &&
    `
  .MuiTableCell-head {
    position: sticky;
    top: 0px;
    z-index: 1;
    background: ${props.theme.palette.background.default};
  }
  `}
`;

const ControlCell = styled(TableCell)`
  white-space: nowrap;
`;

const classes = {};

export interface HierarchicalTableProps {
  className?: string;
  table?: AnnualReportTable;
  tableId?: string;

  /** Merge rows of multiple tables together, must have matching tableIds */
  tables?: AnnualReportTable[];
  tableIds?: string[];

  rowComponents: React.ComponentType<HierarchicalTableRowProps>[];
  controls?: React.ReactElement | React.ReactElement[];
  editing?: boolean;
  stickyHeaderOffset?: number;
  AddRow?: React.ComponentType<AddRowProps>;
  print?: boolean;
}

export interface AddRowProps {
  onClick: (rowParameters?: Record<string, string>) => void;
  level: number;
  parent: AnnualReportTable | AnnualReportTableRow;
  parentId: string;
  columns: AnnualReportTableColumn[];
}

interface RowsProps {
  table: AnnualReportTable;
  tableId: string;
  rowComponents: React.ComponentType<HierarchicalTableRowProps>[];
  editing?: boolean;
  AddRow?: React.ComponentType<AddRowProps>;
  print?: boolean;
}

const Rows = ({
  table,
  tableId,
  rowComponents,
  editing,
  AddRow,
  print,
}: RowsProps) => {
  const dispatch = useDispatch();
  const RowComponent = rowComponents[0];
  return (
    <>
      {table.rows.map(row => (
        <RowComponent
          key={`${tableId}.${row.id}`}
          classes={classes}
          baseId={tableId}
          row={row}
          columns={table.columns}
          level={1}
          rowComponents={rowComponents}
          editing={editing}
          AddRow={AddRow}
          print={print}
        />
      ))}
      {!print && AddRow && table.newRowTemplate && (
        <AddRow
          level={1}
          onClick={cellParameters =>
            dispatch(addAnnualReportRow(tableId, undefined, cellParameters))
          }
          parent={table}
          columns={table.columns}
          parentId={tableId}
        />
      )}
    </>
  );
};

const HierarchicalTable = ({
  className,
  table,
  tableId,
  tables,
  tableIds,
  rowComponents,
  controls,
  editing,
  stickyHeaderOffset,
  AddRow,
  print,
}: HierarchicalTableProps) => {
  const { formatMessage } = useIntl();

  if (tables && !tables[0] && !table) {
    return null;
  }

  const columns = table?.columns || tables?.[0]?.columns;
  if (!columns) {
    return null;
  }

  const firstTableId = tableId || tableIds?.[0];
  if (!firstTableId) {
    return null;
  }

  // AGOY-886
  const renderColumns = columns.filter(col => !col.id?.includes('hidden'));

  return (
    <StyledTable
      className={className}
      stickyHeaderOffset={print ? undefined : stickyHeaderOffset}
    >
      <TableHead>
        <TableRow>
          {renderColumns.map(col => (
            <TableCell key={col.id}>
              {col.label !== undefined
                ? col.label
                : formatMessage({
                    id: `annualReport.${firstTableId}.${col.id}`,
                    defaultMessage: col.id,
                  })}
            </TableCell>
          ))}
          {!print && controls && <ControlCell>{controls}</ControlCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {table && tableId && (
          <Rows
            table={table}
            tableId={tableId}
            rowComponents={rowComponents}
            AddRow={AddRow}
            editing={editing}
            print={print}
          />
        )}
        {tables?.map((table, index) => {
          const id = tableIds?.[index];
          if (!id) {
            return null;
          }
          return (
            <Rows
              key={id}
              table={table}
              tableId={id}
              rowComponents={rowComponents}
              AddRow={AddRow}
              editing={editing}
              print={print}
            />
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

export default HierarchicalTable;
