import PropsOfType from 'utils/PropsOfType';
import { Field } from './field';
import { ManagementReport } from './parts/managementReport';
import { IncomeStatement } from './parts/incomeStatement';
import { CashFlowStatement } from './parts/cashFlowStatement';
import { BalanceSheet } from './parts/balanceSheet';
import { Notes } from './parts/notes';
import { Settings } from './parts/settings';
import { Signatures } from './parts/signatures';
import { AnnualReportTable } from './table';
import { AnnualGeneralMeeting } from './parts/annualGeneralMeeting';
import { ConfirmationCertificate } from './parts/confirmationCertificate';
export * from './cell';
export * from './table';
export * from './field';
export * from './changes';

export type AnnualReportType = 'k2' | 'k3';

export interface AnnualReportSection {
  active?: boolean;
}

export interface AnnualReportSectionArray<T extends AnnualReportSection> {
  sections: (T | null)[];
  newSectionTemplate: T;
}

export interface AnnualReportPart {
  type: 'part';
}

export interface AnnualReport {
  settings: Settings;

  // Fastställelseintyg
  confirmationCertificate: ConfirmationCertificate;

  // Verksamhets berättelse
  managementReport: ManagementReport;

  // Resultaträkning
  incomeStatement: IncomeStatement;

  // Balansräkning
  balanceSheet: BalanceSheet;

  // Kassaflödesanalys
  cashFlowStatement: CashFlowStatement;

  // Noter
  notes: Notes;

  // Underskrifter
  signatures: Signatures;

  // Årsstämmoprotokoll
  annualGeneralMeeting: AnnualGeneralMeeting;
}

export type AnnualReportPartKey = PropsOfType<AnnualReport, AnnualReportPart>;

export function isAnnualReportPartKey(
  part: string
): part is AnnualReportPartKey {
  return contentDefinition[part] !== undefined;
}

//
// Types used to define the content type definition
//
type PartContent<Section extends AnnualReportSection> = {
  [U in PropsOfType<
    Section,
    AnnualReportTable | Field
  >]: Section[U] extends AnnualReportTable ? 'table' : 'field';
};

type PartSectionsArray<Part extends AnnualReportSection> = PartContent<Part> & {
  type: 'sections';
};

type PartSections<Part extends AnnualReportPart> = {
  [U in PropsOfType<
    Part,
    AnnualReportSection | AnnualReportSectionArray<AnnualReportSection>
  >]: Part[U] extends AnnualReportSectionArray<AnnualReportSection>
    ? PartSectionsArray<Part[U]['newSectionTemplate']>
    : PartContent<Part[U]>;
};

type Content = {
  [U in AnnualReportPartKey]: PartSections<AnnualReport[U]>;
};

export const contentDefinition: Content = {
  settings: {
    section: {},
  },
  confirmationCertificate: {
    section: {
      date: 'field',
      location: 'field',
      statement: 'field',
      signatureClarification: 'field',
    },
  },
  managementReport: {
    activities: {
      accountant: 'field',
      events: 'field',
      generally: 'field',
      shares: 'field',
    },
    viability: { text: 'field' },
    ownershipChanges: { text: 'field' },
    controlBalanceSheet: { text: 'field' },
    futureDevelopment: { text: 'field' },
    rAndD: { text: 'field' },
    subsidiariesAbroad: { text: 'field' },
    sustainabilityInfo: { text: 'field' },
    sustainabilityReport: { text: 'field' },
    permitActivities: { text: 'field' },
    multiYearOverview: { table: 'table', multiYearText: 'field' },
    changesInEquity: {
      transition: 'field',
      table: 'table',
      conditionalShareholderContributions: 'table',
    },
    resultsDisposition: {
      selectedDividend: 'field',
      propose: 'field',
      toDispose: 'table',
      total: 'field',
      proposal: 'table',
      toTransfer: 'field',
    },
    profitProposal: {
      dividendPerShare: 'field',
      dividend: 'field',
      shares: 'field',
      toTransfer: 'field',
      paymentDateType: 'field',
      paymentDate: 'field',
      statement: 'field',
    },
  },
  balanceSheet: {
    section: {
      assets: 'table',
      equityAndLiabilities: 'table',
    },
  },
  incomeStatement: {
    section: { table: 'table' },
  },
  cashFlowStatement: {
    section: {
      main: 'table',
      otherAccounts: 'table',
    },
  },
  notes: {
    note1: {
      number: 'field',
      name: 'field',
      disclaimer: 'field',
      tangibleFixedAssets: 'table',
      receivables: 'field',
      otherAssetsProvisionsAndLiabilities: 'field',
      revenueRecognition: 'field',
      saleOfGoods: 'field',
      services: 'field',
      rents: 'field',
      interestRoyaltyAndDividens: 'field',
      intangibleAssetsGeneral: 'field',
      intangibleAssetsTable: 'table',
      intangibleAssetsProjectsClosed: 'field',
      incomeTaxParagraph1: 'field',
      incomeTaxParagraph2: 'field',
      incomeTaxParagraph3: 'field',
      incomeTaxParagraph4: 'field',
      incomeTaxParagraph5: 'field',
      comment: 'field',
    },
    note2: {
      number: 'field',
      name: 'field',
      avgNumberOfEmployees: 'field',
      avgNumberOfEmployeesTable: 'table',
      comment: 'field',
    },
    note3: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note4: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note5: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note6: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note7: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note8: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note9: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note10: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note11: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note12: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note13: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note14: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note15: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note16: {
      number: 'field',
      name: 'field',
      table: 'table',
      secondTable: 'table',
      comment: 'field',
    },
    note17: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note18: {
      number: 'field',
      name: 'field',
      table: 'table',
      secondTable: 'table',
      comment: 'field',
    },
    note19: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note20: {
      number: 'field',
      name: 'field',
      table: 'table',
      secondTable: 'table',
      comment: 'field',
    },
    note21: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note22: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note23: {
      number: 'field',
      name: 'field',
      table: 'table',
      secondTable: 'table',
      comment: 'field',
    },
    note24: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note25: {
      number: 'field',
      name: 'field',
      table: 'table',
      sumTable: 'table',
      comment: 'field',
    },
    note26: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note27: {
      number: 'field',
      name: 'field',
      table: 'table',
      sumTable: 'table',
      comment: 'field',
    },
    note28: {
      number: 'field',
      name: 'field',
      general: 'field',
      table: 'table',
      secondTable: 'table',
      sumTable: 'table',
      comment: 'field',
    },
    note29: {
      number: 'field',
      name: 'field',
      table: 'table',
      sumTable: 'table',
      comment: 'field',
    },
    note30: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note31: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note32: {
      number: 'field',
      name: 'field',
      table: 'table',
      sumTable: 'table',
      comment: 'field',
    },
    note33: {
      number: 'field',
      name: 'field',
      table: 'table',
      sumTable: 'table',
      comment: 'field',
    },
    note34: {
      number: 'field',
      name: 'field',
      table: 'table',
      sumTable: 'table',
      secondTable: 'table',
      comment: 'field',
    },
    note35: {
      number: 'field',
      name: 'field',
      inputField: 'field',
      comment: 'field',
    },
    note36: {
      number: 'field',
      name: 'field',
      inputField: 'field',
      comment: 'field',
    },
    note37: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note38: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    note39: {
      number: 'field',
      name: 'field',
      table: 'table',
      comment: 'field',
    },
    custom: {
      type: 'sections',
      number: 'field',
      name: 'field',
      typeOne: 'field',
      typeTwo: 'table',
      typeThree: 'table',
    },
  },
  signatures: {
    section: {
      location: 'field',
      date: 'field',
      people: 'table',
      auditReportDate: 'field',
      statement: 'field',
    },
  },
  annualGeneralMeeting: {
    main: {
      location: 'field',
      date: 'field',
      votingList: 'table',
      result: 'field',
      resultsDisposition: 'table',
      fee: 'field',
      boardMembers: 'table',
      auditors: 'table',
      noAuditor: 'field',
      deputyAuditors: 'table',
      customParagraphs: 'table',
    },
  },
};

export const sectionOrdering = {
  notes: [
    'note1',
    'note2',
    'note3',
    'note4',
    'note5',
    'note6',
    'note7',
    'note8',
    'note9',
    'note10',
    'note11',
    'note12',
    'note13',
    'note14',
    'note15',
    'note16',
    'note17',
    'note18',
    'note19',
    'note20',
    'note21',
    'note22',
    'note23',
    'note24',
    'note25',
    'note26',
    'note27',
    'note28',
    'note29',
    'note30',
    'note31',
    'note32',
    'note33',
    'note34',
    'note35',
    'note36',
    'note37',
    'note38',
    'note39',
    'custom',
  ],
};
