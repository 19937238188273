export const replaceById = <T extends { id: string }>(
  array: T[] | undefined,
  item: T
): T[] | undefined => array?.map(i => (i.id === item.id ? item : i));

export const replaceByIdAndType = <T extends { id: string; type: string }>(
  array: T[] | undefined,
  item: T
): T[] | undefined =>
  array?.map(i => (i.id === item.id && i.type === item.type ? item : i));

export const replaceByIndex = <T>(
  array: T[] | undefined,
  index: number,
  item: T
): T[] | undefined =>
  array
    ? [...array.slice(0, index), item, ...array.slice(index + 1)]
    : undefined;

export const addItem = <T>(array: T[] | undefined, item: T): T[] =>
  array ? [...array, item] : [item];

export const insertAt = <T>(array: T[], item: T, index: number): T[] => [
  ...array.slice(0, index),
  item,
  ...array.slice(index),
];

export const splitSection = (section: string): [string, number] => {
  const [name, no] = section.split('-');
  return [name, parseInt(no)];
};
