import {
  SET_USER_INPUT_DATA,
  SET_ROUTINES_DATA,
  SET_LAMP_STATUS,
  PATCH_USER_INPUT_DATA,
  PATCH_USER_INPUT_ROUTINE_DATA,
  SET_SIE_DATA,
  SET_ADJUSTMENT_DATA,
  STARTED_SAVING_USER_DATA,
  SAVING_USER_DATA_FAILED,
  SAVING_USER_DATA_SUCCESS,
  INCREASE_IMAGES,
  DECREASE_IMAGES,
  SET_NUM_IMAGES,
  TOGGLE_NEW_SIE_FILE_UPDATED,
  TOGGLE_SINGLE_PERIOD,
  TOGGLE_PERIOD_CHANGE_VISIBILITY,
  TOGGLE_PERIOD_UB_VISIBILITY,
} from '../../actionsTypes';
import { updateTaxView } from '../TaxView';
import { getSieFile } from 'components/Api/Client/sie';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { updateSIE } from 'components/Api/Client/Integration/Fortnox';
import { addGlobalMessage, addGlobalErrorMessage } from '../Messages';
import { arrayBufferToBuffer } from 'utils';
import { readBuffer } from 'utils/sie-reader/sie-reader';
import mapSieInformationToTableData from 'utils/SieParser';
import { RootState } from 'redux/reducers';
import { setFinancialYears } from '../Customers';
import { updateAnnualReportConfig } from 'redux/actions/AnnualReportView/actions';
import {
  SavingUserDataFailedAction,
  SavingUserDataSuccessAction,
  SetAdjustmentDataAction,
  setSieDataArg,
  SetUserInputDataAction,
  StartedSavingUserDataAction,
} from './types';
import { AdjustmentData, UserInput } from 'types/AccountingView/types';

export const patchUserInputData = ({
  newUserInput,
  accountNumber,
  formattedPeriod,
}) => ({
  type: PATCH_USER_INPUT_DATA,
  newUserInput,
  accountNumber,
  formattedPeriod,
});

export const patchUserInputRoutineData = ({
  newUserInput,
  accountNumber,
  formattedPeriod,
}: {
  newUserInput: any;
  accountNumber: string;
  formattedPeriod: string;
}) => ({
  type: PATCH_USER_INPUT_ROUTINE_DATA,
  newUserInput,
  accountNumber,
  formattedPeriod,
});

export const increaseImages = (accountNumber, formattedPeriod) => ({
  type: INCREASE_IMAGES,
  accountNumber,
  formattedPeriod,
});

export const decreaseImages = (accountNumber, formattedPeriod) => ({
  type: DECREASE_IMAGES,
  accountNumber,
  formattedPeriod,
});

export const setNumImages = (accountNumber, formattedPeriod, numImages) => ({
  type: SET_NUM_IMAGES,
  accountNumber,
  formattedPeriod,
  numImages,
});

export const setUserInputData = (
  userInputData: UserInput
): SetUserInputDataAction => {
  return {
    type: SET_USER_INPUT_DATA,
    userInput: userInputData,
  };
};

export const setRoutinesData = routines => {
  return {
    type: SET_ROUTINES_DATA,
    routines,
  };
};

export const setCustomerLampStatus = (mapStatuses, period, customerId) => {
  return {
    type: SET_LAMP_STATUS,
    mapStatuses,
    customerId,
    period,
  };
};

export const setSieData = (sieData: setSieDataArg) => {
  return async (dispatch, getState: () => RootState) => {
    dispatch({
      type: SET_SIE_DATA,
      sieData,
    });
    updateTaxView(dispatch, getState, false);
    updateAnnualReportConfig(dispatch, getState);
  };
};

export const setAdjustmentData = (
  adjustmentData: AdjustmentData
): SetAdjustmentDataAction => ({
  type: SET_ADJUSTMENT_DATA,
  adjustmentData,
});

export const sieFileJustUpdated = () => {
  return dispatch => {
    dispatch({ type: TOGGLE_NEW_SIE_FILE_UPDATED });
    setTimeout(() => dispatch({ type: TOGGLE_NEW_SIE_FILE_UPDATED }), 5000);
  };
};

export const startedSavingUserData = (): StartedSavingUserDataAction => ({
  type: STARTED_SAVING_USER_DATA,
});

export const savingUserDataFailed = (): SavingUserDataFailedAction => ({
  type: SAVING_USER_DATA_FAILED,
});

export const savingUserDataSuccess = (): SavingUserDataSuccessAction => ({
  type: SAVING_USER_DATA_SUCCESS,
});

export const toggleSinglePeriod = period => ({
  type: TOGGLE_SINGLE_PERIOD,
  period,
});

export const togglePeriodChangeVisibility = () => ({
  type: TOGGLE_PERIOD_CHANGE_VISIBILITY,
});

export const togglePeriodUBVisibility = () => ({
  type: TOGGLE_PERIOD_UB_VISIBILITY,
});

export const updateSieFromFortnox = (done?: () => void) => async (
  dispatch,
  getState
) => {
  try {
    const context = getContext();
    if (context) {
      const {
        currentCustomer,
        currentYear,
      } = (getState() as RootState).customerView;
      if (!currentCustomer || !currentYear) {
        return;
      }

      try {
        const { updatedFinancialYears } = await updateSIE(context)(
          currentCustomer,
          currentYear
        );
        if (updatedFinancialYears) {
          dispatch(setFinancialYears(currentCustomer, updatedFinancialYears));
          dispatch(addGlobalMessage('info', 'customersubmenu.addedyear'));
        }

        const request = getSieFile(context.baseUrl, context.headers);
        const { data, lastModified } = await request(
          currentCustomer,
          currentYear
        );

        const buffer = arrayBufferToBuffer(data);
        const sieFile = readBuffer(buffer);
        const {
          periods,
          aggregated,
          transactions,
        } = mapSieInformationToTableData(sieFile);
        dispatch(
          setSieData({
            customerId: currentCustomer,
            period: periods.periods[0].substring(0, 4),
            periods: periods.periods.map(val => val.substring(0, 6)),
            sieBlob: aggregated,
            sieUpdated: lastModified || undefined,
            transactions,
          })
        );

        dispatch(
          addGlobalMessage(
            'success',
            'customersubmenu.updatewithfortnox.success'
          )
        );
      } catch (e) {
        console.error(e);
        if (typeof e === 'string' || !e.details?.message) {
          dispatch(addGlobalErrorMessage('error'));
        } else {
          dispatch(addGlobalErrorMessage(undefined, e.details.message));
        }
      }
    }
  } finally {
    if (done) done();
  }
};
