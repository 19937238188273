import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

const Title = styled(Typography)``;

type FieldSectionTitleProps = {
  noteId: string;
  id: string;
};

export const FieldSectionTitle = ({ noteId, id }: FieldSectionTitleProps) => {
  const { formatMessage } = useIntl();
  return (
    <Title variant="h3">
      {formatMessage({ id: `annualReport.notes.${noteId}.${id}` })}
    </Title>
  );
};
