import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  WithTheme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import styled from '@emotion/styled';
import { useSelector } from 'redux/reducers';
import { AnnualReportTableRow } from 'types/AnnualReport/table';
import { batch, useDispatch } from 'react-redux';
import {
  addAnnualReportRow,
  deleteAnnualReportRow,
  updateAnnualReportCellValue,
} from 'redux/actions/AnnualReportView/actions';
import { stringValue } from 'utils/AnnualReport/cell';
import VotingListRow from './VotingListRow';
import AddTableRow from '../../UI/HierarchicalTable/AddTableRow';
import isPropValid from '@emotion/is-prop-valid';

const StyledTable = styled(Table, {
  shouldForwardProp: prop => isPropValid(prop),
})<{ print: boolean } & WithTheme>`
  max-width: max-content;
  min-width: 600px;
  margin: ${props => props.theme.spacing(2)}px 0;

  .MuiTableCell-root {
    padding: ${props => props.theme.spacing(2)}px;
  }
  .MuiTableCell-root :nth-of-type(3),
  .MuiTableCell-root :nth-of-type(4) {
    text-align: right;
  }
  .MuiTableCell-body {
    border: none;
    padding: ${props => props.theme.spacing(1)}px;
  }
  .MuiTableCell-head {
    color: ${props =>
      !props.print
        ? props.theme.palette.background.default
        : props.theme.palette.text.primary};
    background-color: ${props =>
      !props.print
        ? props.theme.palette.primary.main
        : props.theme.palette.common.white};
  }
`;
const getFullName = cells => {
  return `${cells?.givenName?.value} ${cells?.surName?.value}`;
};

const isNotAccountant = ({ cells }: AnnualReportTableRow) =>
  !(
    cells?.accountantRole.type === 'string' &&
    cells?.accountantRole.value !== ''
  );

const mapRole = ({ cells }: AnnualReportTableRow) =>
  cells?.role.type === 'string' ? cells?.role.value : '';

type VotingListProps = {
  fullId: string;
  print?: boolean;
};
const VotingList = ({ fullId, print = false }: VotingListProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const people = useSelector(
    state => state.annualReport.report?.signatures.section.people
  );
  const votingList = useSelector(
    state => state.annualReport.report?.annualGeneralMeeting.main.votingList
  );

  const handleAddRow = () => {
    dispatch(addAnnualReportRow(fullId, undefined, { name: '' }));
  };

  useEffect(() => {
    // Synchronize votingList with people from signatures
    if (votingList && people) {
      const votingListIds = votingList.rows.map(row => row.id);
      const votingListById = votingList.rows.reduce(
        (result, row) => ({ ...result, [row.id]: row }),
        {}
      );
      const peopleIds = people.rows.map(row => `${row.id}-signature`);
      const peopleToAdd = people.rows
        .filter(isNotAccountant)
        .filter(row => !votingListIds.includes(`${row.id}-signature`));
      const peopleToDelete = votingListIds.filter(
        id => id.endsWith('-signature') && !peopleIds.includes(id)
      );
      const peopleToUpdate = people.rows
        .filter(isNotAccountant)
        .filter(row => votingListIds.includes(`${row.id}-signature`))
        .filter(
          row =>
            getFullName(row.cells) !==
            stringValue(votingListById[`${row.id}-signature`]?.cells?.name)
        );
      if (
        peopleToAdd.length > 0 ||
        peopleToDelete.length > 0 ||
        peopleToUpdate.length > 0
      ) {
        batch(() => {
          peopleToDelete.forEach(id => {
            dispatch(deleteAnnualReportRow(`${fullId}.${id}`));
          });
          peopleToAdd.forEach(row => {
            dispatch(
              addAnnualReportRow(fullId, `${row.id}-signature`, {
                name: getFullName(row.cells),
                role: mapRole(row),
              })
            );
          });
          peopleToUpdate.forEach(row => {
            dispatch(
              updateAnnualReportCellValue(
                `${fullId}.${row.id}-signature.name`,
                getFullName(row.cells)
              )
            );
          });
        });
      }
    }
  }, [people, votingList, dispatch, fullId]);

  return (
    <StyledTable size="small" print={print}>
      <TableHead>
        <TableRow>
          {!print && (
            <TableCell>
              {formatMessage({ id: `annualReport.${fullId}.active` })}
            </TableCell>
          )}
          <TableCell>
            {formatMessage({ id: `annualReport.${fullId}.name` })}
          </TableCell>
          <TableCell>
            {formatMessage({ id: `annualReport.${fullId}.shares` })}
          </TableCell>
          <TableCell>
            {formatMessage({ id: `annualReport.${fullId}.votes` })}
          </TableCell>
          <TableCell>
            {formatMessage({ id: `annualReport.${fullId}.role` })}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {votingList?.rows.map(p => (
          <VotingListRow tableId={fullId} key={p.id} row={p} print={print} />
        ))}
        {!print && (
          <AddTableRow level={0}>
            <TableCell colSpan={5}>
              <div className="add" onClick={handleAddRow}>
                <Button startIcon={<AddIcon />}>Lägg till rad</Button>
              </div>
            </TableCell>
            <TableCell />
          </AddTableRow>
        )}
      </TableBody>
    </StyledTable>
  );
};
export default VotingList;
