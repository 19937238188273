import React, { useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Select, FormControl, MenuItem } from '@material-ui/core';
import moment from 'moment';

import { useSelector } from 'redux/reducers';
import { setCurrentPeriod } from 'redux/actions';

interface PeriodSelection {
  period: string;
  label: string;
}

export const generatePeriods = (financialYear: string): PeriodSelection[] => {
  const [start, end] = financialYear
    .split('-')
    .map(value => moment(value, 'YYYYMM'));
  const periods: PeriodSelection[] = [];

  while (start.isBefore(end)) {
    const date = start.endOf('month');
    periods.push({
      period: date.format('YYYYMM'),
      label: `Periodbokslut ${date.format('YYYY-MM-DD')}`,
    });
    start.add(1, 'month');
  }
  periods.push({
    period: end.format('YYYYMM'),
    label: `Årsbokslut ${end.endOf('month').format('YYYY-MM-DD')}`,
  });

  return periods;
};

interface PeriodSelectorProps {
  customerId: string;
}

const PeriodSelector = ({ customerId }: PeriodSelectorProps) => {
  const financialYears = useSelector(
    state => (customerId && state.customers[customerId]?.financialYears) || []
  );
  const { currentPeriod, currentYear }: any = useSelector(
    state => state.customerView
  );

  const financialYear = financialYears.find(year =>
    year.startsWith(currentYear)
  );
  const periods = useMemo(
    () => (financialYear ? generatePeriods(financialYear) : []),
    [financialYear]
  );

  const dispatch = useDispatch();
  const handleChangePeriod = useCallback(
    event => {
      dispatch(setCurrentPeriod(event.target.value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      !currentPeriod ||
      !periods.find(period => period.period === currentPeriod)
    ) {
      const initialPeriod = moment().format('YYYYMM');
      if (periods.find(period => period.period === initialPeriod)) {
        dispatch(setCurrentPeriod(initialPeriod));
      } else if (periods.length > 0) {
        // Fallback to last period
        dispatch(setCurrentPeriod(periods[periods.length - 1].period));
      }
    }
  }, [currentPeriod, dispatch, periods]);

  return (
    <FormControl>
      <Select
        disableUnderline
        value={currentPeriod || ''}
        onChange={handleChangePeriod}
      >
        {periods.map(period => (
          <MenuItem key={period.period} value={period.period}>
            {period.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PeriodSelector;
