import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import {
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  WithTheme,
  Tooltip,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ProfitProposal as ProfitProposalType } from 'types/AnnualReport/parts/managementReport';
import Cell from '../../../UI/Cell';
import Section from '../Section';
import { stringValue } from 'utils/AnnualReport/cell';
import { useDispatch } from 'react-redux';
import { updateAnnualReportField } from 'redux/actions/AnnualReportView/actions';
import MultilineField from '../UI/MultilineField';
import Heading from '../../../UI/Heading';
import ResetContentButton from '../../../UI/ResetContent/ResetContentButton';
import EditingIconButton from '../../../UI/EditingIconButton';

const StyledRadioGroup = styled(RadioGroup)<WithTheme>`
  margin-left: -${props => props.theme.spacing(2)}px;
`;

const LeftAlignedCell = styled(Cell)`
  text-align: left;
`;

const InlineCell = styled(Cell)`
  display: inline;
`;

const PaymentDateWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content min-content;
  align-items: center;
`;

const Label = styled(Typography)<WithTheme>`
  color: ${props => props.theme.palette.text.secondary};
  min-width: 5rem;
`;

const GridTable = styled.div<WithTheme>`
  width: min-content;
  display: grid;
  gap: ${props => props.theme.spacing(2)}px;
  grid-template-columns: 20rem 20rem;
  margin: ${props => props.theme.spacing(2)}px 0;
`;

interface ProfitProposalProps {
  id: string;
  reportPart: ProfitProposalType;
  print: boolean;
}

const ProfitProposal = ({ id, reportPart, print }: ProfitProposalProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const dividend = reportPart.dividend.value;
  const [editing, setEditing] = useState(false);

  const setPaymentDateType = type => {
    dispatch(updateAnnualReportField(`${id}.paymentDateType`, type));
  };

  const setPaymentDate = (date, value) => {
    if (date.isValid()) {
      dispatch(updateAnnualReportField(`${id}.paymentDate`, value));
    }
  };

  const title = intl.formatMessage({
    id: 'annualReport.menu.managementReport.profitProposal',
  });

  if (print) {
    return (
      <>
        <Heading title={title} id="profitProposal" />
        <Typography>
          {intl.formatMessage({
            id: 'annualReport.managementReport.profitProposal.dividend.1',
          })}
          <InlineCell id={`${id}.dividend`} cell={reportPart.dividend} print />
          {intl.formatMessage({
            id: 'annualReport.managementReport.profitProposal.dividend.2',
          })}
          <InlineCell
            id={`${id}.dividendPerShare`}
            cell={reportPart.dividendPerShare}
            print
          />
          {intl.formatMessage({
            id: 'annualReport.managementReport.profitProposal.dividend.3',
          })}
        </Typography>
        {stringValue(reportPart.paymentDateType) === 'generalMeeting' && (
          <Section>
            <Typography>
              {intl.formatMessage({
                id:
                  'annualReport.managementReport.profitProposal.paymentDate.generalMeeting',
              })}
            </Typography>
          </Section>
        )}
        {stringValue(reportPart.paymentDateType) === 'date' && (
          <Section>
            <Typography>
              {intl.formatMessage(
                {
                  id:
                    'annualReport.managementReport.profitProposal.paymentDate.date',
                },
                { date: stringValue(reportPart.paymentDate) }
              )}
            </Typography>
          </Section>
        )}
        <Section>
          <MultilineField
            id={`${id}.statement`}
            field={reportPart.statement}
            print={print}
          />
        </Section>
      </>
    );
  }

  return (
    <>
      <Section>
        <Heading
          title={title}
          id="profitProposal"
          controls={
            <>
              <EditingIconButton
                value={editing}
                onClick={() => setEditing(value => !value)}
              />
              <ResetContentButton id={id} what={title} />
            </>
          }
        />

        <StyledRadioGroup value="annualReport" row>
          <FormControlLabel
            label={<span>Använd i årsredovisning</span>}
            control={<Radio value="annualReport" />}
            labelPlacement="start"
          />
          <Tooltip
            title={intl.formatMessage({
              id:
                'annualReport.managementReport.profiltProposal.separateDoc.coming',
            })}
          >
            <FormControlLabel
              disabled
              label={<span>Använd i separat dokument</span>}
              control={<Radio value="separatDokument" />}
              labelPlacement="start"
            />
          </Tooltip>
        </StyledRadioGroup>

        <GridTable>
          <div>
            <Label variant="subtitle1">
              Utdelning till aktieägare (kr per aktie)
            </Label>
            <Typography>
              <LeftAlignedCell
                id={`${id}.dividendPerShare`}
                cell={reportPart.dividendPerShare}
                editing={editing}
                print={print}
              />
            </Typography>
          </div>
          <div>
            <Label variant="subtitle1">Utdelning</Label>
            <Typography>
              <LeftAlignedCell
                id={`${id}.dividend`}
                cell={reportPart.dividend}
                editing={editing}
                print={print}
              />
            </Typography>
          </div>
          <div>
            <Label variant="subtitle1">Antal aktier</Label>
            <Typography>
              <LeftAlignedCell
                id={`${id}.shares`}
                cell={reportPart.shares}
                editing={editing}
                print={print}
              />
            </Typography>
          </div>
          <div>
            <Label variant="subtitle1">I ny räkning överföres</Label>
            <Typography>
              <LeftAlignedCell
                id={`${id}.toTransfer`}
                cell={reportPart.toTransfer}
                editing={editing}
                print={print}
              />
            </Typography>
          </div>
        </GridTable>
      </Section>
      {dividend !== undefined && dividend > 0 && (
        <Section>
          <Typography>
            Styrelsen föreslås bemyndigas att besluta om betalningsdag av
            utdelningen.
          </Typography>
          <PaymentDateWrapper>
            <Label>Utbetalas vid årsstämma</Label>
            <Radio
              checked={
                stringValue(reportPart.paymentDateType) === 'generalMeeting'
              }
              onClick={() => setPaymentDateType('generalMeeting')}
            />

            <Label>Utbetalas vid datum</Label>

            <Radio
              checked={stringValue(reportPart.paymentDateType) === 'date'}
              onClick={() => setPaymentDateType('date')}
            />
          </PaymentDateWrapper>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disabled={
                stringValue(reportPart.paymentDateType) === 'generalMeeting'
              }
              disableToolbar
              margin="dense"
              autoOk
              inputVariant="outlined"
              variant="inline"
              format="YYYY-MM-DD"
              value={null}
              inputValue={stringValue(reportPart.paymentDate)}
              onChange={setPaymentDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>

          <MultilineField
            id={`${id}.statement`}
            field={reportPart.statement}
            header={
              <Typography variant="h3" color="textSecondary">
                Yttrande från styrelsen{' '}
              </Typography>
            }
            print={print}
            editing={editing}
          />
        </Section>
      )}
    </>
  );
};

export default ProfitProposal;
