import { AddAnnualReportSectionAction } from 'redux/actions/AnnualReportView/types';
import { isAnnualReportPartKey } from 'types/AnnualReport/types';
import AnnualReportState from '../types';

const addAnnualReportSection = (
  state: AnnualReportState,
  action: AddAnnualReportSectionAction
) => {
  const { id } = action;
  if (!state.report) {
    return state;
  }
  const [part, section] = id.split('.');

  if (!isAnnualReportPartKey(part)) {
    return state;
  }

  if (!Array.isArray(state.report[part][section].sections)) {
    return state;
  }

  return {
    ...state,
    report: {
      ...state.report,
      [part]: {
        ...state.report[part],
        [section]: {
          ...state.report[part][section],
          sections: [
            ...state.report[part][section].sections,
            state.report[part][section].newSectionTemplate,
          ],
        },
      },
    },
    changes: {
      ...state.changes,
      [part]: {
        ...state.changes[part],
        [section]: {
          ...state.changes[part]?.[section],
          [state.report[part][section].sections.length]: {},
        },
      },
    },
  };
};

export default addAnnualReportSection;
