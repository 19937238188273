import groupAccounts from 'components/Views/Customer/CustomerDetails/CustomerDetailsTable/utils/groupAccounts';
import { SieData } from 'types/AccountingView/types';
import {
  generateAccountGroup,
  generateCsvRow,
  generateGroupSummary,
  generateSubGroups,
} from './helpers';

/**
 * Extract periods from sieData
 */
export const extractPeriods = (sieData: SieData | undefined): string[] => {
  const periods = sieData && sieData.periods;

  return periods || [];
};

export const DEFAULT_CSV_HEADERS = [
  'Konto',
  'Balans- och resultaträkning',
  'Ing. balans från föregående år',
  'Tot. förändring innevarande år',
  'Utg. balans innevarande år',
];

/**
 * Generate the CSV column header, composing default and period specific headers.
 */
export const extractHeaders = (periods: string[]): string[] => {
  const headers: string[] = [];

  headers.push(...DEFAULT_CSV_HEADERS);

  periods.forEach((period, i) => {
    headers.push(
      `${period} - Förändring`,
      `${period} - Utgående balans period`
    );
  });

  return headers;
};

/**
 * Extract account from sieData, in a matrix format.
 * Each row in the matrix represents a CSV row.
 */
export const extractAccounts = (
  sieData: SieData,
  periods: string[]
): string[][] => {
  const accounts: string[][] = [];

  const grouppedAccounts = groupAccounts(sieData.sieBlob, periods, periods);

  grouppedAccounts.forEach(group => {
    const { name, subGroups, summary } = group;

    const accountGroup = generateAccountGroup({ name, subGroups, periods });
    accountGroup && accounts.push(accountGroup);

    const subGroupMatrix = generateSubGroups({ subGroups, periods });
    subGroupMatrix && accounts.push(...subGroupMatrix);

    const groupSummary = generateGroupSummary({ summary });
    groupSummary && accounts.push(groupSummary);
  });

  return accounts;
};

/**
 * Generate CSV file string from sieData. UTF-8 encoding.
 */
export const generateCsvString = (sieData: SieData | undefined): string => {
  let csvString = '';

  if (!sieData) return '';

  const periods = extractPeriods(sieData);

  // generate headers
  const headers: string[] = extractHeaders(periods);

  // generate accounts
  const accounts: string[][] = extractAccounts(sieData, periods);

  // tranform headers and accounts into csv
  csvString += generateCsvRow(headers);
  accounts.forEach(account => (csvString += generateCsvRow(account)));

  return csvString;
};

/**
 * Generate a blob from string. Add BOM to fix encoding issues in Windows MS Excel.
 * https://github.com/eligrey/FileSaver.js/issues/28
 */
export const generateBlob = (csvString: string) => {
  const BOM = '\uFEFF';
  return new Blob([BOM + csvString], { type: 'text/csv;charset=utf-8' });
};

/**
 * Generate encoded blob from SieData, so it can be saved to a file.
 */
const generateCsv = (sieData: SieData | undefined): Blob => {
  const csvString = generateCsvString(sieData);
  return generateBlob(csvString);
};

export default generateCsv;
