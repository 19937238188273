import gql from 'graphql-tag';

export interface Document {
  category: string;
  name: string;
  lastModified: string;
  url: string;
}

export interface ListDocumentsData {
  listDocuments: Document[];
}

export const LIST_DOCUMENTS = gql`
  query listDocuments($customerId: String!, $year: String!) {
    listDocuments(customerId: $customerId, year: $year) {
      category
      name
      lastModified
      url
    }
  }
`;
