import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField as MuiTextField,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import styled from '@emotion/styled';
import EditField from 'components/UI/Inputs/EditField';

export const LABELS = {
  CHAIRMAN_OF_THE_BOARD: 'Styrelseordförande',
  BOARD_MEMBER: 'Styrelseledarmot',
  BOARD_ALTERNATE: 'Styrelsesuppleant',
  CEO: 'Verkställande direktör',
};

type AddOptionProps = {
  onAddOption: (newOption: string) => void;
};

const AddOption = ({ onAddOption }: AddOptionProps) => {
  const [optionLabel, setOptionLabel] = useState('');

  const onFieldChange = value => {
    setOptionLabel(value);
  };

  const onButtonClick = () => {
    onAddOption(optionLabel);
  };

  return (
    <EditField
      value={optionLabel}
      onChange={onFieldChange}
      component={({ disableUnderline, ...rest }) => (
        <MuiTextField
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={onButtonClick}>
                <Add />
              </IconButton>
            ),
          }}
          {...rest}
        />
      )}
    />
  );
};

const NewOptionWrapper = styled.div`
  .MuiFormControl-root {
    display: flex;
    width: 50%;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 4px;
  }
`;

type BoardMemberOption = {
  id: string;
  checked: boolean;
  label: string;
};

type BoardMemberTypeProps = {
  roles: string;
  onChange: (changes: string) => void;
};

const BoardMemberTypeSelection = ({
  roles,
  onChange,
}: BoardMemberTypeProps) => {
  const [options, setOptions] = React.useState<BoardMemberOption[]>([
    { id: 'option1', checked: false, label: LABELS.CHAIRMAN_OF_THE_BOARD },
    { id: 'option2', checked: false, label: LABELS.BOARD_MEMBER },
    { id: 'option3', checked: false, label: LABELS.BOARD_ALTERNATE },
    { id: 'option4', checked: false, label: LABELS.CEO },
  ]);

  useEffect(() => {
    if (roles === '') return;

    const roleLabels = roles.split('|');

    setOptions(prevOptions => {
      const newOptions: BoardMemberOption[] = [];
      roleLabels.map((newOptionLabel, index) => {
        const existingOption = prevOptions.find(
          option => option.label === newOptionLabel
        );

        if (!existingOption) {
          newOptions.push({
            id: `option${prevOptions.length + index}`,
            checked: true,
            label: newOptionLabel,
          });
        }
        return undefined;
      });

      const allOptions = [...prevOptions, ...newOptions];
      return allOptions.map(option =>
        roleLabels.includes(option.label)
          ? { ...option, checked: true }
          : option
      );
    });
  }, [roles]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = options.find(option => option.id === event.target.name);
    if (!option) return;

    const updatedOptions = options.map(existingOption =>
      existingOption.id === option.id
        ? { ...existingOption, checked: event.target.checked }
        : existingOption
    );
    setOptions(updatedOptions);

    const changes = updatedOptions
      .filter(item => item.checked)
      .map(item => item.label)
      .join('|');

    onChange(changes);
  };

  const handleAddOption = optionLabel => {
    if (optionLabel === '') return;

    setOptions([
      ...options,
      {
        id: `option${options.length + 1}`,
        checked: false,
        label: optionLabel,
      },
    ]);
  };

  return (
    <>
      <FormControl component="fieldset">
        <FormGroup>
          {options.map(option => (
            <FormControlLabel
              key={option.id}
              control={
                <Checkbox
                  checked={option.checked}
                  onChange={handleChange}
                  name={option.id}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
      </FormControl>
      <NewOptionWrapper>
        <AddOption onAddOption={handleAddOption} />
      </NewOptionWrapper>
    </>
  );
};

export default BoardMemberTypeSelection;
