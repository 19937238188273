import React from 'react';
import { useIntl } from 'react-intl';
import {
  TextField as MuiTextField,
  Typography,
  WithTheme,
} from '@material-ui/core';
import isPropValid from '@emotion/is-prop-valid';
import { default as EditFieldBase } from 'components/UI/Inputs/EditField';
import styled from '@emotion/styled';
import { updateAnnualReportField } from 'redux/actions/AnnualReportView/actions';
import { useDispatch } from 'react-redux';

const Section = styled.div<{ print: boolean } & WithTheme>`
  margin: ${props => (props.print ? '0.25rem' : '1rem')} 0;
`;

const TextFieldBase = props => {
  return <MuiTextField variant="outlined" size="small" {...props} />;
};

const MultiLineMuiTextField = props => (
  <TextFieldBase multiline rows={4} {...props} />
);

const MultiLineTextField = styled(MultiLineMuiTextField, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  width: 75%;
`;

type CustomNoteFieldProps = {
  id: string;
  value: string;
  editing?: boolean;
  print?: boolean;
};

const CustomNoteField = ({
  id,
  value,
  editing,
  print = false,
}: CustomNoteFieldProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onFieldChange = value => {
    dispatch(updateAnnualReportField(id, value));
  };

  return (
    <Section print={print}>
      <Typography variant="h3">
        {formatMessage({
          id: `annualReport.notes.custom.typeOneHeading`,
        })}
      </Typography>

      {editing ? (
        <EditFieldBase
          component={MultiLineTextField}
          value={value}
          onChange={onFieldChange}
        />
      ) : (
        <MultiLineTextField value={value} disabled />
      )}
    </Section>
  );
};

export default CustomNoteField;
