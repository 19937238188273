import { DeleteAnnualReportRow } from 'redux/actions/AnnualReportView/types';
import {
  AnnualReportTable,
  AnnualReportTableRow,
  isAnnualReportPartKey,
  RowChange,
  TableChange,
  UpdateRowChange,
  DeleteRowChange,
} from 'types/AnnualReport/types';
import AnnualReportState from '../types';
import updateRow from './helpers/updateRow';
import updateTable from './helpers/updateTable';
import { addItem } from './helpers/util';

const deleteRowChange = (
  rows: RowChange[] | undefined,
  id: string
): RowChange[] => {
  if (!rows) {
    return [{ type: 'delete', id }];
  }
  const deleteChange: DeleteRowChange = { type: 'delete', id };
  const changes = rows.filter(r => r.id === id);
  if (changes.length > 0) {
    if (changes[0].type === 'add') {
      // If the first change is add, then user added the row and we can delete every trace of it.
      return rows.filter(r => r.id !== id);
    }
    return addItem(
      rows.filter(r => r.id !== id),
      deleteChange
    );
  }

  return addItem(rows, deleteChange);
};

const deleteAnnualReportRow = (
  state: AnnualReportState,
  action: DeleteAnnualReportRow
) => {
  const { id } = action;
  if (!state.report) {
    return state;
  }
  const [part, section, table, ...subId] = id.split('.');
  if (!isAnnualReportPartKey(part)) {
    return state;
  }

  const parentSubId = subId.slice(0, subId.length - 1);
  const rowId = subId[subId.length - 1];

  const deleteSubRow = (
    row: AnnualReportTableRow,
    change: UpdateRowChange
  ): [AnnualReportTableRow, UpdateRowChange] => [
    {
      ...row,
      rows: row.rows?.filter(row => row.id !== rowId),
    },
    {
      ...change,
      id: row.id,
      rows: deleteRowChange(
        change?.type === 'update' ? change?.rows : undefined,
        rowId
      ),
    },
  ];
  const deleteRow = (
    row: AnnualReportTable,
    change: TableChange
  ): [AnnualReportTable, TableChange] => [
    {
      ...row,
      rows: row.rows?.filter(row => row.id !== rowId),
    },
    {
      ...change,
      rows: deleteRowChange(change?.rows, rowId),
    },
  ];

  if (parentSubId.length > 0) {
    return updateRow(state, part, section, table, parentSubId, deleteSubRow);
  }
  return updateTable(state, part, section, table, deleteRow);
};

export default deleteAnnualReportRow;
