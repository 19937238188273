import {
  SET_USER_LOGGED_IN,
  SET_USER_ATTRIBUTES,
  USER_LOG_OUT,
  USER_IS_AUTHENTICATING,
  USER_NOT_AUTHENTICATING,
  SET_USER_FIRST_LOGIN,
} from '../../actionsTypes';
import { UserState } from './types';

const initialUserState = {
  loggedIn: false,
  isAuthenticating: true,
  'custom:organisationId': undefined,
  firstLogin: false,
};

export default (state: UserState = initialUserState, action): UserState => {
  switch (action.type) {
    case SET_USER_LOGGED_IN:
      return {
        ...state,
        loggedIn: true,
        isAuthenticating: false,
      };
    case SET_USER_ATTRIBUTES:
      return {
        ...state,
        // 'custom:organisationId': undefined,
        ...action.userAttributes,
      };
    case USER_LOG_OUT:
      return {
        ...initialUserState,
        isAuthenticating: false,
      };
    case USER_IS_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: true,
      };
    case USER_NOT_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: false,
      };
    case SET_USER_FIRST_LOGIN:
      return {
        ...state,
        firstLogin: true,
      };

    default:
      return state;
  }
};
