import React from 'react';
import ConfirmationCertificatePreview from './Parts/ConfirmationCertificate/ConfirmationCertificatePreview';
import Page from './UI/Print/Page';

const ConfirmationCertificatePrint = () => (
  <Page>
    <ConfirmationCertificatePreview />
  </Page>
);

export default ConfirmationCertificatePrint;
