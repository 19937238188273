import styled from '@emotion/styled';
import { WithTheme } from '@material-ui/core';

const StringInput = styled.input<WithTheme>`
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  width: 100%;
  min-width: 6rem;
  outline: none;

  text-align: inherit;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  padding: ${props => props.theme.spacing(1)}px;
  height: auto;
  border: 1px solid ${props => props.theme.palette.grey[300]};
  :hover {
    border: 1px solid ${props => props.theme.palette.grey[400]};
  }
  :focus {
    border 1px solid ${props => props.theme.palette.primary.main};
  }
`;

export default StringInput;
