import React from 'react';
import {
  TextField as MuiTextField,
  Typography,
  WithTheme,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import isPropValid from '@emotion/is-prop-valid';
import { default as EditFieldBase } from 'components/UI/Inputs/EditField';
import styled from '@emotion/styled';
import { updateAnnualReportField } from 'redux/actions/AnnualReportView/actions';
import { useDispatch } from 'react-redux';

const Section = styled.div<{ print: boolean } & WithTheme>`
  margin: ${props => (props.print ? '0.25rem' : '1rem')} 0;
`;

const TextFieldBase = props => {
  return <MuiTextField variant="outlined" size="small" {...props} />;
};

const MultiLineMuiTextField = props => (
  <TextFieldBase multiline rows={4} {...props} />
);

const MultiLineTextField = styled(MultiLineMuiTextField, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  width: 100%;
`;

type CommentProps = {
  id: string;
  value: string;
  editing?: boolean;
  print?: boolean;
};

const Comment = ({ id, value, editing, print = false }: CommentProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const onFieldChange = value => {
    dispatch(updateAnnualReportField(id, value));
  };

  const Title = (
    <Typography variant="h3">
      {formatMessage({
        id: 'comment',
      })}
    </Typography>
  );

  if (print) {
    return value ? (
      <>
        {Title}
        <Typography>{value}</Typography>
      </>
    ) : null;
  }

  return (
    <Section print={print}>
      {Title}
      {editing ? (
        <EditFieldBase
          component={MultiLineTextField}
          value={value}
          onChange={onFieldChange}
        />
      ) : (
        <MultiLineTextField value={value} disabled />
      )}
    </Section>
  );
};

export default Comment;
