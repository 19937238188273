import React, { useState } from 'react';
import { Note1Section as Note1SectionType } from 'types/AnnualReport/parts/notes';
import PrincipleTable from './Note1Table';
import IncomeTaxSection from './IncomeTaxSection';
import { numberValue, stringValue } from 'utils/AnnualReport/cell';
import Comment from '../../UI/Comment';
import ToggleSection from '../../UI/ToggleSection';
import ToggleMultiSection from '../../UI/ToggleMultiSection';
import Header from '../../UI/Header';
import FieldSection from '../../UI/FieldSection';
import { Note } from '../../UI/Note';

type Note1Props = {
  print?: boolean;
} & Note1SectionType;

const Note1 = ({
  print = false,
  number,
  disclaimer,
  tangibleFixedAssets,
  receivables,
  otherAssetsProvisionsAndLiabilities,
  revenueRecognition,
  saleOfGoods,
  services,
  rents,
  interestRoyaltyAndDividens,
  intangibleAssetsGeneral,
  intangibleAssetsTable,
  intangibleAssetsProjectsClosed,
  comment,
}: Note1Props) => {
  const [editing, setEditing] = useState(false);

  return (
    <Note id="note1">
      <Header
        print={print}
        noteId="note1"
        noteNumber={numberValue(number)}
        editing={editing}
        onEditToggle={editing => {
          setEditing(!editing);
        }}
      />

      {/* disclaimer section, has no section title */}
      <FieldSection
        print={print}
        noteId="note1"
        id="disclaimer"
        value={stringValue(disclaimer) || ''}
        editing={editing}
        multiline
      />

      {/* Tangible assets section */}
      <ToggleMultiSection
        print={print}
        headingTranslationKey="note1.tangibleFixedAssets"
        toggleSections={[
          { type: 'table', section: 'note1', id: 'tangibleFixedAssets' },
        ]}
      >
        <PrincipleTable
          print={print}
          table={tangibleFixedAssets}
          tableId="notes.note1.tangibleFixedAssets"
          editing={editing}
        />
      </ToggleMultiSection>

      <ToggleSection print={print} id="receivables" section="note1">
        <FieldSection
          print={print}
          noteId="note1"
          id="receivables"
          value={stringValue(receivables) || ''}
          editing={editing}
        />
      </ToggleSection>

      {/* otherAssetsProvisionsAndLiabilities section */}
      <ToggleSection
        print={print}
        id="otherAssetsProvisionsAndLiabilities"
        section="note1"
      >
        <FieldSection
          print={print}
          noteId="note1"
          id="otherAssetsProvisionsAndLiabilities"
          value={stringValue(otherAssetsProvisionsAndLiabilities) || ''}
          editing={editing}
        />
      </ToggleSection>

      {/* revenueRecognition section */}
      <ToggleSection print={print} id="revenueRecognition" section="note1">
        <FieldSection
          print={print}
          noteId="note1"
          id="revenueRecognition"
          value={stringValue(revenueRecognition) || ''}
          editing={editing}
          multiline
        />
      </ToggleSection>

      {/* saleOfGoods section */}
      <ToggleSection print={print} id="saleOfGoods" section="note1">
        <FieldSection
          print={print}
          noteId="note1"
          id="saleOfGoods"
          value={stringValue(saleOfGoods) || ''}
          editing={editing}
          multiline
        />
      </ToggleSection>

      {/* services section */}
      <ToggleSection print={print} id="services" section="note1">
        <FieldSection
          print={print}
          noteId="note1"
          id="services"
          value={stringValue(services) || ''}
          editing={editing}
        />
      </ToggleSection>

      {/* rents section */}
      <ToggleSection print={print} id="rents" section="note1">
        <FieldSection
          print={print}
          noteId="note1"
          id="rents"
          value={stringValue(rents) || ''}
          editing={editing}
        />
      </ToggleSection>

      {/* saleOfGoods section */}
      <ToggleSection
        print={print}
        id="interestRoyaltyAndDividens"
        section="note1"
      >
        <FieldSection
          print={print}
          noteId="note1"
          id="interestRoyaltyAndDividens"
          value={stringValue(interestRoyaltyAndDividens) || ''}
          editing={editing}
          multiline
        />
      </ToggleSection>

      {/* intangibleAssets section  general*/}
      <ToggleMultiSection
        print={print}
        headingTranslationKey="note1.intangibleAssets"
        toggleSections={[
          { type: 'field', section: 'note1', id: 'intangibleAssetsGeneral' },
          { type: 'table', section: 'note1', id: 'intangibleAssetsTable' },
          {
            type: 'field',
            section: 'note1',
            id: 'intangibleAssetsProjectsClosed',
          },
        ]}
      >
        <FieldSection
          print={print}
          noteId="note1"
          id="intangibleAssetsGeneral"
          value={stringValue(intangibleAssetsGeneral) || ''}
          editing={editing}
          multiline
        />

        {/* intangibleAssets section table */}
        <PrincipleTable
          editing={editing}
          print={print}
          table={intangibleAssetsTable}
          tableId="notes.note1.intangibleAssetsTable"
        />

        {/* intangibleAssets section closed projects */}
        <FieldSection
          print={print}
          noteId="note1"
          id="intangibleAssetsProjectsClosed"
          value={stringValue(intangibleAssetsProjectsClosed) || ''}
          editing={editing}
        />
      </ToggleMultiSection>

      {/* income tax section */}
      <IncomeTaxSection print={print} id="incomeTax" editing={editing} />

      {/* Comment */}
      <Comment
        print={print}
        id="notes.note1.comment"
        editing={editing}
        value={stringValue(comment) || ''}
      />
    </Note>
  );
};

export default Note1;
