import { createNewRow } from 'redux/reducers/AnnualReportView/createNewRow';
import {
  AnnualReport,
  AnnualReportChanges,
  AnnualReportPart,
  AnnualReportPartChanges,
  AnnualReportSection,
  AnnualReportSectionArray,
  AnnualReportSectionChanges,
  AnnualReportTable,
  AnnualReportTableColumn,
  AnnualReportTableRow,
  AnnualReportTableRowGenerator,
  Cell,
  CellUpdate,
  ColumnChange,
  isField,
  isTableChange,
  RowChange,
  TableChange,
} from 'types/AnnualReport/types';
import { ReferenceError } from 'utils/References/types';
import { value } from './config/util';

/**
 * Inserts the new row at the correct place according to sortKey.
 * Assumes rows is already sorted with ascending with undefined last.
 *
 * Edits the array in-place
 */
export const insertRow = (
  rows: AnnualReportTableRow[],
  newRow: AnnualReportTableRow
): void => {
  const newRowSortKey = newRow.sortKey;
  if (newRowSortKey !== undefined) {
    const index = rows.findIndex(
      row => row.sortKey === undefined || row.sortKey > newRowSortKey
    );
    if (index !== -1) {
      rows.splice(index, 0, newRow);
    } else {
      rows.push(newRow);
    }
  } else {
    rows.push(newRow);
  }
};

export const mergeCells = (
  cells: Record<string, Cell> | undefined,
  update: Record<string, CellUpdate>
): Record<string, Cell> => {
  return Object.keys(update).reduce(
    (newCells: Record<string, Cell>, column: string): Record<string, Cell> => {
      const cellUpdate = update[column];
      if (cellUpdate) {
        switch (cellUpdate.type) {
          case 'number':
          case 'string':
            return { ...newCells, [column]: cellUpdate };
          case 'ref':
            return {
              ...newCells,
              [column]: {
                type: 'ref',
                reference: cellUpdate.reference,
                value: ReferenceError.NotResolved,
              },
            };
          case 'refs':
            return {
              ...newCells,
              [column]: {
                type: 'refs',
                references: cellUpdate.references,
                values: cellUpdate.references.map(
                  _ => ReferenceError.NotResolved
                ),
              },
            };
        }
      }
      return newCells;
    },
    cells || {}
  );
};

const applyRowsChanges = (
  baseId: string,
  newRowTemplate:
    | AnnualReportTableRow
    | AnnualReportTableRowGenerator
    | undefined,
  rows: AnnualReportTableRow[],
  changes: RowChange[]
): AnnualReportTableRow[] => {
  return changes.reduce(
    (rows, change) => {
      if (change.type === 'delete') {
        return rows.filter(row => row.id !== change.id);
      }
      if (change.type === 'update') {
        const index = rows.findIndex(row => row.id === change.id);
        if (index !== -1) {
          const row = rows[index];
          let newRow = {
            ...row,
            active:
              change.row?.active !== undefined ? change.row.active : row.active,
          };
          if (change.rows || row.rows) {
            newRow.rows =
              change.rows && row.rows
                ? applyRowsChanges(
                    `${baseId}.${change.id}`,
                    row.newRowTemplate,
                    row.rows,
                    change.rows
                  ) // TODO Check
                : row.rows;
          }
          if (change.row?.cells || row.cells) {
            newRow.cells = change.row?.cells
              ? mergeCells(row.cells, change.row.cells)
              : row.cells;
          }

          rows[index] = newRow;
        }
      }
      if (change.type === 'add') {
        if (newRowTemplate) {
          const newRow = createNewRow(
            newRowTemplate,
            baseId,
            change.id,
            change.params
          );

          if (change.rows) {
            const row: AnnualReportTableRow = {
              ...newRow,
              ...change.row,
              rows: applyRowsChanges(
                `${baseId}.${change.id}`,
                newRow?.newRowTemplate,
                (change.row || newRow).rows || [],
                change.rows
              ),
            };
            if (newRow?.newRowTemplate) {
              row.newRowTemplate = newRow.newRowTemplate;
            }
            insertRow(rows, row);
          } else {
            insertRow(rows, change.row || newRow);
          }
        }
      }
      return rows;
    },
    [...rows]
  );
};

const applyColumnChanges = (
  config: AnnualReportTableColumn[],
  changes: ColumnChange[]
): AnnualReportTableColumn[] => {
  return changes.reduce((result, change) => {
    if (change.type === 'add') {
      let newColumn: AnnualReportTableColumn = { id: change.id };
      if (change.label !== undefined) {
        newColumn.label = change.label;
      }
      return [
        ...result.slice(0, change.index),
        newColumn,
        ...result.slice(change.index),
      ];
    }
    if (change.type === 'delete') {
      return result.filter(col => col.id !== change.id);
    }
    if (change.type === 'update') {
      return result.map(col =>
        col.id === change.id ? { ...col, label: change.label } : col
      );
    }
    return result;
  }, config);
};

const applyColumnChangesToRow = (
  row: AnnualReportTableRow,
  changes: ColumnChange[]
): AnnualReportTableRow => {
  return changes.reduce((result, change) => {
    let newRow = result;
    if (newRow.cells) {
      if (change.type === 'add') {
        if (!(change.id in newRow.cells)) {
          newRow = {
            ...newRow,
            cells: {
              ...newRow.cells,
              [change.id]: change.cellType === 'string' ? value('') : value(0),
            },
          };
        }
      } else if (change.type === 'delete') {
        const newCells = { ...newRow.cells };
        delete newCells[change.id];
        newRow = { ...newRow, cells: newCells };
      }
    }
    if (newRow.rows) {
      newRow = {
        ...newRow,
        rows: applyColumnChangesToRows(newRow.rows, changes),
      };
    }
    if (typeof newRow.newRowTemplate === 'object') {
      newRow = {
        ...newRow,
        newRowTemplate: applyColumnChangesToRow(newRow.newRowTemplate, changes),
      };
    }
    return newRow;
  }, row);
};

const applyColumnChangesToRows = (
  rows: AnnualReportTableRow[],
  changes: ColumnChange[]
): AnnualReportTableRow[] => {
  return rows.map(row => applyColumnChangesToRow(row, changes));
};

const applyTableChanges = (
  tableId: string,
  config: AnnualReportTable,
  change: TableChange
): AnnualReportTable => {
  let result = config;
  if (change.columns) {
    result = {
      ...result,
      columns: applyColumnChanges(result.columns, change.columns),
    };
  }
  if (change.rows && result.rows) {
    result = {
      ...result,
      rows: applyRowsChanges(
        tableId,
        config.newRowTemplate,
        result.rows,
        change.rows
      ),
    };
  }
  if (change.columns) {
    result = {
      ...result,
      rows: applyColumnChangesToRows(result.rows, change.columns),
    };
    if (typeof result.newRowTemplate === 'object') {
      result = {
        ...result,
        newRowTemplate: applyColumnChangesToRow(
          result.newRowTemplate,
          change.columns
        ),
      };
    }
  }
  if ('active' in change) {
    result = {
      ...result,
      active: !!change.active,
    };
  }
  return result;
};

const applySectionChanges = <T extends AnnualReportSection>(
  sectionId: string,
  config: T,
  changes: AnnualReportSectionChanges<T>
): T => {
  return Object.keys(changes)
    .filter(key => config[key] || key === 'active')
    .reduce((result, key) => {
      if (isTableChange(changes[key])) {
        return {
          ...result,
          [key]: applyTableChanges(
            `${sectionId}.${key}`,
            config[key],
            changes[key]
          ),
        };
      } else if (isField(changes[key])) {
        return {
          ...result,
          [key]: { ...config[key], ...changes[key] },
        };
      } else if (key === 'active') {
        return {
          ...result,
          [key]: changes[key] !== undefined ? changes[key] : result[key],
        };
      }

      console.error(
        `Unhandled change ${JSON.stringify(changes)} to ${JSON.stringify(
          config
        )}.${key}`
      );
      return result;
    }, config);
};

const applySectionArrayChanges = <T extends AnnualReportSection>(
  sectionId: string,
  config: AnnualReportSectionArray<T>,
  changes: Record<number, AnnualReportSectionChanges<T>>
): AnnualReportSectionArray<T> => {
  return {
    ...config,
    sections: Object.keys(changes)
      .map(key => parseInt(key))
      .reduce(
        (result: (T | null)[], key: number) => {
          result[key] =
            changes[key] === null
              ? null
              : applySectionChanges(
                  `${sectionId}-${key}`,
                  config.sections[key] || config.newSectionTemplate,
                  changes[key]
                );

          return result;
        },
        [...config.sections]
      ),
  };
};

const applyPartChanges = <T extends AnnualReportPart>(
  partId: string,
  config: T,
  changes: AnnualReportPartChanges<T>
): T => {
  return Object.keys(changes)
    .filter(key => config[key])
    .reduce(
      (result, key) => ({
        ...result,
        [key]:
          'sections' in result[key]
            ? applySectionArrayChanges(
                `${partId}.${key}`,
                result[key],
                changes[key]
              )
            : applySectionChanges(
                `${partId}.${key}`,
                result[key],
                changes[key]
              ),
      }),
      config
    );
};

const applyChanges = (
  config: AnnualReport,
  changes: AnnualReportChanges
): AnnualReport => {
  return Object.keys(changes)
    .filter(key => config[key])
    .reduce(
      (result, key) => ({
        ...result,
        [key]: applyPartChanges(key, result[key], changes[key]),
      }),
      config
    );
};

export default applyChanges;
