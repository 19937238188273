import React from 'react';
import { accountStatus } from 'utils/accountGrouper';
import styled from '@emotion/styled';
import { getStatusColor } from 'utils';
import { WithTheme } from '@material-ui/core';

interface ShapeElementProps {
  background: string;
  type?: string;
}

const ShapeElement = styled.div<WithTheme & ShapeElementProps>`
  background: ${props => props.background};
  height: 8px;
  border-radius: ${props => props.theme.spacing(1 / 4)}px;
  width: ${props => (props.type === 'legend' ? '1rem' : '100%')};
`;

interface AccountStatusProps {
  status: accountStatus;
  type?: string;
}

const AccountStatus = ({ status, type }: AccountStatusProps) => {
  const background = getStatusColor(status);
  return <ShapeElement background={background} type={type} />;
};

export default AccountStatus;
