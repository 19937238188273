import React, { useState } from 'react';
import { Button, WithTheme } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import styled from '@emotion/styled';
import LoadingLogo from 'components/UI/LoadingLogo';
import ConfirmationDialog from '../ConfirmationDialog';

const ButtonWrapper = styled.div`
  position: relative;
  width: max-content;
`;

const CTALink = styled.a<WithTheme>`
  display: inline-block;
  text-decoration: none;
  padding: ${props => props.theme.spacing(1)}px ${props =>
  props.theme.spacing(2)}px;
  border-radius: ${props => props.theme.shape.borderRadius}px
  text-transform: none;
  font-size: 1rem;
  color: white;
  background-color: ${props => props.theme.palette.primary.main};
`;

const ProgressInButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface CTAButtonProps extends ButtonProps {
  loading?: boolean;
}

const CTAButton = ({
  children,
  loading,
  color = 'primary',
  ...rest
}: CTAButtonProps) => (
  <ButtonWrapper>
    <Button
      {...rest}
      disableElevation
      variant="contained"
      color={color}
      disabled={loading || rest.disabled}
    >
      {children}
    </Button>
    {loading && (
      <ProgressInButtonWrapper>
        <LoadingLogo size="small" />
      </ProgressInButtonWrapper>
    )}
  </ButtonWrapper>
);

const StyledDeleteButton = styled(Button)<WithTheme>`
  background: ${props => props.theme.palette.error.main};
  color: white;

  &:hover {
    background-color: ${props => props.theme.palette.error.main};
  }
`;

const DeleteButton = ({ onClick, children, confirmMessage, ...rest }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledDeleteButton {...rest} onClick={() => setOpen(true)}>
        {children}
      </StyledDeleteButton>
      {open && (
        <ConfirmationDialog
          text={confirmMessage}
          onAccept={() => {
            setOpen(false);
            onClick();
          }}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

const PrimaryButton = ({ children, ...rest }) => (
  <Button {...rest} variant="contained" color="primary">
    {children}
  </Button>
);

const SecondaryButton = ({ children, ...rest }) => (
  <Button color="secondary" {...rest}>
    {children}
  </Button>
);

const UnstyledButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export {
  CTAButton,
  CTALink,
  PrimaryButton,
  SecondaryButton,
  DeleteButton,
  UnstyledButton,
};
