import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addAnnualReportRow } from 'redux/actions/AnnualReportView/actions';
import { NoteOneTableWithSum } from 'types/AnnualReport/parts/notes';
import { numberValue, stringValue } from 'utils/AnnualReport/cell';
import Comment from '../Comment';
import FourColumnTable from '../FourColumnTable';
import Header from '../Header';
import { Note } from '../Note';

type FourColumnSumNoteProps = {
  noteId: string;
  print?: boolean;
} & NoteOneTableWithSum;

export const FourColumnSumNote = ({
  noteId,
  active,
  number,
  table,
  sumTable,
  comment,
  print = false,
}: FourColumnSumNoteProps) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);

  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header
        noteId={noteId}
        noteNumber={numberValue(number)}
        editing={editing}
        onEditToggle={editing => {
          setEditing(!editing);
        }}
        print={print}
      />

      <Box mt={print ? 1 : 3} mb={print ? 1 : 3}>
        <FourColumnTable
          table={table}
          tableId={`notes.${noteId}.table`}
          editing={editing}
          onAddRow={() => {
            dispatch(addAnnualReportRow(`notes.${noteId}.table`));
          }}
          print={print}
        />
      </Box>

      <Box mt={print ? 1 : 3} mb={print ? 1 : 3}>
        <FourColumnTable
          table={sumTable}
          tableId={`notes.${noteId}.sumTable`}
          editing={editing}
          print={print}
        />
      </Box>

      {/* Comment */}
      <Comment
        id={`notes.${noteId}.comment`}
        editing={editing}
        value={stringValue(comment) || ''}
        print={print}
      />
    </Note>
  );
};

export default FourColumnSumNote;
