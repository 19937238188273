import React, { useState } from 'react';
import {
  LoginBox,
  ActionsWrapper,
  TextField,
  LinkWrapper,
  UnderlineLink,
} from './SharedComponents';
import { CTAButton } from 'components/UI/Buttons';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { confirmUser } from 'utils/AwsAsync';
import styled from '@emotion/styled';
import FormError from 'components/UI/Inputs/FormError';

const SuccessWrapper = styled.div`
  flex-direction: column;
`;

interface ConfirmContentProps {
  backToCreate: () => void;
  backToLogin: () => void;
  openResend: () => void;
}

const ConfirmContent = ({
  backToCreate,
  backToLogin,
  openResend,
}: ConfirmContentProps) => {
  const intl = useIntl();
  const { register, errors, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const submitConfirmation = async data => {
    try {
      setLoading(true);
      await confirmUser(data);
      setConfirmed(true);
    } catch (error) {
      setSubmitError(intl.formatMessage({ id: 'confirm.error' }));
      setLoading(false);
    }
  };

  return (
    <>
      {confirmed ? (
        <SuccessWrapper>
          <Typography variant="h5" gutterBottom>
            {intl.formatMessage({ id: 'confirm.successTitle' })}
          </Typography>
          <Typography gutterBottom>
            {intl.formatMessage({ id: 'confirm.successText' })}
          </Typography>
          <ActionsWrapper>
            <CTAButton type="submit" onClick={backToLogin}>
              {intl.formatMessage({ id: 'login' })}
            </CTAButton>
          </ActionsWrapper>
        </SuccessWrapper>
      ) : (
        <LoginBox onSubmit={handleSubmit(submitConfirmation)}>
          <Typography variant="h5">
            {intl.formatMessage({ id: 'confirm.title' })}
          </Typography>
          <Typography>
            {intl.formatMessage({ id: 'confirm.confirmationSubTitle' })}
          </Typography>

          <TextField
            variant="outlined"
            size="small"
            inputRef={register({
              required: intl.formatMessage({ id: 'form.fieldRequired' }),
            })}
            name="username"
            placeholder={intl.formatMessage({ id: 'email.placeholder' })}
            type="email"
          />

          <FormError>{errors.username?.message}</FormError>

          <TextField
            variant="outlined"
            size="small"
            inputRef={register({
              required: intl.formatMessage({ id: 'form.fieldRequired' }),
            })}
            placeholder={intl.formatMessage({ id: 'code' })}
            name="code"
          />

          <FormError>{errors.code?.message}</FormError>

          {submitError && <FormError>{submitError}</FormError>}

          <LinkWrapper>
            <UnderlineLink onClick={openResend}>
              {intl.formatMessage({ id: 'login.resendConfirmation' })}
            </UnderlineLink>
          </LinkWrapper>

          <ActionsWrapper>
            <Button onClick={backToCreate}>
              {intl.formatMessage({ id: 'forgot.back' })}
            </Button>
            <CTAButton type="submit" loading={loading}>
              {intl.formatMessage({ id: 'confirm.verify' })}
            </CTAButton>
          </ActionsWrapper>
        </LoginBox>
      )}
    </>
  );
};

export default ConfirmContent;
