/*
 This component can probably be moved up a level and use din more places
*/

import styled from '@emotion/styled';

export const Section = styled.section`
  margin: 1rem 0;
  width: max-content;
  min-width: 800px;
  max-width: 1200px;
`;

export default Section;
