import React from 'react';
import {
  Box,
  TextField as MuiTextField,
  Typography,
  WithTheme,
} from '@material-ui/core';
import isPropValid from '@emotion/is-prop-valid';
import { default as EditFieldBase } from 'components/UI/Inputs/EditField';
import styled from '@emotion/styled';
import { updateAnnualReportField } from 'redux/actions/AnnualReportView/actions';
import { useDispatch } from 'react-redux';

const Section = styled.div<{ print: boolean } & WithTheme>`
  margin: ${props => (props.print ? '0.25rem' : '1rem')} 0;
`;

const TextFieldBase = props => {
  return <MuiTextField variant="outlined" size="small" {...props} />;
};

const MultiLineMuiTextField = props => (
  <TextFieldBase multiline rows={4} {...props} />
);

const SingleLineTextField = styled(TextFieldBase, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  width: 100%;
`;

const MultiLineTextField = styled(MultiLineMuiTextField, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  width: 100%;
`;

type FieldSectionProps = {
  print?: boolean;
  noteId: string;
  id: string;
  value: string;
  editing?: boolean;
  multiline?: boolean;
};

const FieldSection = ({
  print = false,
  noteId,
  id,
  value,
  editing,
  multiline,
}: FieldSectionProps) => {
  const dispatch = useDispatch();

  const onFieldChange = value => {
    const fullId = `notes.${noteId}.${id}`;
    dispatch(updateAnnualReportField(fullId, value));
  };

  const TextFieldComponent = multiline
    ? MultiLineTextField
    : SingleLineTextField;

  if (print) {
    return (
      <Box mb={2}>
        <Typography variant="body1">{value}</Typography>
      </Box>
    );
  }

  return (
    <Section print={print}>
      {editing ? (
        <EditFieldBase
          component={TextFieldComponent}
          value={value}
          onChange={onFieldChange}
        />
      ) : (
        <TextFieldComponent value={value} disabled />
      )}
    </Section>
  );
};

export default FieldSection;
