import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Section from '../../Section';
import Heading from 'components/Views/Customer/AnnualReport/UI/Heading';
import ResetContentButton from 'components/Views/Customer/AnnualReport/UI/ResetContent/ResetContentButton';
import Accountant from './Accountant';
import MultilineField from '../../UI/MultilineField';
import { stringValue } from 'utils/AnnualReport/cell';
import EditingIconButton from 'components/Views/Customer/AnnualReport/UI/EditingIconButton';

const Activities = ({ id, print, reportPart }) => {
  const intl = useIntl();
  const [editing, setEditing] = useState(false);

  if (!reportPart) return null;

  const title = intl.formatMessage({
    id: 'annualReport.menu.managementReport.activities',
  });

  return (
    <>
      <Section>
        <Heading
          title={title}
          id="activities"
          controls={
            print ? (
              undefined
            ) : (
              <>
                <EditingIconButton
                  value={editing}
                  onClick={() => setEditing(value => !value)}
                />
                <ResetContentButton id={id} what={title} />
              </>
            )
          }
        />
        {/* GENERALLY FIELD */}
        <Typography variant="h3">
          {intl.formatMessage({
            id: 'annualReport.managementReport.activities.aboutBusiness',
          })}
        </Typography>
        <MultilineField
          id={`${id}.generally`}
          field={reportPart.generally}
          print={print}
          editing={editing}
        />
      </Section>

      {/* ACCOUNTANT FIELD */}
      <Accountant
        id={`${id}.accountant`}
        accountant={reportPart.accountant}
        print={print}
        editing={editing}
      />

      {/* REST OF NON K3 FIELDS */}

      {Object.keys(reportPart)
        .filter(
          key =>
            key !== 'active' &&
            key !== 'generally' &&
            key !== 'accountant' &&
            reportPart[key].active
        )
        .map(fieldName => {
          const show = !print || stringValue(reportPart[fieldName]);
          return (
            <Section key={fieldName}>
              {show && (
                <Typography variant="h3">
                  {intl.formatMessage({
                    id: `annualReport.managementReport.activities.${fieldName}`,
                  })}
                </Typography>
              )}
              <MultilineField
                field={reportPart[fieldName]}
                id={`${id}.${fieldName}`}
                print={print}
                editing={editing}
              />
            </Section>
          );
        })}
    </>
  );
};

export default Activities;
