import { RootState } from 'redux/reducers';
import { INIT_STATE, RESET_CUSTOMER } from '../actionsTypes';

export * from './AccountingView';
export * from './AccountListDrawer';
export * from './AnnualReportView';
export * from './User';
export * from './Customers';
export * from './Organisation';
export * from './TaxView';
export * from './CustomersOverview';
export * from './CustomerView';
export * from './Messages';

interface ResetCustomer {
  type: typeof RESET_CUSTOMER;
}

/**
 * Used for resetting the customer view
 */
export const resetCustomer = () => ({
  type: RESET_CUSTOMER,
});

interface InitStateAction {
  type: typeof INIT_STATE;
  state: Partial<RootState>;
}

export const initState = (state: Partial<RootState>) => ({
  type: INIT_STATE,
  state,
});

export type GlobalActions = ResetCustomer | InitStateAction;
