import {
  useAgoyAppQuery,
  AgoyAppQuery,
} from 'utils/AgoyAppClient/useAgoyAppQuery';

type useClientMembers = (email: string) => AgoyAppQuery<Member.MemberType[]>;

export const useClientMembers: useClientMembers = clientId => {
  return useAgoyAppQuery(
    clientId ? `/api/client/${encodeURIComponent(clientId)}/members` : undefined
  );
};

type useOrgMembers = () => AgoyAppQuery<Member.MemberType[]>;

export const useOrgMembers: useOrgMembers = () => {
  return useAgoyAppQuery(`/api/organisation/members/`);
};
