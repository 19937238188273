import React from 'react';
import { WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';

import { appHeaderHeight } from 'theme/measurements';

const Container = styled.div<WithTheme>`
  position: fixed;
  top: ${2 * appHeaderHeight}px;
  right: 0;
  height: calc(100vh - ${appHeaderHeight}px);
  overflow-y: auto;

  z-index: ${props => props.theme.zIndex.drawer};
`;

/**
 * In order to make fixed work for drawers, they must be placed outside a parent with translate.
 */
const DrawerContainer = () => <Container id="drawers" className="topBarShim" />;

export default DrawerContainer;
