import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Switch, Tooltip, Typography } from '@material-ui/core';
import { Field } from 'types/AnnualReport/field';
import { useIntl } from 'react-intl';
import Section from '../../Section';
import { AnnualReportPartKey } from 'types/AnnualReport/types';
import { toggleAnnualReportFieldActive } from 'redux/actions/AnnualReportView/actions';
import { useDispatch } from 'react-redux';
import { stringValue } from 'utils/AnnualReport/cell';
import MultilineField from '../../UI/MultilineField';

const StyledSwitch = styled(Switch)`
  align-self: center;
  margin-left: -46px;
  margin-right: 6px;
`;

// Used to center switch
const SwitchFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface AccountantProps {
  accountant: Field;
  id: string;
  print: boolean;
  editing: boolean;
}

const Accountant = ({ accountant, id, print, editing }: AccountantProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const toggleFieldActive = useCallback(
    id => {
      let [part, section, fieldName] = id.split('.');
      dispatch(
        toggleAnnualReportFieldActive(
          part as AnnualReportPartKey,
          section,
          fieldName
        )
      );
    },
    [dispatch]
  );

  const value = stringValue(accountant) || '';
  if (print) {
    return value && accountant.active ? <Typography>{value}</Typography> : null;
  }
  return (
    <Section>
      <SwitchFieldWrapper>
        <Tooltip
          title={formatMessage({
            id: 'annualReport.include.in.report',
          })}
        >
          <StyledSwitch
            size="small"
            checked={accountant.active}
            onChange={() => toggleFieldActive(`${id}.accountant`)}
          />
        </Tooltip>

        <MultilineField
          id={id}
          field={accountant}
          print={print}
          editing={editing}
        />
      </SwitchFieldWrapper>
    </Section>
  );
};

export default Accountant;
