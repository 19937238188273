import {
  SET_USER_LOGGED_IN,
  SET_USER_ATTRIBUTES,
  USER_LOG_OUT,
  USER_IS_AUTHENTICATING,
  USER_NOT_AUTHENTICATING,
  SET_ORGANISATION,
  SET_USER_FIRST_LOGIN,
} from '../../actionsTypes';
import {
  authenticateUser,
  getCurrentSession as getCurrentSessionHelper,
  getUserAttributes,
  userSignUp as doUserSignUp,
  confirmUser,
  setUserAttributes as doSetUserAttributes,
  forgetPasswordRequest,
  confirmNewPassword,
} from 'utils/AwsAsync';
import { logOut } from 'utils/AuthenticatedClient';

export const setUserLoggedIn = () => ({
  type: SET_USER_LOGGED_IN,
});

export const insertUserAttributes = userAttributes => ({
  type: SET_USER_ATTRIBUTES,
  userAttributes,
});

interface userSignUpArguments {
  username: string;
  password: string;
  given_name: string;
  family_name: string;
}

export const userSignUp = ({
  username,
  password,
  given_name,
  family_name,
}: userSignUpArguments) => {
  return async () => {
    try {
      await doUserSignUp({ username, password, given_name, family_name });
    } catch (error) {
      console.error(error);
    }
  };
};

export const userConfirmSignUp = ({ username, password, code }) => {
  return async dispatch => {
    try {
      await confirmUser({ username, code });
      dispatch(userLogIn({ username, password }));
    } catch (error) {
      console.error(error);
    }
  };
};
export const userForgotPassword = ({ username }) => {
  return async dispatch => {
    try {
      await forgetPasswordRequest({ username });
    } catch (error) {
      console.error(error);
    }
  };
};

export const userConfirmNewPassword = ({ username, code, newPassword }) => {
  return async dispatch => {
    try {
      await confirmNewPassword({ username, code, newPassword });
      dispatch(userLogIn({ username, password: newPassword }));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getCurrentSession = () => {
  return async dispatch => {
    dispatch(userIsAuthenticating());
    try {
      const { session, userAttributes } = await getCurrentSessionHelper({
        returnUserAttributes: true,
      });
      if (session !== null) {
        dispatch(insertUserAttributes(userAttributes));
        dispatch(setUserLoggedIn());
        dispatch(userNotAuthenticating());
      }
      dispatch(userNotAuthenticating());
    } catch (error) {
      console.error(error);
      dispatch(userNotAuthenticating());
    }
  };
};

export const refreshSession = () => async dispatch => {
  const { session, userAttributes } = await getCurrentSessionHelper({
    returnUserAttributes: true,
    forceRefreshSession: true,
  });
  if (session !== null) {
    dispatch(insertUserAttributes(userAttributes));
  }
};

export const userIsAuthenticating = () => {
  return {
    type: USER_IS_AUTHENTICATING,
  };
};

export const userNotAuthenticating = () => ({
  type: USER_NOT_AUTHENTICATING,
});

interface userLogInArguments {
  username: string;
  password: string;
}

export const userLogIn = ({ username, password }: userLogInArguments) => {
  return async dispatch => {
    dispatch(userIsAuthenticating());
    try {
      await authenticateUser({
        username,
        password,
      });
      const userAttributes = await getUserAttributes();
      dispatch(insertUserAttributes(userAttributes));
      dispatch(setUserLoggedIn());
      dispatch(userNotAuthenticating());
    } catch (error) {
      dispatch(userNotAuthenticating());
      throw error;
    }
  };
};

export const setOrganisation = (organisation: Organisation.OrganiationType) => {
  return async dispatch => {
    dispatch({
      type: SET_ORGANISATION,
      organisation,
    });
  };
};

export const setUserLoggedOut = () => ({
  type: USER_LOG_OUT,
});

export const userLogOut = () => {
  return dispatch => {
    logOut();
    window.location.reload();
  };
};

type setUserAttributes = {
  [index: string]: string;
};

export const setUserAttributes = (userAttributes: setUserAttributes) => {
  return async dispatch => {
    const apiCall = await doSetUserAttributes(userAttributes);
    dispatch(insertUserAttributes(userAttributes));
    return apiCall;
  };
};

export const setUserFirstLogin = () => ({
  type: SET_USER_FIRST_LOGIN,
});
