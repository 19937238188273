import { createMuiTheme, ThemeOptions } from '@material-ui/core';
import { merge } from 'lodash/fp';
import { themeOptions } from './theme';

const scaling = 0.8;

const printOptions: ThemeOptions = {
  spacing: 6, // default spacing of MUI is 8, so spacing(3) = 3 * 8
  typography: {
    body1: {
      fontSize: `${scaling}rem`,
    },
    subtitle1: {
      fontSize: `${scaling * 0.875}rem`,
    },
    h1: {
      fontSize: `${scaling * 2}rem`,
      marginTop: `${scaling * 2.5}rem`,
      marginBottom: `${scaling * 1.5}rem`,
    },
    h2: {
      fontSize: `${scaling * 1.75}rem`,
      marginTop: `${scaling * 2.5}rem`,
      marginBottom: `${scaling * 1.5}rem`,
    },
    h3: {
      fontSize: `${scaling * 1.125}rem`,
      marginTop: `${scaling * 1.25}rem`,
      marginBottom: `${scaling * 1}rem`,
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        fontSize: `${scaling * 0.875}rem`,
        padding: `${scaling * 0.5}rem`,
      },
    },
  },
};

const printTheme = createMuiTheme(merge(themeOptions, printOptions));

export default printTheme;
