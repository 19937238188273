import React from 'react';
import { Select, MenuItem, FormHelperText } from '@material-ui/core';
import GenericSimplefied from './GenericSimplefied';
import { SelectProps } from '@material-ui/core/Select';
import { labelPlacement } from './GenericSimplefied';
import { useIntl } from 'react-intl';

interface SimplifiedSelectProps {
  dataCy?: string;
  listItems: Array<ListItem>;
  editing?: boolean;
  labelPlacement?: labelPlacement;
  width?: number;
}

const SimplifiedSelect = (props: SelectProps & SimplifiedSelectProps) => {
  const intl = useIntl();
  const { dataCy = '', listItems, editing } = props;
  const inputProps: { 'data-cy'?: string } = {};
  const strippedProps: any = { ...props };

  if (props.dataCy) {
    inputProps['data-cy'] = dataCy;
    delete strippedProps.dataCy;
  }

  if (props.labelPlacement) {
    delete strippedProps.labelPlacement;
  }

  if (strippedProps.editing) delete strippedProps.editing;
  delete strippedProps.listItems;

  const displayValue = listItems.filter(item => item.value === props.value)[0]
    ?.label;

  return (
    <GenericSimplefied
      label={strippedProps.label}
      required={strippedProps.required}
      value={displayValue}
      editing={editing}
      labelPlacement={props.labelPlacement}
      width={props.width}
    >
      <Select
        error={strippedProps.required && !props.value}
        data-cy={dataCy}
        variant="outlined"
        fullWidth={props.width ? true : false}
        defaultValue={listItems.length && listItems[0].value}
        {...strippedProps}
      >
        {listItems.map(item => (
          <MenuItem
            data-cy={dataCy ? `${dataCy}-item-${item.value}` : ''}
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {strippedProps.required && !displayValue && (
        <FormHelperText error>
          {intl.formatMessage({ id: 'form.fieldRequired' })}
        </FormHelperText>
      )}
    </GenericSimplefied>
  );
};

export default SimplifiedSelect;
