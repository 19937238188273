import packageJson from '../../package.json';
const {
  REACT_APP_USER_POOL_ID,
  REACT_APP_CLIENT_ID,
  REACT_APP_REGION,
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_USER_POOL_ENDPOINT,
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_STRIPE_API_KEY,
  REACT_APP_APP_ENDPOINT,
  REACT_APP_FORTNOX_CLIENT_ID,
  REACT_APP_COMMIT_ID,
  REACT_APP_RUNNING_ENVIRONMENT,
} = process.env;

interface appConfig {
  userPoolId: string;
  clientId: string;
  region: string;
  identityPoolId: string;
  userPoolEndpoint: string;
  graphqlEndpoint: string;
  stripeAPIKey: string;
  appEndpoint: string;
  fortnoxClientId: string;
  appCommitId: string;
  appVersion: string;
  runningEnvironment: string;
}

if (
  REACT_APP_USER_POOL_ID === undefined ||
  REACT_APP_CLIENT_ID === undefined ||
  REACT_APP_REGION === undefined ||
  REACT_APP_IDENTITY_POOL_ID === undefined ||
  REACT_APP_USER_POOL_ENDPOINT === undefined ||
  REACT_APP_GRAPHQL_ENDPOINT === undefined ||
  REACT_APP_STRIPE_API_KEY === undefined ||
  REACT_APP_APP_ENDPOINT === undefined ||
  packageJson.version === undefined
) {
  throw new Error('Environment variable missing');
}

const config: appConfig = {
  userPoolId: REACT_APP_USER_POOL_ID,
  clientId: REACT_APP_CLIENT_ID,
  region: REACT_APP_REGION,
  identityPoolId: REACT_APP_IDENTITY_POOL_ID,
  userPoolEndpoint: REACT_APP_USER_POOL_ENDPOINT,
  graphqlEndpoint: REACT_APP_GRAPHQL_ENDPOINT,
  stripeAPIKey: REACT_APP_STRIPE_API_KEY,
  appEndpoint: REACT_APP_APP_ENDPOINT,
  fortnoxClientId: REACT_APP_FORTNOX_CLIENT_ID || 'kvKaTu9TnEM4',
  appCommitId: REACT_APP_COMMIT_ID || 'undefined-id',
  appVersion: packageJson.version,
  runningEnvironment: REACT_APP_RUNNING_ENVIRONMENT || 'develop',
};

export default config;
