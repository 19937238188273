import styled from '@emotion/styled';
import { Box, TextField, Typography } from '@material-ui/core';
import EditField from 'components/UI/Inputs/EditField';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateAnnualReportField } from 'redux/actions/AnnualReportView/actions';
import { CustomNotesSection } from 'types/AnnualReport/parts/notes';
import { numberValue, stringValue } from 'utils/AnnualReport/cell';
import CustomNoteField from './CustomNoteField';
import CustomNoteTable from './CustomNoteTable';
import Heading from '../../../UI/Heading';
import { Note } from '../UI/Note';

interface CustomNoteProps {
  note: CustomNotesSection;
  noteId: string;
  print?: boolean;
}

const NoteNameField = styled(({ disableUnderline, ...rest }) => (
  <TextField size="small" variant="outlined" {...rest} />
))`
  .MuiInputBase-root {
    font-size: inherit;
    width: 100%;
  }
`;

const CustomNote = ({ note, noteId, print = false }: CustomNoteProps) => {
  const dispatch = useDispatch();

  const onChangeName = useCallback(
    name => {
      dispatch(updateAnnualReportField(`notes.${noteId}.name`, name));
    },
    [dispatch, noteId]
  );

  if (print) {
    // if the note doesn't have a number it has no references to it, so exclude from print
    if (!numberValue(note.number)) return null;

    return (
      <Note>
        <Heading
          title={`${numberValue(note.number)} ${stringValue(note.name)}`}
        />
        <Typography>{stringValue(note.typeOne)}</Typography>
        <CustomNoteTable
          headingKey="annualReport.notes.custom.typeTwoHeading"
          table={note.typeTwo}
          tableId={`notes.${noteId}.typeTwo`}
          variant="3column"
          editing={false}
          print={print}
        />
        <CustomNoteTable
          headingKey="annualReport.notes.custom.typeTwoHeading"
          table={note.typeThree}
          tableId={`notes.${noteId}.typeThree`}
          variant="6column"
          editing={false}
          print={print}
        />
      </Note>
    );
  }

  return (
    <Note id={noteId}>
      <Box display="flex" width="75%">
        <Typography variant="h2" display="inline">
          {numberValue(note.number)}
        </Typography>
        {/* This box adjusts the input field to match h2 styles above */}
        <Box ml=".5rem" mt="1.75rem" fontSize="1.75rem">
          <EditField
            value={stringValue(note.name) || ''}
            onChange={onChangeName}
            component={NoteNameField}
          />
        </Box>
      </Box>

      <CustomNoteField
        id={`notes.${noteId}.typeOne`}
        value={stringValue(note.typeOne) || ''}
        editing={true}
      />
      <Box mt={print ? 1 : 3} mb={print ? 1 : 3}>
        <CustomNoteTable
          headingKey="annualReport.notes.custom.typeTwoHeading"
          table={note.typeTwo}
          tableId={`notes.${noteId}.typeTwo`}
          variant="3column"
        />
      </Box>
      <Box mt={print ? 1 : 3} mb={print ? 1 : 3}>
        <CustomNoteTable
          headingKey="annualReport.notes.custom.typeTwoHeading"
          table={note.typeThree}
          tableId={`notes.${noteId}.typeThree`}
          variant="6column"
        />
      </Box>
    </Note>
  );
};

export default CustomNote;
