import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withTheme, WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';
import HierarchicalTable, {
  HierarchicalTableProps,
} from '../../UI/HierarchicalTable';
import {
  HierarchicalTableRowProps,
  RowLevel1,
  RowLevel2,
  RowLevel3,
} from '../../UI/HierarchicalTable/HierarchicalTableRow';
import BalanceSheetAccountRow, {
  BalanceSheetAccountRowProps,
} from './BalanceSheetAccountRow';
import { useDrag, useDrop } from 'react-dnd';
import { DraggableItemTypes } from 'Constants';
import { moveAnnualReportAccountRow } from 'redux/actions/AnnualReportView/actions';
import { Global, css } from '@emotion/core';
import { BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX } from 'utils/AnnualReport/constants';
import { AnnualReportTableRow } from 'types/AnnualReport/table';
import { referenceValue } from 'utils/AnnualReport/cell';

type BalanceSheetTableProps = Pick<
  HierarchicalTableProps,
  | 'className'
  | 'table'
  | 'tableId'
  | 'tables'
  | 'tableIds'
  | 'editing'
  | 'print'
> & {
  showAccounts: boolean;
};

const StyledHierarchicalTable = styled(HierarchicalTable)`
  // Noter
  .MuiTableHead-root .MuiTableCell-root.MuiTableCell-head:nth-of-type(2) {
    text-align: right;
    min-width: 120px;
  }

  // accounting year
  .MuiTableHead-root .MuiTableCell-root.MuiTableCell-head:nth-of-type(3) {
    text-align: right;
    min-width: 120px;
  }

  // previous accounting year
  .MuiTableHead-root .MuiTableCell-root.MuiTableCell-head:nth-of-type(4) {
    text-align: right;
    min-width: 120px;
  }
`;

// Ex. Tillgångar, never editable
const BalanceSheetRowLevel1 = (props: HierarchicalTableRowProps) => {
  return <RowLevel1 {...props} />;
};

const isRowHidden = (row: AnnualReportTableRow) => {
  return referenceValue(row.cells?.hidden) === 0;
};

// Ex. Anläggningstillgångar
const BalanceSheetRowLevel2Head = (props: HierarchicalTableRowProps) => {
  const { row } = props;

  const isHeading = row.cells && Object.keys(row.cells).length === 1;
  return <RowLevel2 {...props} heading={isHeading} />;
};

// Ex. Materiella anläggninstillgångar
const BalanceSheetRowLevel2 = (props: HierarchicalTableRowProps) => {
  const { row } = props;

  if (!props.editing && isRowHidden(row)) return null;

  return <RowLevel2 {...props} />;
};

const valueNearZero = (col: string, row: AnnualReportTableRow): boolean => {
  const value = row.cells?.[col];
  if (value && value.type === 'ref') {
    const ref = value.value;
    if (ref !== undefined && typeof ref === 'number' && Math.abs(ref) < 1.0) {
      return true;
    }
  }
  return false;
};

const hasNoteReferences = (row: AnnualReportTableRow) => {
  const value = row.cells?.['notes'];
  if (value && value.type === 'refs') {
    const refs = value.references;
    return refs.length > 0;
  }
  return false;
};

const BalanceSheetRowNonZeroLevel3 = (props: HierarchicalTableRowProps) => {
  if (
    valueNearZero('year', props.row) &&
    valueNearZero('previousYear', props.row) &&
    !hasNoteReferences(props.row)
  ) {
    return null;
  }
  return props.print ? (
    <Row3 {...props} />
  ) : (
    <BalanceSheetRowLevel3 {...props} />
  );
};

// Ex. Byggnader och mark
const BalanceSheetRowLevel3 = (props: HierarchicalTableRowProps) => {
  const rowId = `${props.baseId}.${props.row.id}`;
  const dispatch = useDispatch();
  const [{ rowIsOver, rowCanDrop }, drop] = useDrop({
    accept: DraggableItemTypes.BALANCE_SHEET_ACCOUNT_ROW,
    canDrop: (_item, monitor) => {
      const draggedItem = monitor.getItem();
      return draggedItem.baseId !== rowId;
    },
    drop: item => {
      dispatch(moveAnnualReportAccountRow((item as any).rowId, rowId));
    },
    collect: monitor => ({
      rowIsOver: !!monitor.isOver(),
      rowCanDrop: !!monitor.canDrop(),
    }),
  });

  const [{ cellIsOver, cellCanDrop }, dropCell] = useDrop({
    accept: DraggableItemTypes.BALANCE_SHEET_ACCOUNT_ROW_CELL,
    canDrop: (_item, monitor) => {
      const draggedItem = monitor.getItem();
      return draggedItem.baseId !== rowId;
    },
    drop: item => {
      const { baseId, row, moveType } = item as any;
      dispatch(
        moveAnnualReportAccountRow(`${baseId}.${row.id}`, rowId, moveType)
      );
    },
    collect: monitor => ({
      cellIsOver: !!monitor.isOver(),
      cellCanDrop: !!monitor.canDrop(),
    }),
  });

  const isOver = rowIsOver || cellIsOver;
  const canDrop = rowCanDrop || cellCanDrop;

  /* AGOY-887
  const onDelete = useCallback(() => {
    dispatch(deleteAnnualReportRow(rowId));
  }, [dispatch, rowId]);
  */

  const ref = useCallback(
    el => {
      drop(el);
      dropCell(el);
    },
    [drop, dropCell]
  );

  return (
    <Row3
      className={`${rowId.replace(/\./g, '-')}`}
      ref={ref}
      rowIdClass={`${rowId.replace(/\./g, '-')}`}
      isOver={isOver && canDrop}
      // onDelete={onDelete}
      {...props}
    />
  );
};

const Row3 = styled(RowLevel3)<
  WithTheme & { isOver?: boolean; rowIdClass?: string }
>`
  ${props =>
    props.isOver &&
    `
        background: ${props.theme.palette.accountingView.cell.ok};
        & ~ .${props.rowIdClass}-level4 {
            background: ${props.theme.palette.accountingView.cell.ok};
        }
     `};
`;

// Ex. 1211 Maskiner (account row)
const BalanceSheetRowLevel4 = withTheme(
  (props: BalanceSheetAccountRowProps & StyledComponent) => {
    const rowId = `${props.baseId}.${props.row.id}`;
    const dispatch = useDispatch();

    const [, dragRow] = useDrag({
      item: {
        type: DraggableItemTypes.BALANCE_SHEET_ACCOUNT_ROW,
        rowId,
        baseId: props.baseId,
      },
      canDrag: _monitor => {
        // splitted rows are not allowed to be dragged in its entirety
        return (
          !!props.editing &&
          !rowId.includes(BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX)
        );
      },
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    const [{ rowIsOver, rowCanDrop }, dropRow] = useDrop({
      accept: DraggableItemTypes.BALANCE_SHEET_ACCOUNT_ROW,
      canDrop: (_item, monitor) => {
        const draggedItem = monitor.getItem();
        return draggedItem.baseId !== props.baseId;
      },
      drop: item => {
        dispatch(moveAnnualReportAccountRow((item as any).rowId, props.baseId));
      },
      collect: monitor => ({
        rowIsOver: !!monitor.isOver(),
        rowCanDrop: !!monitor.canDrop(),
      }),
    });

    const [{ cellIsOver, cellCanDrop }, dropCell] = useDrop({
      accept: DraggableItemTypes.BALANCE_SHEET_ACCOUNT_ROW_CELL,
      canDrop: (_item, monitor) => {
        const draggedItem = monitor.getItem();
        return draggedItem.baseId !== props.baseId;
      },
      drop: item => {
        const { baseId, row, moveType } = item as any;
        dispatch(
          moveAnnualReportAccountRow(
            `${baseId}.${row.id}`,
            props.baseId,
            moveType
          )
        );
      },
      collect: monitor => ({
        cellIsOver: !!monitor.isOver(),
        cellCanDrop: !!monitor.canDrop(),
      }),
    });

    const rowClass = `${props.baseId.replace(/\./g, '-')}-level4`;
    const parentRowClass = `${props.baseId.replace(/\./g, '-')}`;

    const globalStyles = css`
      .${rowClass} {
        background: ${props.theme.palette.accountingView.cell.ok};
      }

      .${parentRowClass} {
        background: ${props.theme.palette.accountingView.cell.ok};
      }
    `;

    const isOver = rowIsOver || cellIsOver;
    const canDrop = rowCanDrop || cellCanDrop;

    const ref = useCallback(
      el => {
        dragRow(el);
        dropRow(el);
        dropCell(el);
      },
      [dragRow, dropRow, dropCell]
    );

    if (props.print) {
      return <AccountRow dropHover={false} className={rowClass} {...props} />;
    }

    return (
      <>
        {!props.print && isOver && canDrop && <Global styles={globalStyles} />}
        <AccountRow
          className={rowClass}
          {...props}
          ref={ref}
          dropHover={isOver && canDrop}
        />
      </>
    );
  }
);

const AccountRow = styled(BalanceSheetAccountRow)<
  WithTheme & {
    dropHover: boolean;
  }
>`
  .MuiTableCell-body:first-of-type {
    font-weight: 400;
    color: ${props => props.theme.palette.text.primary};
    padding-left: 3rem;
  }

  :hover {
    .MuiTableCell-body {
      background: ${props => props.theme.palette.grey[200]};
    }
  }
`;

/* AGOY-887
const AddRow = (props: AddRowProps) =>
  props.level === 3 ? <AddPostRow {...props} /> : null;

const AddPostRow = ({ onClick, level, parent, columns }: AddRowProps) => {
  const onCellClick = useCallback(() => onClick(), [onClick]);
  return (
    <AddTableRow level={2}>
      <TableCell>
        <div className="add" onClick={onCellClick}>
          <Button startIcon={<AddIcon />}>Lägg till rad</Button>
        </div>
      </TableCell>
      {columns.slice(1).map(column =>
        parent.newRowTemplate ? (
          <TableCell key={column.id} onClick={onCellClick}>
            <div className="add" />
          </TableCell>
        ) : (
          <TableCell key={column.id} />
        )
      )}
    </AddTableRow>
  );
};
*/

const rowComponentsWithAccounts = [
  BalanceSheetRowLevel1,
  BalanceSheetRowLevel2Head,
  BalanceSheetRowLevel2,
  BalanceSheetRowNonZeroLevel3,
  BalanceSheetRowLevel4,
];

const rowComponentsInEditingMode = [
  BalanceSheetRowLevel1,
  BalanceSheetRowLevel2Head,
  BalanceSheetRowLevel2,
  BalanceSheetRowLevel3,
  BalanceSheetRowLevel4,
];

const rowComponents = [
  BalanceSheetRowLevel1,
  BalanceSheetRowLevel2Head,
  BalanceSheetRowLevel2,
  BalanceSheetRowNonZeroLevel3,
];

const rowComponentsPrint = [
  BalanceSheetRowLevel1,
  BalanceSheetRowLevel2Head,
  BalanceSheetRowLevel2,
  BalanceSheetRowNonZeroLevel3,
];

const BalanceSheetTable = ({
  className,
  table,
  tableId,
  tables,
  tableIds,
  editing,
  print,
  showAccounts,
}: BalanceSheetTableProps) => {
  return (
    <StyledHierarchicalTable
      className={className}
      table={table}
      tableId={tableId}
      tables={tables}
      tableIds={tableIds}
      rowComponents={
        print
          ? rowComponentsPrint
          : editing
          ? rowComponentsInEditingMode
          : showAccounts
          ? rowComponentsWithAccounts
          : rowComponents
      }
      editing={editing}
      stickyHeaderOffset={0}
      // AGOY-887 disable add row
      AddRow={editing && !print ? undefined : undefined}
      print={print}
    />
  );
};

export default styled(BalanceSheetTable)<WithTheme>`
  min-width: 800px;

  .MuiTableCell-root:nth-of-type(2) {
    text-align: right;
    padding-left: ${props => props.theme.spacing(6)}px;
  }
`;
