import { ResolvedReference } from 'utils/References/types';

export interface LabelCell {
  type: 'label';
  value: string;
}

export interface FormatMessageCell {
  type: 'msg';
  id: string;
  /**
   * parameterReferences map parameter names to references, the resolved value
   * can then be used as a parameter to the format string
   */
  parameterReferences?: Record<string, string>;
  parameterValues?: Record<string, ResolvedReference>;
}

export interface ReferenceCell {
  type: 'ref';
  reference: string;
  value: ResolvedReference;
}

export interface MultiReferenceCell {
  type: 'refs';
  references: string[];
  values: ResolvedReference[];
}

export interface StringCell {
  type: 'string';
  value: string;
}

export interface NumberCell {
  type: 'number';
  value: number | undefined;
}

export type Cell =
  | LabelCell
  | ReferenceCell
  | MultiReferenceCell
  | StringCell
  | NumberCell
  | FormatMessageCell;

export function isReferenceCell(cell: Cell): cell is ReferenceCell {
  return cell.type === 'ref';
}

export function isMultiReferenceCell(cell: Cell): cell is MultiReferenceCell {
  return cell.type === 'refs';
}

export function isNumberCell(cell: Cell): cell is NumberCell {
  return cell.type === 'number';
}

export function isFormatMessageCell(cell: Cell): cell is FormatMessageCell {
  return cell.type === 'msg';
}
