import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import cloneDeep from 'lodash-es/cloneDeep';
import {
  Grid,
  WithTheme,
  Switch,
  Avatar,
  Typography,
  TextField,
  InputAdornment,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import {
  useMemberRoles,
  useAddMemberRole,
  useDeleteMemberRole,
} from 'components/Api/Admin/MemberRoles';
import { GenericModal } from 'components/Modals/GenericModal';
import Snackbar from 'components/UI/Snackbar';
import { DeleteButton } from 'components/UI';
import { ClientAccessListItem } from '../UI/UserAccessListItem';
import { DefaultContainer } from '../Parts/Container';
import CustomersState from 'redux/reducers/Customers/types';
import { useMutation } from 'react-apollo';
import { DELETE_MEMBER } from 'Graphql/Mutations/OrganisationMutations';
import { addGlobalErrorMessage } from 'redux/actions';
import { useDispatch } from 'react-redux';

const Container = styled(DefaultContainer)<WithTheme>`
  height: 100%;
  width: 85vw;
`;

const GridWrapper = styled(Grid)<WithTheme>`
  padding: ${props => props.theme.spacing(2)}px;
  height: 100%;
`;

const MemberAvatar = styled(Avatar)<WithTheme>`
  width: ${props => props.theme.spacing(20)}px;
  height: ${props => props.theme.spacing(20)}px;
`;

const SearchAndFilterSection = styled(Grid)<WithTheme>`
  margin: ${props => props.theme.spacing(2)}px 0;
`;

const ClientsSection = styled(Grid)<WithTheme>`
  padding: 0 ${props => props.theme.spacing(1)}px;
`;

// Client helpers

interface Clients {
  [clientId: string]: Customer.CustomerType & ClientAuthAttributes;
}

interface ClientAuthAttributes {
  isAuthorised?: boolean;
  isAuthorisedEditedValue: boolean;
  isVisible?: boolean;
}

/**
 * Construct the client list to be used in the popup state.
 * The authorisation for a given member over each client is also computed.
 * @param adminClients clients visible to the administrator
 * @param memberAuthorisedClients clients authorised to the selected member
 */
export const calculateAuthorisedClients = (
  adminClients: CustomersState = {},
  memberAuthorisedClients: any = {}
): Clients => {
  let authorisedClients = {};

  if (!adminClients || !memberAuthorisedClients) return authorisedClients;

  const adminClientIds = Object.keys(adminClients);
  const memberAuthorisedClientIds = Object.keys(memberAuthorisedClients);

  if (adminClientIds.length) {
    adminClientIds.forEach(adminClientId => {
      const adminClient = adminClients[adminClientId];

      const isMemberAuthorisedForClient = memberAuthorisedClientIds.includes(
        adminClientId
      );

      authorisedClients[adminClientId] = {
        ...adminClient,
        isAuthorised: isMemberAuthorisedForClient,
        isAuthorisedEditedValue: isMemberAuthorisedForClient,
        isVisible: true,
      };
    });
  }

  return authorisedClients;
};

export const resetClients = (clients: Clients): Clients => {
  let resetedClients = cloneDeep(clients);

  Object.keys(resetedClients).forEach(clientId => {
    resetedClients[clientId].isAuthorisedEditedValue =
      resetedClients[clientId].isAuthorised;
    resetedClients[clientId].isVisible = true;
  });

  return resetedClients;
};

// Filter helpers

interface Filters {
  companyName: string;
  authorisedOnly: boolean;
}

const DEFAULT_FILTERS: Filters = { companyName: '', authorisedOnly: false };

export const filterClients = ({
  clients,
  filters,
}: {
  clients: Clients;
  filters: Filters;
}): Clients => {
  const filteredClients = cloneDeep(clients);

  Object.keys(clients).forEach(clientId => {
    let isVisible = true;

    if (
      filters.companyName.length > 0 &&
      !filteredClients[clientId].name
        .toLowerCase()
        .includes(filters.companyName.toLowerCase())
    ) {
      isVisible = false;
    }
    if (
      isVisible &&
      filters.authorisedOnly &&
      !filteredClients[clientId].isAuthorisedEditedValue
    ) {
      isVisible = false;
    }

    filteredClients[clientId].isVisible = isVisible;
  });

  return filteredClients;
};

interface EditMemberProps {
  member: Member.MemberType;
  handleClose: () => void;
  onDeleteMember: (member: Member.MemberType) => void;
}

const EditMember = ({
  member,
  handleClose,
  onDeleteMember,
}: EditMemberProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // STATE
  const [filters, setFilters] = useState<Filters>(DEFAULT_FILTERS);
  const [clients, setClients] = useState<Clients>({});
  const [toast, setToast] = useState({ isOpen: false, message: '' });

  const adminClients = useSelector(state => state.customers);
  const picture = useSelector(state => state.user.picture);
  const admins = useSelector(state => state.organisation.admins);
  const userEmail = useSelector(state => state.user.email);
  const isUserAdmin = userEmail && admins ? admins.includes(userEmail) : false;

  // API
  const [memberAuthorisedClients] = useMemberRoles(member.email);
  const [addMemberRole] = useAddMemberRole();
  const [deleteMemberRole] = useDeleteMemberRole();
  const [
    deleteMember,
    { loading: deletingMember, error: deleteMemberError },
  ] = useMutation(DELETE_MEMBER, {
    variables: { email: member.email },
  });

  useEffect(() => {
    if (deleteMemberError) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [dispatch, deleteMemberError]);

  useEffect(() => {
    setClients(
      calculateAuthorisedClients(adminClients, memberAuthorisedClients)
    );
  }, [adminClients, memberAuthorisedClients]);

  const onClose = () => {
    handleClose();

    setFilters(DEFAULT_FILTERS);
  };

  const onFilterChange = (filters: Filters) => {
    setFilters(filters);
    setClients(filterClients({ clients, filters }));
  };

  const onToggleClientAuthorisation = async (clientId: string) => {
    if (!isUserAdmin) {
      setToast({ isOpen: true, message: 'Only admins can edit permissions' });
      return;
    }

    if (admins.includes(member.email)) {
      setToast({
        isOpen: true,
        message: intl.formatMessage({
          id: 'dashboard.members.edit.cannotRemoveAdmin',
        }),
      });
      return;
    }

    let operation: 'addAuthorisation' | 'deleteAuthorisation' =
      'addAuthorisation';

    try {
      if (clients[clientId].isAuthorisedEditedValue) {
        operation = 'deleteAuthorisation';
        await deleteMemberRole(member.email, clientId);
      } else {
        operation = 'addAuthorisation';
        await addMemberRole(member.email, clientId);
      }

      setClients({
        ...clients,
        [clientId]: {
          ...clients[clientId],
          isAuthorisedEditedValue: !clients[clientId].isAuthorisedEditedValue,
        },
      });
    } catch (e) {
      const message = intl.formatMessage({
        id:
          operation === 'addAuthorisation'
            ? 'dashboard.members.edit.removeAccessFailed'
            : 'dashboard.members.edit.grantAccessFailed',
      });
      setToast({ isOpen: true, message });
    }
  };

  const handleDeleteMember = async () => {
    try {
      await deleteMember();
      onDeleteMember(member);
      handleClose();
    } catch {
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  const handleCloseToast = () => {
    setToast({ ...toast, isOpen: false });
  };

  return (
    <GenericModal open={true} handleClose={onClose}>
      <div>
        <Snackbar open={toast.isOpen} onClose={handleCloseToast}>
          <Alert severity="error">{toast.message}</Alert>
        </Snackbar>
      </div>
      <Container>
        <GridWrapper container direction="column" justify="space-between">
          <Grid item>
            <Grid container item>
              <Grid container item xs={6}>
                <Grid container justify="center" item xs={6}>
                  <MemberAvatar src={picture ? picture : undefined} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h2">
                    {`${member.given_name || ''} ${member.family_name || ''}`}
                  </Typography>
                  <Typography variant="body1">{member.email}</Typography>
                  {member.phone_number && (
                    <Typography variant="body1">
                      {member.phone_number}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {isUserAdmin && (
                <Grid container item xs={6} justify="flex-end">
                  <Grid item>
                    <DeleteButton
                      variant="contained"
                      confirmMessage={intl.formatMessage(
                        { id: 'dashboard.members.delete.confirm' },
                        { email: member.email }
                      )}
                      disabled={deletingMember || userEmail === member.email}
                      disableElevation
                      onClick={handleDeleteMember}
                      color="primary"
                    >
                      Ta bort från organisation
                    </DeleteButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <SearchAndFilterSection
                container
                item
                spacing={2}
                justify="space-between"
              >
                <Grid item xs={6}>
                  <TextField
                    id="search-auth-members"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={e =>
                      onFilterChange({
                        ...filters,
                        companyName: e.target.value,
                      })
                    }
                    placeholder={intl.formatMessage({
                      id: 'dashboard.customers.search.placeHolder',
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid container justify="flex-end" item xs={6}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={filters.authorisedOnly}
                          onChange={() =>
                            onFilterChange({
                              ...filters,
                              authorisedOnly: !filters.authorisedOnly,
                            })
                          }
                          name="viewAuthorizedClientsToggle"
                        />
                      }
                      label={intl.formatMessage({
                        id: 'dashboard.members.edit.viewAuthorizedToggle',
                      })}
                    />
                  </FormGroup>
                </Grid>
              </SearchAndFilterSection>
              <ClientsSection container item spacing={2}>
                {Object.keys(clients)
                  .filter(clientId => clients[clientId].isVisible)
                  .map(clientId => (
                    <Grid key={clientId} item xs={4}>
                      <ClientAccessListItem
                        client={clients[clientId]}
                        authorized={clients[clientId].isAuthorisedEditedValue}
                        accessChangeHandler={() =>
                          onToggleClientAuthorisation(clientId)
                        }
                      />
                    </Grid>
                  ))}
              </ClientsSection>
            </Grid>
          </Grid>
        </GridWrapper>
      </Container>
    </GenericModal>
  );
};

export default EditMember;
