import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { AnnualReportTableRow } from 'types/AnnualReport/table';
import { StringCell } from 'types/AnnualReport/cell';
import styled from '@emotion/styled';
import { formatRoles } from './AccountantTitlesPreview';

const Grid = styled.div`
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 40% 40%;
  grid-template-rows: 100px;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
`;

const GridItem = styled.div`
  > p {
    margin-top: 2rem;
  }
`;

type BoardMembersPreviewProps = {
  rows: AnnualReportTableRow[];
};

type BoardMember = {
  name: string;
  roles: string;
};

const BoardMembersPreview = ({ rows }: BoardMembersPreviewProps) => {
  const [boardMembers, setBoardMembers] = useState<BoardMember[]>([]);

  useEffect(() => {
    if (!rows || rows.length === 0) return;
    const filteredMembers = rows
      .filter(row => {
        return (row?.cells?.role as StringCell).value !== '';
      })
      .map(row => {
        return {
          name: `${(row?.cells?.givenName as StringCell).value} ${
            (row?.cells?.surName as StringCell).value
          }`,
          roles: (row?.cells?.role as StringCell).value,
        };
      });

    setBoardMembers(filteredMembers);
  }, [rows]);

  return (
    <Grid>
      {boardMembers.map(member => (
        <GridItem key={member.roles}>
          <Typography>{member.name}</Typography>
          <Typography variant="subtitle1">
            {formatRoles(member.roles)}
          </Typography>
        </GridItem>
      ))}
    </Grid>
  );
};

export default BoardMembersPreview;
