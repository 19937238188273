import React from 'react';
import GenericSimplefied from './GenericSimplefied';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { GenericSimplefiedProps } from './GenericSimplefied';
import { useIntl } from 'react-intl';

interface SimplifiedTextfieldProps {
  dataCy?: string;
}

const SimplifiedTextfield = (
  props: SimplifiedTextfieldProps & GenericSimplefiedProps & TextFieldProps
) => {
  const intl = useIntl();
  const inputProps: { 'data-cy'?: string } = {};
  const { value, required, width } = props;

  const strippedProps = { ...props };
  if (props.label) delete strippedProps.label;

  if (strippedProps.editing) delete strippedProps.editing;

  if (props.dataCy) {
    inputProps['data-cy'] = strippedProps.dataCy;
    delete strippedProps.dataCy;
  }

  if (props.labelPlacement) {
    delete strippedProps.labelPlacement;
  }

  const size = props.size ? props.size : 'small';

  return (
    <GenericSimplefied {...props}>
      <TextField
        variant="outlined"
        size={size}
        inputProps={inputProps}
        fullWidth={width ? true : false}
        error={required && !props.value}
        helperText={
          required && !value && intl.formatMessage({ id: 'form.fieldRequired' })
        }
        {...strippedProps}
      />
    </GenericSimplefied>
  );
};
export default SimplifiedTextfield;
