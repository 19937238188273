import React from 'react';
import { Button } from '@material-ui/core';
import Logo from 'components/UI/Logo';

interface CustomerButtonProps {
  logoUrl?: string;
  name: string;
  handleClick: () => void;
}

const CustomerButton = ({
  logoUrl = '',
  name = '',
  handleClick,
}: CustomerButtonProps) => {
  const startIcon = (
    <Logo style={{ height: '45px', width: '45px' }} src={logoUrl} />
  );

  return (
    <Button startIcon={startIcon} onClick={handleClick}>
      {name}
    </Button>
  );
};

export default CustomerButton;
