import { UserInput } from 'types/AccountingView/types';

/**
 * Remaps the object accounts of periods to periods of accounts to be used in the table
 * @param userInput object from backend:
 * {
 *  2017-2017-09: {
 *                account1910: {saldo, comment, visited},
 *                accountXXXX :{...}
 *               },
 *  2017-routine-2017-09: {
 *                account1910: {routine},
 *                accountXXXX :{...}
 *               },
 *  2017-2018-02: {
 *                account1910: {...},
 *                accountXXXX :{...}
 *               },
 * }
 * @returns object from backend:
 * {
 *  account1910: {
 *                2017-09: {saldo, comment, visited, routine},
 *                2018-02 :{...}
 *               },
 *  accountXXXX: {...},
 * }
 *
 */
export const mapUserInputData = (userInput: {}): UserInput => {
  const listOfObjectsToObject = array => {
    return array.reduce((resultObj, currentObject) => {
      const key = Object.keys(currentObject)[0];
      const existingData = resultObj[key] || {};
      resultObj[key] = { ...existingData, ...currentObject[key] };
      return resultObj;
    }, {});
  };

  const userInputAccounts = Object.keys(userInput).flatMap(period =>
    Object.keys(userInput[period]).filter(val => {
      return val !== 'partitionKey' && val !== 'sortKey';
    })
  );

  const uniqueAccounts = new Set(userInputAccounts);

  const accountUserinput = listOfObjectsToObject(
    [...Array.from(uniqueAccounts)].map(account => ({
      [account]: listOfObjectsToObject(
        Object.keys(userInput).map(yearAndPeriod => {
          const key = yearAndPeriod.slice(-7);
          const userInputForAccount = userInput[yearAndPeriod][account] || {};
          return {
            [key]: userInputForAccount,
          };
        })
      ),
    }))
  );

  return accountUserinput;
};

/**
 * Remaps the object accounts of periods to periods of accounts to be used in the table
 * @param routinesData object from backend:
 * {
 *  account1910: {
 *                2017-09 : "Routine A",
 *                2018-02 : "Routine B"
 *               },
 * }
 * @returns object:
 * {
 *  account1910: {
 *                2017-09 : "Routine A",
 *                2018-02 : "Routine B"
 *               },
 *  accountXXXX: {...},
 * }
 *
 */
export const mapRoutinesData = (routinesData: {}): {} => {
  return routinesData;
};
