import accountPlan from 'translations/kontoplan.json';
import { AccountInformation } from '.';

export const getAccountName = (
  account: string,
  accounts: SieFile.KontoRecord[]
) => {
  const filteredAccount = accounts.filter(obj => obj.kontonr === account)[0]; //TODO: handle no AccountName
  if (!filteredAccount) return accountPlan[account] || ' '; //dynamoDb does note accept ''-> return ' '.
  return filteredAccount.kontonamn;
};

export const calculateIB = (ib: SieFile.IBRecord) => {
  const calculatedIb =
    ib.saldo !== '' && ib.saldo !== null ? parseFloat(ib.saldo) : 0;
  return calculatedIb;
};

export const getIbSaldo = (row: AccountInformation, yearNo: string): number =>
  row.yearIbs.filter(ib => ib.yearNo === yearNo).map(obj => obj.saldo)[0] || 0;
