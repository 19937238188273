import React from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, Typography, WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';
import IncomeTaxParagraph from './IncomeTaxParagraph';
import { useSelector } from 'redux/reducers';

const Section = styled.div<{ print: boolean } & WithTheme>`
  margin: ${props => (props.print ? '0.25rem' : '1rem')} 0;
`;

type IncomeTaxSectionProps = {
  id: string;
  editing?: boolean;
  print?: boolean;
};

const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActiveToggle = styled.div`
  width: 160px;
  display: flex;
  align-items: center;
`;

const IncomeTaxSection = ({
  id,
  editing,
  print = false,
}: IncomeTaxSectionProps) => {
  const { formatMessage } = useIntl();

  const active = useSelector(state => {
    const anyParagraphChecked = [
      'incomeTaxParagraph1',
      'incomeTaxParagraph2',
      'incomeTaxParagraph3',
      'incomeTaxParagraph4',
      'incomeTaxParagraph5',
    ].find(
      paragraphId => state.annualReport.report?.notes.note1[paragraphId].active
    );

    return anyParagraphChecked !== undefined;
  });

  const toggleCheckboxLabelId = active
    ? 'annualReport.notes.includedSection'
    : 'annualReport.notes.excludedSection';

  return (
    <Section print={print}>
      <SectionTitle>
        {print && !active ? null : (
          <Typography variant="h3">
            {formatMessage({
              id: `annualReport.notes.note1.${id}`,
            })}
          </Typography>
        )}
        {!print && (
          <ActiveToggle>
            <Checkbox checked={active} disabled={true} />
            <Typography>
              {formatMessage({
                id: toggleCheckboxLabelId,
              })}
            </Typography>
          </ActiveToggle>
        )}
      </SectionTitle>
      <IncomeTaxParagraph
        print={print}
        id="incomeTaxParagraph1"
        editing={editing}
      />
      <IncomeTaxParagraph
        print={print}
        id="incomeTaxParagraph2"
        editing={editing}
        rows={12}
      />
      <IncomeTaxParagraph
        print={print}
        id="incomeTaxParagraph3"
        editing={editing}
        rows={2}
      />
      <IncomeTaxParagraph
        print={print}
        id="incomeTaxParagraph4"
        editing={editing}
      />
      <IncomeTaxParagraph
        print={print}
        id="incomeTaxParagraph5"
        editing={editing}
      />
    </Section>
  );
};

export default IncomeTaxSection;
