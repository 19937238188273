import PropsOfType from 'utils/PropsOfType';
import {
  MultiReferenceCell,
  NumberCell,
  ReferenceCell,
  StringCell,
} from './cell';
import { Field } from './field';
import { AnnualReportTable, AnnualReportTableRow } from './table';
import {
  AnnualReport,
  AnnualReportPart,
  AnnualReportSection,
  AnnualReportSectionArray,
} from './types';

export interface AddRowChange {
  type: 'add';
  id: string;
  row?: AnnualReportTableRow; // deprecated, changes in added rows now come as updates after
  rows?: RowChange[];
  params?: Record<string, string>;
}

export interface DeleteRowChange {
  type: 'delete';
  id: string;
}

export type CellUpdate =
  | Omit<ReferenceCell, 'value'>
  | Omit<MultiReferenceCell, 'values'>
  | StringCell
  | NumberCell;

export interface RowUpdate {
  active: boolean;
  cells?: Record<string, CellUpdate>;
}

export interface UpdateRowChange {
  type: 'update';
  id: string;
  row?: Partial<RowUpdate>;
  rows?: RowChange[];
}

export type RowChange = AddRowChange | DeleteRowChange | UpdateRowChange;

interface AddColumnChange {
  type: 'add';
  id: string;
  label?: string;
  index: number;
  cellType: 'string' | 'number';
}

interface DeleteColumnChange {
  type: 'delete';
  id: string;
}

interface UpdateColumnChange {
  type: 'update';
  id: string;
  label?: string;
}

export type ColumnChange =
  | AddColumnChange
  | DeleteColumnChange
  | UpdateColumnChange;

export interface TableChange {
  type: 'update';
  rows?: RowChange[];
  columns?: ColumnChange[];
  active?: boolean;
}

export type AnnualReportSectionChanges<T extends AnnualReportSection> = {
  [field in PropsOfType<T, Field | AnnualReportTable>]: T[field] extends Field
    ? Field
    : TableChange;
} & {
  active?: boolean;
};

export type AnnualReportPartChanges<T extends AnnualReportPart> = {
  [section in PropsOfType<
    T,
    AnnualReportSection | AnnualReportSectionArray<AnnualReportSection>
  >]: T[section] extends AnnualReportSectionArray<AnnualReportSection>
    ? Record<
        number,
        Partial<AnnualReportSectionChanges<T[section]['sections'][0]>>
      >
    : Partial<AnnualReportSectionChanges<T[section]>> | undefined;
};

export type AnnualReportChanges = Partial<
  {
    [part in PropsOfType<AnnualReport, AnnualReportPart>]: Partial<
      AnnualReportPartChanges<AnnualReport[part]>
    >;
  }
>;

export function isRowChange(
  change: TableChange | RowChange
): change is RowChange {
  return 'id' in change;
}

export function isUpdateRowChange(
  change: RowChange
): change is UpdateRowChange {
  return change.type === 'update';
}

export function isTableChange(
  change: TableChange | Field
): change is TableChange {
  return change.type === 'update';
}

export function isField(change: TableChange | Field): change is Field {
  return change.type === 'string' || change.type === 'number';
}
