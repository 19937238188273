import gql from 'graphql-tag';

export const ADD_USER_INPUT = gql`
  mutation putUserInputData(
    $customerId: String!
    $year: String!
    $accountingYear: String!
    $month: String!
    $account: String!
    $inputData: AWSJSON
  ) {
    putUserInputData(
      customerId: $customerId
      year: $year
      accountingYear: $accountingYear
      month: $month
      account: $account
      inputData: $inputData
    ) {
      inputData
    }
  }
`;

export const ADD_USER_INPUT_ROUTINE = gql`
  mutation putRoutineData(
    $customerId: String!
    $account: String!
    $routine: AWSJSON!
  ) {
    putRoutineData(
      customerId: $customerId
      account: $account
      routine: $routine
    ) {
      routine
    }
  }
`;

export const PUT_PERIOD_DOCUMENT = gql`
  mutation putPeriodDocument(
    $clientId: String!
    $account: String!
    $year: String!
    $month: String!
    $name: String!
    $contentType: String!
    $accountingYear: String!
  ) {
    putPeriodDocument(
      clientId: $clientId
      account: $account
      year: $year
      month: $month
      name: $name
      contentType: $contentType
      accountingYear: $accountingYear
    ) {
      name
      contentType
      url
      uploadUrl
    }
  }
`;

export const DELETE_PERIOD_DOCUMENT = gql`
  mutation deletePeriodDocument(
    $clientId: String!
    $account: String!
    $year: String!
    $month: String!
    $name: String!
    $accountingYear: String!
  ) {
    deletePeriodDocument(
      clientId: $clientId
      account: $account
      year: $year
      month: $month
      name: $name
      accountingYear: $accountingYear
    )
  }
`;

export const COPY_PERIOD_DOCUMENT = gql`
  mutation copyPeriodDocument(
    $clientId: String!
    $currentAccount: String!
    $currentMonth: String!
    $currentYear: String!
    $currentAccountingYear: String!
    $name: String!
    $newAccount: String!
    $newMonth: String!
    $newYear: String!
    $newAccountingYear: String!
  ) {
    copyPeriodDocument(
      clientId: $clientId
      currentAccount: $currentAccount
      currentMonth: $currentMonth
      currentYear: $currentYear
      currentAccountingYear: $currentAccountingYear
      name: $name
      newAccount: $newAccount
      newMonth: $newMonth
      newYear: $newYear
      newAccountingYear: $newAccountingYear
    ) {
      result
    }
  }
`;

export const RENAME_PERIOD_DOCUMENT = gql`
  mutation renamePeriodDocument(
    $clientId: String!
    $account: String!
    $month: String!
    $year: String!
    $accountingYear: String!
    $oldName: String!
    $newName: String!
  ) {
    renamePeriodDocument(
      clientId: $clientId
      account: $account
      month: $month
      year: $year
      accountingYear: $accountingYear
      oldName: $oldName
      newName: $newName
    )
  }
`;
