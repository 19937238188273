import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { NoteOneTableWithSum } from 'types/AnnualReport/parts/notes';
import { numberValue, stringValue } from 'utils/AnnualReport/cell';
import Comment from '../Comment';
import Header from '../Header';
import { Note } from '../Note';
import ThreeColumnTable from '../ThreeColumnTable';

type ThreeColumnSumNoteProps = {
  noteId: string;
  rowsInGroupsOf?: number;
  print?: boolean;
} & NoteOneTableWithSum;

const ThreeColumnSumNote = ({
  noteId,
  active,
  number,
  table,
  rowsInGroupsOf,
  sumTable,
  comment,
  print = false,
}: ThreeColumnSumNoteProps) => {
  const [editing, setEditing] = useState(false);

  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header
        noteId={noteId}
        noteNumber={numberValue(number)}
        editing={editing}
        onEditToggle={editing => {
          setEditing(!editing);
        }}
        print={print}
      />

      <Box mt={print ? 1 : 3} mb={print ? 1 : 3}>
        <ThreeColumnTable
          table={table}
          tableId={`notes.${noteId}.table`}
          editing={editing}
          groupRowsInterval={rowsInGroupsOf}
          print={print}
        />
      </Box>

      <Box mt={print ? 1 : 3} mb={print ? 1 : 3}>
        <ThreeColumnTable
          table={sumTable}
          tableId={`notes.${noteId}.sumTable`}
          editing={editing}
          print={print}
        />
      </Box>

      {/* Comment */}
      <Comment
        id={`notes.${noteId}.comment`}
        editing={editing}
        value={stringValue(comment) || ''}
        print={print}
      />
    </Note>
  );
};

export default ThreeColumnSumNote;
