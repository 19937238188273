import React from 'react';
import ReactGA from 'react-ga';

interface GAContextProps {
  ga: any; // Lib doesnt export a type for the lib -_-
}

export const GAContext = React.createContext({} as GAContextProps);

export const GAProvider = ({ children }) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-172149187-1');
  }
  const value = { ga: ReactGA };
  return <GAContext.Provider value={value}>{children}</GAContext.Provider>;
};

export default GAProvider;
