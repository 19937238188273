import gql from 'graphql-tag';

export const ADD_SIE_FILE = gql`
  mutation putSie(
    $customerId: String!
    $period: String!
    $periods: [String]
    $sieBlob: [AccountInformation!]!
  ) {
    putSie(
      customerId: $customerId
      period: $period
      periods: $periods
      sieBlob: $sieBlob
    ) {
      sieBlob
    }
  }
`;
