import React, { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import range from 'lodash-es/range';
import { WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';

import { useSelector, RootState } from 'redux/reducers';
import { initState } from 'redux/actions';
import Page from './UI/Print/Page';

import AnnualReportPrint from './AnnualReportPrint';
import ConfirmationCertificatePrint from './ConfirmationCertificatePrint';
import AnnualGeneralMeetingPrint from './AnnualGeneralMeetingPrint';

const Document = styled.div<WithTheme>`
  @page {
    margin: 3cm 1cm 2cm 1cm;
  }
  background-color: white;
`;

const getEmptyPages = (search: string): number => {
  const result = /emptyPages=(\d+)/.exec(search);
  if (result && result[1]) {
    return parseInt(result[1]);
  }
  return 0;
};

const PrintedDocument = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [pageNumbers, setPageNumbers] = useState<Record<string, number>>({});

  const { loggedIn } = useSelector(state => state.user);
  const emptyPages: number = getEmptyPages(history.location.search);

  const content = (
    <Document>
      {range(emptyPages).map(p => (
        <Page key={p}>Empty page</Page>
      ))}
      <Switch>
        <Route path="/print/annualReport">
          <AnnualReportPrint pageNumbers={pageNumbers} />
        </Route>
        <Route path="/print/confirmationCertificate">
          <ConfirmationCertificatePrint />
        </Route>
        <Route path="/print/annualGeneralMeeting">
          <AnnualGeneralMeetingPrint />
        </Route>
      </Switch>
    </Document>
  );

  const setContent = (state: Partial<RootState>) => {
    if (!loggedIn) {
      // The printing service is not logged in, so only set state then to protect logged in users
      dispatch(initState(state));
    } else {
      console.error('setContent while logged in');
    }
  };

  // These functions are used by puppeteer during the printing process

  // @ts-ignore
  window.setContent = setContent;
  // @ts-ignore
  window.setPageNumbers = setPageNumbers;

  return content;
};

export default PrintedDocument;
