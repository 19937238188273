import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from 'react-apollo';
import {
  Breadcrumbs,
  Button,
  DialogActions,
  DialogContent,
  Typography,
  WithTheme,
  Grid,
} from '@material-ui/core';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { setIntegrationStatus, addCustomer } from 'redux/actions';
import { GenericModal } from '../GenericModal';
import { GET_CUSTOMER } from 'Graphql/Queries';
import { useDebounce } from 'utils';
import { parseCustomer } from 'utils/GraphqlParser';
import Steps from './Steps';
import {
  useActivateFortnox,
  useDeleteFortnoxIntegration,
  useGetFortnoxIntegrationStatus,
} from 'components/Api/Client/Integration/Fortnox';
import IntegrationCodeContainer from './IntegrationCodeContainer';

const Container = styled.div`
  min-width: 1280px;
`;

const Video = styled.video<WithTheme>`
  width: 600px;
  height: 366px;
  border: 1px solid;
  border-radius: ${props => props.theme.shape.borderRadius}px;
`;

const BottomRow = styled.div<WithTheme>`
  display: flex;
  justify-content: space-between;
  margin: ${props => props.theme.spacing(3)}px;
`;

const PriceInfo = styled.div<WithTheme>`
  color: ${props => props.theme.palette.text.secondary};
`;

const HIDDEN_INTEGRATION_CODE = '••••••••••••';

const ActivateFortnoxIntegration = ({
  customerId,
  close,
}: {
  customerId: string;
  close: () => any;
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  // STATE
  const [integrationCode, setIntegrationCode] = useState('');
  const debouncedIntegrationCode = useDebounce(integrationCode, 250);

  const { name: companyName, integrations } = useSelector(
    state => state.customers[customerId]
  );
  const integrationStatus = integrations?.fortnox;

  const isIntegrationValid =
    integrationStatus && !integrationStatus.deleted ? true : false;
  const isIntegrationDeleted =
    integrationStatus && integrationStatus.deleted ? true : false;

  // API
  const [getCustomer] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: 'network-only',
    onCompleted: async data => {
      if (data) {
        const parsedCustomer = await parseCustomer(data.getCustomer);
        if (parsedCustomer !== undefined) {
          dispatch(addCustomer(parsedCustomer));
          dispatch(
            setIntegrationStatus(customerId, 'fortnox', {
              lastModified: new Date().toUTCString(),
            })
          );
        }
      }
    },
  });

  const [
    activateFortnox,
    { loading: isActiveateIntegrationLoading, error: activateIntegrationError },
  ] = useActivateFortnox();

  const [
    getFortnoxIntegrationStatus,
    {
      loading: isGetIntegrationStatusLoading,
      error: getIntegrationStatusError,
    },
  ] = useGetFortnoxIntegrationStatus();

  const [
    deleteFortnoxIntegration,
    {
      loading: isDeleteFortnoxIntegrationLoading,
      error: deleteFortnoxIntegrationError,
    },
  ] = useDeleteFortnoxIntegration();

  // EFFECTS

  // trigger fnox integration activation, on debounced integration code
  useEffect(() => {
    const activateFnoxIntegration = async () => {
      if (integrationCode === HIDDEN_INTEGRATION_CODE || !integrationCode)
        return;

      try {
        await activateFortnox(customerId, debouncedIntegrationCode);
        dispatch(
          setIntegrationStatus(customerId, 'fortnox', {
            lastModified: new Date().toUTCString(),
          })
        );
        getCustomer({
          variables: {
            customerId,
          },
        });
      } catch (e) {
        console.error(e);
      }
    };

    activateFnoxIntegration();
  }, [debouncedIntegrationCode]); // eslint-disable-line react-hooks/exhaustive-deps

  // fetch fnox integration status on customerId change
  useEffect(() => {
    const get = async () => {
      try {
        const status = await getFortnoxIntegrationStatus(customerId);
        dispatch(setIntegrationStatus(customerId, 'fortnox', status));
      } catch (error) {
        if (error === 'Not Found') {
          dispatch(setIntegrationStatus(customerId, 'fortnox', null));
        }
      }
    };
    get();
  }, [dispatch, customerId, getFortnoxIntegrationStatus]);

  // calculate if hidden integrationCode on integration success
  useEffect(() => {
    if (isIntegrationValid) {
      setIntegrationCode(HIDDEN_INTEGRATION_CODE);
    } else {
      setIntegrationCode('');
    }
  }, [isIntegrationValid]);

  // HANDLERS
  const handleIntegrationCodeChange: ChangeEventHandler<HTMLInputElement> = async event => {
    if (event.currentTarget.value || event.currentTarget.value === '') {
      setIntegrationCode(event.currentTarget.value);
    }
  };

  const handleIntegrationDelete = async () => {
    await deleteFortnoxIntegration(customerId);
    const status = await getFortnoxIntegrationStatus(customerId);
    dispatch(setIntegrationStatus(customerId, 'fortnox', status));
  };

  return (
    <GenericModal open={true} handleClose={close} fullHeight={false}>
      <Container>
        <DialogContent>
          <Breadcrumbs>
            <Typography>{companyName}</Typography>
            <Typography>
              {formatMessage({ id: 'integration.fortnox.activate.breadcrumb' })}
            </Typography>
          </Breadcrumbs>
          <Typography variant="h2">
            {formatMessage({ id: 'integration.fortnox.activate.title' })}
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Typography>
                {formatMessage({ id: 'integration.fortnox.activate.text' })}
              </Typography>

              <Steps companyName={companyName} />

              <IntegrationCodeContainer
                integrationCode={integrationCode}
                getIntegrationStatusError={getIntegrationStatusError}
                activateIntegrationError={activateIntegrationError}
                deleteFortnoxIntegrationError={deleteFortnoxIntegrationError}
                isLoading={
                  isGetIntegrationStatusLoading ||
                  isActiveateIntegrationLoading ||
                  isDeleteFortnoxIntegrationLoading
                }
                isIntegrationValid={isIntegrationValid}
                isIntegrationDeleted={isIntegrationDeleted}
                handleIntegrationCodeChange={handleIntegrationCodeChange}
                handleIntegrationDelete={handleIntegrationDelete}
              />
            </Grid>
            <Grid item sm={6}>
              <Video
                src="https://agoy-frontend-public-files.s3-eu-west-1.amazonaws.com/FortnoxIntegrationWalkthrough.mov"
                controls
              />
            </Grid>
          </Grid>
        </DialogContent>
        <BottomRow>
          <PriceInfo>
            <FormattedHTMLMessage id="integration.fortnox.activate.priceinfo" />
          </PriceInfo>
          <DialogActions>
            <Button onClick={close}>{formatMessage({ id: 'close' })}</Button>
          </DialogActions>
        </BottomRow>
      </Container>
    </GenericModal>
  );
};

export default ActivateFortnoxIntegration;
