import React, { useContext, useEffect } from 'react';
import { GAContext } from 'Analytics/GAContext';
import { useLocation } from 'react-router-dom';

export const withGA = Component => props => {
  const { pathname } = useLocation();
  const { ga } = useContext(GAContext);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ga.pageview(pathname);
    }
  }, [pathname, ga]);

  return <Component {...props} />;
};

export default withGA;
