import debounce from 'lodash/debounce';

function debounceByKey<T extends any[]>(
  keyFn: (...args: T) => string,
  fn: (...args: T) => void,
  delay: number
) {
  const debouncers: Record<string, (...args: T) => void> = {};

  return function(...args: T) {
    const key = keyFn(...args);
    if (!debouncers[key]) {
      debouncers[key] = debounce((...args: T) => {
        delete debouncers[key];
        fn(...args);
      }, delay);
    }
    debouncers[key](...args);
  };
}

export default debounceByKey;
