import React, { useState, ChangeEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import {
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  WithTheme,
  Button,
} from '@material-ui/core';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import {
  addCustomer,
  addGlobalErrorMessage,
  setSieData,
  sieFileJustUpdated,
} from 'redux/actions';
import {
  ADD_NEW_CUSTOMER,
  ADD_SIE_FILE,
  ADD_LAMP_STATUS,
} from 'Graphql/Mutations';
import { useUploadSieFile } from 'components/Api/Client/sie';
import FileDropArea from 'components/UI/FileDropArea';
import GenericModal from 'components/Modals/GenericModal';
import {
  SaveChanges,
  SimplifiedSelect,
  SimplifiedTextfield,
} from 'components/UI';
import ActivateFortnoxIntegration from 'components/Modals/ActivateFortnoxIntegration';
import { parseCustomer } from 'utils/GraphqlParser';
import mapSieInformationToTableData from 'utils/SieParser';
import { readBuffer } from 'utils/sie-reader/sie-reader';
import { getBuffer } from 'utils/FileReader/file-reader-util';
import {
  isMatchingOrgNumbers,
  sanitizeOrgNumber,
  isValidOrgNumber,
} from 'utils/Client/client-util';
import {
  getClosingMonthOptions,
  getClosingPeriodOptions,
  getCompanyTypeOptions,
  getVatPeriodOptions,
} from '../options';
import ClientSectionWrapper from '../ClientSectionWrapper';
import CustomerContainer from '../CustomerContainer';
import { loadSieFileFnImp } from 'utils/useLoadSieFile';

interface RowContainer {
  maxWidth?: number;
  flexStyle?: string;
}

const Container = styled(CustomerContainer)`
  height: 100%;
`;

const RowContainer = styled.div<WithTheme & RowContainer>`
  display: flex;
  max-width: ${(props: RowContainer) =>
    props.maxWidth ? props.maxWidth : 'unset'};
  justify-content: ${(props: RowContainer) =>
    props.flexStyle ? props.flexStyle : 'initial'};

  > *:not(:last-child) {
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

interface InputWrapper {
  width: number;
  paddingRight?: number;
}
const InputWrapper = styled.div`
  width: ${(props: InputWrapper) => props.width}%;
`;

const Header = styled.div<WithTheme>`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.theme.spacing(4)}px;
`;

const HeaderTitle = styled(Typography)`
  margin-top: 0;
`;

const IntegrateRadioGroup = styled(RadioGroup)`
  display: block;
  text-align: right;
`;

const CustomerSieFileDropArea = styled(FileDropArea)<WithTheme>`
  padding: ${props => props.theme.spacing(2)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.palette.accountingView.cell.ok};
  border: ${props => `1px dashed ${props.theme.palette.secondary.main}`};
  border-radius: ${props => props.theme.shape.borderRadius}px;

  input {
    display: none;
  }
`;

const UploadSIEButton = styled(Button)<WithTheme & { component: any }>`
  margin-top: ${props => props.theme.spacing(2)}px;
`;

const UploadSieFileText = styled(Typography)<WithTheme>`
  margin: ${props => `${props.theme.spacing(2)}px 0`};
  padding: ${props => `0 ${props.theme.spacing(4)}px`};
`;

interface CreateCustomerProps {
  open: boolean;
  close: () => void;
}

const isSieFile = (_: File) => {
  return true;
};

const CreateCustomer = ({ open, close }: CreateCustomerProps) => {
  let orgMembers = useSelector(state => state.organisation.members);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [addSieFile] = useMutation(ADD_SIE_FILE);
  const [addLampStatus] = useMutation(ADD_LAMP_STATUS);
  const [uploadSieFile] = useUploadSieFile();

  const labelledOrgMembers = orgMembers.map(member => ({
    value: member,
    label: member,
  }));

  const closingMonthOptions = getClosingMonthOptions(intl);
  const closingPeriodOptions = getClosingPeriodOptions(intl);
  const vatPeriodOptions = getVatPeriodOptions(intl);
  const companyTypeOptions = getCompanyTypeOptions(intl);

  const [companyName, setCompanyName] = useState('');

  const [closingMonth, setClosingMonth] = useState<number | ''>('');
  const [clientManager, setClientManager] = useState(
    labelledOrgMembers[0]?.value || ''
  );
  const [closingPeriod, setClosingPeriod] = useState(''); // empty string as default 'no option selected'
  const [vatPeriod, setvatPeriod] = useState(''); // empty string as default 'no option selected'
  const [companyType, setCompanyType] = useState(companyTypeOptions[0].value);
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyOrgNumber, setCompanyOrgNumber] = useState('');
  const [companyContact, setCompanyContact] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [orgNrError, setOrgNrError] = useState(false);

  const [newCustomer, setNewCustomer] = useState<
    Customer.CustomerType | undefined
  >(undefined);

  const [sieReader, setSieReader] = useState<FileReader>();

  const [integrateWithFortnox, setIntegrateWithFortnox] = useState(false);
  const onChangeIntegrate: ChangeEventHandler<HTMLInputElement> = event => {
    setIntegrateWithFortnox(event.currentTarget.value === 'true');
  };

  const [addNewCustomer, { loading: addingNewCustomer }] = useMutation(
    ADD_NEW_CUSTOMER,
    {
      onCompleted: async data => {
        const customer = await parseCustomer(data.addCustomer);
        dispatch(addCustomer(customer));

        setNewCustomer(customer);

        loadSieFileFnImp({
          deps: {
            reader: sieReader,
            setLoading: () => null,
            setError: (e: Error.GenericError | undefined) => {
              if (e?.name === 'WrongOrgNr') {
                dispatch(addGlobalErrorMessage('sie.orgNr.error.global'));
              } else {
                dispatch(addGlobalErrorMessage('sie.unknown.error.title'));
              }
            },
            setData: () => null,
            setSieData,
            getBuffer,
            readBuffer,
            uploadSieFile,
            addSieFile,
            addLampStatus,
            sieFileJustUpdated,
            mapSieInformationToTableData,
            isMatchingOrgNumbers,
            dispatch,
          },
          customer,
        });

        if (!integrateWithFortnox) {
          close();
        }
      },
    }
  );

  const handleSubmit = e => {
    e.preventDefault();

    if (
      !companyName ||
      !companyOrgNumber ||
      !closingMonth ||
      !closingPeriod ||
      !vatPeriod ||
      !isValidOrgNumber(sanitizeOrgNumber(companyOrgNumber))
    ) {
      return;
    }

    const additionalFields = {
      address: companyAddress,
      orgNumber: sanitizeOrgNumber(companyOrgNumber),
      contact: companyContact,
      number: companyNumber,
      email: companyEmail,
    };

    const cleanedAdditionalFields = Object.entries(additionalFields).reduce(
      (tot, [key, value]) => {
        return value !== '' && value !== null ? { ...tot, [key]: value } : tot;
      },
      {}
    );

    addNewCustomer({
      variables: {
        name: companyName,
        type: companyType,
        status: 'active',
        closingMonth: closingMonth,
        closingPeriod: closingPeriod,
        manager: clientManager,
        vatPeriod: vatPeriod,
        ...cleanedAdditionalFields,
      },
    });
  };

  const onCloseIntegrateFortnox = () => {
    setIntegrateWithFortnox(false);
    setNewCustomer(undefined);
    close();
  };

  const onAttachFile = (file: File | undefined) => {
    if (!file) return;

    const onLoad = (event: ProgressEvent<FileReader>) => {
      if (event.target) {
        try {
          const ab = getBuffer(reader);
          const sieObj = readBuffer(ab);

          if (sieObj.list('orgnr')[0]?.orgnr) {
            setCompanyOrgNumber(sieObj.list('orgnr')[0].orgnr);
          }

          setCompanyName(sieObj.list('fnamn')[0].företagsnamn);

          const { kontakt, utdelningsadr, postadr, tel } = sieObj.list(
            'adress'
          )[0];

          setCompanyContact(kontakt);
          setCompanyAddress(`${utdelningsadr}, ${postadr}`);
          setCompanyNumber(tel);

          const rarEnd = sieObj.list('rar')[0].slut; // format yyyymmdd
          const accountingYearEndMonth = parseInt(rarEnd.substring(4, 6), 10);
          setClosingMonth(accountingYearEndMonth);

          // store reader so after user has created customer we can use
          // the reader to create an accounting year
          setSieReader(reader);
        } catch (error) {
          dispatch(addGlobalErrorMessage('sie.unknown.error.title'));
        }
      }
    };

    const reader = new FileReader();
    reader.addEventListener('load', onLoad, false);
    reader.readAsArrayBuffer(file);
  };

  const orgNrOnBlur = () => {
    if (!isValidOrgNumber(companyOrgNumber)) {
      setOrgNrError(true);
    } else {
      setOrgNrError(false);
    }
  };

  return (
    <>
      <GenericModal open={open} handleClose={close}>
        <Container>
          <Header>
            <HeaderTitle variant="h2">
              {intl.formatMessage({ id: 'client.create.title' })}
            </HeaderTitle>
            <div>
              <Typography variant="h5">
                {intl.formatMessage({ id: 'client.integrate.fortnox' })}
              </Typography>
              <IntegrateRadioGroup
                value={integrateWithFortnox}
                onChange={onChangeIntegrate}
              >
                <FormControlLabel
                  control={
                    <Radio
                      value={false}
                      color={integrateWithFortnox ? 'default' : 'primary'}
                    />
                  }
                  label={intl.formatMessage({
                    id: 'client.integrate.fortnox.later',
                  })}
                  labelPlacement="start"
                />
                <FormControlLabel
                  control={
                    <Radio
                      value={true}
                      color={integrateWithFortnox ? 'primary' : 'default'}
                    />
                  }
                  label={intl.formatMessage({
                    id: 'client.integrate.fortnox.now',
                  })}
                  labelPlacement="start"
                />
              </IntegrateRadioGroup>
            </div>
          </Header>
          <ClientSectionWrapper
            label={intl.formatMessage({ id: 'client.createWithSIE' })}
            fullWidth={true}
          >
            <CustomerSieFileDropArea
              onDrop={onAttachFile}
              isValidFile={isSieFile}
              onInvalidFileType={() => {}}
            >
              <UploadSIEButton
                variant="contained"
                color="secondary"
                disableElevation
                component="label"
                id="onboarding-upload-file"
              >
                {intl.formatMessage({ id: 'upload.file' })}
                <input
                  data-cy="upload-file"
                  type="file"
                  onChange={e => {
                    if (e.target.files) onAttachFile(e.target.files[0]);
                  }}
                />
              </UploadSIEButton>

              <UploadSieFileText>
                {intl.formatMessage({ id: 'client.dragAndDropSIE' })}
              </UploadSieFileText>
            </CustomerSieFileDropArea>
          </ClientSectionWrapper>

          <ClientSectionWrapper
            label={intl.formatMessage({ id: 'client.createManually' })}
            fullWidth={true}
          >
            <RowContainer>
              <InputWrapper width={66} paddingRight={5}>
                <SimplifiedTextfield
                  editing={true}
                  labelPlacement="top"
                  value={companyName}
                  required
                  label={intl.formatMessage({ id: 'client.name' })}
                  onChange={e => setCompanyName(e.target.value)}
                  dataCy="create-client-name"
                />
              </InputWrapper>
              <InputWrapper width={33}>
                <SimplifiedSelect
                  editing={true}
                  labelPlacement="top"
                  label={intl.formatMessage({ id: 'client.type' })}
                  listItems={companyTypeOptions}
                  onChange={e => {
                    setCompanyType(e.target.value as string);
                  }}
                />
              </InputWrapper>
            </RowContainer>
            <RowContainer>
              <InputWrapper width={33}>
                <SimplifiedSelect
                  displayEmpty
                  required
                  value={closingMonth}
                  renderValue={(value: unknown) => {
                    const selectedMonth = value as number;

                    if (selectedMonth) {
                      return closingMonthOptions[selectedMonth - 1].label;
                    }

                    return intl.formatMessage({
                      id: 'client.closingMonthPlaceholder',
                    });
                  }}
                  editing={true}
                  labelPlacement="top"
                  label={intl.formatMessage({ id: 'client.closingMonth' })}
                  listItems={closingMonthOptions}
                  onChange={e => setClosingMonth(e.target.value as number)}
                />
              </InputWrapper>
              <InputWrapper width={33}>
                <SimplifiedSelect
                  displayEmpty
                  required
                  value={closingPeriod}
                  renderValue={(value: unknown) => {
                    const selectedPeriod = value as string;
                    if (selectedPeriod && selectedPeriod !== '') {
                      return closingPeriodOptions.find(
                        item => item.value === selectedPeriod
                      )?.label;
                    }

                    return intl.formatMessage({
                      id: 'client.closingPeriodPlaceholder',
                    });
                  }}
                  editing={true}
                  labelPlacement="top"
                  label={intl.formatMessage({ id: 'client.closingPeriod' })}
                  listItems={closingPeriodOptions}
                  onChange={e => setClosingPeriod(e.target.value as string)}
                />
              </InputWrapper>
              <InputWrapper width={33}>
                <SimplifiedSelect
                  displayEmpty
                  required
                  value={vatPeriod}
                  renderValue={(value: unknown) => {
                    const selectedVatPeriod = value as string;
                    if (selectedVatPeriod && selectedVatPeriod !== '') {
                      return vatPeriodOptions.find(
                        item => item.value === selectedVatPeriod
                      )?.label;
                    }

                    return intl.formatMessage({
                      id: 'client.vatPeriodPlaceholder',
                    });
                  }}
                  editing={true}
                  labelPlacement="top"
                  label={intl.formatMessage({ id: 'client.vatPeriod' })}
                  listItems={vatPeriodOptions}
                  onChange={e => setvatPeriod(e.target.value as string)}
                />
              </InputWrapper>
            </RowContainer>
            <RowContainer>
              <InputWrapper width={66}>
                <SimplifiedSelect
                  editing={true}
                  labelPlacement="top"
                  label={intl.formatMessage({ id: 'client.manager' })}
                  listItems={labelledOrgMembers}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                    setClientManager(event.target.value as string)
                  }
                />
              </InputWrapper>
            </RowContainer>
          </ClientSectionWrapper>
          <ClientSectionWrapper
            label={intl.formatMessage({
              id: 'client.additional.information',
            })}
            fullWidth={true}
          >
            <RowContainer>
              <InputWrapper width={66}>
                <SimplifiedTextfield
                  editing={true}
                  labelPlacement="top"
                  value={companyAddress}
                  label={intl.formatMessage({ id: 'client.address' })}
                  onChange={e => setCompanyAddress(e.target.value)}
                />
              </InputWrapper>
            </RowContainer>
            <RowContainer>
              <InputWrapper width={22}>
                <SimplifiedTextfield
                  editing={true}
                  labelPlacement="top"
                  value={sanitizeOrgNumber(companyOrgNumber)}
                  required
                  label={intl.formatMessage({ id: 'client.orgnumber' })}
                  onChange={e => setCompanyOrgNumber(e.target.value)}
                  dataCy="create-client-orgnumber"
                  error={orgNrError}
                  helperText={
                    orgNrError && intl.formatMessage({ id: 'orgNr.error' })
                  }
                  onBlur={orgNrOnBlur}
                />
              </InputWrapper>
              <InputWrapper width={22}>
                <SimplifiedTextfield
                  editing={true}
                  labelPlacement="top"
                  value={companyNumber}
                  label={intl.formatMessage({ id: 'client.number' })}
                  onChange={e => setCompanyNumber(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper width={22}>
                <SimplifiedTextfield
                  editing={true}
                  labelPlacement="top"
                  value={companyContact}
                  label={intl.formatMessage({ id: 'client.contact' })}
                  onChange={e => setCompanyContact(e.target.value)}
                />
              </InputWrapper>
            </RowContainer>
            <RowContainer>
              <InputWrapper width={66}>
                <SimplifiedTextfield
                  labelPlacement="top"
                  editing={true}
                  value={companyEmail}
                  label={intl.formatMessage({ id: 'client.email' })}
                  onChange={e => setCompanyEmail(e.target.value)}
                />
              </InputWrapper>
            </RowContainer>
          </ClientSectionWrapper>
          <SaveChanges
            saveLabel={intl.formatMessage({ id: 'client.create' })}
            discardLabel={intl.formatMessage({ id: 'cancel' })}
            discardChanges={close}
            saveChanges={handleSubmit}
            savingChanges={addingNewCustomer}
          />
        </Container>
      </GenericModal>
      {integrateWithFortnox && newCustomer && (
        <ActivateFortnoxIntegration
          customerId={newCustomer.id}
          close={onCloseIntegrateFortnox}
        />
      )}
    </>
  );
};

export default CreateCustomer;
