import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from '@emotion/styled';
import { ButtonBase, WithTheme, ButtonBaseProps } from '@material-ui/core';

const StyledButton = styled(ButtonBase)<WithTheme>`
  margin-left: ${props => props.theme.spacing(2)}px;
  color: ${props => props.theme.palette.grey[700]};
  &:hover {
    color: ${props => props.theme.palette.error.main};
  }
`;

type DeleteButtonProps = Pick<ButtonBaseProps, 'onClick' | 'className'>;

const DeleteButton = ({ onClick, className }: DeleteButtonProps) => (
  <StyledButton onClick={onClick} className={className}>
    <DeleteIcon />
  </StyledButton>
);

export default DeleteButton;
