import {
  ParticularSalaryTax,
  TaxCalculationRow,
  TaxView,
  TaxCalculationContext,
} from './types';
import { calculateTaxTable, calculateRow } from './calculateTaxTable';
import { shallowCompare } from 'utils/shallowCompare';
import { updateContext } from './context';

const calculateParticularSalaryTaxFromSum = (
  context: TaxCalculationContext,
  sum: TaxCalculationRow,
  previousResult: TaxCalculationRow | undefined
): TaxCalculationRow => {
  const taxFromSum =
    sum.value === undefined ? undefined : context.config.salaryTax * sum.value;

  if (!previousResult) {
    return {
      id: 'particularSalaryTaxFromSum',
      value: taxFromSum,
    };
  }

  if (taxFromSum === previousResult.value) {
    return previousResult;
  }
  return { ...previousResult, value: taxFromSum };
};

const calculateToBook = (
  tax: TaxCalculationRow,
  alreadyBooked: TaxCalculationRow,
  previousRow: TaxCalculationRow
): TaxCalculationRow => {
  const toBook = {
    ...previousRow,
    value: (tax.value || 0) - (alreadyBooked.value || 0),
  };

  if (toBook.value === previousRow.value) {
    return previousRow;
  }

  return toBook;
};

export const resolveParticularSalaryTax = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): ParticularSalaryTax => {
  const taxTable = calculateTaxTable(
    taxView?.particularSalaryTax || context.config.particularSalaryTax,
    context
  );
  const particularSalaryTaxFromSum = updateContext(
    context,
    calculateParticularSalaryTaxFromSum(
      context,
      taxTable.sum,
      taxView?.particularSalaryTax?.particularSalaryTaxFromSum
    )
  );
  const alreadyBookedParticularSalaryTax = calculateRow(
    taxView?.particularSalaryTax?.alreadyBookedParticularSalaryTax ||
      context.config.alreadyBookedParticularSalaryTax,
    context
  );

  const particularSalaryTaxToBook = updateContext(
    context,
    calculateToBook(
      particularSalaryTaxFromSum,
      alreadyBookedParticularSalaryTax,
      taxView?.particularSalaryTax?.particularSalaryTaxToBook ||
        context.config.particularSalaryTaxToBook
    )
  );
  const toBookValue = particularSalaryTaxToBook.value || 0;
  const warningLowAmount = toBookValue < -5;
  const warningHighAmount = toBookValue > 5;

  const particularSalaryTax: ParticularSalaryTax = {
    ...taxTable,
    particularSalaryTaxFromSum,
    alreadyBookedParticularSalaryTax,
    particularSalaryTaxToBook,
    warningLowAmount,
    warningHighAmount,
  };

  if (!taxView?.particularSalaryTax) {
    return particularSalaryTax;
  }

  return shallowCompare(taxView?.particularSalaryTax, particularSalaryTax)
    ? taxView?.particularSalaryTax
    : particularSalaryTax;
};
