import React from 'react';
import { useIntl } from 'react-intl';
import { Menu, MenuItem, Button, Link, Box } from '@material-ui/core';

const HelpMenu = () => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box paddingRight={1}>
        <Button
          onClick={handleClick}
          variant="contained"
          disableElevation
          color="secondary"
          id="onboarding-help"
        >
          {intl.formatMessage({ id: 'app.help' })}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        onClose={closeMenu}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem>
          <Link
            href="https://agoyit.atlassian.net/servicedesk/customer/portal/2/topic/41c5fe26-8a2f-4c4d-a5f3-ea2b12c41896/article/557252670"
            target="_blank"
          >
            {intl.formatMessage({ id: 'app.videoinstructions' })}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            href="https://agoyit.atlassian.net/servicedesk/customer/portals"
            target="_blank"
          >
            {intl.formatMessage({ id: 'app.helpcenter' })}
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default HelpMenu;
