import React from 'react';
import Typography from '@material-ui/core/Typography';
import { WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

export type labelPlacement = 'top' | 'left';

export interface GenericSimplefiedProps {
  label?: string | null | React.ReactNode;
  required?: boolean;
  children?: React.ReactNode | React.ReactNodeArray;
  editing?: boolean;
  value?: any;
  labelPlacement?: labelPlacement;
  width?: number;
  inputRef?: React.Ref<any>;
  name?: string;
}

interface WrapperProps {
  labelPlacement: labelPlacement;
  width?: number;
}

const Wrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'labelPlacement',
})<WithTheme & WrapperProps>`
display: flex;
padding: ${props => `${props.theme.spacing(1)}px`} 0;
${props => props.width && `width: ${props.width}px;`}
${props => props.labelPlacement === 'left' && 'align-items: center;'}
${props => props.labelPlacement === 'top' && 'flex-direction: column;'}
`;

const RequiredStar = styled.span<WithTheme>`
  color: ${props => props.theme.palette.error.light};
`;

const Label = styled(Typography)`
  min-width: 180px;
`;

interface NotEditingValueProps {
  isMissing?: boolean;
}

const NotEditingValue = styled(({ isMissing, ...props }) => (
  <Typography {...props} />
))<WithTheme & NotEditingValueProps>`
  height: 43px;
  padding-top: 10px;
  color: ${props =>
    props.isMissing
      ? props.theme.palette.text.secondary
      : props.theme.palette.text.primary};
`;

const GenericSimplefied = ({
  label,
  required,
  editing,
  children,
  value,
  labelPlacement = 'left',
  width,
}: Partial<GenericSimplefiedProps>) => {
  const intl = useIntl();

  // If the value is an empty string, show "missing"
  const displayValue = value
    ? value
    : value !== undefined && intl.formatMessage({ id: 'missing' });
  const isMissing = !value;

  return (
    <Wrapper
      labelPlacement={labelPlacement}
      width={width}
      className="generic-input-wrapper"
    >
      {label && (
        <Label variant="subtitle1" color="textSecondary">
          {label} {required && editing && <RequiredStar>*</RequiredStar>}
        </Label>
      )}
      {editing ? (
        children
      ) : (
        <NotEditingValue isMissing={isMissing}>{displayValue}</NotEditingValue>
      )}
    </Wrapper>
  );
};

export default GenericSimplefied;
