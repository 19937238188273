import React from 'react';

export type SummaryType = 'sum' | 'result' | 'function' | 'solidity';

type Range = [string, string];

type FieldName =
  | 'yearUb'
  | 'yearIb'
  | 'yearChange'
  | 'period.ub'
  | 'period.change';

export type SummaryFunction = (
  n: (number | undefined)[],
  field?: FieldName
) => number | undefined;

export interface SummarySpec {
  title: string;
  type: SummaryType;
  valueType?: '%' | undefined;
  accountRanges?: Range[];
  fn?: SummaryFunction;
  sticky?: number;
}

interface SubTabSpec {
  firstAccount: string;
  lastAccount: string;
  title?: string;
  tab?: React.FC<any>;
  summary?: SummarySpec;
}

export interface SubGroupSpec {
  title?: string;
  summary?: SummarySpec;
  subTabs: SubTabSpec[];
}

export interface GroupSpec {
  name?: string;
  numbers: string[];
  summary?: SummarySpec;
  subGroups: SubGroupSpec[];
}

const groups: GroupSpec[] = [
  {
    numbers: ['3', '4', '5', '6', '7', '8'],
    summary: {
      title: 'Resultat efter finansiella poster (REFI)',
      type: 'function',
      fn: n => (n[1] || 0) + (n[0] || 0),
      accountRanges: [
        ['3000', '3999'],
        ['4000', '8799'],
      ],
      sticky: 0,
    },
    subGroups: [],
  },
  {
    numbers: ['3', '4', '5', '6', '7', '8'],
    summary: {
      title: 'REFI i %',
      type: 'function',
      valueType: '%',
      fn: (n, field) => {
        const [s3 = 0, s4 = 0] = n;
        return s3 === 0 ? undefined : (s3 + s4) / s3;
      },
      accountRanges: [
        ['3000', '3999'],
        ['4000', '8799'],
      ],
      sticky: 1,
    },
    subGroups: [],
  },
  {
    numbers: ['1'],
    summary: {
      title: 'SUMMA TILLGÅNGAR',
      type: 'sum',
    },
    subGroups: [
      {
        title: 'Anläggningstillgångar',
        subTabs: [
          {
            firstAccount: '1000',
            lastAccount: '1099',
            title: 'Immateriella anläggningstillgångar',
            summary: {
              title: 'Summa immateriella anläggningstillgångar',
              type: 'sum',
            },
          },
          {
            firstAccount: '1100',
            lastAccount: '1299',
            title: 'Materiella anläggningstillgångar',
            summary: {
              title: 'Summa materiella anläggningstillgångar',
              type: 'sum',
            },
          },
          {
            firstAccount: '1300',
            lastAccount: '1399',
            title: 'Finansiella anläggningstillgångar',
            summary: {
              title: 'Summa finansiella anläggningstillgångar',
              type: 'sum',
            },
          },
        ],
        summary: {
          title: 'Summa anläggningstillgångar',
          type: 'sum',
        },
      },
      {
        title: 'Omsättningstillgångar',
        subTabs: [
          {
            firstAccount: '1400',
            lastAccount: '1499',
            title: 'Varulager',
            summary: {
              title: 'Summa varulager',
              type: 'sum',
            },
          },
          {
            firstAccount: '1500',
            lastAccount: '1799',
            title: 'Kortfristiga fordringar',
            summary: {
              title: 'Summa kortfristiga fordringar',
              type: 'sum',
            },
          },
          {
            firstAccount: '1800',
            lastAccount: '1899',
            title: 'Kortfristiga placeringar',
            summary: {
              title: 'Summa kortfristiga placeringar',
              type: 'sum',
            },
          },
          {
            firstAccount: '1900',
            lastAccount: '1999',
            title: 'Kassa och bank',
            summary: {
              title: 'Summa kassa och bank',
              type: 'sum',
            },
          },
        ],
        summary: {
          title: 'Summa omsättningstillgångar',
          type: 'sum',
        },
      },
    ],
  },
  {
    name: 'EGET KAPITAL OCH SKULDER',
    numbers: ['2'],
    summary: {
      title: 'SUMMA EGET KAPITAL OCH SKULDER',
      type: 'sum',
    },
    subGroups: [
      {
        summary: {
          title: 'Summa eget kapital',
          type: 'sum',
        },
        subTabs: [
          {
            firstAccount: '2000',
            lastAccount: '2089',
            title: 'Bundet eget kapital',
            summary: {
              title: 'Summa bundet eget kapital',
              type: 'sum',
            },
          },
          {
            firstAccount: '2090',
            lastAccount: '2099',
            title: 'Fritt eget kapital',
            summary: {
              title: 'Summa fritt eget kapital',
              type: 'sum',
            },
          },
        ],
      },
      {
        summary: {
          title: 'Summa skulder',
          type: 'sum',
        },
        subTabs: [
          {
            firstAccount: '2100',
            lastAccount: '2199',
            title: 'Obeskattade reserver',
            summary: {
              title: 'Summa obeskattade reserver',
              type: 'sum',
            },
          },
          {
            firstAccount: '2200',
            lastAccount: '2299',
            title: 'Avsättningar',
            summary: {
              title: 'Summa avsättningar',
              type: 'sum',
            },
          },
          {
            firstAccount: '2300',
            lastAccount: '2399',
            title: 'Långfristiga skulder',
            summary: {
              title: 'Summa långfristiga skulder',
              type: 'sum',
            },
          },
          {
            firstAccount: '2400',
            lastAccount: '2999',
            title: 'Kortfristiga skulder',
            summary: {
              title: 'Summa kortfristiga skulder',
              type: 'sum',
            },
          },
        ],
      },
    ],
  },
  {
    numbers: ['1', '2'],
    summary: {
      title: 'Soliditet',
      type: 'solidity',
      valueType: '%',
      accountRanges: [
        ['2000', '2099'],
        ['2100', '2199'],
        ['2200', '2999'],
        ['1000', '1999'],
      ],
    },
    subGroups: [],
  },
  {
    name: 'RÖRELSENS INTÄKTER',
    numbers: ['3'],
    summary: {
      title: 'Totala intäkter',
      type: 'sum',
    },
    subGroups: [
      {
        subTabs: [
          {
            firstAccount: '3000',
            lastAccount: '3899',
            title: 'Nettoomsättning',
            summary: {
              title: 'Total nettoomsättning',
              type: 'sum',
            },
          },
          {
            firstAccount: '3900',
            lastAccount: '3999',
            title: 'Övriga rörelseintäkter',
            summary: {
              title: 'Totala övriga rörelseintäkter',
              type: 'sum',
            },
          },
        ],
      },
    ],
  },
  {
    name: 'RÖRELSENS KOSTNADER',
    numbers: ['3', '4', '5', '6', '7'],
    subGroups: [
      {
        subTabs: [
          {
            firstAccount: '4000',
            lastAccount: '4999',
            title: 'Bruttokostnader',
            summary: {
              title: 'Summa bruttokostnader (kontoklass 4)',
              type: 'sum',
            },
          },
        ],
      },
      {
        summary: {
          title: 'Bruttovinst',
          type: 'sum',
          accountRanges: [
            ['3000', '3899'],
            ['4000', '4999'],
          ],
        },
        subTabs: [],
      },
      {
        summary: {
          title: 'Bruttovinstmarginal',
          type: 'function',
          valueType: '%',
          fn: n => {
            const [s3 = 0, s4 = 0] = n;
            return s3 === 0 ? 0 : (s4 + s3) / s3;
          },
          accountRanges: [
            ['3000', '3899'],
            ['4000', '4999'],
          ],
        },
        subTabs: [],
      },
      {
        subTabs: [
          {
            firstAccount: '5000',
            lastAccount: '6999',
            title: 'Övriga externa kostnader',
            summary: {
              title: 'Totala övriga externa kostnader',
              type: 'sum',
            },
          },
        ],
      },
      {
        subTabs: [
          {
            firstAccount: '7000',
            lastAccount: '7699',
            title: 'Personalkostnader',
            summary: {
              title: 'Totala personalkostnader',
              type: 'sum',
            },
          },
          {
            firstAccount: '7700',
            lastAccount: '7899',
            title: 'Avskrivningar samt nedskrivningar',
            summary: {
              title: 'Totala avskrivningar samt nedskrivningar',
              type: 'sum',
            },
          },
          {
            firstAccount: '7900',
            lastAccount: '7999',
            title: 'Förlust valuta o FSG av tillgångar',
            summary: {
              title: 'Totala förlust valuta o FSG av tillgångar',
              type: 'sum',
            },
          },
        ],
      },
    ],
    summary: {
      title: 'Totala rörelsekostnader',
      type: 'sum',
    },
  },
  {
    numbers: ['3', '4', '5', '6', '7'],
    summary: {
      title: 'Rörelseresultat',
      type: 'sum',
      accountRanges: [['3000', '7999']],
    },
    subGroups: [],
  },
  {
    numbers: ['8'],
    subGroups: [
      {
        subTabs: [
          {
            firstAccount: '8000',
            lastAccount: '8799',
            title: 'Finansiella intäkter & kostnader',
            summary: {
              title: 'Totala finansiella intäkter & kostnader',
              type: 'sum',
            },
          },
        ],
      },
    ],
  },
  {
    numbers: ['3', '4', '5', '6', '7', '8'],
    summary: {
      title: 'Resultat efter finansiella poster',
      type: 'function',
      fn: n => (n[1] || 0) + (n[0] || 0),
      accountRanges: [
        ['3000', '3999'],
        ['4000', '8799'],
      ],
    },
    subGroups: [],
  },
  {
    numbers: ['3', '4', '5', '6', '7', '8'],
    summary: {
      title: 'REFI %',
      type: 'function',
      valueType: '%',
      fn: n => {
        const [s3 = 0, s4 = 0] = n;
        return s3 === 0 ? undefined : (s3 + s4) / s3;
      },
      accountRanges: [
        ['3000', '3999'],
        ['4000', '8799'],
      ],
    },
    subGroups: [],
  },
  {
    numbers: ['8'],
    subGroups: [
      {
        subTabs: [
          {
            firstAccount: '8800',
            lastAccount: '8899',
            title: 'Bokslutsdispositioner',
            summary: {
              title: 'Totala bokslutsdispositioner',
              type: 'sum',
            },
          },
          {
            firstAccount: '8900',
            lastAccount: '8999',
            title: 'Årets resultat & skatt',
          },
        ],
      },
    ],
  },
  {
    numbers: ['1', '2', '3', '4', '5', '6', '7', '8'],
    summary: {
      title: 'Beräknat resultat/Resultat',
      type: 'result',
      accountRanges: [
        ['8990', '8999'],
        ['3000', '3999'],
        ['4000', '8989'],
      ],
    },
    subGroups: [],
  },
];

export default groups;
