import React, { useState } from 'react';
import { NoteOneTableSection } from 'types/AnnualReport/parts/notes';
import { numberValue, stringValue } from 'utils/AnnualReport/cell';
import CheckboxTable from './CheckboxTable';
import Comment from './Comment';
import Header from './Header';
import { Note } from './Note';

type CheckboxesNoteProps = {
  noteId: string;
  print?: boolean;
} & NoteOneTableSection;

const CheckboxesNote = ({
  noteId,
  active,
  table,
  number,
  comment,
  print = false,
}: CheckboxesNoteProps) => {
  const [editing, setEditing] = useState(false);

  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header
        noteId={noteId}
        noteNumber={numberValue(number)}
        editing={editing}
        onEditToggle={editing => {
          setEditing(!editing);
        }}
        print={print}
      />

      {/* Checkbox table */}
      <CheckboxTable
        tableId={`notes.${noteId}.table`}
        table={table}
        editing={editing}
        print={print}
      />

      {/* Comment */}
      <Comment
        id={`notes.${noteId}.comment`}
        editing={editing}
        value={stringValue(comment) || ''}
        print={print}
      />
    </Note>
  );
};

export default CheckboxesNote;
