import { useAgoyAppMutate } from 'utils/AgoyAppClient/useAgoyAppMutate';

const createDeleteSubscriptionRequest = () => ({
  uri: `/api/organisation/subscription`,
});

type DeleteSubscription = () => {};

export const useDeleteSubscription = () => {
  return useAgoyAppMutate<Parameters<DeleteSubscription>>(
    createDeleteSubscriptionRequest,
    'DELETE'
  );
};
