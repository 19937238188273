import React from 'react';
import { useSelector } from 'redux/reducers';
import styled from '@emotion/styled';
import { Typography, WithTheme } from '@material-ui/core';
import { Notes } from 'types/AnnualReport/parts/notes';
import { useIntl } from 'react-intl';
import CustomNote from './Customised/CustomNote';
import Note1 from './ByNumber/1st/Note1';
import Note2 from './ByNumber/2nd/Note2';
import Note23 from './ByNumber/23rd/Note23';
import Note28 from './ByNumber/28th/Note28';
import OneTableThreeColumnNote from './UI/single-table/ThreeColumnNote';
import OneTableThreeColumnSumNote from './UI/single-table/ThreeColumnSumNote';
import OneTableFourColumnAddSumNote from './UI/single-table/FourColumnSumNote';
import TwoTableThreeColumnSumNote from './UI/two-tables/ThreeColumnSumNote';
import TwoTableSixColumnThreeColumnNote from './UI/two-tables/SixColumnThreeColumnNote';
import InputFieldNote from './UI/InputFieldNote';
import CheckboxesNote from './UI/CheckboxesNote';

const Content = styled.div<WithTheme>`
  min-width: 800px;
  div:first-of-type {
    page-break-inside: auto;
  }
`;

const NotesPreview = () => {
  const { formatMessage } = useIntl();
  const notes: Notes | undefined = useSelector(
    state => state.annualReport.report?.notes
  );

  if (!notes) {
    return null;
  }

  const {
    note1,
    note2,
    note3,
    note4,
    note5,
    note6,
    note7,
    note8,
    note9,
    note10,
    note11,
    note12,
    note13,
    note14,
    note15,
    note16,
    note17,
    note18,
    note19,
    note20,
    note21,
    note22,
    note23,
    note24,
    note25,
    note26,
    note27,
    note28,
    note29,
    note30,
    note31,
    note32,
    note33,
    note34,
    note35,
    note36,
    note37,
    note38,
    note39,
    custom,
  } = notes;

  return (
    <Content>
      <Typography variant="h1">
        {formatMessage({
          id: 'annualReport.menu.notes',
        })}
      </Typography>
      <Note1 print={true} {...note1} />
      <Note2 print={true} {...note2} />
      <OneTableThreeColumnNote print={true} noteId="note3" {...note3} />
      <OneTableThreeColumnNote print={true} noteId="note4" {...note4} />
      <OneTableThreeColumnNote print={true} noteId="note5" {...note5} />

      <OneTableThreeColumnNote
        print={true}
        noteId="note6"
        {...note6}
        rowsInGroupsOf={17}
      />
      <OneTableThreeColumnNote
        print={true}
        noteId="note7"
        {...note7}
        rowsInGroupsOf={17}
      />
      <OneTableThreeColumnNote
        print={true}
        noteId="note8"
        {...note8}
        rowsInGroupsOf={17}
      />
      <OneTableThreeColumnNote print={true} noteId="note9" {...note9} />
      <OneTableThreeColumnNote print={true} noteId="note10" {...note10} />
      <OneTableThreeColumnNote
        print={true}
        noteId="note11"
        {...note11}
        rowsInGroupsOf={17}
      />
      <OneTableThreeColumnNote
        print={true}
        noteId="note12"
        {...note12}
        rowsInGroupsOf={17}
      />
      <OneTableThreeColumnNote
        print={true}
        noteId="note13"
        {...note13}
        rowsInGroupsOf={17}
      />
      <OneTableThreeColumnNote
        print={true}
        noteId="note14"
        {...note14}
        rowsInGroupsOf={17}
      />
      <OneTableThreeColumnNote print={true} noteId="note15" {...note15} />
      <TwoTableSixColumnThreeColumnNote
        print={true}
        noteId="note16"
        {...note16}
      />
      <OneTableThreeColumnNote print={true} noteId="note17" {...note17} />
      <TwoTableSixColumnThreeColumnNote
        print={true}
        noteId="note18"
        {...note18}
      />
      <OneTableThreeColumnNote print={true} noteId="note18" {...note19} />
      <TwoTableSixColumnThreeColumnNote
        print={true}
        noteId="note20"
        {...note20}
      />
      <OneTableThreeColumnNote print={true} noteId="note21" {...note21} />
      <OneTableThreeColumnNote print={true} noteId="note22" {...note22} />
      <Note23 print={true} {...note23} />
      <OneTableThreeColumnNote print={true} noteId="note24" {...note24} />
      <OneTableThreeColumnSumNote print={true} noteId="note25" {...note25} />
      <OneTableThreeColumnNote print={true} noteId="note26" {...note26} />
      <OneTableThreeColumnSumNote print={true} noteId="note27" {...note27} />
      <Note28 print={true} {...note28} />
      <OneTableFourColumnAddSumNote print={true} noteId="note29" {...note29} />
      <OneTableThreeColumnNote print={true} noteId="note30" {...note30} />
      <OneTableThreeColumnNote print={true} noteId="note31" {...note31} />
      <OneTableFourColumnAddSumNote print={true} noteId="note32" {...note32} />

      <OneTableThreeColumnSumNote
        print={true}
        noteId="note33"
        {...note33}
        rowsInGroupsOf={4}
      />
      <TwoTableThreeColumnSumNote print={true} noteId="note34" {...note34} />
      <InputFieldNote print={true} noteId="note35" {...note35} />
      <InputFieldNote print={true} noteId="note36" {...note36} />
      <CheckboxesNote print={true} noteId="note37" {...note37} />
      <CheckboxesNote print={true} noteId="note38" {...note38} />
      <CheckboxesNote print={true} noteId="note39" {...note39} />

      {custom.sections.map(
        (note, index) =>
          note && (
            <CustomNote
              key={index}
              noteId={`custom-${index}`}
              note={note}
              print={true}
            />
          )
      )}
    </Content>
  );
};

export default NotesPreview;
