import groupSpec, {
  GroupSpec,
  SummarySpec,
  SummaryType,
  SummaryFunction,
} from './groupSpec';
import { AccountInformation, SummedSaldo } from 'utils/SieParser';
import range from 'lodash-es/range';
import OverviewTable from 'types/OverviewTable/types';
import { companyTaxPerYear } from 'utils/TaxCalculation/config';

/**
 * all periods does not have to have data in the sie fil.
 * this fills those periods with the previosPeriods Ib, Ub,totalc
 */
export const createRowData = (
  allPeriods: string[],
  row: AccountInformation
): OverviewTable.PeriodInformation[] => {
  const filterRow = row.periods;
  const yearIb = row.yearIbs.find(y => y.yearNo === '0')?.saldo || 0;

  const rows: OverviewTable.PeriodInformation[] = [];
  allPeriods.forEach(period => {
    const periodFromRow: SummedSaldo | undefined = filterRow.find(
      row => row.period === period
    );
    if (periodFromRow !== undefined) {
      rows.push(periodFromRow);
    } else {
      const defaultPrevRow = { period: period, totalSaldo: 0 };
      const previousRow =
        rows.length > 0
          ? {
              ...defaultPrevRow,
              ib: rows[rows.length - 1].ub,
              ub: rows[rows.length - 1].ub,
            }
          : { ...defaultPrevRow, ib: yearIb, ub: yearIb };
      rows.push(previousRow);
    }
  });
  return rows;
};

const between = <T>(begin: T, middle: T, end: T): boolean =>
  middle >= begin && middle <= end;

const summarizeGroup = (
  groupAccounts: OverviewTable.AccountInformation[],
  group: GroupSpec,
  companyTax: number
): OverviewTable.AccountGroupSummary | undefined => {
  const { summary } = group;
  if (!summary) {
    return undefined;
  }
  if (summary.accountRanges) {
    const accounts = summary.accountRanges.map(([firstAccount, lastAccount]) =>
      groupAccounts.filter(account =>
        between(firstAccount, account.account, lastAccount)
      )
    );

    return accounts.length === 0
      ? undefined
      : getSummaryFunction(summary.type, companyTax)(accounts, summary);
  }
  const accounts = group.subGroups
    .flatMap(subGroup => subGroup.subTabs)
    .flatMap(subTab => {
      return groupAccounts.filter(account =>
        between(subTab.firstAccount, account.account, subTab.lastAccount)
      );
    });

  return accounts.length === 0
    ? undefined
    : getSummaryFunction(summary.type, companyTax)([accounts], summary);
};

const summarizeSubGroup = (
  filteredAccounts: OverviewTable.AccountInformation[][],
  groupAccounts: OverviewTable.AccountInformation[],
  summary: SummarySpec | undefined,
  companyTax: number
): OverviewTable.AccountGroupSummary | undefined => {
  if (!summary) {
    return undefined;
  }

  const accounts = summary.accountRanges
    ? summary.accountRanges.map(([firstAccount, lastAccount]) =>
        groupAccounts.filter(account =>
          between(firstAccount, account.account, lastAccount)
        )
      )
    : filteredAccounts;
  if (accounts.length === 0) {
    return undefined;
  }

  return getSummaryFunction(summary.type, companyTax)(accounts, summary);
};

type SummaryTypeFunction = (
  accounts: OverviewTable.AccountInformation[][],
  summary: SummarySpec
) => OverviewTable.AccountGroupSummary | undefined;

export const getYearIb = (account: AccountInformation): number =>
  (
    account.res.find(y => y.yearNo === '-1') ||
    account.yearIbs.find(y => y.yearNo === '0')
  )?.saldo || 0;

export const getYearUb = (account: AccountInformation): number =>
  (
    account.res.find(y => y.yearNo === '0') ||
    account.yearUbs.find(y => y.yearNo === '0')
  )?.saldo || 0;

const accountToRow = (
  account: OverviewTable.AccountInformation
): OverviewTable.SummaryRow => ({
  yearIb: account.yearIb,
  yearUb: account.yearUb,
  yearChange: account.yearChange,
  periods: account.periods.map(period => ({
    ub: period.ub,
    change: period.totalSaldo,
  })),
});

function notNull<T>(obj: T | null) {
  return obj !== null;
}

const calculateByColumn = (
  rows: (OverviewTable.SummaryRow | undefined)[],
  operator: SummaryFunction
): OverviewTable.SummaryRow | undefined => {
  if (rows.length === 0) {
    return undefined;
  }
  const nPeriods = rows.reduce<number>(
    (longestPeriods, row) =>
      row?.periods.length || 0 > longestPeriods
        ? row?.periods.length || 0
        : longestPeriods,
    0
  );
  if (nPeriods === 0) {
    return undefined;
  }
  const yearIb = operator(
    rows.filter(notNull).map(row => row?.yearIb || 0),
    'yearIb'
  );
  const yearUb = operator(
    rows.map(row => row?.yearUb || 0),
    'yearUb'
  );
  return {
    yearIb,
    yearUb,
    yearChange:
      yearIb !== undefined && yearUb !== undefined
        ? yearUb - yearIb
        : undefined,
    periods:
      range(nPeriods).map((_, index) => ({
        ub: operator(
          rows.map(row => row?.periods[index].ub || 0),
          'period.ub'
        ),
        change: operator(
          rows.map(row => row?.periods[index].change || 0),
          'period.change'
        ),
      })) || [],
  };
};

const sumArray = n => n.reduce((a, b) => a + b, 0);

const summarizeAccountsAsSum: SummaryTypeFunction = (
  accounts,
  summary
): OverviewTable.AccountGroupSummary | undefined => {
  const sum = calculateByColumn(
    accounts.map(a => calculateByColumn(a.map(accountToRow), sumArray)),
    sumArray
  );

  const { type, ...summaryRest } = summary;

  return (
    sum && {
      ...summaryRest,
      ...sum,
    }
  );
};

const summarizeAccountsAsResult = (calcSum): SummaryTypeFunction => (
  accounts,
  summary
) => {
  const sums = accounts.map(group =>
    calculateByColumn(group.map(accountToRow), sumArray)
  );
  const diff = calculateByColumn(sums, (n, field) => {
    return field === 'yearUb' ? n[0] : undefined;
  });
  if (!diff) {
    return undefined;
  }
  const noResult = diff.yearUb === undefined || Math.abs(diff.yearUb) < 0.1;

  const sum = calculateByColumn(sums, calcSum);

  const { type, ...summaryRest } = summary;

  return (
    sum && {
      ...summaryRest,
      title: noResult ? 'Beräknat resultat' : 'Resultat',
      ...sum,
    }
  );
};

const summarizeAccountsWithFunction: SummaryTypeFunction = (
  accounts,
  summary
) => {
  const sums = accounts.map(group =>
    calculateByColumn(group.map(accountToRow), sumArray)
  );

  const result = calculateByColumn(sums, summary.fn!);

  return (
    result && {
      ...summary,
      title: summary.title,
      type: summary.valueType,
      ...result,
    }
  );
};

//
// Expects accounts to contain [[accounts 2000-2099],[accounts 2100-2199],[accounts 2200-2999],[accounts 1000-1999]]
// from groupSpec
//
const summarizeSolidity = (companyTax: number): SummaryTypeFunction => (
  accounts,
  summary
) => {
  //
  // For the IB and UB:
  // Excel v31
  // Soliditet=OMFEL((AQ546+((1-INFO!Q6)*AQ549))/AQ562;"")
  // AQ546 = 2000 - 2099 (n1)
  // AQ549 = 2100 - 2199 (n2)
  // AQ562 = 2000 - 2999 (n1+n2+n3)
  // INFO!Q6 = company tax
  //
  // And for the periods:
  // Divide by account 1000-1999 instead and then calculate the changes from that
  //
  const sums = accounts.map(group =>
    calculateByColumn(group.map(accountToRow), sumArray)
  );

  const result = calculateByColumn(sums, (n, field) => {
    if (field === 'yearIb' || field === 'yearUb') {
      const [n1, n2, n3] = n;
      if (n1 === undefined || n2 === undefined || n3 === undefined) {
        return undefined;
      }
      const sum = n1 + n2 + n3;
      if (sum === 0) {
        return undefined;
      }
      return (n1 + (1 - companyTax) * n2) / sum;
    } else if (field === 'period.ub') {
      const [n1, n2, , n4] = n;
      if (n1 === undefined || n2 === undefined || n4 === undefined) {
        return undefined;
      }
      if (n4 === 0) {
        return undefined;
      }
      return (n1 + (1 - companyTax) * n2) / -n4;
    }
    return undefined;
  });

  if (!result || !result.yearIb) {
    return undefined;
  }

  // Fix period changes
  const periods = calculatePeriodChanges(result.yearIb, result.periods);

  return (
    result && {
      ...summary,
      title: summary.title,
      type: summary.valueType,
      ...result,
      periods,
    }
  );
};

const safeSubtract = (
  a: number | undefined,
  b: number | undefined
): number | undefined =>
  a !== undefined && b !== undefined ? a - b : undefined;

const calculatePeriodChanges = (
  ib: number,
  periods: OverviewTable.SummaryRowPeriod[]
): OverviewTable.SummaryRowPeriod[] => {
  return periods.map((p, index) => ({
    ...p,
    change:
      index === 0
        ? safeSubtract(p.ub, ib)
        : safeSubtract(p.ub, periods[index - 1].ub),
  }));
};

const getSummaryFunction = (
  type: SummaryType,
  companyTax: number
): SummaryTypeFunction => {
  switch (type) {
    case 'sum':
      return summarizeAccountsAsSum;
    case 'result':
      return summarizeAccountsAsResult((n, field) => {
        const [, s3 = 0, s4 = 0] = n;
        return field === 'yearChange' ? undefined : -s3 - s4;
      });
    case 'function':
      return summarizeAccountsWithFunction;
    case 'solidity':
      return summarizeSolidity(companyTax);
  }
};

const createPeriods = (
  periods: string[],
  allPeriods: string[],
  account: AccountInformation
): OverviewTable.AccountInformation => {
  const newPeriods = createRowData(allPeriods, account);

  const yearIb = getYearIb(account);
  const yearUb = getYearUb(account);

  return {
    ...account,
    yearIb,
    yearUb,
    yearChange: yearUb - yearIb,
    periods:
      allPeriods === periods
        ? newPeriods
        : newPeriods.filter(p => periods.includes(p.period)),
  };
};

export const groupAccounts = (
  sieBlob: AccountInformation[],
  allPeriods: string[],
  periods: string[]
): OverviewTable.AccountGroup[] => {
  const endingYear = allPeriods[allPeriods.length - 1].substring(0, 4);
  const companyTax = companyTaxPerYear[endingYear];
  const accounts = sieBlob.map(account =>
    createPeriods(periods, allPeriods, account)
  );
  return groupSpec.map(
    (group): OverviewTable.AccountGroup => {
      const groupAccounts = accounts.filter(account =>
        group.numbers.includes(account.account[0])
      );

      return {
        ...group,
        subGroups: group.subGroups.map(
          (subGroup): OverviewTable.AccountSubGroup => {
            const filteredAccounts: OverviewTable.AccountInformation[][] = [];
            const subTabs = subGroup.subTabs.map(
              (subTab): OverviewTable.AccountSubTab => {
                const accounts = groupAccounts.filter(account =>
                  between(
                    subTab.firstAccount,
                    account.account,
                    subTab.lastAccount
                  )
                );
                if (accounts.length > 0) {
                  filteredAccounts.push(accounts);
                }
                const summary = summarizeSubGroup(
                  [accounts],
                  groupAccounts,
                  subTab.summary,
                  companyTax
                );
                return {
                  title: subTab.title,
                  accounts,
                  summary,
                };
              }
            );
            return {
              title: subGroup.title,
              subTabs,
              summary: summarizeSubGroup(
                filteredAccounts,
                groupAccounts,
                subGroup.summary,
                companyTax
              ),
            };
          }
        ),
        summary: summarizeGroup(groupAccounts, group, companyTax),
      };
    }
  );
};

export default groupAccounts;
