import { ToggleAnnualReportTableActive } from 'redux/actions/AnnualReportView/types';
import AnnualReportState from '../types';

const toggleAnnualReportTableActive = (
  state: AnnualReportState,
  action: ToggleAnnualReportTableActive
) => {
  const { part, section, table } = action;
  if (!state.report) {
    return state;
  }
  if (!state.report[part][section]) {
    return state;
  }
  return {
    ...state,
    report: {
      ...state.report,
      [part]: {
        ...state.report[part],
        [section]: {
          ...state.report[part][section],
          [table]: {
            ...state.report[part][section][table],
            active: !state.report[part][section][table].active,
          },
        },
      },
    },
    changes: {
      ...state.changes,
      [part]: {
        ...state.changes[part],
        [section]: {
          ...state.changes[part]?.[section],
          [table]: {
            ...state.changes[part]?.[section]?.[table],
            type: 'update',
            active: !state.report[part][section][table].active,
          },
        },
      },
    },
  };
};

export default toggleAnnualReportTableActive;
