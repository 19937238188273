import moment from 'moment';

const formatFinancialYear = financialYear =>
  financialYear
    ? `${moment(financialYear.split('-')[0], 'YYYYMM')
        .startOf('month')
        .format('YYYY-MM-DD')}
    –
    ${moment(financialYear.split('-')[1], 'YYYYMM')
      .endOf('month')
      .format('YYYY-MM-DD')}`
    : undefined;

export default formatFinancialYear;
