import React, { createContext } from 'react';
import config from 'config';
import { createUserPool } from 'utils/AuthenticatedClient';
import { setContext } from './contextHolder';

interface AgoyAppClientContext {
  baseUrl: string;
  headers: () => Promise<{}>;
}

const AgoyAppClientContext = createContext({} as AgoyAppClientContext);

export const AgoyAppClientProvider = ({ children }) => {
  const context = {
    baseUrl: config.appEndpoint,
    headers: () => {
      const user = createUserPool().getCurrentUser();
      return new Promise<{}>((resolve, reject) => {
        if (!user) {
          reject('No user');
        } else {
          user.getSession((err, session) => {
            if (err) {
              return reject(err);
            }
            const token = session.getIdToken().getJwtToken();
            resolve({ token });
          });
        }
      });
    },
  };
  setContext(context);

  return (
    <AgoyAppClientContext.Provider value={context}>
      {children}
    </AgoyAppClientContext.Provider>
  );
};

export default AgoyAppClientContext;
