import {
  INSERT_USER_TO_ORG,
  ADD_ORGANISATION_INFO,
  REMOVE_INVITE,
  REMOVE_MEMBER,
  SET_SUBSCRIPTION_STATUS,
} from '../../actionsTypes';
import { setSubscriptionCancelledAction } from './types';

export const insertUserToOrg = email => ({
  type: INSERT_USER_TO_ORG,
  email,
});

export const addOrganisationInfo = organisationInfo => ({
  type: ADD_ORGANISATION_INFO,
  organisationInfo,
});

export const removeInvite = email => ({
  type: REMOVE_INVITE,
  email,
});

export const removeMember = email => ({
  type: REMOVE_MEMBER,
  email,
});

export const setSubscriptionStatus = (
  status: Organisation.subscriptionStatus
): setSubscriptionCancelledAction => {
  return {
    type: SET_SUBSCRIPTION_STATUS,
    status,
  };
};
