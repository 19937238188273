import { ADD_GLOBAL_MESSAGE, REMOVE_GLOBAL_MESSAGE } from '../../actionsTypes';
import MessagesState, { Message } from './types';
import { MessagesActions } from 'redux/actions';

const initialState: MessagesState = {
  global: [],
};

let nextId = 0;
const getNextId = () => `${nextId++}`;

export default (
  state: MessagesState = initialState,
  action: MessagesActions
): MessagesState => {
  switch (action.type) {
    case ADD_GLOBAL_MESSAGE: {
      const message: Message = {
        id: getNextId(),
        severity: action.severity,
        textId: action.textId,
        text: action.text,
      };
      return {
        ...state,
        global: [...state.global, message],
      };
    }

    case REMOVE_GLOBAL_MESSAGE:
      return {
        ...state,
        global: state.global.filter(({ id }) => id !== action.id),
      };

    default:
      return state;
  }
};
