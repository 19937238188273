export const getClosingMonthOptions = intl => [
  { value: 1, label: intl.formatMessage({ id: 'month.jan' }) },
  { value: 2, label: intl.formatMessage({ id: 'month.feb' }) },
  { value: 3, label: intl.formatMessage({ id: 'month.mar' }) },
  { value: 4, label: intl.formatMessage({ id: 'month.apr' }) },
  { value: 5, label: intl.formatMessage({ id: 'month.may' }) },
  { value: 6, label: intl.formatMessage({ id: 'month.jun' }) },
  { value: 7, label: intl.formatMessage({ id: 'month.jul' }) },
  { value: 8, label: intl.formatMessage({ id: 'month.aug' }) },
  { value: 9, label: intl.formatMessage({ id: 'month.sep' }) },
  { value: 10, label: intl.formatMessage({ id: 'month.oct' }) },
  { value: 11, label: intl.formatMessage({ id: 'month.nov' }) },
  { value: 12, label: intl.formatMessage({ id: 'month.dec' }) },
];

export const getClosingPeriodOptions = intl => [
  {
    value: 'year',
    label: intl.formatMessage({ id: 'closingperiod.year' }),
  },
  {
    value: 'quarter',
    label: intl.formatMessage({
      id: 'closingperiod.quarter',
    }),
  },
  {
    value: 'month',
    label: intl.formatMessage({
      id: 'closingperiod.month',
    }),
  },
];

export const getVatPeriodOptions = intl => [
  { value: 'month', label: intl.formatMessage({ id: 'vatperiod.month' }) },
  {
    value: 'quarter',
    label: intl.formatMessage({ id: 'vatperiod.quarter' }),
  },
  {
    value: 'year',
    label: intl.formatMessage({ id: 'vatperiod.year' }),
  },
  {
    value: 'novat',
    label: intl.formatMessage({ id: 'vatperiod.novat' }),
  },
];

export const getCompanyTypeOptions = intl => [
  { value: 'shares', label: intl.formatMessage({ id: 'company.shares' }) },
  { value: 'limited', label: intl.formatMessage({ id: 'company.limited' }) },
  {
    value: 'individual',
    label: intl.formatMessage({
      id: 'company.individual',
    }),
  },
  {
    value: 'association',
    label: intl.formatMessage({
      id: 'company.association',
    }),
  },
];
