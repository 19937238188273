import React from 'react';
import styled from '@emotion/styled';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  WithTheme,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateAnnualReportField } from 'redux/actions/AnnualReportView/actions';
import { useIntl } from 'react-intl';

const Content = styled.div<WithTheme>`
  margin: ${props => props.theme.spacing(2)}px 0;
`;

const StatementOptions = [
  { id: 'notApplicable', label: 'Ej tillämpat' },
  {
    id: 'rejectedResultsAndBalance',
    label: 'Avstyrkt resultat- och balansräkning',
  },
  { id: 'rejectedResults', label: 'Avstyrkt resultaträkning' },
  { id: 'rejectedBalance', label: 'Avstyrkt balansräkning' },
  {
    id: 'neitherRejectedResultsAndBalance',
    label: 'Varken till- eller avstyrkt resultat- och balansräkning',
  },
  {
    id: 'neitherRejectedResults',
    label: 'Varken till- eller avstyrkt resultaträkning',
  },
  {
    id: 'neitherRejectedBalance',
    label: 'Varken till- eller avstyrkt balansräkning',
  },
];

const idByLabel = (label): string => {
  const option = StatementOptions.find(item => item.label === label);
  return option ? option.id : 'notApplicable';
};

type SignaturesStatementProps = {
  value: string;
  print?: boolean;
};

const SignaturesStatement = ({
  value = 'notApplicable',
  print,
}: SignaturesStatementProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optionId = (event.target as HTMLInputElement).value;
    const option = StatementOptions.find(option => option.id === optionId);
    if (option) {
      const fullId = 'signatures.section.statement';
      const label = option.id !== 'notApplicable' ? option.label : '';
      dispatch(updateAnnualReportField(fullId, label));
    }
  };

  return (
    <Content>
      {print ? (
        idByLabel(value) !== 'notApplicable' && (
          <Typography>{`, ${value}`}</Typography>
        )
      ) : (
        <>
          <Typography>
            {formatMessage({ id: 'annualReport.signatures.section.statement' })}
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="signature-statement"
              name="statement-1"
              value={idByLabel(value)}
              onChange={handleChange}
            >
              {StatementOptions.map(item => (
                <FormControlLabel
                  key={item.id}
                  value={item.id}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </>
      )}
    </Content>
  );
};

export default SignaturesStatement;
