import {
  ADD_CUSTOMER,
  UPDATE_CUSTOMER,
  SET_CUSTOMER_LOGO,
  DELETE_CUSTOMER,
  POPULATE_CUSTOMERS,
  SET_LAMP_STATUS,
  SET_INTEGRATION_STATUS,
  SET_FINANCIAL_YEARS,
  INIT_STATE,
  SET_SIE_DATA,
} from '../../actionsTypes';
import CustomersState from './types';

const initialCustomersState: CustomersState = {};

export default (
  state: CustomersState = initialCustomersState,
  action
): CustomersState => {
  switch (action.type) {
    case INIT_STATE:
      return action.state.customers || state;

    case SET_LAMP_STATUS:
      const news = {
        ...state,
        [action.customerId]: {
          ...state[action.customerId],
          lamps: {
            ...state[action.customerId].lamps,
            [action.period]: action.mapStatuses,
          },
        },
      };
      return news;

    case SET_SIE_DATA:
      let { customerId } = action.sieData;
      // When a new sie file is inserted, the financial period has to be
      // inserted into the customer
      action.sieData.periods.sort((a, b) => {
        return parseInt(a) - parseInt(b);
      });

      // Get the financial period of the sie file
      const financialPeriod = `${action.sieData.periods[0]}-${
        action.sieData.periods[action.sieData.periods.length - 1]
      }`;

      // Check if the financial period is alrady present in the customer
      if (state[customerId]?.financialYears?.includes(financialPeriod)) {
        return state;
      } else {
        return {
          ...state,
          [customerId]: {
            ...state[customerId],
            financialYears: [
              // Create a set to eliminate duplicates if the period already exists
              ...new Set([
                ...(state[customerId].financialYears
                  ? state[customerId].financialYears
                  : []),
                financialPeriod,
              ]),
            ],
          },
        };
      }

    case ADD_CUSTOMER:
      return {
        ...state,
        [action.customer.id]: action.customer,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        [action.customer.id]: action.customer,
      };
    case SET_CUSTOMER_LOGO:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          logo: action.logo,
        },
      };
    case DELETE_CUSTOMER:
      const newState = { ...state };
      delete newState[action.id];
      return newState;
    case POPULATE_CUSTOMERS:
      return {
        ...action.customers.reduce((acc, current) => {
          acc[current.id] = current;
          return acc;
        }, {}),
        ...state,
      };
    case SET_INTEGRATION_STATUS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          integrations: {
            ...state[action.id]?.integrations?.[action.integration],
            [action.integration]: action.status,
          },
        },
      };
    case SET_FINANCIAL_YEARS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          financialYears: action.financialYears,
        },
      };
    default:
      return state;
  }
};
