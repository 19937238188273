import { TaxCalculationContext, TaxCalculationRow } from './types';

export const updateContext = <T extends TaxCalculationRow>(
  context: TaxCalculationContext,
  row: T
): T => {
  if (!row.id || row.deleted) {
    return row;
  }
  if (context.rowsById[row.id] === row) {
    return row;
  }

  context.rowsById = { ...context.rowsById, [row.id]: row };
  return row;
};

export const isLastPeriod = (context: TaxCalculationContext): boolean =>
  context.input.period === null ||
  context.input.periods[context.input.periods.length - 1] ===
    context.input.period;
