import moment from 'moment';
import { getAccountName, calculateIB, getIbSaldo } from './sieParserUtils';
import groupBy from 'lodash/groupBy';

export const _createPeriods = (
  value: SieFile.AccountingYearRecord
): PeriodsObject => {
  let mStart = moment(value.start, 'YYYYMMDD');
  let mEnd = moment(value.slut, 'YYYYMMDD');
  const monthDiff = Math.round(mEnd.diff(mStart, 'months'));
  return {
    periods: [...new Array(monthDiff + 1)]
      .map((_, index) => {
        let mNew = moment(mStart);
        mNew.add(index, 'month');
        return mNew;
      })
      .map(value => value.format('YYYYMMDD')),
  };
};

interface Saldo {
  account: string;
  yearNo: string;
  period: string;
  main: boolean;
  saldo: number;
}
export interface SummedSaldo extends Saldo {
  totalSaldo: number;
  ib: number;
  ub: number;
}

const sumSaldos = (saldos: Saldo[]): SummedSaldo[] =>
  saldos.map(saldo => {
    return {
      ...saldo,
      ib: 0,
      ub: 0,
      totalSaldo: saldo.saldo || 0,
    };
  });

export interface YearObj {
  yearNo: string;
  saldo: number;
}
export interface AccountInformation {
  account: string;
  accountName: string;
  yearIbs: YearObj[];
  yearUbs: YearObj[];
  periods: SummedSaldo[];
  res: YearObj[];
}

const calculatePeriodSaldo = (transactions: Transaction[]): Saldo[] => {
  const transByAccount = groupBy(transactions, t => t.account);

  return Object.entries(transByAccount).flatMap(([account, trans]) => {
    trans.sort(
      (a, b) => parseInt(a.verificationDate) - parseInt(b.verificationDate)
    );
    return trans.reduce<Saldo[]>((periods, t) => {
      const period = t.verificationDate.substring(0, 6);
      let latest: Saldo = periods[periods.length - 1];
      if (!latest || latest.period !== period) {
        latest = {
          account,
          yearNo: '0',
          period,
          main: true,
          saldo: t.amount,
        };
        periods.push(latest);
      } else {
        latest.saldo += t.amount;
      }

      return periods;
    }, []);
  });
};

/**
 * Add saldo to transactions.
 * Saldos are calculated for all the transacitions grouped by account number.
 */
export const calculateTransactionSaldo = ({
  transactions,
  accountInformation,
}: {
  transactions: Transaction[];
  accountInformation: AccountInformation[];
}): Transaction[] => {
  const transactionsWithSaldo: Transaction[] = [];

  const transByAccount = groupBy(transactions, t => t.account);

  Object.entries(transByAccount).flatMap(([account, trans]) => {
    trans.sort(
      (a, b) => parseInt(a.verificationDate) - parseInt(b.verificationDate)
    );

    trans.reduce<Saldo[]>((periods, t) => {
      const period = t.verificationDate.substring(0, 6);
      let latest: Saldo = periods[periods.length - 1];

      const { saldo: initialSaldo = 0 } = accountInformation.find(
        a => a.account === account
      )?.yearIbs[0] || { saldo: 0 };

      if (!latest) {
        latest = {
          account,
          yearNo: '0',
          period,
          main: true,
          saldo: initialSaldo + t.amount,
        };
        periods.push(latest);
      } else {
        latest.saldo += t.amount;
      }

      transactionsWithSaldo.push({ ...t, saldo: latest.saldo });

      return periods;
    }, []);
  });

  return transactionsWithSaldo;
};

const aggregateAccountInformation = (
  sieObj: SieFile.SieFile,
  transactions: Transaction[]
): AccountInformation[] => {
  const saldos = calculatePeriodSaldo(transactions);
  const sumedSaldos = sumSaldos(saldos);
  const ibs = sieObj.list('ib').filter(saldo => saldo.årsnr === '0'); //TODO: verify that this does not brake anything, like filter on res did
  const ubs = sieObj.list('ub').filter(saldo => saldo.årsnr === '0');
  const res = sieObj.list('res');

  const accounts = sieObj.list('konto');
  const uniqueKontos = new Set(
    [
      ...sumedSaldos.map(saldo => saldo.account),
      ...ibs.map(ib => ib.konto),
      ...ubs.map(ub => ub.konto),
      ...res.map(r => r.konto),
    ].sort()
  ); /// need array.from right now since typescritp does not support iteration on a set...

  return [...Array.from(uniqueKontos)].map(account => ({
    account: account,
    accountName: getAccountName(account, accounts), // TODO: CHECK IF THIS IS Consistent!!
    yearIbs: ibs
      .filter(ib => ib.konto === account)
      .map(ib => ({
        yearNo: ib.årsnr,
        saldo: calculateIB(ib),
      })),
    yearUbs: ubs
      .filter(ub => ub.konto === account)
      .map(ub => ({
        yearNo: ub.årsnr,
        saldo: ub.saldo ? parseFloat(ub.saldo) : 0,
      })),
    res: res
      .filter(val => val.konto === account)
      .map(val => ({ yearNo: val.års, saldo: parseFloat(val.saldo) })),
    periods: sumedSaldos.filter(saldo => saldo.account === account),
  }));
};

const addPeriodIbAndUb = (
  aggregated: AccountInformation[],
  periods: string[]
): AccountInformation[] => {
  let aggregatedData = aggregated.map((row): SummedSaldo[] =>
    row.periods.length > 0
      ? row.periods
      : periods.map(period => ({
          // If no value is present in periods we need to create it and populate it(period being for example a month),
          account: row.account,
          period: period,
          main: true,
          totalSaldo: 0,
          ib: 0,
          ub: 0,
          saldo: 0,
          yearNo: '0', //Ugly solution but is a required type-> set du yearNo?(YearNo is located in row.periods elements not in row. but there should only be "yearNo=0")
        }))
  );

  ///TODO: rewrite using reduce?
  return aggregated.map((row, index) => ({
    ...row,
    periods: genereratePeriodsFromRowPeriod(row, aggregatedData[index]),
  }));
};

const genereratePeriodsFromRowPeriod = (
  row: AccountInformation,
  periods: SummedSaldo[]
): SummedSaldo[] => {
  const ibUbData = periods;
  return periods.map((period, index) => {
    if (index === 0) {
      ibUbData[index].ib = getIbSaldo(row, period.yearNo);
      ibUbData[index].ub = getIbSaldo(row, period.yearNo) + period.totalSaldo;
    } else {
      ibUbData[index].ib = ibUbData[index - 1].ub;
      ibUbData[index].ub = ibUbData[index - 1].ub + period.totalSaldo;
    }

    return {
      ...period,
      ib: ibUbData[index].ib,
      ub: ibUbData[index].ub,
    };
  });
};

const parseTransactions: (
  sieObj: SieFile.SieFile
) => Transaction[] = sieObj => {
  let transactions: Transaction[] = [];
  const ver = sieObj.list('ver');
  ver.forEach(v => {
    (v.poster || []).forEach(t => {
      if (t.etikett === 'trans') {
        transactions.push({
          series: v.serie,
          verificationNumber: v.vernr,
          verificationDate: v.verdatum,
          account: t.kontonr,
          amount: parseFloat(t.belopp),
          transactionDate: t.transdat,
          transactionText: t.transtext,
          saldo: 0,
        });
      }
    });
  });

  return transactions;
};

export interface PeriodsObject {
  periods: string[];
}

const mapSieInformationToTableData = (
  sieObj: SieFile.SieFile
): {
  aggregated: AccountInformation[];
  periods: PeriodsObject;
  financialYear: string;
  orgNr: string | undefined;
  transactions: Transaction[];
} => {
  const transactionsWithoutSaldos = parseTransactions(sieObj);
  const accountInformation = aggregateAccountInformation(
    sieObj,
    transactionsWithoutSaldos
  );
  const transactions = calculateTransactionSaldo({
    transactions: transactionsWithoutSaldos,
    accountInformation: accountInformation,
  });
  const period = sieObj.list('rar').filter(val => val.årsnr === '0')[0];
  const periods = _createPeriods(period);
  const aggregated = addPeriodIbAndUb(accountInformation, periods.periods);
  const orgNr =
    sieObj.list('orgnr') &&
    sieObj.list('orgnr')[0] &&
    sieObj.list('orgnr')[0].orgnr;

  return {
    aggregated,
    periods,
    financialYear: `${period.start}-${period.slut}`,
    orgNr: orgNr,
    transactions,
  };
};

export default mapSieInformationToTableData;
