import React from 'react';
import {
  Checkbox,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import CurrencyField from 'components/UI/Inputs/CurrencyField';
import EditField from 'components/UI/Inputs/EditField';
import StringInput from '../../UI/StringInput';
import { numberValue, stringValue } from 'utils/AnnualReport/cell';
import { useDispatch } from 'react-redux';
import {
  toggleTableRowActive,
  updateAnnualReportCellValue,
} from 'redux/actions/AnnualReportView/actions';
import { useIntl } from 'react-intl';

const VotingListRow = ({ tableId, row, print }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const rowId = `${tableId}.${row.id}`;

  const handleNameChange = name => {
    dispatch(updateAnnualReportCellValue(`${rowId}.name`, name));
  };

  const handleSharesChange = votes => {
    dispatch(updateAnnualReportCellValue(`${rowId}.shares`, votes));
  };

  const handleVotesChange = votes => {
    dispatch(updateAnnualReportCellValue(`${rowId}.votes`, votes));
  };

  const handleRoleChange = event => {
    dispatch(updateAnnualReportCellValue(`${rowId}.role`, event.target.value));
  };

  const handleActiveChange = () => {
    dispatch(toggleTableRowActive(rowId));
  };

  if (print && !row.active) return null;

  return (
    <TableRow>
      {!print && (
        <TableCell>
          <Checkbox
            checked={row.active === undefined ? true : row.active}
            onChange={handleActiveChange}
          />
        </TableCell>
      )}
      <TableCell>
        {print || rowId.endsWith('-signature') ? (
          stringValue(row.cells?.name)
        ) : (
          <EditField
            value={stringValue(row.cells?.name) || ''}
            component={StringInput}
            onChange={handleNameChange}
          />
        )}
      </TableCell>
      <TableCell>
        {print ? (
          numberValue(row.cells?.shares) || '-'
        ) : (
          <CurrencyField
            value={numberValue(row.cells?.shares)}
            onValueChange={handleSharesChange}
            Input={StringInput}
          />
        )}
      </TableCell>
      <TableCell>
        {print ? (
          numberValue(row.cells?.votes) || '-'
        ) : (
          <CurrencyField
            value={numberValue(row.cells?.votes)}
            onValueChange={handleVotesChange}
            Input={StringInput}
          />
        )}
      </TableCell>
      <TableCell>
        {print ? (
          <Typography>
            {formatMessage({
              id: `annualReport.annualGeneralMeeting.role.${stringValue(
                row.cells?.role
              )}`,
              defaultMessage: '',
            })}
          </Typography>
        ) : (
          <Select
            value={stringValue(row.cells?.role) || ''}
            onChange={handleRoleChange}
            fullWidth
            disableUnderline
          >
            <MenuItem value="chairman">
              {formatMessage({
                id: 'annualReport.annualGeneralMeeting.role.chairman',
              })}
            </MenuItem>
            <MenuItem value="recordKeeper">
              {formatMessage({
                id: 'annualReport.annualGeneralMeeting.role.recordKeeper',
              })}
            </MenuItem>
            <MenuItem value="adjuster">
              {formatMessage({
                id: 'annualReport.annualGeneralMeeting.role.adjuster',
              })}
            </MenuItem>
            <MenuItem value="chairmanAndRecordKeeper">
              {formatMessage({
                id:
                  'annualReport.annualGeneralMeeting.role.chairmanAndRecordKeeper',
              })}
            </MenuItem>
            <MenuItem value="">(Ingen)</MenuItem>
          </Select>
        )}
      </TableCell>
    </TableRow>
  );
};

export default VotingListRow;
