import { Signatures } from 'types/AnnualReport/parts/signatures';
import { table, field, value } from './util';

export const signaturesConfig = (): Signatures => ({
  type: 'part',
  section: {
    location: field(''),
    date: field(''),
    people: table(
      'section.people',
      'givenName',
      'surName',
      'socialSecurityNumber',
      'role',
      'accountantRole'
    )
      .newRowTemplate(
        value('$givenName'),
        value('$surName'),
        value('$socialSecurityNumber'),
        value('$role'),
        value('$accountantRole')
      )
      .build(),
    auditReportDate: field(''),
    statement: field(''),
  },
});

export const signaturesReferences = (): Record<string, string> => ({});
