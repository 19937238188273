import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  SetAnnualReportConfigAction,
  UpdateAnnualReportCellValueAction,
  AddAnnualReportColumnAction,
  UpdateAnnualReportCellReferencesAction,
  UpdateAnnualReportField,
  ToggleAnnualReportSectionActive,
  AddAnnualReportRow,
  DeleteAnnualReportRow,
  DeleteAnnualReportColumnAction,
  ToggleTableRowActive,
  SetAnnualReportTypeAction,
  AddAnnualReportSectionAction,
  UpdateNotesNumberingAction,
  DeleteAnnualReportSectionAction,
  SetAnnualReportChangesAction,
  UpdateAnnualReportChangesAction,
  SetAnnualReportAction,
  SetReferencedNotesAction,
} from './types';
import {
  SET_ANNUAL_REPORT_CONFIG,
  UPDATE_ANNUAL_REPORT_CELL_REFERENCES,
  ADD_ANNUAL_REPORT_COLUMN,
  UPDATE_ANNUAL_REPORT_CELL_VALUE,
  UPDATE_ANNUAL_REPORT_FIELD,
  TOGGLE_ANNUAL_REPORT_SECTION_ACTIVE,
  ADD_ANNUAL_REPORT_ROW,
  DELETE_ANNUAL_REPORT_ROW,
  DELETE_ANNUAL_REPORT_COLUMN,
  TOGGLE_TABLE_ROW_ACTIVE,
  TOGGLE_ANNUAL_REPORT_FIELD_ACTIVE,
  SET_ANNUAL_REPORT_TYPE,
  ADD_ANNUAL_REPORT_SECTION,
  UPDATE_NOTES_NUMBERING,
  DELETE_ANNUAL_REPORT_SECTION,
  SET_ANNUAL_REPORT_CHANGES,
  TOGGLE_ANNUAL_REPORT_TABLE_ACTIVE,
  UPDATE_ANNUAL_REPORT_CHANGES,
  SET_ANNUAL_REPORT,
  SET_REFERENCED_NOTES,
  UPDATE_ANNUAL_REPORT_COLUMN_LABEL,
} from 'redux/actionsTypes';
import {
  AnnualReport,
  AnnualReportChanges,
  AnnualReportPartKey,
  AnnualReportType,
  Cell,
} from 'types/AnnualReport/types';
import { RootState } from 'redux/reducers';
import { Action } from 'redux';
import { resolveAnnualReport } from 'utils/AnnualReport/resolveAnnualReport';
import { config } from 'utils/AnnualReport/config';
import { addGlobalErrorMessage } from '../Messages';
import { getAnnualReport } from 'components/Api/Client/annualReport';
import { findRow } from 'redux/reducers/AnnualReportView/reducer';
import {
  BALANCE_SHEET_ACCOUNT_ROW_PERIOD,
  BALANCE_SHEET_ACCOUNT_ROW_PREVIOUS_PERIOD,
  BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX,
} from 'utils/AnnualReport/constants';
import removeChangesById from 'utils/AnnualReport/removeChangesById';
import { stringValue, labelValue } from 'utils/AnnualReport/cell';
import { parentId } from 'utils/AnnualReport/config/util';

const setNewAnnualReportConfigAction = (
  config: AnnualReport
): SetAnnualReportConfigAction => ({
  type: SET_ANNUAL_REPORT_CONFIG,
  config,
});

const setAnnualReport = (report: AnnualReport): SetAnnualReportAction => ({
  type: SET_ANNUAL_REPORT,
  report,
});

export const loadAnnualReport = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch, getState) => {
  const { currentCustomer, currentYear } = getState().customerView;
  if (currentCustomer && currentYear) {
    try {
      const storedAnnualReport = await getAnnualReport(
        currentCustomer,
        currentYear
      );
      if (storedAnnualReport) {
        const { type, ...changes } = storedAnnualReport;
        dispatch(setAnnualReportChanges(changes, type));

        updateAnnualReportConfig(dispatch, getState);
      }
    } catch (e) {
      dispatch(addGlobalErrorMessage('annualReport.failedToLoad'));
    }
  }
};

const setAnnualReportChanges = (
  changes: AnnualReportChanges,
  type: AnnualReportType
): SetAnnualReportChangesAction => ({
  type: SET_ANNUAL_REPORT_CHANGES,
  reportType: type,
  changes,
});

const updateAnnualReportChanges = (
  changes: AnnualReportChanges
): UpdateAnnualReportChangesAction => ({
  type: UPDATE_ANNUAL_REPORT_CHANGES,
  changes,
});

export const updateAnnualReportConfig = (
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>,
  getState: () => RootState
) => {
  const customerId = getState().customerView.currentCustomer;
  if (customerId) {
    const customer = getState().customers[customerId];
    const sieData = getState().accountingView.sieData;
    if (sieData && customer) {
      const { accounts, periods } = sieData;
      const { currentPeriod, currentFinancialYear } = getState().customerView;

      const type = getState().annualReport.type;
      if (customer && accounts && currentPeriod && currentFinancialYear) {
        try {
          dispatch(
            setAnnualReportConfig(
              config(
                customer,
                periods[periods.length - 1] === currentPeriod
                  ? null
                  : currentPeriod,
                currentFinancialYear,
                accounts,
                type
              )
            )
          );

          updateAnnualReportReferences(dispatch, getState);
        } catch (e) {
          console.error(e);
          dispatch(addGlobalErrorMessage('error'));
        }
      }
    }
  }
};

export const updateAnnualReportReferences = (
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>,
  getState: () => RootState,
  checkReferencedNotes = true
) => {
  const accounts = getState().accountingView.sieData?.accounts;
  const periods = getState().accountingView.sieData?.periods;
  const { report, references, referencedNotes } = getState().annualReport;
  const { currentPeriod } = getState().customerView;

  if (report && accounts && periods && currentPeriod) {
    const [newReport, newReferencedNotes] = resolveAnnualReport(
      report,
      references,
      {
        accounts,
        period:
          periods[periods.length - 1] === currentPeriod ? null : currentPeriod,
        periods,
      }
    );

    if (newReport !== report) {
      dispatch(setAnnualReport(newReport));
    }

    if (checkReferencedNotes) {
      const referencedNotesChanged =
        referencedNotes.length !== newReferencedNotes.length ||
        referencedNotes.some((ref, index) => ref !== newReferencedNotes[index]);

      if (referencedNotesChanged) {
        dispatch(setReferencedNotes(newReferencedNotes));
        const notesBeforeNumbering = getState().annualReport.report?.notes;
        dispatch(updateNotesNumbering());

        // If the numbering of notes has change, update all references again but
        // without the checking the referenced notes again.
        if (notesBeforeNumbering !== getState().annualReport.report?.notes) {
          updateAnnualReportReferences(dispatch, getState, false);
        }
      }
    }
  }
};

export const setAnnualReportConfig = (
  config: AnnualReport
): ThunkAction<void, RootState, unknown, Action<string>> => (
  dispatch,
  getState
) => {
  dispatch(setNewAnnualReportConfigAction(config));

  updateAnnualReportReferences(dispatch, getState);
};

export const updateAnnualReportCellReferences = (
  id: string,
  references: string[]
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(updateAnnualReportCellReferencesAction(id, references));

  updateAnnualReportReferences(dispatch, getState);
};

const updateAnnualReportCellReferencesAction = (
  id: string,
  references: string[]
): UpdateAnnualReportCellReferencesAction => ({
  type: UPDATE_ANNUAL_REPORT_CELL_REFERENCES,
  id,
  references,
});

export const updateAnnualReportCellValue = (
  id: string,
  cell: Cell | string | number | undefined
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  switch (typeof cell) {
    case 'string':
      dispatch(
        updateAnnualReportCellValueAction(id, { type: 'string', value: cell })
      );
      break;
    case 'number':
    case 'undefined':
      dispatch(
        updateAnnualReportCellValueAction(id, { type: 'number', value: cell })
      );
      break;
    default:
      dispatch(updateAnnualReportCellValueAction(id, cell));
  }
  updateAnnualReportReferences(dispatch, getState);
};

const updateAnnualReportCellValueAction = (
  id: string,
  cell: Cell
): UpdateAnnualReportCellValueAction => ({
  type: UPDATE_ANNUAL_REPORT_CELL_VALUE,
  id,
  cell,
});

export const addAnnualReportTableColumn = (
  part: AnnualReportPartKey,
  section: string,
  table: string,
  index: number,
  label: string,
  type: 'string' | 'number'
): AddAnnualReportColumnAction => ({
  type: ADD_ANNUAL_REPORT_COLUMN,
  part,
  section,
  table,
  index,
  cellType: type,
  column: {
    id: `@${Date.now()}`,
    label,
  },
});

export const deleteAnnualReportColumn = (
  id: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  const action: DeleteAnnualReportColumnAction = {
    type: DELETE_ANNUAL_REPORT_COLUMN,
    id,
  };
  dispatch(action);

  updateAnnualReportReferences(dispatch, getState);
};

export const updateAnnualReportColumnLabel = (id: string, label: string) => ({
  type: UPDATE_ANNUAL_REPORT_COLUMN_LABEL,
  id,
  label,
});

export const updateAnnualReportField = (
  id: string,
  value: string | number | undefined
): ThunkAction<void, RootState, unknown, Action<string>> => (
  dispatch,
  getState
) => {
  const action: UpdateAnnualReportField = {
    type: UPDATE_ANNUAL_REPORT_FIELD,
    id,
    value,
  };
  dispatch(action);
  updateAnnualReportReferences(dispatch, getState);
};

export const toggleAnnualReportFieldActive = (
  part: AnnualReportPartKey,
  section: string,
  field: string
) => ({
  type: TOGGLE_ANNUAL_REPORT_FIELD_ACTIVE,
  part,
  section,
  field,
});

export const toggleAnnualReportTableActive = (
  part: AnnualReportPartKey,
  section: string,
  table: string
) => ({
  type: TOGGLE_ANNUAL_REPORT_TABLE_ACTIVE,
  part,
  section,
  table,
});

export const toggleAnnualReportSectionActive = (
  part: AnnualReportPartKey,
  section: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(toggleAnnualReportSectionActiveAction(part, section));
  if (part === 'notes') {
    dispatch(updateNotesNumbering());
    updateAnnualReportReferences(dispatch, getState);
  }
};

const toggleAnnualReportSectionActiveAction = (
  part: AnnualReportPartKey,
  section: string
): ToggleAnnualReportSectionActive => ({
  type: TOGGLE_ANNUAL_REPORT_SECTION_ACTIVE,
  part,
  section,
});

export const toggleTableRowActive = (id: string): ToggleTableRowActive => ({
  type: TOGGLE_TABLE_ROW_ACTIVE,
  id,
});

export const addAnnualReportRow = (
  id: string,
  rowId?: string,
  cellParameters?: Record<string, string>,
  copyId?: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(addAnnualReportRowAction(id, rowId, cellParameters, copyId));

  updateAnnualReportReferences(dispatch, getState);
};

const addAnnualReportRowAction = (
  id: string,
  rowId?: string,
  cellParameters?: Record<string, string>,
  copyId?: string
): AddAnnualReportRow => ({
  type: ADD_ANNUAL_REPORT_ROW,
  id,
  rowId,
  cellParameters,
  copyId,
});

export const deleteAnnualReportRow = (
  id: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(deleteAnnualReportRowAction(id));

  updateAnnualReportReferences(dispatch, getState);
};

const deleteAnnualReportRowAction = (id: string): DeleteAnnualReportRow => ({
  type: DELETE_ANNUAL_REPORT_ROW,
  id,
});

const createAccountRowId = (account: string, columns: 'all' | 'ib' | 'ub') =>
  columns === 'all'
    ? account
    : `${account}${BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX}${columns}`;

const getColumnsFromRow = (id: string): 'all' | 'ib' | 'ub' => {
  if (id.endsWith('ib')) {
    return 'ib';
  }
  if (id.endsWith('ub')) {
    return 'ub';
  }
  return 'all';
};

export const moveAnnualReportAccountRow = (
  id: string,
  targetId: string,
  columns: 'all' | 'ib' | 'ub' = 'all'
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  if (targetId === parentId(id)) {
    // Moving to same parent, ignore
    return;
  }

  const accountRow = findRow(id, getState().annualReport);
  const targetRow = findRow(targetId, getState().annualReport);
  if (accountRow && targetRow?.rows) {
    const actualColumns =
      columns === 'all' ? getColumnsFromRow(accountRow.id) : columns;

    const account = accountRow.id.split(
      BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX
    )[0];
    const label = labelValue(accountRow.cells?.label) || 'ERROR';

    if (actualColumns !== 'all' && !accountRow.id.endsWith(actualColumns)) {
      // Add row for the remaining columns
      dispatch(
        addAnnualReportRowAction(
          parentId(id),
          createAccountRowId(account, actualColumns === 'ib' ? 'ub' : 'ib'),
          {
            label,
            ib: actualColumns === 'ib' ? '' : account,
            ub: actualColumns === 'ib' ? account : '',
          }
        )
      );
    }

    // Check if this account already exists in the target
    const existingAccountRows = targetRow.rows?.filter(row =>
      row.id.includes(account)
    );

    if (actualColumns === 'all') {
      if (existingAccountRows.length > 0) {
        console.error('Existing account rows', existingAccountRows);
        return;
      }
      dispatch(
        addAnnualReportRowAction(targetId, account, {
          label,
          ib: account,
          ub: account,
        })
      );
      dispatch(deleteAnnualReportRowAction(id));
    } else {
      if (existingAccountRows.length > 0) {
        if (existingAccountRows.length > 1) {
          console.error(
            'Multiple rows of same account found in target',
            existingAccountRows
          );
          return;
        }
        const existingColumns = getColumnsFromRow(existingAccountRows[0].id);
        if (
          (actualColumns === 'ib' && existingColumns === 'ub') ||
          (actualColumns === 'ub' && existingColumns === 'ib')
        ) {
          // Right type of existing row, merge them.
          dispatch(
            addAnnualReportRowAction(targetId, account, {
              label,
              ib: account,
              ub: account,
            })
          );
          dispatch(deleteAnnualReportRowAction(id));
          dispatch(
            deleteAnnualReportRowAction(
              `${targetId}.${existingAccountRows[0].id}`
            )
          );
        } else {
          console.error(
            `Wrong type (${existingColumns} of existing account row in target for source type ${actualColumns}`
          );
          return;
        }
      } else {
        // No existing account row in target
        dispatch(
          addAnnualReportRowAction(
            targetId,
            createAccountRowId(account, actualColumns),
            {
              label,
              ib: actualColumns === 'ib' ? account : '',
              ub: actualColumns === 'ib' ? '' : account,
            }
          )
        );
        dispatch(deleteAnnualReportRow(id));
      }
    }

    updateAnnualReportReferences(dispatch, getState);
  }
};

const accountRowIdCurrentPeriodSuffix = `${BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX}ub`;
const accountRowIdPreviousPeriodSuffix = `${BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX}ib`;
const getStrippedAccountId = (id: string) => {
  const accountId = id.substring(id.lastIndexOf('.') + 1);
  if (accountId.includes(accountRowIdCurrentPeriodSuffix))
    return accountId.substring(
      0,
      accountId.indexOf(accountRowIdCurrentPeriodSuffix)
    );
  if (accountId.includes(accountRowIdPreviousPeriodSuffix))
    return accountId.substring(
      0,
      accountId.indexOf(accountRowIdPreviousPeriodSuffix)
    );
  return accountId;
};

const getRef = (cell: Cell | undefined) =>
  cell?.type === 'ref' ? cell.reference : undefined;

export const splitAnnualReportAccountRow = (
  id: string,
  targetId: string,
  splitType: string // previousYear = ib, year = ub
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  const state = getState().annualReport;
  const splittedRow = findRow(id, state);
  const targetRow = findRow(targetId, state);

  if (!splittedRow || !targetRow) return null;

  const { cells: splittedRowCells } = splittedRow;

  // user dragging ub part of account row
  if (splitType === BALANCE_SHEET_ACCOUNT_ROW_PERIOD) {
    const isAlreadySplitRow = id.indexOf(accountRowIdCurrentPeriodSuffix) > -1;

    if (!isAlreadySplitRow) {
      // add new row to parent node of splitted row with previous period (ib)
      const parentRowId = id.substring(0, id.lastIndexOf('.'));
      if (parentRowId) {
        dispatch(
          addAnnualReportRowAction(
            `${parentRowId}`,
            `${splittedRow.id}${accountRowIdPreviousPeriodSuffix}`,
            {
              label: stringValue(splittedRowCells?.label) || '',
              ib: getRef(splittedRowCells?.previousYear) || '',
            }
          )
        );
      }
    }

    // check if ib part is present in target row
    const accountBaseId = getStrippedAccountId(id);
    const previousPeriodRow = targetRow.rows?.filter(
      row => row.id === `${accountBaseId}${accountRowIdPreviousPeriodSuffix}`
    )[0];

    if (isAlreadySplitRow && previousPeriodRow) {
      // add full new row
      dispatch(
        addAnnualReportRowAction(`${targetId}`, `${accountBaseId}`, {
          label: stringValue(splittedRowCells?.label) || '',
          ub: getRef(splittedRowCells?.year) || '',
          ib: getRef(previousPeriodRow?.cells?.previousYear) || '',
        })
      );

      // delete target split part
      dispatch(deleteAnnualReportRow(`${targetId}.${previousPeriodRow.id}`));
    } else {
      // add new row to target with current period (ub)
      dispatch(
        addAnnualReportRowAction(
          `${targetId}`,
          `${accountBaseId}${accountRowIdCurrentPeriodSuffix}`,
          {
            label: stringValue(splittedRowCells?.label) || '',
            ub: getRef(splittedRowCells?.year) || '',
          }
        )
      );
    }

    // delete original row from parent
    dispatch(deleteAnnualReportRow(id));
  }

  // user dragging ib part of year
  if (splitType === BALANCE_SHEET_ACCOUNT_ROW_PREVIOUS_PERIOD) {
    const isAlreadySplitRow = id.indexOf(accountRowIdPreviousPeriodSuffix) > -1;
    if (!isAlreadySplitRow) {
      // add new row to parent node of splitted row with current period (ub)
      const parentRowId = id.substring(0, id.lastIndexOf('.'));
      if (parentRowId) {
        dispatch(
          addAnnualReportRowAction(
            `${parentRowId}`,
            `${splittedRow.id}${accountRowIdCurrentPeriodSuffix}`,
            {
              label: stringValue(splittedRowCells?.label) || '',
              ub: getRef(splittedRowCells?.year) || '',
            }
          )
        );
      }
    }
    // check if ib part is present in target row
    const accountBaseId = getStrippedAccountId(id);
    const currentPeriodRow = targetRow.rows?.filter(
      row => row.id === `${accountBaseId}${accountRowIdCurrentPeriodSuffix}`
    )[0];

    if (isAlreadySplitRow && currentPeriodRow) {
      // add full new row
      dispatch(
        addAnnualReportRowAction(`${targetId}`, `${accountBaseId}`, {
          label: stringValue(splittedRowCells?.label) || '',
          ub: getRef(currentPeriodRow?.cells?.year) || '',
          ib: getRef(splittedRowCells?.previousYear) || '',
        })
      );

      // delete target split part
      dispatch(deleteAnnualReportRow(`${targetId}.${currentPeriodRow.id}`));
    } else {
      // add new row to target with previous period (ib)
      dispatch(
        addAnnualReportRowAction(
          `${targetId}`,
          `${accountBaseId}${accountRowIdPreviousPeriodSuffix}`,
          {
            label: stringValue(splittedRowCells?.label) || '',
            ib: getRef(splittedRowCells?.previousYear) || '',
          }
        )
      );
    }

    // delete original row from parent
    dispatch(deleteAnnualReportRow(id));
  }

  return updateAnnualReportReferences(dispatch, getState);
};

export const setAnnualReportType = (
  type: AnnualReportType
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(setAnnualReportTypeAction(type));

  updateAnnualReportConfig(dispatch, getState);
};

export const setAnnualReportTypeAction = (
  reportType: AnnualReportType
): SetAnnualReportTypeAction => ({
  type: SET_ANNUAL_REPORT_TYPE,
  reportType,
});

export const addAnnualReportSection = (
  id: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(addAnnualReportSectionAction(id));
  updateAnnualReportReferences(dispatch, getState);
};

const addAnnualReportSectionAction = (
  id: string
): AddAnnualReportSectionAction => ({
  type: ADD_ANNUAL_REPORT_SECTION,
  id,
});

export const updateNotesNumbering = (): UpdateNotesNumberingAction => ({
  type: UPDATE_NOTES_NUMBERING,
});

export const deleteAnnualReportSection = (
  id: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(deleteAnnualReportSectionAction(id));

  updateAnnualReportReferences(dispatch, getState);
};

const deleteAnnualReportSectionAction = (
  id: string
): DeleteAnnualReportSectionAction => ({
  type: DELETE_ANNUAL_REPORT_SECTION,
  id,
});

export const resetAnnualReportContent = (
  id: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(
    updateAnnualReportChanges(
      removeChangesById(getState().annualReport.changes, id)
    )
  );

  updateAnnualReportConfig(dispatch, getState);
};

const setReferencedNotes = (notes: string[]): SetReferencedNotesAction => ({
  type: SET_REFERENCED_NOTES,
  notes,
});
