import gql from 'graphql-tag';

export const ADD_ORGANISATION = gql`
  mutation addOrganisation($name: String!) {
    addOrganisation(name: $name) {
      id: partitionKey
      name
      members
      admins
    }
  }
`;

export const INVITE_TO_ORGANISATION = gql`
  mutation addOrganisationInvite($email: String!) {
    addOrganisationInvite(email: $email)
  }
`;

export const ACCEPT_ORG_INVITE = gql`
  mutation acceptInvite($organisationId: String!) {
    acceptInvite(organisationId: $organisationId) {
      id: partitionKey
      name
      members
    }
  }
`;

export const DELETE_INVITE = gql`
  mutation deleteInvite($email: String!) {
    deleteInvite(email: $email)
  }
`;

export const DELETE_MEMBER = gql`
  mutation deleteMember($email: String!) {
    deleteMember(email: $email)
  }
`;

export const SET_PAYMENT_METHOD = gql`
  mutation setPaymentMethod(
    $paymentMethodId: String!
    $billingEmail: String!
    $payDirectly: Boolean
  ) {
    setPaymentMethod(
      paymentMethodId: $paymentMethodId
      billingEmail: $billingEmail
      payDirectly: $payDirectly
    )
  }
`;

export const SET_ORG_DETAILS = gql`
  mutation setBillingDetails(
    $address: String
    $postalCode: String
    $city: String
    $orgNumber: String
    $country: String
  ) {
    setBillingDetails(
      address: $address
      postalCode: $postalCode
      city: $city
      orgNumber: $orgNumber
      country: $country
    ) {
      name
    }
  }
`;
