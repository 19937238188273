/**
 * When a customer is returned from the api, the id resides within the "sortKey"
 * attribute. That is converted into an id attribute here
 */
export const parseCustomers = async (
  customers
): Promise<Customer.CustomerType[]> => {
  return Promise.all(
    customers.map(
      (customer): Promise<Customer.CustomerType> => {
        const sortKey = customer.sortKey!;
        const customerId = sortKey.substring(
          sortKey.indexOf('-') + 1,
          sortKey.length
        );
        const parsedCustomer: Customer.CustomerType = {
          ...customer,
          status: customer.status ? customer.status : 'active', // AGOY-495 if status isn't set we assume active
          lamps: customer.lamps ? JSON.parse(customer.lamps) : {},
          id: customerId,
        };
        return fetchLogo(parsedCustomer);
      }
    )
  );
};

export const parseCustomer = async (
  customer
): Promise<Customer.CustomerType> => {
  return (await parseCustomers([customer]))[0];
};

/**
 * Fetch the logo for a customer, and returns a customer object with the logo property, in base64.
 */
export const fetchLogo = async (
  customer: Customer.CustomerType
): Promise<Customer.CustomerType> => {
  if (customer.logo) {
    try {
      const response = await fetch(customer.logo);
      if (response.ok) {
        const blob = await response.blob();
        return new Promise(resolve => {
          const reader = new FileReader();
          reader.onload = e => {
            if (e.target !== null) {
              resolve(
                typeof e.target.result === 'string'
                  ? { ...customer, logo: e.target.result }
                  : customer
              );
            } else {
              resolve(customer);
            }
          };
          reader.onerror = e => {
            // Don't reject, it's just an image
            console.error(e);
            resolve(customer);
          };
          reader.readAsDataURL(blob);
        });
      } else {
        return { ...customer, logo: undefined };
      }
    } catch (e) {
      console.error(e);
    }
  }
  return customer;
};

export const parseOrganisation = rawOrganisation => {
  if (!rawOrganisation) return rawOrganisation;
  rawOrganisation.invited.forEach((invited, index) => {
    rawOrganisation.invited[index] = invited.substring(
      invited.indexOf('-') + 1,
      invited.length
    );
  });
  return rawOrganisation;
};
