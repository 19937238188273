import React from 'react';
import { useIntl } from 'react-intl';
import {
  Grid,
  WithTheme,
  Button,
  Typography,
  Paper,
  Avatar,
} from '@material-ui/core';
import { VerifiedUser } from '@material-ui/icons';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';

const Container = styled(Paper)<WithTheme>`
  padding: ${props => props.theme.spacing(2)}px;
  min-width: 330px;
`;

const MemberAvatar = styled(Avatar)`
  height: 50px;
  width: 50px;
`;

const AccessBadge = styled.div<WithTheme & { authorized?: boolean }>`
  display: flex;
  color: ${props =>
    props.authorized
      ? props.theme.palette.success.main
      : props.theme.palette.error.main};
`;

interface UserAccessListItemProps {
  name: string | React.ReactNode | React.ReactNodeArray;
  email?: string;
  authorized?: boolean;
  updatingAccess: boolean;
  accessChangeHandler: () => void;
}

export const UserAccessListItem = ({
  name,
  email,
  authorized,
  updatingAccess,
  accessChangeHandler,
}: UserAccessListItemProps) => {
  const intl = useIntl();
  const picture = useSelector(state => state.user.picture);
  return (
    <Container elevation={1}>
      <Grid container spacing={1}>
        <Grid item style={{ alignSelf: 'center' }}>
          <MemberAvatar src={picture ? picture : undefined} />
        </Grid>
        <Grid container direction="column" xs={true} item>
          {name && (
            <Grid item>
              <Typography>{name}</Typography>
            </Grid>
          )}
          {email && (
            <Grid item>
              <Typography>{email}</Typography>
            </Grid>
          )}
          <Grid container justify="space-between" item>
            <Grid container alignItems="center" item xs={true}>
              <AccessBadge authorized={authorized}>
                <VerifiedUser fontSize="small" />
              </AccessBadge>
              <Typography variant="body2" component="span">
                {authorized
                  ? intl.formatMessage({
                      id: 'dashboard.customers.edit.access',
                    })
                  : intl.formatMessage({
                      id: 'dashboard.customers.edit.noAccess',
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                size="small"
                onClick={accessChangeHandler}
                disabled={updatingAccess}
              >
                {authorized
                  ? intl.formatMessage({
                      id: 'dashboard.customers.edit.removeAccess',
                    })
                  : intl.formatMessage({
                      id: 'dashboard.customers.edit.grantAccess',
                    })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

interface ClientAccessListItem {
  client: Partial<Customer.CustomerType>;
  authorized: boolean;
  accessChangeHandler: () => void;
}

export const ClientAccessListItem = ({
  client,
  authorized,
  accessChangeHandler,
}: ClientAccessListItem) => {
  const intl = useIntl();

  return (
    <Container elevation={3}>
      <Grid container spacing={1}>
        <Grid item style={{ alignSelf: 'center' }}>
          <MemberAvatar src={client.logo} />
        </Grid>
        <Grid container direction="column" xs={true} item>
          <Grid item>
            <Typography variant="body1">{client.name}</Typography>
          </Grid>
          <Grid container justify="space-between" item>
            <Grid container alignItems="center" item xs={true}>
              <AccessBadge authorized={authorized}>
                <VerifiedUser fontSize="small" />
              </AccessBadge>
              <Typography variant="body2" component="span">
                {authorized
                  ? intl.formatMessage({
                      id: 'dashboard.members.edit.access',
                    })
                  : intl.formatMessage({
                      id: 'dashboard.members.edit.noAccess',
                    })}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                size="small"
                onClick={accessChangeHandler}
              >
                {authorized
                  ? intl.formatMessage({
                      id: 'dashboard.customers.edit.removeAccess',
                    })
                  : intl.formatMessage({
                      id: 'dashboard.customers.edit.grantAccess',
                    })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserAccessListItem;
