import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Button, Typography, WithTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Section from '../Section';
import Cell from '../../../UI/Cell';
import { ResultsDisposition as ResultsDispositionType } from 'types/AnnualReport/parts/managementReport';
import { useDispatch } from 'react-redux';
import { addAnnualReportRow } from 'redux/actions/AnnualReportView/actions';
import MultilineField from '../UI/MultilineField';
import Heading from '../../../UI/Heading';
import ResetContentButton from '../../../UI/ResetContent/ResetContentButton';
import EditingIconButton from '../../../UI/EditingIconButton';

const Label = styled(Typography)<WithTheme>`
  color: ${props => props.theme.palette.text.secondary};
`;

const GridTable = styled.div<WithTheme>`
  width: min-content;
  display: grid;
  gap: ${props => props.theme.spacing(2)}px;
  grid-template-columns: 20rem 5rem;
  margin-bottom: ${props => props.theme.spacing(2)}px;
`;

interface ResultsDispositionProps {
  id: string;
  print: boolean;
  reportPart: ResultsDispositionType | undefined;
}

const ResultsDisposition = ({
  id,
  print,
  reportPart,
}: ResultsDispositionProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);

  if (!reportPart) return null;

  const title = intl.formatMessage({
    id: 'annualReport.menu.managementReport.resultsDisposition',
  });

  return (
    <Section>
      <Heading
        title={title}
        id="resultsDisposition"
        controls={
          print ? (
            undefined
          ) : (
            <>
              <EditingIconButton
                value={editing}
                onClick={() => setEditing(value => !value)}
              />
              <ResetContentButton id={id} what={title} />
            </>
          )
        }
      />

      {!print && (
        <GridTable>
          <Label>Vald utdelning</Label>
          <Typography>
            <Cell
              editing={editing}
              cell={reportPart.selectedDividend}
              id="managementReport.resultsDisposition.selectedDividend"
            />
          </Typography>
        </GridTable>
      )}

      <MultilineField
        header={
          <Typography variant="h3">
            Förslag till behandling av bolagets resultat
          </Typography>
        }
        field={reportPart.propose}
        id={`${id}.propose`}
        print={print}
        editing={editing}
      />

      <Typography variant="h3">Till årsstämmans förfogande står</Typography>

      <GridTable>
        {reportPart.toDispose.rows.map(
          row =>
            row.cells?.label &&
            row.cells.value && (
              <React.Fragment key={row.id}>
                <Label>
                  <Cell
                    cell={row.cells?.label}
                    id={`${id}.toDispose.${row.id}.label`}
                    editing={editing}
                  />
                </Label>
                <Typography>
                  <Cell
                    cell={row.cells?.value}
                    id={`${id}.toDispose.${row.id}.value`}
                    editing={editing}
                  />
                </Typography>
              </React.Fragment>
            )
        )}
        {editing && (
          <>
            <Label>
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  dispatch(addAnnualReportRow(`${id}.toDispose`));
                }}
              >
                {intl.formatMessage({ id: 'add.row' })}
              </Button>
            </Label>
            <Label />
          </>
        )}
        <Label>Total</Label>
        <Typography>
          <Cell
            cell={reportPart.total}
            id="managementReport.resultsDisposition.total"
          />
        </Typography>
      </GridTable>

      <Typography variant="h3">Styrelsen föreslår att</Typography>
      <GridTable>
        {reportPart.proposal.rows.map(
          row =>
            row.cells?.label &&
            row.cells.value && (
              <React.Fragment key={row.id}>
                <Label>
                  <Cell
                    cell={row.cells?.label}
                    id={`${id}.proposal.${row.id}.label`}
                    editing={editing}
                  />
                </Label>
                <Typography>
                  <Cell
                    cell={row.cells?.value}
                    id={`${id}.proposal.${row.id}.value`}
                    editing={editing}
                  />
                </Typography>
              </React.Fragment>
            )
        )}
        {editing && (
          <>
            <Label>
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  dispatch(addAnnualReportRow(`${id}.proposal`));
                }}
              >
                {intl.formatMessage({ id: 'add.row' })}
              </Button>
            </Label>
            <Label />
          </>
        )}
        <Label>I ny räkning överföres</Label>
        <Typography>
          <Cell
            cell={reportPart.toTransfer}
            id="managementReport.resultsDisposition.toTransfer"
            editing={editing}
          />
        </Typography>
      </GridTable>
    </Section>
  );
};

export default ResultsDisposition;
