import { DeleteAnnualReportSectionAction } from 'redux/actions/AnnualReportView/types';
import { isAnnualReportPartKey } from 'types/AnnualReport/types';
import AnnualReportState from '../types';
import { replaceByIndex, splitSection } from './helpers/util';

const deleteAnnualReportSection = (
  state: AnnualReportState,
  action: DeleteAnnualReportSectionAction
) => {
  const { id } = action;
  if (!state.report) {
    return state;
  }
  const [part, sectionId] = id.split('.');

  if (!isAnnualReportPartKey(part)) {
    return state;
  }

  const [section, sectionIndex] = splitSection(sectionId);
  if (sectionIndex === undefined) {
    return state;
  }

  if (!Array.isArray(state.report[part][section].sections)) {
    return state;
  }
  return {
    ...state,
    report: {
      ...state.report,
      [part]: {
        ...state.report[part],
        [section]: {
          ...state.report[part][section],
          sections: replaceByIndex(
            state.report[part][section].sections,
            sectionIndex,
            null
          ),
        },
      },
    },
    changes: {
      ...state.changes,
      [part]: {
        ...state.changes[part],
        [section]: {
          ...state.changes[part]?.[section],
          [sectionIndex]: null,
        },
      },
    },
  };
};

export default deleteAnnualReportSection;
