import {
  AnnualReport,
  Cell,
  isNumberCell,
  isReferenceCell,
  isMultiReferenceCell,
  AnnualReportTable,
  AnnualReportTableRow,
  contentDefinition,
  isFormatMessageCell,
} from 'types/AnnualReport/types';
import { resolveReference } from 'utils/References/resolveReference';
import {
  ResolveReferenceContext,
  ResolveReferenceInput,
  ResolvedReference,
  ReferenceError,
} from 'utils/References/types';
import updateReportValues from './updateReportValues';

const collectFromRow = <T extends Cell, U>(
  baseId: string,
  row: AnnualReportTableRow,
  filter: (cell: Cell) => cell is T,
  collector: (id: string, cell: T) => void
) => {
  if (row.cells) {
    Object.entries(row.cells)
      .filter((column): column is [string, T] => filter(column[1]))
      .forEach(([colId, cell]) => {
        collector(`${baseId}.${colId}`, cell);
      });
  }
  if (row.rows) {
    row.rows.forEach(row => {
      collectFromRow(`${baseId}.${row.id}`, row, filter, collector);
    });
  }
};

const collectFromSection = <T extends Cell>(
  sectionData,
  sectionDefinition,
  key: string,
  filter: (cell: Cell) => cell is T,
  collector: (id: string, cell: T) => void,
  contentKey: string
) => {
  const contentType = sectionDefinition[contentKey];
  if (contentType === 'table') {
    const table: AnnualReportTable = sectionData?.[contentKey];
    table?.rows.forEach(row => {
      collectFromRow(`${key}.${row.id}`, row, filter, collector);
    });
  } else if (contentType === 'field') {
    const field = sectionData?.[contentKey];
    if (field && filter(field)) {
      collector(key, field);
    }
  }
};

const collect = <T extends Cell>(
  data: AnnualReport,
  filter: (cell: Cell) => cell is T,
  collector: (id: string, cell: T) => void
): void => {
  Object.keys(contentDefinition).forEach(part => {
    Object.keys(contentDefinition[part]).forEach(section => {
      const sectionDefinition = contentDefinition[part][section];
      const sectionData = data[part]?.[section];
      if (sectionDefinition.type === 'sections') {
        sectionData?.sections?.forEach((sectionItem, index) => {
          Object.keys(contentDefinition[part][section]).forEach(contentKey =>
            collectFromSection(
              sectionItem,
              sectionDefinition,
              `${part}.${section}-${index}.${contentKey}`,
              filter,
              collector,
              contentKey
            )
          );
        });
      } else {
        Object.keys(contentDefinition[part][section]).forEach(contentKey =>
          collectFromSection(
            sectionData,
            sectionDefinition,
            `${part}.${section}.${contentKey}`,
            filter,
            collector,
            contentKey
          )
        );
      }
    });
  });
};

const collectReferences = (
  data: AnnualReport,
  baseReferences: Record<string, string>
): Record<string, string> => {
  const refs: Record<string, string> = { ...baseReferences };
  collect(data, isReferenceCell, (id, cell) => {
    refs[id] = cell.reference;
  });
  collect(data, isFormatMessageCell, (id, cell) => {
    if (cell.parameterReferences) {
      Object.entries(cell.parameterReferences).forEach(([key, ref]) => {
        refs[`${id}.${key}`] = ref;
      });
    }
  });
  collect(data, isMultiReferenceCell, (id, cell) => {
    cell.references.forEach((ref, index) => {
      refs[`${id}-${index}`] = ref;
    });
  });
  return refs;
};

const collectValues = (data: AnnualReport): Record<string, number> => {
  const values: Record<string, number> = {};
  collect(data, isNumberCell, (id, cell) => {
    if (cell.value !== undefined) {
      values[id] = cell.value;
    }
  });
  return values;
};

export const expandIds = (allIds: string[]) => (
  ids: string,
  context: ResolveReferenceContext
): string[] => {
  const parts = ids.split('*');
  const result = allIds.filter(id => {
    if (id.startsWith(parts[0])) {
      let position = parts[0].length;
      for (let i = 1; i < parts.length; i++) {
        const start = position;
        if (parts[i].length === 0 && i === parts.length - 1) {
          return true;
        }
        position = id.indexOf(parts[i], position);
        if (position === -1) {
          return false;
        }
        let dot = id.indexOf('.', start);
        if (dot !== -1 && dot < position) {
          return false;
        }
        position += parts[i].length;
      }
      // Last position should be at the end.
      return position === id.length;
    }
    return false;
  });
  return result;
};

export const calculateReferences = (
  data: AnnualReport,
  baseReferences: Record<string, string>,
  input: ResolveReferenceInput
): Record<string, ResolvedReference> => {
  const values: Record<string, ResolvedReference> = collectValues(data);
  const references = collectReferences(data, baseReferences);
  const allIds = [
    ...new Set([...Object.keys(values), ...Object.keys(references)]),
  ];

  const calculatedReferences: Record<string, ResolvedReference> = {};

  const context: ResolveReferenceContext = {
    resolveById: (id, context) => {
      if (id in values) {
        return values[id];
      }
      const ref = references[id];

      if (ref) {
        if (calculatedReferences[ref] !== undefined) {
          return calculatedReferences[ref];
        }
        const refValue = resolveReference(ref, context);
        calculatedReferences[ref] = refValue;
        return refValue;
      }
      return ReferenceError.MissingId;
    },
    resolveConfig: (name, context) => undefined,
    expandIds: expandIds(allIds),
    input,
  };

  Object.entries(references).forEach(([id, reference]) => {
    if (calculatedReferences[reference] === undefined) {
      values[id] = calculatedReferences[reference] = resolveReference(
        reference,
        context
      );
    }
  });

  return calculatedReferences;
};

/**
 * Extracts the ids of referenced notes, the references must be "id(notes.[the note].number)"
 */
export const extractReferencedNotes = (
  references: Record<string, ResolvedReference>
): string[] => {
  const all = Object.keys(references)
    .filter(ref => ref.startsWith('id(notes.'))
    .filter(ref => ref.endsWith('.number)'))
    .map(ref => {
      return ref.substring(3, ref.length - 8);
    })
    .sort();

  return all;
};

/**
 * resolveAnnualReport calculates all references and updates the report.
 *
 * It also extracts the ids of all referenced notes
 */
export const resolveAnnualReport = (
  report: AnnualReport,
  baseReferences: Record<string, string>,
  input: ResolveReferenceInput
): [AnnualReport, string[]] => {
  const references = calculateReferences(report, baseReferences, input);

  Object.keys(references)
    .filter(ref => ref.startsWith('notes.'))
    .map(ref => ref.replace(/^(notes\..*?)\..*/, '$1'));

  return [
    updateReportValues(report, references),
    extractReferencedNotes(references),
  ];
};
