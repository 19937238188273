import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import IndexPage from './UI/Print/IndexPage';
import Page from './UI/Print/Page';
import CashFlowStatementPreview from './Parts/CashFlowStatement/CashFlowStatementPreview';
import SignaturesPreview from './Parts/Signatures/SignaturesPreview';
import ManagementReportPreview from './Parts/ManagementReport/ManagementReportPreview';
import AnnualGeneralMeetingPreview from './Parts/AnnualGeneralMeeting/AnnualGeneralMeetingPreview';
import NotesPreview from './Parts/Notes/NotesPreview';
import BalanceSheetPreview from './Parts/BalanceSheet/BalanceSheetPreview';
import IncomeStatementPreview from './Parts/IncomeStatement/IncomeStatementPreview';

const AnnualReportPrint = ({ pageNumbers }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/index`}>
        <IndexPage pageNumbers={pageNumbers} />
      </Route>
      <Route path={`${path}/managementReport`}>
        <Page>
          <ManagementReportPreview />
        </Page>
      </Route>
      <Route path={`${path}/incomeStatement`}>
        <Page>
          <IncomeStatementPreview />
        </Page>
      </Route>
      <Route path={`${path}/balanceSheet`}>
        <Page>
          <BalanceSheetPreview />
        </Page>
      </Route>
      <Route path={`${path}/cashFlowStatement`}>
        <Page>
          <CashFlowStatementPreview />
        </Page>
      </Route>
      <Route path={`${path}/notes`}>
        <Page>
          <NotesPreview />
        </Page>
      </Route>
      <Route path={`${path}/signatures`}>
        <Page>
          <SignaturesPreview />
        </Page>
      </Route>
      <Route path={`${path}/annualGeneralMeeting`}>
        <Page>
          <AnnualGeneralMeetingPreview />
        </Page>
      </Route>
      <Route path={path} exact>
        <IndexPage pageNumbers={pageNumbers} />
        <Page>
          <ManagementReportPreview />
        </Page>
        <Page>
          <IncomeStatementPreview />
        </Page>
        <Page>
          <BalanceSheetPreview />
        </Page>
        {'cashFlowStatement' in pageNumbers && (
          <Page>
            <CashFlowStatementPreview />
          </Page>
        )}
        <Page>
          <NotesPreview />
        </Page>
        <Page>
          <SignaturesPreview />
        </Page>
        <Page>
          <AnnualGeneralMeetingPreview />
        </Page>
      </Route>
    </Switch>
  );
};

export default AnnualReportPrint;
