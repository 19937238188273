import React from 'react';
import PreviewSection from '../../UI/PreviewSection';
import ManagementReportContent from './ManagementReportContent';

const ManagementReportPreview = () => {
  return (
    <PreviewSection id="managementReport">
      <ManagementReportContent print={true} />
    </PreviewSection>
  );
};

export default ManagementReportPreview;
