import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addAnnualReportRow } from 'redux/actions/AnnualReportView/actions';
import { NoteTwoTableSection } from 'types/AnnualReport/parts/notes';
import { numberValue, stringValue } from 'utils/AnnualReport/cell';
import Comment from '../../UI/Comment';
import Header from '../../UI/Header';
import { Note } from '../../UI/Note';
import ThreeColumnTable from '../../UI/ThreeColumnTable';

type Note23Props = { print?: boolean } & NoteTwoTableSection;

const Note23 = ({
  active,
  number,
  table,
  secondTable,
  comment,
  print = false,
}: Note23Props) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);

  if (!active) return null;

  return (
    <Note id="note23">
      <Header
        noteId="note23"
        noteNumber={numberValue(number)}
        editing={editing}
        onEditToggle={editing => {
          setEditing(!editing);
        }}
        print={print}
      />

      <Box mb={print ? 1 : 2}>
        <ThreeColumnTable
          table={table}
          tableId="notes.note23.table"
          editing={editing}
          onAddRow={() => {
            dispatch(addAnnualReportRow('notes.note23.table'));
          }}
          print={print}
        />
      </Box>

      <ThreeColumnTable
        table={secondTable}
        tableId="notes.note23.secondTable"
        editing={editing}
        print={print}
      />

      {/* Comment */}
      <Comment
        id="notes.note23.comment"
        editing={editing}
        value={stringValue(comment) || ''}
        print={print}
      />
    </Note>
  );
};

export default Note23;
