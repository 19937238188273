import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useLazyQuery } from 'react-apollo';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {
  WithTheme,
  Select,
  MenuItem,
  Button,
  Typography,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { GenericModal } from 'components/Modals/GenericModal';
import FileDropArea from 'components/UI/FileDropArea';
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from 'components/UI/ConfirmationDialog';
import Document from './Document';
import { PUT_DOCUMENT } from 'Graphql/Mutations';
import { LIST_DOCUMENTS } from 'Graphql/Queries';
import { formatFinancialYear } from 'utils';
import Downloadlink from 'utils/DownloadLink';
import LoadingLogo from '../../../UI/LoadingLogo';

const Container = styled.div<WithTheme>`
  padding: ${props => props.theme.spacing(2)}px
    ${props => props.theme.spacing(4)}px;
  width: 100%;
`;

const BreadCrumbs = styled.div``;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const UploadArea = styled(FileDropArea)<WithTheme>`
  background: ${props => props.theme.palette.accountingView.cell.ok};
  border: ${props => `1px dashed ${props.theme.palette.secondary.main}`};
  padding: ${props => props.theme.spacing(4)}px;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: ${props => props.theme.spacing(2)}px 0;
`;

const UploadButton = styled(Button)<WithTheme>`
  margin-bottom: ${props => props.theme.spacing(2)}px;
` as typeof Button;

const HiddenUploadInput = styled.input`
  display: none;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr min-content min-content;
`;

const TableHeader = styled.div<WithTheme>`
  white-space: nowrap;
  padding: ${props => props.theme.spacing(1)}px;
`;

const LoadingIndicator = styled.div<WithTheme>`
  margin: 0 ${props => props.theme.spacing(4)}px;
  display: flex;
  justify-content: center;
`;

const DocumentPage = ({ customerId, open, handleClose }) => {
  const name = useSelector(state => state.customers[customerId]?.name);
  const [selectedYear, setSelectedYear] = useState<string | undefined>();
  const [autoDownload, setAutoDownload] = useState<
    { url: string; name: string } | undefined
  >();

  const [
    listDocuments,
    { data: files, loading: loadingFiles, refetch },
  ] = useLazyQuery(LIST_DOCUMENTS);

  const currentYear: any = useSelector(state => state.customerView.currentYear);
  const financialYears = useSelector(
    state => state.customers[customerId]?.financialYears
  );
  const [uploading, setUploading] = useState(false);

  const [putDocument, { error: putDocumentError }] = useMutation(PUT_DOCUMENT);

  useEffect(() => {
    if (selectedYear) {
      listDocuments({
        variables: {
          customerId,
          year: selectedYear,
        },
      });
    }
  }, [selectedYear, customerId, listDocuments]);

  useEffect(() => {
    if (open) {
      setSelectedYear(
        financialYears?.find(year => year.startsWith(currentYear)) ||
          (financialYears && financialYears[financialYears.length - 1])
      );
    }
  }, [open, financialYears, currentYear]);

  const onSelectYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedYear(event.target.value as string);
  };

  const uploadFile = useCallback(
    async (file: File) => {
      const { name, type } = file;
      const baseVariables = {
        customerId,
        year: selectedYear,
      };
      setUploading(true);
      try {
        await putDocument({
          variables: {
            ...baseVariables,
            name: `${name}`,
            category: 'other',
            contentType: type,
          },
          update: async (store, { data: { putDocument } }) => {
            const { uploadUrl } = putDocument;
            await fetch(uploadUrl, {
              method: 'PUT',
              body: file,
              headers: {
                'Content-Type': type,
              },
            });
            const storeQuery = {
              query: LIST_DOCUMENTS,
              variables: baseVariables,
            };
            const data = store.readQuery(storeQuery) as any;
            store.writeQuery({
              ...storeQuery,
              data: {
                listDocuments: [
                  { ...putDocument, lastModified: new Date().toISOString() },
                  ...data.listDocuments,
                ],
              },
            });
            listDocuments({
              variables: baseVariables,
            });
            setUploading(false);
          },
        });
      } catch {
        setUploading(false);
      }
    },
    [selectedYear, putDocument, listDocuments, customerId]
  );

  const handleFileButton = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.files) {
        const file = event.currentTarget.files[0];
        if (file) {
          uploadFile(file);
        }
      }
    },
    [uploadFile]
  );

  const { formatMessage } = useIntl();

  const [
    verifyDeleteFile,
    setVerifyDeleteFile,
  ] = useState<ConfirmationDialogProps | null>(null);
  const verifyDelete = useCallback(
    (name: string, doDelete: () => void) => {
      setVerifyDeleteFile({
        text: formatMessage({ id: 'documents.confirm.delete' }, { name }),
        onAccept: doDelete,
        onClose: () => {
          setVerifyDeleteFile(null);
        },
      });
    },
    [formatMessage]
  );

  const sortedFiles = useMemo(() => {
    return [...(files?.listDocuments || [])].sort((a, b) =>
      a.lastModified.localeCompare(b.lastModified)
    );
  }, [files]);

  const handleInvalidDocument = async (error, file) => {
    const { data } = await refetch();
    const updatedFile = data.listDocuments?.filter(
      ({ name, category }) => name === file.name && category === file.category
    )[0];
    if (updatedFile) {
      setAutoDownload(updatedFile);
    }
  };

  return (
    <GenericModal open={open} handleClose={handleClose}>
      <Container>
        <BreadCrumbs>
          {name} / <strong>{formatMessage({ id: 'documents' })}</strong>
        </BreadCrumbs>
        <HeaderRow>
          <h1>{formatMessage({ id: 'documents.clientdocuments' })}</h1>
          <SelectWrapper>
            <Typography variant="body1" color="textSecondary">
              {formatMessage({ id: 'documents.year.label' })}
            </Typography>
            <Select
              value={selectedYear}
              disableUnderline
              onChange={onSelectYear}
            >
              {financialYears?.map(year => (
                <MenuItem key={year} value={year}>
                  {formatFinancialYear(year)}
                </MenuItem>
              ))}
            </Select>
          </SelectWrapper>
        </HeaderRow>
        {putDocumentError && (
          <Alert severity="error">
            <AlertTitle>
              {formatMessage({ id: 'documents.upload.error.title' })}
            </AlertTitle>
            {formatMessage({ id: 'documents.upload.error.text' })}
          </Alert>
        )}
        <UploadArea
          onDrop={uploadFile}
          isValidFile={() => true}
          onInvalidFileType={() => {}}
        >
          {uploading ? (
            <LoadingLogo size="medium" />
          ) : (
            <>
              <UploadButton
                startIcon={<PublishIcon />}
                component="label"
                color="secondary"
                variant="contained"
                disableElevation
              >
                {formatMessage({ id: 'documents.upload.button' })}
                <HiddenUploadInput type="file" onChange={handleFileButton} />
              </UploadButton>
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'documents.upload.drop' })}
              </Typography>
            </>
          )}
        </UploadArea>

        {verifyDeleteFile && <ConfirmationDialog {...verifyDeleteFile} />}
        {!loadingFiles && files && (
          <Table>
            <TableHeader>
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'documents.column.name' })}
              </Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'documents.column.date' })}
              </Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'documents.column.download' })}
              </Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'documents.column.delete' })}
              </Typography>
            </TableHeader>
            {sortedFiles.map(file => (
              <Document
                key={file.name}
                {...file}
                customerId={customerId}
                year={selectedYear}
                verifyDelete={verifyDelete}
                onInvalidDocument={e => handleInvalidDocument(e, file)}
              />
            ))}
          </Table>
        )}
        {loadingFiles && (
          <LoadingIndicator>
            <LoadingLogo size="medium" />
          </LoadingIndicator>
        )}
        {autoDownload && (
          <Downloadlink
            autoDownload
            href={autoDownload.url}
            title={autoDownload.name}
          />
        )}
      </Container>
    </GenericModal>
  );
};

export default DocumentPage;
