import React from 'react';
import styled from '@emotion/styled';
import Business from '@material-ui/icons/Business';

interface LogoProps {
  style?: any;
  className?: string;
  src: string | undefined;
}

const Fallback = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 8px #00000029;
`;

const Logo = ({ style, className, src }: LogoProps) => {
  return src ? (
    <img style={style} className={className} src={src} alt="" />
  ) : (
    <Fallback style={style} className={className}>
      <Business fontSize="large" />
    </Fallback>
  );
};

export default styled(Logo)`
  width: 64px;
  height: 64px;
  border-radius: 4px;
  object-fit: cover;
`;
