import React from 'react';
import Container from '../Parts/Container';

interface TabPanelProps {
  children?: React.ReactNode;
  name: string;
  index: any;
  value: any;
}

function CardTabPanel(props: TabPanelProps) {
  const { children, name, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-tabpanel-${index}`}
      aria-labelledby={`${name}-tab-${index}`}
      {...other}
    >
      {value === index && <Container>{children}</Container>}
    </div>
  );
}

export default CardTabPanel;
