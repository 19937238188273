import moment from 'moment';

interface period {
  date: string;
  hasData: boolean;
}

type financialYearsFunction = (financialYears: string[]) => period[];

/**
 * Will create period strings 12 months back and 6 months ahead
 */

const createPeriods: financialYearsFunction = financialYears => {
  const date = moment();
  date.add(-1, 'year');

  const periods = Array.from(Array(18).keys()).map((element, index) => {
    date.add(1, 'month');
    return date.format('YYYYMM');
  });

  return periods.map(period => {
    const output = {
      date: period,
      hasData: false,
    };

    if (financialYears && financialYears.length !== 0) {
      let periodAsInt = parseInt(period);

      for (let i = 0; i < financialYears.length; i++) {
        let [start, end] = financialYears[i].split('-');
        output.hasData =
          periodAsInt >= parseInt(start) && periodAsInt <= parseInt(end)
            ? true
            : false;
      }
    }

    return output;
  });
};

export default createPeriods;
