import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { updateAnnualReportField } from 'redux/actions/AnnualReportView/actions';
import { AnnualReportTable } from 'types/AnnualReport/table';
import NameTable from './NameTable';
import Paragraph from './Paragraph';

interface AuditorsParagraphProps {
  no: number;
  auditors: AnnualReportTable;
  deputyAuditors: AnnualReportTable;
  print: boolean;
  noAuditor: string;
}

const AuditorsParagraph = ({
  no,
  auditors,
  deputyAuditors,
  noAuditor,
  print,
}: AuditorsParagraphProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const numberOfAuditors = auditors.rows.filter(row => row.active).length;

  const handleNoAuditor = event => {
    const value = event.target.value;
    dispatch(
      updateAnnualReportField(
        'annualGeneralMeeting.main.noAuditor',
        value === noAuditor ? '' : value
      )
    );
  };

  /* If no selection is made in either assigning accountant 
  or choosing a checkbox option we render empty paragraph */
  if (
    auditors.rows.length === 0 &&
    deputyAuditors.rows.length === 0 &&
    !noAuditor &&
    print
  ) {
    return (
      <Paragraph no={no} id="12" editable={!print} hideSubheading={true} />
    );
  }

  return (
    <Paragraph
      no={no}
      id="12"
      editable={!print}
      hideSubheading={numberOfAuditors === 0 && print}
    >
      {editing => (
        <>
          <Typography component="div">
            <NameTable
              editing={editing}
              print={print}
              table={auditors}
              tableId="annualGeneralMeeting.main.auditors"
              addLabelId="annualReport.annualGeneralMeeting.add.auditor"
            />
          </Typography>
          {numberOfAuditors === 0 &&
            !print &&
            ['1', '2', '3'].map(option => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={noAuditor === option}
                    value={option}
                    onChange={handleNoAuditor}
                  />
                }
                label={formatMessage({
                  id: `annualReport.annualGeneralMeeting.no.auditor.${option}`,
                })}
                labelPlacement="end"
                key={option}
              />
            ))}

          {numberOfAuditors === 0 &&
            print &&
            ['1', '2', '3'].map(option => {
              if (noAuditor !== '' && noAuditor !== option) return null;
              return (
                <Typography>
                  {formatMessage({
                    id: `annualReport.annualGeneralMeeting.no.auditor.${noAuditor}`,
                  })}
                </Typography>
              );
            })}

          {(numberOfAuditors > 0 || noAuditor === '') && (
            <Box marginTop={2}>
              <Typography component="div">
                {formatMessage({
                  id: 'annualReport.annualGeneralMeeting.main.12.text2',
                })}
                <NameTable
                  editing={editing}
                  print={print}
                  table={deputyAuditors}
                  tableId="annualGeneralMeeting.main.deputyAuditors"
                  addLabelId="annualReport.annualGeneralMeeting.add.auditor"
                />
              </Typography>
            </Box>
          )}
        </>
      )}
    </Paragraph>
  );
};

export default AuditorsParagraph;
