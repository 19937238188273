export const SET_USER_LOGGED_IN = 'SET_USER_LOGGED_IN';
export const SET_USER_ATTRIBUTES = 'SET_USER_ATTRIBUTES';
export const USER_LOG_OUT = 'USER_LOG_OUT';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const SET_CUSTOMER_LOGO = 'SET_CUSTOMER_LOGO';
export const POPULATE_CUSTOMERS = 'POPULATE_CUSTOMERS';
export const USER_IS_AUTHENTICATING = 'USER_IS_AUTHENTICATING';
export const USER_NOT_AUTHENTICATING = 'USER_NOT_AUTHENTICATING';
export const SET_USER_FIRST_LOGIN = 'SET_USER_FIRST_LOGIN';
export const SET_ORGANISATION = 'SET_ORGANISATION';
export const INSERT_USER_TO_ORG = 'INSERT_USER_TO_ORG';
export const ADD_ORGANISATION_INFO = 'ADD_ORGANISATION_INFO';
export const SET_SIE_DATA = 'SET_SIE_DATA';
export const SET_USER_INPUT_DATA = 'SET_USER_INPUT_DATA';
export const SET_ROUTINES_DATA = 'SET_ROUTINES_DATA';
export const PATCH_USER_INPUT_DATA = 'PATCH_USER_INPUT_DATA';
export const PATCH_USER_INPUT_ROUTINE_DATA = 'PATCH_USER_INPUT_ROUTINE_DATA';
export const RESET_CUSTOMER = 'RESET_CUSTOMER';
export const TOGGLE_NEW_SIE_FILE_UPDATED = 'TOGGLE_NEW_SIE_FILE_UPDATED';
export const SET_ADJUSTMENT_DATA = 'SET_ADJUSTMENT_DATA';
export const SET_LAMP_STATUS = 'SET_LAMP_STATUS';
export const SET_CURRENT_YEAR = 'SET_CURRENT_YEAR';
export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';
export const TOGGLE_LAMPS_EXPANDED = 'TOGGLE_LAMPS_EXPANDED';
export const STARTED_SAVING_USER_DATA = 'STARTED_SAVING_USER_DATA';
export const SAVING_USER_DATA_FAILED = 'SAVING_USER_DATA_FAILED';
export const SAVING_USER_DATA_SUCCESS = 'SAVING_USER_DATA_SUCCESS';
export const TOGGLE_MINIMIZED_CARDS = 'TOGGLE_MINIMIZED_CARDS';
export const INCREASE_IMAGES = 'INCREASE_IMAGES';
export const DECREASE_IMAGES = 'DECREASE_IMAGES';
export const SET_NUM_IMAGES = 'SET_NUM_IMAGES';
export const REMOVE_INVITE = 'REMOVE_INVITE';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const TOGGLE_SINGLE_PERIOD = 'TOGGLE_SINGLE_PERIOD';
export const TOGGLE_PERIOD_CHANGE_VISIBILITY =
  'TOGGLE_PERIOD_CHANGE_VISIBILITY';
export const TOGGLE_PERIOD_UB_VISIBILITY = 'TOGGLE_PERIOD_UB_VISIBILITY';
export const SET_INTEGRATION_STATUS = 'SET_INTEGRATION_STATUS';
export const SET_FINANCIAL_YEARS = 'SET_FINANCIAL_YEARS';
export const SET_CURRENT_PERIOD = 'SET_CURRENT_PERIOD';
export const SET_TAX_VIEW_CONFIG = 'SET_TAX_VIEW_CONFIG';
export const SET_TAX_VIEW_STATE = 'SET_TAX_VIEW_STATE';
export const ADD_EMPTY_TAX_TABLE_ROW = 'ADD_EMPTY_TAX_TABLE_ROW';
export const DELETE_TAX_TABLE_ROW = 'DELETE_TAX_TABLE_ROW';
export const UPDATE_TAX_TABLE_ROW_LABEL = 'UPDATE_TAX_TABLE_ROW_LABEL';
export const UPDATE_TAX_TABLE_ROW_VALUE = 'UPDATE_TAX_TABLE_ROW_VALUE';
export const UPDATE_TAX_TABLE_ROW_REFERENCE = 'UPDATE_TAX_TABLE_ROW_REFERENCE';
export const SET_SUBSCRIPTION_STATUS = 'SET_SUBSCRIPTION_STATUS';

// Account list drawer
export const SET_ACCOUNT_LIST_DRAWER_EXPANDED =
  'SET_ACCOUNT_LIST_DRAWER_EXPANDED';
export const SET_ACCOUNT_LIST_DRAWER_MODE = 'SET_ACCOUNT_LIST_DRAWER_MODE';
export const ACCOUNT_LIST_DRAWER_ADD_ACCOUNT =
  'ACCOUNT_LIST_DRAWER_ADD_ACCOUNT';
export const ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT =
  'ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT';
export const ACCOUNT_LIST_DRAWER_CLEAR_SELECTION =
  'ACCOUNT_LIST_DRAWER_CLEAR_SELECTION';
export const ACCOUNT_LIST_DRAWER_SET_ACCOUNTS =
  'ACCOUNT_LIST_DRAWER_SET_ACCOUNTS';

export const UPDATE_TRANSACTION_ROW_ACCOUNT = 'UPDATE_TRANSACTION_ROW_ACCOUNT';
export const REMOVE_USER_TRANSACTIONS = 'REMOVE_USER_TRANSACTIONS';
export const RESET_YEAR_END_PLANNING = 'RESET_YEAR_END_PLANNING';
export const RESET_TAX_CALCULATION_TABLE = 'RESET_TAX_CALCULATION_TABLE';
export const RESET_TAX_CALCULATION_ADJUSTMENTS =
  'RESET_TAX_CALCULATION_ADJUSTMENTS';
export const UPDATE_YEAR_END_PLANNING_CHECKSUM =
  'UPDATE_YEAR_END_PLANNING_CHECKSUM';
export const ADD_GLOBAL_MESSAGE = 'ADD_GLOBAL_MESSAGE';
export const REMOVE_GLOBAL_MESSAGE = 'REMOVE_GLOBAL_MESSAGE';

// Annual Report
export const SET_ANNUAL_REPORT_TYPE = 'SET_ANNUAL_REPORT_TYPE';
export const SET_ANNUAL_REPORT_CONFIG = 'SET_ANNUAL_REPORT_CONFIG';
export const SET_ANNUAL_REPORT = 'SET_ANNUAL_REPORT';
export const SET_ANNUAL_REPORT_CHANGES = 'SET_ANNUAL_REPORT_CHANGES';
export const ADD_ANNUAL_REPORT_ROW = 'ADD_ANNUAL_REPORT_ROW';
export const ADD_ANNUAL_REPORT_COLUMN = 'ADD_ANNUAL_REPORT_COLUMN';
export const DELETE_ANNUAL_REPORT_COLUMN = 'DELETE_ANNUAL_REPORT_COLUMN';
export const UPDATE_ANNUAL_REPORT_COLUMN_LABEL =
  'UPDATE_ANNUAL_REPORT_COLUMN_LABEL';
export const UPDATE_ANNUAL_REPORT_CELL_VALUE =
  'UPDATE_ANNUAL_REPORT_CELL_VALUE';
export const UPDATE_ANNUAL_REPORT_CELL_REFERENCES =
  'UPDATE_ANNUAL_REPORT_CELL_REFERENCES';
export const UPDATE_ANNUAL_REPORT_FIELD = 'UPDATE_ANNUAL_REPORT_FIELD';
export const TOGGLE_ANNUAL_REPORT_SECTION_ACTIVE =
  'TOGGLE_ANNUAL_REPORT_SECTION_ACTIVE';
export const DELETE_ANNUAL_REPORT_ROW = 'DELETE_ANNUAL_REPORT_ROW';
export const ADD_ANNUAL_REPORT_SECTION = 'ADD_ANNUAL_REPORT_SECTION';
export const DELETE_ANNUAL_REPORT_SECTION = 'DELETE_ANNUAL_REPORT_SECTION';
export const UPDATE_NOTES_NUMBERING = 'UPDATE_NOTES_NUMBERING';
export const SET_REFERENCED_NOTES = 'SET_REFERENCED_NOTES';
export const UPDATE_ANNUAL_REPORT_CHANGES = 'UPDATE_ANNUAL_REPORT_CHANGES';

// Global
export const INIT_STATE = 'INIT_STATE';
export const TOGGLE_TABLE_ROW_ACTIVE = 'TOGGLE_TABLE_ROW_ACTIVE';
export const TOGGLE_ANNUAL_REPORT_FIELD_ACTIVE =
  'TOGGLE_ANNUAL_REPORT_FIELD_ACTIVE';

export const TOGGLE_ANNUAL_REPORT_TABLE_ACTIVE =
  'TOGGLE_ANNUAL_REPORT_TABLE_ACTIVE';
