import { ConfirmationCertificate } from 'types/AnnualReport/parts/confirmationCertificate';
import { field } from './util';

export const confirmationCertificateConfig = (): ConfirmationCertificate => ({
  type: 'part',
  section: {
    date: field(''),
    location: field(''),
    statement: field(''),
    signatureClarification: field(''),
  },
});

export const confirmationCertificateReferences = (): Record<
  string,
  string
> => ({});
