import React from 'react';
import styled from '@emotion/styled';
import { Button, Typography, WithTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteButton from 'components/UI/DeleteButton';
import { stringValue } from 'utils/AnnualReport/cell';
import EditField from 'components/UI/Inputs/EditField';
import StringInput from '../../UI/StringInput';
import {
  addAnnualReportRow,
  deleteAnnualReportRow,
  toggleTableRowActive,
  updateAnnualReportCellValue,
} from 'redux/actions/AnnualReportView/actions';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

const Names = styled.div<WithTheme>`
  margin: ${props => props.theme.spacing(1)}px 0;
`;

const Name = styled.div<WithTheme>`
  padding: ${props => props.theme.spacing(0.5)}px 0;
  width: 50%;
  display: flex;
  justify-content: space-between;
`;

const NameTable = ({ editing, print, table, tableId, addLabelId }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const handleAddName = () => {
    dispatch(addAnnualReportRow(tableId, undefined, { name: '' }));
  };
  return (
    <Names>
      {table.rows
        .filter(row => row.active)
        .map(row => {
          const handleNameChange = name => {
            dispatch(
              updateAnnualReportCellValue(`${tableId}.${row.id}.name`, name)
            );
          };
          const handleDelete = () => {
            if (row.id.endsWith('-signature')) {
              dispatch(toggleTableRowActive(`${tableId}.${row.id}`));
            } else {
              dispatch(deleteAnnualReportRow(`${tableId}.${row.id}`));
            }
          };
          return (
            <Name key={row.id}>
              {print ? (
                <Typography>{stringValue(row.cells?.name)}</Typography>
              ) : (
                <EditField
                  value={stringValue(row.cells?.name) || ''}
                  onChange={handleNameChange}
                  component={StringInput}
                />
              )}
              {editing && <DeleteButton onClick={handleDelete} />}
            </Name>
          );
        })}
      {!print && (
        <Button startIcon={<AddIcon />} onClick={handleAddName}>
          {formatMessage({ id: addLabelId })}
        </Button>
      )}
    </Names>
  );
};

export default NameTable;
