import { Typography } from '@material-ui/core';
import React from 'react';
import NameTable from './NameTable';
import Paragraph from './Paragraph';

const BoardMembersParagraph = ({ no, table, print }) => {
  return (
    <Paragraph no={no} id="11" editable={!print}>
      {editing => (
        <Typography component="div">
          <NameTable
            editing={editing}
            print={print}
            table={table}
            tableId="annualGeneralMeeting.main.boardMembers"
            addLabelId="annualReport.annualGeneralMeeting.add.member"
          />
        </Typography>
      )}
    </Paragraph>
  );
};

export default BoardMembersParagraph;
