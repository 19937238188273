import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Tooltip, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import styled from '@emotion/styled';
import moment from 'moment';

import { useSelector } from 'redux/reducers';

const StyledTooltip = styled(({ className, ...other }) => (
  <Tooltip classes={{ tooltip: className }} {...other} />
))`
  font-size: 1rem;
`;

interface FileUploadProps {
  intl: any;
  handleChange: (files: FileList | null) => void;
  showToolTip: boolean;
  sieUpdated: string | undefined;
  disabled: boolean;
}

const FileUpload = ({
  intl,
  handleChange,
  showToolTip,
  sieUpdated,
  disabled,
}: FileUploadProps) => {
  const [toolTipOpen, setToolTipOpen] = useState(false);

  const sieFileJustUpdated = useSelector(
    state => state.customerView.sieFileJustUpdated
  );

  useEffect(() => {
    if (showToolTip) {
      const timer = setTimeout(() => setToolTipOpen(true), 400);
      return () => clearTimeout(timer);
    } else {
      setToolTipOpen(false);
    }
  }, [showToolTip]);

  useEffect(() => {
    setToolTipOpen(sieFileJustUpdated);
  }, [sieFileJustUpdated]);

  const mouseEnter = () => {
    setToolTipOpen(true);
  };
  const mouseLeave = () => {
    setToolTipOpen(false);
  };

  const tooltipTitle = sieFileJustUpdated ? (
    // Multiline tooltip hack
    <>
      <Typography>
        {intl.formatMessage({ id: 'upload.new.sie.tooltip.title' })}
      </Typography>
      {intl.formatMessage({ id: 'upload.new.sie.tooltip.body' })}
    </>
  ) : sieUpdated ? (
    intl.formatMessage(
      { id: 'upload.file.tooltip.updated' },
      { time: moment(sieUpdated, moment.ISO_8601).format('YYYY-MM-DD HH:mm') }
    )
  ) : (
    intl.formatMessage({ id: 'upload.file.tooltip.default' })
  );

  return (
    <StyledTooltip open={toolTipOpen} arrow={true} title={tooltipTitle}>
      <Button
        startIcon={<DescriptionIcon />}
        component="label"
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        disabled={disabled}
      >
        {intl.formatMessage({ id: 'upload.file' })}
        <input
          data-cy="upload-file"
          type="file"
          onChange={e => handleChange(e.target.files)}
          style={{ display: 'none' }}
        />
      </Button>
    </StyledTooltip>
  );
};

export default injectIntl(FileUpload);
