import { Typography } from '@material-ui/core';
import React from 'react';
import { RootState, useSelector } from 'redux/reducers';
import styled from '@emotion/styled';
import Page from './Page';
import { formatFinancialYear } from 'utils';
import { useIntl } from 'react-intl';
import extractPartsToPrint from './extractPartsToPrint';
import AnnualReportState from 'redux/reducers/AnnualReportView/types';

const StyledPage = styled(Page)`
  text-align: center;
`;

const Title = styled(Typography)`
  margin-top: 130px;
  margin-bottom: 40px;
  font-size: 3rem;
  text-transform: uppercase;
`;

const For = styled(Typography)`
  font-size: 1.5rem;
  margin-bottom: 40px;
`;

const Logo = styled.img`
  margin-bottom: 40px;
  max-height: 250px;
  max-width: 80%;
`;

const CompanyName = styled(Typography)`
  font-size: 2rem;
`;

const Orgnr = styled(Typography)`
  margin-bottom: 24px;
`;

const Text = styled(Typography)`
  margin-bottom: 32px;
`;

const Index = styled.table`
  text-align: left;
  width: 60%;
  margin: 0 auto;

  thead td {
    font-weight: 700;
  }

  tr td :first-of-type {
    width: 90%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

interface IndexPageProps {
  pageNumbers: Record<string, number>;
}

const IndexPage = ({ pageNumbers }: IndexPageProps) => {
  const { formatMessage } = useIntl();
  const customer: Customer.CustomerType = useSelector((state: RootState) => {
    return state.customers[state.customerView.currentCustomer || ''];
  });
  const financialYears = useSelector(
    (state: RootState) =>
      state.customers[state.customerView.currentCustomer || '']
        ?.financialYears || []
  );
  const currentYear = useSelector(
    (state: RootState) => state.customerView.currentYear
  );

  const currentFinancialYear =
    currentYear &&
    financialYears.find(period => period.startsWith(currentYear));

  const annualReport: AnnualReportState = useSelector(
    (state: RootState) => state.annualReport
  );

  if (!customer) {
    return null;
  }

  const parts = extractPartsToPrint(annualReport);

  return (
    <StyledPage>
      <Title variant="h1">Årsredovisning</Title>
      <For>för</For>
      {customer.logo && <Logo src={customer.logo} alt="" />}

      <CompanyName>{customer.name}</CompanyName>
      <Orgnr>{`Org.nr. ${customer.orgNumber}`}</Orgnr>

      <Text>
        {`Styrelsen får härmed avlämna årsredovisning för räkenskapsåret ${formatFinancialYear(
          currentFinancialYear
        )}`}
      </Text>

      <Index>
        <thead>
          <tr>
            <th>Innehåll</th>
            <th align="right">Sida</th>
          </tr>
        </thead>
        <tbody>
          {parts.map(
            part =>
              pageNumbers[part] && (
                <tr>
                  <td>{formatMessage({ id: `annualReport.menu.${part}` })}</td>
                  <td align="right">
                    <a href={`#${part}`}>{pageNumbers[part]}</a>
                  </td>
                </tr>
              )
          )}
        </tbody>
      </Index>
    </StyledPage>
  );
};

export default IndexPage;
