import React from 'react';
import { accountStatus } from 'utils/accountGrouper';
import styled from '@emotion/styled';
import { getStatusColor } from 'utils';

interface ShapeElementProps {
  background: string;
}

const ShapeElement = styled.div`
  background: ${(props: ShapeElementProps) => props.background};
  height: 8px;
  width: 100%;
`;

interface PeriodShapeProps {
  status: accountStatus;
}

const PeriodShape = ({ status }: PeriodShapeProps) => {
  const background = getStatusColor(status);
  return <ShapeElement background={background} />;
};

export default PeriodShape;
