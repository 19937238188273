import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Section from '../../Section';
import YearTable from '../../UI/YearTable';
import styled from '@emotion/styled';
import EditingIconButton from 'components/Views/Customer/AnnualReport/UI/EditingIconButton';
import ResetContentButton from 'components/Views/Customer/AnnualReport/UI/ResetContent/ResetContentButton';
import Heading from 'components/Views/Customer/AnnualReport/UI/Heading';
import MultilineField from '../../UI/MultilineField';

const StyledYearTable = styled(YearTable)`
  .MuiTableCell-head {
    text-align: right;
  }
  .MuiTableCell-head :nth-of-type(1) {
    text-align: left;
  }
`;

const usePercentageForSolidity = (rowId: string) =>
  rowId === 'solidity' || rowId === 'resultPercentage'
    ? 'percentage'
    : 'standard';

const MultiYearOverview = ({ id, print, reportPart }) => {
  const { formatMessage } = useIntl();
  const intl = useIntl();
  const [editingTable, setEditingTable] = useState(false);

  const toggleActiveTable = () => {
    setEditingTable(editing => !editing);
  };

  if (!reportPart) return null;

  const table = reportPart.table;
  const title = intl.formatMessage({
    id: 'annualReport.menu.managementReport.multiYearOverview',
  });
  return (
    <Section>
      <Heading
        title={title}
        id="multiYearOverview"
        controls={
          print ? (
            undefined
          ) : (
            <>
              <EditingIconButton
                onClick={toggleActiveTable}
                value={editingTable}
              />
              <ResetContentButton id={id} what={title} />
            </>
          )
        }
      />

      <StyledYearTable
        tableId="managementReport.multiYearOverview.table"
        table={table}
        editing={editingTable}
        print={print}
        numberFormat={usePercentageForSolidity}
      />

      <MultilineField
        header={
          <Typography variant="h3">
            {formatMessage({
              id: `annualReport.managementReport.multiYearOverview.comments`,
            })}
          </Typography>
        }
        id={`${id}.multiYearText`}
        field={reportPart.multiYearText}
        print={print}
      />
    </Section>
  );
};

export default MultiYearOverview;
