import { ToggleTableRowActive } from 'redux/actions/AnnualReportView/types';
import { isAnnualReportPartKey } from 'types/AnnualReport/types';
import AnnualReportState from '../types';
import updateRow from './helpers/updateRow';

const toggleTableRowActive = (
  state: AnnualReportState,
  action: ToggleTableRowActive
) => {
  const { id } = action;
  if (!state.report) {
    return state;
  }
  const [part, section, table, ...subId] = id.split('.');
  if (!isAnnualReportPartKey(part)) {
    return state;
  }

  return updateRow(state, part, section, table, subId, (row, change) => {
    const active = row.active === undefined ? false : !row.active;
    return [
      { ...row, active },
      { ...change, row: { ...change.row, active } },
    ];
  });
};

export default toggleTableRowActive;
