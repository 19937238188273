import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, Typography, WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';
import {
  toggleAnnualReportFieldActive,
  toggleAnnualReportTableActive,
} from 'redux/actions/AnnualReportView/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';

const Section = styled.div<{ print: boolean } & WithTheme>`
  display: flex;
  justify-content: space-between;
  margin: ${props => (props.print ? '0.25rem' : '1rem')} 0;
`;

const ActiveToggle = styled.div`
  width: 160px;
  display: flex;
  align-items: center;
`;

type ToggableSection = {
  type: 'field' | 'table';
  section: string;
  id: string;
};

type ToggleMultiSectionProps = {
  headingTranslationKey: string;
  toggleSections: ToggableSection[];
  children: React.ReactNode;
  print?: boolean;
};

const ToggleMultiSection = ({
  headingTranslationKey,
  toggleSections,
  children,
  print = false,
}: ToggleMultiSectionProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const active = useSelector(state => {
    return toggleSections.every(
      toggleSection =>
        state.annualReport.report?.notes[toggleSection.section][
          toggleSection.id
        ].active
    );
  });

  const toggleFieldActive = useCallback(
    (toggleSections: ToggableSection[]) => {
      toggleSections.map(toggleSection => {
        if (toggleSection.type === 'table') {
          return dispatch(
            toggleAnnualReportTableActive(
              'notes',
              toggleSection.section,
              toggleSection.id
            )
          );
        }
        return dispatch(
          toggleAnnualReportFieldActive(
            'notes',
            toggleSection.section,
            toggleSection.id
          )
        );
      });
    },
    [dispatch]
  );

  const toggleCheckboxLabelId = active
    ? 'annualReport.notes.includedSection'
    : 'annualReport.notes.excludedSection';

  if (print && !active) {
    return null;
  }

  return (
    <>
      <Section print={print}>
        <Typography variant="h3">
          {formatMessage({
            id: `annualReport.notes.${headingTranslationKey}`,
          })}
        </Typography>
        {!print && (
          <ActiveToggle>
            <Checkbox
              checked={active}
              onChange={() => toggleFieldActive(toggleSections)}
            />
            <Typography>
              {formatMessage({
                id: toggleCheckboxLabelId,
              })}
            </Typography>
          </ActiveToggle>
        )}
      </Section>
      {active && children}
    </>
  );
};

export default ToggleMultiSection;
