import gql from 'graphql-tag';

export const ADD_NEW_CUSTOMER = gql`
  mutation addCustomer(
    $name: String!
    $type: String!
    $status: String!
    $closingPeriod: String
    $closingMonth: Int
    $manager: String!
    $vatPeriod: String
    $address: String
    $contact: String
    $email: String
    $number: String
    $orgNumber: String
  ) {
    addCustomer(
      name: $name
      type: $type
      status: $status
      closingPeriod: $closingPeriod
      closingMonth: $closingMonth
      manager: $manager
      vatPeriod: $vatPeriod
      address: $address
      contact: $contact
      email: $email
      number: $number
      orgNumber: $orgNumber
    ) {
      sortKey
      name
      type
      status
      closingPeriod
      closingMonth
      manager
      vatPeriod
      address
      contact
      email
      number
      orgNumber
      logo
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $customerId: String!
    $name: String!
    $type: String!
    $status: String
    $closingPeriod: String
    $closingMonth: Int
    $manager: String!
    $vatPeriod: String
    $address: String
    $contact: String
    $email: String
    $number: String
    $orgNumber: String
  ) {
    updateCustomer(
      customerId: $customerId
      name: $name
      type: $type
      status: $status
      closingPeriod: $closingPeriod
      closingMonth: $closingMonth
      manager: $manager
      vatPeriod: $vatPeriod
      address: $address
      contact: $contact
      email: $email
      number: $number
      orgNumber: $orgNumber
    ) {
      sortKey
      name
      type
      status
      closingPeriod
      closingMonth
      manager
      vatPeriod
      address
      contact
      email
      number
      orgNumber
      logo
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($id: String!) {
    deleteCustomer(id: $id)
  }
`;

export const ADD_LAMP_STATUS = gql`
  mutation putAccountStatus(
    $customerId: String!
    $period: String!
    $statuses: AccountStatusInput
  ) {
    putAccountStatus(
      customerId: $customerId
      period: $period
      statuses: $statuses
    ) {
      a1630
      moms
      rest
      a15xx
      a24xx
      a19xx
    }
  }
`;

export const PUT_CUSTOMER_LOGO = gql`
  mutation putCustomerLogo($customerId: String!, $contentType: String!) {
    putCustomerLogo(customerId: $customerId, contentType: $contentType) {
      url
      uploadUrl
    }
  }
`;
