import React from 'react';
import { useDispatch } from 'react-redux';
import {
  addAnnualReportRow,
  toggleTableRowActive,
} from 'redux/actions/AnnualReportView/actions';
import {
  TableCell,
  TableRow,
  Switch,
  Button,
  Tooltip,
} from '@material-ui/core';
import {
  AnnualReportTableColumn,
  AnnualReportTableRow,
} from 'types/AnnualReport/types';
import Cell, { NumberFormatType } from '../../../UI/Cell';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

interface TableRowProps {
  baseId: string;
  row: AnnualReportTableRow;
  columns: AnnualReportTableColumn[];
  addNewRowIds?: string[];
  skipColumns?: number[];
  numberFormat?: NumberFormatType;
  editing: boolean;
  print: boolean;
}

const StyledSwitch = styled(Switch)`
  position: absolute;
  left: -4rem;
  top: -1rem;
`;

const SwitchContainer = styled.div`
  position: relative;
`;

const StyledTableRow = styled(TableRow)`
  .MuiTableCell-root :first-of-type {
    padding: 0;
  }
`;

const GeneralTableRow = ({
  baseId,
  row,
  columns,
  addNewRowIds,
  skipColumns = [],
  numberFormat = 'standard',
  editing,
  print,
}: TableRowProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const toggleRow = row => {
    dispatch(toggleTableRowActive(`${baseId}.${row.id}`));
  };

  const addRow = () => {
    dispatch(addAnnualReportRow(`${baseId}.${row.id}`));
  };

  if (print && row.active === false) {
    return null;
  }

  return (
    <>
      {row.cells && (
        <StyledTableRow key={row.id}>
          {!print && (
            <TableCell>
              <SwitchContainer>
                <Tooltip
                  title={formatMessage({
                    id: 'annualReport.include.in.report',
                  })}
                >
                  <StyledSwitch
                    checked={row.active}
                    onChange={() => toggleRow(row)}
                  />
                </Tooltip>
              </SwitchContainer>
            </TableCell>
          )}
          {columns.map((col, index) => {
            const cell = row.cells?.[col.id];
            const key = `${row.id}.${col.id}`;
            const output: React.ReactNode[] = [];

            if (skipColumns.indexOf(index) !== -1) {
              output.push(<TableCell key={`${key}.1`} />);
            }

            if (!cell) {
              output.push(<TableCell key={key} />);
            } else {
              output.push(
                <TableCell key={key}>
                  <Cell
                    cell={cell}
                    id={`${baseId}.${key}`}
                    numberFormatType={numberFormat}
                    editing={editing}
                    print={print}
                  />
                </TableCell>
              );
            }

            return output;
          })}
        </StyledTableRow>
      )}

      {/* Print sub rows recursively */}

      {row.rows?.map(subRow => (
        <GeneralTableRow
          skipColumns={skipColumns}
          key={`${baseId}.${row.id}.${subRow.id}`}
          row={subRow}
          baseId={`${baseId}.${row.id}`}
          columns={columns}
          addNewRowIds={addNewRowIds}
          editing={editing}
          numberFormat={numberFormat}
          print={print}
        />
      ))}

      {/* If the row id id in addNewRowIds, add a "add new row" row  */}

      {editing && addNewRowIds?.includes(row.id) && (
        <TableRow>
          <TableCell colSpan={columns.length + skipColumns.length + 1}>
            <Button onClick={addRow}>Lägg till rad</Button>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default GeneralTableRow;
