import gql from 'graphql-tag';
export const ADD_EQUITYROW_DATA = gql`
  mutation putModuleData(
    $customerId: String!
    $period: String!
    $module: String!
    $inputBlob: AWSJSON!
  ) {
    putModuleData(
      customerId: $customerId
      period: $period
      module: $module
      inputBlob: $inputBlob
    ) {
      equityRowBlob
    }
  }
`;
