import { useAgoyAppMutate } from 'utils/AgoyAppClient/useAgoyAppMutate';
import { agoyAppClient } from 'utils/AgoyAppClient/useAgoyAppClient';
import { Voucher } from 'utils/TaxCalculation/types';
import AgoyAppClientContext from 'utils/AgoyAppClient/AgoyAppClientContext';

interface ActivateFortnoxRequest {
  authorizationCode: string;
}

interface FortnoxError {
  error: string;
  details: {
    message: string;
    code: number;
  };
}

type ActivateFortnox = (clientId: string, authorizationCode: string) => void;

const createActivateFortnoxRequest = (clientId, authorizationCode) => ({
  uri: `/api/client/${encodeURIComponent(clientId)}/integration/fortnox`,
  content: {
    authorizationCode,
  },
});

export const useActivateFortnox = () => {
  return useAgoyAppMutate<
    Parameters<ActivateFortnox>,
    ActivateFortnoxRequest,
    void,
    FortnoxError
  >(createActivateFortnoxRequest, 'POST');
};

type DeleteFortnoxIntegration = (clientId: string) => void;

const createDeleteFortnoxIntegratoinRequest = clientId => ({
  uri: `/api/client/${encodeURIComponent(clientId)}/integration/fortnox`,
});

export const useDeleteFortnoxIntegration = () => {
  return useAgoyAppMutate<
    Parameters<DeleteFortnoxIntegration>,
    void,
    FortnoxError
  >(createDeleteFortnoxIntegratoinRequest, 'DELETE');
};

const createFortnoxIntegrationStatusRequest = clientId => ({
  uri: `/api/client/${encodeURIComponent(clientId)}/integration/fortnox`,
});

type GetFortnoxIntegrationStatus = (
  clientId: string
) => Customer.IntegrationStatus;

export const useGetFortnoxIntegrationStatus = () => {
  return useAgoyAppMutate<
    Parameters<GetFortnoxIntegrationStatus>,
    void,
    ReturnType<GetFortnoxIntegrationStatus>
  >(createFortnoxIntegrationStatusRequest, 'GET');
};

interface FortnoxUpdateSIEResponse {
  updatedFinancialYears: string[];
}

type UpdateSIE = (clientId: string, year: number) => FortnoxUpdateSIEResponse;

interface UpdateSIERequest {
  year: string;
}

export const updateSIE = (context: AgoyAppClientContext) => {
  const client = agoyAppClient<
    FortnoxUpdateSIEResponse,
    UpdateSIERequest,
    FortnoxError
  >(context);
  return async (
    clientId: string,
    year: string
  ): Promise<FortnoxUpdateSIEResponse> => {
    const { uri, content } = createUpdateSIERequest(clientId, year);
    const response = await client(uri, 'POST', content);
    if (response.ok) {
      return response.body;
    }
    return Promise.reject(response.error);
  };
};

const createUpdateSIERequest = (clientId, year) => ({
  uri: `/api/client/${encodeURIComponent(
    clientId
  )}/integration/fortnox/update-sie`,
  content: { year: `${year}` },
});

export const useUpdateSIE = () => {
  return useAgoyAppMutate<
    Parameters<UpdateSIE>,
    UpdateSIERequest,
    ReturnType<UpdateSIE>,
    FortnoxError
  >(createUpdateSIERequest, 'POST');
};

type CreateVoucherRequest = (clientId: string, voucher: Voucher) => void;

const createCreateVoucherRequest = (clientId, voucher) => ({
  uri: `/api/client/${encodeURIComponent(
    clientId
  )}/integration/fortnox/voucher`,
  content: voucher,
});

export const useCreateVoucherRequest = () => {
  return useAgoyAppMutate<
    Parameters<CreateVoucherRequest>,
    CreateVoucherRequest,
    ReturnType<CreateVoucherRequest>,
    FortnoxError
  >(createCreateVoucherRequest, 'POST');
};
