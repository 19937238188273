import React, { useEffect, useState } from 'react';
import { Typography, Switch, FormControlLabel } from '@material-ui/core';
import { useIntl } from 'react-intl';
import BigTable from './BigTable';
import Section from '../../Section';
import { useSelector } from 'redux/reducers';
import { updateAnnualReportField } from 'redux/actions/AnnualReportView/actions';
import { ChangesInEquity as ChangesInEquityType } from 'types/AnnualReport/parts/managementReport';
import { stringValue } from 'utils/AnnualReport/cell';
import { useDispatch } from 'react-redux';
import YearTable from '../../UI/YearTable';
import Heading from 'components/Views/Customer/AnnualReport/UI/Heading';
import EditingIconButton from 'components/Views/Customer/AnnualReport/UI/EditingIconButton';
import ResetContentButton from 'components/Views/Customer/AnnualReport/UI/ResetContent/ResetContentButton';

interface ChangesInEquityProps {
  id: string;
  print: boolean;
  reportPart: ChangesInEquityType;
}

const ChangesInEquity = ({ id, print, reportPart }: ChangesInEquityProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [editingTable, setEditingTable] = useState(false);
  const reportType = useSelector(state => state.annualReport.type);
  const transition = stringValue(reportPart.transition) || '';
  const toggleActiveTable = () => {
    setEditingTable(editing => !editing);
  };

  const toggleTransition = event => {
    dispatch(
      updateAnnualReportField(
        `${id}.transition`,
        event.target.checked ? (reportType === 'k2' ? 'toK3' : 'toK2') : ''
      )
    );
  };
  const isTransition =
    (reportType === 'k2' && transition === 'toK3') ||
    (reportType === 'k3' && transition === 'toK2');

  useEffect(() => {
    // Reset illegal transition
    if (
      (reportType === 'k3' && transition === 'toK3') ||
      (reportType === 'k2' && transition === 'toK2')
    ) {
      dispatch(updateAnnualReportField(`${id}.transition`, ''));
    }
  }, [dispatch, id, transition, reportType]);

  if (!reportPart) return null;

  const table = reportPart.table;

  const title = intl.formatMessage({
    id: 'annualReport.menu.managementReport.changesInEquity',
  });

  return (
    <>
      <Section>
        <Heading
          title={title}
          id="changesInEquity"
          controls={
            print ? (
              undefined
            ) : (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isTransition}
                      onChange={toggleTransition}
                    />
                  }
                  label={intl.formatMessage({
                    id: `annualReport.menu.managementReport.changesInEquity.transition.from.${reportType}`,
                  })}
                />
                <EditingIconButton
                  value={editingTable}
                  onClick={toggleActiveTable}
                />
                <ResetContentButton id={id} what={title} />
              </>
            )
          }
        />
        <BigTable
          table={table}
          editing={editingTable}
          transition={transition}
          print={print}
        />
      </Section>
      {!(
        print &&
        !reportPart.conditionalShareholderContributions.rows.some(
          row => row.active !== false
        )
      ) && (
        <Typography variant="h3" color="textSecondary">
          {intl.formatMessage({
            id:
              'annualReport.menu.managementReport.changesInEquity.conditionalShareholderContributions',
          })}
        </Typography>
      )}
      <Section>
        <YearTable
          tableId="managementReport.changesInEquity.conditionalShareholderContributions"
          table={reportPart.conditionalShareholderContributions}
          editing={editingTable}
          print={print}
        />
      </Section>
    </>
  );
};

export default ChangesInEquity;
