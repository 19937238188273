import {
  useAgoyAppQuery,
  AgoyAppQuery,
} from 'utils/AgoyAppClient/useAgoyAppQuery';
import { useAgoyAppMutate } from 'utils/AgoyAppClient/useAgoyAppMutate';

type MemberRole = 'AccountingConsultant';

type MemberRoles = Record<string, MemberRole[]>;

type PutMemberRole = {
  clientId: string;
  roleName: string;
};

type useMemberRoles = (email: string) => AgoyAppQuery<MemberRoles>;

export const useMemberRoles: useMemberRoles = email => {
  return useAgoyAppQuery(
    email ? `/api/member/${encodeURIComponent(email)}/clients` : undefined
  );
};

type AddMemberRole = (
  email: string,
  clientId: string,
  role?: MemberRole
) => void;

const createAddMemberRoleRequest = (
  email: string,
  clientId: string,
  roleName: string = 'AccountingConsultant'
) => ({
  uri: `/api/admin/member-roles/${encodeURIComponent(email)}`,
  content: { clientId, roleName },
});

export const useAddMemberRole = () => {
  return useAgoyAppMutate<Parameters<AddMemberRole>, PutMemberRole>(
    createAddMemberRoleRequest,
    'PUT'
  );
};

type DeleteMemberRole = (email: string, clientId: string) => void;

const createDeleteMemberRoleRequest = (email: string, clientId: string) => ({
  uri: `/api/admin/member-roles/${encodeURIComponent(
    email
  )}/${encodeURIComponent(clientId)}`,
});

export const useDeleteMemberRole = () => {
  return useAgoyAppMutate<Parameters<DeleteMemberRole>>(
    createDeleteMemberRoleRequest,
    'DELETE'
  );
};
