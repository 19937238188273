import { ToggleAnnualReportFieldActive } from 'redux/actions/AnnualReportView/types';
import AnnualReportState from '../types';

const toggleAnnualReportFieldActive = (
  state: AnnualReportState,
  action: ToggleAnnualReportFieldActive
) => {
  const { part, section, field } = action;
  if (!state.report) {
    return state;
  }
  if (!state.report[part][section]) {
    return state;
  }
  return {
    ...state,
    report: {
      ...state.report,
      [part]: {
        ...state.report[part],
        [section]: {
          ...state.report[part][section],
          [field]: {
            ...state.report[part][section][field],
            active: !state.report[part][section][field].active,
          },
        },
      },
    },
    changes: {
      ...state.changes,
      [part]: {
        ...state.changes[part],
        [section]: {
          ...state.changes[part]?.[section],
          [field]: {
            ...state.changes[part]?.[section]?.[field],
            type: state.report[part][section][field].type,
            active: !state.report[part][section][field].active,
          },
        },
      },
    },
  };
};
export default toggleAnnualReportFieldActive;
