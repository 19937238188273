import { UpdateAnnualReportCellReferencesAction } from 'redux/actions/AnnualReportView/types';
import { isAnnualReportPartKey } from 'types/AnnualReport/types';
import { ReferenceError } from 'utils/References/types';
import AnnualReportState from '../types';
import updateCell from './helpers/updateCell';

const updateAnnualReportCellReferences = (
  state: AnnualReportState,
  action: UpdateAnnualReportCellReferencesAction
) => {
  if (!state.report) {
    return state;
  }
  const { id, references } = action;
  const [part, section, table, ...subId] = id.split('.');
  if (!subId || subId.length < 2) {
    console.error(`Too few id parts in ${id}`);
    return state;
  }

  if (!isAnnualReportPartKey(part)) {
    return state;
  }

  return updateCell(
    state,
    part,
    section,
    table,
    subId.slice(0, subId.length - 1),
    subId[subId.length - 1],
    cell => {
      if (cell.type === 'refs') {
        return {
          ...cell,
          references,
          values: references.map(_ => ReferenceError.NotResolved),
        };
      }
      console.error(
        `Trying to update a cell of type ${cell.type} with a reference`
      );
      return cell;
    }
  );
};

export default updateAnnualReportCellReferences;
