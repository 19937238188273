import React from 'react';
import AnnualGeneralMeetingPreview from './Parts/AnnualGeneralMeeting/AnnualGeneralMeetingPreview';
import Page from './UI/Print/Page';

const AnnualGeneralMeetingPrint = () => (
  <Page>
    <AnnualGeneralMeetingPreview />
  </Page>
);

export default AnnualGeneralMeetingPrint;
