import { AnnualReport, AnnualReportType } from 'types/AnnualReport/types';
import {
  managementReportConfig,
  managementReportReferences,
} from './managementReport';
import {
  cashFlowStatementConfig,
  cashFlowStatementReferences,
} from './cashFlowStatement';
import { balanceSheetConfig, balanceSheetReferences } from './balanceSheet';
import { notesConfig } from './notes';
import { signaturesConfig } from './signatures';
import { AccountInformation } from 'utils/SieParser';
import {
  incomeStatementConfig,
  incomeStatementReferences,
} from './incomeStatement';
import { annualGeneralMeetingConfig } from './annualGeneralMeeting';
import { confirmationCertificateConfig } from './confirmationCertificate';
import { settingsConfig } from './settings';

/**
 * Creates a new configuration of a annual report
 * @param customer
 */
export const config = (
  customer: Customer.CustomerType,
  period: string | null,
  financialYear: string,
  accounts: Record<string, AccountInformation>,
  type: AnnualReportType = 'k2'
): AnnualReport => {
  return {
    settings: settingsConfig(),
    confirmationCertificate: confirmationCertificateConfig(),
    managementReport: managementReportConfig(
      customer,
      financialYear,
      type,
      period
    ),
    incomeStatement: incomeStatementConfig(accounts, financialYear, type),
    balanceSheet: balanceSheetConfig(accounts, financialYear, type),
    cashFlowStatement: cashFlowStatementConfig(accounts, period, financialYear),
    notes: notesConfig(financialYear),
    signatures: signaturesConfig(),
    annualGeneralMeeting: annualGeneralMeetingConfig(),
  };
};

/**
 * Creates all references that the annual report refers to
 */
export const references = () => {
  const sharedReferences = {};

  const partReferences = [
    sharedReferences,
    managementReportReferences(),
    balanceSheetReferences(),
    incomeStatementReferences(),
    cashFlowStatementReferences(),
  ];

  // Verify that there is no name conflict
  let references = {};
  partReferences.forEach(refs => {
    const keysSoFar = Object.keys(references);
    const conflict = Object.keys(refs).find(ref => keysSoFar.includes(ref));
    if (conflict) {
      throw new Error(`Conflicting keys in references (${conflict})`);
    }
    references = { ...references, ...refs };
  });

  return references;
};
