import { BalanceSheet } from 'types/AnnualReport/parts/balanceSheet';
import { AccountInformation } from 'utils/SieParser';
import { label, table, ref, RowsBuilder, value, refs } from './util';
import { id, account, sum, or, multiply } from 'utils/References/helpers';
import range from 'lodash-es/range';
import { AnnualReportType } from 'types/AnnualReport/types';

const accountRangeRows = (
  accounts: Record<string, AccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder,
  negative: boolean
): RowsBuilder => {
  range(first, last + 1).forEach(n => {
    const accountNumber = n.toString();
    const accountInformation = accounts[accountNumber];
    if (accountInformation) {
      rows
        .addRow(
          accountNumber,
          label(`${accountNumber} ${accountInformation.accountName}`),
          undefined,
          ref(
            negative
              ? multiply(-1, account(accountNumber))
              : account(accountNumber)
          ),
          ref(
            negative
              ? multiply(-1, account(accountNumber, 'ib'))
              : account(accountNumber, 'ib')
          )
        )
        .setSortKey(n);
    }
  });
  return rows;
};

const addPost = (
  type: AnnualReportType,
  accounts: Record<string, AccountInformation>,
  negative: boolean,
  rows: RowsBuilder
) => (
  rowId: string,
  name: string,
  accountNumbers: [number, number][]
): RowsBuilder => {
  return rows
    .addRow(
      rowId,
      type === 'k3' ? value(name) : label(name),
      refs(),
      ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
      ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0))
    )
    .newRowTemplateGenerator((id, baseId, { label: labelValue, ib, ub }) => ({
      id,
      active: true,
      cells: {
        label: label(labelValue),
        year: ub
          ? ref(negative ? multiply(-1, account(ub)) : account(ub))
          : value(0),
        previousYear: ib
          ? ref(negative ? multiply(-1, account(ib, 'ib')) : account(ib, 'ib'))
          : value(0),
      },
      sortKey: ib ? parseInt(ib) : parseInt(ub),
    }))
    .addSubRows(rows => {
      accountNumbers.forEach(([first, last]) =>
        accountRangeRows(accounts, first, last, rows, negative)
      );
      return rows.build();
    });
};

const addGroup = (
  type: AnnualReportType,
  rows: RowsBuilder,
  rowId: string,
  name: string,
  sumName: string,
  subRowBuilder: (rows: RowsBuilder) => void
): void => {
  rows
    .addRow(
      rowId,
      name === undefined
        ? undefined
        : type === 'k3'
        ? value(name)
        : label(name),
      refs(),
      undefined,
      undefined,
      ref(id(`${rows.getBaseId()}.${rowId}Sum.hidden`))
    )
    .addSubRows(rows => {
      subRowBuilder(rows);
      return rows.build();
    });

  rows.newRowTemplateBuilder(template =>
    template
      .addRow(
        '',
        value(''),
        refs(),
        ref(or(sum(id('$id.*.year')), 0)),
        ref(or(sum(id('$id.*.previousYear')), 0))
      )
      .newRowTemplate(label('$label'), label(''), ref('$ub'), ref('$ib'))
  );

  rows.addRow(
    `${rowId}Sum`,
    type === 'k3' ? value(sumName) : label(sumName),
    refs(),
    ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
    ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),
    ref(
      sum(
        or(
          id(`${rows.getBaseId()}.${rowId}.*.notes-0`),
          id(`${rows.getBaseId()}.${rowId}Sum.notes-0`),
          0
        ),
        id(`${rows.getBaseId()}.${rowId}Sum.year`),
        id(`${rows.getBaseId()}.${rowId}Sum.previousYear`)
      )
    )
  );
};

const addIntangibleAssets = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
): void => {
  addGroup(
    type,
    rows,
    'intangibleAssets',
    'Immateriella anläggningstillgångar',
    'Summa immateriella anläggningstillgångar',
    rows => {
      const add = addPost(type, accounts, false, rows);

      if (type === 'k3') {
        add(
          '1',
          'Balanserade utgifter för utvecklingsarbeten och liknande arbeten',
          [[1010, 1019]]
        );
      }

      add(
        '2',
        'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
        type === 'k3' ? [[1020, 1059]] : [[1000, 1059]]
      );
      add('3', 'Hyresrätter och liknande rätter', [[1060, 1069]]);
      add('4', 'Goodwill', [[1070, 1079]]);
      add('5', 'Förskott avseende immateriella anläggningstillgångar', [
        [1080, 1089],
      ]);
      add('6', 'Övriga immateriella anläggningstillgångar', [[1090, 1099]]);
    }
  );
};

const addTangibleFixedAssets = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
): void => {
  addGroup(
    type,
    rows,
    'tangibleFixedAssets',
    'Materiella anläggningstillgångar',
    'Summa materiella anläggningstillgångar',
    rows => {
      const add = addPost(type, accounts, false, rows);
      add('1', 'Byggnader och mark', [
        [1100, 1119],
        [1130, 1199],
      ]);
      add('2', 'Maskiner och andra tekniska anläggningar', [[1200, 1219]]);
      add('3', 'Inventarier, verktyg och installationer', [[1220, 1279]]);
      add('4', 'Förbättringsutgifter på annans fastighet', [[1120, 1129]]);
      add(
        '5',
        'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
        [[1280, 1289]]
      );
      add('6', 'Övriga materiella anläggningstillgångar', [[1290, 1299]]);
    }
  );
};

const addFinancialAssets = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
): void => {
  addGroup(
    type,
    rows,
    'financialAssets',
    'Finansiella anläggningstillgångar',
    'Summa finansiella anläggningstillgångar',
    rows => {
      const add = addPost(type, accounts, false, rows);
      add('1', 'Andelar i koncernföretag', [[1300, 1319]]);
      add('2', 'Fodringar hos koncernföretag', [[1320, 1329]]);
      add('3', 'Andelar i intresseföretag och gemensamt styrda företag', [
        [1330, 1339],
      ]);
      add('4', 'Fordringar hos intresseföretag och gemensamt styrda företag', [
        [1340, 1349],
      ]);
      add('5', 'Ägarintressen i övriga företag', [[1350, 1359]]);
      add(
        '6',
        'Fordringar hos övriga företag som det finns ett ägarintresse i',
        []
      );
      add('7', 'Andra långfristiga värdepappersinnehav', []);
      add('8', 'Lån till delägare eller till delägare närstående', [
        [1360, 1369],
      ]);
      if (type === 'k3') {
        add('9', 'Uppskjuten skattefordran', [[1370, 1379]]);
      }
      add(
        '10',
        'Andra långfristiga fordringar',
        type === 'k3' ? [[1380, 1399]] : [[1370, 1399]]
      );
    }
  );
};

const addInventory = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
): void => {
  addGroup(
    type,
    rows,
    'inventory',
    'Varulager m.m.',
    'Summa varulager m.m.',
    rows => {
      const add = addPost(type, accounts, false, rows);
      add('1', 'Råvaror och förnödenheter', [[1400, 1439]]);
      add('2', 'Varor under tillverkning', [[1440, 1449]]);
      add('3', 'Färdiga varor och handelsvaror', [[1450, 1469]]);
      add('4', 'Pågående arbeten för annans räkning', [[1470, 1479]]);
      add('5', 'Förskott till leverantörer', [[1480, 1489]]);
      add('6', 'Övriga lagertillgångar', [[1490, 1499]]);
    }
  );
};

const addShortTermReceivables = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
) => {
  addGroup(
    type,
    rows,
    'shortTermReceivables',
    'Kortfristiga fordringar',
    'Summa kortfristiga fordringar',

    rows => {
      const add = addPost(type, accounts, false, rows);
      add('1', 'Kundfordringar', [[1500, 1599]]);
      add('2', 'Fordringar hos koncernföretag', [[1660, 1669]]);
      add('3', 'Fordringar hos intresseföretag och gemensamt styrda företag', [
        [1670, 1672],
      ]);
      add(
        '4',
        'Fordringar hos övriga företag som det finns ett ägarintresse i',
        [[1673, 1679]]
      );
      add('5', 'Aktuell skattefordran', [[1630, 1659]]);
      add('6', 'Övriga fordringar', [
        [1600, 1619],
        [1680, 1689],
      ]);
      add('7', 'Upparbetad men ej fakturerad intäkt', [[1620, 1629]]);
      add('8', 'Förutbetalda kostnader och upplupna intäkter', [[1700, 1799]]);
    }
  );
};

const addShortTermInvestments = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
) => {
  addGroup(
    type,
    rows,
    'shortTermInvestments',
    'Kortfristiga placeringar',
    'Summa kortfristiga placeringar',
    rows => {
      const add = addPost(type, accounts, false, rows);
      add('1', 'Andelar i koncernföretag', [[1860, 1869]]);
      add('2', 'Övriga kortfristiga placeringar', [
        [1800, 1859],
        [1870, 1899],
      ]);
    }
  );
};

const addCashAndBankBalances = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
) => {
  addGroup(
    type,
    rows,
    'cashAndBankBalances',
    'Kassa och bank',
    'Summa kassa och bank',
    rows => {
      const add = addPost(type, accounts, false, rows);
      add('1', 'Kassa och bank', [[1900, 1989]]);
      add('2', 'Redovisningsmedel', [[1990, 1999]]);
    }
  );
};

const addRestrictedEquity = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
) => {
  addGroup(
    type,
    rows,
    'restrictedEquity',
    'Bundet eget kapital',
    'Summa bundet eget kapital',
    rows => {
      const add = addPost(type, accounts, true, rows);
      add('1', 'Eget kapital', [
        [2000, 2080],
        [2083, 2084],
        [2086, 2088],
      ]);
      add('2', 'Aktiekapital', [[2081, 2081]]);
      add('3', 'Ej registrerat aktiekaptial', [[2082, 2082]]);
      add('4', 'Uppskrivningsfond', [[2085, 2085]]);
      if (type === 'k3') {
        add('5', 'Fond för utvecklingsavgifter', [[2089, 2089]]);
      }
      // TODO Where to put 2089 in K2
    }
  );
};

const addUnrestrictedEquity = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
) => {
  addGroup(
    type,
    rows,
    'unrestrictedEquity',
    'Fritt eget kapital',
    'Summa fritt eget kapital',
    rows => {
      const add = addPost(type, accounts, true, rows);
      add('1', 'Överkursfond', [[2097, 2097]]);
      add('2', 'Balanserat resultat', [
        [2091, 2092],
        [2094, 2096],
        [2098, 2098],
      ]);
      add('3', 'Erhållet aktieägartillskott', [[2093, 2093]]);
      add('4', 'Årets resultat', [[2099, 2099]]);
    }
  );
};

const addUntaxedReserves = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
) => {
  addGroup(
    type,
    rows,
    'untaxedReserves',
    'Obeskattade reserver',
    'Summa obeskattade reserver',
    rows => {
      const add = addPost(type, accounts, true, rows);
      add('1', 'Periodiseringsfond', [[2100, 2149]]);
      add('2', 'Ackumulerade avskrivningar utöver plan', [[2150, 2159]]);
      add('3', 'Ersättningsfond', [[2160, 2169]]);
      add('4', 'Obeskattade reserver', [[2170, 2199]]);
    }
  );
};

const addAllocations = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
) => {
  addGroup(
    type,
    rows,
    'allocations',
    'Avsättningar',
    'Summa avsättningar',
    rows => {
      const add = addPost(type, accounts, true, rows);
      add('1', 'Pensioner och andra liknande förpliktelser', [[2200, 2239]]);
      if (type === 'k3') {
        add('2', 'Uppskjuten skatteskuld', [[2240, 2259]]);
      }
      add(
        '3',
        'Övriga avsättningar',
        type === 'k3' ? [[2260, 2299]] : [[2240, 2299]]
      );
    }
  );
};

const addLongTermLiabilities = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
) => {
  addGroup(
    type,
    rows,
    '1',
    'Långfristiga skulder',
    'Summa långfristiga skulder',
    rows => {
      const add = addPost(type, accounts, true, rows);
      add('1', 'Obligationslån', [[2300, 2319]]);
      add('2', 'Konvertibla lån', [[2320, 2329]]);
      add('3', 'Checkräkningskredit', [[2330, 2339]]);
      add('4', 'Byggnadskreditiv', [[2340, 2349]]);
      add('5', 'Skulder till kreditinstitut', [[2350, 2359]]);
      add('6', 'Skulder till koncernföretag', [[2360, 2369]]);
      add('7', 'Skulder till intresseföretag och gemensamt styrda företag', [
        [2370, 2372],
      ]);
      add('8', 'Skulder till övriga företag som det finns ett ägarintresse i', [
        [2373, 2379],
      ]);
      add('9', 'Övriga långfristiga skulder', [[2380, 2399]]);
    }
  );
};

const addShortTermLiabilities = (
  accounts: Record<string, AccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType
) => {
  addGroup(
    type,
    rows,
    'shortTermLiabilities',
    'Kortfristiga skulder',
    'Summa kortfristiga skulder',
    rows => {
      const add = addPost(type, accounts, true, rows);
      add('1', 'Checkräkningskredit', [[2480, 2489]]);
      add('2', 'Skulder till kreditinstitut', [[2400, 2419]]);
      add('3', 'Förskott från kunder', [[2420, 2429]]);
      add('4', 'Pågående arbeten', [[2430, 2439]]);
      add('5', 'Leverantörsskulder', [[2440, 2449]]);
      add('6', 'Växelskulder', [[2490, 2499]]);
      add('7', 'Skulder till koncernföretag', [
        [2860, 2869],
        [2460, 2469],
      ]);
      add('8', 'Skulder till intresseföretag och gemensamt styrda företag', [
        [2870, 2872],
        [2470, 2472],
      ]);
      add('9', 'Skulder till övriga företag som det finns ett ägarintresse i', [
        [2873, 2879],
        [2473, 2479],
      ]);
      add('10', 'Aktuell skatteskuld', [[2500, 2599]]);
      add('11', 'Övriga skulder', [
        [2600, 2799],
        [2800, 2859],
        [2880, 2899],
      ]);
      add('12', 'Fakturerad men ej upparbetad intäkt', [[2450, 2459]]);
      add('13', 'Upplupna kostnader och förutbetalda intäkter', [[2900, 2999]]);
    }
  );
};

const year = (financialYear): string => financialYear.substring(7, 11);

export const balanceSheetConfig = (
  accounts: Record<string, AccountInformation>,
  financialYear: string,
  type: AnnualReportType
): BalanceSheet => ({
  type: 'part',
  section: {
    assets: table(
      'balanceSheet.section.assets',
      'label',
      'notes',
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` },
      'hidden'
    )
      .addRows(rows =>
        rows
          .addRow(
            'assets',
            type === 'k3' ? value('Tillgångar') : label('Tillgångar')
          )
          .addSubRows(rows => {
            rows
              .addRow(
                'fixedAssets',
                type === 'k3'
                  ? value('Anläggningstillgångar')
                  : label('Anläggningstillgångar')
              )
              .addSubRows(rows => {
                addIntangibleAssets(accounts, rows, type);
                addTangibleFixedAssets(accounts, rows, type);
                addFinancialAssets(accounts, rows, type);
                rows.addRow(
                  'sumFixedAssets',
                  type === 'k3'
                    ? value('Summa anläggningstillgångar')
                    : label('Summa anläggningstillgångar'),
                  refs(),
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.intangibleAssetsSum.year`),
                      id(`${rows.getBaseId()}.tangibleFixedAssetsSum.year`),
                      id(`${rows.getBaseId()}.financialAssetsSum.year`)
                    )
                  ),
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.intangibleAssetsSum.previousYear`
                      ),
                      id(
                        `${rows.getBaseId()}.tangibleFixedAssetsSum.previousYear`
                      ),
                      id(`${rows.getBaseId()}.financialAssetsSum.previousYear`)
                    )
                  )
                );
                return rows.build();
              });

            rows
              .addRow(
                'currentAssets',
                type === 'k3'
                  ? value('Omsättningstillgångar')
                  : label('Omsättningstillgångar')
              )
              .addSubRows(rows => {
                addInventory(accounts, rows, type);
                addShortTermReceivables(accounts, rows, type);
                addShortTermInvestments(accounts, rows, type);
                addCashAndBankBalances(accounts, rows, type);

                rows.addRow(
                  'sumCurrentAssets',
                  type === 'k3'
                    ? value('Summa omsättningstillgångar')
                    : label('Summa omsättningstillgångar'),
                  refs(),
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.inventorySum.year`),
                      id(`${rows.getBaseId()}.shortTermReceivablesSum.year`),
                      id(`${rows.getBaseId()}.shortTermInvestmentsSum.year`),
                      id(`${rows.getBaseId()}.cashAndBankBalancesSum.year`)
                    )
                  ),
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.inventorySum.previousYear`),
                      id(
                        `${rows.getBaseId()}.shortTermReceivablesSum.previousYear`
                      ),
                      id(
                        `${rows.getBaseId()}.shortTermInvestmentsSum.previousYear`
                      ),
                      id(
                        `${rows.getBaseId()}.cashAndBankBalancesSum.previousYear`
                      )
                    )
                  )
                );
                return rows.build();
              });

            return rows.build();
          })
          .addRow(
            'sumAssets',
            type === 'k3'
              ? value('Summa tillgångar')
              : label('Summa tillgångar'),
            refs(),
            ref(
              or(
                sum(
                  id(`${rows.getBaseId()}.assets.fixedAssets.*Sum.year`),
                  id(`${rows.getBaseId()}.assets.currentAssets.*Sum.year`)
                ),
                0
              )
            ),
            ref(
              or(
                sum(
                  id(
                    `${rows.getBaseId()}.assets.fixedAssets.*Sum.previousYear`
                  ),
                  id(
                    `${rows.getBaseId()}.assets.currentAssets.*Sum.previousYear`
                  )
                ),
                0
              )
            )
          )
          .build()
      )
      .build(),
    equityAndLiabilities: table(
      'balanceSheet.section.equityAndLiabilities',
      'label',
      'notes',
      { id: 'year', label: year(financialYear) },
      { id: 'previousYear', label: `${parseInt(year(financialYear)) - 1}` },
      'hidden'
    )
      .addRows(rows =>
        rows
          .addRow(
            'equityAndLiabilities',
            type === 'k3'
              ? value('Eget kaptial och skulder')
              : label('Eget kapital och skulder')
          )
          .addSubRows(rows => {
            rows
              .addRow(
                'equity',
                type === 'k3' ? value('Eget kapital') : label('Eget kapital')
              )
              .addSubRows(rows => {
                addRestrictedEquity(accounts, rows, type);
                addUnrestrictedEquity(accounts, rows, type);

                rows.addRow(
                  'sumEquity',
                  type === 'k3'
                    ? value('Summa eget kapital')
                    : label('Summa eget kapital'),
                  refs(),
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.restrictedEquitySum.year`),
                      id(`${rows.getBaseId()}.unrestrictedEquitySum.year`)
                    )
                  ),
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.restrictedEquitySum.previousYear`
                      ),
                      id(
                        `${rows.getBaseId()}.unrestrictedEquitySum.previousYear`
                      )
                    )
                  )
                );

                addUntaxedReserves(accounts, rows, type);
                addAllocations(accounts, rows, type);

                return rows.build();
              });

            rows
              .addRow(
                'longTermLiabilities',
                type === 'k3'
                  ? value('Långfristiga skulder')
                  : label('Långfristiga skulder')
              )
              .addSubRows(rows => {
                addLongTermLiabilities(accounts, rows, type);
                return rows.build();
              });
            rows
              .addRow(
                'shortTermLiabilities',
                type === 'k3'
                  ? value('Kortfristiga skulder')
                  : label('Kortfristiga skulder')
              )
              .addSubRows(rows => {
                addShortTermLiabilities(accounts, rows, type);
                return rows.build();
              });

            return rows.build();
          })
          .addRow(
            'sumEquityAndLiabilities',
            type === 'k3'
              ? value('Summa eget kapital och skulder')
              : label('Summa eget kapital och skulder'),
            refs(),
            ref(
              sum(
                id(`${rows.getBaseId()}.equityAndLiabilities.equity.*Sum.year`),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.longTermLiabilities.*Sum.year`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.shortTermLiabilities.*Sum.year`
                )
              )
            ),
            ref(
              sum(
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.equity.*Sum.previousYear`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.longTermLiabilities.*Sum.previousYear`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.shortTermLiabilities.*Sum.previousYear`
                )
              )
            )
          )
          .build()
      )
      .build(),
  },
});

export const balanceSheetReferences = (): Record<string, string> => ({
  'balanceSheet.sumEquity.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.sumEquity.year'
  ),
  'balanceSheet.sumEquity.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.sumEquity.previousYear'
  ),
  'balanceSheet.sumUntaxedReserves.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.untaxedReservesSum.year'
  ),
  'balanceSheet.sumUntaxedReserves.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.untaxedReservesSum.previousYear'
  ),
  'balanceSheet.sumEquityAndLiabilities.year': id(
    'balanceSheet.section.equityAndLiabilities.sumEquityAndLiabilities.year'
  ),
  'balanceSheet.sumEquityAndLiabilities.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.sumEquityAndLiabilities.previousYear'
  ),
});
