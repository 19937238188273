import {
  AnnualReportPartKey,
  AnnualReportTable,
  AnnualReportTableRow,
  isUpdateRowChange,
  TableChange,
  UpdateRowChange,
} from 'types/AnnualReport/types';
import AnnualReportState from '../../types';
import updateTable from './updateTable';
import { addItem, replaceById, replaceByIdAndType } from './util';

const updateRow = (
  state: AnnualReportState,
  partKey: AnnualReportPartKey,
  sectionKey: string,
  tableKey: string,
  rowIds: string[],
  updater: (
    row: AnnualReportTableRow,
    change: UpdateRowChange
  ) => [AnnualReportTableRow, UpdateRowChange]
): AnnualReportState => {
  const subRowUpdater = <T extends AnnualReportTableRow | AnnualReportTable>(
    row: T,
    change: T extends AnnualReportTableRow ? UpdateRowChange : TableChange,
    rowIds: string[]
  ): [T, T extends AnnualReportTableRow ? UpdateRowChange : TableChange] => {
    const rowId = rowIds[0];
    const subRow = row.rows?.find(row => row.id === rowId);
    const existingRowChange: UpdateRowChange | undefined = change.rows
      ?.filter(isUpdateRowChange)
      .find(c => c.id === rowId);

    if (subRow) {
      let newRow: AnnualReportTableRow, newRowChange: UpdateRowChange;
      if (rowIds.length === 1) {
        [newRow, newRowChange] = updater(
          subRow,
          existingRowChange || { type: 'update', id: subRow.id }
        );
      } else {
        if (
          existingRowChange !== undefined &&
          existingRowChange.type !== 'update' &&
          existingRowChange.type !== 'add'
        ) {
          throw new Error("Only updating changes of type 'update' supported");
        }
        [newRow, newRowChange] = subRowUpdater(
          subRow,
          existingRowChange || { type: 'update', id: subRow.id },
          rowIds.slice(1)
        );
      }
      if (newRow !== subRow) {
        return [
          {
            ...row,
            rows: replaceById(row.rows, newRow),
          },
          {
            ...change,
            rows: existingRowChange
              ? replaceByIdAndType(change.rows, newRowChange)
              : addItem(change.rows, newRowChange),
          },
        ];
      }
    }

    return [row, change];
  };

  return updateTable(state, partKey, sectionKey, tableKey, (table, change) => {
    if (change.type !== 'update') {
      throw new Error("Only updating changes of type 'update' supported");
    }
    return subRowUpdater(table, change, rowIds);
  });
};

export default updateRow;
