import { ManagementReport } from 'types/AnnualReport/parts/managementReport';
import { active, field, ref, table, value, refs } from './util';
import { or, id, sum, account, multiply, div } from 'utils/References/helpers';
import parseFinancialYear from 'utils/parseFinancialYear';
import { AnnualReportType } from 'types/AnnualReport/types';
import { companyTaxPerYear } from 'utils/TaxCalculation/config';

const toYearColumn = (year: string) => ({ id: year, label: year });

export const managementReportConfig = (
  customer: Customer.CustomerType,
  selectedFinancialYear: string,
  reportType: AnnualReportType,
  period: string | null
): ManagementReport => {
  const finYears = customer.financialYears
    .filter(interval => interval <= selectedFinancialYear) // filter out the years in the future of the selected financialYear
    .map(interval =>
      toYearColumn(parseFinancialYear(interval).end.format('YYYY'))
    )
    .sort((a, b) => parseInt(b.id) - parseInt(a.id));

  const years =
    finYears.length === 1
      ? [
          ...finYears,
          {
            id: `${parseInt(finYears[0].id) - 1}`,
            label: `${parseInt(finYears[0].id) - 1}`,
          },
        ]
      : finYears;

  return {
    type: 'part',
    activities: {
      active: true,
      generally: active(field('')),
      accountant: active(
        field(
          '[namn, byrå], som är auktoriserad redovisnings-/lönekonsult (FAR / SRF), har anlitats för biträde med följande tjänster: bokföring, lön, bokslut och årsredovisning.'
        )
      ),
      events: active(field('')),
      shares: active(field('')),

      // K3 fields
    },
    viability: { active: reportType === 'k3', text: field('') },
    ownershipChanges: { active: reportType === 'k3', text: field('') },
    controlBalanceSheet: { active: reportType === 'k3', text: field('') },
    futureDevelopment: { active: reportType === 'k3', text: field('') },
    rAndD: { active: reportType === 'k3', text: field('') },
    subsidiariesAbroad: { active: reportType === 'k3', text: field('') },
    sustainabilityInfo: { active: reportType === 'k3', text: field('') },
    sustainabilityReport: { active: reportType === 'k3', text: field('') },
    permitActivities: { active: reportType === 'k3', text: field('') },
    multiYearOverview: {
      active: true,
      multiYearText: field(''),
      table: table(
        'managementReport.multiYearOverview.table',
        { id: 'label', label: '' },
        ...years
      )
        .addRows(rows =>
          rows
            .addRow(
              'netto',
              value('Nettoomsättning'),
              ref(id('incomeStatement.netSales.year')),
              ref(id('incomeStatement.netSales.previousYear')),
              ...years.slice(2).map(year => value(undefined))
            )
            .addRow(
              'result',
              value('Resultat efter finansiella poster'),
              ref(id('incomeStatement.resultAfterFinancial.year')),
              ref(id('incomeStatement.resultAfterFinancial.previousYear')),
              ...years.slice(2).map(year => value(undefined))
            )
            .addRow(
              'resultPercentage',
              value('Resultat i % av nettoomsättningen'),
              ...years.map(year =>
                ref(
                  div(
                    id(`${rows.getBaseId()}.result.${year.id}`),
                    id(`${rows.getBaseId()}.netto.${year.id}`)
                  )
                )
              )
            )
            .addRow(
              'balanceSheetTotal',
              value('Balansomslutning'),
              ref(id('balanceSheet.section.assets.sumAssets.year')),
              ref(id('balanceSheet.section.assets.sumAssets.previousYear')),
              ...years.slice(2).map(year => value(undefined))
            )
            .addRow(
              'solidity',
              value('Soliditet (%)'),
              // ("Summa eget kapital" + (1 - (aktuell bolagsskatt)) * "summa obeskattade reserver")  / "Summa eget kapital och skulder"
              ref(
                div(
                  sum(
                    or(id('balanceSheet.sumEquity.year'), 0),
                    multiply(
                      sum(1, multiply(-1, companyTaxPerYear[years[0].id])),
                      or(id('balanceSheet.sumUntaxedReserves.year'), 0)
                    )
                  ),
                  id('balanceSheet.sumEquityAndLiabilities.year')
                )
              ),
              ref(
                div(
                  sum(
                    or(id('balanceSheet.sumEquity.previousYear'), 0),
                    multiply(
                      sum(1, multiply(-1, companyTaxPerYear[years[1].id])),
                      or(id('balanceSheet.sumUntaxedReserves.previousYear'), 0)
                    )
                  ),
                  id('balanceSheet.sumEquityAndLiabilities.previousYear')
                )
              ),
              ...years.slice(2).map(year => value(undefined))
            )
            .build()
        )
        .newRowTemplate(value(''), ...years.map(year => value(0)))
        .build(),
    },
    changesInEquity: {
      active: true,
      transition: field(''),
      table: table(
        'managementReport.changesInEquity.table',
        { id: 'label', label: '' },
        'note',
        'shareCapital',
        'otherRestrictedCapital',
        'otherUnrestrictedCapital',
        'yearResult',
        'totalUnrestrictedEquity'
      )
        .addRows(rows =>
          rows
            // Start with k3 transition rows. These are shown conditionally in the
            //table component
            .addRow('transitionToK3')
            .addSubRows(rows =>
              rows
                .addRowActive(false)
                .addRow(
                  'amountPrevYear',
                  value(
                    'Belopp vid jämförelseårets ingång enligt fastställd balansräkning'
                  ),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'adjustmentsBFNAR',
                  value('Justeringar vid övergång till BFNAR 2012:1 (K3)'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'adjustedAmountPrevYear',
                  value('Justerat belopp vid jämförelseårets ingång'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'fundEmission',
                  value('Fondemission'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'newSplit',
                  value('Nyemission'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'shareholderContributions',
                  value('Erhållna akteägartillskott'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'contributions',
                  value('Erhållna/Lämnade koncernbidrag'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'taxContributions',
                  value('Skatt hänförlig till koncernbidrag'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'dispositionResults',
                  value('Resultatsdesposition enl. beslut av årsstämma'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'provision',
                  value('Avsättning till reservfond'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'dividendShareholders',
                  value('Utdelning till aktieägare'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'refundShareholders',
                  value('Återbetalning akteägartillskott'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'yearLoss',
                  value('Årets förlust'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'adjustmentDuringTransition',
                  value('Justering av jämförelsebelopp vid övergång till K3'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'adjustedAmountYearStart',
                  value('Justerade Belopp vid årets ingång'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )

                .build()
            )
            .newRowTemplate(
              value(''),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0)
            )
            // Rows for transition to K2
            .addRow('transitionToK2')
            .addSubRows(rows =>
              rows
                .addRowActive(false)
                .addRow(
                  'amountPrevYear',
                  value(
                    'Belopp vid jämförelseårets ingång enligt fastställd balansräkning'
                  ),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'adjustmentsBFNAR',
                  value('Justeringar vid övergång till BFNAR 2012:1 (K2)'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  '3',
                  value('Egen upparbetad immateriell anläggningstillgång'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  '4',
                  value('Eget kaptial föregående år'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  '5',
                  value(
                    'Finansiell anläggningstillgång värderad till verkligt värde'
                  ),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  '6',
                  value('Justeringar vid övergång till BFNAR 2016:10 (K2)'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  '7',
                  value('Leasingavtal'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  '8',
                  value('Pensionsavsättningar'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  '9',
                  value('Uppskjuten skattefordran'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  '10',
                  value('Uppskjuten skatteskuld'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  '11',
                  value('Uppskriven anläggningstillgång'),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .build()
            )
            .newRowTemplate(
              value(''),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0)
            )

            // This row is only shown if there is no transition to k3
            .addRow('main')
            .addSubRows(rows =>
              rows
                .addRow('content')
                .addSubRows(rows =>
                  rows
                    .addRow(
                      'incomingAmount',
                      value('Belopp vid årets ingång'),
                      refs(),
                      ref(multiply(-1, account('2081', 'ib'))),
                      ref(or(multiply(-1, account('2082:2089', 'ib')), 0)),
                      ref(or(multiply(-1, account('2090:2098', 'ib')), 0)),
                      ref(or(multiply(-1, account('2099', 'ib')), 0)),
                      ref(
                        or(
                          sum(
                            id(
                              'managementReport.changesInEquity.table.main.content.incomingAmount.yearResult'
                            ),
                            id(
                              'managementReport.changesInEquity.table.main.content.incomingAmount.otherUnrestrictedCapital'
                            )
                          ),
                          0
                        )
                      )
                    )
                    .addRow(
                      'fundEmission',
                      value('Fondemission'),
                      refs(),
                      value(0),
                      value(0),
                      value(0),
                      value(0),
                      value(0)
                    )
                    .addRow(
                      'newSplit',
                      value('Nyemission'),
                      refs(),
                      ref(or(multiply(-1, account('2087', 'change')), 0)),
                      value(0),
                      value(0),
                      value(0),
                      value(0)
                    )
                    .addRow(
                      'shareholderContributions',
                      value('Erhållna akteägartillskott'),
                      refs(),
                      value(0),
                      value(0),
                      value(0),
                      value(0),
                      value(0)
                    )
                    .addRow(
                      'transferAdjusterResult',
                      value('Omföring av ovan justerat resultat'),
                      refs(),
                      value(0),
                      value(0),
                      value(0),
                      value(0),
                      value(0)
                    )
                    .addRow(
                      'despositionOfResults',
                      value('Resultatdesposition enl. beslut av årsstämma'),
                      refs(),
                      value(0),
                      value(0),
                      ref(
                        id(
                          'managementReport.changesInEquity.table.main.content.incomingAmount.yearResult'
                        )
                      ),
                      ref(
                        multiply(
                          -1,
                          id(
                            'managementReport.changesInEquity.table.main.content.incomingAmount.yearResult'
                          )
                        )
                      ),
                      value(0)
                    )
                    .addRow(
                      'depositionReservFund',
                      value('Avsättning till reservfond'),
                      refs(),
                      value(0),
                      value(0),
                      value(0),
                      value(0),
                      value(0)
                    )
                    .addRow(
                      'dividendToShareHolders',
                      value('Utdelning till aktieägare'),
                      refs(),
                      value(0),
                      value(0),
                      ref(
                        or(
                          // id(
                          //   'managementReport.resultsDisposition.selectedDividend' // Should get this from last year somehow
                          // ),
                          id('payedDividend')
                        )
                      ),
                      value(0),
                      value(0)
                    )
                    .addRow(
                      'refundShareholders',
                      value('Återbetalning akteägartillskott'),
                      refs(),
                      value(0),
                      value(0),
                      value(0),
                      value(0),
                      value(0)
                    )

                    .addRow(
                      'yearResult',
                      value('Årets resultat'),
                      refs(),
                      value(0),
                      value(0),
                      value(0),
                      ref(multiply(-1, account('2099'))),
                      value(0)
                    )
                    .build()
                )
                .newRowTemplate(
                  value(''),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .addRow(
                  'sumByYearEnd',
                  value('Belopp vid årets utgång'),
                  refs(),
                  ref(sum(id(`${rows.getBaseId()}.content.*.shareCapital`))),
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.content.*.otherRestrictedCapital`)
                    )
                  ),
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.content.*.otherUnrestrictedCapital`
                      )
                    )
                  ),
                  ref(sum(id(`${rows.getBaseId()}.content.*.yearResult`))),
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.sumByYearEnd.otherUnrestrictedCapital`
                      ),
                      id(`${rows.getBaseId()}.sumByYearEnd.yearResult`)
                    )
                  )
                )
                .newRowTemplate(
                  value(''),
                  refs(),
                  value(0),
                  value(0),
                  value(0),
                  value(0),
                  value(0)
                )
                .build()
            )
            .newRowTemplate(
              value(''),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0)
            )
            .build()
        )
        .newRowTemplate(
          value(''),
          refs(),
          value(0),
          value(0),
          value(0),
          value(0),
          value(0)
        )

        .build(),
      conditionalShareholderContributions: table(
        'managementReport.changesInEquity.conditionalShareholderContributions',
        { id: 'label', label: '' },
        ...years
      )
        .addRows(rows =>
          rows
            .addRow(
              '1',
              value('Villkorade akteägartillskott uppgår till'),
              ref(or(multiply(-1, account('2093')), 0)),
              ref(or(multiply(-1, account('2093', 'ib')), 0)),
              ...years.slice(2).map(year => value(0))
            )
            .build()
        )
        .newRowTemplate(value(0), ...years.map(year => value(0)))
        .build(),
    },

    resultsDisposition: {
      active: true,
      selectedDividend: field(0),
      propose: field(''),
      toDispose: table(
        'managementReport.resultsDisposition.toDispose',
        'label',
        'value'
      )
        .addRows(rows =>
          rows
            .addRow(
              '1',
              value('Balanserat resultat'),
              ref(or(multiply(-1, account('2090:2098')), 0))
            )
            .addRow(
              '2',
              value('Årets resultat'),
              ref(or(multiply(-1, account('2099')), 0))
            )
            .build()
        )
        .newRowTemplate(value(''), value(0))
        .build(),
      // balancedResult: ref(or(account('2090:2098'), 0)),
      // yearResult: ref(or(account('2099'), 0)),
      total: ref(
        or(sum(id('managementReport.resultsDisposition.toDispose.*.value')), 0)
      ),
      proposal: table(
        'managementReport.resultsDisposition.proposal',
        'label',
        'value'
      )
        .addRows(rows =>
          rows
            .addRow(
              '1',
              value('Vald utdelning'),
              ref(id('managementReport.resultsDisposition.selectedDividend'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(0))
        .build(),
      toTransfer: ref(
        sum(
          multiply(
            -1,
            or(
              sum(id('managementReport.resultsDisposition.proposal.*.value')),
              0
            )
          ),
          id('managementReport.resultsDisposition.total')
        )
      ),
    },
    profitProposal: {
      active: true,
      dividendPerShare: ref(
        div(
          id('managementReport.profitProposal.dividend'),
          id('managementReport.profitProposal.shares')
        )
      ),
      dividend: ref(
        or(id('managementReport.resultsDisposition.selectedDividend'), 0)
      ),
      shares: field(1),
      toTransfer: ref(id('managementReport.resultsDisposition.toTransfer')),
      paymentDateType: field('generalMeeting'),
      paymentDate: field(''),
      statement: field(
        'Styrelsen har gjort bedömningen att vinstutdelningen är försvarlig ' +
          'med hänsyn till dom krav som verksamhetens art, omfattning och ' +
          'risker ställer på storleken av det egna kapitalet, och bolagets ' +
          'konsolideringsbehov, likviditet och ställning i övrigt. Den ' +
          'förslagna vinstutdelningen är därför förenlig med vad som anges i ' +
          'aktiebolagslagen 17 kap. 3§ andra och tredje styckena.'
      ),
    },
  };
};

export const managementReportReferences = (): Record<string, string> => ({
  solidity: sum(
    id('managementReport.multiYearOverview.table.nettoAndResult.*.value')
  ),
});
