import React, { memo, useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppBar, Toolbar, Divider, WithTheme } from '@material-ui/core';
import { useSelector } from 'redux/reducers';
import { setIntegrationStatus } from 'redux/actions';
import { CustomerInformation } from 'components/Modals/Customer';
import { useGetFortnoxIntegrationStatus } from 'components/Api/Client/Integration/Fortnox';
import DocumentPage from 'components/Modals/Customer/DocumentPage';
import CustomerButton from './CustomerButton';
import ModulHeader from 'components/ModalHeader';
import YearSwitcher from '../YearSwitcher';
import styled from '@emotion/styled';
import UploadSIEButton from '../UploadSIEButton';
import UpdateSIEButton from '../UpdateSIEButton';
import SavingStatusButton from '../SavingStatusButton';
import PeriodSelector from '../PeriodSelector';
import DownloadCsvButton from './DownloadCsvButton';

const classes = {
  bottomAppBar: theme => ({
    background: theme.palette.grey[200],
  }),
  bottomToolbar: theme => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&& > *': {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  }),
};

const SaveWrapper = styled.div<WithTheme>`
  margin-left: auto !important;
`;

const CustomerSubMenu = () => {
  const dispatch = useDispatch();

  const customerId = useSelector(state => state.customerView.currentCustomer);
  const customer = useSelector(state =>
    customerId ? state.customers[customerId] : undefined
  );

  const [showCustomerInfo, setShowCustomerInfo] = useState(false);
  const [documentPageOpen, setDocumentPageOpen] = useState(false);
  const [getFortnoxIntegrationStatus] = useGetFortnoxIntegrationStatus();
  const sieData = useSelector(state => state.accountingView.sieData);

  useEffect(() => {
    if (customer?.integrations?.['fortnox'] === undefined && customerId) {
      const get = async () => {
        try {
          const status = await getFortnoxIntegrationStatus(customerId);
          dispatch(setIntegrationStatus(customerId, 'fortnox', status));
        } catch (error) {
          if (error === 'Not Found') {
            dispatch(setIntegrationStatus(customerId, 'fortnox', null));
          }
        }
      };
      get();
    }
  }, [customer, customerId, dispatch, getFortnoxIntegrationStatus]);

  const handleClick = () => {
    setShowCustomerInfo(true);
  };

  const handleCloseCustomerInfo = () => {
    setShowCustomerInfo(false);
  };

  const fortnoxStatus = customer?.integrations?.['fortnox'];

  return (
    <AppBar position="static" css={classes.bottomAppBar}>
      <Toolbar css={classes.bottomToolbar}>
        {customer && (
          <>
            <CustomerButton
              logoUrl={customer.logo}
              name={customer.name}
              handleClick={handleClick}
            />
            {showCustomerInfo && (
              <CustomerInformation
                open={showCustomerInfo}
                handleClose={handleCloseCustomerInfo}
                handleDocumentsOpen={() => {
                  setShowCustomerInfo(false);
                  setDocumentPageOpen(true);
                }}
                customer={customer}
              />
            )}
            {documentPageOpen && (
              <DocumentPage
                customerId={customerId}
                open={documentPageOpen}
                handleClose={() => setDocumentPageOpen(false)}
              />
            )}
            <Divider orientation="vertical" flexItem />

            {sieData && customerId && (
              <>
                <ModulHeader />
                <Divider orientation="vertical" flexItem />
                <YearSwitcher customerId={customerId} />
                <Divider orientation="vertical" flexItem />
                <Switch>
                  <Route path="/customer/:id/tax">
                    <PeriodSelector customerId={customerId} />
                    <Divider orientation="vertical" flexItem />
                  </Route>
                  <Route path="/customer/:id/year">
                    <PeriodSelector customerId={customerId} />
                    <Divider orientation="vertical" flexItem />
                  </Route>
                </Switch>
              </>
            )}

            <UploadSIEButton />

            {fortnoxStatus && !fortnoxStatus.deleted && <UpdateSIEButton />}

            <DownloadCsvButton customerName={customer.name} />

            <SaveWrapper>
              <SavingStatusButton />
            </SaveWrapper>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default memo(CustomerSubMenu);
