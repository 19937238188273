import React, { useRef, useEffect } from 'react';
import aLogo from 'assets/Aloader.json';
import lottie from 'lottie-web';
import styled from '@emotion/styled';

type Size = 'small' | 'medium' | 'large';

export interface LoadingLogoProps {
  size?: Size;
}

type sizes = Record<Size, number>;

const sizes: sizes = {
  small: 30,
  medium: 60,
  large: 100,
};

interface LogoWrapperProps {
  size: Size;
}

const LogoWrapper = styled.div<LogoWrapperProps>`
  height: ${props => sizes[props.size]}px;
  width: ${props => sizes[props.size]}px;
  overflow: hidden;
  margin: 0px auto;
  outline: none;

  svg {
    display: block;
    margin: auto;
  }
`;

const LoadingLogo = ({ size = 'small' }: LoadingLogoProps) => {
  const refToDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: refToDiv.current!,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: aLogo,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMin slice',
      },
    });
    return () => {
      animation.destroy();
    };
  }, []);

  return <LogoWrapper size={size} ref={refToDiv} />;
};

export default LoadingLogo;
