import { MenuItem, Select, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { updateAnnualReportField } from 'redux/actions/AnnualReportView/actions';

const Fee = ({ no, fee, print }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleChange = event => {
    dispatch(
      updateAnnualReportField(
        'annualGeneralMeeting.main.fee',
        event.target.value
      )
    );
  };

  return (
    <>
      <Typography variant="h2" color="textSecondary">
        {`§${no} ${formatMessage({
          id: 'annualReport.annualGeneralMeeting.main.10',
        })}`}
      </Typography>
      <Typography component="span">
        {formatMessage({
          id: 'annualReport.annualGeneralMeeting.main.10.text',
        })}
        {print &&
          formatMessage({
            id: `annualReport.annualGeneralMeeting.fee.option.${fee}`,
            defaultMessage: fee,
          })}
      </Typography>
      {!print && (
        <Select value={fee} onChange={handleChange} variant="outlined">
          {['no', 'accountant'].map(value => (
            <MenuItem value={value} key={value}>
              {formatMessage({
                id: `annualReport.annualGeneralMeeting.fee.option.${value}`,
              })}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

export default Fee;
