import { AddAnnualReportRow } from 'redux/actions/AnnualReportView/types';
import {
  AddRowChange,
  AnnualReportTable,
  AnnualReportTableRow,
  isAnnualReportPartKey,
  TableChange,
  UpdateRowChange,
} from 'types/AnnualReport/types';
import { createNewRow } from '../createNewRow';
import { findRow } from '../reducer';
import AnnualReportState from '../types';
import updateRow from './helpers/updateRow';
import updateTable from './helpers/updateTable';
import { insertAt } from './helpers/util';

export const insertBySortKey = (
  rows: AnnualReportTableRow[],
  newRow: AnnualReportTableRow
): AnnualReportTableRow[] => {
  const newRowSortKey = newRow.sortKey;
  if (newRowSortKey !== undefined) {
    const index = rows.findIndex(
      row => row.sortKey === undefined || row.sortKey > newRowSortKey
    );
    if (index !== -1) {
      return insertAt(rows, newRow, index);
    }
  }
  return [...rows, newRow];
};

const addAnnualReportRow = (
  state: AnnualReportState,
  action: AddAnnualReportRow
) => {
  const { id, rowId, cellParameters, copyId } = action;
  if (!state.report) {
    return state;
  }

  const [part, section, table, ...subId] = id.split('.');
  const copyRow = copyId ? findRow(copyId, state) : undefined;

  if (!isAnnualReportPartKey(part)) {
    return state;
  }

  if (subId.length > 0) {
    const addSubRow = (
      row: AnnualReportTableRow,
      change: UpdateRowChange
    ): [AnnualReportTableRow, UpdateRowChange] => {
      if (row.newRowTemplate && row.rows) {
        const newRow =
          copyRow ||
          createNewRow(row.newRowTemplate, id, rowId, cellParameters);

        const addChange: AddRowChange = cellParameters
          ? { type: 'add', id: newRow.id, params: cellParameters }
          : { type: 'add', id: newRow.id };

        return [
          { ...row, rows: insertBySortKey(row.rows, newRow) },
          {
            ...change,
            id: row.id,
            rows: [...(change.rows || []), addChange],
          },
        ];
      }
      return [row, change];
    };
    return updateRow(state, part, section, table, subId, addSubRow);
  }
  const addRow = (
    table: AnnualReportTable,
    change: TableChange
  ): [AnnualReportTable, TableChange] => {
    if (table.newRowTemplate && table.rows) {
      const newRow = createNewRow(
        table.newRowTemplate,
        id,
        rowId,
        cellParameters
      );

      const addChange: AddRowChange = cellParameters
        ? { type: 'add', id: newRow.id, params: cellParameters }
        : { type: 'add', id: newRow.id };

      return [
        { ...table, rows: insertBySortKey(table.rows, newRow) },
        {
          ...change,
          rows: [
            ...((change?.type === 'update' && change.rows) || []),
            addChange,
          ],
        },
      ];
    }
    throw new Error('Row needs newRowTemplate and rows to add more');
  };
  return updateTable(state, part, section, table, addRow);
};

export default addAnnualReportRow;
