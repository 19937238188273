import gql from 'graphql-tag';

export const SAVE_YEAR_REPORT = gql`
  mutation setYearReportData(
    $customerId: String!
    $year: Int!
    $settingsAndBaseInformation: AWSJSON
    $managementReport: AWSJSON
    $incomeStatement: AWSJSON
    $balanceSheet: AWSJSON
    $flowAnalysis: AWSJSON
    $notes: AWSJSON
    $ending: AWSJSON
  ) {
    setYearReportData(
      customerId: $customerId
      year: $year
      settingsAndBaseInformation: $settingsAndBaseInformation
      managementReport: $managementReport
      incomeStatement: $incomeStatement
      balanceSheet: $balanceSheet
      flowAnalysis: $flowAnalysis
      notes: $notes
      ending: $ending
    ) {
      settingsAndBaseInformation
      managementReport
      incomeStatement
      balanceSheet
      flowAnalysis
      notes
      ending
    }
  }
`;
