import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  WithTheme,
} from '@material-ui/core';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import styled from '@emotion/styled';
import { MultiReferenceCell } from 'types/AnnualReport/cell';
import { Notes, NotesSection } from 'types/AnnualReport/parts/notes';
import { updateAnnualReportCellReferences } from 'redux/actions/AnnualReportView/actions';
import { numberValue, stringValue } from 'utils/AnnualReport/cell';

interface NoteSelectorProps {
  className?: string;
  cellId: string;
  cell: MultiReferenceCell;
  editing?: boolean;
}

const sortNumerical = (a, b) => parseInt(a) - parseInt(b);

const idPattern = /id\((.*).number\)/;

const referencedNotes = (references: string[]): string[] =>
  references
    .map(ref => idPattern.exec(ref)?.[1] || '')
    .filter(ref => ref !== '');

interface IdNote {
  id: string;
  note: NotesSection;
}

const mapCustomNotes = (key: string, notes: NotesSection[]): IdNote[] =>
  notes.map((note, index) => ({ id: `notes.${key}-${index}`, note }));

const mapNotes = (notes: Notes | undefined): IdNote[] => {
  if (!notes) {
    return [];
  }
  return Object.keys(notes)
    .filter(key => key !== 'type')
    .flatMap((key: string): IdNote[] =>
      notes[key].sections
        ? mapCustomNotes(key, notes[key].sections)
        : [{ id: `notes.${key}`, note: notes[key] }]
    )
    .filter((result: IdNote) => result.note);
};

const ThinSelect = styled(Select)<WithTheme>`
  width: 6rem;
  .MuiOutlinedInput-input {
    padding-top: ${props => props.theme.spacing(1)}px;
    padding-bottom: ${props => props.theme.spacing(1)}px;
  }
  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.palette.grey[400]};
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.palette.grey[300]};
  }
`;

interface SelectorProps {
  className?: string;
  cellId: string;
  selectedNotes: string[];
  renderValue: (selected: any) => string;
  allNotes: IdNote[];
}

const Selector = memo(
  ({
    className,
    cellId,
    selectedNotes,
    renderValue,
    allNotes,
  }: SelectorProps) => {
    const dispatch = useDispatch();
    const onSelect = useCallback(
      event => {
        dispatch(
          updateAnnualReportCellReferences(
            cellId,
            event.target.value.map(id => `id(${id}.number)`)
          )
        );
      },
      [dispatch, cellId]
    );

    return (
      <ThinSelect
        multiple
        value={selectedNotes}
        variant="outlined"
        renderValue={renderValue}
        onChange={onSelect}
        className={className}
      >
        {allNotes.map(({ id, note }) => (
          <MenuItem value={id} key={id}>
            <Checkbox checked={selectedNotes.includes(id)} />
            <ListItemText
              primary={`${numberValue(note.number) || ''} ${stringValue(
                note.name
              ) || ''}`}
            />
          </MenuItem>
        ))}
      </ThinSelect>
    );
  }
);

const EditingNoteSelector = (props: NoteSelectorProps) => {
  const notes = useSelector(
    (state: RootState) => state.annualReport.report?.notes
  );
  const selectedNotes = referencedNotes(props.cell.references);
  const allNotes = useMemo(() => mapNotes(notes), [notes]);

  const allNotesLookup = useMemo(
    () =>
      allNotes.reduce((result, { id, note }) => {
        result[id] = note;
        return result;
      }, {}),
    [allNotes]
  );

  const renderValue = useCallback(
    selected =>
      (selected as string[])
        .map(id => allNotesLookup[id]?.number?.value)
        .sort(sortNumerical)
        .join(', '),
    [allNotesLookup]
  );

  return (
    <Selector
      cellId={props.cellId}
      allNotes={allNotes}
      renderValue={renderValue}
      selectedNotes={selectedNotes}
      className={props.className}
    />
  );
};

const NoteSelector = (props: NoteSelectorProps) => {
  return props.editing ? (
    <EditingNoteSelector {...props} />
  ) : (
    <Typography className={props.className}>
      {props.cell.values.join(', ')}
    </Typography>
  );
};

export default NoteSelector;
