import React from 'react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Breadcrumbs, Typography, WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';
import {
  PrimaryButton,
  SecondaryButton,
  SimplifiedTextfield,
} from 'components/UI';
import Logo from 'components/UI/Logo';
import UserClientImage from 'utils/UserClientImage';
import { useIntl } from 'react-intl';

const CustomerName = styled.p`
  font-size: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

const Container = styled.div<WithTheme>`
  margin-top: ${props => props.theme.spacing(4)}px;
  margin-bottom: ${props => props.theme.spacing(4)}px;
`;

const ButtonWrapper = styled.div`
  margin-right: 16px;
`;

const RowContainer = styled.div`
  display: flex;
`;

const Head = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

const EditHead = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CustomerLogo = styled(Logo)`
  width: 6rem;
  height: 6rem;
  margin-right: 1rem;
  display: block;
`;

const EditLogoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  width: 180px;
`;

interface EditableProps {
  customerName: string;
  logo?: string;
  handleCustomerNameChange: (e) => void;
  handleLogoSelect: (e) => void;
}

const Editable = ({
  customerName,
  logo,
  handleCustomerNameChange,
  handleLogoSelect,
}: EditableProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Breadcrumbs>
        <Typography>{customerName}</Typography>
        <Typography>{formatMessage({ id: 'client.editing' })}</Typography>
      </Breadcrumbs>
      <EditHead>
        <EditLogoContainer>
          <UserClientImage
            editing={true}
            imageUrl={logo}
            handleChange={handleLogoSelect}
            square={true}
          />
        </EditLogoContainer>
        <RowContainer>
          <SimplifiedTextfield
            width={420}
            editing={true}
            labelPlacement="top"
            dataCy="customer-textfield-name"
            value={customerName}
            label={formatMessage({ id: 'client.name' })}
            required={true}
            onChange={handleCustomerNameChange}
          />
        </RowContainer>
      </EditHead>
    </>
  );
};

interface Representation {
  customerName: string;
  logo?: string;
  openEdit: () => void;
  openDocuments: () => void;
  openFortnoxIntegration: () => void;
}

const Representation = ({
  customerName,
  logo,
  openEdit,
  openDocuments,
  openFortnoxIntegration,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Head>
      <CustomerLogo src={logo} />
      <div>
        <RowContainer>
          <CustomerName data-cy="customer-name">{customerName}</CustomerName>
        </RowContainer>
        <RowContainer>
          <ButtonWrapper>
            <SecondaryButton
              data-cy="customer-edit-btn"
              variant="outlined"
              color="primary"
              onClick={openEdit}
            >
              {formatMessage({ id: 'edit' })}
            </SecondaryButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <PrimaryButton
              startIcon={<InsertDriveFileIcon />}
              onClick={openDocuments}
            >
              {formatMessage({ id: 'documents' })}
            </PrimaryButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <PrimaryButton onClick={openFortnoxIntegration}>
              {formatMessage({ id: 'customerinfo.fortnox' })}
            </PrimaryButton>
          </ButtonWrapper>
        </RowContainer>
      </div>
    </Head>
  );
};

interface CustomerInfoHead {
  edit: boolean;
  logo?: string;
  customerName: string;
  handleCustomerNameChange: (e) => void;
  handleLogoSelect: (e) => void;
  openEdit: () => void;
  openDocuments: () => void;
  openFortnoxIntegration: () => void;
}

const CustomerInfoHead = ({
  edit,
  logo,
  customerName,
  handleLogoSelect,
  handleCustomerNameChange,
  openEdit,
  openDocuments,
  openFortnoxIntegration,
}: CustomerInfoHead) => {
  return (
    <Container>
      {edit ? (
        <Editable
          logo={logo}
          customerName={customerName}
          handleLogoSelect={handleLogoSelect}
          handleCustomerNameChange={handleCustomerNameChange}
        />
      ) : (
        <Representation
          logo={logo}
          customerName={customerName}
          openEdit={openEdit}
          openDocuments={openDocuments}
          openFortnoxIntegration={openFortnoxIntegration}
        />
      )}
    </Container>
  );
};

export default CustomerInfoHead;
