import React, { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import LoginContent from './LoginContent';
import ForgotContent from './ForgotContent';
import CreateContent from './CreateContent';
import ConformationContent from './ConfirmationContent';
import ResetPasswordContent from './ResetPasswordContent';
import { ReactComponent as BetaLogo } from 'assets/AgoyLogoBETA.svg';
import ResendCodeContent from './ResendCodeContent';

const AgoyLogo = styled(BetaLogo)`
  position: absolute;
  top: 32px;
  left: 100px;
  width: 220px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
`;

const LoginWrapper = styled.div`
  background-color: #fff;
  width: 50vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginImageContainer = styled.div`
  display: flex;
  background: url(login-background.jpg);
  background-size: cover;
  width: 50vw;
`;

const AdContainer = styled.div`
  margin: auto;
  border-radius: 4px;
  background-color: rgba(0, 49, 60, 0.6);
  padding: 1.5rem;
  color: white;
  max-width: 685px;
  & > h2 {
    font-size: 2.5rem;
    margin: 0;
    color: #00ce7c;
  }
  & > p:last-of-type {
    margin-bottom: 0;
  }
  & a {
    color: #00ce7c;
  }
`;

const Login = () => {
  const [openView, setOpenView] = useState<
    'login' | 'create' | 'forgot' | 'confirm' | 'reset' | 'resend'
  >('login');

  const openConfirm = () => setOpenView('confirm');
  const openLogin = () => setOpenView('login');
  const openForgot = () => setOpenView('forgot');
  const openCreate = () => setOpenView('create');
  const openReset = () => setOpenView('reset');
  const openResend = () => setOpenView('resend');

  const currentView = useMemo(() => {
    switch (openView) {
      case 'reset':
        return <ResetPasswordContent backToLogin={openLogin} />;
      case 'forgot':
        return (
          <ForgotContent
            backToLogin={openLogin}
            goToResetPassword={openReset}
          />
        );
      case 'create':
        return (
          <CreateContent OpenConfirm={openConfirm} backToLogin={openLogin} />
        );
      case 'confirm':
        return (
          <ConformationContent
            openResend={openResend}
            backToCreate={openCreate}
            backToLogin={openLogin}
          />
        );
      case 'login':
        return (
          <LoginContent
            createUserCallback={openCreate}
            forgotPasswordCallback={openForgot}
          />
        );
      case 'resend':
        return <ResendCodeContent backToConfirm={openConfirm} />;
    }
  }, [openView]);

  return (
    <Wrapper>
      <LoginWrapper>
        <AgoyLogo />
        {currentView}
      </LoginWrapper>
      <LoginImageContainer>
        <AdContainer>
          <h2>Vill du vara med och revolutionera redovisningsvärlden?</h2>
          <p>Ta del av Agoys beta version!</p>
          <p>Registrera dig nu och använd Agoy gratis i 30 dagar.</p>
        </AdContainer>
      </LoginImageContainer>
    </Wrapper>
  );
};

export default Login;
