import React from 'react';
import NotPayedAdmin from './notPayedAdmin';
import NotPayedUser from './notPayedUser';
import { useSelector } from 'redux/reducers';

const NotPayed = props => {
  const userEmail = useSelector(state => state.user.email);
  const admins = useSelector(state => state.organisation.admins);
  const isAdmin = userEmail ? admins.includes(userEmail) : false;

  return isAdmin ? <NotPayedAdmin {...props} /> : <NotPayedUser {...props} />;
};

export default NotPayed;
