import React from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { Typography, WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';

import config from 'config';

const ListWrapper = styled.ol<WithTheme>`
  padding-left: 1rem;
  & > li {
    font-weight: 500;
    margin: ${props => props.theme.spacing(1)}px 0;
  }
`;

const Steps = ({ companyName }: { companyName: string }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Typography variant="h3">
        {formatMessage({ id: 'integration.fortnox.activate.title' })}
      </Typography>
      <ListWrapper>
        <li>
          <Typography>
            <FormattedHTMLMessage
              id="integration.fortnox.activate.step1"
              values={{ name: companyName }}
              tagName="span"
            />
          </Typography>
        </li>
        <li>
          <Typography>
            {formatMessage({
              id: 'integration.fortnox.activate.step2',
            })}
          </Typography>
        </li>
        <li>
          <Typography>
            {formatMessage({
              id: 'integration.fortnox.activate.step3',
            })}
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedHTMLMessage
              id="integration.fortnox.activate.step4"
              values={{ clientId: config.fortnoxClientId }}
              tagName="span"
            />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedHTMLMessage
              id="integration.fortnox.activate.step5"
              tagName="span"
            />
          </Typography>
        </li>
        <li>
          <Typography>
            {formatMessage({
              id: 'integration.fortnox.activate.step6',
            })}{' '}
          </Typography>
        </li>
        <li>
          <Typography>
            {formatMessage({
              id: 'integration.fortnox.activate.step7',
            })}{' '}
          </Typography>
        </li>
      </ListWrapper>
    </>
  );
};

export default Steps;
