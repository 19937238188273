export const ORG_NUMBER = 'orgNumber',
  VAT_PERIOD = 'vatPeriod',
  COMPANY_TYPE = 'type',
  CLOSING_PERIOD = 'closingPeriod',
  CLOSING_MONTH = 'closingMonth',
  CLIENT_MANAGER = 'manager',
  COMPANY_NAME = 'name',
  COMPANY_ADDRESS = 'address',
  EMAIL = 'email',
  PHONE_NUMBER = 'number',
  CONTACT_PERSON = 'contact';

export const clientInformationAttributes = [
  ORG_NUMBER,
  COMPANY_ADDRESS,
  CLOSING_PERIOD,
  VAT_PERIOD,
  COMPANY_TYPE,
  CLOSING_MONTH,
  CLIENT_MANAGER,
];
export const contactInformationAttributes = [
  CONTACT_PERSON,
  PHONE_NUMBER,
  EMAIL,
];

export const CUSTOMER_KEYS = [
  ...clientInformationAttributes,
  ...contactInformationAttributes,
];
export const additionalInformation = [
  COMPANY_ADDRESS,
  ORG_NUMBER,
  PHONE_NUMBER,
  CONTACT_PERSON,
  EMAIL,
];

export const fullCostomerInformation = CUSTOMER_KEYS.reduce((tot, val) => {
  return { ...tot, [val]: '' };
}, {});
