import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addAnnualReportRow } from 'redux/actions/AnnualReportView/actions';
import { Note28Section } from 'types/AnnualReport/parts/notes';
import { numberValue, stringValue } from 'utils/AnnualReport/cell';
import Comment from '../../UI/Comment';
import FieldSection from '../../UI/FieldSection';
import { FieldSectionTitle } from '../../UI/FieldSectionTitle';
import FinancialYearsHeader from '../../UI/FinancialYearsHeader';
import Header from '../../UI/Header';
import { Note } from '../../UI/Note';
import ThreeColumnTable from '../../UI/ThreeColumnTable';

type Note28Props = { print?: boolean } & Note28Section;

const Note28 = ({
  active,
  number,
  general,
  table,
  secondTable,
  sumTable,
  comment,
  print = false,
}: Note28Props) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);

  if (!active) return null;

  return (
    <Note id="note28">
      <Header
        noteId="note28"
        noteNumber={numberValue(number)}
        editing={editing}
        onEditToggle={editing => {
          setEditing(!editing);
        }}
        print={print}
      />

      <FinancialYearsHeader />

      <FieldSectionTitle noteId="note28" id="general" />
      <FieldSection
        noteId="note28"
        id="general"
        value={stringValue(general) || ''}
        editing={editing}
        print={print}
      />

      <Box mt={print ? 1 : 3} mb={print ? 1 : 3}>
        <ThreeColumnTable
          table={table}
          tableId="notes.note28.table"
          editing={editing}
          onAddRow={() => {
            dispatch(addAnnualReportRow('notes.note28.table'));
          }}
          print={print}
        />
      </Box>

      <Box mt={print ? 1 : 3} mb={print ? 1 : 3}>
        <ThreeColumnTable
          table={secondTable}
          tableId="notes.note28.secondTable"
          editing={editing}
          onAddRow={() => {
            dispatch(addAnnualReportRow('notes.note28.secondTable'));
          }}
          print={print}
        />
      </Box>

      <Box mt={print ? 1 : 3} mb={print ? 1 : 3}>
        <ThreeColumnTable
          table={sumTable}
          tableId="notes.note28.sumTable"
          editing={editing}
          print={print}
        />
      </Box>

      {/* Comment */}
      <Comment
        id="notes.note28.comment"
        editing={editing}
        value={stringValue(comment) || ''}
        print={print}
      />
    </Note>
  );
};

export default Note28;
