import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  CardExpiryElement,
  CardCvcElement,
  CardNumberElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useMutation } from '@apollo/react-hooks';
import {
  Typography,
  Paper,
  Grid,
  WithTheme,
  Button,
  TextField,
} from '@material-ui/core';
import Alert, { Color as AlertSeverity } from '@material-ui/lab/Alert';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { addOrganisationInfo } from 'redux/actions/Organisation';
import { SET_PAYMENT_METHOD } from 'Graphql/Mutations/OrganisationMutations';
import { subscriptionStatus as subscriptionStatusType } from 'types/Organisation/types';
import Snackbar from 'components/UI/Snackbar';
import { CTAButton } from 'components/UI/Buttons';
import Container from './Container';
import ContainerSubTitle from './ContainerSubTitle';
import withStripeProvider from '../withStripeProvider';

const DotValue = styled.div<WithTheme>`
  display: inline-block;
  padding-right: ${props => props.theme.spacing(1)}px;
  color: ${props => props.theme.palette.text.secondary};
`;

const CardNumberLast4 = styled(Typography)<WithTheme>`
  display: inline-block;
`;

// Some fugly overrides to make Stripe input wrapper look a bit like our input fields
const StripeCardInputWrapper = styled.div<WithTheme>`
  padding: 11px 10px 10px 10px;
  margin-top: -7px;
  border: 1px solid rgba(51, 51, 51);
  border-radius: ${props => props.theme.shape.borderRadius}px;
`;

const InputNewEmailTextField = styled(TextField)<WithTheme>`
  margin-top: -${props => props.theme.spacing(1)}px;
`;

type PaymentSnackbar = {
  isOpen: boolean;
  severity: AlertSeverity;
  message: string | undefined;
};

const Payment = () => {
  const { billingEmail = '', last4 } = useSelector(state => state.organisation);

  const subscriptionStatus = useSelector(
    state => state.organisation.subscriptionStatus
  );

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [newBillingEmail, setNewBillingEmail] = useState('');
  const [changePaymentOpen, setChangePaymentOpen] = useState(false);
  const [toast, setToast] = useState<PaymentSnackbar>({
    isOpen: false,
    severity: 'info',
    message: '',
  });
  const [loadingStripeRequest, setLoadingStripeRequest] = useState(false);

  useEffect(() => {
    setNewBillingEmail(billingEmail);
  }, [billingEmail]);

  const [setPaymentMethod, { loading: settingPaymentMethod }] = useMutation(
    SET_PAYMENT_METHOD,
    {
      onCompleted: async ({ setPaymentMethod }) => {
        try {
          const { setupIntent, paymentMethod, paymentIntent } = JSON.parse(
            setPaymentMethod
          ).body;

          await confirmPayment(setupIntent, paymentIntent);

          setToast({
            isOpen: true,
            severity: 'success',
            message: intl.formatMessage({ id: 'dashboard.payment.setSucess' }),
          });
          setChangePaymentOpen(false);

          // Update the payment method and subscription status in the UI. Note
          // that Stripe will send a webhook when the payment is confirmed to
          // our servers which will update the subscription status in the DB.
          // the changes here are only visual. There might be a time gap
          // between the time this payment is confirmed and the stripe
          // webhook which we should address in the future
          dispatch(
            addOrganisationInfo({
              last4: paymentMethod.card.last4,
              subscriptionStatus: subscriptionStatus.active,
            })
          );
        } catch (err) {
          setToast({ isOpen: true, severity: 'error', message: err.message });
        }
      },
      onError: error => {
        setToast({
          isOpen: true,
          severity: 'error',
          message: intl.formatMessage({ id: 'error' }),
        });
      },
    }
  );

  const handleSubmit = async event => {
    event.preventDefault();
    const cardNumberElement = elements!.getElement(CardNumberElement);
    setLoadingStripeRequest(true);

    const payDirectly =
      subscriptionStatus !== subscriptionStatusType.trialing &&
      subscriptionStatus !== subscriptionStatusType.active;

    const { paymentMethod, error } = await stripe!.createPaymentMethod({
      type: 'card',
      card: cardNumberElement!,
      billing_details: {
        email: newBillingEmail,
      },
    });

    setLoadingStripeRequest(false);

    if (error) {
      setToast({ isOpen: true, severity: 'error', message: error.message });
    } else {
      setPaymentMethod({
        variables: {
          billingEmail: newBillingEmail,
          paymentMethodId: paymentMethod!.id,
          payDirectly: payDirectly,
        },
      });
    }
  };

  /**
   * Sometimes credit cards require additional actions before the payment is
   * authorized. Stripe will trough confirmCardXXX (which injects an iframe)
   * create a popup which will handle any potential 2-step auth of the card
   */
  const confirmPayment = async (setupIntent, paymentIntent) => {
    if (setupIntent !== undefined) {
      // If the user is updating the payment method and the subscriptions status
      // does not require an immidiate payment, a setupIntent object will be present.
      // SetupIntents are used for future payments
      const { client_secret: clientSecret, status } = setupIntent;
      if (status === 'requires_action') {
        const { error } = await stripe!.confirmCardSetup(clientSecret);
        if (error) throw error;
      }
    } else if (paymentIntent !== undefined) {
      // If the subscrition has been cancelled and the user needs to pay
      // immediately, the server will return a payment intent.
      // PaymentIntents are used for emmitiate payments
      const { client_secret: clientSecret, status } = paymentIntent;
      if (status === 'requires_action') {
        const { error } = await stripe!.confirmCardPayment(clientSecret);
        if (error) throw error;
      }
    }
  };

  const intl = useIntl();

  const handleCloseToaster = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast({ isOpen: false, severity: 'info', message: '' });
  };

  return (
    <>
      <Paper elevation={4}>
        <Container>
          <form onSubmit={handleSubmit}>
            <div>
              <Snackbar open={toast.isOpen} onClose={handleCloseToaster}>
                <Alert severity={toast.severity}>{toast.message}</Alert>
              </Snackbar>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {intl.formatMessage({ id: 'dashboard.payment.heading' })}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <ContainerSubTitle>
                  {intl.formatMessage({ id: 'dashboard.payment.cardNumber' })}
                </ContainerSubTitle>
              </Grid>
              <Grid item xs={7}>
                <ContainerSubTitle>
                  {intl.formatMessage({ id: 'dashboard.payment.invoiceEmail' })}
                </ContainerSubTitle>
              </Grid>
              <Grid item xs={5}>
                {changePaymentOpen ? (
                  <StripeCardInputWrapper>
                    <CardNumberElement />
                  </StripeCardInputWrapper>
                ) : (
                  <>
                    <DotValue>●●●●</DotValue>
                    <DotValue>●●●●</DotValue>
                    <DotValue>●●●●</DotValue>
                    {last4 ? (
                      <CardNumberLast4 variant="body1">{last4}</CardNumberLast4>
                    ) : (
                      <DotValue>●●●●</DotValue>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={7}>
                {changePaymentOpen ? (
                  <InputNewEmailTextField
                    id="payment-new-email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newBillingEmail}
                    onChange={e => {
                      setNewBillingEmail(e.target.value);
                    }}
                  />
                ) : (
                  <Typography variant="body1">{billingEmail}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <ContainerSubTitle>
                  {intl.formatMessage({
                    id: 'dashboard.payment.cardExpireDate',
                  })}
                </ContainerSubTitle>
              </Grid>
              <Grid item xs={10}>
                <ContainerSubTitle>
                  {intl.formatMessage({ id: 'dashboard.payment.cardCVV' })}
                </ContainerSubTitle>
              </Grid>
              <Grid item xs={2}>
                {changePaymentOpen ? (
                  <StripeCardInputWrapper>
                    <CardExpiryElement />
                  </StripeCardInputWrapper>
                ) : (
                  <DotValue>●● / ●●</DotValue>
                )}
              </Grid>
              <Grid item xs={2}>
                {changePaymentOpen ? (
                  <StripeCardInputWrapper>
                    <CardCvcElement />
                  </StripeCardInputWrapper>
                ) : (
                  <DotValue>●●●</DotValue>
                )}
              </Grid>
              <Grid container item justify="flex-end">
                {changePaymentOpen ? (
                  <Grid container justify="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        color="primary"
                        onClick={() => {
                          setChangePaymentOpen(false);
                        }}
                      >
                        Avbryt
                      </Button>
                    </Grid>
                    <Grid item>
                      <CTAButton
                        disableElevation
                        type="submit"
                        loading={
                          settingPaymentMethod ||
                          loadingStripeRequest ||
                          !stripe
                        }
                      >
                        {intl.formatMessage({ id: 'save' })}
                      </CTAButton>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      color="primary"
                      onClick={() => {
                        setChangePaymentOpen(true);
                      }}
                    >
                      {intl.formatMessage({
                        id: 'dashboard.payment.updatePaymentButton',
                      })}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </form>
        </Container>
      </Paper>
    </>
  );
};

export default withStripeProvider(Payment);
