import React from 'react';
import { useIntl } from 'react-intl';
import FileSaver from 'file-saver';
import { Button } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDownward';

import { useSelector } from 'redux/reducers';
import generateCsv from 'utils/generateCSV';

const DownloadCsvButton = (props: { customerName: string }) => {
  const intl = useIntl();

  // STATE
  const sieData = useSelector(state => state.accountingView.sieData);

  // HANDLERS
  const handleCSVDownload = () => {
    const csvBlob = generateCsv(sieData);
    FileSaver.saveAs(csvBlob, `${props.customerName}.csv`);
  };

  return (
    <Button
      startIcon={<ArrowDropDown />}
      onClick={handleCSVDownload}
      data-cy={'add-client'}
    >
      {intl.formatMessage({ id: 'sidemenu.download.csvFile' })}
    </Button>
  );
};

export default DownloadCsvButton;
