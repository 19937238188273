import gql from 'graphql-tag';

export const SET_ENDING_ADJUSTMENT = gql`
  mutation setEndingAdjustment(
    $year: Int!
    $customerId: String!
    $adjustmentData: [EndingAdjustmentInput!]!
  ) {
    setEndingAdjustment(
      year: $year
      customerId: $customerId
      adjustmentData: $adjustmentData
    ) {
      description
      account
      amount
    }
  }
`;
