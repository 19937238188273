import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TableCell, WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';
import HierarchicalTable, {
  HierarchicalTableProps,
  AddRowProps,
} from '../../UI/HierarchicalTable';
import HierarchicalTableRow, {
  HierarchicalTableRowProps,
  RowLevel1,
  RowLevel2,
  RowLevel3,
} from '../../UI/HierarchicalTable/HierarchicalTableRow';
import AddIcon from '@material-ui/icons/Add';
import { deleteAnnualReportRow } from 'redux/actions/AnnualReportView/actions';
import AccountSelector from 'components/UI/AccountSelector';
import AddTableRow from '../../UI/HierarchicalTable/AddTableRow';
import { AnnualReportTable } from 'types/AnnualReport/table';
import { Cell } from 'types/AnnualReport/cell';

type CashFlowStatementTableProps = Pick<
  HierarchicalTableProps,
  'className' | 'table' | 'tableId'
> & { print?: boolean; editing?: boolean; showAccounts?: boolean };

const DifferenceRow = styled(RowLevel1)<WithTheme>`
  .MuiTableCell-body {
    color: ${props => props.theme.palette.success.main} !important;
  }
`;

const StickyDifferenceRow = styled(DifferenceRow)<WithTheme>`
  .MuiTableCell-body {
    position: sticky;
    top: 40px;
    border-bottom: 1px solid ${props => props.theme.palette.grey[200]};
    background-color: ${props => props.theme.palette.background.default};
    z-index: 1;
  }
`;

const CashFlowStatementRowLevel1 = (props: HierarchicalTableRowProps) => {
  if (props.row.id === 'topDifference') {
    return props.print ? null : (
      <StickyDifferenceRow {...props} editing={false} />
    );
  }
  if (props.row.id === 'difference') {
    return props.print ? null : <DifferenceRow {...props} editing={false} />;
  }
  return <RowLevel1 {...props} />;
};

const WarningRowLevel2 = styled(RowLevel2)<WithTheme>`
  .MuiTableCell-body {
    color: ${props => props.theme.palette.error.main} !important;
  }
`;

const CashFlowStatementRowLevel2 = (props: HierarchicalTableRowProps) => {
  if (
    props.baseId === 'cashFlowStatement.section.main.changeLiquidAssets' &&
    props.row.id === '1'
  ) {
    // "Förändring av likvida medel enligt denna kassaflödesanalys" should be hidden if the calculated difference is zero and red otherwise
    // The solution is to use a MessageCell with a parameter referencing the difference.
    const labelCell = props.row.cells?.label;
    if (labelCell?.type === 'msg') {
      const diff = labelCell.parameterValues?.diff;
      if ((typeof diff === 'number' && Math.abs(diff) < 2) || props.print) {
        return null;
      } else {
        return <WarningRowLevel2 {...props} />;
      }
    }
  }
  return <RowLevel2 {...props} />;
};

const editOnlyAdjustments = (editing, columnId) =>
  columnId === 'adjustment' ? editing : false;

const DeletableRowLevel3 = (props: HierarchicalTableRowProps) => {
  const dispatch = useDispatch();

  const deletable = props.row.cells?.['label']?.type === 'string';

  const onDelete = useCallback(() => {
    dispatch(deleteAnnualReportRow(`${props.baseId}.${props.row.id}`));
  }, [dispatch, props.row.id, props.baseId]);

  return <RowLevel3 {...props} onDelete={deletable ? onDelete : undefined} />;
};

const DeletableRowLevel4 = (props: HierarchicalTableRowProps) => {
  const dispatch = useDispatch();

  const deletable =
    props.baseId.startsWith(
      'cashFlowStatement.section.main.currentOperations.'
    ) ||
    props.baseId.startsWith(
      'cashFlowStatement.section.main.changeLiquidAssets'
    );

  const onDelete = useCallback(() => {
    dispatch(deleteAnnualReportRow(`${props.baseId}.${props.row.id}`));
  }, [dispatch, props.row.id, props.baseId]);

  return (
    <HierarchicalTableRow
      {...props}
      editingOverride={editOnlyAdjustments}
      onDelete={deletable ? onDelete : undefined}
    />
  );
};

const hasRefs = (cell: Cell | undefined) =>
  cell && cell.type === 'refs' && cell.references.length > 0;

const HideZerosLevel3 = (props: HierarchicalTableRowProps) => {
  const cell = props.row.cells?.['value'];
  if (cell && cell.type === 'ref') {
    const ref = cell.value;
    if (ref !== undefined && typeof ref === 'number' && Math.abs(ref) < 1.0) {
      if (!hasRefs(props.row.cells?.notes)) {
        return null;
      }
    }
  }
  return <RowLevel3 {...props} />;
};

const AccountRow = styled(DeletableRowLevel4)<WithTheme>`
  .MuiTableCell-body:first-of-type {
    padding-left: ${props => props.theme.spacing(6)}px;
  }
`;

const AddRow = (props: AddRowProps) =>
  props.level === 3 ? <AddAccountRow {...props} /> : <AddPostRow {...props} />;

const AddPostRow = ({ onClick, level, parent, columns }: AddRowProps) => {
  const onCellClick = useCallback(() => onClick(), [onClick]);
  return (
    <AddTableRow level={level}>
      <TableCell>
        <div className="add" onClick={onCellClick}>
          <Button startIcon={<AddIcon />}>Lägg till rad</Button>
        </div>
      </TableCell>
      {columns.slice(1).map(column =>
        parent.newRowTemplate ? (
          <TableCell key={column.id} onClick={onCellClick}>
            <div className="add" />
          </TableCell>
        ) : (
          <TableCell key={column.id} />
        )
      )}
    </AddTableRow>
  );
};

const AddAccountRow = ({
  onClick,
  level,
  parent,
  parentId,
  columns,
}: AddRowProps) => {
  const onAddAccount = useCallback(
    (
      selectedAccount: string | undefined,
      selectedAccountName: string | undefined,
      close?: () => void
    ) => {
      if (selectedAccount && selectedAccountName) {
        onClick({
          account: selectedAccount,
          label: `${selectedAccount} ${selectedAccountName}`,
        });
        if (close) {
          close();
        }
      }
    },
    [onClick]
  );
  const accountSelectorRef = useRef<HTMLButtonElement>();
  const onCellClick = useCallback(() => {
    accountSelectorRef.current?.click();
  }, []);

  return (
    <AddTableRow level={level}>
      <TableCell>
        <div className="add" onClick={onCellClick}>
          <AccountSelector
            ref={accountSelectorRef}
            rowId={parentId}
            onAccountSelected={onAddAccount}
            variant="text"
            startIcon={<AddIcon />}
          >
            Lägg till konto
          </AccountSelector>
        </div>
      </TableCell>
      {columns.slice(1).map(column =>
        parent.newRowTemplate ? (
          <TableCell key={column.id} onClick={onCellClick}>
            <div className="add" />
          </TableCell>
        ) : (
          <TableCell key={column.id} />
        )
      )}
    </AddTableRow>
  );
};

const rowComponentsEditing = [
  CashFlowStatementRowLevel1,
  CashFlowStatementRowLevel2,
  DeletableRowLevel3,
  AccountRow,
];

const rowComponentsWithAccounts = [
  CashFlowStatementRowLevel1,
  CashFlowStatementRowLevel2,
  RowLevel3,
  AccountRow,
];
const rowComponents = [
  CashFlowStatementRowLevel1,
  CashFlowStatementRowLevel2,
  HideZerosLevel3,
];

const CashFlowStatementTable = ({
  className,
  table,
  tableId,
  print,
  showAccounts,
  editing,
}: CashFlowStatementTableProps) => {
  const modTable = useMemo((): AnnualReportTable | undefined => {
    return print && table
      ? {
          ...table,
          columns: table.columns.filter(col => col.id !== 'adjustment'),
        }
      : table;
  }, [print, table]);

  return (
    <HierarchicalTable
      className={className}
      table={modTable}
      tableId={tableId}
      rowComponents={
        editing
          ? rowComponentsEditing
          : showAccounts
          ? rowComponentsWithAccounts
          : rowComponents
      }
      AddRow={editing ? AddRow : undefined}
      editing={editing}
      stickyHeaderOffset={0}
      print={print}
    />
  );
};

export default styled(CashFlowStatementTable)<WithTheme>`
  .MuiTableCell-root:nth-of-type(2) {
    text-align: right;
    width: max-content;
  }
  .MuiTableCell-root:nth-of-type(3) {
    text-align: right;
    padding-left: ${props => props.theme.spacing(6)}px;
  }
  .MuiTableCell-root:nth-of-type(4) {
    text-align: right;
  }
`;
