import gql from 'graphql-tag';
export const ADD_TAX_MODULE = gql`
  mutation putTaxData(
    $customerId: String!
    $period: String!
    $taxModule: String!
    $taxBlob: AWSJSON!
  ) {
    putTaxData(
      customerId: $customerId
      period: $period
      taxModule: $taxModule
      taxBlob: $taxBlob
    ) {
      taxModule
      taxBlob
    }
  }
`;
