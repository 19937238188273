/**
 * Placeholder view  for when page are being loaded
 */
import React from 'react';
import styled from '@emotion/styled';

import LoadingLogo from 'components/UI/LoadingLogo';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingPlaceholder = () => {
  return (
    <Wrapper>
      <LoadingLogo size="medium" />
    </Wrapper>
  );
};
export default LoadingPlaceholder;
