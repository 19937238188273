import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tabs,
  Tab,
  AppBar,
  Radio,
  ListItemIcon,
} from '@material-ui/core';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { ADD_ORGANISATION, ACCEPT_ORG_INVITE } from 'Graphql/Mutations';
import { GET_USER_INIVTES } from 'Graphql/Queries';
import { useDispatch } from 'react-redux';
import {
  refreshSession,
  setOrganisation,
  setUserFirstLogin,
} from 'redux/actions';
import { useIntl } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import LoadingLogo from '../../UI/LoadingLogo';

const classes = {
  appbar: {
    marginBottom: '1.8rem',
  },
};

const CreateOrganisation = () => {
  const dispatch = useDispatch();
  const [organisationName, setOrganisationName] = useState('');
  const [currentTab, setcurrentTab] = useState(0);
  const intl = useIntl();
  const [currentCompany, setCurrentCompany] = useState();
  const { loading: loadingInvites, data: invites } = useQuery(GET_USER_INIVTES);
  const client = useApolloClient();
  const [addOrganisation, { loading: addingOrganisation }] = useMutation(
    ADD_ORGANISATION,
    {
      onCompleted: data => {
        dispatch(setOrganisation(data.addOrganisation));
        dispatch(refreshSession());
        // Action to display the onboarding tour1 in CustomersView
        dispatch(setUserFirstLogin());
      },
    }
  );

  const [acceptOrgInvite] = useMutation(ACCEPT_ORG_INVITE, {
    onCompleted: async data => {
      const organisation = data.acceptInvite;

      await client.cache.reset();
      dispatch(setOrganisation(organisation));
      dispatch(refreshSession());
      // Action to display the onboarding tour2 in CustomersView
      dispatch(setUserFirstLogin());
    },
  });

  const handleCreateOrg = e => {
    e.preventDefault();
    addOrganisation({
      variables: {
        name: organisationName,
      },
    });
  };

  const selectOrganisation = organisation => {
    setCurrentCompany(organisation.id);
  };

  const acceptInvite = () => {
    acceptOrgInvite({
      variables: {
        organisationId: currentCompany,
      },
    });
  };

  const handleTabCHange = (_, newTab) => setcurrentTab(newTab);

  return (
    <Dialog
      aria-labelledby="Join an organisation"
      open={true}
      id="onboarding-join-org"
    >
      <AppBar position="static" color="primary" css={classes.appbar}>
        <Tabs
          variant="fullWidth"
          value={currentTab}
          onChange={handleTabCHange}
          aria-label={intl.formatMessage({ id: 'company.create.or.join' })}
        >
          <Tab label={intl.formatMessage({ id: 'company.join.org' })} />
          <Tab label={intl.formatMessage({ id: 'company.create.org' })} />
        </Tabs>
      </AppBar>

      {currentTab === 0 && (
        <>
          <DialogContent>
            <DialogContentText>
              {intl.formatMessage({ id: 'company.join.desc' })}
            </DialogContentText>
            <List component="nav">
              {loadingInvites && (
                <ListItem button>
                  <Skeleton variant="text" animation="wave" width={'100%'} />
                </ListItem>
              )}

              {invites && invites.getUserInvites.length === 0 && (
                <ListItem button>
                  <ListItemText
                    primary={intl.formatMessage({
                      id: 'company.join.no.invites',
                    })}
                  />
                </ListItem>
              )}

              {invites &&
                invites.getUserInvites.map(org => (
                  <ListItem
                    onClick={() => {
                      selectOrganisation(org);
                    }}
                    button
                    key={org.id}
                  >
                    <ListItemIcon>
                      <Radio checked={currentCompany === org.id} />
                    </ListItemIcon>
                    <ListItemText primary={org.name} />
                  </ListItem>
                ))}
            </List>
            <DialogActions>
              <Button
                color="primary"
                type="submit"
                onClick={acceptInvite}
                disabled={currentCompany === undefined}
              >
                {intl.formatMessage({ id: 'company.join' })}
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      )}

      {currentTab === 1 && (
        <>
          <DialogContent>
            <DialogContentText>
              {intl.formatMessage({ id: 'company.create.desc' })}
            </DialogContentText>

            <form onSubmit={handleCreateOrg}>
              <TextField
                label={intl.formatMessage({ id: 'company.name' })}
                value={organisationName}
                onChange={e => setOrganisationName(e.target.value)}
                fullWidth
              />
              <DialogActions>
                {addingOrganisation && <LoadingLogo />}
                {!addingOrganisation && (
                  <Button color="primary" type="submit">
                    {intl.formatMessage({ id: 'company.create' })}
                  </Button>
                )}
              </DialogActions>
            </form>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default CreateOrganisation;
