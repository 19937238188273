import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { SnackbarProps, WithTheme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { Message } from 'redux/reducers/Messages/types';
import { removeGlobalMessage } from 'redux/actions';
import Snackbar from '../Snackbar';

const StackedSnackBar = styled(Snackbar)<
  SnackbarProps & WithTheme & { index: number }
>`
  margin-top: ${props => props.theme.spacing(props.index * 8)}px;
  transition: margin-top 0.25s ease-in-out;
`;

const Messages = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const messages: Message[] = useSelector(state => state.messages.global);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const el = document.createElement('div');
    const portals = document.getElementById('portals');
    portals?.appendChild(el);
    setContainer(el);

    return () => {
      portals?.removeChild(el);
    };
  }, []);

  return (
    container &&
    createPortal(
      <>
        {messages.map((msg, index) => (
          <StackedSnackBar
            open={true}
            key={msg.id}
            index={index}
            onClose={() => {
              dispatch(removeGlobalMessage(msg.id));
            }}
          >
            <Alert severity={msg.severity}>
              <AlertTitle>
                {msg.textId ? formatMessage({ id: msg.textId }) : msg.text}
              </AlertTitle>
            </Alert>
          </StackedSnackBar>
        ))}
      </>,
      container
    )
  );
};

export default Messages;
