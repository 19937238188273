import { UpdateAnnualReportCellValueAction } from 'redux/actions/AnnualReportView/types';
import { isAnnualReportPartKey } from 'types/AnnualReport/types';
import AnnualReportState from '../types';
import updateCell from './helpers/updateCell';

const updateAnnualReportCellValue = (
  state: AnnualReportState,
  action: UpdateAnnualReportCellValueAction
) => {
  const { id, cell } = action;

  const [part, section, table, ...subId] = id.split('.');
  if (!subId || subId.length < 2) {
    console.error(`Too few id parts in ${id}`);
    return state;
  }

  if (!isAnnualReportPartKey(part)) {
    return state;
  }

  return updateCell(
    state,
    part,
    section,
    table,
    subId.slice(0, subId.length - 1),
    subId[subId.length - 1],
    previousCell => cell
  );
};

export default updateAnnualReportCellValue;
