import { useApolloClient } from 'react-apollo';
import { LOG_ERROR } from 'Graphql/Mutations';
import { useCallback } from 'react';

type errorLogger = (error: string, errorInfo?: {}) => void;

const useErrorLogger = (): errorLogger => {
  const client = useApolloClient();

  return useCallback(
    (error: string, errorInfo: any) => {
      client.mutate({
        mutation: LOG_ERROR,
        variables: {
          error,
          errorInfo: errorInfo && JSON.stringify(errorInfo),
        },
      });
    },
    [client]
  );
};

export default useErrorLogger;
