import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'redux/reducers';
import { stringValue } from 'utils/AnnualReport/cell';
import PreviewSection from '../../UI/PreviewSection';
import SignaturesAuditReportDate from './AuditReportDate';
import AccountantTitlesPreview from './People/AccountantTitlesPreview';
import BoardMembersPreview from './People/BoardMembersPreview';
import SignaturesStatement from './Statement';

const SignaturesPreview = () => {
  const signatures = useSelector(
    state => state.annualReport.report?.signatures
  );

  const { formatMessage } = useIntl();

  if (!signatures) {
    return null;
  }

  const { section } = signatures;
  const displayAuditReportAndStatement =
    section.people.rows.length > 0
      ? section.people.rows.find(row => {
          return stringValue(row?.cells?.accountantRole) !== '';
        })
      : false;

  return (
    <PreviewSection id="signatures">
      <Typography variant="h1">
        {formatMessage({
          id: 'annualReport.menu.signatures',
        })}
      </Typography>

      {/* Location */}
      {section.location && (
        <Box mt={1}>
          <Typography>
            {formatMessage({
              id: 'annualReport.signatures.section.location',
            })}
          </Typography>
          <Typography>{stringValue(section.location)}</Typography>
        </Box>
      )}

      {/* Date */}
      {section.date && (
        <Box mt={1}>
          <Typography>
            {formatMessage({
              id: 'annualReport.signatures.section.date',
            })}
          </Typography>
          <Typography>{stringValue(section.date)}</Typography>
        </Box>
      )}

      {/* Board member signatures */}
      <BoardMembersPreview rows={section.people.rows} />

      {/* AuditReportDate and Statement should only be displayed based on table content  */}
      {displayAuditReportAndStatement && (
        <Box mt={1} display="flex">
          {section.auditReportDate && (
            <SignaturesAuditReportDate
              value={stringValue(section.auditReportDate) || ''}
              print={true}
            />
          )}
          {section.statement && (
            <SignaturesStatement
              value={stringValue(section.statement) || ''}
              print={true}
            />
          )}
        </Box>
      )}

      <AccountantTitlesPreview rows={section.people.rows} />
    </PreviewSection>
  );
};

export default SignaturesPreview;
