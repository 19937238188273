import React, { useEffect, useState } from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import messages from 'translations/sv.json';
import theme from 'theme';
import printTheme from 'theme/printTheme';
import CreateOrganisation from 'components/Modals/CreateOrganisation';
import { useDispatch } from 'react-redux';
import Router from 'components/Router';
import AppHeader from 'components/UI/AppHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import NotPayed from 'components/Modals/NotPayed';
import { withRouter } from 'react-router-dom';
import flatten from 'flat';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'emotion-theming';
import { ApolloProvider } from 'react-apollo';
import { createIdentityPoolClient } from 'utils/AuthenticatedClient';
import { PrintContextProvider } from 'components/UI/PrintContext';
import { injectClient } from 'redux/middleware/apolloMiddlewareClient';
import { GAProvider } from 'Analytics/GAContext';
import { FallbackComponent } from 'components/UI/ErrorBoundary';
import { AgoyAppClientProvider } from 'utils/AgoyAppClient/AgoyAppClientContext';
import Messages from 'components/UI/Messages/Messages';
import DrawerContainer from 'components/UI/DrawerContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'redux/reducers';
import PrintedDocument from 'components/Views/Customer/AnnualReport/PrintedDocument';
import { isMobile } from 'utils/deviceDetect';
import MobileSplash from 'components/UI/SplashScreen/MobileSplash';
import { isBrowserNotSupported } from 'utils/nonSupportedBrowserDetect';
import NonSuppBrowserSplash from 'components/UI/SplashScreen/NonSuppBrowserSplash';
import { getCurrentSession } from 'redux/actions';
import { LoadingPlaceholder } from 'components/UI';
import { css, Global } from '@emotion/core';
import { Login } from 'components/Views';

const cache = createIntlCache();
const intl = createIntl(
  {
    locale: 'sv',
    messages: flatten(messages),
    onError: err => {
      if (!err.includes('using default')) {
        console.error(err);
      }
    },
  },
  cache
);

/**
 * This wrapper exists so that not the whole app opbject is re-rendered when the
 * `useSelector` is trigered by a change in the Redux State.
 */
const ModalsWrapper = withRouter(({ location }) => {
  const { pathname: pathName } = location;
  const loggedIn = useSelector(state => state.user.loggedIn);
  const organisationID = useSelector(
    state => state.user['custom:organisationId']
  );
  const subscriptionStatus: Organisation.subscriptionStatus = useSelector(
    state => state.organisation.subscriptionStatus
  );

  const notPayed =
    subscriptionStatus !== 'active' &&
    subscriptionStatus !== 'trialing' &&
    subscriptionStatus !== 'cancel_at_period_end' &&
    subscriptionStatus !== undefined;

  return (
    <>
      {organisationID === undefined && loggedIn && <CreateOrganisation />}
      {notPayed && pathName !== '/organisation' && (
        <NotPayed subscriptionStatus={subscriptionStatus} />
      )}
    </>
  );
});

const Content = () => {
  const { loggedIn, isAuthenticating } = useSelector(state => state.user);
  const [graphQLClient, setGraphQLClient] = useState(null);

  useEffect(() => {
    if (!isAuthenticating) {
      setGraphQLClient(injectClient(createIdentityPoolClient()));
    }
  }, [isAuthenticating]);

  if (isMobile(navigator.userAgent)) {
    return <MobileSplash />;
  }

  if (isBrowserNotSupported(navigator.userAgent)) {
    return <NonSuppBrowserSplash />;
  }

  return (
    <Switch>
      <Route path="/login">
        {loggedIn && <Redirect to="/" />}
        {!loggedIn && <Login />}
      </Route>
      <Route>
        {isAuthenticating && <LoadingPlaceholder />}
        {!isAuthenticating && graphQLClient && (
          <ApolloProvider client={graphQLClient as any}>
            <AgoyAppClientProvider>
              <PrintContextProvider>
                <DndProvider backend={HTML5Backend}>
                  <AppHeader />
                  <ModalsWrapper />
                  <Router loggedIn={loggedIn} />
                </DndProvider>
              </PrintContextProvider>
            </AgoyAppClientProvider>
          </ApolloProvider>
        )}
        <Messages />
        <DrawerContainer />
        <div id="portals" />
      </Route>
    </Switch>
  );
};

const StandardApp = () => {
  const dispatch = useDispatch();

  // EFFECTS

  /**
   * Start the app by fetching the current session
   */
  useEffect(() => {
    dispatch(getCurrentSession());
  }, [dispatch]);

  return (
    <>
      <Global
        styles={css`
          html {
            height: 100vh;
            overflow: hidden;
          }

          body {
            height: 100vh;
            width: min-content;
            min-width: 100%;
          }

          #root {
            height: 100vh;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
          }
        `}
      />
      <MaterialThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Sentry.ErrorBoundary fallback={FallbackComponent}>
            <CssBaseline />
            <Content />
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </MaterialThemeProvider>
    </>
  );
};

const PrintApp = () => (
  <MaterialThemeProvider theme={printTheme}>
    <ThemeProvider theme={printTheme}>
      <Sentry.ErrorBoundary fallback={FallbackComponent}>
        <CssBaseline />
        <PrintedDocument />
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  </MaterialThemeProvider>
);

const App = () => {
  return (
    <GAProvider>
      <RawIntlProvider value={intl}>
        <BrowserRouter>
          <Switch>
            <Route path="/print">
              <PrintApp />
            </Route>
            <Route component={StandardApp} />
          </Switch>
        </BrowserRouter>
      </RawIntlProvider>
    </GAProvider>
  );
};

export default App;
