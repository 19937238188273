import React from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const classes = {
  buttonStyle: {
    color: 'white',
  },
};
const CloseIconButton = ({ stopPropagation = true, onClose }) => {
  return (
    <IconButton
      data-cy="modal-close-btn"
      css={classes.buttonStyle}
      onClick={event => {
        stopPropagation && event.stopPropagation();
        onClose();
      }}
    >
      <CloseIcon fontSize="large"></CloseIcon>
    </IconButton>
  );
};

export default CloseIconButton;
