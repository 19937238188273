import { AddAnnualReportColumnAction } from 'redux/actions/AnnualReportView/types';
import { RowUpdate } from 'types/AnnualReport/changes';
import {
  AnnualReportTable,
  AnnualReportTableRow,
} from 'types/AnnualReport/table';
import AnnualReportState from '../types';
import updateTable from './helpers/updateTable';
import { addItem, insertAt } from './helpers/util';

const addAnnualReportColumn = (
  state: AnnualReportState,
  action: AddAnnualReportColumnAction
) => {
  const {
    part: partKey,
    section: sectionKey,
    table: tableKey,
    column,
    cellType,
    index,
  } = action;

  const updateChange = (row: Partial<RowUpdate>): Partial<RowUpdate> => {
    let newRow = row;

    if (newRow.cells) {
      newRow = {
        ...newRow,
        cells: {
          ...newRow.cells,
          [column.id]:
            cellType === 'string'
              ? { type: 'string', value: '' }
              : { type: 'number', value: 0 },
        },
      };
    }

    return newRow;
  };

  const updateRow = (row: AnnualReportTableRow): AnnualReportTableRow => {
    let newRow = row;
    if (newRow.rows) {
      newRow = { ...newRow, rows: newRow.rows.map(updateRow) };
    }
    if (newRow.cells) {
      newRow = {
        ...newRow,
        cells: {
          ...newRow.cells,
          [column.id]:
            cellType === 'string'
              ? { type: 'string', value: '' }
              : { type: 'number', value: 0 },
        },
      };
    }
    if (newRow.newRowTemplate) {
      if (typeof newRow.newRowTemplate === 'object') {
        newRow = {
          ...newRow,
          newRowTemplate: updateRow(newRow.newRowTemplate),
        };
      } else if (typeof newRow.newRowTemplate === 'function') {
        console.error(
          'Adding columns to rows with a newRowTemplate function is not supported',
          newRow.id
        );
      }
    }
    return newRow;
  };

  return updateTable(state, partKey, sectionKey, tableKey, (table, change) => {
    let newTable: AnnualReportTable = {
      ...table,
      columns: insertAt(table.columns, column, index),
      rows: table.rows.map(updateRow),
    };

    if (newTable.newRowTemplate) {
      if (typeof newTable.newRowTemplate === 'object') {
        newTable = {
          ...newTable,
          newRowTemplate: updateRow(newTable.newRowTemplate),
        };
      } else if (typeof newTable.newRowTemplate === 'function') {
        console.error(
          'Adding columns to tables with a newRowTemplate function is not supported',
          tableKey
        );
      }
    }

    const newRows = change.rows?.map(row => {
      switch (row.type) {
        case 'delete':
          return row;
        case 'add': {
          if (row.row) {
            return {
              ...row,
              row: updateRow(row.row),
            };
          }
          return row;
        }
        case 'update': {
          if (row.row) {
            return {
              ...row,
              row: updateChange(row.row),
            };
          }
          return row;
        }
      }
    });

    return [
      newTable,
      {
        ...(newRows ? { ...change, rows: newRows } : change),
        columns: addItem(change.columns, {
          type: 'add',
          cellType,
          id: column.id,
          index,
          label: column.label,
        }),
      },
    ];
  });
};

export default addAnnualReportColumn;
