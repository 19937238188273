import React from 'react';
import { useIntl } from 'react-intl';
import Heading from '../../../UI/Heading';
import EditingIconButton from '../../../UI/EditingIconButton';
import ResetContentButton from '../../../UI/ResetContent/ResetContentButton';

type HeaderProps = {
  noteId: string;
  noteNumber: number | undefined;
  editing: boolean;
  print?: boolean;
  onEditToggle: (editing) => void;
};

const Header = ({
  noteId,
  noteNumber,
  editing,
  print = false,
  onEditToggle,
}: HeaderProps) => {
  const { formatMessage } = useIntl();

  const title = `${noteNumber} ${formatMessage({
    id: `annualReport.menu.notes.${noteId}`,
  })}`;

  return (
    <Heading
      title={title}
      controls={
        print ? (
          undefined
        ) : (
          <>
            <EditingIconButton
              value={editing}
              onClick={() => onEditToggle(editing)}
            />
            <ResetContentButton id={`notes.${noteId}`} what={title} />
          </>
        )
      }
    />
  );
};

export default Header;
