import React from 'react';
import { Modal, Fade, Backdrop } from '@material-ui/core';
import CloseTopBar from '../UI/CloseTopBar';
import styled from '@emotion/styled';

const Root = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  outline: none;
`;

interface ModalContentProps {
  fullHeight: boolean;
}

const ModalContent = styled.div<ModalContentProps>`
  border-radius: 0 0 8px 8px;
  margin: auto auto;
  width: min-content;
  ${props => (props.fullHeight ? 'max-height: 85vh;' : '')}
  overflow: auto;
  min-width: 1000px;
  background: white;

  display: flex;
`;

interface GenericModal {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  fullHeight?: boolean;
}
export const GenericModal = ({
  open,
  handleClose,
  children,
  fullHeight = true,
}: GenericModal) => {
  return (
    <Root
      aria-labelledby="image-modal"
      aria-describedby="image-modal"
      open={open}
      onBackdropClick={event => {
        event.stopPropagation();
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Container>
          <CloseTopBar onClose={handleClose} />
          <ModalContent
            onClick={event => event.stopPropagation()}
            fullHeight={fullHeight}
          >
            {children}
          </ModalContent>
        </Container>
      </Fade>
    </Root>
  );
};

export default GenericModal;
