import React, { useCallback } from 'react';
import {
  InputBase,
  TableCell,
  TableRow,
  Typography,
  WithTheme,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  AnnualReportTableColumn,
  AnnualReportTableRow,
} from 'types/AnnualReport/table';
import { ccyFormat } from 'utils';
import { updateAnnualReportCellValue } from 'redux/actions/AnnualReportView/actions';
import styled from '@emotion/styled';
import EditField from 'components/UI/Inputs/EditField';
import CurrencyField from 'components/UI/Inputs/CurrencyField';
import { DraggableItemTypes } from 'Constants';
import { useDrag } from 'react-dnd';
import { HierarchicalTableRowProps } from '../../UI/HierarchicalTable/HierarchicalTableRow';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {
  BALANCE_SHEET_ACCOUNT_ROW_PERIOD,
  BALANCE_SHEET_ACCOUNT_ROW_PREVIOUS_PERIOD,
  BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX,
} from 'utils/AnnualReport/constants';

export interface BalanceSheetAccountRowProps {
  className?: string;
  classes: Record<string, string>;
  baseId: string;
  row: AnnualReportTableRow;
  columns: AnnualReportTableColumn[];
  rowComponents: React.ComponentType<HierarchicalTableRowProps>[];
  level: number;
  editing?: boolean;
  print?: boolean;
}

const DragIndicatorLabelCell = styled(TableCell)`
  cursor: grab;
  &:hover::before {
    display: inline-block;
    position: absolute;
    content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'/></svg>");
    background-size: 28px 28px;
    height: 28px;
    width: 28px;
    left: 4px;
    margin-top: -2px;
  }
`;

const NumberCell = styled(TableCell)`
  font-family: Roboto;
`;

const DraggableNumberCell = styled(NumberCell)<{}>`
  cursor: grab;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &:hover > div > svg {
    visibility: visible;
  }

  & > div > svg {
    display: inline-block;
    visibility: hidden;
  }
`;

const MiscCell = styled(TableCell)<WithTheme>`
  &.MuiTableCell-body {
    padding: 0;
  }
  .MuiIconButton-root {
    padding: ${props => props.theme.spacing(0.5)}px;
  }
`;

const StringInput = styled(InputBase)<WithTheme>`
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  width: 100%;

  .MuiInputBase-input {
    text-align: inherit;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    margin: -${props => props.theme.spacing(0.5)}px;
    padding: ${props => props.theme.spacing(0.5)}px;
    height: auto;
    border: 1px solid transparent;
    :hover,
    :focus {
      border: 1px solid ${props => props.theme.palette.grey[300]};
    }
  }
`;

interface StringFieldProps {
  id: string;
  value: string;
}

const StringField = ({ id, value }: StringFieldProps) => {
  const dispatch = useDispatch();
  const onChange = useCallback(
    (value: string) => {
      dispatch(updateAnnualReportCellValue(id, value));
    },
    [dispatch, id]
  );

  return (
    <EditField value={value} onChange={onChange} component={StringInput} />
  );
};

interface NumberFieldProps {
  id: string;
  value: number | undefined;
}

const NumberField = ({ id, value }: NumberFieldProps) => {
  const dispatch = useDispatch();
  const onChange = useCallback(
    (value: number | undefined) => {
      dispatch(updateAnnualReportCellValue(id, value));
    },
    [dispatch, id]
  );

  return (
    <CurrencyField value={value} onValueChange={onChange} Input={StringInput} />
  );
};

const DraggableCell = ({ baseId, colId, cell, row, value }) => {
  const moveType =
    colId === BALANCE_SHEET_ACCOUNT_ROW_PERIOD
      ? 'ub'
      : colId === BALANCE_SHEET_ACCOUNT_ROW_PREVIOUS_PERIOD
      ? 'ib'
      : 'all';

  const [, drag] = useDrag({
    item: {
      type: DraggableItemTypes.BALANCE_SHEET_ACCOUNT_ROW_CELL,
      baseId,
      row,
      colId,
      cell,
      moveType,
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <DraggableNumberCell ref={drag}>
      <div>
        <DragIndicatorIcon />
        <span>{ccyFormat(value)}</span>
      </div>
    </DraggableNumberCell>
  );
};

const BalanceSheetAccountRow = React.forwardRef<
  HTMLTableRowElement,
  BalanceSheetAccountRowProps
>(({ className, row, columns, baseId, level, editing, print }, fwdRef) => {
  const { formatMessage } = useIntl();

  if (!row.cells) return null;

  return (
    <TableRow ref={fwdRef} key={row.id} className={className}>
      {columns.map(col => {
        const cell = row.cells?.[col.id];
        const key = `${row.id}.${col.id}`;
        const cellId = `${baseId}.${key}`;

        if (!cell) {
          return <TableCell key={key} />;
        }
        if (cell.type === 'label' && !print) {
          return editing &&
            !row.id.includes(BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX) ? (
            <DragIndicatorLabelCell key={key}>
              {cell.value}
            </DragIndicatorLabelCell>
          ) : (
            <TableCell key={key}>{cell.value}</TableCell>
          );
        }
        if (cell.type === 'number' && !print) {
          return (
            <NumberCell key={key} align="right">
              <NumberField id={cellId} value={cell.value} />
            </NumberCell>
          );
        }
        if (cell.type === 'refs') {
          return <TableCell>Not supported</TableCell>;
        }
        if (cell.type === 'ref') {
          const value = cell.value;
          if (value === undefined) {
            return <TableCell key={key} />;
          }
          if (typeof value === 'string') {
            return (
              <TableCell key={key}>
                <Typography color="error">
                  {formatMessage({
                    id: value,
                    defaultMessage: value,
                  })}
                </Typography>
              </TableCell>
            );
          }

          return editing && value !== 0 ? (
            <DraggableCell
              baseId={baseId}
              colId={col.id}
              cell={cell}
              row={row}
              key={key}
              value={value}
            />
          ) : (
            <NumberCell key={key} align="right">
              {ccyFormat(value)}
            </NumberCell>
          );
        }
        if (cell.type === 'msg') {
          return null;
        }
        if (cell.type === 'string' && !print) {
          return (
            <TableCell key={key}>
              <StringField id={cellId} value={cell.value} />
            </TableCell>
          );
        }
        if (print) {
          return (
            <TableCell key={key}>
              {cell.type === 'number' ? ccyFormat(cell.value) : cell.value}
            </TableCell>
          );
        }
        return null;
      })}
      <MiscCell></MiscCell>
    </TableRow>
  );
});

export default BalanceSheetAccountRow;
