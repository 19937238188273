export const BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX = '-part-';

/*
    period and previous period constants match again balanceSheet.config so
    if you update these make sure to update config as well
*/
export const BALANCE_SHEET_ACCOUNT_ROW_PERIOD = 'year';
export const BALANCE_SHEET_ACCOUNT_ROW_PREVIOUS_PERIOD = 'previousYear';

/*
    Certain notes are toggable through active prop on note
    Exception to this rule is the first note, Redovisningsprinciper
*/
export const NOTES_CONTROLLED_BY_ACTIVE_FLAG = [
  'note1',
  'note33',
  'note34',
  'note35',
  'note36',
  'note37',
  'note38',
  'note39',
];
