import {
  SET_CURRENT_YEAR,
  SET_CURRENT_CUSTOMER,
  SET_CURRENT_PERIOD,
} from '../../actionsTypes';
import { updateTaxView } from '../TaxView';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'redux/reducers';
import { Action } from 'redux';
import {
  SetCurrentCustomerAction,
  SetCurrentPeriodAction,
  SetCurrentYearAction,
} from './types';
import {
  loadAnnualReport,
  updateAnnualReportConfig,
} from '../AnnualReportView/actions';

export const setCurrentYear = (
  year: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(setCurrentYearAction(year));
  dispatch(loadAnnualReport());
};

const setCurrentYearAction = (financialYear: string): SetCurrentYearAction => {
  return {
    type: SET_CURRENT_YEAR,
    year: financialYear.substring(0, 4),
    financialYear,
  };
};

export const setCurrentCustomer = (
  customerId: string | undefined
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(setCurrentCustomerAction(customerId));
  dispatch(loadAnnualReport());
};

const setCurrentCustomerAction = (
  customerId: string | undefined
): SetCurrentCustomerAction => {
  return {
    type: SET_CURRENT_CUSTOMER,
    currentCustomer: customerId,
  };
};

export const setCurrentPeriod = (
  period: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(setCurrentPeriodAction(period));

  updateTaxView(dispatch, getState, false);
  updateAnnualReportConfig(dispatch, getState);
};

const setCurrentPeriodAction = (period: string): SetCurrentPeriodAction => ({
  type: SET_CURRENT_PERIOD,
  period,
});
