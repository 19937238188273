import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { AnnualReportTableRow } from 'types/AnnualReport/table';
import { StringCell } from 'types/AnnualReport/cell';
import styled from '@emotion/styled';

export const formatRoles = (roles): string => {
  if (!roles || roles === '') return '';
  return roles.split('|').join(', ');
};

const Grid = styled.div`
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 40% 40%;
  grid-template-rows: 100px;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
`;

const GridItem = styled.div`
  > p {
    margin-top: 2rem;
  }
`;

type AccountantTitlesPreviewProps = {
  rows: AnnualReportTableRow[];
};

type Accountant = {
  name: string;
  roles: string;
};

const AccountantTitlesPreview = ({ rows }: AccountantTitlesPreviewProps) => {
  const [accountants, setAccountants] = useState<Accountant[]>([]);

  useEffect(() => {
    if (!rows || rows.length === 0) return;
    const filteredAccountants = rows
      .filter(row => {
        return (row?.cells?.accountantRole as StringCell).value !== '';
      })
      .map(row => {
        return {
          name: `${(row?.cells?.givenName as StringCell).value} ${
            (row?.cells?.surName as StringCell).value
          }`,
          roles: (row?.cells?.accountantRole as StringCell).value,
        };
      });

    setAccountants(filteredAccountants);
  }, [rows]);

  return (
    <Grid>
      {accountants.map(accountant => (
        <GridItem key={accountant.roles}>
          <Typography>{accountant.name}</Typography>
          <Typography variant="subtitle1">
            {formatRoles(accountant.roles)}
          </Typography>
        </GridItem>
      ))}
    </Grid>
  );
};

export default AccountantTitlesPreview;
