import { UpdateAnnualReportColumnLabelAction } from 'redux/actions/AnnualReportView/types';
import {
  AnnualReportTable,
  isAnnualReportPartKey,
  TableChange,
} from 'types/AnnualReport/types';
import AnnualReportState from '../types';
import updateTable from './helpers/updateTable';

const updateAnnualReportColumnLabel = (
  state: AnnualReportState,
  action: UpdateAnnualReportColumnLabelAction
): AnnualReportState => {
  const { id, label } = action;
  if (!state.report) {
    return state;
  }
  const [part, section, table, columnId] = id.split('.');
  if (!isAnnualReportPartKey(part)) {
    return state;
  }

  return updateTable(
    state,
    part,
    section,
    table,
    (table: AnnualReportTable, change: TableChange) => {
      const column = table.columns.find(col => col.id === columnId);
      if (!column) {
        return [table, change];
      }

      const newTable = {
        ...table,
        columns: table.columns.map(col =>
          col.id === columnId ? { ...col, label } : col
        ),
      };

      if (change.columns) {
        const existingColumnChange = change.columns?.find(
          col => col.id === columnId
        );
        if (existingColumnChange) {
          return [
            newTable,
            {
              ...change,
              columns: change.columns.map(col =>
                col.id === columnId ? { ...col, label } : col
              ),
            },
          ];
        } else {
          return [
            newTable,
            {
              ...change,
              columns: [
                ...change.columns,
                { type: 'update', id: columnId, label },
              ],
            },
          ];
        }
      }
      return [
        newTable,
        { ...change, columns: [{ type: 'update', id: columnId, label }] },
      ];
    }
  );
};

export default updateAnnualReportColumnLabel;
