import React from 'react';
import styled from '@emotion/styled';
import moment, { Moment } from 'moment';
import { WithTheme } from '@material-ui/core';

const monthWidthPercent = 4;
const spaceAroundMonthsPercent = (100 - 18 * monthWidthPercent) / 19;

const Container = styled.div`
  display: flex;
  line-height: 1;
`;

const Line = styled.div<WithTheme>`
  border-bottom: 1px solid ${props => props.theme.palette.grey[400]};
  content: '';
  flex-grow: 1;
  margin-bottom: 6px;
  margin-top: 3px;
  height: 6px;
`;

const End = styled(Line)<WithTheme>`
  border-right: 1px solid ${props => props.theme.palette.grey[400]};
`;

const Start = styled(Line)<WithTheme>`
  border-left: 1px solid ${props => props.theme.palette.grey[400]};
`;

const Label = styled.div`
  white-space: nowrap;
  margin: 0 4px;
`;

interface FloatingLabel {
  first: boolean;
  last: boolean;
}

interface TimeSpanProps {
  months: number;
  startOpen?: boolean;
  endOpen?: boolean;
}

const FloatingLabel = styled.div<TimeSpanProps & FloatingLabel>`
  white-space: nowrap;
  position: absolute;
  ${(props: TimeSpanProps) => (props.months === 1 ? 'top: 12px' : 'top: 10px')};
  ${(props: FloatingLabel) =>
    props.first
      ? ''
      : props.last
      ? 'right: 0'
      : 'left: 50%; transform: translateX(-50%);'}
`;

const TimeSpan = styled.div`
  display: flex;
  overflow: visible;
  position: relative;
  line-height: 1;
  width: ${(props: TimeSpanProps) =>
    props.months * (spaceAroundMonthsPercent + monthWidthPercent) -
    (!props.startOpen && !props.endOpen ? spaceAroundMonthsPercent : 0) +
    (props.startOpen && props.endOpen ? spaceAroundMonthsPercent : 0)}%;
  margin-left: ${(props: TimeSpanProps) =>
    props.startOpen ? '0' : `${spaceAroundMonthsPercent}%`};
`;

interface PropTypes {
  className?: string;
  financialYears: string[];
}

interface FinancialYear {
  start: Moment;
  end: Moment;
}

const parseFinancialYear: (finYear: string) => FinancialYear = finYear => {
  const parts = finYear.split('-');
  return {
    start: moment(parts[0], 'YYYYMM').startOf('month'),
    end: moment(parts[1], 'YYYYMM').endOf('month'),
  };
};

const calcMonths: (
  finYear: FinancialYear,
  start: Moment,
  end: Moment
) => number = (finYear, start, end) => {
  const firstMonth = moment.max(finYear.start, start);
  const lastMonth = moment.min(finYear.end, end);
  const months = moment.duration(lastMonth.diff(firstMonth)).asMonths();
  return Math.round(months);
};

const Timespans = ({ financialYears, className }: PropTypes) => {
  if (!financialYears) {
    return null;
  }

  const start = moment()
    .subtract(11, 'months')
    .startOf('month');
  const end = moment()
    .add(6, 'months')
    .endOf('month');

  const visibleFinancialYears = financialYears
    .map(parseFinancialYear)
    .filter((value: FinancialYear) => value.end.isAfter(start))
    .filter((value: FinancialYear) => value.start.isBefore(end));

  if (visibleFinancialYears.length === 0) {
    return null;
  }

  const emptyMonths = Math.round(
    moment.duration(visibleFinancialYears[0].start.diff(start)).asMonths()
  );

  return (
    <Container className={className}>
      {emptyMonths > 0 && <TimeSpan months={emptyMonths} />}
      {visibleFinancialYears.map(
        (finYear: FinancialYear, index: number, arr: FinancialYear[]) => {
          const startOpen = start.isAfter(finYear.start);
          const endOpen = finYear.end.isAfter(end);
          const months = calcMonths(finYear, start, end);

          const label = `${finYear.start.format(
            'YYYY-MM-DD'
          )} – ${finYear.end.format('YYYY-MM-DD')}`;
          return (
            <TimeSpan
              key={index}
              months={months}
              startOpen={startOpen}
              endOpen={endOpen}
            >
              {startOpen ? <Line /> : <Start />}
              {months <= 2 && (
                <FloatingLabel
                  months={months}
                  first={index === 0 && emptyMonths <= 0}
                  last={index === arr.length - 1}
                >
                  {months === 1 ? finYear.end.format('YYYY-MM') : label}
                </FloatingLabel>
              )}
              {months > 2 && <Label>{label}</Label>}
              {endOpen ? <Line /> : <End />}
            </TimeSpan>
          );
        }
      )}
    </Container>
  );
};

export default Timespans;
