import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import { useSelector } from 'redux/reducers';
import { setOrganisation, userLogOut } from 'redux/actions';
import Customer from 'components/Views/Customer';
import { Login, CustomersView, Organisation } from 'components/Views';
import NotFound from 'components/Views/Error/NotFound';
import Profile from 'components/Views/Profile';
import Version from 'components/Views/Version';

import ScrollTo from './ScrollTo';
import { withGA } from 'Analytics';
import { useLazyQuery } from 'react-apollo';
import { GET_USER_ORGANISATION } from 'Graphql/Queries';

interface RouterProps {
  loggedIn: boolean;
}

const Router = ({ loggedIn }: RouterProps) => {
  const dispatch = useDispatch();
  const isAuthenticating = useSelector(state => state.user.isAuthenticating);
  const [getUserOrg] = useLazyQuery(GET_USER_ORGANISATION, {
    onCompleted: data => {
      dispatch(setOrganisation(data.getUserOrganisation));
    },
  });

  useEffect(() => {
    if (loggedIn) {
      getUserOrg();
    }
  }, [loggedIn, getUserOrg]);

  if (loggedIn) {
    return (
      <ScrollTo>
        <Switch>
          <Route exact path="/organisation" component={Organisation} />
          <Redirect exact from="/customer/:id" to="/customer/:id/details" />
          <Route path="/customer/:customerId" component={Customer} />
          <Route exact path="/" component={CustomersView} />
          <Route
            path="/logout"
            render={() => {
              dispatch(userLogOut());
              return <Redirect to={{ pathname: '/login' }} />;
            }}
          />
          <Route exact path="/profile" component={Profile} />;
          <Route exact path="/version" component={Version} />;
          <Redirect from="/login" to="/" />
          <Route component={NotFound} />
        </Switch>
      </ScrollTo>
    );
  } else {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/version" component={Version} />;
        {!isAuthenticating && <Redirect to="/login" />}
      </Switch>
    );
  }
};

export default withGA(Router);
