import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField as MuiTextField,
  TextFieldProps,
} from '@material-ui/core';
import EditField from 'components/UI/Inputs/EditField';
import styled from '@emotion/styled';

export const LABELS = {
  ACCOUNTANT: 'Revisor',
  ACCOUNTANT_ALTERNATE: 'Revisorssuppleant',
  AUTHORIZED: 'Auktoriserad',
  APPROVED: 'Godkänd',
  MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM: 'Huvudrevisor är revisionsbolaget',
};

const TextField = styled(
  ({
    disableUnderline,
    ...rest
  }: TextFieldProps & { disableUnderline: boolean }) => (
    <MuiTextField variant="outlined" size="small" {...rest} />
  )
)`
  margin-left: 2rem;
`;

type AccountantTitleOption = {
  id: string;
  checked: boolean;
  label: string;
  freeText?: string;
};

type AccountantTitleSelectionProps = {
  titles: string;
  onChange: (changes: string) => void;
};

const AccountantTitleSelection = ({
  titles,
  onChange,
}: AccountantTitleSelectionProps) => {
  const [options, setOptions] = useState<AccountantTitleOption[]>([
    { id: 'option1', checked: false, label: LABELS.ACCOUNTANT },
    { id: 'option2', checked: false, label: LABELS.ACCOUNTANT_ALTERNATE },
    { id: 'option3', checked: false, label: LABELS.AUTHORIZED },
    { id: 'option4', checked: false, label: LABELS.APPROVED },
    {
      id: 'option5',
      checked: false,
      label: LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM,
      freeText: '',
    },
  ]);

  useEffect(() => {
    if (titles === '') return;

    let freeTextMainAccountantIsAccountingFirm;

    const roleLabels = titles.split('|');
    const option5 = roleLabels.find(value =>
      value.includes(LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM)
    );

    if (option5 && option5 !== '') {
      freeTextMainAccountantIsAccountingFirm = option5
        .split(LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM)[1]
        .trim();
    }

    setOptions(prevOptions => {
      return prevOptions.map(option => {
        if (
          option.label === LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM &&
          freeTextMainAccountantIsAccountingFirm
        ) {
          return {
            ...option,
            checked: true,
            freeText: freeTextMainAccountantIsAccountingFirm,
          };
        }
        return roleLabels.includes(option.label)
          ? { ...option, checked: true }
          : option;
      });
    });
  }, [titles]);

  const collectChanges = (updatedOptions = options) => {
    return updatedOptions
      .filter(item => item.checked)
      .map(item => {
        if (item.label === LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM) {
          return `${item.label} ${item.freeText}`;
        }
        return item.label;
      })
      .join('|');
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = options.find(option => option.id === event.target.name);
    if (!option) return;

    const updatedOptions = options.map(existingOption =>
      existingOption.id === option.id
        ? { ...existingOption, checked: event.target.checked }
        : existingOption
    );
    setOptions(updatedOptions);

    const changes = collectChanges(updatedOptions);
    onChange(changes);
  };

  const onTextfieldChange = value => {
    const option = options.find(
      option => option.label === LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM
    );
    if (option) {
      const updatedOptions = options.map(existingOption =>
        existingOption.id === option.id
          ? { ...existingOption, freeText: value ? value : '' }
          : existingOption
      );
      setOptions(updatedOptions);

      const changes = collectChanges(updatedOptions);
      onChange(changes);
    }
  };

  return (
    <>
      <FormControl component="fieldset">
        <FormGroup>
          {options.map(option => (
            <div key={option.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={option.checked}
                    onChange={handleCheckboxChange}
                    name={option.id}
                  />
                }
                label={option.label}
              />
              {option.label === LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM && (
                <EditField
                  value={option.freeText as string}
                  component={TextField}
                  onChange={onTextfieldChange}
                />
              )}
            </div>
          ))}
        </FormGroup>
      </FormControl>
    </>
  );
};

export default AccountantTitleSelection;
