import { AnnualGeneralMeeting } from 'types/AnnualReport/parts/annualGeneralMeeting';
import { id } from 'utils/References/helpers';
import { table, field, value, ref, msg } from './util';

export const annualGeneralMeetingConfig = (): AnnualGeneralMeeting => ({
  type: 'part',
  main: {
    location: field(''),
    date: field(''),
    votingList: table(
      'annualGeneralMeeting.main.votingList',
      'name',
      'shares',
      'votes',
      'role'
    )
      .newRowTemplate(value('$name'), value(0), value(0), value(''))
      .build(),
    result: ref(id('managementReport.resultsDisposition.total')),
    resultsDisposition: table(
      'annualGeneralMeeting.main.resultsDisposition',
      'label',
      'value'
    )
      .addRows(rows =>
        rows
          .addRow(
            'dividend',
            msg('annualReport.annualGeneralMeeting.main.8.dividend', {
              shareDividend: id(
                'managementReport.profitProposal.dividendPerShare'
              ),
            }),
            ref(id('managementReport.resultsDisposition.selectedDividend'))
          )
          .newRowTemplate(value(''), value(0))
          .addRow(
            'result',
            value('I ny räkning överföres'),
            ref(id('managementReport.resultsDisposition.toTransfer'))
          )
          .build()
      )
      .build(),
    fee: field('no'),
    boardMembers: table('annualGeneralMeeting.main.boardMembers', 'name')
      .newRowTemplate(value('$name'))
      .build(),
    auditors: table('annualGeneralMeeting.main.auditors', 'name')
      .newRowTemplate(value('$name'))
      .build(),
    noAuditor: field(''),
    deputyAuditors: table('annualGeneralMeeting.main.deputyAuditors', 'name')
      .newRowTemplate(value('$name'))
      .build(),
    customParagraphs: table(
      'annualGeneralMeeting.main.customParagraphs',
      'heading',
      'text'
    )
      .newRowTemplate(value('Rubrik'), value('text...'))
      .build(),
  },
});
