import { Dispatch, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ExecutionResult,
  MutationFunctionOptions,
  useMutation,
} from 'react-apollo';
import {
  setSieData,
  sieFileJustUpdated,
  setCustomerLampStatus,
} from 'redux/actions';
import mapSieInformationToTableData, {
  AccountInformation,
  PeriodsObject,
} from 'utils/SieParser';
import { ADD_LAMP_STATUS, ADD_SIE_FILE } from 'Graphql/Mutations';
import { GET_SIE_DATA } from 'Graphql/Queries';
import { accountGrouper } from 'utils';
import { readBuffer } from 'utils/sie-reader';
import { useUploadSieFile } from 'components/Api/Client/sie';
import { getBuffer } from './FileReader/file-reader-util';
import { isMatchingOrgNumbers } from './Client/client-util';

type UseLoadSieFileFnImp = {
  deps: {
    reader: FileReader | any;
    setLoading: (l: boolean) => void;
    setError: (e: Error.GenericError | undefined) => void;
    setData: (d: SieFile.SieFile | undefined) => void;
    setSieData: (sieData: any) => void;
    getBuffer: (r: FileReader) => Buffer;
    readBuffer: (Buffer) => SieFile.SieFile;
    uploadSieFile: (
      clientId: string,
      year: string,
      file: ArrayBuffer
    ) => Promise<void>;
    addSieFile: (
      options?: MutationFunctionOptions<any, Record<string, any>> | undefined
    ) => Promise<ExecutionResult<any>>;
    addLampStatus: (args) => void;
    sieFileJustUpdated: () => void;
    mapSieInformationToTableData: (
      sieObj: SieFile.SieFile
    ) => {
      aggregated: AccountInformation[];
      periods: PeriodsObject;
      financialYear: string;
      orgNr: string | undefined;
      transactions: Transaction[];
    };
    isMatchingOrgNumbers: (firstOrgNr: string, secondOrgNr: string) => boolean;
    dispatch: Dispatch<any>;
  };
  customer: Customer.CustomerType;
};

/**
 * Loop trough the periods of the siefile and generate the lamps. If they differ from
 * the default lamps (all lamps are gray), write the new status to the state and send to
 * the server
 */
export const updateLampStatuses = ({
  periods,
  customerId,
  aggregated,
  grouper,
  setCustomerLampStatusImpl,
  addLampStatusImpl,
  dispatchImpl,
}: {
  periods: string[];
  customerId: string;
  aggregated: AccountInformation[];
  grouper: (a: any, b: any, c: any) => any;
  setCustomerLampStatusImpl: (
    mapStatuses: any,
    period: any,
    customerId: any
  ) => void;
  addLampStatusImpl: (args: any) => void;
  dispatchImpl: (args: any) => void;
}) => {
  periods.forEach(period => {
    const groupedForLamps = grouper(period, { sieBlob: aggregated }, {});

    if (Object.values(groupedForLamps).some(status => status !== 'unvisited')) {
      dispatchImpl(
        setCustomerLampStatusImpl(groupedForLamps, period, customerId)
      );

      addLampStatusImpl({
        variables: {
          customerId: customerId,
          statuses: groupedForLamps,
          period,
        },
      });
    }
  });
};

export const loadSieFileFnImp = async ({
  deps: {
    reader,
    setLoading,
    setError,
    setData,
    setSieData,
    getBuffer,
    readBuffer,
    uploadSieFile,
    addSieFile,
    addLampStatus,
    sieFileJustUpdated,
    mapSieInformationToTableData,
    isMatchingOrgNumbers,
    dispatch,
  },
  customer,
}: UseLoadSieFileFnImp) => {
  const customerId = customer.id;

  setLoading(true);
  try {
    const ab = getBuffer(reader);
    const sieObj = readBuffer(ab);

    const {
      aggregated,
      periods: periodsFromSie,
      orgNr,
      transactions,
    } = mapSieInformationToTableData(sieObj);

    if (orgNr && customer.orgNumber) {
      if (!isMatchingOrgNumbers(orgNr, customer.orgNumber)) {
        const e = new Error(
          `The Sie-file's OrgNr does not match the customer's`
        );
        e.name = 'WrongOrgNr';
        setError(e);
        setLoading(false);
        return;
      }
    }
    const variables = {
      period: periodsFromSie.periods[0].substring(0, 4),
      periods: periodsFromSie.periods.map(val => val.substring(0, 6)),
      customerId,
      sieBlob: aggregated,
    };

    await uploadSieFile(
      customerId,
      variables.period,
      reader.result as ArrayBuffer
    );

    await addSieFile({
      variables,
      refetchQueries: [
        {
          query: GET_SIE_DATA,
          variables: { customerId, period: variables.period },
        },
      ],
    });

    setData(sieObj);

    dispatch(
      setSieData({
        ...variables,
        transactions,
        sieUpdated: new Date().toISOString(),
      })
    );

    updateLampStatuses({
      periods: variables.periods,
      customerId: customer.id,
      aggregated,
      grouper: accountGrouper,
      setCustomerLampStatusImpl: setCustomerLampStatus,
      addLampStatusImpl: addLampStatus,
      dispatchImpl: dispatch,
    });

    setLoading(false);

    dispatch(sieFileJustUpdated());
  } catch (error) {
    setLoading(false);
    setError(error);
  }
};

type UseLoadSieFileAttributes = {
  loading: boolean;
  data?: SieFile.SieFile;
  error?: Error.GenericError;
};

type UseLoadSieFile = (
  customer?: Customer.CustomerType
) => [(reader: FileReader) => void, UseLoadSieFileAttributes];

const useLoadSieFile: UseLoadSieFile = customer => {
  const dispatch = useDispatch();
  const [addSieFile] = useMutation(ADD_SIE_FILE);
  const [addLampStatus] = useMutation(ADD_LAMP_STATUS);
  const [uploadSieFile] = useUploadSieFile();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error.GenericError>();
  const [data, setData] = useState<SieFile.SieFile>();

  if (customer === undefined) {
    return [() => {}, { loading: false }];
  }

  const useLoadSieFileFn = async (reader: FileReader) => {
    return loadSieFileFnImp({
      deps: {
        reader,
        setLoading,
        setError,
        setData,
        setSieData,
        getBuffer,
        readBuffer,
        uploadSieFile,
        addSieFile,
        addLampStatus,
        sieFileJustUpdated,
        mapSieInformationToTableData,
        isMatchingOrgNumbers,
        dispatch,
      },
      customer,
    });
  };

  return [useLoadSieFileFn, { error, loading, data }];
};

export default useLoadSieFile;
