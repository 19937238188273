import React from 'react';
import { useSelector } from 'redux/reducers';
import styled from '@emotion/styled';
import { Box, Typography, WithTheme } from '@material-ui/core';
import { ConfirmationCertificate } from 'types/AnnualReport/parts/confirmationCertificate';
import { useIntl } from 'react-intl';
import { stringValue } from 'utils/AnnualReport/cell';

const Content = styled.div<WithTheme>`
  padding: ${props => props.theme.spacing(4)}px;
`;

const ConfirmationCertificatePreview = () => {
  const { formatMessage } = useIntl();
  const confirmationCert: ConfirmationCertificate | undefined = useSelector(
    state => state.annualReport.report?.confirmationCertificate
  );
  const client = useSelector(state => {
    if (state.customerView.currentCustomer) {
      return state.customers[state.customerView.currentCustomer];
    }
  });
  const currentFinancialYear = useSelector(
    state => state.customerView.currentFinancialYear
  );

  if (!confirmationCert) {
    return null;
  }

  const { section } = confirmationCert;
  return (
    <Content>
      <Typography variant="h1">
        {formatMessage({ id: 'annualReport.menu.confirmationCertificate' })}
      </Typography>

      <Typography>{`${client?.name} (${client?.orgNumber})`}</Typography>
      <Typography>
        {formatMessage({ id: 'financialyear' })} {currentFinancialYear}
      </Typography>

      {/* Statement */}
      <Box mt="4rem" mb="8rem" whiteSpace="break-spaces">
        <Typography>{`${stringValue(section.statement)}`}</Typography>
      </Box>

      {/* Location & Date */}
      <Box mb="4rem">
        <Typography>{`${stringValue(section.location)}, ${stringValue(
          section.date
        )}`}</Typography>
      </Box>

      <Box mb="2rem">
        <Typography>{stringValue(section.signatureClarification)}</Typography>
      </Box>
    </Content>
  );
};

export default ConfirmationCertificatePreview;
