import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { userLogOut } from 'redux/actions';
import { useDispatch } from 'react-redux';

const NotPayedUser = ({ subscriptionStatus }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          {intl.formatMessage({ id: 'subscription_expired.user' })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          type="submit"
          onClick={() => {
            dispatch(userLogOut());
          }}
        >
          {intl.formatMessage({ id: 'app.logout' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotPayedUser;
