import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/react-hooks';
import pick from 'lodash-es/pick';
import get from 'lodash-es/get';

import { useSelector } from 'redux/reducers';
import {
  setCustomerLogo,
  updateCustomer as updateCustomerAction,
} from 'redux/actions';
import { UPDATE_CUSTOMER, PUT_CUSTOMER_LOGO } from 'Graphql/Mutations';
import {
  SimplifiedSelect,
  SimplifiedTextfield,
  SaveChanges,
} from 'components/UI';
import GenericModal from 'components/Modals/GenericModal';
import ActivateFortnoxIntegration from 'components/Modals/ActivateFortnoxIntegration';
import ClientSectionWrapper from '../ClientSectionWrapper';
import CustomerInfoHead from './CustomerInfoHead';
import {
  fullCostomerInformation,
  clientInformationAttributes,
  contactInformationAttributes,
  ORG_NUMBER,
  VAT_PERIOD,
  COMPANY_TYPE,
  CLOSING_PERIOD,
  CLOSING_MONTH,
  CLIENT_MANAGER,
  COMPANY_NAME,
  COMPANY_ADDRESS,
  EMAIL,
  PHONE_NUMBER,
  CONTACT_PERSON,
} from '../Constants';
import {
  getClosingMonthOptions,
  getClosingPeriodOptions,
  getCompanyTypeOptions,
  getVatPeriodOptions,
} from '../options';
import CustomerContainer from '../CustomerContainer';
import { isValidOrgNumber } from 'utils/Client/client-util';
import { fetchLogo } from 'utils/GraphqlParser';

const FullHeightCustomerContainer = styled(CustomerContainer)`
  height: 100%;
`;

const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

interface CustomerModalProps {
  open: boolean;
  customer: Customer.CustomerType;
  handleClose: () => void;
  handleDocumentsOpen?: () => void;
}

const CustomerInformation = ({
  handleClose,
  handleDocumentsOpen,
  open,
  customer,
}: CustomerModalProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  let orgMembers = useSelector(state => state.organisation.members);

  const [newCustomerInformation, setNewCustomerInformation] = useState({
    ...fullCostomerInformation,
    ...customer,
  });

  const [edit, setEdit] = useState(false);
  const requiredFields = [
    COMPANY_NAME,
    CLOSING_MONTH,
    VAT_PERIOD,
    CLOSING_PERIOD,
    COMPANY_TYPE,
    ORG_NUMBER,
  ];

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: data => {
      dispatch(updateCustomerAction(newCustomerInformation));
      closeEdit();
    },
  });
  const [putCustomerLogo] = useMutation(PUT_CUSTOMER_LOGO);

  const handleUpdate = (key, value) => {
    const newState = { ...newCustomerInformation, [key]: value };
    setNewCustomerInformation(newState);
  };
  const openEdit = () => {
    setEdit(true);
  };

  const handleOpenDocuments = () => {
    if (handleDocumentsOpen) handleDocumentsOpen();
  };

  const [showFortnoxIntegration, setShowFortnoxIntegration] = useState(false);
  const handleOpenFortnoxIntegration = () => {
    setShowFortnoxIntegration(true);
  };
  const closeFortnoxIntegration = useCallback(() => {
    setShowFortnoxIntegration(false);
  }, []);

  const closeEdit = () => {
    setEdit(false);
  };
  const saveChanges = () => {
    const requiredFieldsNotEmpty = Object.values(
      pick(newCustomerInformation, requiredFields)
    ).every(value => value);

    if (requiredFieldsNotEmpty) {
      const additionalFields = pick(newCustomerInformation, [
        COMPANY_ADDRESS,
        ORG_NUMBER,
        PHONE_NUMBER,
        EMAIL,
        CONTACT_PERSON,
      ]);
      const cleanedAdditionalFields = Object.entries(additionalFields).reduce(
        (tot, [key, value]) => {
          return value !== '' && value !== null
            ? { ...tot, [key]: value }
            : tot;
        },
        {}
      );

      const orgNumberValue = cleanedAdditionalFields?.[ORG_NUMBER];

      if (orgNumberValue && !isValidOrgNumber(orgNumberValue)) {
        return;
      }

      updateCustomer({
        variables: {
          customerId: newCustomerInformation.sortKey,
          name: newCustomerInformation.name,
          type: newCustomerInformation.type,
          closingMonth: newCustomerInformation.closingMonth,
          closingPeriod: newCustomerInformation.closingPeriod,
          manager: newCustomerInformation.manager,
          vatPeriod: newCustomerInformation.vatPeriod,
          ...cleanedAdditionalFields,
        },
      });
    }
  };
  const discardChanges = () => {
    setNewCustomerInformation(customer);
    closeEdit();
  };

  const closingMonthOptions = getClosingMonthOptions(intl);
  const closingPeriodOptions = getClosingPeriodOptions(intl);
  const vatPeriodOptions = getVatPeriodOptions(intl);
  const companyTypeOptions = getCompanyTypeOptions(intl);

  const optionsConfig = {
    closingPeriod: {
      options: closingPeriodOptions,
      label: intl.formatMessage({ id: `client.${CLOSING_PERIOD}` }),
    },
    vatPeriod: {
      options: vatPeriodOptions,
      label: intl.formatMessage({ id: `client.${VAT_PERIOD}` }),
    },
    type: {
      options: companyTypeOptions,
      label: intl.formatMessage({ id: `client.${COMPANY_TYPE}` }),
    },
    closingMonth: {
      options: closingMonthOptions,
      label: intl.formatMessage({
        id: `client.${CLOSING_MONTH}`,
      }),
    },
    manager: {
      options: orgMembers.map(member => ({ value: member, label: member })),
      label: intl.formatMessage({ id: `client.${CLIENT_MANAGER}` }),
    },
  };

  const handleLogoSelect = async files => {
    const file = files[0];

    if (file) {
      const contentType = file.type;

      if (!file.type.startsWith('image/')) {
        window.alert(intl.formatMessage({ id: 'error.imageonly' }));
        return;
      }

      const { data } = await putCustomerLogo({
        variables: {
          customerId: customer.id,
          contentType,
        },
      });
      if (data) {
        const { url, uploadUrl } = data.putCustomerLogo;
        await fetch(uploadUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        });

        // fetch the logo from S3 and transform into base64
        const { logo = '' } = await fetchLogo({ ...customer, logo: url });

        dispatch(setCustomerLogo(customer.id, logo));
        setNewCustomerInformation(s => ({ ...s, logo }));
      }
    }
  };

  return (
    <>
      <GenericModal
        open={open}
        handleClose={handleClose}
        id="onboarding-modal-create-customer"
      >
        <FullHeightCustomerContainer>
          <FullHeightGrid container direction="column" justify="space-between">
            <Grid item key="customer-information-section">
              <CustomerInfoHead
                edit={edit}
                logo={newCustomerInformation.logo}
                customerName={newCustomerInformation.name}
                openFortnoxIntegration={handleOpenFortnoxIntegration}
                openDocuments={handleOpenDocuments}
                openEdit={openEdit}
                handleCustomerNameChange={event => {
                  handleUpdate('name', event.target.value);
                }}
                handleLogoSelect={handleLogoSelect}
              />
              <ClientInformationSection
                requiredFieldsCustom={requiredFields}
                edit={edit}
                label={intl.formatMessage({ id: 'client.information' })}
                intl={intl}
                handleUpdate={handleUpdate}
                optionsConfig={optionsConfig}
                content={pick(
                  newCustomerInformation,
                  clientInformationAttributes
                )}
              />
              <ClientInformationSection
                requiredFieldsCustom={requiredFields}
                edit={edit}
                label={intl.formatMessage({ id: 'client.contact.information' })}
                intl={intl}
                handleUpdate={handleUpdate}
                optionsConfig={optionsConfig}
                content={pick(
                  newCustomerInformation,
                  contactInformationAttributes
                )}
              />
            </Grid>
            {edit && (
              <Grid item key="customer-edit-section">
                <SaveChanges
                  saveLabel={intl.formatMessage({ id: 'save.changes' })}
                  discardLabel={intl.formatMessage({ id: 'discard.changes' })}
                  saveChanges={saveChanges}
                  discardChanges={discardChanges}
                />
              </Grid>
            )}
          </FullHeightGrid>
        </FullHeightCustomerContainer>
      </GenericModal>
      {showFortnoxIntegration && (
        <ActivateFortnoxIntegration
          customerId={customer.id}
          close={closeFortnoxIntegration}
        />
      )}
    </>
  );
};

export default CustomerInformation;

const ClientInformationSection = ({
  requiredFieldsCustom,
  label,
  edit,
  intl,
  content,
  handleUpdate,
  optionsConfig,
}) => (
  <ClientSectionWrapper label={label} fullWidth={true}>
    <Grid container direction="column">
      {Object.keys(content).map(key => {
        return Content({
          requiredFieldsCustom: requiredFieldsCustom,
          key: key,
          value: content[key],
          handleUpdate: event => handleUpdate(key, event.target.value),
          options: get(optionsConfig[key], 'options', []),
          edit: edit,
          placeholderConfig: {
            value: intl.formatMessage({ id: 'missing' }),
            style: theme => ({ color: theme.palette.text.secondary }),
          },
          label:
            optionsConfig[key]?.label ||
            intl.formatMessage({ id: `client.${key.toLowerCase()}` }),
          orgNumberErrorText:
            key === 'orgNumber' &&
            !isValidOrgNumber(content[key]) &&
            intl.formatMessage({ id: 'orgNr.error' }),
        });
      })}
    </Grid>
  </ClientSectionWrapper>
);

interface ContentProps {
  value?: string | undefined;
  key: string;
  label: string;
  handleUpdate: (event: any) => void;
  options: ListItem[];
  menuFieldsCustom?: string[];
  requiredFieldsCustom?: string[];
  edit: boolean;
  placeholderConfig: PlaceholderConfig;
  orgNumberErrorText?: string;
}

const Content = ({
  key,
  value = '',
  label,
  handleUpdate,
  options,
  menuFieldsCustom,
  requiredFieldsCustom,
  edit,
  placeholderConfig,
  orgNumberErrorText,
}: ContentProps) => {
  const menuFields = menuFieldsCustom
    ? menuFieldsCustom
    : [VAT_PERIOD, COMPANY_TYPE, CLOSING_MONTH, CLOSING_PERIOD, CLIENT_MANAGER];
  const requiredFields = requiredFieldsCustom
    ? requiredFieldsCustom
    : [COMPANY_NAME, CLOSING_MONTH, VAT_PERIOD, CLOSING_PERIOD, COMPANY_TYPE];

  return menuFields.includes(key) ? (
    <Grid key={key} item>
      <SimplifiedSelect
        width={600}
        key={key}
        dataCy={`customer-select-${key}`}
        editing={edit}
        value={value}
        required={requiredFields.includes(key)}
        label={label}
        listItems={options ? options : []}
        onChange={handleUpdate}
      />
    </Grid>
  ) : (
    <Grid item key={key}>
      <SimplifiedTextfield
        width={600}
        key={key}
        dataCy={`customer-textfield-${key}`}
        editing={edit}
        value={value}
        label={label}
        required={requiredFields.includes(key)}
        onChange={handleUpdate}
        error={orgNumberErrorText ? true : false}
        helperText={orgNumberErrorText}
      />
    </Grid>
  );
};
