import React from 'react';

import { useSelector } from 'redux/reducers';
import CashFlowStatementTable from './CashFlowStatementTable';
import PreviewSection from '../../UI/PreviewSection';
import styled from '@emotion/styled';
import { WithTheme } from '@material-ui/core';

const PrintCashFlowStatementTable = styled(CashFlowStatementTable)<WithTheme>`
  .MuiTableCell-head :first-of-type {
    font-size: ${props => props.theme.typography.h1.fontSize};
    color: ${props => props.theme.palette.primary.main};
  }
`;

const CashFlowStatementPreview = () => {
  const cashFlowStatement = useSelector(
    state => state.annualReport.report?.cashFlowStatement
  );

  if (!cashFlowStatement) {
    return null;
  }
  return (
    <PreviewSection id="cashFlowStatement">
      <PrintCashFlowStatementTable
        table={cashFlowStatement.section.main}
        tableId="cashFlowStatement.section.main"
        print={true}
      />
    </PreviewSection>
  );
};

export default CashFlowStatementPreview;
