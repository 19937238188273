import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  Menu,
  MenuItem,
  IconButton,
  WithTheme,
  Typography,
  Avatar,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { useSelector } from 'redux/reducers';
import { userLogOut } from 'redux/actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const ProfileWrapper = styled(IconButton)<WithTheme>`
  padding: 0;
  .MuiIconButton-label {
    margin-left: ${props => props.theme.spacing(1)}px;
  }
`;

const StyledAvatar = styled(Avatar)<WithTheme>`
  width: ${props => props.theme.spacing(4)}px;
  height: ${props => props.theme.spacing(4)}px;
  margin-right: ${props => props.theme.spacing(1)}px;
`;

const UserMenu = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(state => state.user.email);
  const picture = useSelector(state => state.user.picture);
  const givenName = useSelector(state => state.user.given_name);
  const familyName = useSelector(state => state.user.family_name);
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setExpanded(true);
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setExpanded(false);
    setAnchorEl(null);
  };

  return (
    <>
      {userEmail && (
        <>
          <ProfileWrapper data-cy="user-menu-btn" onClick={handleClick}>
            <StyledAvatar variant="circle" src={picture} />
            <Typography variant="h3">
              {givenName && familyName
                ? `${givenName} ${familyName}`
                : userEmail}
            </Typography>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ProfileWrapper>
        </>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        onClose={closeMenu}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem component={Link} to="/organisation" onClick={closeMenu}>
          {intl.formatMessage({ id: 'organisation' })}
        </MenuItem>

        <MenuItem component={Link} to="/profile" onClick={closeMenu}>
          {intl.formatMessage({ id: 'profile' })}
        </MenuItem>

        <MenuItem
          data-cy="logout-menu-item"
          onClick={() => {
            closeMenu();
            dispatch(userLogOut());
            return <Redirect to={{ pathname: '/' }} />;
          }}
        >
          {intl.formatMessage({ id: 'logout' })}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
