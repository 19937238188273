import {
  ADD_CUSTOMER,
  UPDATE_CUSTOMER,
  POPULATE_CUSTOMERS,
  DELETE_CUSTOMER,
  SET_CUSTOMER_LOGO,
  SET_INTEGRATION_STATUS,
  SET_FINANCIAL_YEARS,
} from '../../actionsTypes';

export const addCustomer = (customer: Customer.CustomerType) => ({
  type: ADD_CUSTOMER,
  customer,
});

export const updateCustomer = (customer: Customer.CustomerType) => ({
  type: UPDATE_CUSTOMER,
  customer,
});

export const setCustomerLogo = (id: string, logo: string) => ({
  type: SET_CUSTOMER_LOGO,
  id,
  logo,
});

export const deleteCustomer = ({ id }: { id: string }) => ({
  type: DELETE_CUSTOMER,
  id: id,
});

export const populateCustomers = ({
  customers,
}: {
  customers: Customer.CustomerType[];
}) => ({
  type: POPULATE_CUSTOMERS,
  customers,
});

export const setIntegrationStatus = (
  id: string,
  integration: Customer.IntegrationType,
  status: Customer.IntegrationStatus | null
) => ({
  type: SET_INTEGRATION_STATUS,
  id,
  integration,
  status,
});

export const setFinancialYears = (id: string, financialYears: string[]) => ({
  type: SET_FINANCIAL_YEARS,
  id,
  financialYears,
});
