import {
  TaxCalculationContext,
  TaxView,
  TransactionRow,
  TaxTableConfig,
  TaxTable,
} from './types';
import { calculateTaxTable } from './calculateTaxTable';

const createFromContext = (
  config: TaxTableConfig<TransactionRow>
): TaxTable<TransactionRow> => ({ ...config });

export const calculateAdjustments = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): TaxTable<TransactionRow> => {
  return calculateTaxTable(
    taxView?.adjustments || createFromContext(context.config.adjustments),
    context
  );
};
