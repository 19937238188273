import { useContext, useMemo } from 'react';
import AgoyAppClientContext from './AgoyAppClientContext';

export interface AgoyAppClientResponse<T> {
  ok: true;
  statusCode: number;
  body: T;
}

export interface AgoyAppClientError<ErrorType> {
  ok: false;
  statusCode: number;
  error: ErrorType | string;
}

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

type AgoyAppClient<T = unknown, BodyType = void, ErrorType = any> = (
  uri: string,
  method?: Method,
  content?: BodyType,
  rawContent?: ArrayBuffer,
  contentType?: string
) => Promise<AgoyAppClientResponse<T> | AgoyAppClientError<ErrorType>>;

export const agoyAppClient = <ResponseType, BodyType, ErrorType>(
  context: AgoyAppClientContext
): AgoyAppClient<ResponseType, BodyType, ErrorType> => {
  return async (
    path,
    method = 'GET',
    content = undefined,
    rawContent = undefined,
    contentType = undefined
  ) => {
    const uri = `${context.baseUrl}${path}`;
    const headers = await context.headers();
    const body = rawContent || (content && JSON.stringify(content));
    const options = {
      headers: body
        ? {
            ...headers,
            'Content-Type': contentType || 'application/json',
          }
        : headers,
      method,
      body,
    };
    const response = await fetch(uri, options);
    if (response.ok) {
      return {
        ok: response.ok,
        statusCode: response.status,
        body:
          response.headers.get('Content-Type')?.includes('application/json') &&
          (await response.json()),
      };
    }
    return {
      ok: false,
      statusCode: response.status,
      error: response.headers.get('Content-Type')?.includes('application/json')
        ? await response.json()
        : await response.text(),
    };
  };
};
const useAgoyAppClient: <
  ResponseType,
  BodyType = void,
  ErrorType = any
>() => AgoyAppClient<ResponseType, BodyType, ErrorType> = <
  ResponseType,
  BodyType,
  ErrorType
>() => {
  const context = useContext(AgoyAppClientContext);

  return useMemo(
    () => agoyAppClient<ResponseType, BodyType, ErrorType>(context),
    [context]
  );
};

export default useAgoyAppClient;
