import AnnualReportState from './types';
import { AnnualReportActionTypes } from 'redux/actions/AnnualReportView/types';
import { AnnualReportTableRow } from 'types/AnnualReport/types';
import {
  ADD_ANNUAL_REPORT_COLUMN,
  SET_ANNUAL_REPORT_CONFIG,
  UPDATE_ANNUAL_REPORT_CELL_REFERENCES,
  UPDATE_ANNUAL_REPORT_CELL_VALUE,
  UPDATE_ANNUAL_REPORT_FIELD,
  TOGGLE_ANNUAL_REPORT_SECTION_ACTIVE,
  ADD_ANNUAL_REPORT_ROW,
  RESET_CUSTOMER,
  DELETE_ANNUAL_REPORT_ROW,
  DELETE_ANNUAL_REPORT_COLUMN,
  INIT_STATE,
  TOGGLE_TABLE_ROW_ACTIVE,
  TOGGLE_ANNUAL_REPORT_FIELD_ACTIVE,
  TOGGLE_ANNUAL_REPORT_TABLE_ACTIVE,
  SET_ANNUAL_REPORT_TYPE,
  ADD_ANNUAL_REPORT_SECTION,
  UPDATE_NOTES_NUMBERING,
  DELETE_ANNUAL_REPORT_SECTION,
  SET_ANNUAL_REPORT_CHANGES,
  UPDATE_ANNUAL_REPORT_CHANGES,
  SET_ANNUAL_REPORT,
  SET_REFERENCED_NOTES,
  UPDATE_ANNUAL_REPORT_COLUMN_LABEL,
} from 'redux/actionsTypes';
import { references } from 'utils/AnnualReport/config';
import { GlobalActions } from 'redux/actions';
import applyChanges from 'utils/AnnualReport/applyChanges';
import updateAnnualReportCellReferences from './reducers/updateAnnualReportCellReferences';
import addAnnualReportColumn from './reducers/addAnnualReportColumn';
import deleteAnnualReportColumn from './reducers/deleteAnnualReportColumn';
import updateAnnualReportCellValue from './reducers/updateAnnualReportCellValue';
import updateAnnualReportField from './reducers/updateAnnualReportField';
import toggleAnnualReportFieldActive from './reducers/toggleAnnualReportFieldActive';
import toggleAnnualReportSectionActive from './reducers/toggleAnnualReportSectionActive';
import toggleAnnualReportTableActive from './reducers/toggleAnnualReportTableActive';
import toggleTableRowActive from './reducers/toggleTableRowActive';
import addAnnualReportRow from './reducers/addAnnualReportRow';
import deleteAnnualReportRow from './reducers/deleteAnnualReportRow';
import addAnnualReportSection from './reducers/addAnnualReportSection';
import deleteAnnualReportSection from './reducers/deleteAnnualReportSection';
import updateNotesNumbering from './reducers/updateNotesNumbering';
import updateAnnualReportColumnLabel from './reducers/updateAnnualReportColumnLabel';

export const findRow = (
  id: string,
  state: AnnualReportState
): AnnualReportTableRow | undefined => {
  const [part, section, table, ...subIds] = id.split('.');
  const rows = state.report?.[part]?.[section]?.[table].rows;
  const findSubRow = (rows, subIds) => {
    if (!rows) return undefined;

    const subId = subIds[0];
    const row = rows.find(row => row.id === subId);
    if (row) {
      if (subIds.length > 1) return findSubRow(row.rows, subIds.slice(1));
      return row;
    }
    return undefined;
  };

  return findSubRow(rows, subIds);
};

export const initialState: AnnualReportState = {
  type: 'k2',
  references: references(),
  changes: {},
  referencedNotes: [],
};

const reducer = (
  state: AnnualReportState = initialState,
  action: AnnualReportActionTypes | GlobalActions
): AnnualReportState => {
  switch (action.type) {
    case RESET_CUSTOMER:
      return initialState;

    case INIT_STATE:
      return action.state.annualReport || state;

    case SET_ANNUAL_REPORT_TYPE:
      if (!state.report) {
        return state;
      }
      return {
        ...state,
        type: action.reportType,
      };

    case SET_ANNUAL_REPORT_CONFIG:
      return {
        ...state,
        report: applyChanges(action.config, state.changes),
        referencedNotes: ['unknown'], // will force an update of notes numbering
      };

    case SET_ANNUAL_REPORT:
      if (action.report === state.report) {
        return state;
      }
      return { ...state, report: action.report };

    case SET_ANNUAL_REPORT_CHANGES:
      return {
        ...state,
        changes: action.changes,
        type: action.reportType,
      };

    case UPDATE_ANNUAL_REPORT_CHANGES:
      return {
        ...state,
        changes: action.changes,
      };

    case SET_REFERENCED_NOTES:
      return {
        ...state,
        referencedNotes: action.notes,
      };

    case UPDATE_ANNUAL_REPORT_CELL_REFERENCES:
      return updateAnnualReportCellReferences(state, action);

    case ADD_ANNUAL_REPORT_COLUMN:
      return addAnnualReportColumn(state, action);

    case DELETE_ANNUAL_REPORT_COLUMN:
      return deleteAnnualReportColumn(state, action);

    case UPDATE_ANNUAL_REPORT_COLUMN_LABEL:
      return updateAnnualReportColumnLabel(state, action);

    case UPDATE_ANNUAL_REPORT_CELL_VALUE:
      return updateAnnualReportCellValue(state, action);

    case UPDATE_ANNUAL_REPORT_FIELD:
      return updateAnnualReportField(state, action);

    case TOGGLE_ANNUAL_REPORT_FIELD_ACTIVE:
      return toggleAnnualReportFieldActive(state, action);

    case TOGGLE_ANNUAL_REPORT_TABLE_ACTIVE:
      return toggleAnnualReportTableActive(state, action);

    case TOGGLE_ANNUAL_REPORT_SECTION_ACTIVE:
      return toggleAnnualReportSectionActive(state, action);

    case TOGGLE_TABLE_ROW_ACTIVE:
      return toggleTableRowActive(state, action);

    case ADD_ANNUAL_REPORT_ROW:
      return addAnnualReportRow(state, action);

    case DELETE_ANNUAL_REPORT_ROW:
      return deleteAnnualReportRow(state, action);

    case ADD_ANNUAL_REPORT_SECTION:
      return addAnnualReportSection(state, action);

    case DELETE_ANNUAL_REPORT_SECTION:
      return deleteAnnualReportSection(state, action);

    case UPDATE_NOTES_NUMBERING:
      return updateNotesNumbering(state);

    default:
      return state;
  }
};

export default reducer;
