import React from 'react';

import styled from '@emotion/styled';
import {
  Snackbar as MuiSnackbar,
  SnackbarProps,
  WithTheme,
} from '@material-ui/core';

const SnackBar = styled(MuiSnackbar)<WithTheme>`
  padding: ${props => props.theme.spacing(4)}px;
`;

const Snackbar = ({ children, ...rest }: SnackbarProps) => (
  <SnackBar
    {...rest}
    autoHideDuration={5000}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
    {children}
  </SnackBar>
);

export default Snackbar;
