/**
 * AwsAsync.tsx
 *
 * Amazon functions rewritten to use promises so that we can use async/await
 * instead of callback spaghetti
 */

import {
  createUserPool,
  refreshAwsCredentials,
  tokenNeedsRefresh,
  updateCredentials,
} from 'utils/AuthenticatedClient';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

interface userAuth {
  username: string;
  password: string;
}

export const resendConfirmation = async (email: string) => {
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();

    var userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    if (cognitoUser !== null) {
      cognitoUser.resendConfirmationCode(function(err, result) {
        if (err) {
          reject(err);
        }
        resolve(result);
      });
    }
  });
};

export const authenticateUser = ({
  username,
  password,
}: userAuth): Promise<object> => {
  const lowerCaseUsername = username.toLowerCase();
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      {
        Username: lowerCaseUsername,
        Password: password,
      }
    );
    const userData = {
      Username: lowerCaseUsername,
      Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async session => {
        updateCredentials(session);
        await refreshAwsCredentials();
        resolve(session);
      },
      onFailure: reject,
    });
  });
};

export const refreshSession = refreshToken => {
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.refreshSession(refreshToken, async (err, newSession) => {
        if (err) {
          return reject(err);
        } else {
          updateCredentials(newSession);
          await refreshAwsCredentials();
          return resolve(newSession);
        }
      });
    }
  });
};

interface getCurrentSessionProps {
  returnUserAttributes?: boolean;
  forceRefreshSession?: boolean;
}

export const getCurrentSession = (
  options?: getCurrentSessionProps
): Promise<{
  session: object | null;
  userAttributes?: object;
}> => {
  const { returnUserAttributes, forceRefreshSession } = options || {
    returnUserAttributes: false,
  };
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(async (err, session) => {
        if (!session) {
          return resolve({
            session: null,
          });
        }

        let newSession = session;
        updateCredentials(session);
        await refreshAwsCredentials();

        if (err) {
          return reject(err);
        }

        if (forceRefreshSession || tokenNeedsRefresh()) {
          newSession = await refreshSession(session.getRefreshToken());
        }

        let resolveData = { session: newSession };
        if (returnUserAttributes) {
          cognitoUser.getUserAttributes((err, attributes) => {
            if (err) {
              return reject(err);
            }
            resolveData['userAttributes'] = _createUserAttributes(attributes);
            return resolve(resolveData);
          });
        } else {
          return resolve(resolveData);
        }
      });
    } else {
      return resolve({
        session: null,
      });
    }
  });
};

/**
 * Converts the array of user attributes that AWS SDK returns to an object
 * @param rawAttributes raw user attributes from AWS SDK
 */
const _createUserAttributes = rawAttributes => {
  return rawAttributes.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.getName()]: curr.getValue(),
    }),
    {}
  );
};

interface userAttributes {
  'custom:organisationId': string;
  email: string;
  email_veified: boolean;
}

export const getUserAttributes = () => {
  return new Promise<Partial<userAttributes>>((resolve, reject) => {
    const userPool = createUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession(function(err, session) {
        // Does not work if not calling getSesssion first
        cognitoUser.getUserAttributes(function(err, attributes) {
          if (err) {
            console.error(err);
          } else {
            // Create an object from the array
            if (attributes === undefined) return resolve();
            const attributesObject = _createUserAttributes(attributes);
            resolve(attributesObject);
          }
        });
      });
    } else {
      reject('Cognito user can not be null');
    }
  });
};

export const forgetPasswordRequest = ({ username }) => {
  return new Promise<Partial<userAttributes>>((resolve, reject) => {
    const userPool = createUserPool();
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: username.toLowerCase(),
      Pool: userPool,
    });
    cognitoUser.forgotPassword({
      onSuccess: async data => {
        resolve(data);
      },
      onFailure: async err => {
        reject(err);
      },
    });
  });
};

interface userSignUpArgs {
  username: string;
  password: string;
  given_name: string;
  family_name: string;
}

export const userSignUp = ({
  username,
  password,
  given_name,
  family_name,
}: userSignUpArgs) => {
  const userAttributeGivenName = new AmazonCognitoIdentity.CognitoUserAttribute(
    {
      Name: 'given_name',
      Value: given_name,
    }
  );

  const userAttributeFamilyName = new AmazonCognitoIdentity.CognitoUserAttribute(
    {
      Name: 'family_name',
      Value: family_name,
    }
  );

  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    userPool.signUp(
      username.toLowerCase(),
      password,
      [userAttributeGivenName, userAttributeFamilyName],
      [],
      function(error, result) {
        if (error) {
          reject(error);
          return;
        } else if (result) {
          resolve(result.user);
        }
      }
    );
  });
};

type setUserAttributes = {
  [index: string]: string;
};

export const setUserAttributes = (attributes: setUserAttributes) => {
  return new Promise((resolve, reject) => {
    const attributeList: AmazonCognitoIdentity.ICognitoUserAttributeData[] = [];
    Object.keys(attributes).forEach(key => {
      attributeList.push({
        Name: key,
        Value: attributes[key],
      });
    });

    const userPool = createUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession(function(err, session) {
        cognitoUser.updateAttributes(attributeList, function(err, result) {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        });
      });
    } else {
      reject('Cognito user can not be null');
    }
  });
};

interface confirmUser {
  username: string;
  code: string;
}

export const confirmUser = ({
  username,
  code,
}: confirmUser): Promise<object> => {
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    const userData = {
      Username: username.toLowerCase(),
      Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, function(err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

export const changeUserPassword = (oldPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession(function(err, session) {
        cognitoUser.changePassword(oldPassword, newPassword, function(
          err,
          result
        ) {
          if (err) {
            console.error(err);
            reject(err);
          } else {
            resolve(result);
          }
        });
      });
    } else {
      reject('Cognito user can not be null');
    }
  });
};
interface confirmPassword extends confirmUser {
  newPassword: string;
}
export const confirmNewPassword = ({
  username,
  code,
  newPassword,
}: confirmPassword): Promise<object> => {
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: username.toLowerCase(),
      Pool: userPool,
    });

    cognitoUser.confirmPassword(code, newPassword, {
      onFailure: err => {
        reject(err);
      },
      onSuccess: () => {
        resolve();
      },
    });
  });
};
