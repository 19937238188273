import { UpdateAnnualReportField } from 'redux/actions/AnnualReportView/types';
import { Field } from 'types/AnnualReport/field';
import { contentDefinition } from 'types/AnnualReport/types';
import AnnualReportState from '../types';
import { replaceByIndex, splitSection } from './helpers/util';

const validateField = (part: string, section: string, field: string) => {
  if (section.includes('-')) {
    const [name, no] = splitSection(section);
    return (
      !Number.isNaN(no) &&
      Number.isInteger(no) &&
      contentDefinition[part]?.[name]?.[field] === 'field' &&
      contentDefinition[part]?.[name]?.type === 'sections'
    );
  }
  return contentDefinition[part]?.[section]?.[field] === 'field';
};

const updateAnnualReportField = (
  state: AnnualReportState,
  action: UpdateAnnualReportField
) => {
  if (!state.report) {
    return state;
  }

  const { id, value } = action;

  const [part, sectionId, field] = id.split('.');
  if (!validateField(part, sectionId, field)) {
    console.error(`Id of field to update is not valid ${id}`);
    return state;
  }

  const [section, sectionIndex] = sectionId.includes('-')
    ? splitSection(sectionId)
    : [sectionId, undefined];

  const currentField: Field =
    sectionIndex !== undefined
      ? state.report[part][section].sections[sectionIndex][field]
      : state.report[part][section][field];

  if (currentField.type === 'string' && typeof value !== 'string') {
    console.error(
      `Field ${id} has type string but value is of type ${typeof value}`
    );
    return state;
  }
  if (currentField.type === 'number' && typeof value === 'string') {
    console.error(`Field ${id} has type number but value is of type string`);
    return state;
  }
  if (currentField.type === 'ref') {
    return state;
  }
  if (currentField.value === value) {
    return state;
  }
  const newField = { ...currentField, value };
  return {
    ...state,
    report: {
      ...state.report,
      [part]: {
        ...state.report[part],
        [section]:
          sectionIndex !== undefined
            ? {
                ...state.report[part][section],
                sections: replaceByIndex(
                  state.report[part][section].sections,
                  sectionIndex,
                  {
                    ...state.report[part][section].sections[sectionIndex],
                    [field]: newField,
                  }
                ),
              }
            : {
                ...state.report[part][section],
                [field]: newField,
              },
      },
    },
    changes: {
      ...state.changes,
      [part]: {
        ...state.changes[part],
        [section]:
          sectionIndex !== undefined
            ? {
                ...state.changes[part]?.[section],
                [sectionIndex]: {
                  ...state.changes[part]?.[section][sectionIndex],
                  [field]: newField,
                },
              }
            : {
                ...state.changes[part]?.[section],
                [field]: newField,
              },
      },
    },
  };
};
export default updateAnnualReportField;
