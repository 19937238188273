import moment from 'moment';
import {
  TaxCalculationContext,
  TaxView,
  YearEndPlanning,
  TaxCalculationRow,
  TaxTable,
} from './types';
import { calculateTaxTable, calculateRow } from './calculateTaxTable';
import { shallowCompare } from 'utils/shallowCompare';
import { isLastPeriod } from './context';

const createDefault = (context: TaxCalculationContext): TaxTable => {
  return {
    ...context.config.yearEndPlanning,
  };
};

const checksum = (s: string) => {
  var chk = 0x12345678;
  var len = s.length;
  for (var i = 0; i < len; i++) {
    chk += s.charCodeAt(i) * (i + 1);
  }

  return (chk & 0xffffffff).toString(16);
};

export const calculateChecksum = (rows: TaxCalculationRow[]): string => {
  const description = rows
    .map(
      row => `${row.id}:${row.label}:${row.value}:${row.error}:${row.reference}`
    )
    .join(';');
  return checksum(description);
};

export const resolveYearEndPlanning = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): YearEndPlanning | null => {
  if (!isLastPeriod(context)) {
    // Only for last period
    return null;
  }
  const { rows, sum } = calculateTaxTable(
    taxView?.yearEndPlanning || createDefault(context),
    context
  );
  const resultAfterYearEndPlanningBeforeTaxes = calculateRow(
    taxView?.yearEndPlanning?.resultAfterYearEndPlanningBeforeTaxes ||
      context.config.yearEndPlanningResultBeforeTaxes,
    context
  );
  const checksum = calculateChecksum([
    ...rows,
    sum,
    resultAfterYearEndPlanningBeforeTaxes,
  ]);

  const lastModified = context.config.yearEndPlanning.lastModified;
  let outdated = false;
  if (
    lastModified &&
    moment(context.input.sieUpdated).isAfter(moment(lastModified))
  ) {
    // SIE data is updated
    outdated = checksum !== context.config.yearEndPlanning.checksum;
  }

  const yearEndPlanning: YearEndPlanning = {
    outdated,
    checksum,
    rows,
    sum,
    resultAfterYearEndPlanningBeforeTaxes,
  };

  if (!taxView?.yearEndPlanning) {
    return yearEndPlanning;
  }

  return shallowCompare(taxView?.yearEndPlanning, yearEndPlanning)
    ? taxView?.yearEndPlanning
    : yearEndPlanning;
};
