import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button, ButtonProps } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import {
  addGlobalMessage,
  setIntegrationStatus,
  updateSieFromFortnox,
} from 'redux/actions';
import LoadingLogo from 'components/UI/LoadingLogo';
import { useGetFortnoxIntegrationStatus } from 'components/Api/Client/Integration/Fortnox';
import { useSelector } from 'redux/reducers';

const EMPTY_STRING = '';

const UpdateSIEButton = ({ variant }: Pick<ButtonProps, 'variant'>) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  // STATE
  const [loading, setLoading] = useState(false);

  const customerId = useSelector(
    state => state.customerView.currentCustomer || EMPTY_STRING
  );

  // API

  const [getFortnoxIntegrationStatus] = useGetFortnoxIntegrationStatus();

  // CALLBACKS

  const handleUpdateWithFortnoxAction = useCallback(async () => {
    setLoading(true);

    try {
      const status = await getFortnoxIntegrationStatus(customerId);
      const isIntegrationValid = status && !status.deleted ? true : false;
      if (!isIntegrationValid) throw Error('Fortnox integration not found');

      dispatch(setIntegrationStatus(customerId, 'fortnox', status));
      dispatch(updateSieFromFortnox(() => setLoading(false)));
    } catch (error) {
      dispatch(
        addGlobalMessage('error', 'customersubmenu.updatewithfortnox.error')
      );

      if (error.message === 'Fortnox integration not found') {
        dispatch(setIntegrationStatus(customerId, 'fortnox', null));
      }

      setLoading(false);
    }
  }, [dispatch, customerId, getFortnoxIntegrationStatus]);

  return (
    <Button
      variant={variant}
      onClick={handleUpdateWithFortnoxAction}
      disabled={loading}
      startIcon={loading ? <LoadingLogo size="small" /> : <UpdateIcon />}
    >
      {formatMessage({ id: 'customersubmenu.updatewithfortnox' })}
    </Button>
  );
};

export default UpdateSIEButton;
