import React from 'react';
import styled from '@emotion/styled';
import { WithTheme, Typography } from '@material-ui/core';

const InformationSectionLabelWrapper = styled.div<WithTheme>`
  margin-left: ${props => props.theme.spacing(1)}px;
`;

interface ChildrenWrapper {
  fullWidth?: boolean;
}
const ChildrenWrapper = styled.div<WithTheme & ChildrenWrapper>`
  width: ${props => (props.fullWidth ? '100%' : 'min-content')};
  margin-bottom: ${props => props.theme.spacing(4)}px;
`;

interface ClientSectionWrapper {
  label: string;
  children: any;
  fullWidth?: boolean;
}
const ClientSectionWrapper = ({
  label,
  children,
  fullWidth,
}: ClientSectionWrapper) => (
  <>
    <InformationSectionLabelWrapper>
      <Typography variant="h3">{label}</Typography>
    </InformationSectionLabelWrapper>
    <ChildrenWrapper fullWidth={fullWidth}>{children}</ChildrenWrapper>
  </>
);

export default ClientSectionWrapper;
