import React from 'react';
import { WithTheme, Button, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { AnnualReportTable } from 'types/AnnualReport/types';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { addAnnualReportRow } from 'redux/actions/AnnualReportView/actions';
import { Add } from '@material-ui/icons';
import { flattenRows } from '../utils';
import Cell from '../../../UI/Cell';

interface CustomNoteTableProps {
  headingKey?: string;
  table: AnnualReportTable;
  tableId: string;
  variant?: '3column' | '6column';
  editing?: boolean;
  print?: boolean;
}

const GridTable = styled.div<{ columnVariant: string } & WithTheme>`
  display: grid;
  grid-template-columns: ${props =>
    props.columnVariant === '3column'
      ? 'auto 130px 130px'
      : 'auto 10% 10% 10% 130px 130px'};
  grid-gap: ${props => props.theme.spacing(1) / 2}px;
`;

const GridTableHead = styled.div`
  display: contents;

  > *:first-of-type {
    justify-self: start;
  }
`;

const GridTableHeadCell = styled.div<WithTheme>`
  align-self: center;
  justify-self: end;
  color: ${props => props.theme.palette.grey[700]};
`;

const GridTableRow = styled.div<WithTheme>`
  display: contents;
  > * {
    padding: ${props => props.theme.spacing(1) / 2}px;
  }

  /* Zebra grid */
  &:nth-of-type(even) {
    > * {
      background-color: ${props => props.theme.palette.grey[100]};
    }
  }

  /* Last two column justify content right */
  > *:nth-last-of-type(1),
  > *:nth-last-of-type(2) {
    justify-content: flex-end;
  }
`;

const RowCell = styled.div<{ print: boolean } & WithTheme>`
  ${props => (props.print ? `padding: ${props.theme.spacing(0, 1)};` : '')}
  display: flex;
  min-height: 45px;
  align-items: center;
  font-size: inherit;
  line-height: 1.5rem;
`;

const AddRow = styled.div`
  margin: 1rem 0;
`;

const CustomNoteTable = ({
  headingKey,
  table,
  tableId,
  variant = '3column',
  editing = true,
  print = false,
}: CustomNoteTableProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const renderRows = flattenRows(table.rows);

  return (
    <>
      <Typography variant="h3">
        {formatMessage({
          id: headingKey,
        })}
      </Typography>
      <GridTable columnVariant={variant}>
        <GridTableHead>
          {table.columns.map(col => (
            <GridTableHeadCell key={col.id}>
              {col.label || ''}
            </GridTableHeadCell>
          ))}
        </GridTableHead>

        {renderRows.map(row => {
          return (
            <GridTableRow key={row.id}>
              {row.cells && (
                <React.Fragment>
                  {table.columns.map(col => {
                    const cell = row.cells?.[col.id];
                    const key = `${row.id}.${col.id}`;
                    if (!cell) {
                      return <div key={key} />;
                    }
                    return (
                      <RowCell key={key} print={print}>
                        <Cell
                          cell={cell}
                          id={`${tableId}.${key}`}
                          editing={editing}
                          print={print}
                        />
                      </RowCell>
                    );
                  })}
                </React.Fragment>
              )}
            </GridTableRow>
          );
        })}
      </GridTable>
      {!print && table.newRowTemplate && (
        <AddRow>
          <Button
            startIcon={<Add />}
            onClick={() => {
              const newRowValues = {
                label: '',
                val1: '',
                val2: '',
                val3: '',
                year: '',
                previousYear: '',
              };
              dispatch(addAnnualReportRow(tableId, undefined, newRowValues));
            }}
          >
            {formatMessage({ id: 'add.row' })}
          </Button>
        </AddRow>
      )}
    </>
  );
};

export default CustomNoteTable;
