import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { useIntl } from 'react-intl';
import { IconButton, Tooltip } from '@material-ui/core';

interface EditingIconButtonProps {
  className?: string;
  value: boolean;
  onClick: () => void;
}

const EditingIconButton = ({
  className,
  value,
  onClick,
}: EditingIconButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <Tooltip title={formatMessage({ id: 'edit' })}>
      <IconButton
        className={className}
        onClick={onClick}
        color={value ? 'primary' : undefined}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};

export default EditingIconButton;
