import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Typography, WithTheme } from '@material-ui/core';
import { useSelector } from 'redux/reducers';
import { useDispatch } from 'react-redux';
import { updateAnnualReportField } from 'redux/actions/AnnualReportView/actions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useIntl } from 'react-intl';
import { StringCell } from 'types/AnnualReport/cell';
import { LABELS as ROLES } from '../People/AccountantTitleSelection';

const Content = styled.div<WithTheme>`
  margin: ${props => props.theme.spacing(2)}px 0;
`;

type SignaturesAuditReportDateProps = {
  value: string;
  print?: boolean;
};

const SignaturesAuditReportDate = ({
  value,
  print,
}: SignaturesAuditReportDateProps) => {
  const dispatch = useDispatch();
  const peopleRows = useSelector(
    state => state.annualReport.report?.signatures.section.people.rows
  );

  const [isMainAccountantCompany, setIsMainAccountantCompany] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    peopleRows?.forEach(row => {
      const accountantRoles = (row?.cells?.accountantRole as StringCell)?.value;
      const roles = accountantRoles ? accountantRoles.split('|') : [];
      if (
        roles.find(value =>
          value.includes(ROLES.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM)
        )
      ) {
        setIsMainAccountantCompany(true);
      } else {
        setIsMainAccountantCompany(false);
      }
    });
  }, [peopleRows]);

  const handleDateChange = (date, value) => {
    const fullId = 'signatures.section.auditReportDate';
    if (date.isValid()) {
      dispatch(updateAnnualReportField(fullId, value));
    }
  };

  const isDateValid = value !== '';

  return (
    <Content>
      <Typography>
        {`${formatMessage({
          id: `annualReport.signatures.section.auditReportDate.${
            isMainAccountantCompany ? 'ours' : 'mine'
          }`,
        })} ${formatMessage({
          id: 'annualReport.signatures.section.auditReportDate',
        })}
      `}
        {print ? value : null}
      </Typography>
      {!print && (
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          margin="dense"
          format="YYYY-MM-DD"
          autoOk
          inputVariant="outlined"
          id="date-picker-inline-signatures-audit-date"
          value={null}
          inputValue={value}
          required={true}
          error={!isDateValid}
          helperText={
            !isDateValid
              ? formatMessage({
                  id:
                    'annualReport.signatures.section.auditReportDate.errorLabel',
                })
              : null
          }
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      )}
    </Content>
  );
};

export default SignaturesAuditReportDate;
