import gql from 'graphql-tag';

export const GET_USER_ORGANISATION = gql`
  query getUserOrganisation {
    getUserOrganisation {
      name
      members
      admins
      invited
      billingEmail
      subscriptionStatus
      nextPayment
    }
  }
`;

export const GET_ORGANISATION_INFO = gql`
  query getUserOrganisation {
    getUserOrganisation {
      invoices
      billingEmail
      last4
      address
      postalCode
      city
      orgNumber
      country
    }
  }
`;

export const GET_USER_INIVTES = gql`
  query getUserInvites {
    getUserInvites {
      name
      id: partitionKey
    }
  }
`;

export const GET_ORGANISATION_INIVTES = gql`
  query getOrganisationInvites {
    getOrganisationInvites {
      sortKey: email
    }
  }
`;
