import fs from 'fs';
import * as cp437 from './cp437';

type CallbackFunction<T> = (err: any, value?: T) => void;

interface SieModule {
  readFile: (
    fileName: string,
    callback: CallbackFunction<SieFile.SieFile>
  ) => SieFile.SieFile;
  readText: (data: string) => SieFile.SieFile;
  readBuffer: (data: Buffer) => SieFile.SieFile;
}

interface ElementsObject {
  name: string;
  type: string[];
  many?: boolean;
}

interface SieParser {
  parse: (sieFileData: string) => SieFile.SieFile;
  list: <T extends SieFile.Etikett>(
    scan: SieFile.RecordType<SieFile.Etikett>[],
    etikett: T
  ) => SieFile.RecordType<T>[];

  _parseLine: (line: string) => SieFile.Record | undefined;
  _tokenizer: (line: string) => [SieFile.ElementToken, ...SieFile.Token[]];
  _Tokens: {
    ARRAY: SieFile.ArrayTokenType;
    ELEMENT: SieFile.ElementTokenType;
    [key: string]: SieFile.TokenType;
  };
  _validateEtikett: (etikett: string) => SieFile.Etikett | undefined;
  _parseAttrs: (row: SieFile.Record, tokens: SieFile.Token[]) => SieFile.Record;
  _parseArray: (tokens: SieFile.Token[], start, attrDef) => any;
  _Elements: { [key: string]: (ElementsObject | string)[] };
  _addAttr: (
    obj: SieFile.Record,
    attr: string,
    token: SieFile.Token[],
    i: number
  ) => void;
  _valuesOnly: (tokens: SieFile.Token[]) => string[];
}

const sie: SieModule = {
  readFile: function(fileName, callback) {
    var data;
    fs.readFile(fileName, function(err, original_data) {
      if (!err) {
        try {
          const callbcackValue = callback(null, sie.readBuffer(original_data));
          data = callbcackValue;
        } catch (ex) {
          callback(ex);
        }
      } else {
        callback(err);
      }
    });
    return data;
  },
  readText: function(original_data) {
    return parser.parse(original_data);
  },
  readBuffer: function(original_data) {
    return parser.parse(cp437.convert(original_data).toString());
  },
};
const parser: SieParser = {
  parse: function(sieFileData) {
    var root = new SieFile();
    var lines = sieFileData.split(/\r?\n/).map(line => line.trim());
    var stack: SieFile.Records[] = [];
    var cur: SieFile.Records = root;
    for (var i in lines) {
      cur.poster = cur.poster || [];
      if (lines[i] === '{') {
        stack[stack.length] = cur;
        cur = cur.poster[cur.poster.length - 1];
      } else if (lines[i] === '}') {
        cur = stack.pop() || cur;
      } else if (lines[i].startsWith('#')) {
        const post = parser._parseLine(lines[i]);
        if (post) {
          cur.poster.push(post);
        }
      }
    }
    return root;
  },
  _validateEtikett: function(etikett: string): SieFile.Etikett | undefined {
    switch (etikett) {
      case 'ver':
      case 'trans':
      case 'ib':
      case 'ub':
      case 'res':
      case 'konto':
      case 'adress':
      case 'rar':
      case 'fnamn':
      case 'ftyp':
      case 'orgnr':
        return etikett;
      default:
        return;
    }
  },
  _parseLine: function(line: string) {
    var tokens = parser._tokenizer(line);

    var etikett = this._validateEtikett(
      tokens[0].value!.replace(/^#/, '').toLowerCase()
    );
    if (etikett) {
      var row: SieFile.Record = {
        etikett: etikett,
        tokens: tokens.slice(1),
      };
      return parser._parseAttrs(row, tokens.slice(1));
    }
  },
  _Tokens: {
    ELEMENT: '#',
    BEGINARRAY: '{',
    ENDARRAY: '}',
    STRING: '"',
    ARRAY: '{}',
  },
  _tokenizer: function(line) {
    var tokens: [SieFile.ElementToken, ...SieFile.Token[]] = [
      { type: this._Tokens.ELEMENT, value: '' },
    ];
    var consume = false;
    var quoted = false;
    var doubleQuoted = false;
    var saldoIsSet = false;
    let setSaldo;
    for (var i = 0; i < line.length; i++) {
      if (consume) {
        if (quoted) {
          if (line[i] === '\\' && i + 1 < line.length && line[i + 1] === '"') {
            tokens[tokens.length - 1].value += line[++i];
          } else {
            quoted = consume = line[i] !== '"';
            if (doubleQuoted) {
              if (
                !quoted &&
                i + 2 < line.length &&
                line[i + 1] === '"' &&
                line[i + 2] === '"'
              ) {
                doubleQuoted = false;
                i += 2;
              } else {
                quoted = true;
              }
            }
            if (consume) {
              tokens[tokens.length - 1].value += line[i];
            }
            consume = consume || doubleQuoted;
          }
        } else {
          consume = line[i] !== ' ' && line[i] !== '\t' && line[i] !== '}';
          if (consume) {
            tokens[tokens.length - 1].value += line[i];
          } else if (line[i] === '}') {
            tokens[tokens.length] = { type: parser._Tokens.ENDARRAY };
          }
        }
      } else {
        if (line[i] === '#') {
          consume = true;
        } else if (line[i] === '{') {
          tokens[tokens.length] = { type: parser._Tokens.BEGINARRAY };
        } else if (line[i] === '}') {
          tokens[tokens.length] = { type: parser._Tokens.ENDARRAY };
        } else if (line[i] === '"') {
          consume = quoted = true;
          if (
            i + 2 < line.length &&
            line[i + 1] === '"' &&
            line[i + 2] === '"'
          ) {
            doubleQuoted = true;
            i += 2;
          }
          tokens[tokens.length] = { type: parser._Tokens.STRING, value: '' };
        } else if (line[i] !== ' ' && line[i] !== '\t') {
          consume = true;
          setSaldo = line.slice(0, i).includes('}') && !saldoIsSet;
          tokens[tokens.length] = {
            type: parser._Tokens.STRING,
            psaldo: setSaldo ? 'yes' : 'no',
            value: line[i],
          };
          saldoIsSet = setSaldo;
        }
      }
    }
    return tokens;
  },
  _parseAttrs: function(row, tokens) {
    if (parser._Elements[row.etikett]) {
      for (var i = 0; i < parser._Elements[row.etikett].length; i++) {
        const elem = parser._Elements[row.etikett][i];
        if (typeof elem === 'object') {
          parser._parseArray(tokens, i, elem);
          parser._addAttr(row, elem.name, tokens, i);
        } else {
          parser._addAttr(row, elem, tokens, i);
        }
      }
    }
    return row;
  },
  _parseArray: function(tokens, start, attrDef) {
    const startToken: SieFile.ArrayToken = {
      type: parser._Tokens.ARRAY,
      value: [],
    };

    for (var i = start + 1; i < tokens.length; i++) {
      if (tokens[i].type === parser._Tokens.ENDARRAY) {
        tokens[start] = startToken;
        startToken.value = parser._valuesOnly(
          tokens.splice(start, i - start).slice(1)
        );
        var a: any[] = [];
        for (
          var j = 0;
          j < startToken.value.length - attrDef.type.length + 1;
          j += attrDef.type.length
        ) {
          var o = {};
          for (var k = 0; k < attrDef.type.length; k++) {
            o[attrDef.type[k]] = startToken.value[j + k];
          }
          a[a.length] = o;
        }
        startToken.value = attrDef.many ? a : a[0] || null;
      }
    }
  },
  _addAttr: function(obj, attr, tokens, pos) {
    if (pos < tokens.length) {
      obj[attr] = tokens[pos].value;
    }
  },
  _valuesOnly: function(tokens) {
    return tokens.flatMap(token => token.value || '');
  },
  _Elements: {
    adress: ['kontakt', 'utdelningsadr', 'postadr', 'tel'],
    bkod: ['SNI-kod'],
    dim: ['dimensionsnr', 'namn'],
    enhet: ['kontonr', 'enhet'],
    flagga: ['x'],
    fnamn: ['företagsnamn'],
    fnr: ['företagsid'],
    format: ['PC8'],
    ftyp: ['företagstyp'],
    gen: ['datum', 'sign'],
    ib: ['årsnr', 'konto', 'saldo', 'kvantitet'],
    konto: ['kontonr', 'kontonamn'],
    kptyp: ['typ'],
    ktyp: ['kontonr', 'kontotyp'],
    objekt: ['dimensionsnr', 'objektnr', 'objektnamn'],
    oib: [
      'årsnr',
      'konto',
      { name: 'objekt', type: ['dimensionsnr', 'objektnr'] },
      'saldo',
      'kvantitet',
    ],
    omfattn: ['datum'],
    orgnr: ['orgnr', 'förvnr', 'verknr'],
    oub: [
      'årsnr',
      'konto',
      { name: 'objekt', type: ['dimensionsnr', 'objektnr'] },
      'saldo',
      'kvantitet',
    ],
    pbudget: [
      'årsnr',
      'period',
      'konto',
      { name: 'objekt', type: ['dimensionsnr', 'objektnr'] },
      'saldo',
      'kvantitet',
    ],
    program: ['programnamn', 'version'],
    prosa: ['text'],
    psaldo: [
      'årsnr',
      'period',
      'konto',
      { name: 'objekt', type: ['dimensionsnr', 'objektnr'] },
      'saldo',
      'kvantitet',
    ],
    rar: ['årsnr', 'start', 'slut'],
    res: ['års', 'konto', 'saldo', 'kvantitet'],
    sietype: ['typnr'],
    sru: ['konto', 'SRU-kod'],
    taxar: ['år'],
    trans: [
      'kontonr',
      { name: 'objektlista', type: ['dimensionsnr', 'objektnr'], many: true },
      'belopp',
      'transdat',
      'transtext',
      'kvantitet',
      'sign',
    ],
    rtrans: [
      'kontonr',
      { name: 'objektlista', type: ['dimensionsnr', 'objektnr'], many: true },
      'belopp',
      'transdat',
      'transtext',
      'kvantitet',
      'sign',
    ],
    btrans: [
      'kontonr',
      { name: 'objektlista', type: ['dimensionsnr', 'objektnr'], many: true },
      'belopp',
      'transdat',
      'transtext',
      'kvantitet',
      'sign',
    ],
    ub: ['årsnr', 'konto', 'saldo', 'kvantitet'],
    underdim: ['dimensionsnr', 'namn', 'superdimension'],
    valuta: ['valutakod'],
    ver: ['serie', 'vernr', 'verdatum', 'vertext', 'regdatum', 'sign'],
  },
  list: function<T extends SieFile.Etikett>(
    scan: SieFile.RecordType<SieFile.Etikett>[],
    etikett: T
  ): SieFile.RecordType<T>[] {
    return scan.filter(p => p.etikett === etikett) as SieFile.RecordType<T>[];
  },
};

class SieFile implements SieFile.SieFile {
  poster = [];

  list<T extends SieFile.Etikett>(etikett: T): SieFile.RecordType<T>[] {
    return parser.list(this.poster, etikett);
  }
}

export default sie;
export const readBuffer = sie.readBuffer;
export const readText = sie.readText;
