import React, { useState, useCallback } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import { IconButton, WithTheme } from '@material-ui/core';
import { CloudDownload, Delete } from '@material-ui/icons';
import { keyframes } from '@emotion/core';
import { useMutation } from 'react-apollo';
import { DELETE_DOCUMENT } from 'Graphql/Mutations';
import { LIST_DOCUMENTS } from 'Graphql/Queries';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useIntl } from 'react-intl';
import DownloadLink from 'utils/DownloadLink';
import LoadingLogo from '../../../UI/LoadingLogo';

const TableCell = styled(({ className, children }) => (
  <div className={className}>
    <div>{children}</div>
  </div>
))<{ align?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.align || 'left'};
  & > div {
    padding: 8px;
  }
`;

const rowHeight = 60;
const rowEnter = keyframes`
  from {
      height: 0;
  }
  to {
      height: ${rowHeight}px;
  }
`;

const Row = styled.div<WithTheme & { visible: boolean }>`
  display: contents;
  & > * {
    height: ${props => (props.visible ? rowHeight : 0)}px;
    transition: height 0.25s ease-in-out;
    animation: ${rowEnter} 0.25s ease-in-out;
    overflow-y: hidden;
  }

  &:hover > * {
    background: ${props => props.theme.palette.accountingView.cell.ok};
  }
`;

const ErrorMessage = styled(Alert)`
  grid-column: span 4;
`;

type FileState = 'ready' | 'deleting' | 'deleted' | 'downloading';

const Document = ({
  name,
  category,
  lastModified,
  verifyDelete,
  customerId,
  url,
  year,
  onInvalidDocument,
}) => {
  const [fileState, setFileState] = useState<FileState>('ready');
  const [downloadError, setDownloadError] = useState<string | null>(null);
  const [deleteDocument, { error: deleteError }] = useMutation(DELETE_DOCUMENT);

  const onDownloading = async (downloading, error) => {
    setFileState(downloading ? 'downloading' : 'ready');
    if (error === '403') {
      try {
        await onInvalidDocument(error);
      } catch {
        setDownloadError(error);
      }
    } else {
      setDownloadError(error);
    }
  };

  const handleDelete = useCallback(() => {
    verifyDelete(name, async () => {
      setFileState('deleting');
      try {
        await deleteDocument({
          variables: {
            customerId,
            year,
            category,
            name,
          },
          refetchQueries: [
            {
              query: LIST_DOCUMENTS,
              variables: {
                customerId,
                year,
              },
            },
          ],
        });
        setFileState('deleted');
      } catch (e) {
        setFileState('ready');
      }
    });
  }, [name, verifyDelete, category, customerId, year, deleteDocument]);

  const { formatMessage } = useIntl();

  return (
    <Row visible={fileState !== 'deleted'}>
      {deleteError && (
        <ErrorMessage severity="error">
          <AlertTitle>
            {formatMessage({ id: 'documents.delete.failed.title' })}
          </AlertTitle>
          {formatMessage({ id: 'documents.delete.failed.text' })}
        </ErrorMessage>
      )}
      {downloadError && (
        <ErrorMessage severity="error">
          <AlertTitle>
            {formatMessage({ id: 'documents.download.failed.title' })}
          </AlertTitle>
          {formatMessage({ id: 'documents.download.failed.text' })}
        </ErrorMessage>
      )}
      <TableCell>{name}</TableCell>
      <TableCell>
        <span title={moment(lastModified).format('YYYY-MM-DD HH:mm')}>
          {moment(lastModified).format('YYYY-MM-DD')}
        </span>
      </TableCell>
      <TableCell align="center">
        <DownloadLink title={name} href={url} onDownloading={onDownloading}>
          <IconButton disabled={fileState !== 'ready'}>
            {fileState === 'downloading' ? (
              <LoadingLogo size="small" />
            ) : (
              <CloudDownload />
            )}
          </IconButton>
        </DownloadLink>
      </TableCell>
      <TableCell align="center">
        <IconButton onClick={handleDelete} disabled={fileState !== 'ready'}>
          {fileState === 'deleting' ? <LoadingLogo size="small" /> : <Delete />}
        </IconButton>
      </TableCell>
    </Row>
  );
};

export default Document;
