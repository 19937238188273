import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import * as ReactRedux from 'react-redux';
import { Provider } from 'react-redux';
import store from 'redux/store';
import config from './config';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from '@emotion/core'; // Needed, see https://emotion.sh/docs/introduction

Sentry.init({
  dsn:
    'https://86d9799886644842bcb7e52f7f7df336@o447691.ingest.sentry.io/5427878',
  environment: config.runningEnvironment,
  enabled:
    config.runningEnvironment === 'production' ||
    config.runningEnvironment === 'staging' ||
    config.runningEnvironment === 'test',
  release: config.appVersion,
});

console.log(`Agoy web-app version: v${config.appVersion}`);
console.log(`Running environment ${config.runningEnvironment}`);

// Logs unecessary rerenders
// Create react app defined NODE_ENV by default depending on build
if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: false,
    trackHooks: true,
    trackExtraHooks: [[ReactRedux, 'useSelector']],
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// expose store when run in Cypress
// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}

serviceWorker.unregister();
