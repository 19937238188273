import {
  AnnualReportChanges,
  AnnualReportType,
} from 'types/AnnualReport/types';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { agoyAppClient } from 'utils/AgoyAppClient/useAgoyAppClient';

export interface StoredAnnualReportChanges extends AnnualReportChanges {
  type: AnnualReportType;
}

export const storeAnnualReport = async (
  clientId: string,
  year: string,
  changes: AnnualReportChanges,
  type: AnnualReportType
): Promise<void> => {
  const context = getContext();
  if (!context) {
    throw new Error('context not set yet');
  }
  const client = agoyAppClient<void, StoredAnnualReportChanges, void>(context);

  const response = await client(
    `/api/client/${encodeURIComponent(clientId)}/annualReport/${year}`,
    'PUT',
    { ...changes, type }
  );

  if (response.ok) {
    return;
  } else {
    return Promise.reject(response.error);
  }
};

export const getAnnualReport = async (
  clientId: string,
  year
): Promise<StoredAnnualReportChanges | null> => {
  const context = getContext();
  if (!context) {
    throw new Error('context not set yet');
  }
  const client = agoyAppClient<StoredAnnualReportChanges, void, void>(context);

  const response = await client(
    `/api/client/${encodeURIComponent(clientId)}/annualReport/${year}`
  );

  if (response.ok) {
    return response.body;
  } else if (response.statusCode === 404) {
    return null;
  } else {
    return Promise.reject(response.error);
  }
};
