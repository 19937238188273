import React from 'react';
import CloseIconButton from 'components/UI/CloseIconButton';
import { WithTheme } from '@material-ui/core';
import styled from '@emotion/styled';

const Container = styled.div<WithTheme>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-radius: 8px 8px 0px 0px;
  padding: ${props => props.theme.spacing(2)}px;
  background-color: ${props => props.theme.palette.primary.main};
`;

const CloseTopBar = ({ onClose }) => (
  <Container>
    <CloseIconButton stopPropagation={true} onClose={onClose} />
  </Container>
);

export default CloseTopBar;
